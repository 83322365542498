/******************************************
 *  Author : Dr. Hanno Georg   
 *  Created On : Fri Sep 03 2021
 *  File : signout-oidcs.js
 *******************************************/

 import React, { useEffect } from 'react'
 import { signoutRedirectCallback } from '../services/userService'
 import { AuthContext } from '../utils/authProvider';
 import { useHistory } from 'react-router-dom'
 
 function SignoutOidc() {
     const history = useHistory()
     const userManager = React.useContext(AuthContext);
   useEffect(() => {
     async function signoutAsync() {
       await signoutRedirectCallback(userManager)
       history.push('/')
     }
     signoutAsync()
   }, [history])
 
   return (
     <div>
       Redirecting...
     </div>
   )
 }
 
 export default SignoutOidc
 