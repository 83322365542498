import React, { createContext, useContext, useReducer} from "react";

import { v4 } from "uuid";
import ExceptionDialog from "./ExceptionDialog";

const ExceptionDialogContext = createContext();

export const ExceptionDialogActions = {
    ADD_EXCEPTION: "ADD_NOTIFICATION",
    REMOVE_EXCEPTION: "REMOVE_NOTIFICATION",
    REMOVE_PAGE_EXCEPTIONS: "REMOVE_PAGE_NOTIFICATION"
};

const ExceptionDialogProvider = (props) => {
    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case ExceptionDialogActions.ADD_EXCEPTION:
                return [...state, { ...action.payload }];
            case ExceptionDialogActions.REMOVE_EXCEPTION:
                return state.filter(el => el.id !== action.id);
            case ExceptionDialogActions.REMOVE_PAGE_EXCEPTIONS:
                return state.filter(el => el.pageId !== action.payload.pageId);
            default:
                return state
        }
    }, []);

    return (
        <ExceptionDialogContext.Provider value={dispatch}>
            <div>
                    {state.map((note) => {
                        return <ExceptionDialog dispatch={dispatch} key={note.id} {...note} />
                    })}
           </div>
            {props.children}
        </ExceptionDialogContext.Provider>
    )
};

export const useExceptionDialog = () => {
    const dispatch = useContext(ExceptionDialogContext);

    return (props) => {
        dispatch({
            type: props.remove ? ExceptionDialogActions.REMOVE_PAGE_EXCEPTIONS : ExceptionDialogActions.ADD_EXCEPTION,
            payload: {
                id: v4(),
                ...props
            }
        })          
    }
};

export default ExceptionDialogProvider;