import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Card, CardHeader, CardBody, Button } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import validator from 'validator';
import { Form, FormElement, Field } from '@progress/kendo-react-form';
import { Input } from "@progress/kendo-react-inputs";
import { FormAutoComplete, FormCheckbox, FormDatePicker, FormInput } from '../../Inventory/Wizard/form-components';
import { NotificationTypes } from '../../Shared/Notifications/Notification';
import { saveCustomerBillingInfo, validateVATNumber, validateCompanyName } from '../../../services/masterDataService';
import { Prompt } from 'react-router';
import { euMember } from 'is-european';
import ReactTooltip from 'react-tooltip';

const BillingTab = (props) => {
    const intl = useIntl();

    const currentCustomer = useSelector(state => state.dashboard.customer);

    useEffect(() => {
        return () => {
            props.removeNotification();
        };
    }, []);

    const [isInEU, setIsInEU] = React.useState("");
    const [countryCode, setCountryCode] = React.useState("");
    const [billingCountries, setBillingCountries] = React.useState(props.getCountries());

    const [customerMasterData, setCustomerMasterData] = useState(props.customerMasterData);
    useEffect(() => {
        const custMasterData = props.customerMasterData;
        const cCode = props.countries.getAlpha2Code(props.customerMasterData.billingAddressCountry, props.countriesLNG);

        if (cCode) {
            setCountryCode(cCode);
            setIsInEU(euMember(cCode));
            custMasterData.iHaveNoVAT = euMember(cCode) && !props.customerMasterData.isVATValid;
        }
        else {
            setCountryCode("");
            setIsInEU(false);
            custMasterData.iHaveNoVAT = true;
        }
        setIHaveNoVAT(custMasterData.iHaveNoVAT);
        setCustomerMasterData(custMasterData);

        setIsBillingAddressCityChanged(false);
        setIsBillingAddressCompanyNameChanged(false);
        setIsBillingAddressStreetNumberChanged(false);
        setIsBillingAddressPostalCodeChanged(false);
        setIsBillingAddressStreetChanged(false);
        setIsBillingEmailAddressChanged(false);
        setIsBillingInfoPurchaseOrderNumberChanged(false);
        setIsCountryChanged(false);
        setIsVatNumberChanged(false);
        setIHaveNoVATChanged(false);

        props.setAreChangesOnBilling(false);

    }, [props.customerMasterData]);

    const billingEmailAddressValidator = (value) => {
        if (!value) {
            return intl.formatMessage({ id: 'MasterData.BillingEmailAddressRequired' });
        }

        return validator.isEmail(value) ? "" : <FormattedMessage id='MasterData.BillingEmailAddressInvalid' />;
    };

    const vatNumberValidator = (value) => {
        return value || iHaveNoVAT ? "" : intl.formatMessage({ id: 'MasterData.VatNumberRequired' });
    }
    const billingAddressCompanyNameValidator = (value) => value.trim() ? "" : intl.formatMessage({ id: 'MasterData.CompanyNameRequired' });
    const billingAddressStreetValidator = (value) => value ? "" : intl.formatMessage({ id: 'MasterData.StreetRequired' });
    const billingAddressStreetNumberValidator = (value) => value ? "" : intl.formatMessage({ id: 'MasterData.StreetNumberRequired' });
    const billingAddressPostalCodeValidator = (value) => value ? "" : intl.formatMessage({ id: 'MasterData.PostalCodeRequired' });
    const billingAddressCityValidator = (value) => value ? "" : intl.formatMessage({ id: 'MasterData.CityRequired' });

    const countryValidator = (value) => {
        if (!value) {
            return intl.formatMessage({ id: "MasterData.CountryRequired" });
        }

        const isValidCountry = props.getCountries().some(x => x === value);
        return isValidCountry ? "" : intl.formatMessage({ id: "MasterData.CountryInvalid" });
    };

    const handleSubmitBillingInfo = (dataItem) => {
        validateCompanyNameImpl(dataItem);
    };

    const validateCompanyNameImpl = (dataItem) => {
        if (isBillingAddressCompanyNameChanged) {
            props.removeNotification();
            props.incrementLoadingCount();
            validateCompanyName(
                dataItem.customerId,
                dataItem.billingAddressCompanyName.trim(),
                (result) => {
                    props.decrementLoadingCount();

                    if (result.companyNameAlreadyExists) {
                        props.dispatchNotification({
                            type: NotificationTypes.error,
                            pageId: props.pageId,
                            message: intl.formatMessage({ id: 'MasterData.CompanyNameAlreadyExists' })
                        });
                        return;
                    }

                    validateVATNumberImpl(dataItem);
                },
                props.handleError);
        }
        else {
            validateVATNumberImpl(dataItem);
        }
    };

    const validateVATNumberImpl = (dataItem) => {
        if (isInEU && !dataItem.iHaveNoVAT && countryCode.toLowerCase() !== "de") {
            props.removeNotification();
            props.incrementLoadingCount();
            validateVATNumber(
                countryCode + dataItem.vatNumber,
                dataItem.billingAddressCompanyName.trim(),
                dataItem.billingAddressCity,
                (result) => {
                    props.decrementLoadingCount();

                    if (!result.isVATValid) {
                        let MasterDataVatVerificationErrorCode = 'MasterData.VatVerificationErrorCode_';
                        let reasonDesc = intl.formatMessage({ id: MasterDataVatVerificationErrorCode + result.responseCode });

                        if (result.responseCode === '203') {
                            reasonDesc = intl.formatMessage({ id: MasterDataVatVerificationErrorCode + result.responseCode }, { ValidFrom: result.validFrom });
                        }

                        if (result.responseCode === '204') {
                            reasonDesc = intl.formatMessage({ id: MasterDataVatVerificationErrorCode + result.responseCode }).replace("{ValidFrom}", result.validFrom).replace("{ValidTo}", result.validTo);
                        }

                        props.dispatchNotification({
                            type: NotificationTypes.error,
                            pageId: props.pageId,
                            isHtml: true,
                            message: reasonDesc ?? intl.formatMessage({ id: 'MasterData.TheVATIdNumberIsNotValid' })
                        });

                        return;
                    } else if (!result.isCompanyNameValid) {
                        props.dispatchNotification({
                            type: NotificationTypes.error,
                            pageId: props.pageId,
                            message: intl.formatMessage({ id: 'MasterData.VATIdNumberDoesNotMatchTheCompanyName' })
                        });
                        return;
                    }
                    else if (!result.isCityNameValid) {
                        props.dispatchNotification({
                            type: NotificationTypes.error,
                            pageId: props.pageId,
                            message: intl.formatMessage({ id: 'MasterData.VATIdNumberDoesNotMatchTheCity' })
                        });
                        return;
                    }

                    doSaveCustomerBillingInfo(dataItem);
                },
                props.handleError);
        }
        else {
            doSaveCustomerBillingInfo(dataItem);
        }
    };

    const doSaveCustomerBillingInfo = (dataItem) => {
        const cCode = props.countries.getAlpha2Code(dataItem.billingAddressCountry, props.countriesLNG);
        const billingInfo = {
            customerId: customerMasterData.customerId || currentCustomer.customerId,
            vatNumber: !dataItem.iHaveNoVAT || isInEU ? dataItem.vatNumber : null,
            billingEmailAddress: dataItem.billingEmailAddress,
            billingInfoPurchaseOrderNumber: dataItem.billingInfoPurchaseOrderNumber,
            billingInfoPurchaseOrderDate: dataItem.billingInfoPurchaseOrderDate,
            billingAddressCompanyName: dataItem.billingAddressCompanyName.trim(),
            billingAddressStreet: dataItem.billingAddressStreet,
            billingAddressStreetNumber: dataItem.billingAddressStreetNumber,
            billingAddressPostalCode: dataItem.billingAddressPostalCode,
            billingAddressCity: dataItem.billingAddressCity,
            billingAddressCountry: cCode,
            isVATValid: !dataItem.iHaveNoVAT && isInEU
        };

        props.removeNotification();
        props.incrementLoadingCount();

        saveCustomerBillingInfo(
            billingInfo,
            () => {
                props.decrementLoadingCount();
                props.dispatchNotification({
                    pageId: props.pageId,
                    type: NotificationTypes.success,
                    message: <FormattedMessage id='MasterData.SaveBillingInfoSuccess' />
                });
                props.dispatchCustomerMasterData({
                    ...customerMasterData,
                    vatNumber: dataItem.vatNumber,
                    billingEmailAddress: dataItem.billingEmailAddress,
                    billingInfoPurchaseOrderNumber: dataItem.billingInfoPurchaseOrderNumber,
                    billingInfoPurchaseOrderDate: dataItem.billingInfoPurchaseOrderDate,
                    billingAddressCompanyName: dataItem.billingAddressCompanyName.trim(),
                    billingAddressStreet: dataItem.billingAddressStreet,
                    billingAddressStreetNumber: dataItem.billingAddressStreetNumber,
                    billingAddressPostalCode: dataItem.billingAddressPostalCode,
                    billingAddressCity: dataItem.billingAddressCity,
                    billingAddressCountry: dataItem.billingAddressCountry,
                    isVATValid: !dataItem.iHaveNoVAT && isInEU

                });

                setIsVatNumberChanged(false);
                setIsBillingEmailAddressChanged(false);
                setIsBillingInfoPurchaseOrderNumberChanged(false);
                setIsBillingInfoPurchaseOrderDateChanged(false);
                setIsBillingAddressCompanyNameChanged(false);
                setIsBillingAddressStreetChanged(false);
                setIsBillingAddressStreetNumberChanged(false);
                setIsBillingAddressPostalCodeChanged(false);
                setIsBillingAddressCityChanged(false);
                setIsCountryChanged(false);
                setIHaveNoVATChanged(false);

                if (props.onBillingInfoSaved) {
                    props.onBillingInfoSaved(billingInfo);
                }
            },
            props.handleError);
    }

    const [isBillingAddressCompanyNameChanged, setIsBillingAddressCompanyNameChanged] = useState(false);
    const handleBillingAddressCompanyNameChange = (event) => {
        const isChanged = event.value.trim() !== customerMasterData.billingAddressCompanyName.trim();
        setIsBillingAddressCompanyNameChanged(isChanged);
    };

    const [isBillingAddressStreetNumberChanged, setIsBillingAddressStreetNumberChanged] = useState(false);
    const handleBillingAddressStreetNumberChange = (event) => {
        const isChanged = event.value !== customerMasterData.billingAddressStreetNumber;
        setIsBillingAddressStreetNumberChanged(isChanged);
    };

    const [isBillingAddressStreetChanged, setIsBillingAddressStreetChanged] = useState(false);
    const handleBillingAddressStreetChange = (event) => {
        const isChanged = event.value !== customerMasterData.billingAddressStreet;
        setIsBillingAddressStreetChanged(isChanged);
    };

    const [isBillingAddressPostalCodeChanged, setIsBillingAddressPostalCodeChanged] = useState(false);
    const handleBillingAddressPostalCodeChange = (event) => {
        const isChanged = event.value !== customerMasterData.billingAddressPostalCode;
        setIsBillingAddressPostalCodeChanged(isChanged);
    };

    const [isBillingAddressCityChanged, setIsBillingAddressCityChanged] = useState(false);
    const handleBillingAddressCityChange = (event) => {
        const isChanged = event.value !== customerMasterData.billingAddressCity;
        setIsBillingAddressCityChanged(isChanged);
    };

    const [isCountryChanged, setIsCountryChanged] = useState(false);
    const handleCountryChange = (event) => {
        const isChanged = event.value !== customerMasterData.billingAddressCountry;
        setIsCountryChanged(isChanged);
        const cCode = props.countries.getAlpha2Code(event.value, props.countriesLNG);
        setIsInEU(euMember(cCode));
        setCountryCode(cCode);
        setBillingCountries(props.filterBillingCountries(event.value));
    };

    const [isVatNumberChanged, setIsVatNumberChanged] = useState(false);
    const handleVatNumberChange = (event) => {
        const isChanged = event.value !== customerMasterData.vatNumber;
        setIsVatNumberChanged(isChanged);
    };

    const [isBillingEmailAddressChanged, setIsBillingEmailAddressChanged] = useState(false);
    const handleBillingEmailAddressChange = (event) => {
        const isChanged = event.value !== customerMasterData.billingEmailAddress;
        setIsBillingEmailAddressChanged(isChanged);
    };

    const [isBillingInfoPurchaseOrderNumberChanged, setIsBillingInfoPurchaseOrderNumberChanged] = useState(false);
    const handleBillingInfoPurchaseOrderNumberChange = (event) => {
        const isChanged = event.value !== customerMasterData.billingInfoPurchaseOrderNumber;
        setIsBillingInfoPurchaseOrderNumberChanged(isChanged);
    };

    const [isBillingInfoPurchaseOrderDateChanged, setIsBillingInfoPurchaseOrderDateChanged] = useState(false);
    const handleBillingInfoPurchaseOrderDateChange = (event) => {
        const isChanged = event.value !== customerMasterData.billingInfoPurchaseOrderDate;
        setIsBillingInfoPurchaseOrderDateChanged(isChanged);
    };

    const [iHaveNoVAT, setIHaveNoVAT] = useState(false);
    const [isIHaveNoVATChanged, setIHaveNoVATChanged] = useState(false);
    const handleIHaveNoVATChange = (event) => {
        const isChanged = event.value !== customerMasterData.iHaveNoVAT;
        setIHaveNoVATChanged(isChanged);
        setIHaveNoVAT(event.value);

        if (!event.value) {
            setTimeout(() => {
                document.getElementById('vatNumber').focus();
            });
            setTimeout(() => {
                document.getElementById('vatNumber').blur();
            }, 10);
        }
    };

    useEffect(() => {
        const areChanges = getAreChanges();
        props.setAreChangesOnBilling(areChanges);

        if (props.onBillingInfoChanged) {
            props.onBillingInfoChanged(areChanges);
        }

    }, [isIHaveNoVATChanged, isBillingAddressCityChanged,
        isBillingAddressCompanyNameChanged, isBillingAddressStreetNumberChanged,
        isBillingAddressPostalCodeChanged, isBillingAddressStreetChanged, isBillingEmailAddressChanged,
        isBillingInfoPurchaseOrderNumberChanged, isCountryChanged,
        isVatNumberChanged, isBillingInfoPurchaseOrderDateChanged
    ]);

    const getAreChanges = () => {
        return isIHaveNoVATChanged || isBillingAddressCityChanged ||
            isBillingAddressCompanyNameChanged || isBillingAddressStreetNumberChanged ||
            isBillingAddressPostalCodeChanged || isBillingAddressStreetChanged || isBillingEmailAddressChanged ||
            isBillingInfoPurchaseOrderNumberChanged || isCountryChanged ||
            isVatNumberChanged || isBillingInfoPurchaseOrderDateChanged;
    };

    const isDisabledSaveBillingInfo = (formRenderProps) => {
        return !formRenderProps.allowSubmit || !formRenderProps.valid ||
            !(isIHaveNoVATChanged || isBillingAddressCityChanged || isBillingAddressCompanyNameChanged ||
                isBillingAddressStreetNumberChanged || isBillingAddressPostalCodeChanged ||
                isBillingAddressStreetChanged || isBillingEmailAddressChanged ||
                isBillingInfoPurchaseOrderNumberChanged || isCountryChanged
                || isVatNumberChanged || isBillingInfoPurchaseOrderDateChanged);
    };

    const getSaveTooltip = (formRenderProps) => {
        if (!formRenderProps.valid) {
            return intl.formatMessage({ id: 'Control.MissingMandatoryFields' });
        }
        return '';
    };

    return (
        <>
            <Prompt
                when={getAreChanges()}
                message={JSON.stringify({
                    header: "" + intl.formatMessage({ id: 'Confirm.UnsavedChangesTitle' }),
                    content: "" + intl.formatMessage({ id: 'Confirm.UnsavedChangesText' }),
                    cancel: "" + intl.formatMessage({ id: 'Control.Cancel' }),
                    confirm: "" + intl.formatMessage({ id: 'Control.Confirm' }),
                    confirmFunc: true
                })}
            />
            <div className="content">
                <Row id="masterdata">
                    <Col xs="12">
                        <Form
                            onSubmit={handleSubmitBillingInfo}
                            initialValues={customerMasterData}
                            key={JSON.stringify(customerMasterData) + "billingInfo"}
                            render={(formRenderProps) => (
                                <FormElement>
                                    <Card>
                                        <CardHeader>
                                            <h3>
                                                <FormattedMessage id='MasterData.BillingInfo' />
                                                <div className="float-right">
                                                    <ReactTooltip id="saveTooltip" place="top" type="error" backgroundColor='#41b6e6' effect="solid" />
                                                    <span
                                                        data-for="saveTooltip"
                                                        data-tip={getSaveTooltip(formRenderProps)}
                                                    >
                                                        <Button
                                                            type={"submit"}
                                                            color="primary"
                                                            disabled={isDisabledSaveBillingInfo(formRenderProps)}
                                                        >
                                                            <FormattedMessage id='Control.Save' />
                                                        </Button>
                                                    </span>
                                                </div>
                                            </h3>
                                        </CardHeader>
                                        <CardBody>
                                            <Row >
                                                <Col xs="12" lg="6">
                                                    <Row>
                                                        <Col xs="12">
                                                            <fieldset className={"k-form-fieldset"}>
                                                                <div className="mb-3">
                                                                    <Field
                                                                        name={"billingAddressCompanyName"}
                                                                        label={intl.formatMessage({ id: 'MasterData.CompanyName' })}
                                                                        component={Input}
                                                                        validator={billingAddressCompanyNameValidator}
                                                                        maxLength={200}
                                                                        onChange={handleBillingAddressCompanyNameChange}
                                                                    />
                                                                </div>
                                                            </fieldset>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs="8">
                                                            <fieldset className={"k-form-fieldset"}>
                                                                <div className="mb-3">
                                                                    <Field
                                                                        name={"billingAddressStreet"}
                                                                        label={intl.formatMessage({ id: 'MasterData.Street' })}
                                                                        component={Input}
                                                                        validator={billingAddressStreetValidator}
                                                                        maxLength={200}
                                                                        onChange={handleBillingAddressStreetChange}
                                                                    />
                                                                </div>
                                                            </fieldset>
                                                        </Col>
                                                        <Col xs="4">
                                                            <fieldset className={"k-form-fieldset"}>
                                                                <div className="mb-3">
                                                                    <Field
                                                                        name={"billingAddressStreetNumber"}
                                                                        label={intl.formatMessage({ id: 'MasterData.No' })}
                                                                        component={Input}
                                                                        validator={billingAddressStreetNumberValidator}
                                                                        maxLength={10}
                                                                        onChange={handleBillingAddressStreetNumberChange}
                                                                    />
                                                                </div>
                                                            </fieldset>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs="4">
                                                            <fieldset className={"k-form-fieldset"}>
                                                                <div className="mb-3">
                                                                    <Field
                                                                        name={"billingAddressPostalCode"}
                                                                        label={intl.formatMessage({ id: 'MasterData.PostalCode' })}
                                                                        component={Input}
                                                                        validator={billingAddressPostalCodeValidator}
                                                                        maxLength={10}
                                                                        onChange={handleBillingAddressPostalCodeChange}
                                                                    />
                                                                </div>
                                                            </fieldset>
                                                        </Col>
                                                        <Col xs="8">
                                                            <fieldset className={"k-form-fieldset"}>
                                                                <div className="mb-3">
                                                                    <Field
                                                                        name={"billingAddressCity"}
                                                                        label={intl.formatMessage({ id: 'MasterData.City' })}
                                                                        component={Input}
                                                                        validator={billingAddressCityValidator}
                                                                        maxLength={200}
                                                                        onChange={handleBillingAddressCityChange}
                                                                    />
                                                                </div>
                                                            </fieldset>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs="12">
                                                            <fieldset className={"k-form-fieldset"}>
                                                                <div className="mb-3 country">
                                                                    <Field
                                                                        id={"billingAddressCountry"}
                                                                        name={"billingAddressCountry"}
                                                                        label={intl.formatMessage({ id: 'MasterData.Country' })}
                                                                        component={FormAutoComplete}
                                                                        data={billingCountries}
                                                                        validator={countryValidator}
                                                                        maxLength={200}
                                                                        onChange={handleCountryChange}
                                                                    />
                                                                </div>
                                                            </fieldset>
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                <Col xs="12" lg="6">
                                                    {isInEU &&
                                                        <>
                                                            <Row>
                                                                <Col xs="6">
                                                                    <fieldset className={"k-form-fieldset"}>
                                                                        <div className="mb-3">
                                                                            <Field
                                                                                id={"vatNumber"}
                                                                                name={"vatNumber"}
                                                                                label={intl.formatMessage({ id: 'MasterData.VatNumber' }) + (iHaveNoVAT ? '' : ' *')}
                                                                                secondLabel={countryCode}
                                                                                component={FormInput}
                                                                                validator={vatNumberValidator}
                                                                                maxLength={20}
                                                                                onChange={handleVatNumberChange}
                                                                                disabled={iHaveNoVAT}
                                                                            />
                                                                        </div>
                                                                    </fieldset>
                                                                </Col>
                                                                <Col xs="6">
                                                                    <fieldset className={"k-form-fieldset"}>
                                                                        <div className="mb-3 no-vat-check">
                                                                            <Field
                                                                                id={"iHaveNoVAT"}
                                                                                name={"iHaveNoVAT"}
                                                                                label={intl.formatMessage({ id: 'MasterData.IHaveNoVATIdentificationNumber' })}
                                                                                component={FormCheckbox}
                                                                                onChange={handleIHaveNoVATChange}
                                                                            />
                                                                        </div>
                                                                    </fieldset>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    }
                                                    <Row>
                                                        <Col xs="12">
                                                            <fieldset className={"k-form-fieldset"}>
                                                                <div className="mb-3">
                                                                    <Field
                                                                        name={"billingEmailAddress"}
                                                                        label={intl.formatMessage({ id: 'MasterData.BillingEmailAddress' })}
                                                                        component={Input}
                                                                        validator={billingEmailAddressValidator}
                                                                        maxLength={256}
                                                                        onChange={handleBillingEmailAddressChange}
                                                                    />
                                                                </div>
                                                            </fieldset>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs="6">
                                                            <fieldset className={"k-form-fieldset"}>
                                                                <div className="mb-3">
                                                                    <Field
                                                                        name={"billingInfoPurchaseOrderNumber"}
                                                                        label={intl.formatMessage({ id: 'MasterData.PurchaseOrderNumber' })}
                                                                        component={Input}
                                                                        validator={""}
                                                                        maxLength={200}
                                                                        onChange={handleBillingInfoPurchaseOrderNumberChange}
                                                                    />
                                                                </div>
                                                            </fieldset>
                                                        </Col>
                                                        <Col xs="6">
                                                            <fieldset className={"k-form-fieldset"}>
                                                                <div className="mb-3">
                                                                    <Field
                                                                        name={"billingInfoPurchaseOrderDate"}
                                                                        label={intl.formatMessage({ id: 'MasterData.PurchaseOrderDate' })}
                                                                        locale={props.locale}
                                                                        language={props.language}
                                                                        component={FormDatePicker}
                                                                        validator={""}
                                                                        onChange={handleBillingInfoPurchaseOrderDateChange}
                                                                    />
                                                                </div>
                                                            </fieldset>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </FormElement>
                            )}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default BillingTab
