import * as React from "react";

export const PopulationsCell = (props) => {
    return (
        <td>
            {props.dataItem.warehouseLocations && props.dataItem.warehouseLocations.map(warehouseLocation => {
                return (
                    <div key={warehouseLocation.warehouseLocationId}>
                        <div>{warehouseLocation.warehouseLocationName}
                            {warehouseLocation.populations && warehouseLocation.populations.map(population => {
                                return (
                                    <div key={population.populationId}>
                                        <small>
                                            {population.populationName}
                                        </small>
                                    </div>
                                );
                            })}
                        </div>

                    </div>
                )

            }
            )
            }
        </td>
    );
};