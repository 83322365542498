import React from 'react';
import { Progress } from "reactstrap";

const ProgressIndicator = (props) => {
    return <div>
        <p>{props.statusMessage}</p>
        <Progress value={props.progressPercent} />
        <p>{props.progressPercent.toFixed()}%</p>
    </div>;
}

export default ProgressIndicator

