import React from 'react';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {
    CardTitle, Form, FormGroup, Label, Col, Row
} from 'reactstrap';
import axios from 'axios';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getProgramLockState, resetInventory } from '../../services/inventoryService';
import {
    UserContext,
    GetStartingInventoryAllowedBySubscriptionTooltip,
    IsStartingInventoryAllowedBySubscription,
    RefreshUserContext
} from '../UserContext';
import { setLastUsedPopulationId } from '../../services/dashboardService';
import { useLoading } from '../Shared/LoadingContext';
import { useNotification } from '../Shared/Notifications/NotificationProvider';
import { useExceptionDialog } from '../Shared/ExceptionDialog/ExceptionDialogProvider';
import { NotificationTypes } from '../Shared/Notifications/Notification';
import ConfirmDialog from '../Shared/ConfirmDialog';
import { getLocale, formatNumber } from '../../utils/localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faHistory,
    faBackward,
    faSearch,
    faPlay,
    faPencilAlt,
    faWhistle
} from '@fortawesome/pro-light-svg-icons'

export default function Population(props) {

    const { setLoading } = useLoading();
    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const pageId = 'Dashboard.Population';

    const userProfile = useSelector(state => state.profile.profile);
    const locale = getLocale(userProfile.userLanguage);
    const cancelTokenSource = axios.CancelToken.source();
    const intl = useIntl();
    const [programLockState, setProgramLockState] = React.useState(props.population?.programLockState ?? window.enumProgramLockState.Unknown);
    const isStartingInventoryAllowedBySubscription = IsStartingInventoryAllowedBySubscription();
    const getStartingInventoryAllowedBySubscriptionTooltip = GetStartingInventoryAllowedBySubscriptionTooltip();
    let history = useHistory();
    const userContext = React.useContext(UserContext);
    const userWithAdminPriviledges = userContext.userGlobal.userInfo?.isStatcontrolAdmin || userContext.userGlobal.userInfo?.isAdminUser;
    const userWithControllerPriviledges = userContext.userGlobal.userInfo?.isController;
    const userWithEditorPriviledges = userContext.userGlobal.userInfo?.isEditor;
    const userWithOperatorPriviledges = userContext.userGlobal.userInfo?.isOperator;

    const [isConfirmDialogVisible, setIsConfirmDialogVisible] = React.useState(false);

    const handleError = (errorMessage, showNotif = true) => {
        setLoading(false);
        if (showNotif) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            });
        }
    };

    const refreshUserContextForInventory = RefreshUserContext(handleError, () => {
        history.push({
            pathname: '/inventory/inventory/' + props.warehouse?.warehouseLocationId
        });
    });

    const refreshUserContextForReports = RefreshUserContext(handleError, () => {
        history.push({
            pathname: 'inventory/reports/' + props.population?.populationId
        });
    });

    React.useEffect(() => {
        props.population.warehouseLocationId = props.warehouse.warehouseLocationId;
        props.population.warehouseLocationName = props.warehouse.warehouseLocationName;

        getProgramLockState(props.population?.populationId,
            data => {
                if (data.succeeded) {
                    setProgramLockState(data.data.programLockState);
                }
                else {
                    console.log('Program lock state cannot be determined!');
                }
            },
            error => {
                console.log('Error reading lock state:', error);
            },
            cancelTokenSource.token
        );
        return () => { cancelTokenSource.cancel(); };
    }, []);

    const inventoryLinkTooltipByLockState = programLockState > window.enumProgramLockState.ProgramNotInitialized
        ? intl.formatMessage({ id: 'Population.ContinueInventory' })
        : intl.formatMessage({ id: 'Population.StartInventory' });

    const inventoryLinkTooltipByEditorRole = () => {
        if (userWithEditorPriviledges) {
            return inventoryLinkIconDisabled
                ? intl.formatMessage({ id: 'Population.CountingNotYetStarted' })
                : intl.formatMessage({ id: 'Population.EnterCountedInventoryPositions' });
        }

        return inventoryLinkTooltipByLockState;
    };

    const inventoryLinkTooltip = () => {
        if (getStartingInventoryAllowedBySubscriptionTooltip) {
            return getStartingInventoryAllowedBySubscriptionTooltip;
        }

        if (userWithControllerPriviledges) {
            if (inventoryLinkIconDisabled) {
                return programLockState > window.enumProgramLockState.ProgramNotInitialized
                    ? intl.formatMessage({ id: 'Population.InventoryNotYetCompleted' })
                    : intl.formatMessage({ id: 'Population.InventoryNotYetStarted' });
            }

            return intl.formatMessage({ id: 'Population.ViewInventoryResult' });
        }

        return inventoryLinkTooltipByEditorRole();
    };

    const reportsLinkTooltip = intl.formatMessage({ id: 'Reports.Title' });

    const resetLinkTooltip = intl.formatMessage({ id: 'Control.ResetInventory' });

    const InventoryLinkIconByLockState = programLockState > window.enumProgramLockState.ProgramNotInitialized
        ? <FontAwesomeIcon icon={faPlay} />
        : <FontAwesomeIcon icon={faWhistle} />

    const inventoryLinkIconByEditorRole = userWithEditorPriviledges
        ? <FontAwesomeIcon icon={faPencilAlt} />
        : InventoryLinkIconByLockState;

    const inventoryLinkIcon = userWithControllerPriviledges
        ? <FontAwesomeIcon icon={faSearch} />
        : inventoryLinkIconByEditorRole;

    const inventoryLinkIconDisabledByLockState = programLockState > window.enumProgramLockState.ProgramNotInitialized
        ? false
        : !isStartingInventoryAllowedBySubscription;

    const inventoryLinkIconDisabledByEditorRole = userWithEditorPriviledges
        ? programLockState < window.enumProgramLockState.CountListCreated
        : inventoryLinkIconDisabledByLockState;

    const inventoryLinkIconDisabled = userWithControllerPriviledges
        ? programLockState < window.enumProgramLockState.FeedbackCompletedTotal
        : inventoryLinkIconDisabledByEditorRole;

    const reportsAllowedForRole = userWithAdminPriviledges || userWithControllerPriviledges || userWithOperatorPriviledges;
    const reportsLinkIconVisible = reportsAllowedForRole && programLockState >= window.enumProgramLockState.InitialDataLoaded;
    const isResetInventoryVisible = reportsLinkIconVisible && userWithAdminPriviledges;

    const formatLastStockItemImportDate = () => {
        return props.population.lastStockItemImportDate ? (new Date(props.population.lastStockItemImportDate)).getFullYear().toString() : '';
    };

    const inventoryLinkClicked = (event) => {
        event.preventDefault();
        setLoading(true);
        setLastUsedPopulationId(
            props.population.populationId,
            _result => {
                setLoading(false);
                refreshUserContextForInventory();
            },
            handleError);
    };

    const reportsLinkClicked = (event) => {
        event.preventDefault();
        setLoading(true);
        setLastUsedPopulationId(
            props.population.populationId,
            _result => {
                setLoading(false);
                refreshUserContextForReports();
            },
            handleError);
    };

    const resetInventoryClicked = (event) => {
        event.preventDefault();
        setIsConfirmDialogVisible(true)

    };

    const handleConfirmDialogClose = (isConfirmed) => {
        setIsConfirmDialogVisible(false);
        if (isConfirmed) {
            setLoading(true);
            resetInventory(
                props.population.populationId,
                _result => {
                    setLoading(false);
                    setProgramLockState(window.enumProgramLockState.Unknown);
                },
                handleError);
        }
    };

    const getIconClass = () => {
        switch (programLockState) {
            case window.enumProgramLockState.ProgramNotInitialized:
                return "k-i-cart";
            case window.enumProgramLockState.InitialDataLoaded:
                return "k-i-marker-pin-target";
            case window.enumProgramLockState.StratumsCreated:
                return "k-i-clock";
            case window.enumProgramLockState.CountListCreated:
            case window.enumProgramLockState.FeedbackStarted:
            case window.enumProgramLockState.FeedbackCompletedRun:
                return "k-i-dollar";
            case window.enumProgramLockState.FeedbackCompletedTotal:
                return "k-i-lock";
            default:
                return "k-i-circle";
        }
    }

    const getInvetoryStepTooltip = () => {
        let numberOfSteps = 11;
        switch (programLockState) {
            case window.enumProgramLockState.ProgramNotInitialized:
                return intl.formatMessage({ id: 'Wizard.Preparation' }) + " " + intl.formatMessage({ id: 'Wizard.Step' }) + " 1 " + intl.formatMessage({ id: 'Wizard.StepOf' }) + " " + numberOfSteps;
            case window.enumProgramLockState.InitialDataLoaded:
                return intl.formatMessage({ id: 'Wizard.InventoryData' }) + " " + intl.formatMessage({ id: 'Wizard.Step' }) + " 3 " + intl.formatMessage({ id: 'Wizard.StepOf' }) + " " + numberOfSteps;
            case window.enumProgramLockState.StratumsCreated:
                return intl.formatMessage({ id: 'Wizard.PreAnalysis' }) + " " + intl.formatMessage({ id: 'Wizard.Step' }) + " 5 " + intl.formatMessage({ id: 'Wizard.StepOf' }) + " " + numberOfSteps;
            case window.enumProgramLockState.CountListCreated:
            case window.enumProgramLockState.FeedbackStarted:
            case window.enumProgramLockState.FeedbackCompletedRun:
                return intl.formatMessage({ id: 'Wizard.Counting' }) + " " + intl.formatMessage({ id: 'Wizard.Step' }) + " 8 " + intl.formatMessage({ id: 'Wizard.StepOf' }) + " " + numberOfSteps;
            case window.enumProgramLockState.FeedbackCompletedTotal:
                return intl.formatMessage({ id: 'Wizard.PostAnalysis' }) + " " + intl.formatMessage({ id: 'Wizard.Step' }) + " 11 " + intl.formatMessage({ id: 'Wizard.StepOf' }) + " " + numberOfSteps;
            default:
                return "";
        }
    }


    const reportsButton = reportsLinkIconVisible ?
        <div id="reset-history">
            {isResetInventoryVisible && (
                <>
                    <ReactTooltip id="resetLinkTip" place="left" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />
                    <button
                        data-tip={resetLinkTooltip} data-for="resetLinkTip"
                        onClick={resetInventoryClicked}
                        className="btn btn-link"
                    >
                        <FontAwesomeIcon icon={faBackward} />
                    </button>
                </>
            )}
            <ReactTooltip id="reportsLinkTip" place="top" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />
            <button
                onClick={reportsLinkClicked}
                data-tip={reportsLinkTooltip} data-for="reportsLinkTip"
                className="btn btn-link"
            >
                <FontAwesomeIcon icon={faHistory} />
            </button>
        </div>
        : '';

    return (
        <div>
            <Form>
                <FormGroup className="dash-pop">
                    <Row className="mt-2">
                        <Col xs="2">
                            {programLockState >= window.enumProgramLockState.InitialDataLoaded &&
                                <div>
                                    <ReactTooltip id="inventoryStepTip" place="right" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />
                                    <span data-tip={getInvetoryStepTooltip()} data-for="inventoryStepTip">
                                        <span className={"invetory-step-icon k-step-indicator-icon k-icon " + getIconClass()}>
                                        </span>
                                    </span>
                                </div>
                            }
                        </Col>
                        <Col xs="5">
                            <CardTitle tag="h5">{props.population.populationName}
                            </CardTitle>
                            <Label className="dash-pop">{formatLastStockItemImportDate()}</Label>
                            {!props.population.inaccessibleStatistics &&
                                <small>
                                    &nbsp;&mdash;&nbsp;
                                    <FormattedMessage
                                        id="Control.ProgressCurrentOfTotal"
                                        values={{
                                            Current: formatNumber(props.population.countedItems, locale),
                                            Total: formatNumber(props.population.drawnItems, locale)
                                        }}
                                    />
                                </small>
                            }
                        </Col>
                        <Col xs="5" className="pop-action-cell">
                            {!props.population.inaccessibleStatistics &&
                                <div className="float-right">
                                    {reportsButton}
                                    <div id="start-continue">
                                        <ReactTooltip id="inventoryLinkTip" place="right" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />
                                        <span data-tip={inventoryLinkTooltip()} data-for="inventoryLinkTip">
                                            <button
                                                onClick={inventoryLinkClicked}
                                                className="btn btn-link"
                                                disabled={inventoryLinkIconDisabled}
                                            >
                                                {inventoryLinkIcon}
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            }
                        </Col>
                    </Row>
                </FormGroup>
            </Form>
            <ConfirmDialog
                visible={isConfirmDialogVisible}
                onClose={handleConfirmDialogClose}
                negative={<FormattedMessage id="Control.Cancel" />}
                positive={<FormattedMessage id="Control.Reset" />}
                title={<FormattedMessage id="ResetInventory.ResetInventory" />}
                detail={<FormattedMessage id="ResetInventory.AreYouSureYouWantToResetThisInventory" />}
            />
        </div>
    );
}
