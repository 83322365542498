import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Card, CardBody, CardHeader } from 'reactstrap';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {
    Grid,
    GridColumn as Column,
    GridNoRecords
} from "@progress/kendo-react-grid";
import { FormattedMessage, useIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import RecycleBinActionCell from "./RecycleBinActionCell";
import ConfirmDialog from '../../Shared/ConfirmDialog';
import { useLoading } from '../../Shared/LoadingContext';
import { useExceptionDialog } from '../../Shared/ExceptionDialog/ExceptionDialogProvider';
import { useNotification } from '../../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../../Shared/Notifications/Notification';
import {
    getRecycleBinContent,
    restoreWarehouseLocation, restorePopulation, restoreAllDeletedPopulations, restoreAllDeletedWarehouseLocations,
    purgeWarehouseLocation, purgePopulation, purgeAllDeletedPopulations, purgeAllDeletedWarehouseLocations
} from "../../../services/settingsService";
import GridFooter from '../../Shared/GridFooter';
import { process } from "@progress/kendo-data-query";

const RecycleBinCard = (props) => {

    const intl = useIntl();
    const { setLoading } = useLoading();
    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const pageId = 'RecycleBin';

    const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);
    const [dataItemToDelete, setDataItemToDelete] = useState(null);
    const [deleteAllItems, setDeleteAllItems] = useState(false);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [itemsPerPage, setItemsPerPage] = React.useState(10);
    const [isPagingVisible, setIsPagingVisible] = React.useState(false);
    const [dataState, setDataState] = useState({});

    const handleConfirmDialogClose = (isConfirmed) => {
        setIsConfirmDialogVisible(false);
        if (isConfirmed) {
            if (dataItemToDelete) {
                deleteItem(dataItemToDelete);
            }
            else if (deleteAllItems) {
                performDeleteAllItems();
            }
        }
        else {
            setDeleteAllItems(false);
            setDataItemToDelete(null);
        }
    };

    const RecycleBinActionButtons = (props) => (
        <RecycleBinActionCell
            {...props}
            tooltipDelete={intl.formatMessage({ id: 'Settings.RecycleBin.Tooltip.Delete' })}
            tooltipRestore={intl.formatMessage({ id: 'Settings.RecycleBin.Tooltip.Restore' })}
            restoreItem={restoreItem}
            deleteItem={handleDeleteItemClicked}
        />
    );

    const restoreItem = (dataItem) => {
        let restoreMethod = restoreWarehouseLocation;
        if (selectedItemType != itemType.Location) {
            restoreMethod = restorePopulation;
        }
        incrementLoadingCount();
        restoreMethod(
            dataItem,
            recycleBinContent => {
                decrementLoadingCount();
                setRecycleBinContent(recycleBinContent);
            },
            handleError);
    };

    const handleDeleteItemClicked = (dataItem) => {
        setDataItemToDelete(dataItem);
        setIsConfirmDialogVisible(true);
    }

    const deleteItem = (dataItem) => {
        if (dataItem) {
            let deleteMethod = purgeWarehouseLocation;
            if (selectedItemType != itemType.Location) {
                deleteMethod = purgePopulation;
            }
            incrementLoadingCount();
            deleteMethod(
                dataItem,
                recycleBinContent => {
                    decrementLoadingCount();
                    setRecycleBinContent(recycleBinContent);
                },
                handleError);

            setDataItemToDelete(null);
        }
    };

    const performDeleteAllItems = () => {
        if (deleteAllItems) {
            let purgeMethod = purgeAllDeletedWarehouseLocations;
            if (selectedItemType != itemType.Location) {
                purgeMethod = purgeAllDeletedPopulations;
            }
            incrementLoadingCount();
            purgeMethod(
                props.customer.customerId,
                (recBinContent) => {
                    setRecycleBinContent(recBinContent);
                    decrementLoadingCount();
                    dispatchNotification({
                        type: NotificationTypes.success,
                        message: <FormattedMessage id="Settings.RecycleBin.Notification.DeleteSucceded" />
                    });
                },
                handleError);

            setDeleteAllItems(false);
        }
    };

    const restoreAllClicked = () => {
        let restoreAllMethod = restoreAllDeletedWarehouseLocations;
        if (selectedItemType != itemType.Location) {
            restoreAllMethod = restoreAllDeletedPopulations;
        }
        incrementLoadingCount();
        restoreAllMethod(
            props.customer.customerId,
            (recBinContent) => {
                decrementLoadingCount();
                setRecycleBinContent(recBinContent);
            },
            handleError
        );
    };

    const deleteAllClicked = () => {
        setDeleteAllItems(true);
        setIsConfirmDialogVisible(true);
    };

    const [loadingCount, setLoadingCount] = useState(0);
    useEffect(() => {
        setLoading(loadingCount > 0);
    }, [loadingCount]);

    const incrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount + 1);
    };

    const decrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount - 1);
    };

    const itemType = { Location: 0, Population: 1 };
    const selectionLocation = { id: itemType.Location, text: intl.formatMessage({ id: 'Settings.RecycleBin.Locations' }) }
    const selectionPopulation = { id: itemType.Population, text: intl.formatMessage({ id: 'Settings.RecycleBin.Populations' }) }
    const recycleBinItemSelectionList = [
        selectionLocation,
        selectionPopulation
    ];

    const [selectedItemType, setSelectedItemType] = useState(itemType.Location);
    const restoreAllText = <FormattedMessage id={selectedItemType == itemType.Location ? "Settings.RecycleBin.RestoreLocations" : "Settings.RecycleBin.RestorePopulations"} />;
    const deleteAllText = <FormattedMessage id={selectedItemType == itemType.Location ? "Settings.RecycleBin.DeleteLocations" : "Settings.RecycleBin.DeletePopulations"} />;
    const itemNameColumnHeader = intl.formatMessage({ id: (selectedItemType == itemType.Location ? "Settings.RecycleBin.LocationName" : "Settings.RecycleBin.PopulationName") });

    const [populations, setPopulations] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [restoreAllEnabled, setRestoreAllEnabled] = useState(false);

    const confirmDialogTitle = <FormattedMessage id="Settings.RecycleBin.ConfirmDeleteItem.Title" />;
    const confirmDialogDetail = deleteAllItems
        ? <FormattedMessage id="Settings.RecycleBin.ConfirmDeleteAllItems.Details" />
        : <FormattedMessage id="Settings.RecycleBin.ConfirmDeleteItem.Details" />;

    const deletedItems = selectedItemType == itemType.Location ? warehouses : populations;
    const [gridData, setGridData] = useState(deletedItems);
    const minGridLength = 5;

    const disabledRestoreButtonTooltip = !restoreAllEnabled && deletedItems.length > 0 ? intl.formatMessage({ id: 'Settings.RecycleBin.Tooltip.RestoreDisabled' }) : '';

    useEffect(() => {
        loadRecycleBin();
    }, [props.customer]);


    const handleError = (errorMessage, showNotif = true) => {
        decrementLoadingCount();
        if (showNotif) {
            dispatchNotification({
                type: NotificationTypes.error,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            })
        }
    };

    const setRecycleBinContent = (recycleBinContent) => {
        if (!recycleBinContent) {
            recycleBinContent = { populations: [], warehouses: [], restoreAllEnabled: false };
        }

        if (recycleBinContent.populations) {
            let items = recycleBinContent.populations.map((item, index) => ({ ...item, id: index + 1 }));
            setPopulations(items);
        }
        if (recycleBinContent.warehouseLocations) {
            let items = recycleBinContent.warehouseLocations.map((item, index) => ({ ...item, id: index + 1 }));
            setWarehouses(items);
        }
        setRestoreAllEnabled(recycleBinContent.restoreAllEnabled);
    }

    const loadRecycleBin = () => {
        incrementLoadingCount();
        getRecycleBinContent(
            props.customer.customerId,
            (data) => {
                decrementLoadingCount();
                setRecycleBinContent(data);
            },
            handleError);
    }

    const handleItemTypeChange = (event) => {
        setSelectedItemType(event.target.value.id);
    };

    const handleItemsPerPageChange = (dropDownItem) => {
        setItemsPerPage(dropDownItem.value);
    };

    useEffect(() => {
        setIsPagingVisible(gridData.length > minGridLength);
        setPageHandler(currentPage);
    }, [deletedItems, itemsPerPage]);

    const setPageHandler = (page) => {
        let startIndex = (page - 1) * itemsPerPage;
        let endIndex = (page) * itemsPerPage;
        setGridData(deletedItems.slice(startIndex, endIndex));
        if (deletedItems.length > itemsPerPage) {
            setCurrentPage(page);
        }
        else {
            setCurrentPage(1);
        }
    }

    const deletedItemsGrid =
        <Grid
            data={process(gridData, dataState)}
            {...dataState}
            onDataStateChange={(e) => {
                setDataState(e.dataState);
            }}>
            <GridNoRecords>
                {<FormattedMessage id="Settings.RecycleBin.EmptyRecycleBin" />}
            </GridNoRecords>
            <Column field="id" title="ID" width="40px" />
            <Column field={selectedItemType == itemType.Location ? "warehouseLocationName" : "populationName"} title={itemNameColumnHeader} />
            {selectedItemType != itemType.Location ? <Column field="warehouseLocationName" title={intl.formatMessage({ id: 'Settings.RecycleBin.LocationName' })} width="*" /> : ''}
            <Column field={"purgingInDays"} title={intl.formatMessage({ id: 'Settings.RecycleBin.PurgingInDays' })} />
            <Column cell={RecycleBinActionButtons} filterable={false} width="200px" />
        </Grid>

    return (
        <div>
            <Card>
                <CardHeader>
                    <h3 className="recycle-header"><FormattedMessage id="Settings.RecycleBin.Title" /> &nbsp;
                        <div className="header-customer-selection">
                            <small>
                                <DropDownList
                                    data={recycleBinItemSelectionList}
                                    textField="text"
                                    dataItemKey="id"
                                    value={recycleBinItemSelectionList[selectedItemType]}
                                    onChange={handleItemTypeChange}
                                />
                            </small>
                        </div>
                        <div className="pull-right">
                            <small>
                                <FormattedMessage id="Settings.RecycleBin.PurgeTerm" />
                            </small>
                        </div>
                    </h3>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col xs="12">
                            {deletedItemsGrid}
                            {isPagingVisible && (
                                <GridFooter
                                    itemsPerPage={itemsPerPage}
                                    handleItemsPerPageChange={handleItemsPerPageChange}
                                    dataLength={deletedItems.length}
                                    setPageHandler={setPageHandler}
                                    currentPage={currentPage}
                                />
                            )}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <span className="k-form-separator"></span>
            <div className="float-right">
                <ReactTooltip id="buttonTip" place="top" type="error" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />
                <span data-tip={disabledRestoreButtonTooltip} data-for="buttonTip">
                    <Button color="secondary" disabled={!restoreAllEnabled} onClick={restoreAllClicked}>
                        {restoreAllText}
                    </Button>
                </span>
                <Button color="primary" disabled={!(populations && populations.length > 0)} onClick={deleteAllClicked}>{deleteAllText}</Button>
            </div>

            <ConfirmDialog
                visible={isConfirmDialogVisible}
                onClose={handleConfirmDialogClose}
                negative={<FormattedMessage id='Control.Cancel' />}
                positive={<FormattedMessage id='Control.Confirm' />}
                title={confirmDialogTitle}
                detail={confirmDialogDetail}
            />

        </div>
    );
}

export default RecycleBinCard
