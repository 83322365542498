import { get, post, postWithPopulationIdHeader } from './serviceBase';

const baseUrl = '/api/v1.0/admin/WarehouseManagement';
const baseUrlPopulation = '/api/v1.0/admin/PopulationManagement';

export function getUserInfo(successCallback, errorCallback) {
    const url = `${baseUrl}/GetUserInfo`;
    get(url, successCallback, errorCallback);
}

export function getWarehouseLocations(customerId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('customerId', customerId);
    const url = `${baseUrl}/GetWarehouseLocations?` + params;
    get(url, successCallback, errorCallback);
}

export function addWarehouseLocation(wareHouse, successCallback, errorCallback) {
    const url = `${baseUrl}/AddWarehouseLocation`;
    post(url, wareHouse, successCallback, errorCallback);
}

export function updateWarehouseLocation(wareHouse, successCallback, errorCallback) {
    const url = `${baseUrl}/UpdateWarehouseLocation`;
    post(url, wareHouse, successCallback, errorCallback);
}

export function getPopulations(warehouseLocationId, successCallback, errorCallback, cancelToken) {
    const params = new URLSearchParams();
    params.append('warehouseLocationId', warehouseLocationId);
    const url = `${baseUrl}/GetPopulations?` + params;
    get(url, successCallback, errorCallback, cancelToken);
}

export function addPopulation(population, successCallback, errorCallback) {
    const url = `${baseUrl}/AddPopulation`;
    post(url, population, successCallback, errorCallback);
}

export async function updatePopulation(population, successCallback, errorCallback) {
    const url = `${baseUrlPopulation}/UpdatePopulation`;
    return postWithPopulationIdHeader(url, population.populationId, population, successCallback, errorCallback);
}

export function deleteWarehouseLocation(warehouseLocationId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    if (warehouseLocationId) {
        params.append('warehouseLocationId', warehouseLocationId);
    }
    const url = `${baseUrl}/DeleteWarehouseLocation?` + params;
    get(url, successCallback, errorCallback);
}

export function deletePopulation(populationId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    if (populationId) {
        params.append('populationId', populationId);
    }
    const url = `${baseUrl}/DeletePopulation?` + params;
    get(url, successCallback, errorCallback);
}

export function getTimeZones(successCallback, errorCallback) {
    const url = `${baseUrl}/GetTimeZones`;
    get(url, successCallback, errorCallback);
}
