import * as React from "react";
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faTrashAlt
} from '@fortawesome/pro-light-svg-icons'

export const CustomerActionCell = (props) => {
    const [dataItem, setDataItem] = React.useState(props.dataItem);

    React.useEffect(() => {
        setDataItem(props.dataItem);
    }, [props.dataItem]);

    const deleteMethod = () => {
        if (props.dataItem?.deleteMethod) {
            props.dataItem.deleteMethod(dataItem);
        }
        console.log('deleteMethod was not provided!');
    };

    return (
        <td>
            <Button
                color="link"
                onClick={deleteMethod}
            >
                <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
        </td>
    );

}

export default CustomerActionCell
