import * as React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { useIntl } from "react-intl";

export const DropdownFilterCell = (props) => {
    const intl = useIntl();

    let hasValue = (value) => Boolean(value && value !== props.defaultItem);

    const onChange = (event) => {
        hasValue = hasValue(event.target.value);
        props.onChange({
            value: hasValue ? event.target.value : '',
            operator: hasValue ? 'eq' : '',
            syntheticEvent: event.syntheticEvent
        });
    };

    const onClearButtonClick = (event) => {
        event.preventDefault();
        props.onChange({
            value: '',
            operator: '',
            syntheticEvent: event
        });
    };

    let otherProps = {};
    if (props.defaultItem) {
        otherProps.defaultItem = props.defaultItem;
    }

    return (
        <div className="filterDropDownCell">
            <div className="k-filtercell">
                <div className="k-filtercell-wrapper">
                    <DropDownList
                        data={props.data}
                        textField={props.textField}
                        dataItemKey={props.dataItemKey}
                        onChange={onChange}
                        value={props.value || props.defaultItem}
                        {...otherProps}
                    />
                    {hasValue(props.value) && (
                        <div className="k-filtercell-operator">
                            <Button
                                className="k-button k-button-icon k-clear-button-visible"
                                title={intl.formatMessage({ id: "Control.Clear" })}
                                onClick={onClearButtonClick}
                                icon="filter-clear"
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
