import React, { useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomerSelection from '../../Dashboard/CustomerSelection';
import { Col, Row } from 'reactstrap';
import LocationGrid from "./LocationGrid";
import { getWarehouseLocations } from '../../../services/warehouseLocationsService';
import { WL_ACTIONS, WL_Reducer } from './services';
import { useLoading } from "../../Shared/LoadingContext";
import { useNotification } from '../../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../../Shared/Notifications/Notification';
import { FormattedMessage } from 'react-intl';
import { storeCustomer } from '../../../actions/dashboardActions';
import { useExceptionDialog } from '../../Shared/ExceptionDialog/ExceptionDialogProvider';
import { UserContext } from '../../UserContext';
import { useLocation, useParams } from "react-router-dom";

const WarehouseManagement = () => {
    const { setLoading } = useLoading();
    const [warehouseLocationsReducer, dispatchWL] = useReducer(WL_Reducer, []);
    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const pageId = 'WarehouseManagement';
    const location = useLocation()
    const selectedWarehouseLocation = location.state?.selectedWarehouseLocation
    const currentCustomer = useSelector(state => state.dashboard.customer);
    const dispatch = useDispatch();
    const { add } = useParams();
    let addWarehouseDialogOpened = add;
   
    const userContext = React.useContext(UserContext);

    useEffect(() => {
        return () => {
            removeNotification();
        };
    }, []);

    React.useEffect(() => {
        if (!currentCustomer && userContext.userGlobal.userInfo.accessibleCustomers && userContext.userGlobal.userInfo.accessibleCustomers.length > 0) {
            const defaultCustomer = userContext.userGlobal.userInfo.accessibleCustomers.find(x => x.customerId === userContext.userGlobal.userInfo.customerId);
            if (defaultCustomer) {
                dispatch(storeCustomer(defaultCustomer));
            }
        }
    }, [userContext.userGlobal.userInfo]);

    const [loadingCount, setLoadingCount] = React.useState(0);
    React.useEffect(() => {
        setLoading(loadingCount > 0);
    }, [loadingCount]);

    const incrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount + 1);
    };

    const decrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount - 1);
    };

    useEffect(() => {
        if (currentCustomer == null)
            return;
        incrementLoadingCount();

        getWarehouseLocations(
            currentCustomer.customerId,
            items => {
                decrementLoadingCount();
                
                items = items.map(item => (
                    {
                        ...item,
                        changed: false
                    }));
                dispatchWL({ type: WL_ACTIONS.Set, payload: { items: items } });
            },
            handleError);
    }, [currentCustomer]);

    const handleError = (errorMessage, showNotif = true) => {
        decrementLoadingCount();
        if (showNotif) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            });
        }
    };

    const removeNotification = () => {
        dispatchNotification({
            remove: true,
            pageId: pageId
        });
    };

    const handleCustomerChanged = (cust) => {
        dispatch(storeCustomer(cust));
    };

    return (
        <div className="content">
            <Row>
                <Col xs="12">
                    <h1>
                        <FormattedMessage id='WarehouseManagement.WarehouseManagement' /> {" "}
                        {userContext.userGlobal.userInfo && (
                            <CustomerSelection
                                customer={currentCustomer}
                                customers={userContext.userGlobal.userInfo.accessibleCustomers}
                                isStatcontrolAdmin={userContext.userGlobal.userInfo.isStatcontrolAdmin}
                                onCustomerChanged={handleCustomerChanged}
                            />
                        )}
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <LocationGrid
                        customerId={currentCustomer ? currentCustomer.customerId : null}
                        warehouseLocations={warehouseLocationsReducer}
                        selectedWarehouseLocation={selectedWarehouseLocation}
                        dispatchWL={dispatchWL}
                        incrementLoadingCount={incrementLoadingCount}
                        decrementLoadingCount={decrementLoadingCount}
                        addWarehouseDialogOpened={addWarehouseDialogOpened}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default WarehouseManagement