import React, { useState, useContext } from 'react';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { UserContext } from '../UserContext';
import { getLocale } from '../../utils/localization';
import ReportFilters from './ReportFilters';
import ActivityHistory from './ActivityHistory';
import ReportsHistory from './ReportsHistory';

export const reportsEvents = {
    reportsFilterChanged: "reports:filterChanged"
}

const Reports = function () {
    const selectedPopulation = useSelector(state => state.dashboard.population);
    const userProfile = useSelector(state => state.profile.profile);
    const userContext = useContext(UserContext);
    const userWithAdminPriviledges = userContext.userGlobal.userInfo && (userContext.userGlobal.userInfo.isStatcontrolAdmin || userContext.userGlobal.userInfo.isAdminUser);
    const isReportsAllowed = userContext.userGlobal.userInfo?.isController || userContext.userGlobal.userInfo?.isOperator;
    const locale = getLocale(userProfile.userLanguage);

    const [selectedTab, setSelectedTab] = useState(0);

    const handleSelect = e => {
        setSelectedTab(e.selected);
    };

    const selectedPopulationId = selectedPopulation?.populationId;

    return (
        isReportsAllowed || userWithAdminPriviledges ?
            <div className="content">
                <ReportFilters />
                <TabStrip selected={selectedTab} onSelect={handleSelect}>
                    <TabStripTab title={<FormattedMessage id='Reports.PreAnalysis' />} >
                        <ReportsHistory populationId={selectedPopulationId} locale={locale}
                            title={<FormattedMessage id='Reports.PreAnalysis.Title' />}
                        />
                    </TabStripTab>
                    <TabStripTab title={<FormattedMessage id='Reports.PostAnalysis' />} >
                        <ReportsHistory populationId={selectedPopulationId} locale={locale} postAnalysis={true}
                            title={<FormattedMessage id='Reports.PostAnalysis.Title' />}
                        />
                    </TabStripTab>
                    <TabStripTab title={<FormattedMessage id='Reports.ActivityHistory' />} >
                        <ActivityHistory populationId={selectedPopulationId} />
                    </TabStripTab>
                </TabStrip>
            </div>
        : <div/>
    )
}

export default Reports
