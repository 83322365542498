import React, { createContext, useEffect } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { storeCustomer, selectPopulation } from "../actions/dashboardActions";
import { loadState, saveState } from "../reducers/browser-storage";
import { getUserInfo } from "../services/dashboardService";
import { useLoading } from "./Shared/LoadingContext";

export const UserContext = createContext({
    userInfo: {}
});

export const USERCONTEXT_ACTIONS = {
    SetUserInfo: 'SetUserInfo',
    RemoveUserInfo: 'RemoveUserInfo',
    LoadFromLocalStorage: 'LoadFromLocalStorage',
}

const localStorageKey = "UserInfo";

export function UserReducer(state, action) {
    switch (action.type) {
        case USERCONTEXT_ACTIONS.SetUserInfo:
            {
                let currentState = { ...state };
                currentState.userInfo = action.payload;
                saveState(currentState, localStorageKey);
                return currentState;
            }
        case USERCONTEXT_ACTIONS.LoadFromLocalStorage:
            {
                return loadState(localStorageKey);
            }
        case USERCONTEXT_ACTIONS.RemoveUserInfo:
            {
                return {
                    userInfo: {}
                };
            }
        default:
            return state
    }
}

export function getLSUserInfo() {
    return loadState(localStorageKey);
}

export function removeLSUserInfo() {
    return saveState({}, localStorageKey);
}

export function RefreshUserContext(handleError, successAction) {
    const dispatch = useDispatch();
    const { setLoading } = useLoading();
    const userContext = React.useContext(UserContext);
    const currentCustomer = useSelector(state => state.dashboard.customer);

    return () => {
        setLoading(true);
        getUserInfo(
            userInfo => {
                setLoading(false);
                if (userInfo) {
                    userContext.dispatchUserGlobal({
                        type: USERCONTEXT_ACTIONS.SetUserInfo,
                        payload: userInfo
                    });

                    let defaultCustomer = null;
                    if (userInfo.lastUsedPopulation) {
                        dispatch(selectPopulation(userInfo.lastUsedPopulation));
                        defaultCustomer = userInfo.accessibleCustomers.find(c => c.customerId === userInfo.lastUsedPopulation.customerId);
                    }

                    if (!defaultCustomer && userInfo.accessibleCustomers && userInfo.accessibleCustomers.length > 0) {
                        defaultCustomer = userInfo.accessibleCustomers[0];
                        dispatch(selectPopulation(null));
                    }

                    if (currentCustomer?.customerId === defaultCustomer.customerId) {
                        dispatch(storeCustomer({
                            ...currentCustomer,
                            ...defaultCustomer
                        }));
                    }
                    else if (!currentCustomer){
                        dispatch(storeCustomer(defaultCustomer));
                    }

                    if (successAction) {
                        successAction();
                    }
                }
                else {
                    window.location.assign("/initial-steps");
                }
            },
            handleError);
    };
}

export function canAddPopulation(customer) {
    if (!customer || !customer.activeLicense) {
        return false;
    }

    return customer.numberOfPopulations < customer.activeLicense.numberOfPopulations;
}

export function IsAddingPopulationAllowedBySubscription () {
    const currentCustomer = useSelector(state => state.dashboard.customer);
    const [isAddingPopulationAllowedBySubscription, setIsAddingPopulationAllowedBySubscription] = React.useState(true);

    useEffect(() => {
        setIsAddingPopulationAllowedBySubscription(canAddPopulation(currentCustomer));
    }, [currentCustomer]);

    return isAddingPopulationAllowedBySubscription;
}


export function GetDisabledAddPopulationButtonTooltip() {
    const intl = useIntl();
    const isAddingPopulationAllowedBySubscription = IsAddingPopulationAllowedBySubscription();

    const disabledAddPopulationButtonTooltip = () => { return (!isAddingPopulationAllowedBySubscription ? intl.formatMessage({ id: 'Population.PleaseUpgradeYourLicenseToAddAPopulation' }) : ""); };

    return disabledAddPopulationButtonTooltip();
}

export function GetDisabledAddLocationButtonTooltip() {
    const intl = useIntl();
    const isAddingPopulationAllowedBySubscription = IsAddingPopulationAllowedBySubscription();

    const disabledAddPopulationButtonTooltip = () => { return (!isAddingPopulationAllowedBySubscription ? intl.formatMessage({ id: 'Population.PleaseUpgradeYourLicenseToAddALocation' }) : ""); };

    return disabledAddPopulationButtonTooltip();
}

export function canStartInventory(customer) {
    if (!customer || !customer.activeLicense) {
        return false;
    }

    return customer.numberOfPositions < customer.activeLicense.numberOfPositions;
}

export function IsStartingInventoryAllowedBySubscription() {
    const currentCustomer = useSelector(state => state.dashboard.customer);
    const [isStartingInventoryAllowedBySubscription, setIsStartingInventoryAllowedBySubscription] = React.useState(true);

    useEffect(() => {
        setIsStartingInventoryAllowedBySubscription(canStartInventory(currentCustomer));
    }, [currentCustomer]);

    return isStartingInventoryAllowedBySubscription;
}

export function GetStartingInventoryAllowedBySubscriptionTooltip() {
    const intl = useIntl();
    const isStartingInventoryAllowedBySubscription = IsStartingInventoryAllowedBySubscription();

    const tooltip = () => { return (!isStartingInventoryAllowedBySubscription ? intl.formatMessage({ id: 'ManageLicense.PleaseUpgradeYourLicenseToUploadFurtherPositions' }) : ""); };

    return tooltip();
}
