import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import SigninOidc from './components/signin-oidc'
import SignoutOidc from './components/signout-oidc'
import Home from './components/Home'
import Login from './components/Login'
import Register from './components/Register'
import InitialSteps from './components/Administration/InitialSteps';
import WarehouseManagement from "./components/Administration/Warehouse Management/WarehouseManagement";
import UserManagement from "./components/Administration/User Management/UserManagement";
import Settings from "./components/Administration/Settings/Settings";
import MasterData from "./components/Administration/Master Data/MasterData";
import Config from "./components/Administration/Config/Config";
import Inventory from './components/Inventory/Inventory';
import Reports from './components/Reports/Reports';
import Help from './components/Help/Help';
import { Provider } from 'react-redux';
import { Layout } from './components/Layout';
import store from './store';
import { loadUserFromStorage, createUserManager } from './services/userService'
import AuthProvider from './utils/authProvider'
import PrivateRoute from './utils/protectedRoute'
import '@progress/kendo-theme-bootstrap/dist/all.css'
import './assets/styles/app.scss'
import { LoadingProvider } from "./components/Shared/LoadingContext";
import NotificationProvider from "./components/Shared/Notifications/NotificationProvider";
import ExceptionDialogProvider from "./components/Shared/ExceptionDialog/ExceptionDialogProvider";
import SupportTools from './components/Support/SupportTools';
import { CookiesProvider } from 'react-cookie';
import { debounce, ReduxKEY, saveState } from './reducers/browser-storage';
import UserLeaveConfirmation from './components/Shared/UserLeaveConfirmation';
import ReleaseNotes from './components/ReleaseNotes';
import ConfigList from './components/Administration/Config/ConfigList';


function App() {

    const [scriptConstantsEvaluated, setScriptsConstantsEvaluated] = useState(false);
    const [scriptEnumsEvaluated, setScriptsEnumEvaluated] = useState(false);
    const [userManager, setUserManager] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(true);
    const allScriptsEvaluated = scriptConstantsEvaluated && scriptEnumsEvaluated;

    useEffect(() => {

        function buildElementScriptForSharedEnums() {
            const script = document.createElement("script");
            script.onload = function () {
                setScriptsEnumEvaluated(true);
            };
            script.src = "/api/v1.0/EnumDispatcher/AllSharedEnums.js";
            script.async = false;
            return script;
        }

        function buildElementScriptForGlobalConstants() {
            const script = document.createElement("script");
            script.onload = function () {
                setScriptsConstantsEvaluated(true);
                let um = createUserManager();
                setUserManager(um);
                loadUserFromStorage(store, um);
            };
            script.src = "/api/v1.0/ConstantDispatcher/GlobalConstants.js";
            script.async = false;
            return script;
        }

        let enumsScript = buildElementScriptForSharedEnums();
        let constantsScript = buildElementScriptForGlobalConstants();

        document.head.prepend(constantsScript);
        document.head.prepend(enumsScript);

        return () => {
            document.head.removeChild(enumsScript);
            document.head.removeChild(constantsScript);
        }
    }, [])


    store.subscribe(debounce(() => {
        saveState(store.getState(), ReduxKEY);
    }, 1000));

    return (
        allScriptsEvaluated && userManager ?
            <CookiesProvider>
                <Provider store={store}>
                    <AuthProvider userManager={userManager} store={store}>
                        <LoadingProvider>
                            <ExceptionDialogProvider>
                                <NotificationProvider>
                                    <Router
                                        getUserConfirmation={(message, callback) =>
                                            UserLeaveConfirmation(message, callback, confirmOpen, setConfirmOpen)}
                                    >
                                        <Switch>
                                            <Route exact path="/login" component={Login} />
                                            <Route path="/login/:fromAutomaticLogout" component={Login} />
                                            <Route path="/register" component={Register} />
                                            <PrivateRoute path="/initial-steps" component={InitialSteps} />
                                            <Route path="/signout-oidc" component={SignoutOidc} />
                                            <Route path="/signin-oidc" component={SigninOidc} />

                                            <Layout>
                                                <PrivateRoute exact path="/" component={Home} />
                                                <Route exact path='/inventory/inventory' component={Inventory} />
                                                <Route path='/inventory/inventory/:warehouseLocationId' component={Inventory} />
                                                <Route exact path='/inventory/reports' component={Reports} />
                                                <Route path='/inventory/reports/:populationId' component={Reports} />
                                                <Route exact path='/administration/warehousemanagement' component={WarehouseManagement} />
                                                <Route path='/administration/warehousemanagement/:add' component={WarehouseManagement} />
                                                <Route path='/administration/usermanagement' component={UserManagement} />
                                                <Route exact path='/administration/settings' component={Settings} />
                                                <Route path='/administration/settings/:tab' component={Settings} />
                                                <Route path='/administration/masterdata' component={MasterData} />
                                                <Route path='/administration/configlist' component={ConfigList} />
                                                <Route path='/administration/config/:interfaceConfigurationId/:selectedTabIndex?/:expanded?' component={Config} />
                                                <Route path='/help' component={Help} />
                                                <Route path='/support-tools' component={SupportTools} />
                                                <Route path='/release-notes' component={ReleaseNotes} />
                                            </Layout>

                                        </Switch>
                                    </Router>
                                </NotificationProvider>
                            </ExceptionDialogProvider>
                        </LoadingProvider>
                    </AuthProvider>
                </Provider >
            </CookiesProvider>
            : <div />
    );
}

export default App;
