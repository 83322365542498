import React, { useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom'
import { Row, Col } from 'reactstrap';
import Wizard from './Wizard/Wizard';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { UserContext } from '../UserContext';
import { getInventoryHeaderInfo, setLastUsedPopulation } from '../../services/inventoryService';
import { selectPopulation, storeCustomer } from '../../actions/dashboardActions';
import { useExceptionDialog } from '../Shared/ExceptionDialog/ExceptionDialogProvider';

const Inventory = function () {
    const pageId = 'pageInventory';
    const cancelTokenSource = axios.CancelToken.source();

    const selectedPopulation = useSelector(state => state.dashboard.population);
    const currentCustomer = useSelector(state => state.dashboard.customer);
    const userContext = useContext(UserContext);
    const userWithAdminPriviledges = userContext.userGlobal.userInfo?.isStatcontrolAdmin || userContext.userGlobal.userInfo?.isAdminUser;

    const [headerInfo, setHeaderInfo] = React.useState({});

    const dispatch = useDispatch();
    const dispatchExceptionDialog = useExceptionDialog();

    const inventoryHeaderDataSuccess = data => {
        if (data.population) {
            dispatch(selectPopulation(data.population));
        }
        if (data.customer) {
            if (data.customer.customerId === currentCustomer?.customerId) {
                dispatch(storeCustomer({
                    ...currentCustomer,
                    ...data.customer
                }));
            }
            else {
                dispatch(storeCustomer(data.customer));
            }
        }
        setHeaderInfo(data);

        if (data.population) {
            setLastUsedPop(data.population.populationId);
        }
    }

    const loadInventoryHeaderData = () => {
        getInventoryHeaderInfo(selectedPopulation?.populationId,
            inventoryHeaderDataSuccess,
            error => {
                console.log('error getting inventory header info for current population', error);
                dispatchExceptionDialog({ pageId: pageId, message: error });
            },
            cancelTokenSource.token
        );
    }

    const setLastUsedPop = (populationId) => {
        setLastUsedPopulation(populationId,
            () => {
                console.log('last used population set:' + populationId);
            },
            error => {
                console.log('error setting last used population', error);
                dispatchExceptionDialog({ pageId: pageId, message: error });
            },
            cancelTokenSource.token
        );
    }

    useEffect(() => {
        if (currentCustomer) {
            loadInventoryHeaderData();
        }
        return () => { cancelTokenSource.cancel(); };
    }, []);

    const displayCustomerName = headerInfo.customer ? decodeURIComponent(headerInfo.customer.customerName) + ' - ' : '';
    const displayPopulationId = selectedPopulation ? selectedPopulation.populationId : '';
    const displayInfoForAdminUser = userWithAdminPriviledges ? displayCustomerName + displayPopulationId : '';
    const displayWarehouseInfo = headerInfo.warehouse ? (decodeURIComponent(headerInfo.warehouse.warehouseLocationName) + ' - ') : '';
    const displayPopulationInfo = selectedPopulation ? selectedPopulation.populationName : '';

    return (
        currentCustomer
            ? <div className="content">
                <Row className="wizard-location-name">
                    <Col xs="12">
                        <small>{displayInfoForAdminUser}</small>
                        <h3>{displayWarehouseInfo}<small>{displayPopulationInfo}</small></h3>
                    </Col>
                </Row>
                <Wizard headerInfo={headerInfo} />
            </div>
            : <Redirect to={'/login'} />
    )
}

export default Inventory
