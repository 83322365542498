import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Grid,
    GridColumn as Column,
} from "@progress/kendo-react-grid";
import { FormattedMessage, useIntl } from 'react-intl';
import { useLoading } from '../../Shared/LoadingContext';
import { useNotification } from '../../Shared/Notifications/NotificationProvider';
import { useExceptionDialog } from '../../Shared/ExceptionDialog/ExceptionDialogProvider';
import { NotificationTypes } from '../../Shared/Notifications/Notification';
import { getLicenses, getSupports, saveLicense } from '../../../services/licenseManagementService';
import { Col, Row, Card, CardBody, CardHeader } from 'reactstrap';
import { getLocale, formatNumberWithDecimals, formatNumber, formatCurrency } from '../../../utils/localization';
import ManageLicenseActionCell from './ManageLicenseActionCell';
import LicenseEditForm from './LicenseEditForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/pro-light-svg-icons'


const CustomVisibilityCell = (props) => {
    const userProfile = useSelector(state => state.profile.profile);
    const locale = getLocale(userProfile.userLanguage);

    const field = props.field || "";
    let value = props.dataItem[field];
    switch (field) {
        case 'pricePerMonthPaymentPerYear':
        case 'pricePerMonthPaymentPerMonth':
            value = formatCurrency(value, 'EUR', locale, 2);
            break;
        case 'settlementAdditionalPositionPrice':
            value = formatNumberWithDecimals(value, locale, 2);
            break;
        case 'numberOfPositions':
        case 'numberOfPopulations':
        case 'minimumTerm':
            value = formatNumber(value, locale);
            break;
        case 'description':
            value = value != null && value.length > 20 ? value.substring(0, 20) + '...' : value;
            break;
    }

    let isVisibleField = '';
    switch (field) {
        case 'subscriptionTypeName':
            isVisibleField = 'isLicenseVisible';
            break;
        case 'supportName':
            isVisibleField = 'isSupportVisible';
            break;
        case 'procedureName':
            isVisibleField = 'isProcedureVisible';
            break;
        default:
            isVisibleField = "is" + field.charAt(0).toUpperCase() + field.slice(1) + "Visible";
            break;
    }
    const valueIsVisibleField = props.dataItem[isVisibleField];

    let colClassName = "";
    if (field === "pricePerMonthPaymentPerYear" || field === "pricePerMonthPaymentPerMonth" || field === "settlementAdditionalPositionPrice"
        || field === "numberOfPositions" || field === "numberOfPopulations" || field === "minimumTerm") {
        colClassName = "text-right";
    }

    return (
        <td>
            <Row>
                <Col xs="8" className={colClassName} >
                    <div>
                        {value === null ? "" : value.toString() + "  "}
                    </div>
                </Col>
                <Col xs="4">
                    {valueIsVisibleField === null ? false : valueIsVisibleField &&
                        <FontAwesomeIcon icon={faEye} />
                    }
                </Col>
            </Row>
        </td>
    );
};


const ManageLicense = () => {
    const userProfile = useSelector(state => state.profile.profile);
    const intl = useIntl();

    const { setLoading } = useLoading();
    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const pageId = 'ManageLicense';

    const [openForm, setOpenForm] = React.useState(false);
    const [editItem, setEditItem] = React.useState({
        ProductID: 1,
    });

    useEffect(() => {
        return () => {
            removeNotification();
        };
    }, []);

    const [supports, setSupports] = useState([]);
    useEffect(() => {
        incrementLoadingCount();
        getSupports(
            values => {
                decrementLoadingCount();
                setSupports(values);
            },
            handleError);
    }, []);

    const getTranslatedProcedureText = procVal => {
        let translated = '';
        if (procVal != null) {
            translated = intl.formatMessage({ id: 'ManageLicense.Procedure_' + procVal });
        }
        return translated;
    };

    const procedures = Object.keys(window.enumProcedure).map(procedure => { return { text: getTranslatedProcedureText(window.enumProcedure[procedure]), value: window.enumProcedure[procedure] } });

    useEffect(() => {
        if (!supports || supports.length === 0
            || !procedures || procedures.length === 0) {
            return;
        }

        loadLicenses();
    }, [supports]);

    const [licenses, setLicenses] = useState([]);
    const loadLicenses = () => {
        removeNotification();
        incrementLoadingCount();
        getLicenses(
            null,
            licenseInfos => {
                decrementLoadingCount();
                let data = translateData(licenseInfos);
                setLicenses(data);
            },
            handleError);
    };

    useEffect(() => {
        loadLicenses();
    }, [userProfile.userLanguage]);

    const translateData = (data) => {
        data.forEach((item, index, arr) => {
            const support = supports.find(x => item.supportId === x.value);
            arr[index].supportObj = support;

            const procedure = procedures.find(x => item.procedure === x.value);
            arr[index].procedureObj = procedure;

            arr[index].procedureName = procedure.text;
        });
        return data;
    };

    const [loadingCount, setLoadingCount] = useState(0);
    useEffect(() => {
        setLoading(loadingCount > 0);
    }, [loadingCount]);

    const incrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount + 1);
    };

    const decrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount - 1);
    };

    const handleError = (errorMessage, showNotif = true) => {
        decrementLoadingCount();
        if (showNotif) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            });
        }
    };

    const removeNotification = () => {
        dispatchNotification({
            remove: true,
            pageId: pageId
        });
    };

    const enterEdit = (item) => {
        setOpenForm(true);
        setEditItem(item);
    };

    const handleSubmit = (item) => {
        removeNotification();
        incrementLoadingCount();
        item.supportId = item.supportObj.value;
        item.procedure = item.procedureObj.value;
        saveLicense(
            item,
            () => {
                decrementLoadingCount();
                setOpenForm(false);
                loadLicenses();

                dispatchNotification({
                    pageId: pageId,
                    type: NotificationTypes.success,
                    message: <FormattedMessage id='ManageLicense.SaveLicensesSuccess' />
                });
            },
            handleError);
    };

    const handleCancelEdit = () => {
        setOpenForm(false);
    };

    const EditLicense = (props) => (
        <ManageLicenseActionCell
            {...props}
            enterEdit={enterEdit}
        />
    );

    return (
        <div>
            <Card>
                <CardHeader>
                    <h3><FormattedMessage id='ManageLicense.DefaultSubscription' /></h3>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col xs="12">
                            <Grid
                                data={licenses.map((item) => ({
                                    ...item
                                }))}
                                editField="inEdit"
                                className="manage-license"
                            >
                                <Column
                                    field="subscriptionTypeName"
                                    title={intl.formatMessage({ id: 'ManageLicense.Subscription' })}
                                    editable={false}
                                    cell={CustomVisibilityCell}
                                />

                                <Column
                                    field="pricePerMonthPaymentPerYear"
                                    title={intl.formatMessage({ id: 'ManageLicense.PricePerMonthPaymentPerYear' })}
                                    editable={false}
                                    cell={CustomVisibilityCell}
                                />

                                <Column
                                    field="numberOfPositions"
                                    title={intl.formatMessage({ id: 'ManageLicense.NumberOfPositions' })}
                                    editable={false}
                                    cell={CustomVisibilityCell}
                                />

                                <Column
                                    field="procedureName"
                                    title={intl.formatMessage({ id: 'ManageLicense.Procedure' })}
                                    editable={false}
                                    cell={CustomVisibilityCell}
                                />

                                <Column
                                    width="50px"
                                    cell={EditLicense}
                                />
                            </Grid>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            {openForm && (
                <LicenseEditForm
                    cancelEdit={handleCancelEdit}
                    onSubmit={handleSubmit}
                    item={editItem}
                    supports={supports}
                    procedures={procedures}
                />
            )}
        </div>
    );
}

export default ManageLicense
