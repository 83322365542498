import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    Row,
    Col,
    Button
} from 'reactstrap';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Switch } from "@progress/kendo-react-inputs";
import { FormattedMessage, useIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { useLoading } from '../Shared/LoadingContext';
import { useNotification } from '../Shared/Notifications/NotificationProvider';
import { useExceptionDialog } from '../Shared/ExceptionDialog/ExceptionDialogProvider';
import { NotificationTypes } from '../Shared/Notifications/Notification';
import MasterData from '../Administration/Master Data/MasterData';
import GeneralTermsDialog from '../GeneralTermsDialog';
import ConfirmDialog from '../Shared/ConfirmDialog';
import { assignLicenseToCustomer } from '../../services/licenseManagementService';
import { RefreshUserContext } from '../UserContext';
import { saveCustomerGeneralInfo } from '../../services/masterDataService';
import GtcText from '../../language/GeneralTermsAndConditions/AGB-15-04-2022.pdf';

const LicenseMasterDataDialog = (props) => {
    const intl = useIntl();

    const { setLoading } = useLoading();
    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const pageId = 'LicenseMasterData';

    const currentCustomer = useSelector(state => state.dashboard.customer);

    const refreshUserContext = RefreshUserContext();

    const handleMasterDataLoaded = (masterData) => {
        validateBillingInfo(masterData);
        validateGeneralInfo(masterData);
    };

    const [isBillingInfoValid, setIsBillingInfoValid] = useState(false);

    const validateBillingInfo = (masterData) => {
        const isCompanyNameValid = masterData.billingAddressCompanyName !== '';
        const isPostalCodeValid = masterData.billingAddressPostalCode !== '';
        const isStreetValid = masterData.billingAddressStreet !== '';
        const isCityValid = masterData.billingAddressCity !== '';
        const isCountryValid = masterData.billingAddressCountry !== '';
        const isStreetNoValid = masterData.billingAddressStreetNumber !== '';
        const isEmailValid = masterData.billingEmailAddress !== '';
        setIsBillingInfoValid(isCompanyNameValid && isPostalCodeValid && isStreetValid && isCityValid && isCountryValid && isStreetNoValid && isEmailValid);
    };

    const [isGeneralInfoValid, setIsGeneralInfoValid] = useState(false);

    const validateGeneralInfo = (masterData) => {
        const isCompanyNameValid = masterData.companyName !== '';
        const isStreetValid = masterData.street !== '';
        const isStreetNumberValid = masterData.streetNumber !== '';
        const isPostalCodeValid = masterData.postalCode !== '';
        const isCityValid = masterData.city !== '';
        const isCountryValid = masterData.country !== '';
        setIsGeneralInfoValid(isCompanyNameValid && isStreetValid && isStreetNumberValid && isPostalCodeValid && isCityValid && isCountryValid);
    };

    const handleBillingInfoSaved = (billingInfo) => {
        setIsBillingInfoValid(true);

        if (!isGeneralInfoValid) {
            setConfirmDialogTitle(<FormattedMessage id='LicenseMasterData.SaveCustomerAddressConfirmationTitle' />);
            setConfirmDialogDetail(<FormattedMessage id='LicenseMasterData.SaveCustomerAddressConfirmationDetail' />);
            setConfirmDialogPositive(<FormattedMessage id='Control.Save' />);
            setConfirmDialogCallback({
                success: () => handleSaveCustomerGeneralInfo(billingInfo)
            });
            setIsConfirmDialogVisible(true);
        }
    };

    const [areUnsavedChanges, setAreUnsavedChanges] = useState(false);

    const handleBillingInfoChanged = (areChanges) => {
        setAreUnsavedChanges(areChanges);
    };

    const [gtcChecked, setGtcChecked] = useState(false);
    const [showGTCDialog, setShowGTCDialog] = useState(false);

    const gtcClicked = () => {
        setShowGTCDialog(true);
    };

    const closeGTCDialog = () => {
        setShowGTCDialog(false);
    };

    const handleBack = () => {
        props.onBack();
    };

    const handleClose = () => {
        if (areUnsavedChanges) {
            setConfirmDialogTitle(<FormattedMessage id='Confirm.UnsavedChangesTitle' />);
            setConfirmDialogDetail(<FormattedMessage id='Confirm.UnsavedChangesText' />);
            setConfirmDialogPositive(<FormattedMessage id='Control.Confirm' />);
            setConfirmDialogCallback({
                success: () => {
                    props.onClose(false);
                }
            });
            setIsConfirmDialogVisible(true);
        }
        else {
            props.onClose(false);
        }
    };

    const handleBuyNow = () => {
        const data = {
            customerId: currentCustomer.customerId,
            licenseId: props.licenseId,
            billingPeriod: props.billingPeriod
        };

        removeNotification();
        incrementLoadingCount();
        assignLicenseToCustomer(
            data,
            () => {
                decrementLoadingCount();
                dispatchNotification({
                    pageId: pageId,
                    type: NotificationTypes.success,
                    message: <FormattedMessage id='LicenseMasterData.LicenseAcquired' />
                });
                refreshUserContext(handleError);
                props.onClose(true);
            },
            handleError);
    };

    const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);
    const [confirmDialogTitle, setConfirmDialogTitle] = useState('');
    const [confirmDialogDetail, setConfirmDialogDetail] = useState('');
    const [confirmDialogPositive, setConfirmDialogPositive] = useState('');
    const [confirmDialogCallback, setConfirmDialogCallback] = useState(null);

    const handleConfirmDialogClose = (isConfirmed) => {
        setIsConfirmDialogVisible(false);
        if (isConfirmed) {
            if (confirmDialogCallback) {
                confirmDialogCallback.success();
            }
        }
    };

    const isBuyNowDisabled = !isBillingInfoValid || !gtcChecked || areUnsavedChanges;

    const [loadingCount, setLoadingCount] = useState(0);
    useEffect(() => {
        setLoading(loadingCount > 0);
    }, [loadingCount]);

    const incrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount + 1);
    };

    const decrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount - 1);
    };

    const handleError = (errorMessage, showNotif = true) => {
        decrementLoadingCount();
        if (showNotif) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            });
        }
    };

    const removeNotification = () => {
        dispatchNotification({
            removeAll: true
        });
    };

    const getBuyNowTooltip = () => {
        if (!isBillingInfoValid && !areUnsavedChanges) {
            return intl.formatMessage({ id: 'LicenseMasterData.BillingInfoIncomplete' });
        }

        if (areUnsavedChanges && !gtcChecked) {
            return intl.formatMessage({ id: 'LicenseMasterData.UnsavedChangesAndGtcNotAccepted' });
        }

        if (areUnsavedChanges) {
            return intl.formatMessage({ id: 'LicenseMasterData.UnsavedChanges' });
        }

        if (!gtcChecked) {
            return intl.formatMessage({ id: 'LicenseMasterData.GtcNotAccepted' });
        }

        return '';
    };

    const handleSaveCustomerGeneralInfo = (billingInfo) => {
        const generalInfo = {
            customerId: billingInfo.customerId,
            companyName: billingInfo.billingAddressCompanyName,
            street: billingInfo.billingAddressStreet,
            streetNumber: billingInfo.billingAddressStreetNumber,
            postalCode: billingInfo.billingAddressPostalCode,
            city: billingInfo.billingAddressCity,
            country: billingInfo.billingAddressCountry,
            saveCompanyNameToBilling: false
        };

        removeNotification();
        incrementLoadingCount();
        saveCustomerGeneralInfo(
            generalInfo,
            () => {
                decrementLoadingCount();
                setIsGeneralInfoValid(true);
                dispatchNotification({
                    pageId: pageId,
                    type: NotificationTypes.success,
                    message: intl.formatMessage({ id: 'MasterData.SaveGeneralInfoSuccess' })
                });
            },
            handleError);
    };

    return (
        <div>
            <Dialog
                title={<FormattedMessage id='LicenseMasterData.Title' />}
                onClose={handleClose}
                className="license-check-out"
            >
                <MasterData
                    isGeneralInfoVisible={false}
                    isBillingInfoVisible={true}
                    isLicenseInfoVisible={false}
                    isStandalone={true}
                    onMasterDataLoaded={handleMasterDataLoaded}
                    onBillingInfoSaved={handleBillingInfoSaved}
                    onBillingInfoChanged={handleBillingInfoChanged}
                />

                <Row className="float-left">
                    <Col xs="12">
                        <Switch
                            onChange={(event) => { setGtcChecked(event.target.value) }}
                            checked={gtcChecked}
                            onLabel={intl.formatMessage({ id: 'Control.Yes' })}
                            offLabel={intl.formatMessage({ id: 'Control.No' })}
                        />
                        <span>
                            <FormattedMessage id='Control.GtcText' />
                            <Link className="info-color" to={GtcText} target="_blank">
                                <FormattedMessage id='Control.Gtc' />
                            </Link>
                        </span>
                    </Col>
                </Row>

                <DialogActionsBar>
                    <div className="k-form-buttons k-justify-content-stretch">
                        <Button
                            color="secondary"
                            onClick={handleBack}
                        >
                            <FormattedMessage id='Control.Previous' />
                        </Button>

                        <ReactTooltip id="buttonTooltip" place="top" type="error" backgroundColor='#41b6e6' effect="solid" />
                        <span
                            data-for="buttonTooltip"
                            data-tip={getBuyNowTooltip()}
                        >
                            <Button
                                color="primary"
                                disabled={isBuyNowDisabled}
                                onClick={handleBuyNow}
                            >
                                <FormattedMessage id='LicenseMasterData.BuyNow' />
                            </Button>
                        </span>
                    </div>
                </DialogActionsBar>
            </Dialog>

            <GeneralTermsDialog
                visible={showGTCDialog}
                onClose={closeGTCDialog}
            />

            <ConfirmDialog
                visible={isConfirmDialogVisible}
                onClose={handleConfirmDialogClose}
                negative={<FormattedMessage id='Control.Cancel' />}
                positive={confirmDialogPositive}
                title={confirmDialogTitle}
                detail={confirmDialogDetail}
            />
        </div>
    );
};

export default LicenseMasterDataDialog;
