import React, { useState } from "react";
import {
    Notification
} from "@progress/kendo-react-notification";

import { NotificationActions } from "./NotificationProvider"


export const NotificationTypes = {
    none: "none",
    success: "success",
    error: "error",
    warning: "warning",
    info: "info"
};

const NotificationCtrl = props => {
    const [exit, setExit] = useState(false);
    const [width, setWidth] = useState(0);
    const [intervalID, setIntervalID] = useState(null);

    //onMouseLeave = { handleStartTimer }
    const handleStartTimer = () => {
        const id = setInterval(() => {
            setWidth(prev => {
                if (prev < 100) {
                    return prev + 0.5;
                }

                clearInterval(id);
                return prev;
            });
        }, 20);

        setIntervalID(id);
    };

    //onMouseEnter={handlePauseTimer}
    const handlePauseTimer = () => {
        clearInterval(intervalID);
    };

    const handleCloseNotification = () => {
        handlePauseTimer();
        setExit(true);
        setTimeout(() => {
            props.dispatch({
                type: NotificationActions.REMOVE_NOTIFICATION,
                id: props.id
            })
        }, 10)
    };

    React.useEffect(() => {
        if (width === 100) {
            handleCloseNotification()
        }
    }, [width])

    React.useEffect(() => {
        //handleStartTimer();
    }, []);


    return (
        <div>
            <Notification

                type={{
                    style: props.type,
                    icon: true,
                }}
                closable={true}
                onClose={handleCloseNotification}
            >
                {props.isHtml ? <div dangerouslySetInnerHTML={{ __html: props.message }} /> : props.message}
                
                
            </Notification>
        </div>
    );
};

export default NotificationCtrl;
