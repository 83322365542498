import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import Device from './Device';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';

const DeviceList = (props) => {
    const onAddDevice = () => {
        if (props.onAddDevice) {
            props.onAddDevice();
        }
    };

    const handleDrop = (deviceToken) => {
        if (props.onDrop) {
            props.onDrop(deviceToken);
        }
    };

    const handleShowDeviceCountListDetails = (deviceId) => {
        if (props.onShowDeviceCountListDetails) {
            props.onShowDeviceCountListDetails(deviceId);
        }
    };

    return (
        <Card>
            <CardHeader>
                <h3>
                    <FormattedMessage id="DeviceList.Devices" />
                    <div className="float-right">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={onAddDevice}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </div>
                </h3>
            </CardHeader>
            <CardBody>
                {props.devices && props.devices.map(device =>
                    <Device
                        key={device.id}
                        device={device}
                        totalCount={props.totalCount}
                        onDrop={handleDrop}
                        onEditDevice={props.onShowEditDevice}
                        onShowDeviceCountListDetails={handleShowDeviceCountListDetails}
                    />
                )}
            </CardBody>
        </Card>
    );
}

export default DeviceList
