import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Card, CardBody, Row, Col, FormGroup } from 'reactstrap';
import { Upload } from '@progress/kendo-react-upload';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { importUploadedFileData } from '../../../../services/countService';
import {
    uploadInventoryFileChunk, finalizeInventoryFileChunkUpload, getProgramLockState
} from '../../../../services/inventoryService';
import { useNotification } from '../../../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../../../Shared/Notifications/Notification';
import ProgressIndicator from '../../../Shared/ProgressIndicator'
import { useExceptionDialog } from '../../../Shared/ExceptionDialog/ExceptionDialogProvider';
import { wizardEvents } from '../Wizard';
import { on, off } from "../../../../actions/events";
import { v4 as uuidv4 } from "uuid";
import { WizzardContext, WIZZARD_ACTIONS } from "../WizzardContext";
import { useLoading } from "../../../Shared/LoadingContext";
import LongRunningBackgroundTaskIndicatorDialog from '../../../Shared/LongRunningBackgroundTaskIndicatorDialog';
import { getColumnSeparators, MINIMUM_REQUIRED_COLUMN_COUNT } from '../Stock Data/StockData';

const note = <span>Only CSV or .dat files are allowed.</span>;
const FILE_SLICE_SIZE_KB = 5;
const FILE_UPLOAD_CHUNK_SIZE_MB = 1;

const ImportCounting = (props) => {
    const pageId = 'pageImportCounting';
    const selectedPopulation = useSelector(state => state.dashboard.population);
    const currentCustomer = useSelector(state => state.dashboard.customer);

    const { setLoading } = useLoading();
    const dispatchNotification = useNotification();
    const notifyError = (errorMessage) => dispatchNotification({
        type: NotificationTypes.error,
        pageId: pageId,
        message: errorMessage
    });

    const wizzardContext = React.useContext(WizzardContext);
    const dispatchExceptionDialog = useExceptionDialog();

    const [showProgress, setShowProgress] = useState(false)
    const [progress, setProgress] = useState(0)
    const [progressMessage, setProgressMessage] = useState(0)
    const [fileSize, setFileSize] = useState(0)
    const [fileGuid, setFileGuid] = useState("")
    const [fileToBeUpload, setFileToBeUpload] = useState({})

    const chunkSize = 1048576 * FILE_UPLOAD_CHUNK_SIZE_MB;
    const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0)
    const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize)
    const [chunkCount, setChunkCount] = useState(0)
    const [chunkCounter, setChunkCounter] = useState(1)
    const [selectedFile, setSelectedFile] = React.useState(wizzardContext.wizzardGlobal.Counting?.CurrentImportFile?.importFile ??
        {
        importFile: {},
        columnHeaders: []
    });
    const [defaultInterfaceConfiguration, setDefaultInterfaceConfiguration] = React.useState(null);
    React.useEffect(() => {
        setDefaultInterfaceConfiguration(wizzardContext.wizzardGlobal.InterfaceConfiguration);
    }, []);
    const uploadRef = useRef();
    
    const handleFileSelected = e => {
        let target = e.target;
        if (target.state.files.length > 0) {
            let fr = new FileReader();
            fr.onloadend = fileLoadEnded;

            // need only the first line of file containing column names, so read only the first slice big enough to contain the first line
            let blob = target.state.files[0].getRawFile().slice(0, 1024 * FILE_SLICE_SIZE_KB);
            fr.readAsText(blob);
            fileUpload(chunkCounter);
        }
    };

    const fileLoadEnded = (evt) => {
        let currentSeparator =
            defaultInterfaceConfiguration?.importCountQuantities?.columnSeparator !== window.enumColumnSeparator.None ? ";" : "";
        if (defaultInterfaceConfiguration?.importCountQuantities) {
            let columnSeparators = getColumnSeparators(defaultInterfaceConfiguration.importCountQuantities.customColumnSeparator);

            if (defaultInterfaceConfiguration.importCountQuantities.columnSeparator !== window.enumColumnSeparator.None) {
                currentSeparator = columnSeparators
                    .find(x => x.value === defaultInterfaceConfiguration.importCountQuantities.columnSeparator)
                    .textValue;
            }
        }

        if (evt.target.readyState === FileReader.DONE) {
            let columnHeaders = [];
            let needHeaders = currentSeparator && currentSeparator.length > 0;
            if (needHeaders) {
                columnHeaders = evt.target.result.split('\r\n')[0].split(currentSeparator)
                    .map((headerText, index) => ({ ColumnName: headerText, ColumnNo: index, selected: false }));
            }          

            if (!needHeaders || columnHeaders.length >= MINIMUM_REQUIRED_COLUMN_COUNT) {
                let importFile = { importFile: uploadRef.current.state.files[0].getRawFile(), columnHeaders: columnHeaders };
                wizzardContext.dispatchWizzardGlobal({ type: WIZZARD_ACTIONS.SetImportFile, payload: { ImportFile: importFile } });
                setSelectedFile(importFile);
            }
            else {
                resetFileSelection();
                notifyError(<FormattedMessage id="StockData.WrongFileFormat" />);
            }
        }
    }

    const uploadCountingCountFile = React.useCallback((event) => {
        uploadFile();
    }, [])

    const uploadFile = useCallback(() => {
        const resetChunkProperties = () => {
            setShowProgress(true)
            setProgressMessage(<FormattedMessage id='Counting.WaitForUploadCountQuantitiesFile' />)
            setProgress(0)
            setChunkCounter(1)
            setBeginingOfTheChunk(0)
            setEndOfTheChunk(chunkSize)
        }

        resetChunkProperties();
        const _filesize = wizzardContext.wizzardGlobal.Counting.CurrentImportFile.importFile.size || 0;
        setFileSize(_filesize);
        const _totalCount = _filesize % chunkSize === 0 ? _filesize / chunkSize : Math.floor(_filesize / chunkSize) + 1;
        setChunkCount(_totalCount)
        setFileToBeUpload(wizzardContext.wizzardGlobal.Counting.CurrentImportFile.importFile)
        if (_filesize > 0) {
            const _fileID = uuidv4() + "." + wizzardContext.wizzardGlobal.Counting.CurrentImportFile.importFile.name.split('.').pop();
            setFileGuid(_fileID)
        }
    }, [chunkSize, wizzardContext.wizzardGlobal.Counting.CurrentImportFile]);

    React.useEffect(() => {
        on(wizardEvents.countingImportButtonClicked, uploadCountingCountFile);

        return () => {
            off(wizardEvents.countingImportButtonClicked, uploadCountingCountFile);
        }
    }, [uploadCountingCountFile]);

    const resetFileSelection = () => {
        setSelectedFile({ importFile: {}, columnHeaders: [] });
        uploadRef.current.setState({ ...uploadRef.current.state, files: [] });
        wizzardContext.dispatchWizzardGlobal({ type: WIZZARD_ACTIONS.SetImportFile, payload: {} });
    }

    const handleFileRemoved = e => {
        resetFileSelection();
    };

    useEffect(() => {
        if (fileSize > 0 && chunkCounter <= chunkCount) {
            fileUpload(chunkCounter);
        }
    }, [chunkCounter, fileSize, chunkCount]);

    const getCurrentProgramLockState = () => {
        setLoading(true);
        getProgramLockState(selectedPopulation && selectedPopulation.populationId,
            data => {
                setLoading(false);
                if (data.succeeded) {
                    console.log('ProgramLockState:', data.data.programLockState);
                    wizzardContext.dispatchWizzardGlobal({
                        type: WIZZARD_ACTIONS.SetProgramLockState,
                        payload: {
                            ProgramLockState: window.enumProgramLockState.ImportCountQuantitiesStarted,
                            BackgroundTaskStatus: window.enumBackgroundTaskStatus.ImportCountQuantitiesStarted,
                            IsBackgroundTaskRunning: data.data.isBackgroundTaskRunning,
                            IsBackgroundTaskFinishedWithError: data.data.isBackgroundTaskFinishedWithError,
                            LastError: data.data.lastError,
                            FirstMissingFeedbackPageNr: data.data.firstMissingFeedbackPageNr,
                            WaitingForErp: data.data.waitingForErp,
                            CountingOption: data.data.countingOption
                        }
                    });
                    if (data.data.isBackgroundTaskFinishedWithError) {
                        notifyError(importStatusMessage(window.enumBackgroundTaskStatus.ImportCountQuantitiesFailed));
                    }
                }
                else {
                    notifyError(<FormattedMessage id="Wizard.Error.ProgramLockState" />);
                }
            },
            error => {
                setLoading(false);
                notifyError(<FormattedMessage id="Wizard.Error.ProgramLockState" />);
                console.log('error', error);
                dispatchExceptionDialog({ pageId: pageId, message: error });
                wizzardContext.dispatchWizzardGlobal({ type: WIZZARD_ACTIONS.SetProgramLockState, payload: { ProgramLockState: window.enumProgramLockState.Unknown, BackgroundTaskStatus: window.enumBackgroundTaskStatus.NoBackgroundTaskRunning } });
            }
        );
    }

    const importUploadedData = async () => {
        setLoading(true);
        const response = await importUploadedFileData(fileGuid, wizzardContext.wizzardGlobal.Counting.CurrentImportFile.importFile.name, selectedPopulation ? selectedPopulation.populationId : null);
        setLoading(false);
        const data = response.data;
        if (!data.succeeded) {
            notifyError(<FormattedMessage id='Counting.CountQuantitiesFileImportError' />);
            if (data.errors)
                data.errors.map(item => console.log(item));
            return { succeeded: false }
        }
        else {
            getCurrentProgramLockState();
            return { succeeded: true }
        }
    }

    const uploadCompleted = async () => {
        setLoading(true);
        const response = await finalizeInventoryFileChunkUpload(fileGuid, currentCustomer.customerId, selectedPopulation.populationId);
        setLoading(false);
        const data = response.data;
        if (data.succeeded) {
            return importUploadedData();
        }
        else {
            if (data.errors)
                data.errors.map(item => console.log(item));
            return { succeeded: false }
        }
    }

    const uploadChunk = async (chunk, counter) => {
        try {
            setLoading(true);
            const response = await uploadInventoryFileChunk(chunk, counter, fileGuid, selectedPopulation.populationId);
            setLoading(false);
            const data = response.data;
            if (data.succeeded) {
                let percentage = (counter / chunkCount) * 100;
                setProgress(percentage);
                setBeginingOfTheChunk(endOfTheChunk);
                setEndOfTheChunk(endOfTheChunk + chunkSize);
                if (counter === chunkCount) {
                    console.log('Counting file upload is completed, chunk count:', counter);
                    const importResult = await uploadCompleted();
                    if (importResult.succeeded) {
                        console.log('Uploaded Counting file processing is successfully completed');
                    }
                    return importResult;
                }
                return { succeeded: true };
            } else {
                console.log('Error Occurred:', data.message);
                if (data.errors)
                    data.errors.map(item => console.log(item));
                return { succeeded: false };
            }
        } catch (error) {
            setLoading(false);
            console.log('error', error);
            dispatchExceptionDialog({ pageId: pageId, message: error });

            notifyError(<FormattedMessage id="Counting.Error.FileUpload" />);
            resetFileSelection();

            return { succeeded: false };
        }
    }

    const fileUpload = async (counter) => {
        if (counter <= chunkCount) {
            let chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
            const result = await uploadChunk(chunk, counter);
            if (result.succeeded) {
                if (counter < chunkCount)
                    setChunkCounter(counter + 1);
            }
        }
    }

    const importInProgress = wizzardContext.wizzardGlobal.Import.IsBackgroundTaskRunning;

    const statusMessageFromBackgroundTaskStatus = () => {
        return <FormattedMessage id='Counting.ImportStatus.DataImportInProgress' />;
    }

    const importStatusMessage = (importStatus) => {
        let text = null;
        switch (importStatus) {
            case window.enumBackgroundTaskStatus.ImportCountQuantitiesStarted:
                text = (<FormattedMessage id='Counting.ImportStatus.DataImportInProgress' />);
                break;
            case window.enumBackgroundTaskStatus.ImportCountQuantitiesSucceeded:
                text = (<FormattedMessage id='Counting.ImportStatus.DataImportSucceeded' />);
                break;
            case window.enumBackgroundTaskStatus.ImportCountQuantitiesFailed:
                text = (<FormattedMessage id='Counting.ImportStatus.DataImportFailed' />);
                break;
            case window.enumBackgroundTaskStatus.PostAnalysisReportGenerationStarted:
                text = (<FormattedMessage id='Counting.ImportStatus.ReportGenerationInProgress' />);
                break;
            case window.enumBackgroundTaskStatus.PostAnalysisReportGenerationSucceeded:
                text = '';
                break;
            case window.enumBackgroundTaskStatus.PostAnalysisReportGenerationFailed:
                text = (<FormattedMessage id='Counting.ImportStatus.ReportGenerationFailed' />);
                break;
            case window.enumBackgroundTaskStatus.WaitingToStart:
                text = (<FormattedMessage id='ImportResult.ImportStatus.WaitingToStart' />);
                break;
            case window.enumBackgroundTaskStatus.NoBackgroundTaskRunning:
            default:
                text = '';
        }
        return text;
    }

    const importTaskFinished = countImportStatus => {
        let successState = window.enumBackgroundTaskStatus.ImportCountQuantitiesSucceeded;
        let success = countImportStatus === successState;

        if (success) {
            props.goToCounting();
        }
        else {
            wizzardContext.dispatchWizzardGlobal({
                type: WIZZARD_ACTIONS.SetProgramLockState,
                payload: {
                    ...wizzardContext.wizzardGlobal.Import,
                    IsBackgroundTaskRunning: false,
                    IsBackgroundTaskFinishedWithError: false,
                }
            });

            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: importStatusMessage(countImportStatus)
            });
        }
    }

    return (
        <div>
            <Row className="wizard-content">
                <Col xs='9'>
                    <Upload
                        ref={uploadRef}
                        batch={false}
                        multiple={false}
                        defaultFiles={selectedFile && selectedFile.name ? [selectedFile] : []}
                        withCredentials={false}
                        autoUpload={false}
                        showActionButtons={false}
                        accept={'.csv, .dat, .txt'}
                        saveUrl={""}
                        removeUrl={""}
                        customNote={note}
                        onAdd={handleFileSelected}
                        onRemove={handleFileRemoved}
                        className="wizard-upload"
                    />
                </Col>
                <Col xs='3'>
                    <Card className='card-chart'>
                        <CardBody>
                            <FormattedMessage id='ImportCounting.DataDescription' />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs='12'>
                    <FormGroup style={{ display: showProgress ? "block" : "none" }}>
                        <ProgressIndicator progressPercent={progress} statusMessage={progressMessage} />
                    </FormGroup>
                </Col>
            </Row>

            {importInProgress &&
                <LongRunningBackgroundTaskIndicatorDialog visible={importInProgress}
                    title={<FormattedMessage id='Counting.ImportInProgress' />}
                    waitMessage={<FormattedMessage id='Counting.ImportStatus.Wait' />}
                    openingStatusText={statusMessageFromBackgroundTaskStatus()}
                    statusChangeTextProvider={importStatusMessage}
                    finalStates={[
                        window.enumBackgroundTaskStatus.NoBackgroundTaskRunning,
                        window.enumBackgroundTaskStatus.ImportCountQuantitiesSucceeded,
                        window.enumBackgroundTaskStatus.ImportCountQuantitiesFailed,
                        window.enumBackgroundTaskStatus.ImportCancelled
                    ]}
                    onTaskFinishedCallback={importTaskFinished}
                    buttons={[
                        {
                            disabled: true,
                            text: <FormattedMessage id='Control.Cancel' />,
                            //onClick: cancelButtonClicked,
                            //onClickText: <FormattedMessage id='Counting.WaitForCancel' />
                        }
                    ]}
                    links={[
                        {
                            text: <FormattedMessage id='Sidebar.Dashboard' />,
                            to: '/'
                        }
                    ]}
                />
            }

        </div>
    );
};

export default ImportCounting