import * as React from "react";
import { useIntl } from "react-intl";
import ReactTooltip from "react-tooltip";
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faTimes,
    faSave,
    faPencilAlt,
    faLockAlt,
    faLockOpenAlt,
    faTrashAlt
} from '@fortawesome/pro-light-svg-icons'
import { UserContext } from "../../UserContext";

export const UserManagementActionCell = (props) => {
    const [dataItem, setDataItem] = React.useState(props.dataItem);
    const inEdit = dataItem[props.editField];
    const isNewItem = dataItem.id === undefined;
    const intl = useIntl();
    const userContext = React.useContext(UserContext);

    React.useEffect(() => {
        setDataItem(props.dataItem);
    }, [props.dataItem]);

    const getTooltipMessageLockingNotAllowed = () => {
        const activeLockTooltipText = dataItem.enabled ? intl.formatMessage({ id: "UserManagement.LockUser" }) : intl.formatMessage({ id: "UserManagement.UnlockUser" });
        return props.disabled || dataItem.email === userContext.userGlobal?.userInfo?.userName
            ? intl.formatMessage({ id: "UserManagement.YouCannotLockYourOwnUser" })
            : activeLockTooltipText;
    };

    const canChangeRoles = () => {
        if (!dataItem.isAdmin)
            return true;
        return props.users.some((item) => item.id !== dataItem.id && item.isAdmin) ? true : false;
    }

    const disabledDeleteButtonTooltip = () => {

        if (dataItem.email === userContext.userGlobal.userInfo.userName)
            return intl.formatMessage({ id: "UserManagement.YouCannotDeleteYourOwnUser" })
        if (props.users.length === 1)
            return intl.formatMessage({ id: "UserManagement.CustomerHasToHaveAtLeastOneUser" })
        if (!canChangeRoles())
            return intl.formatMessage({ id: "UserManagement.AtLeastOneUserMustHaveAnAdminRole" })
    }

    const deleteDataTip = intl.formatMessage({ id: 'Control.Delete' });

    const isSameUser = () => {
        return !userContext.userGlobal || !userContext.userGlobal.userInfo || !userContext.userGlobal.userInfo.userName || dataItem.email === userContext.userGlobal.userInfo.userName
    }


    const removeClick = () => {
        return props.remove(dataItem);
    }

    if (inEdit) {
        return (
            <td>
                <Button
                    color="link"
                    onClick={() =>
                        isNewItem ? props.add(dataItem) : props.update(dataItem)
                    }
                    disabled={!dataItem.changed}
                >
                    <FontAwesomeIcon icon={faSave} />
                </Button>
                <Button
                    color="link"
                    onClick={() =>
                        isNewItem ? props.discard(dataItem) : props.cancel(dataItem)
                    }
                >
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
            </td>
        );
    }
    else {
        return (
            <td>
                <ReactTooltip id="editUser" place="top" type="error" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />
                <span data-tip={intl.formatMessage({ id: "UserManagement.EditUser" })} data-for="editUser">
                    <Button
                        color="link"
                        onClick={() => props.edit(dataItem)}
                        disabled={props.disabled || !(dataItem.dataSource && dataItem.dataSource.length > 0)}
                    >
                        <FontAwesomeIcon icon={faPencilAlt} />
                    </Button>
                </span>
                <ReactTooltip id="buttonTipLocking" place="top" type="error" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />
                <span data-tip={getTooltipMessageLockingNotAllowed()} data-for="buttonTipLocking">
                    <Button
                        color="link"
                        data-for="buttonTipLocking"
                        onClick={() => props.enable(dataItem, !dataItem.enabled)}
                        disabled={props.disabled || isSameUser() || !canChangeRoles()}
                    >
                        {dataItem.enabled && <FontAwesomeIcon icon={faLockAlt} />}
                        {!dataItem.enabled && <FontAwesomeIcon icon={faLockOpenAlt} />}
                    </Button>
                </span>
                <ReactTooltip id="buttonTip" place="left" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />
                <span data-tip={disabledDeleteButtonTooltip()} data-for="buttonTip">
                    <Button
                        color="link"
                        data-tip={deleteDataTip} data-for="buttonTip"
                        onClick={removeClick}
                        disabled={props.disabled || !canChangeRoles() || props.users.length === 1 || isSameUser()}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </span>
            </td>
        );
    }
}

export default UserManagementActionCell
