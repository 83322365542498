import React, { useState, useEffect } from 'react';
import { useLoading } from "../Shared/LoadingContext";
import { useNotification } from '../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../Shared/Notifications/Notification';
import { useExceptionDialog } from '../Shared/ExceptionDialog/ExceptionDialogProvider';
import ConfirmDialog from '../Shared/ConfirmDialog';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row, Card, Button, CardBody, CardHeader, Input } from 'reactstrap';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import customers from "./customers.json";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPauseCircle,
    faBan
} from '@fortawesome/pro-light-svg-icons'


const ActionCell = (props) => {
    return (
        <td>
            <button
                className="btn btn-link"
            >
                <FontAwesomeIcon icon={faPauseCircle} />
            </button>
            <button
                className="btn btn-link"
            >
                <FontAwesomeIcon icon={faBan} />
            </button>
        </td>
    );
};

const StatusCell = (props) => {
    return (
        <td>
            <label className="label label-success">active</label>
        </td>
    );
};

export default function BlacklistCompany() {

    const CustomerActionCell = (props) => (
        <ActionCell />
    );

    const CustomerStatusCell = (props) => (
        <StatusCell />
    );

    return (
        <div className="content">
            <Card>
                <CardBody>
                    <Row>
                        <Col xs="12">
                            <Grid
                                data={customers}
                            >
                                <Column field="CustomerID" title="ID" width="40px" />
                                <Column field="CustomerName" title="Name" />
                                <Column field="CustomerSubscription" title="Subscription" />
                                <Column cell={CustomerStatusCell} title="Status" />
                                <Column cell={CustomerActionCell} width="150px" />
                            </Grid>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
}