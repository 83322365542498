import * as React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from 'reactstrap';

const ConfirmDialog = props => {
    const [visible, setVisible] = React.useState(props.visible);

    const handleClose = () => {
        if (props.onClose)
            props.onClose(false);
    };

    const handleCancel = (event) => {
        event.preventDefault();
        if (props.onCancel)
            props.onCancel();
        else if (props.onClose)
            props.onClose(false);
    };

    const handleConfirm = (event) => {
        event.preventDefault();
        if (props.onClose)
            props.onClose(true);
    };

    const handleNeutral = (event) => {
        event.preventDefault();
        if (props.onNeutral)
            props.onNeutral(true);
    };

    React.useEffect(() => {
        setVisible(props.visible);
    }, [props.visible]);

    return (
        <div>
            {visible && (
                <Dialog title={props.title} onClose={handleClose}
                    {...(props.width ? { width: props.width } : {})}
                >
                    <p className="confirm-dialog-body">
                        {(typeof props.detail === 'string' || (typeof props.detail === 'object' && !(props.detail instanceof Array))) && props.detail}
                        {props.detail instanceof Array && (
                            props.detail.map(item => (
                                <React.Fragment key={item}>
                                    {item}<br />
                                </React.Fragment>
                            ))
                        )}
                    </p>
                    {props.children}
                    <DialogActionsBar>
                        {
                            props.negative &&
                            <Button type={"submit"} color="secondary" onClick={handleCancel}>
                                {props.negative}
                            </Button>
                        }
                        {
                            props.neutral &&
                            <Button type={"submit"} color="primary" onClick={handleNeutral}>
                                {props.neutral}
                            </Button>
                        }
                        {
                            props.positive &&
                            <Button type={"submit"} color="primary" onClick={handleConfirm}
                                disabled={props.positiveDisabled ?? false}
                            >
                                {props.positive}
                            </Button>
                        }
                    </DialogActionsBar>
                </Dialog>
            )}
        </div>
    );
};

export default ConfirmDialog
