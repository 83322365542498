import React, { useEffect, useState } from "react";
import { MaskedTextBox } from "@progress/kendo-react-inputs";
import { parseCustomFloat, formatWithCustomSeparators } from '../../utils/localization';

const getEscapedSeparator = (separator) => {
    if (separator === '.') {
        return '\\.';
    }
    else if (separator === ' ') {
        return '\\s';
    }
    else {
        return separator;
    }
}

export const NumericCellWithCustomSeparators = (props, separators, additionalParams) => {

    const decSep = getEscapedSeparator(separators.decimalSeparator);
    const thSep = getEscapedSeparator(separators.groupSeparator);
    const thsepPattern = thSep.length > 0 ? thSep + '*' : '';
    const regexPattern = "^\\d{1,3}(" + thsepPattern + "\\d{3})*((" + thsepPattern + "\\d{0,3})|(" + decSep + "\\d*))$";

    const { dataItem } = props;
    const field = props.field || "";
    const dataValue = dataItem[field];
    const allowNull = additionalParams.allowEmptyEditField ? dataItem[additionalParams.allowEmptyEditField] : true;
    const key = additionalParams.keyField ? dataItem[additionalParams.keyField] : 0;

    const initialValue = formatWithCustomSeparators(dataValue, separators.decimalSeparator, separators.groupSeparator);
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        if (dataValue !== null) {
            let formVal = formatWithCustomSeparators(dataValue, separators.decimalSeparator, separators.groupSeparator);
            if (value !== formVal) {
                setValue(formVal);
            }
        }
        else {
            setValue('');
        }
    }, [dataValue]);

    const handleInputChange = (e) => {
        const val = e.target.value;

        let filteredValue = val;

        if (decSep.length > 0) {
            filteredValue = val.replace(new RegExp(`${decSep}{2,}`, 'g'), separators.decimalSeparator);
            let decSepCount = (filteredValue.match(new RegExp(decSep, 'g')) || []).length;
            if (decSepCount > 1) {
                return false;
            }
        }

        if (thSep.length > 0) {
            filteredValue = filteredValue.replace(new RegExp(`${thSep}{2,}`, 'g'), separators.groupSeparator);
        }

        let correctlyFormattedNumberCount = (filteredValue.match(new RegExp(regexPattern, 'g')) || []).length;
        if (correctlyFormattedNumberCount !== 1) {
            if (filteredValue !== '' || !allowNull) {
                return false;
            }
        }

        setValue(filteredValue);
    };

    const handleBlur = (e) => {
        if (props.onChange) {
            let dval = parseCustomFloat(value, decSep, thSep);
            let formattedVal = formatWithCustomSeparators(dval, separators.decimalSeparator, separators.groupSeparator);
            setValue(formattedVal);
            if (dval !== dataValue) {
                props.onChange({
                    dataIndex: 0,
                    dataItem: props.dataItem,
                    field: props.field,
                    syntheticEvent: e.syntheticEvent,
                    value: dval
                });
            }
        }
    };

    const textBoxClass = "right-align masked-text-" + key;

    return props &&
        <td
            colSpan={props.colSpan}
            className={props.className}
            style={props.style}
            data-grid-col-index={props.columnIndex}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
            role="gridcell"
        >
            <MaskedTextBox
                mask={''}
                value={value}
                onChange={handleInputChange}
                onBlur={handleBlur}
                className={textBoxClass} />
        </td>;

};