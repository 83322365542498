import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row, Card, CardHeader, CardBody } from 'reactstrap';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-light-svg-icons'
import { formatWithParameters } from '../../../../utils/localization';

const SequentialTestDescriptionCell = (props) => {
    return props.dataItem
        ? <td>
            {<div dangerouslySetInnerHTML={{ __html: props.dataItem[props.field] }} />}
        </td>
        : <td />
}

const SequentialTestValueCell = (props) => {
    return props.dataItem
        ? <td className="text-right">
            {props.dataItem[props.field]}
        </td>
        : <td />
}

const SequentialTestBooleanCell = (props) => {
    return props.dataItem
        ? <td className="text-left">
            {props.dataItem[props.field] && <FontAwesomeIcon icon={faCheck} />}
        </td>
        : <td />
}

export default function SequentialTestResults(props) {
    const intl = useIntl();
    const [testResultsGridData, setTestResultsGridData] = useState([]);
    const userProfile = useSelector(state => state.profile.profile);

    useEffect(() => {
        buildTestLogGridData();
    }, [props.pureStaseqReporting, userProfile.userLanguage]);

    const buildTestLogGridData = () => {
        if (props.pureStaseqReporting?.protocolOfResult?.tolerabelDefinitionLines) {
            let gridData = props.pureStaseqReporting.protocolOfResult.tolerabelDefinitionLines.map(buildGridDataLine);
            setTestResultsGridData(gridData);
        }
    }

    const buildGridDataLine = (tolerableDefinitionLine) => {
        return {
            ...tolerableDefinitionLine,
            description: buildGridDataLineDescription(tolerableDefinitionLine),
            unit: buildGridDataLineUnit(tolerableDefinitionLine),
            isCompliant: tolerableDefinitionLine.awvIdwOk
        };
    }

    const buildGridDataLineDescription = (tolerableDefinitionLine) => {
        let desc = '';
        if (tolerableDefinitionLine.description?.textKey) {
            let rawDesc = intl.formatMessage({ id: tolerableDefinitionLine.description?.textKey });
            desc = formatWithParameters(rawDesc, tolerableDefinitionLine.description?.parameter)
                .replaceAll(" ", "&nbsp;")
                .replaceAll("\n", "<br/>");
        }
        return desc;
    }

    const buildGridDataLineUnit = (tolerableDefinitionLine) => {
        let unit = '';
        if (tolerableDefinitionLine.unit?.textKey) {
            let rawUnit = intl.formatMessage({ id: tolerableDefinitionLine.unit.textKey });
            unit = formatWithParameters(rawUnit, tolerableDefinitionLine.unit?.parameter);
        }
        return unit;
    }

    return (
        <Card id="pop-tile">
            <CardHeader>
                <h3>
                    <FormattedMessage id='Reporting.SequentialTest.Results.Title' />
                </h3>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col xs="12">
                        <Grid data={testResultsGridData}
                            key="no">
                            <GridNoRecords>
                                {<FormattedMessage id="Reporting.SequentialTest.EmptySequentialTestResults" />}
                            </GridNoRecords>
                            <Column field={'description'} title={intl.formatMessage({ id: "Reporting.SequentialTest.Description" })}
                                cell={SequentialTestDescriptionCell}
                            />
                            <Column field={'value'} title={intl.formatMessage({ id: "Reporting.SequentialTest.Value" })}
                                cell={SequentialTestValueCell}
                            />
                            <Column field={'unit'} title={intl.formatMessage({ id: "Reporting.SequentialTest.Unit" })} />
                            <Column field={'isCompliant'} title={intl.formatMessage({ id: "Reporting.SequentialTest.IsCompliant" })}
                                cell={SequentialTestBooleanCell}
                            />
                        </Grid>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}