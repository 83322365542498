import * as React from "react";
import { Button } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faTrashAlt,
    faReply
} from '@fortawesome/pro-light-svg-icons'

const RecycleBinActionCell = (props) => {
    const { dataItem } = props;
    return (
        <td>
            <ReactTooltip place="top" type="error" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />
            <Button color="link" data-tip={props.tooltipRestore} onClick={() => props.restoreItem(dataItem)} disabled={!dataItem.restoreEnabled} >
                <FontAwesomeIcon icon={faReply} />
            </Button>
            <Button color="link" data-tip={props.tooltipDelete} onClick={() => props.deleteItem(dataItem)} >
                <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
        </td>
    );
}

export default RecycleBinActionCell
