import React from 'react';
import { useHistory } from 'react-router-dom';
import Population from '../Dashboard/Population';
import { Col, Row, Card, CardHeader, CardBody, Label } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import { FormattedMessage, useIntl } from 'react-intl';
import { GetDisabledAddPopulationButtonTooltip, IsAddingPopulationAllowedBySubscription } from '../UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

export default function WarehouseLocation(props) {
    const intl = useIntl();
    let history = useHistory();
    const addPopulationClicked = () => {
        history.push({
            pathname: '/administration/warehousemanagement',
            state: { selectedWarehouseLocation: props.warehouseLocation },
        });
    }
    const isAddingPopulationAllowedBySubscription = IsAddingPopulationAllowedBySubscription();
    const getDisabledAddPopulationButtonTooltip = GetDisabledAddPopulationButtonTooltip();


    return (
        <Col xs="12" lg="6">
            <Card>
                <ReactTooltip id="addPopulationTip" place="right" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />
                <CardHeader>
                    <h3>
                        {props.warehouseLocation.warehouseLocationName}
                        <div className="float-right">
                            {props.isAddingPopulationAllowed && (
                                <span data-tip={getDisabledAddPopulationButtonTooltip} data-for="addPopulationTip">
                                    <button data-tip={intl.formatMessage({ id: 'Population.AddPopulation' })} data-for="addPopulationTip"
                                        className="btn btn-secondary"
                                        onClick={addPopulationClicked}
                                        disabled={!isAddingPopulationAllowedBySubscription}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </button>
                                </span>
                            )}
                        </div>
                    </h3>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col xs="2">
                            <Label><FormattedMessage id='Settings.Progress' /></Label>
                        </Col>
                        <Col xs="5">
                            <Label><FormattedMessage id='Settings.Population' /></Label>
                        </Col>
                        <Col xs="5" className="pop-action-cell">
                        </Col>
                    </Row>
                    <Row>
                        {props.warehouseLocation.populations && props.warehouseLocation.populations.map(population =>
                            <Col xs="12" key={population.populationId + 'col'}>
                                <Population
                                    key={population.populationId}
                                    warehouse={props.warehouseLocation}
                                    population={population}
                                    language={props.language}
                                />
                            </Col>
                        )}
                    </Row>
                </CardBody>

            </Card>
        </Col>
    );
}
