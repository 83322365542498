import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { useSelector } from 'react-redux';
import { loadPdfReport, loadPdfReportInfo, getDefaultDifferencesListFileName, getDifferencesListExported, getDifferencesListGroupedExported } from '../../../../services/inventoryService';
import { downloadFile, getNewFileHandle, saveFile } from '../../../../services/commonService';
import PreviewPDF from '../../PreviewPDF';
import { useNotification } from '../../../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../../../Shared/Notifications/Notification';
import { useLoading } from "../../../Shared/LoadingContext";
import ConfirmDialog from '../../../Shared/ConfirmDialog';
import { useExceptionDialog } from '../../../Shared/ExceptionDialog/ExceptionDialogProvider';
import { FormattedMessage, useIntl } from 'react-intl';
import ReportingStatistics from './ReportingStatistics';
import ReportingStatisticsSequential from './ReportingStatisticsSequential';
import { formatNumberWithDecimals, getLocale } from '../../../../utils/localization';
import { WizzardContext } from "../WizzardContext";

const WizardTabs = (props) => {
    const pageId = 'pageInventoryWizard.Reports';
    const selectedPopulation = useSelector(state => state.dashboard.population);

    const dispatchNotification = useNotification();
    const notifyError = (errorMessage) => dispatchNotification({
        type: NotificationTypes.error,
        pageId: pageId,
        message: errorMessage
    });
    const intl = useIntl();
    const dispatchExceptionDialog = useExceptionDialog();
    const userProfile = useSelector(state => state.profile.profile);
    const locale = getLocale(userProfile.userLanguage);
    const { setLoading } = useLoading();
    const wizzardContext = React.useContext(WizzardContext);

    const currentCustomer = useSelector(state => state.dashboard.customer);
    const isCustomerWithoutLicense = currentCustomer.activeLicense.isFreeTrial;
    const isHighQuality = props.inventoryMethod === window.enumInventoryMethod.Staseq;

    const [selected, setSelected] = useState(0);

    const [fileExtrapolation, setFileExtrapolation] = useState(null);
    const [fileDifferential, setFileDifferential] = useState(null);
    const [fileDifferentialGrouped, setFileDifferentialGrouped] = useState(null);
    const [fileCountedItems, setFileCountedItems] = useState(null);
    const [fileUncountedItems, setFileUncountedItems] = useState(null);
    const [fileMultipleCountedItems, setFileMultipleCountedItems] = useState(null);
    const [fileCountlist, setFileCountlist] = React.useState(null);
    const [fileEvaluation, setFileEvaluation] = React.useState(null);
    const [fileDecisionGraph, setFileDecisionGraph] = React.useState(null);
    const [isPageCountWarningVisible, setIsPageCountWarningVisible] = useState(false);
    const [expectedPageCount, setExpectedPageCount] = useState(undefined);
    const [icTabIndexToOpen, setIcTabIndexToOpen] = useState(0);
    const decimals = 2;
    const interfaceConfigTabIndex = {
        countList : 1,
        accountingData : 3
    };

    const pdfPreviewErrorCallback = (error) => {
        setLoading(false);
        notifyError(<FormattedMessage id="Reporting.Error.Pdf.Preview" />);
        dispatchExceptionDialog({ pageId: pageId, message: error });
    }

    const exportDifferencesListErrorCallback = (error) => {
        setLoading(false);
        notifyError(<FormattedMessage id="Reporting.Error.Export.Differences" />);
        dispatchExceptionDialog({ pageId: pageId, message: error });
    }

    const withinToleranceClass = "card-free-trial-within-tolerance";
    const outsideToleranceClass = "card-free-trial-outside-tolerance";

    const loadExtrapolationReport = () => {
        if (!fileExtrapolation) {
            setLoading(true);
            loadPdfReport(
                window.enumReportType.Extrapolation,
                r => {
                    setFileExtrapolation(r);
                    setLoading(false);
                },
                pdfPreviewErrorCallback,
                selectedPopulation ? selectedPopulation.populationId : null
            );
        }
    }

    const loadDifferencesListReport = () => {
        if (!fileDifferential) {
            setLoading(true);
            loadPdfReportInfo(
                selectedPopulation ? selectedPopulation.populationId : null,
                window.enumReportType.DifferencesList,
                (file, response) => {
                    setFileDifferential(file);
                    if (response.expectedPageCount && response.renderedPageCount > response.expectedPageCount) {
                        setExpectedPageCount(response.expectedPageCount);
                        setIcTabIndexToOpen(interfaceConfigTabIndex.accountingData);
                        setIsPageCountWarningVisible(true);
                    }
                    setLoading(false);
                },
                pdfPreviewErrorCallback
            );
        }
    }

    const loadDifferencesListGroupedReport = () => {
        if (!fileDifferentialGrouped) {
            setLoading(true);
            loadPdfReportInfo(
                selectedPopulation ? selectedPopulation.populationId : null,
                window.enumReportType.DifferencesListGrouped,
                (file, response) => {
                    setFileDifferentialGrouped(file);
                    if (response.expectedPageCount && response.renderedPageCount > response.expectedPageCount) {
                        setExpectedPageCount(response.expectedPageCount);
                        setIcTabIndexToOpen(interfaceConfigTabIndex.accountingData);
                        setIsPageCountWarningVisible(true);
                    }
                    setLoading(false);
                },
                pdfPreviewErrorCallback
            );
        }
    }

    const loadCountedItemsReport = () => {
        if (!fileCountedItems) {
            setLoading(true);
            loadPdfReport(
                window.enumReportType.CountedItems,
                r => {
                    setFileCountedItems(r);
                    setLoading(false);
                },
                pdfPreviewErrorCallback,
                selectedPopulation ? selectedPopulation.populationId : null
            );
        }
    }

    const loadUncountedItemsReport = () => {
        if (!fileUncountedItems) {
            setLoading(true);
            loadPdfReport(
                window.enumReportType.UncountedItems,
                r => {
                    setFileUncountedItems(r);
                    setLoading(false);
                },
                pdfPreviewErrorCallback,
                selectedPopulation ? selectedPopulation.populationId : null
            );
        }
    }

    const loadMultipleCountedItemsReport = () => {
        if (!fileMultipleCountedItems) {
            setLoading(true);
            loadPdfReport(
                window.enumReportType.MultipleCountedItems,
                r => {
                    setFileMultipleCountedItems(r);
                    setLoading(false);
                },
                pdfPreviewErrorCallback,
                selectedPopulation ? selectedPopulation.populationId : null
            );
        }
    }

    const loadCountListReport = () => {
        if (!fileCountlist) {
            setLoading(true);
            loadPdfReportInfo(
                selectedPopulation ? selectedPopulation.populationId : null,
                window.enumReportType.Countlist,
                (file, response) => {
                    setFileCountlist(file);
                    if (response.expectedPageCount && response.renderedPageCount > response.expectedPageCount) {
                        setExpectedPageCount(response.expectedPageCount);
                        setIcTabIndexToOpen(interfaceConfigTabIndex.countList);
                        setIsPageCountWarningVisible(true);
                    }
                    setLoading(false);
                },
                pdfPreviewErrorCallback
            );
        }
    }

    const loadEvaluationReport = () => {
        if (!fileEvaluation) {
            setLoading(true);
            loadPdfReport(
                window.enumReportType.Evaluation,
                r => {
                    setFileEvaluation(r);
                    setLoading(false);
                },
                pdfPreviewErrorCallback,
                selectedPopulation ? selectedPopulation.populationId : null
            );
        }
    }

    const loadDecisionGraphReport = () => {
        if (!fileDecisionGraph) {
            setLoading(true);
            loadPdfReport(
                window.enumReportType.DecisionGraph,
                r => {
                    setFileDecisionGraph(r);
                    setLoading(false);
                },
                pdfPreviewErrorCallback,
                selectedPopulation ? selectedPopulation.populationId : null
            );
        }
    }

    const loadReportsForTab3 = () => {
        props.enableGroupedDifferences ? loadDifferencesListGroupedReport() : loadCountedItemsReport();
    }

    const loadReportPreviewOnSelectedTab = (selectedTabIndex) => {
        switch (selectedTabIndex) {
            case 1:
                loadExtrapolationReport();
                break;
            case 2:
                loadDifferencesListReport();
                break;
            case 3:
                loadReportsForTab3();
                break;
            case 4:
                props.enableGroupedDifferences ? loadCountedItemsReport() : loadUncountedItemsReport();
                break;
            case 5:
                props.enableGroupedDifferences ? loadUncountedItemsReport() : loadMultipleCountedItemsReport();
                break;
            case 6:
                props.enableGroupedDifferences ? loadMultipleCountedItemsReport() : loadCountListReport();
                break;
            case 7:
                props.enableGroupedDifferences ? loadCountListReport() : loadEvaluationReport();
                break;
            case 8:
                if (props.enableGroupedDifferences) {
                    loadEvaluationReport();
                }
                break;
            case 9:
                if (!props.enableGroupedDifferences) {
                    loadDecisionGraphReport();
                }
                break;
            case 10:
                if (props.enableGroupedDifferences) {
                    loadDecisionGraphReport();
                }
                break;

            default:
        }
    }

    const handleSelect = e => {
        setSelected(e.selected);
        if (!isCustomerWithoutLicense) {
            loadReportPreviewOnSelectedTab(e.selected);
        }
    };

    const getIsTotalDeviationPercentWithinTolerance = () => {
        if (props.statistics?.differenceOfTotalValuesInPercent != null
            && props.statistics.differenceOfTotalValuesInPercentRequired != null) {
            return Math.abs(props.statistics.differenceOfTotalValuesInPercent) <= Math.abs(props.statistics.differenceOfTotalValuesInPercentRequired)
                ? 1
                : -1;
        }
        else {
            return 0;
        }
    }

    const getIsRequiredSamplingErrorPercentWithinTolerance = () => {
        if (props.statistics?.samplingErrorRequired != null
            && props.statistics.samplingError != null) {
            return Math.abs(props.statistics.samplingError) <= Math.abs(props.statistics.samplingErrorRequired)
                ? 1
                : -1;
        }
        else {
            return 0;
        }
    }

    const getFormattedErrorNotificationMessage = (isTotalDevInTolerance) => {
        return intl.formatMessage(
            { id: 'ReportingStatistics.TheTotalDifferenceIsOutsideTheTolerance' },
            {
                Actual: isTotalDevInTolerance == -1 ? formatNumberWithDecimals(props.statistics.differenceOfTotalValuesInPercent, locale, decimals) : formatNumberWithDecimals(props.statistics.samplingError, locale, decimals),
                Tolerance: isTotalDevInTolerance == -1 ? props.statistics.differenceOfTotalValuesInPercentRequired.toLocaleString(locale) : props.statistics.samplingErrorRequired.toLocaleString(locale)
            }
        ) + (isCustomerWithoutLicense ? " " + intl.formatMessage({ id: 'ReportingStatistics.ToSeeTheResultYouCanSubscribe' }) : "");
    }

    const getTotalDeviationPercentClass = () => {
        let isInTolerance = getIsTotalDeviationPercentWithinTolerance();

        switch (isInTolerance) {
            case 1:
                return withinToleranceClass;
            case -1:
                return outsideToleranceClass;
            default:
                return ""
        }
    }

    const getRequiredSamplingErrorPercentClass = () => {
        let isInTolerance = getIsRequiredSamplingErrorPercentWithinTolerance();
        switch (isInTolerance) {
            case 1:
                return withinToleranceClass;
            case -1:
                return outsideToleranceClass;
            default:
                return ""
        }
    }

    const getStatusClass = () => {
        if (props.isHighQuality && props.pureStaseqReporting) {
            if (props.pureStaseqReporting.statusOfSequentialTest.reachedAcceptanceThreshold) {
                return withinToleranceClass;
            }
            else if (props.pureStaseqReporting.statusOfSequentialTest.reachedRejectionThreshold) {
                return outsideToleranceClass;
            }
        }
    }

    const resetDownloadedReportFiles = () => {
        setFileExtrapolation(null);
        setFileDifferential(null);
        setFileDifferentialGrouped(null);
        setFileCountedItems(null);
        setFileUncountedItems(null);
        setFileMultipleCountedItems(null);
        setFileCountlist(null);
        setFileEvaluation(null);
        setFileDecisionGraph(null);
    }

    useEffect(() => {
        if (props.isHighQuality && props.pureStaseqReporting) {
            if (props.pureStaseqReporting.statusOfSequentialTest.reachedAcceptanceThreshold) {
                dispatchNotification({
                    type: NotificationTypes.success,
                    pageId: pageId,
                    message: intl.formatMessage({ id: 'ReportingStatistics.YourInventoryWasSuccessful' }) + (isCustomerWithoutLicense ? " " + intl.formatMessage({ id: 'ReportingStatistics.ToSeeTheResultYouCanSubscribe' }) : "")
                });
            }
            else if (props.pureStaseqReporting.statusOfSequentialTest.reachedRejectionThreshold) {
                dispatchNotification({
                    type: NotificationTypes.error,
                    pageId: pageId,
                    message: intl.formatMessage({ id: 'ReportingStatistics.YourInventoryWasUnsuccessful' }) + (isCustomerWithoutLicense ? " " + intl.formatMessage({ id: 'ReportingStatistics.ToSeeTheResultYouCanSubscribe' }) : "")
                });
            }
        }
    }, [props.pureStaseqReporting]);

    useEffect(() => {
        let isTotalDeviationInTolerance = getIsTotalDeviationPercentWithinTolerance();
        let isRequiredSamplingInTolerance = getIsRequiredSamplingErrorPercentWithinTolerance();
        let isItemsToDraw = props.statistics?.needToDraw && props.statistics?.noOfItemsToDraw > 0;
        if (isTotalDeviationInTolerance != 0 && isRequiredSamplingInTolerance) {
            if (isTotalDeviationInTolerance == 1 && isRequiredSamplingInTolerance == 1) {
                if (!isItemsToDraw) {
                    dispatchNotification({
                        type: NotificationTypes.success,
                        pageId: pageId,
                        message: intl.formatMessage({ id: 'ReportingStatistics.YourInventoryWasSuccessful' }) + (isCustomerWithoutLicense ? " " + intl.formatMessage({ id: 'ReportingStatistics.ToSeeTheResultYouCanSubscribe' }) : "")
                    });
                }
            } else if (isTotalDeviationInTolerance == -1) {
                dispatchNotification({
                    type: NotificationTypes.error,
                    pageId: pageId,
                    message: getFormattedErrorNotificationMessage(isTotalDeviationInTolerance)
                });
            }
        }
        resetDownloadedReportFiles();
    }, [props.statistics]);

    const selectExportFileAndExportDifferencesList = async (isGrouped, defaultFileName) => {
        const fileHandle = await getNewFileHandle(
            defaultFileName,
            intl.formatMessage({ id: 'fileType.csv' }),
            intl.formatMessage({ id: 'fileType.txt' }),
            intl.formatMessage({ id: 'fileType.all' })
        );

        setLoading(true);

        const downloadDifferencesListMethod = isGrouped ? getDifferencesListGroupedExported : getDifferencesListExported;
        downloadDifferencesListMethod(
            async (file) => {
                setLoading(false);

                if (window.showSaveFilePicker && fileHandle) {
                    await saveFile(fileHandle, file, "text/csv");

                    dispatchNotification({
                        type: NotificationTypes.success,
                        pageId: pageId,
                        message: <FormattedMessage id="Control.FileDownloaded" values={{ FileName: fileHandle.name }} />
                    });
                }
                else {
                    downloadFile(file, "text/csv");
                }
            },
            exportDifferencesListErrorCallback,
            selectedPopulation ? selectedPopulation.populationId : null
        );
    }

    const getDefaultFileNameAndExportDifferencesList = async (isGrouped) => {
        getDefaultDifferencesListFileName(selectedPopulation?.populationId, isGrouped,
            async result => {
                console.log('default differences list name:', result);
                await selectExportFileAndExportDifferencesList(isGrouped, result);
            },
            exportDifferencesListErrorCallback
        );
    };

    const downloadExportedDifferencesList = async (isGrouped) => {
        await getDefaultFileNameAndExportDifferencesList(isGrouped ?? false);
    };

    const onExportFileClicked = React.useCallback(async () => {
        await downloadExportedDifferencesList();
    }, []);

    const onExportFileGroupedClicked = React.useCallback(async () => {
        await downloadExportedDifferencesList(true);
    }, []);

    const handlePageWarningClose = (isConfirmed) => {
        setIsPageCountWarningVisible(false);
    };

    const extrapolationReportTabText = isHighQuality
        ? <FormattedMessage id='Reporting.ExtrapolationSeq' />
        : <FormattedMessage id='Reporting.Extrapolation' />;

    const pageWarningText = wizzardContext.wizzardGlobal.InterfaceConfiguration?.interfaceConfigurationId
        ? intl.formatMessage({ id: 'SelectCountingOption.PageCountWarningWithAssignedInterfaceConfig' }, {
            'Count': icTabIndexToOpen === interfaceConfigTabIndex.countList
                ? wizzardContext.wizzardGlobal.InterfaceConfiguration.exportCountItems?.numberOfPositionsOnPage ?? ''
                : wizzardContext.wizzardGlobal.InterfaceConfiguration.exportAccountingData?.numberOfPositionsOnPage ?? ''
        })
        : intl.formatMessage({ id: 'SelectCountingOption.PageCountWarningWithoutAssignedInterfaceConfig' }, { 'Count': expectedPageCount });

    const linkToInterfaceConfiguration = <Link to={`/administration/config/${wizzardContext.wizzardGlobal.InterfaceConfiguration?.interfaceConfigurationId}/${icTabIndexToOpen}/${true}`} > {wizzardContext.wizzardGlobal.InterfaceConfiguration?.interfaceConfigurationName}</Link>;


    return <div>
        <TabStrip selected={selected} onSelect={handleSelect}>
        <TabStripTab title={<FormattedMessage id='Reporting.InventoryResult' />}>
            {isCustomerWithoutLicense && (
                <div><FormattedMessage id='Reporting.ResultNotVisible' /></div>
            )}
            {!isCustomerWithoutLicense && (
                isHighQuality
                    ? <ReportingStatisticsSequential statistics={props.statistics} pureStaseqReporting={props.pureStaseqReporting} importInProgress={props.importInProgress} getStatusClass={getStatusClass} />
                    : <ReportingStatistics statistics={props.statistics} importInProgress={props.importInProgress} getTotalDeviationPercentClass={getTotalDeviationPercentClass} getRequiredSamplingErrorPercentClass={getRequiredSamplingErrorPercentClass} />
            )}
        </TabStripTab>
        <TabStripTab title={extrapolationReportTabText} disabled={!props.enableReportPreview || isHighQuality}>
            <div>
                {isCustomerWithoutLicense && (
                    <div><FormattedMessage id='Reporting.ResultNotVisible' /></div>
                )}
                {!isCustomerWithoutLicense && (
                    <PreviewPDF file={fileExtrapolation}
                        prevPageText='<'
                        nextPageText='>'
                        downloadFileText={<FormattedMessage id='Reporting.DownloadDocument' />}
                    />
                )}
            </div>
        </TabStripTab>
        <TabStripTab title={<FormattedMessage id='Reporting.Differences' />} disabled={!props.enableReportPreview}>
            <div>
                {isCustomerWithoutLicense && (
                    <div><FormattedMessage id='Reporting.ResultNotVisible' /></div>
                )}
                {!isCustomerWithoutLicense && (
                    <PreviewPDF file={fileDifferential}
                        prevPageText='<'
                        nextPageText='>'
                        downloadFileText={<FormattedMessage id='Reporting.DownloadDocument' />}
                        exportFileText={<FormattedMessage id='Reporting.ExportDifferenceList' />}
                        onExportFileClicked={onExportFileClicked}
                    />
                )}
            </div>
        </TabStripTab>
        {props.enableGroupedDifferences &&
            <TabStripTab title={<FormattedMessage id='Reporting.DifferencesGrouped' />} disabled={!props.enableReportPreview}>
                <div>
                    {isCustomerWithoutLicense && (
                        <div><FormattedMessage id='Reporting.ResultNotVisible' /></div>
                    )}
                    {!isCustomerWithoutLicense && (
                        <PreviewPDF file={fileDifferentialGrouped}
                            prevPageText='<'
                            nextPageText='>'
                            downloadFileText={<FormattedMessage id='Reporting.DownloadDocument' />}
                            exportFileText={<FormattedMessage id='Reporting.ExportDifferenceList' />}
                            onExportFileClicked={onExportFileGroupedClicked}
                        />
                    )}
                </div>
            </TabStripTab>
        }
        <TabStripTab title={<FormattedMessage id='Reporting.CountedItems' />} disabled={!props.enableReportPreview}>
            <div>
                {isCustomerWithoutLicense && (
                    <div><FormattedMessage id='Reporting.ResultNotVisible' /></div>
                )}
                {!isCustomerWithoutLicense && (
                    <PreviewPDF file={fileCountedItems}
                        prevPageText='<'
                        nextPageText='>'
                        downloadFileText={<FormattedMessage id='Reporting.DownloadDocument' />}
                    />
                )}
            </div>
        </TabStripTab>
        <TabStripTab title={<FormattedMessage id='Reporting.UncountedItems' />} disabled={!props.enableReportPreview}>
            <div>
                {isCustomerWithoutLicense && (
                    <div><FormattedMessage id='Reporting.ResultNotVisible' /></div>
                )}
                {!isCustomerWithoutLicense && (
                    <PreviewPDF file={fileUncountedItems}
                        prevPageText='<'
                        nextPageText='>'
                        downloadFileText={<FormattedMessage id='Reporting.DownloadDocument' />}
                    />
                )}
            </div>
        </TabStripTab>
        <TabStripTab title={<FormattedMessage id='Reporting.MultipleCountedItems' />} disabled={!props.enableReportPreview}>
            <div>
                {isCustomerWithoutLicense && (
                    <div><FormattedMessage id='Reporting.ResultNotVisible' /></div>
                )}
                {!isCustomerWithoutLicense && (
                    <PreviewPDF file={fileMultipleCountedItems}
                        prevPageText='<'
                        nextPageText='>'
                        downloadFileText={<FormattedMessage id='Reporting.DownloadDocument' />}
                    />
                )}
            </div>
        </TabStripTab>
        <TabStripTab title={<FormattedMessage id='Reporting.CountList' />} disabled={!props.enableReportPreview}>
            <div>
                {isCustomerWithoutLicense && (
                    <div><FormattedMessage id='Reporting.ResultNotVisible' /></div>
                )}
                {!isCustomerWithoutLicense && (
                    <PreviewPDF file={fileCountlist}
                        prevPageText='<'
                        nextPageText='>'
                        downloadFileText={<FormattedMessage id='Reporting.DownloadDocument' />}
                    />
                )}
            </div>
        </TabStripTab>
        <TabStripTab title={<FormattedMessage id='Reporting.Evaluation' />} disabled={!props.enableReportPreview}>
            <div>
                {isCustomerWithoutLicense && (
                    <div><FormattedMessage id='Reporting.ResultNotVisible' /></div>
                )}
                {!isCustomerWithoutLicense && (
                    <PreviewPDF file={fileEvaluation}
                        prevPageText='<'
                        nextPageText='>'
                        downloadFileText={<FormattedMessage id='Reporting.DownloadDocument' />}
                    />
                )}
            </div>
        </TabStripTab>
        <TabStripTab title={<FormattedMessage id='Reporting.ErrorStatistics' />} disabled={true} />
        {!isHighQuality
            ? <TabStripTab title={<FormattedMessage id='Reporting.CorrelationDiagram' />} disabled={true} />
            : <TabStripTab title={<FormattedMessage id='Reporting.DecisionDiagram' />} >
                <div>
                    {isCustomerWithoutLicense && (
                        <div><FormattedMessage id='Reporting.ResultNotVisible' /></div>
                    )}
                    {!isCustomerWithoutLicense && (
                        <PreviewPDF file={fileDecisionGraph}
                            prevPageText='<'
                            nextPageText='>'
                            downloadFileText={<FormattedMessage id='Reporting.DownloadDocument' />}
                        />
                    )}
                </div>
            </TabStripTab>
        }
        </TabStrip>

        <ConfirmDialog
            visible={isPageCountWarningVisible}
            onClose={handlePageWarningClose}
            negative={<FormattedMessage id='Control.Ok' />}
            title={"PageCountWarning"}
        >
            <p>
                {pageWarningText}
            </p>
            <p>
                {linkToInterfaceConfiguration}
            </p>
        </ConfirmDialog>
    </div>
}

export default WizardTabs