import * as React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { RadioButton } from "@progress/kendo-react-inputs";
import { Button } from 'reactstrap';

const OptionSelectorDialog = props => {
    const [visible, setVisible] = React.useState(props.visible);

    const handleClose = () => {
        if (props.onClose)
            props.onClose();
    };

    const handleCancel = (event) => {
        event.preventDefault();
        if (props.onCancel)
            props.onCancel();
        else if (props.onClose)
            props.onClose();
    };

    const handleConfirm = (event) => {
        event.preventDefault();
        if (props.onClose)
            props.onClose(selectedValue);
    };

    React.useEffect(() => {
        setSelectedValue(null);
        setVisible(props.visible);
    }, [props.visible]);

    const [selectedValue, setSelectedValue] = React.useState(null);

    const handleChange = React.useCallback(
        (e) => { setSelectedValue(e.value); },
        [setSelectedValue]
    );

    return (
        <div>
            {visible && (
                <Dialog title={props.title} onClose={handleClose}  >
                    <div>
                        {
                            props.options.map((option, index) =>
                            <div key={'radio-' + option.value + '-' + index}>
                                <RadioButton
                                        name={props.name ?? "group1"}
                                        value={option.value}
                                        checked={selectedValue === option.value}
                                        label={option.text}
                                        onChange={handleChange}
                                        disabled={option.disabled ?? false}
                                />
                            </div>)
                        }
                    </div>
                    {props.children && (
                        <p className={"option-selector-dialog-children"} >
                            {props.children}
                        </p>
                    )}
                    <DialogActionsBar>
                        {
                            props.negative &&
                            <Button type={"submit"} color="secondary" onClick={handleCancel}>
                                {props.negative}
                            </Button>
                        }
                        {
                            props.positive &&
                            <Button type={"submit"} color="primary" onClick={handleConfirm} disabled={!selectedValue} >
                                {props.positive}
                            </Button>
                        }
                    </DialogActionsBar>
                </Dialog>
            )}
        </div>
    );
};

export default OptionSelectorDialog
