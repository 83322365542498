import React, { useEffect } from 'react'
import { signinRedirect } from '../services/userService'
import { Redirect, Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import StatLogo from '../assets/images/statcontrol.png';
import RemiraLogo from '../assets/images/Logo.png';
import CustomerLogo1 from '../assets/images/customers/boss.png';
import CustomerLogo2 from '../assets/images/customers/kneipp.png';
import CustomerLogo3 from '../assets/images/customers/olympus.png';
import CustomerLogo4 from '../assets/images/customers/rewe.png';
import CustomerLogo5 from '../assets/images/customers/tesa.png';
import CustomerLogo6 from '../assets/images/customers/wuerth.png';
import CustomerLogo7 from '../assets/images/customers/zapf.png';
import CustomerLogo8 from '../assets/images/customers/zollner.png';
import { AuthContext } from '../utils/authProvider';
import  Footer  from './Footer';
import {
    Row,
    Col,
    Button
} from 'reactstrap';
import CookieConsent from './CookieConsent/CookieConsent';
import { FormattedMessage } from 'react-intl';
import { useNotification } from './Shared/Notifications/NotificationProvider';
import { NotificationTypes } from './Shared/Notifications/Notification';
import { saveState } from '../reducers/browser-storage';
import { storeCustomer } from '../actions/dashboardActions';
import store from '../store';
import { removeLSUserInfo } from './UserContext';
import { leaveTimeKey } from './NavMenu';
import { SessionKeyIsAutomaticLogout } from '../services/serviceBase';
import DeviceAlert from './Alert/DeviceAlert';

console.log(process.env);

function Login() {
    const dispatch = useDispatch();
    const pageId = 'pageLogin';
    const dispatchNotification = useNotification();
    const user = useSelector(state => state.auth.user);
    const userManager = React.useContext(AuthContext);
    const { fromAutomaticLogout } = useParams();

    if (!user) {
        dispatch(storeCustomer(null));
        saveState(store.getState());
    }

    useEffect(() => {
        removeLSUserInfo();
        localStorage.removeItem(leaveTimeKey);

        const isAutomaticLogout = sessionStorage.getItem(SessionKeyIsAutomaticLogout);
        if (isAutomaticLogout) {
            sessionStorage.removeItem(SessionKeyIsAutomaticLogout);

            dispatchNotification({
                type: NotificationTypes.info,
                pageId: pageId,
                message: (<FormattedMessage id='Login.YouHaveBeenAutomaticallyLoggedOut' />)
            });
        }
    }, []);

    useEffect(() => {
        if (fromAutomaticLogout) {
            dispatchNotification({
                type: NotificationTypes.info,
                pageId: pageId,
                message: (<FormattedMessage id='Login.YouHaveBeenAutomaticallyLoggedOut' />)
            });
        }
    }, [fromAutomaticLogout]);

    function login() {
        signinRedirect(userManager);
    }

    return (
        (user)
            ? (<Redirect to={'/'} />)
            : (
                <div>
                    <div className='container login-container'>
                        <Row>
                            <Col xs='12'>
                                <div id='logo-container'>
                                    <img src={StatLogo} alt="Stat Logo" />
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Row className="login-form-submit">
                            <Col xs='12' className='mb-4'>
                                <h1 className="mb-4 login-header"><FormattedMessage id='Register.Heading' /></h1>
                                <p className="mb-1 login-lead"><FormattedMessage id='Register.Info' /></p>
                                <p><b><FormattedMessage id='Register.Certified' /></b></p>
                            </Col>

                            <Col xs="12">
                                <div className="k-form-buttons k-justify-content-stretch">
                                    <Button color='secondary' onClick={() => login()}>
                                        <FormattedMessage id='Control.Login' />
                                    </Button>
                                    <Link className='btn btn-primary' to='/register'>
                                        <FormattedMessage id='Control.Register' />
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                        <hr className="mb-4 mt-4" />
                        <p className="text-center"><b><FormattedMessage id="Register.Customers" /></b></p>
                        <Row>
                            <Col sm='3' xs='6'>
                                <img src={CustomerLogo1} className="customer" alt="Customer Logo" />
                            </Col>
                            <Col sm='3' xs='6'>
                                <img src={CustomerLogo2} className="customer" alt="Customer Logo" />
                            </Col>
                            <Col sm='3' xs='6'>
                                <img src={CustomerLogo3} className="customer" alt="Customer Logo" />
                            </Col>
                            <Col sm='3' xs='6'>
                                <img src={CustomerLogo4} className="customer" alt="Customer Logo" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm='3' xs='6'>
                                <img src={CustomerLogo5} className="customer" alt="Customer Logo" />
                            </Col>
                            <Col sm='3' xs='6'>
                                <img src={CustomerLogo6} className="customer" alt="Customer Logo" />
                            </Col>
                            <Col sm='3' xs='6'>
                                <img src={CustomerLogo7} className="customer" alt="Customer Logo" />
                            </Col>
                            <Col sm='3' xs='6'>
                                <img src={CustomerLogo8} className="customer" alt="Customer Logo" />
                            </Col>
                        </Row>
                        <CookieConsent></CookieConsent>
                        <DeviceAlert/>
                    </div>
                    <Row className="login-remira">
                        <Col xs='12'>
                            <div id='logo-container'>
                                <img src={RemiraLogo} className="remira" alt="Stat Logo" />
                            </div>
                        </Col>
                    </Row>
                    <Footer />
                </div>                
            )
    );
}

export default Login