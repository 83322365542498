import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Row, Col, Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    Chart,
    ChartLegend,
    ChartTitle,
    ChartSeries,
    ChartSeriesItem,
    ChartSeriesLabels,
    ChartTooltip,
    ChartArea
} from "@progress/kendo-react-charts";
import { ArcGauge } from "@progress/kendo-react-gauges";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { getLocale, formatNumber } from '../../../../utils/localization';
import { trigger } from '../../../../actions/events'
import ReactTooltip from 'react-tooltip';
import { FormattedMessage, useIntl } from 'react-intl';
import "hammerjs";
import { getCompleteCountParameter, setCompleteCountThreshold, createStratification } from '../../../../services/inventoryService';
import { useLoading } from "../../../Shared/LoadingContext";
import { useNotification } from '../../../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../../../Shared/Notifications/Notification';
import { useExceptionDialog } from '../../../Shared/ExceptionDialog/ExceptionDialogProvider';
import { wizardEvents, wizardStep } from '../Wizard';
import {
    IntlProvider,
} from "@progress/kendo-react-intl";
import { Input } from '@progress/kendo-react-inputs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faVial,
    faTally,
    faSackDollar,
    faExclamationTriangle,
    faClock,
    faShare,
    faChevronCircleRight,
    faChevronCircleLeft,
    faArrowsRotate
} from '@fortawesome/pro-light-svg-icons'

const stratumChartColors = ["#3eaee2", "#319fd2", "#2185b4", "#166f99", "#0e5a7e", "#093950"];

const SamplingStatistics = (props) => {
    const cancelTokenSource = axios.CancelToken.source();
    const selectedPopulation = useSelector(state => state.dashboard.population);
    const userProfile = useSelector(state => state.profile.profile);
    const locale = getLocale(userProfile.userLanguage);
    const { setLoading } = useLoading();
    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const pageId = 'SamplingStatistics';
    const intl = useIntl();

    const pricesImported = props.statistics?.pricesImported;

    const handleError = (errorMessage, showNotif = true) => {
        setLoading(false);
        if (showNotif) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            });
        }
    };

    const tooltipRender = (tprops) => {
        if (tprops.point) {
            return tprops.point.dataItem.stat;
        }
    };

    const centerRenderer = (_currentValue, _color) => {
        return (
            <div >
                <h4>
                    {completeCountParams ? formatNumber(Math.round(completeCountParams.completeCountThreshold.value), locale) + " " + getCurrency() : '...'}
                </h4>
                <h4>
                    {completeCountParams ? formatNumber(completeCountParams.completeCountThreshold.noOfItems, locale) + " " + intl.formatMessage({ id: 'SamplingStatistics.Positions' }) : '...'}
                </h4>
                <Button color="secondary" onClick={decreasePercentageValue} className="btn btn-link" disabled={props.readOnly}>
                    <FontAwesomeIcon icon={faChevronCircleLeft} />
                </Button>
                <Input
                    id={"percentageInput"}
                    name={"percentageInput"}
                    className="wizard-sampling-percent"
                    maxLength={4}
                    value={completeCountParams ? formatNumber(Math.round(completeCountParams.completeCountThreshold.percentage), locale) : '...'}
                    onChange={onPercentageValueChanged}
                    disabled={props.readOnly}
                /><span className="wizard-sampling-text-percent">%</span>
                <Button color="secondary" onClick={increasePercentageValue} className="btn btn-link" disabled={props.readOnly}>
                    <FontAwesomeIcon icon={faChevronCircleRight} />
                </Button>
            </div>
        );
    };

    const thresholdCalcTypes = [
        { text: '%', value: window.enumCompCntParamThresholdCalculation['ByPercentage'] },
        { text: 'val', value: window.enumCompCntParamThresholdCalculation['ByValue'] }
    ];

    const lastStockItemImportDate = props.statistics && props.statistics.standardParameter ? props.statistics.standardParameter.lastStockItemImport : null;

    const formattedImportDateDay = () => {
        const options = { weekday: 'long', hour: '2-digit', minute: '2-digit' };
        return lastStockItemImportDate ? new Date(lastStockItemImportDate).toLocaleDateString(locale, options) : '';
    };

    const formattedImportDateDate = () => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return lastStockItemImportDate ? new Date(lastStockItemImportDate).toLocaleDateString(locale, options) : '';
    };

    const formattedTotalRecords = props.statistics && props.statistics.inventoryData && props.statistics.inventoryData.rowsInFileCnt
        ? props.statistics.inventoryData.rowsInFileCnt.toLocaleString(locale)
        : '';

    const formattedTotalValue = props.statistics && props.statistics.inventoryData && props.statistics.inventoryData.fileValueAccepted
        ? props.statistics.inventoryData.fileValueAccepted.toLocaleString(locale)
        : '';

    const rejectedRowCount = props.statistics && props.statistics.inventoryData && props.statistics.inventoryData.rowsRejectedCnt
        ? props.statistics.inventoryData.rowsRejectedCnt : 0;

    const formattedRejectedRowCount = rejectedRowCount.toLocaleString(locale);

    const getCurrency = () => {
        if (props.statistics && props.statistics.standardParameter) {
            return props.statistics.standardParameter.currency;
        }
        return decodeURI('%E2%82%AC');
    }

    const formattedNumberOfSamplesPercentage = props.statistics && props.statistics.statisticsOfStratification &&
        props.statistics.statisticsOfStratification.totalSum && props.statistics.statisticsOfStratification.totalSum.noOfSamplesPct
        ? props.statistics.statisticsOfStratification.totalSum.noOfSamplesPct.toLocaleString(locale) + '%'
        : ''

    const formattedNumberOfSamples = props.statistics && props.statistics.statisticsOfStratification &&
        props.statistics.statisticsOfStratification.totalSum && props.statistics.statisticsOfStratification.totalSum.noOfSamplesCnt
        ? props.statistics.statisticsOfStratification.totalSum.noOfSamplesCnt.toLocaleString(locale)
        : '';

    const getStratumChartColor = (index) => {
        let i = Math.min(stratumChartColors.length, index);
        return stratumChartColors[i - 1];
    }

    const stratumChartData = () => {
        let strats = props.statistics && props.statistics.statisticsOfStratification && props.statistics.statisticsOfStratification.stratumStatistics
            ? props.statistics.statisticsOfStratification.stratumStatistics.map((item) => ({
                stat: " " + intl.formatMessage({ id: 'SamplingStatistics.Stratum' }) + " " + item.stratumNo,
                count: item.valueOfItemsInStratum,
                color: getStratumChartColor(item.stratumNo),
                randomSamplingCount: item.noOfRandomSamplingsCnt,
                upperBoundary: item.upperBoundary
            }))
            : [];

        if (strats.length > 0) {
            strats.push({
                stat: intl.formatMessage({ id: 'SamplingStatistics.CompleteCountStrat' }),
                count: props.statistics.statisticsOfStratification.totalCompleteCount.valueOfItemsInStratumAcc,
                color: getStratumChartColor(props.statistics.statisticsOfStratification.stratumStatistics[0].stratumNo),
                randomSamplingCount: props.statistics.statisticsOfStratification.totalCompleteCount.noOfSamplesCnt,
                upperBoundary: props.statistics.statisticsOfStratification.totalCompleteCount.valueOfItemsInStratumAcc
            })
        }

        return strats.reverse();
    }

    const updateStratumDetails = (dataItem) => {
        if (dataItem) {
            setStratumDetailName(dataItem.stat);
            setStratumDetailRandomSamplingCnt(dataItem.randomSamplingCount);
            setStratumDetailUpperBoundary(Math.round(dataItem.upperBoundary));
        }
    }

    const stratumChartSeriesAreaClicked = (event) => {
        if (event) {
            updateStratumDetails(event.dataItem);
        }
    }

    const handleLimitationChange = (event) => {
        setLimitation(event.target.value);
    };

    const increasePercentageValue = () => {
        const newValue = completeCountParams.completeCountThreshold.percentage + 1;
        if (newValue <= 100) {
            setPercentageValue(newValue);
            setThreshold(newValue);
            props.setThresholdChanged(true);
        }
    };
    const decreasePercentageValue = () => {
        const newValue = completeCountParams.completeCountThreshold.percentage - 1;
        if (newValue >= 0) {
            setPercentageValue(newValue);
            setThreshold(newValue);
            props.setThresholdChanged(true);
        }
    };

    const onPercentageValueChanged = (event) => {
        if (event.value >= 0 && event.value <= 100) {
            setPercentageValue(event.value);
            setThreshold(event.value);
            props.setThresholdChanged(true);
        }
    };

    const setThreshold = (value) => {
        let thresholdParams = {
            thresholdCalcType: limitation.value,
            completeCountParam: {
                ...completeCountParams,
                completeCountThreshold: {
                    ...completeCountParams.completeCountThreshold,
                    value: limitation.value == window.enumCompCntParamThresholdCalculation['ByValue'] ? value : completeCountParams.completeCountThreshold.value,
                    percentage: limitation.value == window.enumCompCntParamThresholdCalculation['ByPercentage'] ? value : completeCountParams.completeCountThreshold.percentage
                }
            }
        };
        setLoading(true);
        setCompleteCountThreshold(
            selectedPopulation.populationId,
            thresholdParams,
            success => {
                setLoading(false);
                console.log('setCompleteCountThreshold succeeded');
                setCompleteCountParams(success)
            },
            handleError,
            cancelTokenSource.token
        );
    }

    const createStratificationAndDoAction = (action, params) => {
        setLoading(true);
        createStratification(selectedPopulation && selectedPopulation.populationId,
            () => {
                setLoading(false);
                if (action) {
                    action(params);
                }
            },
            handleError,
            cancelTokenSource.token
        );
    }

    const reloadStratumsClicked = () => {
        createStratificationAndDoAction(() => trigger(wizardEvents.reloadImportStatistics, wizardStep.Sampling));
        props.setThresholdChanged(false);
    }

    const getInitialCompleteCountParameters = () => {
        getCompleteCountParameter(
            selectedPopulation.populationId,
            response => {
                setCompleteCountParams(response)
            },
            handleError,
            cancelTokenSource.token
        );
    }

    const [stratumDetailName, setStratumDetailName] = useState('')
    const [stratumDetailRandomSamplingCnt, setStratumDetailRandomSamplingCnt] = useState(null)
    const [stratumDetailUpperBoundary, setStratumDetailUpperBoundary] = useState(null)

    const [limitation, setLimitation] = useState({ text: '%', value: window.enumCompCntParamThresholdCalculation['ByPercentage'] })

    const [percentageValue, setPercentageValue] = useState(0)
    const [percentageMax, setPercentageMax] = useState(100)
    const [tachoValue, setTachoValue] = useState(0)
    const [tachoMax, setTachoMax] = useState(100)
    const [completeCountParams, setCompleteCountParams] = useState(null)

    useEffect(() => {
        return () => {
            setLoading(false);
            cancelTokenSource.cancel();
        };
    }, []);

    useEffect(() => {
        let strats = stratumChartData();
        if (strats.length > 0) {
            updateStratumDetails(strats[strats.length - 1]);
        }
    }, [props.statistics]);

    useEffect(() => {
        if ( props.importInProgress === false && props.importFinishedWithError === false ) {
            getInitialCompleteCountParameters();
        }
    }, [props.importInProgress, props.importFinishedWithError]);

    useEffect(() => {
        if (completeCountParams) {
            if (limitation && limitation.value == window.enumCompCntParamThresholdCalculation['ByValue']) {
                setPercentageMax(completeCountParams.maxValueOfStockItem);
            }
            else {
                setPercentageMax(100);
            }
        }
    }, [limitation]);

    useEffect(() => {
        setTachoValue(percentageValue);
    }, [percentageValue]);

    useEffect(() => {
        setTachoMax(percentageMax);
    }, [percentageMax]);

    useEffect(() => {
        if (completeCountParams && completeCountParams.completeCountThreshold) {
            if (limitation && limitation.value == window.enumCompCntParamThresholdCalculation['ByValue']) {
                setPercentageValue(completeCountParams.completeCountThreshold.value)
            }
            else {
                setPercentageValue(completeCountParams.completeCountThreshold.percentage)
            }
        }
    }, [completeCountParams, limitation]);

    return (

        props.statistics ?
            <Row className="import-result">
                <Col xs="4" className="text-right">
                    <Row>
                        <Col xs="6">
                            <Card>
                                <CardHeader>
                                    <h5 className="card-category">{formattedImportDateDay()}</h5>
                                    <h1>{formattedImportDateDate()}</h1>
                                </CardHeader>
                                <CardBody>
                                    <FontAwesomeIcon icon={faClock} />
                                    &nbsp; <FormattedMessage id='SamplingStatistics.ImportDate' />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs="6">
                            <Card>
                                <CardHeader>
                                    <h5 className="card-category">&nbsp;</h5>
                                    <h1>{formattedTotalRecords}</h1>
                                </CardHeader>
                                <CardBody>
                                    <FontAwesomeIcon icon={faTally} />
                                    &nbsp; <FormattedMessage id='SamplingStatistics.TotalRecords' />
                                </CardBody>
                            </Card>
                        </Col>

                        {pricesImported &&
                            <Col xs="12">
                                <Card>
                                    <CardHeader>
                                        <h5 className="card-category">in {getCurrency()}</h5>
                                        <h1>{formattedTotalValue}</h1>
                                    </CardHeader>
                                    <CardBody>
                                        <FontAwesomeIcon icon={faSackDollar} />
                                        &nbsp; <FormattedMessage id='SamplingStatistics.TotalValue' />
                                    </CardBody>
                                </Card>
                            </Col>
                        }

                        <Col xs="6">
                            <Card >
                                <CardHeader>
                                    <h5 className="card-category">{formattedNumberOfSamplesPercentage} <FormattedMessage id='SamplingStatistics.OfTotal' /></h5>
                                    <h1>{formattedNumberOfSamples}</h1>
                                </CardHeader>
                                <CardBody>
                                    <FontAwesomeIcon icon={faVial} />
                                    &nbsp; <FormattedMessage id='SamplingStatistics.Samples' />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs="6">
                            <Card>
                                {
                                    rejectedRowCount > 0 ?
                                        <CardHeader>
                                            <h5 className="card-category text-danger"><FormattedMessage id='SamplingStatistics.ActionNeeded' /></h5>
                                            <h1>{formattedRejectedRowCount} <FontAwesomeIcon icon={faShare} className="text-danger" /></h1>
                                        </CardHeader> :
                                        <CardHeader>
                                            <h1>{formattedRejectedRowCount}</h1>
                                        </CardHeader>
                                }
                                <CardBody>
                                    <FontAwesomeIcon icon={faExclamationTriangle} />
                                    &nbsp; <FormattedMessage id='SamplingStatistics.RejectedRecords' />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Col>

                {pricesImported &&
                    <Col xs="4" >
                        <Card className="stratum-chart">
                            <IntlProvider locale={locale}>
                                <Chart
                                    onSeriesClick={stratumChartSeriesAreaClicked}
                                >
                                    <ChartArea background="transparent" />
                                    <ChartTitle text={intl.formatMessage({ id: 'SamplingStatistics.StratumsChartTitle' })} />
                                    <ChartSeries>
                                        <ChartSeriesItem
                                            type="funnel"
                                            data={stratumChartData()}
                                            categoryField="stat"
                                            field="count"
                                            colorField="color"
                                            neckRatio="1.6"
                                        >
                                            <ChartSeriesLabels color="white" background="none" format="N0" />
                                        </ChartSeriesItem>
                                    </ChartSeries>
                                    <ChartTooltip render={tooltipRender} />
                                    <ChartLegend visible={false} />
                                </Chart>
                            </IntlProvider>
                        </Card>
                    </Col>
                }
                {pricesImported &&
                    <Col xs="4" className="text-right">
                        <Row>
                            <Col xs="6">
                                <Card>
                                    <CardHeader>
                                        <h5 className="card-category"><FormattedMessage id='SamplingStatistics.ItemsToDraw' /></h5>
                                        <h1>{formatNumber(stratumDetailRandomSamplingCnt, locale)}</h1>
                                    </CardHeader>
                                    <CardBody>
                                        {stratumDetailName}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs="6">
                                <Card className="compare-options">
                                    <CardHeader>
                                        <h5 className="card-category"><FormattedMessage id='SamplingStatistics.ValueUpTo' /></h5>
                                        <h1>{formatNumber(stratumDetailUpperBoundary, locale)} {getCurrency()}</h1>
                                    </CardHeader>
                                    <CardBody>
                                        {stratumDetailName}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs="12">
                                <Card className="compare-options">
                                    <CardHeader>
                                        <Link className="btn btn-primary pull-left wizard-sampling-apply-changes"
                                            to={`#`}
                                            onClick={reloadStratumsClicked}
                                            disabled={props.readOnly}
                                            data-tip={intl.formatMessage({ id: 'SamplingStatistics.ApplyChanges' })}>
                                            <FontAwesomeIcon icon={faArrowsRotate} />
                                        </Link>

                                        <h5 className="card-category" data-tip={intl.formatMessage({ id: 'SamplingStatistics.TachoTooltip' })}>
                                            <FormattedMessage id='SamplingStatistics.Limitations' />
                                            <small>
                                                <DropDownList
                                                    data={thresholdCalcTypes}
                                                    textField="text"
                                                    dataItemKey="value"
                                                    value={limitation}
                                                    onChange={handleLimitationChange}
                                                    className="wizard-limit"
                                                    disabled={props.readOnly}
                                                />
                                            </small>
                                        </h5>
                                        <ReactTooltip place="top" type="error" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xs="12">
                                                <ArcGauge
                                                    scale={{ max: tachoMax }}
                                                    value={tachoValue}
                                                    color="#41b6e6"
                                                    centerRender={centerRenderer}>
                                                </ArcGauge>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                }
            </Row>
            : ''
    );
}

export default SamplingStatistics