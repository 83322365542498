/******************************************
 *  Author : Dr. Hanno Georg   
 *  Created On : Fri Sep 03 2021
 *  File : axiosHeader.js
 *******************************************/

import axios from 'axios';

export function setAuthHeader(token) {
    axios.defaults.headers.common['Authorization'] = token ? 'Bearer ' + token : '';
}

export function getTokenFromAuthHeader() {
    const header = axios.defaults.headers.common['Authorization'];
    return header ? header.replace('Bearer ', '') : '';
}
