import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import ConfigTabs from "./ConfigTabs";
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ConfigContext, ConfigContextReducer, CONFIG_CONTEXT_ACTIONS } from './ConfigContext';
import { UserContext } from '../../UserContext';
import { storeCustomer } from '../../../actions/dashboardActions';
import { getInterfaceConfigurationById } from '../../../services/interfaceConfigurationsService';
import { useLoading } from '../../Shared/LoadingContext';
import { useNotification } from '../../Shared/Notifications/NotificationProvider';
import { useExceptionDialog } from '../../Shared/ExceptionDialog/ExceptionDialogProvider';
import { NotificationTypes } from '../../Shared/Notifications/Notification';
import { useParams } from 'react-router-dom';
import { getFSort } from './ConfigEnums';

const defaultNumberOfPositionsOnPage = 15;

const Config = () => {
    const intl = useIntl();
    const { setLoading } = useLoading();
    const pageId = 'Config';
    const userProfile = useSelector(state => state.profile.profile);
    const userContext = React.useContext(UserContext);
    const currentCustomer = useSelector(state => state.dashboard.customer);
    const dispatch = useDispatch();
    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const { interfaceConfigurationId, selectedTabIndex, expanded } = useParams();
    const [isInterfaceConfigurationLoaded, setIsInterfaceConfigurationLoaded] = useState(false);

    React.useEffect(() => {
        if (!currentCustomer && userContext.userGlobal.userInfo.accessibleCustomers && userContext.userGlobal.userInfo.accessibleCustomers.length > 0) {
            const defaultCustomer = userContext.userGlobal.userInfo.accessibleCustomers.find(x => x.customerId === userContext.userGlobal.userInfo.customerId);
            if (defaultCustomer) {
                dispatch(storeCustomer(defaultCustomer));
            }
        }
    }, [userContext.userGlobal.userInfo]);


    const handleError = (errorMessage, showNotif = true) => {
        setLoading(false);
        if (showNotif) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            });
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const [configGlobal, dispatchConfigGlobal] = React.useReducer(ConfigContextReducer, {
        columnSeparators: [],
        decimalSeparators: [],
        thousandSeparators: [],
        priceUnits: [],
        interfaceConfiguration: {
            initialData: {
                startLine: 1,
                headingEnabled: false,
                priceEqualValue: false,
                columnSeparator: 0,
                decimalSeparator: 0,
                thousandSeparator: 0,
                priceUnit: 0,
                exportPositionAndPageNumbers: false,
                leadingZeros: false
            },
            importCountQuantities: {},
            exportCountItems: {},
            exportAccountingData: {},
            exportItemTableId: {}
        },

    });

    const configValue = React.useMemo(() => ({ configGlobal, dispatchConfigGlobal }), [configGlobal, dispatchConfigGlobal]);

    const adjustImportCountQuantities = (data) => {
        if (data.importCountQuantities.interfaceConfigurationParametersId <= 0 && data.initialData.interfaceConfigurationParametersId > 0) {
            data.importCountQuantities.columnSeparator = data.initialData.columnSeparator;
            data.importCountQuantities.customColumnSeparator = data.initialData.customColumnSeparator;
            data.importCountQuantities.decimalSeparator = data.initialData.decimalSeparator;
            data.importCountQuantities.thousandSeparator = data.initialData.thousandSeparator;
            data.importCountQuantities.headingEnabled = data.initialData.headingEnabled;
            data.importCountQuantities.startLine = data.initialData.startLine;
            data.importCountQuantities.priceUnit = data.initialData.priceUnit;
            data.importCountQuantities.priceEqualValue = data.initialData.priceEqualValue;

            data.importCountQuantities.interfaceConfigurationFieldMappings = [];
        }

        data.importCountQuantities.interfaceConfigurationFieldMappings = corelateColumnMappings(data.initialData.interfaceConfigurationFieldMappings, data.importCountQuantities.interfaceConfigurationFieldMappings, data.importCountQuantities.interfaceConfigurationParametersId, false, true);

        if (!data.importCountQuantities.interfaceConfigurationFieldMappings.find((dest) => dest.fId === window.enumFields.ActualQuantity)) {
            addExportField(data.importCountQuantities.interfaceConfigurationFieldMappings, window.enumFields.ActualQuantity, intl.formatMessage({ id: 'InterfaceConfiguration.ActualQuantity' }), data.importCountQuantities.interfaceConfigurationParametersId);
        }

        let headersImportCountQuantities = [];
        if (data.importCountQuantities?.interfaceConfigurationFieldMappings) {
            headersImportCountQuantities = data.importCountQuantities.interfaceConfigurationFieldMappings.map((item) => {
                return {
                    ColumnName: item.originalColumnName,
                    ColumnNo: item.position,
                    selected: false
                }
            });
            data.importCountQuantities.headers = headersImportCountQuantities
        }
        return headersImportCountQuantities;
    }

    const adjustExports = (data, headers) => {
        if (!data.exportCountItems) {
            data.exportCountItems = {
                needsSave: true
            };
        }
        data.exportCountItems.headers = headers;
        data.exportCountItems.interfaceConfigurationFieldMappings = corelateColumnMappings(data.initialData.interfaceConfigurationFieldMappings, data.exportCountItems.interfaceConfigurationFieldMappings, data.exportCountItems.interfaceConfigurationParametersId, true);
        data.exportCountItems.numberOfPositionsOnPage = data.exportCountItems.numberOfPositionsOnPage ?? defaultNumberOfPositionsOnPage;

        if (data.exportCountItems.interfaceConfigurationParametersId <= 0 && data.initialData.interfaceConfigurationParametersId > 0) {
            data.exportCountItems.columnSeparator = data.initialData.columnSeparator;
            data.exportCountItems.customColumnSeparator = data.initialData.customColumnSeparator;
            data.exportCountItems.decimalSeparator = data.initialData.decimalSeparator;
            data.exportCountItems.thousandSeparator = data.initialData.thousandSeparator;
            data.exportCountItems.headingEnabled = data.initialData.headingEnabled;
            data.exportCountItems.exportPositionAndPageNumbers = data.initialData.exportPositionAndPageNumbers;
            data.exportCountItems.leadingZeros = data.initialData.leadingZeros;
        }

        if (!data.exportAccountingData) {
            data.exportAccountingData = {
                needsSave: true
            };
        }
        data.exportAccountingData.headers = headers;
        data.exportAccountingData.interfaceConfigurationFieldMappings = corelateColumnMappings(data.initialData.interfaceConfigurationFieldMappings, data.exportAccountingData.interfaceConfigurationFieldMappings, data.exportAccountingData.interfaceConfigurationParametersId, false);
        data.exportAccountingData.numberOfPositionsOnPage = data.exportAccountingData.numberOfPositionsOnPage ?? defaultNumberOfPositionsOnPage;

        if (data.exportAccountingData.interfaceConfigurationParametersId <= 0 && data.initialData.interfaceConfigurationParametersId > 0) {
            data.exportAccountingData.columnSeparator = data.initialData.columnSeparator;
            data.exportAccountingData.customColumnSeparator = data.initialData.customColumnSeparator;
            data.exportAccountingData.decimalSeparator = data.initialData.decimalSeparator;
            data.exportAccountingData.thousandSeparator = data.initialData.thousandSeparator;
            data.exportAccountingData.headingEnabled = data.initialData.headingEnabled;
            data.exportAccountingData.exportPositionAndPageNumbers = data.initialData.exportPositionAndPageNumbers;
            data.exportAccountingData.leadingZeros = data.initialData.leadingZeros;
        }

        if (!data.exportItemTable) {
            data.exportItemTable = {
                needsSave: true
            };
        }
        data.exportItemTable.headers = headers;
        data.exportItemTable.interfaceConfigurationFieldMappings = corelateColumnMappings(data.initialData.interfaceConfigurationFieldMappings, data.exportItemTable.interfaceConfigurationFieldMappings, data.exportItemTable.interfaceConfigurationParametersId, false);

        if (data.exportItemTable.interfaceConfigurationParametersId <= 0 && data.initialData.interfaceConfigurationParametersId > 0) {
            data.exportItemTable.columnSeparator = data.initialData.columnSeparator;
            data.exportItemTable.customColumnSeparator = data.initialData.customColumnSeparator;
            data.exportItemTable.decimalSeparator = data.initialData.decimalSeparator;
            data.exportItemTable.thousandSeparator = data.initialData.thousandSeparator;
            data.exportItemTable.headingEnabled = data.initialData.headingEnabled;
            data.exportItemTable.exportPositionAndPageNumbers = data.initialData.exportPositionAndPageNumbers;
            data.exportItemTable.leadingZeros = data.initialData.leadingZeros;
        }
    }

    const loadInterfaceConfiguration = data => {
        setLoading(false);
        let headers = [];
        if (data.initialData?.interfaceConfigurationFieldMappings) {
            headers = data.initialData.interfaceConfigurationFieldMappings.map((item) => {
                return {
                    ColumnName: item.originalColumnName,
                    ColumnNo: item.position,
                    selected: false
                }
            });
        }

        if (data.initialData.interfaceConfigurationParametersId <= 0) {
            if (userProfile.userLanguage === window.enumLanguage.German) {
                data.initialData.decimalSeparator = window.enumDecimalSeparator.Comma;
                data.initialData.thousandSeparator = window.enumThousandSeparator.Dot;
            }
        }

        if (data.initialData) {
            data.initialData.headers = headers
        }

        let headersImportCountQuantities = adjustImportCountQuantities(data);

        adjustExports(data, headers);

        data.initialData.interfaceConfigurationFieldMappings.forEach((element) => {
            element.fSort = getFSort(element.fId);
        });
        data.importCountQuantities.interfaceConfigurationFieldMappings.forEach((element) => {
            element.fSort = getFSort(element.fId);
        });
        data.exportCountItems.interfaceConfigurationFieldMappings.forEach((element) => {
            element.fSort = getFSort(element.fId);
        });
        data.exportAccountingData.interfaceConfigurationFieldMappings.forEach((element) => {
            element.fSort = getFSort(element.fId);
        });
        data.exportItemTable.interfaceConfigurationFieldMappings.forEach((element) => {
            element.fSort = getFSort(element.fId);
        });

        dispatchConfigGlobal({
            type: CONFIG_CONTEXT_ACTIONS.SetInterfaceConfiguration,
            payload: data
        });

        dispatchConfigGlobal({
            type: CONFIG_CONTEXT_ACTIONS.SetCustomColumnSeparator,
            payload: data.initialData.columnSeparator
        });

        dispatchConfigGlobal({
            type: CONFIG_CONTEXT_ACTIONS.SetCurrentColumnSeparator,
            payload: data.initialData.columnSeparator
        });

        dispatchConfigGlobal({
            type: CONFIG_CONTEXT_ACTIONS.SetInitialDataHeaders,
            payload: configGlobal.interfaceConfiguration?.initialDataHeaders ? configGlobal.interfaceConfiguration.initialDataHeaders : headers
        });

        dispatchConfigGlobal({
            type: CONFIG_CONTEXT_ACTIONS.SetImportCountQuantitiesHeaders,
            payload: configGlobal.interfaceConfiguration?.importCountQuantitiesHeaders ? configGlobal.interfaceConfiguration.importCountQuantitiesHeaders : headersImportCountQuantities
        });

        setIsInterfaceConfigurationLoaded(true);
    };

    const loadData = () => {
        setLoading(true);
        setIsInterfaceConfigurationLoaded(false);
        if (interfaceConfigurationId) {
            getInterfaceConfigurationById(
                interfaceConfigurationId,
                loadInterfaceConfiguration,
                handleError
            );
        }
    };

    const corelateDestinationIdentFieldMappings = (result, sourceMappings, destinationMappings, destInterfaceConfigurationParametersId) => {
        let destinationMappingsIdent = destinationMappings.filter(x => x.fId <= window.enumFields.Ident15);
        let sourceMappingsIdent = sourceMappings.filter(x => x.fId <= window.enumFields.Ident15);

        sourceMappingsIdent.forEach(source => {
            let dest = destinationMappingsIdent.find((dest) => source.originalColumnName === dest.originalColumnName);
            if (!dest) {
                result.push({
                    ...source,
                    usedByImportExport: false,
                    interfaceConfigurationParametersId: destInterfaceConfigurationParametersId,
                    inEdit: true
                })
            }
            else {
                result.push({ ...dest, fId: source.fId, inEdit: true });
            }
        });

        destinationMappingsIdent.forEach(destination => {
            let des = result.find((res) => destination.fId === res.fId);
            if (!des) {
                result.push({ ...destination, inEdit: true })
            }
        });
    }

    const corelateDestinationAdditionalFieldMappings = (result, sourceMappings, destinationMappings, destInterfaceConfigurationParametersId) => {
        let destinationMappingsAditional = destinationMappings.filter(x => x.fId >= window.enumFields.AdditionalField1 && x.fId <= window.enumFields.AdditionalField15);
        let sourceMappingsAditional = sourceMappings.filter(x => x.fId >= window.enumFields.AdditionalField1 && x.fId <= window.enumFields.AdditionalField15);

        sourceMappingsAditional.forEach(source => {
            let dest = destinationMappingsAditional.find((dest) => source.originalColumnName === dest.originalColumnName);
            if (!dest) {
                result.push({
                    ...source,
                    usedByImportExport: false,
                    interfaceConfigurationParametersId: destInterfaceConfigurationParametersId,
                    inEdit: true
                })
            }
            else {
                result.push({ ...dest, fId: source.fId, inEdit: true });
            }
        });

        destinationMappingsAditional.forEach(destination => {
            let des = result.find((res) => destination.fId === res.fId);
            if (!des) {
                result.push({ ...destination, inEdit: true })
            }
        });
    }

    const corelateDestinationRemainingFieldMappings = (result, sourceMappings, destinationMappings, destInterfaceConfigurationParametersId) => {
        let destinationMappingsOther = destinationMappings.filter(x => x.fId > window.enumFields.Ident15 && !(x.fId >= window.enumFields.AdditionalField1 && x.fId <= window.enumFields.AdditionalField15));
        let sourceMappingsOther = sourceMappings.filter(x => x.fId > window.enumFields.Ident15 && !(x.fId >= window.enumFields.AdditionalField1 && x.fId <= window.enumFields.AdditionalField15));

        sourceMappingsOther.forEach(source => {
            let dest = destinationMappingsOther.find((dest) => source.fId === dest.fId);
            if (!dest) {
                result.push({
                    ...source,
                    usedByImportExport: false,
                    interfaceConfigurationParametersId: destInterfaceConfigurationParametersId,
                    inEdit: true,
                    originalColumnName: source.originalColumnName,
                    columnOrder: destinationMappings.length + source.columnOrder
                })
            }
            else {
                result.push({ ...dest, inEdit: true });
            }
        });

        destinationMappingsOther.forEach(destination => {
            let des = result.find((res) => destination.fId === res.fId);
            if (!des) {
                result.push({ ...destination, inEdit: true })
            }
        });
    }

    const corelateDestinationMappings = (result, sourceMappings, destinationMappings, destInterfaceConfigurationParametersId, isImport) => {
        
        corelateDestinationIdentFieldMappings(result, sourceMappings, destinationMappings, destInterfaceConfigurationParametersId);
        corelateDestinationAdditionalFieldMappings(result, sourceMappings, destinationMappings, destInterfaceConfigurationParametersId);
        corelateDestinationRemainingFieldMappings(result, sourceMappings, destinationMappings, destInterfaceConfigurationParametersId);

        if (!isImport) {
            checkExportField(result, destinationMappings, window.enumFields.StratumNumber, intl.formatMessage({ id: 'InterfaceConfiguration.Stratum' }));
            checkExportField(result, destinationMappings, window.enumFields.QuantityDifference, intl.formatMessage({ id: 'InterfaceConfiguration.DifferenceQuantity' }));
            checkExportField(result, destinationMappings, window.enumFields.DummyT1, intl.formatMessage({ id: 'InterfaceConfiguration.DifferenceQuantitySign' }));
            checkExportField(result, destinationMappings, window.enumFields.ValueDifference, intl.formatMessage({ id: 'InterfaceConfiguration.DifferenceValue' }));
            checkExportField(result, destinationMappings, window.enumFields.DummyT2, intl.formatMessage({ id: 'InterfaceConfiguration.DifferenceValueSign' }));
        }
    }

    const addExportFieldsIfNeeded = (result, destInterfaceConfigurationParametersId, isExportCountItems, isImport) => {
        if (!isImport && !result.find((dest) => dest.fId === window.enumFields.StratumNumber)) {
            addExportField(result, window.enumFields.StratumNumber, intl.formatMessage({ id: 'InterfaceConfiguration.Stratum' }), destInterfaceConfigurationParametersId);
        }

        if (!isImport && !result.find((dest) => dest.fId === window.enumFields.ActualQuantity)) {
            addExportField(result, window.enumFields.ActualQuantity, intl.formatMessage({ id: 'InterfaceConfiguration.ActualQuantity' }), destInterfaceConfigurationParametersId);
        }

        if (!isImport && !isExportCountItems) {
            if (!result.find((dest) => dest.fId === window.enumFields.QuantityDifference)) {
                addExportField(result, window.enumFields.QuantityDifference, intl.formatMessage({ id: 'InterfaceConfiguration.DifferenceQuantity' }), destInterfaceConfigurationParametersId);
            }
            if (!result.find((dest) => dest.fId === window.enumFields.DummyT1)) {
                addExportField(result, window.enumFields.DummyT1, intl.formatMessage({ id: 'InterfaceConfiguration.DifferenceQuantitySign' }), destInterfaceConfigurationParametersId);
            }
            if (!result.find((dest) => dest.fId === window.enumFields.ValueDifference)) {
                addExportField(result, window.enumFields.ValueDifference, intl.formatMessage({ id: 'InterfaceConfiguration.DifferenceValue' }), destInterfaceConfigurationParametersId);
            }
            if (!result.find((dest) => dest.fId === window.enumFields.DummyT2)) {
                addExportField(result, window.enumFields.DummyT2, intl.formatMessage({ id: 'InterfaceConfiguration.DifferenceValueSign' }), destInterfaceConfigurationParametersId);
            }
        }
    }

    const corelateColumnMappings = (sourceMappings, destinationMappings, destInterfaceConfigurationParametersId, isExportCountItems, isImport = false) => {
        let result = [];
        if (sourceMappings) {
            if (destinationMappings?.length > 0) {
                corelateDestinationMappings(result, sourceMappings, destinationMappings, destInterfaceConfigurationParametersId, isImport);
            }
            else {
                result = sourceMappings.map(source => source ? { ...source, interfaceConfigurationParametersId: destInterfaceConfigurationParametersId, inEdit: true, usedByImportExport: false} : {});
            }
            
            addExportFieldsIfNeeded(result, destInterfaceConfigurationParametersId, isExportCountItems, isImport);
        }
        return result;
    }

    const checkExportField = (result, destinationMappings, fId, columnName) => {
        let element = destinationMappings.find((dest) => dest.fId === fId)
        let elementResult = result.find((dest) => dest.fId === fId)
        if (!elementResult && element) {
            result.push({
                ...element, selected: true, inEdit: true,
                originalColumnName: columnName,
                customColumnName: columnName,
                reportColumnName: columnName,
            });
        }
    }

    const addExportField = (result, fId, columnName, interfaceConfigurationParametersId) => {
        result.push({
            interfaceConfigurationParametersId: interfaceConfigurationParametersId,
            fId: fId,
            originalColumnName: columnName,
            customColumnName: columnName,
            reportColumnName: columnName,
            optimalColumnWidth: 0,
            pageBreakFieldOnCountList: false,
            noOfCharactersUsedForPageBreakOnCountList: 0,
            sortPositionOnCountList: 0,
            listPositionOnCountList: 0,
            pageBreakFieldOnDifferenceList: false,
            noOfCharactersUsedForPageBreakOnDifferenceList: 0,
            sortPositionOnDifferenceList: 0,
            listPositionOnDifferenceList: 0,
            displayOnInterfaceConfiguration: false,
            usedByImportExport: true,
            decimalPlaces: 0,
            notNullOnImport: 0,
            position: result.reduce((max, current) => current.position > max ? current.position : max, 0) + 1,
            lenght: 0,
            inEdit: true,
            columnOrder: result.reduce((max, current) => current.columnOrder > max ? current.columnOrder : max, 0) + 1
        });
    }

    const loadDecimalSeparators = () => {
        const decSeparators = [{ value: window.enumDecimalSeparator.None, text: intl.formatMessage({ id: 'InterfaceConfiguration.None' }), textValue: 'noneDecimalSeparator' },
        { value: window.enumDecimalSeparator.Point, text: intl.formatMessage({ id: 'DecimalSeparator.Point' }), textValue: '.' },
        { value: window.enumDecimalSeparator.Comma, text: intl.formatMessage({ id: 'DecimalSeparator.Comma' }), textValue: ',' }];

        dispatchConfigGlobal({
            type: CONFIG_CONTEXT_ACTIONS.SetDecimalSeparators,
            payload: decSeparators
        });
    };

    const loadColumnSeparators = () => {
        const separators = getColumnSeparators();

        dispatchConfigGlobal({
            type: CONFIG_CONTEXT_ACTIONS.SetColumnSeparators,
            payload: separators
        });
    };

    const getColumnSeparators = () => {
        return [{ value: window.enumColumnSeparator.Semicolon, text: intl.formatMessage({ id: 'ColumnSeparator.Semicolon' }), textValue: ';' },
        { value: window.enumColumnSeparator.Comma, text: intl.formatMessage({ id: 'ColumnSeparator.Comma' }), textValue: ',' },
        { value: window.enumColumnSeparator.Tabulator, text: intl.formatMessage({ id: 'ColumnSeparator.Tabulator' }), textValue: '	' },
        { value: window.enumColumnSeparator.Custom, text: intl.formatMessage({ id: 'ColumnSeparator.Custom' }) },
        { value: window.enumColumnSeparator.None, text: intl.formatMessage({ id: 'ColumnSeparator.None' }), textValue: 'noneColumnSeparator' },
        ];
    };

    const loadThousandSeparators = () => {
        const separators = [
            { value: window.enumThousandSeparator.Comma, text: intl.formatMessage({ id: 'ThousandSeparators.Comma' }), textValue: ',' },
            { value: window.enumThousandSeparator.Dot, text: intl.formatMessage({ id: 'ThousandSeparators.Dot' }), textValue: '.' },
            { value: window.enumThousandSeparator.Space, text: intl.formatMessage({ id: 'ThousandSeparators.Space' }), textValue: ' ' },
            { value: window.enumThousandSeparator.Apostrophe, text: intl.formatMessage({ id: 'ThousandSeparators.Apostrophe' }), textValue: "'" },
            { value: window.enumThousandSeparator.None, text: intl.formatMessage({ id: 'InterfaceConfiguration.None' }), textValue: 'noneThousandSeparator' }];

        dispatchConfigGlobal({
            type: CONFIG_CONTEXT_ACTIONS.SetThousandSeparators,
            payload: separators
        });
    };

    const loadPriceUnits = () => {
        const values = [{ value: window.enumPriceUnit.Variable, text: intl.formatMessage({ id: 'PriceUnit.Variable' }) },
        { value: window.enumPriceUnit.PowerOf10StartingAt0, text: intl.formatMessage({ id: 'PriceUnit.PowerOf10StartingAt0' }) },
        { value: window.enumPriceUnit.PowerOf10StartingAt1, text: intl.formatMessage({ id: 'PriceUnit.PowerOf10StartingAt1' }) }];

        dispatchConfigGlobal({
            type: CONFIG_CONTEXT_ACTIONS.SetPriceUnits,
            payload: values
        });
    };

    const loadFieldTypes = () => {
        const values = [{ value: window.enumFields.Ident1, text: intl.formatMessage({ id: 'InterfaceConfiguration.UniqueId' }) },
        { value: window.enumFields.Quantity, text: intl.formatMessage({ id: 'InterfaceConfiguration.Quantity' }) },
        { value: window.enumFields.SinglePrice, text: intl.formatMessage({ id: 'InterfaceConfiguration.Price' }) },
        { value: window.enumFields.QuantityUnit, text: intl.formatMessage({ id: 'InterfaceConfiguration.QuantityUnit' }) },
        { value: window.enumFields.PriceUnit, text: intl.formatMessage({ id: 'InterfaceConfiguration.PriceUnit' }) },
        { value: window.enumFields.ItemName, text: intl.formatMessage({ id: 'InterfaceConfiguration.ArticleDescription' }) },
        { value: window.enumFields.AdditionalField1, text: intl.formatMessage({ id: 'InterfaceConfiguration.AdditionalFields' }) },
        { value: window.enumFields.ActualQuantity, text: intl.formatMessage({ id: 'InterfaceConfiguration.ActualQuantity' }) },
        { value: window.enumFields.CategoryMark, text: intl.formatMessage({ id: 'InterfaceConfiguration.CategoryMark' }) },
        { value: window.enumFields.QuantitySign, text: intl.formatMessage({ id: 'InterfaceConfiguration.QuantitySign' }) },
        { value: window.enumFields.PriceSign, text: intl.formatMessage({ id: 'InterfaceConfiguration.PriceSign' }) }];

        dispatchConfigGlobal({
            type: CONFIG_CONTEXT_ACTIONS.SetFieldTypes,
            payload: values
        });
    };

    useEffect(() => {
        loadDecimalSeparators();
        loadColumnSeparators();
        loadThousandSeparators();
        loadPriceUnits();
        loadFieldTypes();
    }, [userProfile]);

    return (
        <div className="content">
            <Row>
                <Col xs="12">
                    <h1><FormattedMessage id='InterfaceConfiguration.Configuration' /> {" "} <small><FormattedMessage id='InterfaceConfiguration.OfImportExportStructures' /></small> </h1>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <ConfigContext.Provider value={configValue}>
                        <div className="content">
                            <Row>
                                <Col xs="12">
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12">
                                    <ConfigTabs
                                        handleError={handleError}
                                        loadData={loadData}
                                        isInterfaceConfigurationLoaded={isInterfaceConfigurationLoaded}
                                        selectedTabIndex={selectedTabIndex}
                                        expanded={expanded}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </ConfigContext.Provider>
                </Col>
            </Row>
        </div>

    )
}

export default Config