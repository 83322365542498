import React, { useState } from 'react';
import { Prompt } from 'react-router-dom';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormCheckbox, FormDropDownList, FormNumericTextBox, FormInput, FormTextArea } from "../../Inventory/Wizard/form-components";
import { FormattedMessage, useIntl } from "react-intl";
import { Col, Row, Button } from 'reactstrap';
import { useNotification } from "../../Shared/Notifications/NotificationProvider";
import { NotificationTypes } from "../../Shared/Notifications/Notification";
import ConfirmDialog from '../../Shared/ConfirmDialog';

const LicenseEditForm = (props) => {
    const intl = useIntl();

    const subscriptionTypeNameValidator = (value) => value ? "" : intl.formatMessage({ id: 'ManageLicense.SubscriptionTypeNameRequired' });
    const pricePerMonthPaymentPerYearValidator = (value) => value != null ? "" : intl.formatMessage({ id: 'ManageLicense.PricePerMonthPaymentPerYearRequired' });
    const pricePerMonthPaymentPerMonthValidator = (value) => value != null ? "" : intl.formatMessage({ id: 'ManageLicense.PricePerMonthPaymentPerMonthRequired' });
    const numberOfPositionsValidator = (value) => value != null ? "" : intl.formatMessage({ id: 'ManageLicense.NumberOfPositionsRequired' });
    const numberOfPopulationsValidator = (value) => value != null ? "" : intl.formatMessage({ id: 'ManageLicense.NumberOfPopulationsRequired' });
    const supportValidator = (value) => value ? "" : intl.formatMessage({ id: 'ManageLicense.SupportRequired' });
    const procedureValidator = (value) => value ? "" : intl.formatMessage({ id: 'ManageLicense.ProcedureRequired' });
    const minimumTermValidator = (value) => value != null ? "" : intl.formatMessage({ id: 'ManageLicense.MinimumTermRequired' });
    const settlementAdditionalPositionPriceValidator = (value) => value != null ? "" : intl.formatMessage({ id: 'ManageLicense.SettlementAdditionalPositionPriceRequired' });

    const dispatchNotification = useNotification();
    const pageId = 'LicenseEditForm';
    const maximumValue = 2147483647;

    const isSaveDisabled = (formRenderProps) => {
        return !formRenderProps.allowSubmit || !formRenderProps.valid
    }

    const isServerDataValid = (dataItem) => {
        const areMissingValues =
            dataItem.pricePerMonthPaymentPerYear == null
            || dataItem.pricePerMonthPaymentPerMonth == null
            || dataItem.settlementAdditionalPositionPrice == null
            || dataItem.numberOfPositions == null
            || dataItem.numberOfPopulations == null
            || dataItem.supportObj == null
            || dataItem.procedureObj == null
            || dataItem.minimumTerm == null;

        if (areMissingValues) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: <FormattedMessage id="ManageLicense.AllValuesRequired" />
            });
            return false;
        }

        const areNegativeValues =
            dataItem.pricePerMonthPaymentPerYear < 0
            || dataItem.pricePerMonthPaymentPerYear > maximumValue
            || dataItem.pricePerMonthPaymentPerMonth < 0
            || dataItem.pricePerMonthPaymentPerMonth > maximumValue
            || dataItem.numberOfPositions < 0
            || dataItem.numberOfPositions > maximumValue
            || dataItem.numberOfPopulations < 0
            || dataItem.numberOfPopulations > maximumValue
            || dataItem.minimumTerm < 0
            || dataItem.minimumTerm > maximumValue;

        if (areNegativeValues) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: <FormattedMessage
                    id="ManageLicense.AllValuesMustBeInRange"
                    values={{
                        Min: 0,
                        Max: maximumValue
                    }}
                />
            });
            return false;
        }

        const isSettlementAdditionalPositionPriceOutOfRange =
            dataItem.settlementAdditionalPositionPrice < 0
            || dataItem.settlementAdditionalPositionPrice > 100;

        if (isSettlementAdditionalPositionPriceOutOfRange) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: <FormattedMessage id="ManageLicense.SettlementAdditionalPositionPriceOutOfRange" />
            });
            return false;
        }

        return true;
    };

    const handleSubmit = (dataItem) => {
        if (isServerDataValid(dataItem)) {
            props.onSubmit(dataItem);
        }
    };

    const handleCancel = () => {
        if (areChanges) {
            setConfirmDialogTitle(<FormattedMessage id='Confirm.UnsavedChangesTitle' />);
            setConfirmDialogDetail(<FormattedMessage id='Confirm.UnsavedChangesText' />);
            setConfirmDialogCallback({
                success: () => {
                    props.cancelEdit();
                }
            });
            setIsConfirmDialogVisible(true);
        }
        else {
            props.cancelEdit();
        }
    };

    const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);
    const [confirmDialogTitle, setConfirmDialogTitle] = useState('');
    const [confirmDialogDetail, setConfirmDialogDetail] = useState('');
    const [confirmDialogCallback, setConfirmDialogCallback] = useState(null);

    const handleConfirmDialogClose = (isConfirmed) => {
        setIsConfirmDialogVisible(false);
        if (isConfirmed) {
            if (confirmDialogCallback) {
                confirmDialogCallback.success();
            }
        }
        else {
            if (confirmDialogCallback && confirmDialogCallback.cancel) {
                confirmDialogCallback.cancel();
            }
        }
    };

    const [isSubscriptionTypeNameChanged, setIsSubscriptionTypeNameChanged] = useState(false);
    const handleSubscriptionTypeNameChange = (event) => {
        const isChanged = event.value !== props.item.subscriptionTypeName;
        setIsSubscriptionTypeNameChanged(isChanged);
    };

    const [isIsLicenseVisibleChanged, setIsIsLicenseVisibleChanged] = useState(false);
    const handleIsLicenseVisibleChange = (event) => {
        const isChanged = event.value !== props.item.isLicenseVisible;
        setIsIsLicenseVisibleChanged(isChanged);
    };

    const [isPricePerMonthPaymentPerYearChanged, setIsPricePerMonthPaymentPerYearChanged] = useState(false);
    const handlePricePerMonthPaymentPerYearChange = (event) => {
        const isChanged = event.value !== props.item.pricePerMonthPaymentPerYear;
        setIsPricePerMonthPaymentPerYearChanged(isChanged);
    };

    const [isIsPricePerMonthPaymentPerYearVisibleChanged, setIsIsPricePerMonthPaymentPerYearVisibleChanged] = useState(false);
    const handleIsPricePerMonthPaymentPerYearVisibleChange = (event) => {
        const isChanged = event.value !== props.item.isPricePerMonthPaymentPerYearVisible;
        setIsIsPricePerMonthPaymentPerYearVisibleChanged(isChanged);
    };

    const [isPricePerMonthPaymentPerMonthChanged, setIsPricePerMonthPaymentPerMonthChanged] = useState(false);
    const handlePricePerMonthPaymentPerMonthChange = (event) => {
        const isChanged = event.value !== props.item.pricePerMonthPaymentPerMonth;
        setIsPricePerMonthPaymentPerMonthChanged(isChanged);
    };

    const [isIsPricePerMonthPaymentPerMonthVisibleChanged, setIsIsPricePerMonthPaymentPerMonthVisibleChanged] = useState(false);
    const handleIsPricePerMonthPaymentPerMonthVisibleChange = (event) => {
        const isChanged = event.value !== props.item.isPricePerMonthPaymentPerMonthVisible;
        setIsIsPricePerMonthPaymentPerMonthVisibleChanged(isChanged);
    };

    const [isNumberOfPositionsChanged, setIsNumberOfPositionsChanged] = useState(false);
    const handleNumberOfPositionsChange = (event) => {
        const isChanged = event.value !== props.item.numberOfPositions;
        setIsNumberOfPositionsChanged(isChanged);
    };

    const [isIsNumberOfPositionsVisibleChanged, setIsIsNumberOfPositionsVisibleChanged] = useState(false);
    const handleIsNumberOfPositionsVisibleChange = (event) => {
        const isChanged = event.value !== props.item.isNumberOfPositionsVisible;
        setIsIsNumberOfPositionsVisibleChanged(isChanged);
    };

    const [isNumberOfPopulationsChanged, setIsNumberOfPopulationsChanged] = useState(false);
    const handleNumberOfPopulationsChange = (event) => {
        const isChanged = event.value !== props.item.numberOfPopulations;
        setIsNumberOfPopulationsChanged(isChanged);
    };

    const [isIsNumberOfPopulationsVisibleChanged, setIsIsNumberOfPopulationsVisibleChanged] = useState(false);
    const handleIsNumberOfPopulationsVisibleChange = (event) => {
        const isChanged = event.value !== props.item.isNumberOfPopulationsVisible;
        setIsIsNumberOfPopulationsVisibleChanged(isChanged);
    };

    const [isMinimumTermChanged, setIsMinimumTermChanged] = useState(false);
    const handleMinimumTermChange = (event) => {
        const isChanged = event.value !== props.item.minimumTerm;
        setIsMinimumTermChanged(isChanged);
    };

    const [isIsMinimumTermVisibleChanged, setIsIsMinimumTermVisibleChanged] = useState(false);
    const handleIsMinimumTermVisibleChange = (event) => {
        const isChanged = event.value !== props.item.isMinimumTermVisible;
        setIsIsMinimumTermVisibleChanged(isChanged);
    };

    const [isSupportChanged, setIsSupportChanged] = useState(false);
    const handleSupportChange = (event) => {
        const isChanged = event.value.value !== props.item.supportId;
        setIsSupportChanged(isChanged);
    };

    const [isIsSupportVisibleChanged, setIsIsSupportVisibleChanged] = useState(false);
    const handleIsSupportVisibleChange = (event) => {
        const isChanged = event.value !== props.item.isSupportVisible;
        setIsIsSupportVisibleChanged(isChanged);
    };

    const [isProcedureChanged, setIsProcedureChanged] = useState(false);
    const handleProcedureChange = (event) => {
        const isChanged = event.value.value !== props.item.procedureId;
        setIsProcedureChanged(isChanged);
    };

    const [isIsProcedureVisibleChanged, setIsIsProcedureVisibleChanged] = useState(false);
    const handleIsProcedureVisibleChange = (event) => {
        const isChanged = event.value !== props.item.isProcedureVisible;
        setIsIsProcedureVisibleChanged(isChanged);
    };

    const [isSettlementAdditionalPositionPriceChanged, setIsSettlementAdditionalPositionPriceChanged] = useState(false);
    const handleSettlementAdditionalPositionPriceChange = (event) => {
        const isChanged = event.value !== props.item.settlementAdditionalPositionPrice;
        setIsSettlementAdditionalPositionPriceChanged(isChanged);
    };

    const [isIsSettlementAdditionalPositionPriceVisibleChanged, setIsIsSettlementAdditionalPositionPriceVisibleChanged] = useState(false);
    const handleIsSettlementAdditionalPositionPriceVisibleChange = (event) => {
        const isChanged = event.value !== props.item.isSettlementAdditionalPositionPriceVisible;
        setIsIsSettlementAdditionalPositionPriceVisibleChanged(isChanged);
    };

    const [isDescriptionChanged, setIsDescriptionChanged] = useState(false);
    const handleDescriptionChange = (event) => {
        const isChanged = event.value !== props.item.description;
        setIsDescriptionChanged(isChanged);
    };

    const [isIsDescriptionVisibleChanged, setIsIsDescriptionVisibleChanged] = useState(false);
    const handleIsDescriptionVisibleChange = (event) => {
        const isChanged = event.value !== props.item.isDescriptionVisible;
        setIsIsDescriptionVisibleChanged(isChanged);
    };

    const areChanges = isSubscriptionTypeNameChanged || isIsLicenseVisibleChanged
        || isPricePerMonthPaymentPerYearChanged || isIsPricePerMonthPaymentPerYearVisibleChanged
        || isPricePerMonthPaymentPerMonthChanged || isIsPricePerMonthPaymentPerMonthVisibleChanged
        || isNumberOfPositionsChanged || isIsNumberOfPositionsVisibleChanged
        || isNumberOfPopulationsChanged || isIsNumberOfPopulationsVisibleChanged
        || isMinimumTermChanged || isIsMinimumTermVisibleChanged
        || isSupportChanged || isIsSupportVisibleChanged
        || isProcedureChanged || isIsProcedureVisibleChanged
        || isSettlementAdditionalPositionPriceChanged || isIsSettlementAdditionalPositionPriceVisibleChanged
        || isDescriptionChanged || isIsDescriptionVisibleChanged;

    return (
        <div>
            <Prompt
                when={areChanges}
                message={JSON.stringify({
                    header: "" + intl.formatMessage({ id: 'Confirm.UnsavedChangesTitle' }),
                    content: "" + intl.formatMessage({ id: 'Confirm.UnsavedChangesText' }),
                    cancel: "" + intl.formatMessage({ id: 'Control.Cancel' }),
                    confirm: "" + intl.formatMessage({ id: 'Control.Confirm' })
                })}
            />

            <Dialog
                title={intl.formatMessage({ id: "Control.Edit" }) + " " + props.item.subscriptionTypeName}
                className="manage-license-dialog"
                onClose={handleCancel}>
                <Form
                    onSubmit={handleSubmit}
                    initialValues={props.item}
                    render={(formRenderProps) => (
                        <FormElement>
                            <fieldset className={"k-form-fieldset"}>
                                <Row>
                                    <Col xs="10">
                                        <div className="mb-3">
                                            <Field
                                                name={"subscriptionTypeName"}
                                                component={FormInput}
                                                validator={subscriptionTypeNameValidator}
                                                maxLength={256}
                                                label={intl.formatMessage({ id: 'ManageLicense.Subscription' })}
                                                onChange={handleSubscriptionTypeNameChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs="2">
                                        <Field
                                            name={"isLicenseVisible"}
                                            component={FormCheckbox}
                                            label={intl.formatMessage({ id: 'ManageLicense.Visible' })}
                                            onChange={handleIsLicenseVisibleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" lg="6">
                                        <Row>
                                            <Col xs="8">
                                                <div className="mb-3">
                                                    <Field
                                                        name={"pricePerMonthPaymentPerYear"}
                                                        component={FormNumericTextBox}
                                                        validator={pricePerMonthPaymentPerYearValidator}
                                                        min={0}
                                                        max={maximumValue}
                                                        format="n2"
                                                        label={intl.formatMessage({ id: 'ManageLicense.PricePerMonthPaymentPerYear' })}
                                                        onChange={handlePricePerMonthPaymentPerYearChange}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs="4">
                                                <Field
                                                    name={"isPricePerMonthPaymentPerYearVisible"}
                                                    component={FormCheckbox}
                                                    label={intl.formatMessage({ id: 'ManageLicense.Visible' })}
                                                    onChange={handleIsPricePerMonthPaymentPerYearVisibleChange}
                                                />
                                            </Col>
                                            <Col xs="8">
                                                <div className="mb-3">
                                                    <Field
                                                        id={"supportObj"}
                                                        name={"supportObj"}
                                                        label={intl.formatMessage({ id: 'ManageLicense.Support' })}
                                                        component={FormDropDownList}
                                                        textField={"text"}
                                                        dataItemKey={"value"}
                                                        data={props.supports}
                                                        validator={supportValidator}
                                                        onChange={handleSupportChange}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs="4">
                                                <Field
                                                    name={"isSupportVisible"}
                                                    component={FormCheckbox}
                                                    label={intl.formatMessage({ id: 'ManageLicense.Visible' })}
                                                    onChange={handleIsSupportVisibleChange}
                                                />
                                            </Col>
                                            <Col xs="8">
                                                <div className="mb-3">
                                                    <Field
                                                        name={"numberOfPopulations"}
                                                        component={FormNumericTextBox}
                                                        validator={numberOfPopulationsValidator}
                                                        min={0}
                                                        max={maximumValue}
                                                        format="n0"
                                                        label={intl.formatMessage({ id: 'ManageLicense.NumberOfPopulations' })}
                                                        onChange={handleNumberOfPopulationsChange}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs="4">
                                                <Field
                                                    name={"isNumberOfPopulationsVisible"}
                                                    component={FormCheckbox}
                                                    label={intl.formatMessage({ id: 'ManageLicense.Visible' })}
                                                    onChange={handleIsNumberOfPopulationsVisibleChange}
                                                />
                                            </Col>
                                            <Col xs="8">
                                                <div className="mb-3">
                                                    <Field
                                                        name={"minimumTerm"}
                                                        component={FormNumericTextBox}
                                                        validator={minimumTermValidator}
                                                        min={0}
                                                        max={maximumValue}
                                                        format="n0"
                                                        label={intl.formatMessage({ id: 'ManageLicense.MinimumTerm' })}
                                                        onChange={handleMinimumTermChange}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs="4">
                                                <Field
                                                    name={"isMinimumTermVisible"}
                                                    component={FormCheckbox}
                                                    label={intl.formatMessage({ id: 'ManageLicense.Visible' })}
                                                    onChange={handleIsMinimumTermVisibleChange}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs="12" lg="6">
                                        <Row>
                                            <Col xs="8">
                                                <div className="mb-3">
                                                    <Field
                                                        name={"pricePerMonthPaymentPerMonth"}
                                                        component={FormNumericTextBox}
                                                        validator={pricePerMonthPaymentPerMonthValidator}
                                                        min={0}
                                                        max={maximumValue}
                                                        format="n2"
                                                        label={intl.formatMessage({ id: 'ManageLicense.PricePerMonthPaymentPerMonth' })}
                                                        onChange={handlePricePerMonthPaymentPerMonthChange}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs="4">
                                                <Field
                                                    name={"isPricePerMonthPaymentPerMonthVisible"}
                                                    component={FormCheckbox}
                                                    label={intl.formatMessage({ id: 'ManageLicense.Visible' })}
                                                    onChange={handleIsPricePerMonthPaymentPerMonthVisibleChange}
                                                />
                                            </Col>
                                            <Col xs="8">
                                                <div className="mb-3">
                                                    <Field
                                                        id={"procedureObj"}
                                                        name={"procedureObj"}
                                                        label={intl.formatMessage({ id: 'ManageLicense.Procedure' })}
                                                        component={FormDropDownList}
                                                        textField={"text"}
                                                        dataItemKey={"value"}
                                                        data={props.procedures}
                                                        validator={procedureValidator}
                                                        onChange={handleProcedureChange}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs="4">
                                                <Field
                                                    name={"isProcedureVisible"}
                                                    component={FormCheckbox}
                                                    label={intl.formatMessage({ id: 'ManageLicense.Visible' })}
                                                    onChange={handleIsProcedureVisibleChange}
                                                />
                                            </Col>
                                            <Col xs="8">
                                                <div className="mb-3">
                                                    <Field
                                                        name={"numberOfPositions"}
                                                        component={FormNumericTextBox}
                                                        validator={numberOfPositionsValidator}
                                                        min={0}
                                                        max={maximumValue}
                                                        format="n0"
                                                        label={intl.formatMessage({ id: 'ManageLicense.NumberOfPositions' })}
                                                        onChange={handleNumberOfPositionsChange}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs="4">
                                                <Field
                                                    name={"isNumberOfPositionsVisible"}
                                                    component={FormCheckbox}
                                                    label={intl.formatMessage({ id: 'ManageLicense.Visible' })}
                                                    onChange={handleIsNumberOfPositionsVisibleChange}
                                                />
                                            </Col>
                                            <Col xs="8">
                                                <div className="mb-3">
                                                    <Field
                                                        name={"settlementAdditionalPositionPrice"}
                                                        component={FormNumericTextBox}
                                                        validator={settlementAdditionalPositionPriceValidator}
                                                        min={0}
                                                        max={100}
                                                        format="n2"
                                                        label={intl.formatMessage({ id: 'ManageLicense.SettlementAdditionalPositionPrice' })}
                                                        onChange={handleSettlementAdditionalPositionPriceChange}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs="4">
                                                <Field
                                                    name={"isSettlementAdditionalPositionPriceVisible"}
                                                    component={FormCheckbox}
                                                    label={intl.formatMessage({ id: 'ManageLicense.Visible' })}
                                                    onChange={handleIsSettlementAdditionalPositionPriceVisibleChange}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="10">
                                        <div className="mb-3">
                                            <Field
                                                name={"description"}
                                                component={FormTextArea}
                                                label={intl.formatMessage({ id: 'ManageLicense.Description' })}
                                                rows={2}
                                                onChange={handleDescriptionChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs="2">
                                        <Field
                                            name={"isDescriptionVisible"}
                                            component={FormCheckbox}
                                            label={intl.formatMessage({ id: 'ManageLicense.Visible' })}
                                            onChange={handleIsDescriptionVisibleChange}
                                        />
                                    </Col>
                                </Row>
                            </fieldset>

                            <DialogActionsBar>
                                <div className="k-form-buttons k-justify-content-stretch">
                                    <Button
                                        type={"button"}
                                        color="secondary"
                                        onClick={handleCancel}
                                    >
                                        <FormattedMessage id='Control.Cancel' />
                                    </Button>
                                    <Button
                                        type={"submit"}
                                        color="primary"
                                        disabled={isSaveDisabled(formRenderProps)}
                                    >
                                        <FormattedMessage id="Control.Save" />
                                    </Button>
                                </div>
                            </DialogActionsBar>
                        </FormElement>
                    )}
                />
            </Dialog>

            <ConfirmDialog
                visible={isConfirmDialogVisible}
                onClose={handleConfirmDialogClose}
                negative={<FormattedMessage id='Control.Cancel' />}
                positive={<FormattedMessage id='Control.Confirm' />}
                title={confirmDialogTitle}
                detail={confirmDialogDetail}
            />
        </div>
    );
};

export default LicenseEditForm;
