import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { process } from "@progress/kendo-data-query";
import { Card, CardBody, CardHeader, Button, Row, Col, Input } from 'reactstrap';
import EditIndividualForm from "./EditIndividualForm";
import { getLocale, formatDate, formatNumber } from '../../../utils/localization';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLoading } from '../../Shared/LoadingContext';
import { useNotification } from '../../Shared/Notifications/NotificationProvider';
import { useExceptionDialog } from '../../Shared/ExceptionDialog/ExceptionDialogProvider';
import { NotificationTypes } from '../../Shared/Notifications/Notification';
import {
    getCustomerLicenses,
    exportCustomerLicenses,
    getSupports,
    addIndividualLicense,
    updateIndividualLicense,
    activateIndividualLicense,
    suspendCustomerLicense,
    reactivateCustomerLicense,
    cancelCustomerLicense,
    deleteCustomerLicense,
    getCustomerIndividualLicenseStatus,
    getCustomerStatus
} from '../../../services/licenseManagementService';
import { getCustomers } from '../../../services/masterDataService';
import ReactTooltip from 'react-tooltip';
import GTCAcceptedOnIndividualForm from './GTCAcceptedOnIndividualForm';
import ConfirmDialog from '../../Shared/ConfirmDialog';
import GridFooter from '../../Shared/GridFooter';
import { RefreshUserContext } from '../../UserContext';
import {
    IntlProvider,
    LocalizationProvider
} from "@progress/kendo-react-intl";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faDownload,
    faEye,
    faPauseCircle,
    faPlayCircle,
    faPencilAlt,
    faPlus,
    faTimesCircle,
    faTrashAlt
} from '@fortawesome/pro-light-svg-icons'


export const IndividualLicenseEditorState = {
    Add: 0,
    Edit: 1,
    View: 2
};

const EditCommandCell = (props) => {
    const isEditAllowed = props.dataItem.licenseStatus === window.enumSubscriptionStatus.Pending;
    const isActivateAllowed = props.dataItem.licenseStatus === window.enumSubscriptionStatus.Pending;
    const isSuspendAllowed = props.dataItem.licenseStatus === window.enumSubscriptionStatus.Active;
    const isReactivateAllowed = props.dataItem.licenseStatus === window.enumSubscriptionStatus.Suspended;
    const isCancelAllowed = (props.dataItem.licenseStatus === window.enumSubscriptionStatus.Active || props.dataItem.licenseStatus === window.enumSubscriptionStatus.Suspended) && props.dataItem.licenseCancellationDate == null;
    const intl = useIntl();

    return (
        <td>
            <ReactTooltip id="buttonTooltip" place="top" type="error" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />
            <Button
                color="link"
                onClick={() => props.onViewItem(props.dataItem)}
                data-for="buttonTooltip"
                data-tip={intl.formatMessage({ id: 'ManageLicense.ViewDetails' })}
            >
                <FontAwesomeIcon icon={faEye} />
            </Button>

            {isEditAllowed && (
                <Button
                    color="link"
                    onClick={() => props.onEditItem(props.dataItem)}
                    data-for="buttonTooltip"
                    data-tip={intl.formatMessage({ id: 'ManageLicense.SetGTCAcceptanceDateTooltip' })}
                >
                    <FontAwesomeIcon icon={faPencilAlt} />
                </Button>
            )}

            {isActivateAllowed && (
                <Button
                    color="link"
                    onClick={() => props.onActivateItem(props.dataItem)}
                    data-for="buttonTooltip"
                    data-tip={intl.formatMessage({ id: 'ManageLicense.ActivateTooltip' })}
                >
                    <FontAwesomeIcon icon={faPlayCircle} />
                </Button>
            )}

            {isSuspendAllowed && (
                <Button
                    color="link"
                    onClick={() => props.onSuspendItem(props.dataItem)}
                    data-for="buttonTooltip"
                    data-tip={intl.formatMessage({ id: 'ManageLicense.SuspendTooltip' })}
                >
                    <FontAwesomeIcon icon={faPauseCircle} />
                </Button>
            )}

            {isReactivateAllowed && (
                <Button
                    color="link"
                    onClick={() => props.onReactivateItem(props.dataItem)}
                    data-for="buttonTooltip"
                    data-tip={intl.formatMessage({ id: 'ManageLicense.ReactivateTooltip' })}
                >
                    <FontAwesomeIcon icon={faPlayCircle} />

                </Button>
            )}

            {isCancelAllowed && (
                <Button
                    color="link"
                    onClick={() => props.onCancelItem(props.dataItem)}
                    data-for="buttonTooltip"
                    data-tip={intl.formatMessage({ id: 'ManageLicense.CancelTooltip' })}
                >
                    <FontAwesomeIcon icon={faTimesCircle} />
                </Button>
            )}

            <Button
                color="link"
                onClick={() => props.onDeleteItem(props.dataItem)}
                data-for="buttonTooltip"
                data-tip={intl.formatMessage({ id: 'ManageLicense.DeleteTooltip' })}
            >
                <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
        </td>
    );
};

const ManageIndividualLicense = () => {
    const intl = useIntl();
    const currentCustomer = useSelector(rxState => rxState.dashboard.customer);
    const userProfile = useSelector(state => state.profile.profile);
    const locale = getLocale(userProfile.userLanguage);
    const language = getLocale(userProfile.userLanguage, true);
    const [openForm, setOpenForm] = useState(false);
    const [openFormGTCAccepted, setOpenFormGTCAccepted] = useState(false);
    const [editItem, setEditItem] = useState({});
    const [editorState, setEditorState] = useState(null);
    const [isEditorSaveDisabled, setIsEditorSaveDisabled] = useState(false);
    const [customerLicenses, setCustomerLicenses] = useState([]);
    const [translatedCustomerLicenses, setTranslatedCustomerLicenses] = useState([]);

    const [filterCustomerName, setFilterCustomerName] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [filterExpirationDate, setFilterExpirationDate] = useState('');
    const [filterStartDate, setFilterStartDate] = useState('');
    const [filterSubscriptionName, setFilterSubscriptionName] = useState('');

    const [filteredCustomerLicenses, setFilteredCustomerLicenses] = useState([]);
    const [dataState, setDataState] = useState({});
    const [gridData, setGridData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [isPagingVisible, setIsPagingVisible] = useState(false);

    const minGridLength = 5;

    const statuses = [
        '',
        intl.formatMessage({ id: 'ManageLicense.Status_1' }),
        intl.formatMessage({ id: 'ManageLicense.Status_2' }),
        intl.formatMessage({ id: 'ManageLicense.Status_3' }),
        intl.formatMessage({ id: 'ManageLicense.Status_4' }),
        intl.formatMessage({ id: 'ManageLicense.Status_5' }),
        intl.formatMessage({ id: 'ManageLicense.Status_6' })
    ];

    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const pageId = 'ManageIndividualLicense';

    const refreshUserContext = RefreshUserContext();

    const { setLoading } = useLoading();
    const [loadingCount, setLoadingCount] = useState(0);
    useEffect(() => {
        setLoading(loadingCount > 0);
    }, [loadingCount]);

    const incrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount + 1);
    };

    const decrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount - 1);
    };

    useEffect(() => {
        return () => {
            removeNotification();
        };
    }, []);

    const [supports, setSupports] = useState([]);
    useEffect(() => {
        incrementLoadingCount();
        getSupports(
            values => {
                decrementLoadingCount();
                setSupports(values);
            },
            handleError);
    }, []);

    const [customers, setCustomers] = useState([]);
    useEffect(() => {
        incrementLoadingCount();
        getCustomers(
            values => {
                decrementLoadingCount();
                setCustomers(values);
            },
            handleError);
    }, []);

    const getTranslatedProcedureText = procVal => {
        let translated = '';
        if (procVal != null) {
            translated = intl.formatMessage({ id: 'ManageLicense.Procedure_' + procVal });
        }
        return translated;
    };

    const getTranslatedStatusText = status => {
        let translated = '';
        if (status != null) {
            translated = intl.formatMessage({ id: 'ManageLicense.Status_' + status });
        }
        return translated;
    };

    const procedures = Object.keys(window.enumProcedure).map(procedure => { return { text: getTranslatedProcedureText(window.enumProcedure[procedure]), value: window.enumProcedure[procedure] } });

    useEffect(() => {
        if (!supports || supports.length === 0
            || !customers || customers.length === 0
            || !procedures || procedures.length === 0) {
            return;
        }

        loadCustomerLicenses();
    }, [supports, customers]);

    useEffect(() => {
        if (!customerLicenses || customerLicenses.length === 0) {
            return;
        }

        let data = translateData(customerLicenses);
        setTranslatedCustomerLicenses(data);

    }, [userProfile.userLanguage]);

    useEffect(() => {
        setIsPagingVisible(filteredCustomerLicenses.length > minGridLength);
        setPageHandler(1);
    }, [filteredCustomerLicenses, itemsPerPage]);

    useEffect(() => {
        applyFilter();
    }, [translatedCustomerLicenses, filterCustomerName, filterStatus, filterExpirationDate, filterStartDate, filterSubscriptionName]);

    const applyFilter = () => {
        const results = translatedCustomerLicenses.filter(x =>
            x.customerName.toLowerCase().includes(filterCustomerName.toLowerCase()) &&
            (filterStatus ? x.status === filterStatus : true) &&
            (filterExpirationDate ? x.expirationDate === filterExpirationDate : true) &&
            (filterStartDate ? x.startDate === filterStartDate : true) &&
            x.subscriptionName.toLowerCase().includes(filterSubscriptionName.toLowerCase())
        );
        setFilteredCustomerLicenses(results);
    };

    const loadCustomerLicenses = () => {
        removeNotification();
        incrementLoadingCount();
        getCustomerLicenses(
            licenseInfos => {
                decrementLoadingCount();
                setCustomerLicenses(licenseInfos);
                let data = translateData(licenseInfos);
                setTranslatedCustomerLicenses(data);
            },
            handleError);
    };

    const translateData = (data) => {
        let items = data;

        items.forEach((item, index, arr) => {
            const customer = customers.find(x => item.customerId === x.customerId);
            arr[index].customerObj = customer;

            const support = supports.find(x => item.supportId === x.value);
            arr[index].supportObj = support;

            const procedure = procedures.find(x => item.procedure === x.value);
            arr[index].procedureObj = procedure;

            arr[index].procedureName = procedure.text;
            arr[index].status = getTranslatedStatusText(item.licenseStatus);
            arr[index].startDate = formatDate(item.licenseStartDate, locale);
            arr[index].expirationDate = formatDate(item.licenseEndDate, locale);

            arr[index].licenseStartDate = new Date(item.licenseStartDate);
            arr[index].licenseEndDate = new Date(item.licenseEndDate);
            arr[index].gtcAcceptedOn = item.gtcAcceptedOn ? new Date(item.gtcAcceptedOn) : null;

            arr[index].subscriptionName = item.subscriptionType === window.enumSubscriptionType.Individual
                ? intl.formatMessage({ id: 'ManageLicense.Individual' })
                : item.subscriptionTypeName;
        });

        return items;
    };

    const handleError = (errorMessage, showNotif = true) => {
        decrementLoadingCount();
        setIsEditorSaveDisabled(false);

        if (errorMessage === "Upgrade not valid") {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: <FormattedMessage id='ManageLicense.NoDowngradeLicense' />
            });
        }
        else if (errorMessage.startsWith("Failed to send email after adding the individual license:")) {
            setOpenForm(false);
            setOpenFormGTCAccepted(false);
            loadCustomerLicenses();
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.success,
                message: <FormattedMessage id='ManageLicense.SaveLicensesSuccess' />
            });
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: <FormattedMessage id='ManageLicense.FailedToSendIndividualLicenseEmail' />
            });
        }
        else {
            if (showNotif) {
                dispatchNotification({
                    pageId: pageId,
                    type: NotificationTypes.error,
                    message: errorMessage
                });
            }
            else {
                dispatchExceptionDialog({
                    pageId: pageId,
                    message: errorMessage
                });
            }
        }
    };

    const removeNotification = () => {
        dispatchNotification({
            remove: true,
            pageId: pageId
        });
    };

    const handleEditItem = (item) => {
        setEditorState(IndividualLicenseEditorState.Edit);
        setEditItem(item);
        setOpenFormGTCAccepted(true);
    };

    const [isConfirmDialogVisible, setIsConfirmDialogVisible] = React.useState(false);
    const [confirmDialogTitle, setConfirmDialogTitle] = React.useState('');
    const [confirmDialogDetail, setConfirmDialogDetail] = React.useState('');
    const [confirmDialogOkText, setConfirmDialogOkText] = React.useState('');
    const [confirmDialogCallback, setConfirmDialogCallback] = React.useState(null);

    const handleActivateItem = (item) => {
        setConfirmDialogTitle(intl.formatMessage({ id: 'ManageLicense.ActivateConfirmationTitle' }));
        setConfirmDialogDetail(intl.formatMessage({ id: 'ManageLicense.ActivateConfirmationDetail' }));
        setConfirmDialogOkText(intl.formatMessage({ id: 'Control.Activate' }));
        setConfirmDialogCallback({
            success: () => handleActivateIndividualLicense(item)
        });
        setIsConfirmDialogVisible(true);
    };

    const handleActivateIndividualLicense = (item) => {
        removeNotification();
        incrementLoadingCount();

        activateIndividualLicense(
            item.customerLicenseId,
            () => {
                decrementLoadingCount();
                loadCustomerLicenses();
                dispatchNotification({
                    pageId: pageId,
                    type: NotificationTypes.success,
                    message: <FormattedMessage id='ManageLicense.ActivateSuccess' />
                });
            },
            handleError);
    };

    const handleSuspendItem = (item) => {
        setConfirmDialogTitle(intl.formatMessage({ id: 'ManageLicense.SuspendConfirmationTitle' }));
        setConfirmDialogDetail(intl.formatMessage({ id: 'ManageLicense.SuspendConfirmationDetail' }));
        setConfirmDialogOkText(intl.formatMessage({ id: 'Control.Suspend' }));
        setConfirmDialogCallback({
            success: () => handleSuspendCustomerLicense(item)
        });
        setIsConfirmDialogVisible(true);
    };

    const handleSuspendCustomerLicense = (item) => {
        removeNotification();
        incrementLoadingCount();

        suspendCustomerLicense(
            item.customerLicenseId,
            () => {
                decrementLoadingCount();
                loadCustomerLicenses();
                dispatchNotification({
                    pageId: pageId,
                    type: NotificationTypes.success,
                    message: <FormattedMessage id='ManageLicense.SuspendSuccess' />
                });
            },
            handleError);
    };

    const handleReactivateItem = (item) => {
        setConfirmDialogTitle(intl.formatMessage({ id: 'ManageLicense.ReactivateConfirmationTitle' }));
        setConfirmDialogDetail(intl.formatMessage({ id: 'ManageLicense.ReactivateConfirmationDetail' }));
        setConfirmDialogOkText(intl.formatMessage({ id: 'Control.Reactivate' }));
        setConfirmDialogCallback({
            success: () => handleReactivateCustomerLicense(item)
        });
        setIsConfirmDialogVisible(true);
    };

    const handleReactivateCustomerLicense = (item) => {
        removeNotification();
        incrementLoadingCount();

        reactivateCustomerLicense(
            item.customerLicenseId,
            () => {
                decrementLoadingCount();
                loadCustomerLicenses();
                dispatchNotification({
                    pageId: pageId,
                    type: NotificationTypes.success,
                    message: <FormattedMessage id='ManageLicense.ReactivateSuccess' />
                });
            },
            handleError);
    };

    const handleCancelItem = (item) => {
        setConfirmDialogTitle(intl.formatMessage({ id: 'ManageLicense.CancelConfirmationTitle' }));
        setConfirmDialogDetail(intl.formatMessage({ id: 'ManageLicense.CancelConfirmationDetail' }));
        setConfirmDialogOkText(intl.formatMessage({ id: 'Control.Confirm' }));
        setConfirmDialogCallback({
            success: () => handleCancelCustomerLicense(item)
        });
        setIsConfirmDialogVisible(true);
    };

    const handleCancelCustomerLicense = (item) => {
        removeNotification();
        incrementLoadingCount();

        cancelCustomerLicense(
            item.customerLicenseId,
            () => {
                decrementLoadingCount();
                loadCustomerLicenses();
                dispatchNotification({
                    pageId: pageId,
                    type: NotificationTypes.success,
                    message: <FormattedMessage id='ManageLicense.CancelSuccess' />
                });
            },
            handleError);
    };

    const handleDeleteItem = (item) => {
        if (item.licenseStatus === window.enumSubscriptionStatus.Active) {
            removeNotification();
            incrementLoadingCount();

            getCustomerStatus(
                item.customerObj.customerId,
                status => {
                    decrementLoadingCount();
                    if (status.isAboveBasicLicense) {
                        const message = intl.formatMessage({ id: "ManageLicense.CustomerStatus" }, {
                            "PopulationCount": formatNumber(status.numberOfPopulations, locale),
                            "PositionCount": formatNumber(status.numberOfPositions, locale)
                        });
                        confirmDeleteItem(item, message);
                    }
                    else {
                        confirmDeleteItem(item);
                    }
                },
                handleError);
        }
        else {
            confirmDeleteItem(item);
        }
    };

    const confirmDeleteItem = (item, message) => {
        const generalMessage = intl.formatMessage({ id: 'ManageLicense.DeleteConfirmation' }, { 'SubscriptionName': item.subscriptionTypeName });
        const details = message
            ? [generalMessage, message]
            : generalMessage;

        setConfirmDialogTitle(intl.formatMessage({ id: 'ManageLicense.DeleteConfirmationTitle' }));
        setConfirmDialogDetail(details);
        setConfirmDialogOkText(intl.formatMessage({ id: 'Control.Delete' }));
        setConfirmDialogCallback({
            success: () => handleDeleteCustomerLicense(item)
        });
        setIsConfirmDialogVisible(true);
    };

    const handleDeleteCustomerLicense = (item) => {
        removeNotification();
        incrementLoadingCount();

        deleteCustomerLicense(
            item.customerLicenseId,
            () => {
                decrementLoadingCount();
                loadCustomerLicenses();
                dispatchNotification({
                    pageId: pageId,
                    type: NotificationTypes.success,
                    message: <FormattedMessage id='ManageLicense.DeleteSuccess' />
                });
                if (item.customerId == currentCustomer.customerId) {
                    refreshUserContext(handleError);
                }
            },
            handleError);
    };

    const handleViewItem = (item) => {
        setEditorState(IndividualLicenseEditorState.View);
        setEditItem(item);
        setOpenForm(true);
    };

    const addNewLicenseClicked = () => {
        setEditorState(IndividualLicenseEditorState.Add);
        setEditItem({
            licenseStatus: window.enumSubscriptionStatus.Pending,
            status: getTranslatedStatusText(window.enumSubscriptionStatus.Pending)
        });
        setOpenForm(true);
    };

    const handleSubmit = (item) => {
        if (editorState === IndividualLicenseEditorState.Add) {
            handleAddItem(item);
        }
        else if (editorState === IndividualLicenseEditorState.Edit) {
            handleUpdateIndividualLicense(item);
        }
    };

    const handleAddItem = (item) => {
        setIsEditorSaveDisabled(true);
        removeNotification();
        incrementLoadingCount();

        getCustomerIndividualLicenseStatus(
            item.customerObj.customerId,
            status => {
                decrementLoadingCount();
                if (status.hasPendingLicense || status.hasActiveLicense) {
                    setConfirmDialogTitle(intl.formatMessage({ id: 'ManageLicense.AddConfirmationTitle' }));
                    setConfirmDialogDetail(intl.formatMessage({ id: status.hasActiveLicense ? 'ManageLicense.AddConfirmationDetailActive' : 'ManageLicense.AddConfirmationDetailPending' }));
                    setConfirmDialogOkText(intl.formatMessage({ id: 'Control.Confirm' }));
                    setConfirmDialogCallback({
                        success: () => handleAddIndividualLicense(item)
                    });
                    setIsConfirmDialogVisible(true);
                }
                else {
                    handleAddIndividualLicense(item);
                }
            },
            handleError);
    };

    const handleAddIndividualLicense = (item) => {
        removeNotification();
        incrementLoadingCount();

        const data = {
            customerId: item.customerObj.customerId,
            subscriptionTypeName: item.subscriptionTypeName,
            supportId: item.supportObj.value,
            procedure: item.procedureObj.value,
            pricePerMonthPaymentPerYear: item.pricePerMonthPaymentPerYear,
            pricePerMonthPaymentPerMonth: item.pricePerMonthPaymentPerMonth,
            settlementAdditionalPositionPrice: item.settlementAdditionalPositionPrice,
            numberOfPositions: item.numberOfPositions,
            numberOfPopulations: item.numberOfPopulations,
            minimumTerm: item.minimumTerm,
            description: item.description,
            licenseStartDate: new Date(Date.UTC(item.licenseStartDate.getFullYear(), item.licenseStartDate.getMonth(), item.licenseStartDate.getDate())),
            licenseEndDate: new Date(Date.UTC(item.licenseEndDate.getFullYear(), item.licenseEndDate.getMonth(), item.licenseEndDate.getDate())),
            gtcAcceptedOn: item.gtcAcceptedOn
                ? new Date(Date.UTC(item.gtcAcceptedOn.getFullYear(), item.gtcAcceptedOn.getMonth(), item.gtcAcceptedOn.getDate()))
                : null,
            confirmationEmailAddress: item.confirmationEmailAddress
        };

        addIndividualLicense(
            data,
            handleSuccess,
            handleError);
    };

    const handleUpdateIndividualLicense = (item) => {
        removeNotification();
        incrementLoadingCount();

        const data = {
            customerLicenseId: item.customerLicenseId,
            gtcAcceptedOn: new Date(Date.UTC(item.gtcAcceptedOn.getFullYear(), item.gtcAcceptedOn.getMonth(), item.gtcAcceptedOn.getDate()))
        };

        updateIndividualLicense(
            data,
            handleSuccess,
            handleError);
    };

    const handleSuccess = () => {
        decrementLoadingCount();
        setOpenForm(false);
        setOpenFormGTCAccepted(false);
        setIsEditorSaveDisabled(false);
        loadCustomerLicenses();
        dispatchNotification({
            pageId: pageId,
            type: NotificationTypes.success,
            message: <FormattedMessage id='ManageLicense.SaveLicensesSuccess' />
        });
    };

    const handleCancelEdit = () => {
        setOpenForm(false);
        setOpenFormGTCAccepted(false);
    };

    const handleExportButton = () => {
        removeNotification();
        incrementLoadingCount();
        exportCustomerLicenses(
            null,
            result => {
                decrementLoadingCount();
                const url = window.URL.createObjectURL(
                    new Blob([result]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    result.name,
                );

                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            },
            handleError);

    };

    const handleConfirmDialogClose = (isConfirmed) => {
        setIsConfirmDialogVisible(false);
        if (isConfirmed) {
            if (confirmDialogCallback) {
                confirmDialogCallback.success();
            }
        }
        else {
            setIsEditorSaveDisabled(false);
        }
    };

    const handleFilterCustomerNameChange = (event) => {
        setCurrentPage(1);
        setFilterCustomerName(event.target.value);
    };

    const handleFilterStatusChange = (event) => {
        setCurrentPage(1);
        setFilterStatus(event.value);
    };

    const handleFilterExpirationDateChange = (event) => {
        setCurrentPage(1);
        const formattedDate = formatDate(event.value, locale);
        setFilterExpirationDate(formattedDate);
    };

    const handleFilterStartDateChange = (event) => {
        setCurrentPage(1);
        const formattedDate = formatDate(event.value, locale);
        setFilterStartDate(formattedDate);
    };

    const handleFilterSubscriptionNameChange = (event) => {
        setCurrentPage(1);
        setFilterSubscriptionName(event.target.value);
    };

    const handleItemsPerPageChange = (dropDownItem) => {
        setItemsPerPage(dropDownItem.value);
    };

    const setPageHandler = (page) => {
        if (filteredCustomerLicenses && page > 0 && (page <= Math.floor((filteredCustomerLicenses.length - 1) / itemsPerPage) + 1 || filteredCustomerLicenses.length == 0)) {
            const startIndex = (page - 1) * itemsPerPage;
            const endIndex = page * itemsPerPage;
            setGridData(filteredCustomerLicenses.slice(startIndex, endIndex));
            if (translatedCustomerLicenses.length > itemsPerPage) {
                setCurrentPage(page);
            }
            else {
                setCurrentPage(1);
            }
        }
    };

    const MyEditCommandCell = (props) => (
        <EditCommandCell
            {...props}
            onEditItem={handleEditItem}
            onActivateItem={handleActivateItem}
            onSuspendItem={handleSuspendItem}
            onReactivateItem={handleReactivateItem}
            onCancelItem={handleCancelItem}
            onDeleteItem={handleDeleteItem}
            onViewItem={handleViewItem}
        />
    );

    return <React.Fragment>
        <Card>
            <CardHeader>
                <h3>
                    <FormattedMessage id='ManageLicense.AllSubscriptions' />
                    <div className="pull-right">
                        <ReactTooltip id="buttonTipExport" place="left" type="error" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />
                        <Button className="btn btn-secondary" onClick={handleExportButton} data-for="buttonTipExport" data-tip={intl.formatMessage({ id: 'HistoryLicense.ExportIndividualLicense' })}>
                            <FontAwesomeIcon icon={faDownload} />
                        </Button>
                        <ReactTooltip id="buttonTipAdd" place="left" type="error" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />
                        <button className="btn btn-primary" onClick={addNewLicenseClicked} data-for="buttonTipAdd" data-tip={intl.formatMessage({ id: 'ManageLicense.AddIndividualSubscription' })}>
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </div>
                </h3>
            </CardHeader>
            <CardBody>
                <LocalizationProvider language={locale}>
                    <IntlProvider locale={language}>
                        <Row className="filter-row">
                            <Col xs="2">
                                <Input
                                    name="filterCustomerName"
                                    className="grid-filter"
                                    placeholder={intl.formatMessage({ id: 'Reports.ReportFilter.Customer' })}
                                    onChange={handleFilterCustomerNameChange}
                                />
                            </Col>
                            <Col xs="2">
                                <DropDownList
                                    name="filterStatus"
                                    className="grid-filter"
                                    data={statuses}
                                    onChange={handleFilterStatusChange}
                                />
                            </Col>
                            <Col xs="2">
                                <DatePicker
                                    name="filterExpirationDate"
                                    className="grid-filter"
                                    onChange={handleFilterExpirationDateChange}
                                />
                            </Col>
                            <Col xs="2">
                                <DatePicker
                                    name="filterStartDate"
                                    className="grid-filter"
                                    onChange={handleFilterStartDateChange}
                                />
                            </Col>
                            <Col xs="2">
                                <Input
                                    name="filterSubscriptionName"
                                    className="grid-filter"
                                    placeholder={intl.formatMessage({ id: 'ManageLicense.Subscription' })}
                                    onChange={handleFilterSubscriptionNameChange}
                                />
                            </Col>
                        </Row>
                    </IntlProvider>
                </LocalizationProvider>

                <Grid
                    data={process(gridData, dataState)}
                    {...dataState}
                    onDataStateChange={(e) => {
                        setDataState(e.dataState);
                    }}
                >
                    <Column field="customerName" title={intl.formatMessage({ id: 'Reports.ReportFilter.Customer' })} />
                    <Column field="status" title={intl.formatMessage({ id: 'ManageLicense.Status' })} />
                    <Column field="expirationDate" title={intl.formatMessage({ id: 'MasterData.ExpirationDate' })} />
                    <Column field="startDate" title={intl.formatMessage({ id: 'ManageLicense.StartDate' })} />
                    <Column field="subscriptionName" title={intl.formatMessage({ id: 'ManageLicense.Subscription' })} />
                    <Column cell={MyEditCommandCell} width="220px" />
                </Grid>

                {isPagingVisible && (
                    <GridFooter
                        itemsPerPage={itemsPerPage}
                        handleItemsPerPageChange={handleItemsPerPageChange}
                        dataLength={filteredCustomerLicenses.length}
                        setPageHandler={setPageHandler}
                        currentPage={currentPage}
                    />
                )}

                {openForm && (
                    <EditIndividualForm
                        editorState={editorState}
                        cancelEdit={handleCancelEdit}
                        onSubmit={handleSubmit}
                        item={editItem}
                        supports={supports}
                        procedures={procedures}
                        customers={customers}
                        isSaveDisabled={isEditorSaveDisabled}
                        removeNotification={removeNotification}
                        incrementLoadingCount={incrementLoadingCount}
                        decrementLoadingCount={decrementLoadingCount}
                        handleError={handleError}
                    />
                )}

                {openFormGTCAccepted && (
                    <GTCAcceptedOnIndividualForm
                        editorState={editorState}
                        cancelEdit={handleCancelEdit}
                        onSubmit={handleSubmit}
                        item={editItem}
                        supports={supports}
                        procedures={procedures}
                        customers={customers}
                    />
                )}
            </CardBody>
        </Card>

        <ConfirmDialog
            visible={isConfirmDialogVisible}
            onClose={handleConfirmDialogClose}
            negative={<FormattedMessage id="Control.Cancel" />}
            positive={confirmDialogOkText}
            title={confirmDialogTitle}
            detail={confirmDialogDetail}
        />
    </React.Fragment>;
};

export default ManageIndividualLicense
