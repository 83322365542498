import * as React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { useDraggable, Icon } from '@progress/kendo-react-common';

export const DraggableButton = props => {
    const [initial, setInitial] = React.useState(null);
    const button = React.useRef(null);
    const handleDragStart = React.useCallback(event => {
        setInitial({
            x: event.clientX,
            y: event.clientY
        });
        if (props.params.onDragStart) {
            props.params.onDragStart(props.params.column)
        }
    }, [props.params]);
    const handleDrag = React.useCallback(event => {
        if (!button.current || !button.current.element || !initial) {
            return;
        }

        const transform = `translate(${event.clientX - initial.x}px, ${event.clientY - initial.y}px)`;
        button.current.element.style.transition = 'none';
        button.current.element.style.transform = transform;
    }, [initial]);
    const handleDragEnd = React.useCallback(() => {
        if (!button.current || !button.current.element) {
            return;
        }

        button.current.element.style.transition = 'transform .3s ease-in-out';
        button.current.element.style.transform = null;
        setInitial(null);
        if (props.params.onDragEnd) {
            props.params.onDragEnd(props.params.column)
        }
    }, [props.params]);

    useDraggable(button, {
        onDragStart: handleDragStart,
        onDrag: handleDrag,
        onDragEnd: handleDragEnd
    });

    const onRemove = React.useCallback((_event) => {
        if (props.params.onRemove) {
            props.params.onRemove(props.params.column);
        }
    }, [props.params]);

    const handleClick = React.useCallback((event) => {
        if (event.detail === 2) {
            if (props.params.onDoubleClick) {
                props.params.onDoubleClick(props.params.column);
            }
        }
    }, [props.params]);
    
    return <Button {...props}
        onClick={handleClick}
        className="m-1"
        ref={button}
        color="secondary"
        disabled={props.params.column.selected}
        themeColor={'secondary'}
    >
        <Icon name="move" size="medium" className="mr-1" />

        {props.params.column ? props.params.column.ColumnName : ""}
        {props.params.showClose &&
            <Icon name="close" size="medium" className="ml-1" onClick={onRemove} />
        }
    </Button>;
};
