import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
	Row,
	Col
} from 'reactstrap';
import { Loader } from "@progress/kendo-react-indicators";
import { useNotification } from '../Shared/Notifications/NotificationProvider';
import { useExceptionDialog } from '../Shared/ExceptionDialog/ExceptionDialogProvider';
import { NotificationTypes } from '../Shared/Notifications/Notification';
import StatLogo from '../../assets/images/statcontrol.png';
import { getProvisionType, createDatabase, createAdminDbMappings, createBlobContainer, createUserMapping } from '../../services/userProvisionService';

export default function InitialSteps() {
    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const pageId = 'InitialSteps';

    let history = useHistory();

    const ProvisionType = {
        None: 0,
        Full: 1,
        Partial: 2
    };

    const Status = {
        ToDo: 0,
        InProgress: 1,
        Success: 2,
        Error: 3
    };

    useEffect(() => {
        checkProvisionType();
    }, []);

    const [provisionType, setProvisionType] = useState(null);
    const checkProvisionType = () => {
        getProvisionType(
            type => {
                setProvisionType(type);
                startProvision(type);
            },
            handleError);
    };

    const startProvision = (type) => {
        switch (type) {
            case ProvisionType.None:
                redirectToHome();
                break;
            case ProvisionType.Full:
                startFullProvisionStep1();
                break;
            case ProvisionType.Partial:
                startPartialProvision();
                break;
        }
    };

    const [statusFullProvisionStep1, setStatusFullProvisionStep1] = useState(Status.ToDo);
    const startFullProvisionStep1 = () => {
        setStatusFullProvisionStep1(Status.InProgress);
        createDatabase(
            databaseName => {
                setStatusFullProvisionStep1(Status.Success);
                startFullProvisionStep2(databaseName);
            },
            (errorMessage, showNotif) => {
                setStatusFullProvisionStep1(Status.Error);
                handleError(errorMessage, showNotif);
            });
    };

    const [statusFullProvisionStep2, setStatusFullProvisionStep2] = useState(Status.ToDo);
    const startFullProvisionStep2 = (databaseName) => {
        setStatusFullProvisionStep2(Status.InProgress);
        createAdminDbMappings(
            databaseName,
            () => {
                setStatusFullProvisionStep2(Status.Success);
                startFullProvisionStep3(databaseName);
            },
            (errorMessage, showNotif) => {
                setStatusFullProvisionStep2(Status.Error);
                handleError(errorMessage, showNotif);
            });
    };

    const [statusFullProvisionStep3, setStatusFullProvisionStep3] = useState(Status.ToDo);
    const startFullProvisionStep3 = (containerName) => {
        setStatusFullProvisionStep3(Status.InProgress);
        createBlobContainer(
            containerName,
            () => {
                setStatusFullProvisionStep3(Status.Success);
                redirectToHome();
            },
            (errorMessage, showNotif) => {
                setStatusFullProvisionStep3(Status.Error);
                handleError(errorMessage, showNotif);
            });
    };

    const [statusPartialProvision, setStatusPartialProvision] = useState(Status.ToDo);
    const startPartialProvision = () => {
        setStatusPartialProvision(Status.InProgress);
        createUserMapping(
            () => {
                setStatusPartialProvision(Status.Success);
                redirectToHome();
            },
            (errorMessage, showNotif) => {
                setStatusPartialProvision(Status.Error);
                handleError(errorMessage, showNotif);
            });
    };

    const redirectToHome = () => {
        history.push('/');
    };

    const getStepContent = (stepStatus) => {
        switch (stepStatus) {
            case Status.ToDo:
                return (
                    <h5 className="text-right">To do</h5>
                );
            case Status.InProgress:
                return (
                    <h5 className="text-right">
                        In progress
                        &nbsp;
                        <Loader type="pulsing" />
                    </h5>
                );
            case Status.Success:
                return (
                    <h5 className="text-right">
                        Done
                        &nbsp;
                        <i className="fal fa-check-circle"></i>
                    </h5>
                );
            case Status.Error:
                return (
                    <h5 className="text-right">
                        Error
                        &nbsp;
                        <i className="fal fa-times-circle"></i>
                    </h5>
                );
            default:
                return (<div></div>);
        }
    };

    const handleError = (errorMessage, showNotif = true) => {
        if (showNotif) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            });
        }
    };

    return (
        <div className="container login-container">
			<Row>
				<Col xs="12">
					<div id="logo-container">
						<img alt="Stat Logo" src={StatLogo} />
					</div>
				</Col>
            </Row>
            <hr />
            <h2 className="text-center">Welcome to StatControl!</h2>
            <h4 className="text-center">Please wait while we configure your environment.</h4>
            {provisionType === ProvisionType.Full && (
                <div>
                    <Row>
                        <Col xs="6">
                            <h5>Creating your database</h5>
                        </Col>
                        <Col xs="6">
                            {getStepContent(statusFullProvisionStep1)}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="6">
                            <h5>Creating your mappings</h5>
                        </Col>
                        <Col xs="6">
                            {getStepContent(statusFullProvisionStep2)}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="6">
                            <h5>Creating your storage</h5>
                        </Col>
                        <Col xs="6">
                            {getStepContent(statusFullProvisionStep3)}
                        </Col>
                    </Row>
                </div>
            )}
            {provisionType === ProvisionType.Partial && (
                <Row>
                    <Col xs="6">
                        <h5>Creating your mappings</h5>
                    </Col>
                    <Col xs="6">
                        {getStepContent(statusPartialProvision)}
                    </Col>
                </Row>
            )}
        </div>
    );
}
