import React, { useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../assets/images/Logo.png';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { UserContext } from './UserContext';
import { useNotification } from './Shared/Notifications/NotificationProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faAbacus,
    faWarehouse,
    faUserCog,
    faSlidersH,
    faInfoCircle,
    faWrench,
    faQuestionCircle,
    faHome,
    faCog,
    faHistory
} from '@fortawesome/pro-light-svg-icons'


const AdminMenu = (props) => {
    if (props.isUserAdmin) {
        return (
            <UncontrolledDropdown nav inNavbar className={props.adminMenuClassName}>
                <DropdownToggle nav caret>
                    <FontAwesomeIcon icon={faCog} />
                    <span>&nbsp; <FormattedMessage id="Sidebar.Administration" /></span>
                </DropdownToggle>
                <DropdownMenu>
                    <Link to="/administration/warehousemanagement" className="nav-link"><FontAwesomeIcon icon={faWarehouse} />&nbsp; <FormattedMessage id="Sidebar.WarehouseManagement" /></Link>
                    <Link to="/administration/usermanagement" className="nav-link"><FontAwesomeIcon icon={faUserCog} />&nbsp; <FormattedMessage id="Sidebar.UserManagement" /></Link>
                    <Link to="/administration/masterdata" className="nav-link"><FontAwesomeIcon icon={faInfoCircle} />&nbsp; <FormattedMessage id="Sidebar.MasterData" /></Link>
                    <Link to="/administration/settings" className="nav-link"><FontAwesomeIcon icon={faSlidersH} />&nbsp; <FormattedMessage id="Sidebar.Settings" /></Link>
                    <Link to="/administration/configlist" className="nav-link"><FontAwesomeIcon icon={faWrench} />&nbsp; <FormattedMessage id="Sidebar.Config" /></Link>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }
    else {
        // hide the Administration menu item because of missing right
        return null;
    }
}

const InventoryMenu = (props) => {
    return (
        <UncontrolledDropdown nav inNavbar className={props.inventoryMenuClassName}>
            <DropdownToggle nav caret>
                <FontAwesomeIcon icon={faAbacus} />
                <span>&nbsp; <FormattedMessage id="Sidebar.Inventory" /></span>
            </DropdownToggle>
            <DropdownMenu>
                <Link to="/inventory/inventory" className="nav-link"><FontAwesomeIcon icon={faAbacus} />&nbsp; <FormattedMessage id="Sidebar.Inventory" /></Link>
                {
                    props.enableReportsMenu
                    ? <Link to="/inventory/reports" className="nav-link"><FontAwesomeIcon icon={faHistory} />&nbsp; <FormattedMessage id="Sidebar.Reports" /></Link>
                    : ''
                }
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}


const Sidebar = () => {

    const dispatchNotification = useNotification();

    const userContext = useContext(UserContext);
    const isUserAdmin = userContext.userGlobal.userInfo && (
        userContext.userGlobal.userInfo.isStatcontrolAdmin ||
        userContext.userGlobal.userInfo.isAdminUser
    )
    const isUserController = userContext.userGlobal.userInfo?.isController;
    const isUserOperator = userContext.userGlobal.userInfo?.isOperator;
    const enableReportsMenu = isUserAdmin || isUserController || isUserOperator;

    const location = useLocation();

    useEffect(() => {
        dispatchNotification({
            removeOnPageChanges: true
        });
    }, [location]);

    const { pathname } = location;

    const splitLocation = pathname.split("/");
    const adminMenuClassName = splitLocation[1] === "administration" ? "active" : "";
    const inventoryMenuClassName = splitLocation[1] === "inventory" ? "active" : "";

    return (
        <>
            <div className="sidebar" >
                <div className="sidebar-wrapper" >
                    <ul className="nav">
                        <Link to='/' className="navbar-brand banner">
                            <img alt="Logo" id="logo-change" src={Logo} />
                        </Link>
                        <li className={splitLocation[1] === "" ? "active nav-item" : "nav-item"}>
                            <Link to='/'>
                                <FontAwesomeIcon icon={faHome} />
                                <span>&nbsp; <FormattedMessage id="Sidebar.Dashboard" /></span>
                            </Link>
                        </li>
                        <InventoryMenu inventoryMenuClassName={inventoryMenuClassName} enableReportsMenu={enableReportsMenu}/>
                        <AdminMenu isUserAdmin={isUserAdmin} adminMenuClassName={adminMenuClassName}/>
                        {userContext.userGlobal.userInfo?.isStatcontrolAdmin && (
                            <li className={splitLocation[1] === "support-tools" ? "active nav-item" : "nav-item"}>
                                <Link to='/support-tools'>
                                    <FontAwesomeIcon icon={faQuestionCircle} />
                                    <span>&nbsp; <FormattedMessage id="Sidebar.SupportTools" /></span>
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Sidebar
