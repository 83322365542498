import { getWithPopulationIdHeader, postWithPopulationIdHeader } from './serviceBase';

const baseUrl = '/api/v1.0/CountingDevice';

export function getCountingDevices(populationId, successCallback, errorCallback) {
    const url = `${baseUrl}/GetCountingDevices`;
    getWithPopulationIdHeader(url, populationId, successCallback, errorCallback);
}

export function addCountingDevice(countingDevice, populationId, successCallback, errorCallback) {
    const url = `${baseUrl}/AddCountingDevice`;
    postWithPopulationIdHeader(url, populationId, countingDevice, successCallback, errorCallback);
}

export function editCountingDevice(changeCountingDevice, populationId, successCallback, errorCallback) {
    const url = `${baseUrl}/EditCountingDevice`;
    postWithPopulationIdHeader(url, populationId, changeCountingDevice, successCallback, errorCallback);
}

export function sendCountingDeviceEmail(countingDeviceEmail, populationId, successCallback, errorCallback) {
    const url = `${baseUrl}/SendCountingDeviceEmail`;
    postWithPopulationIdHeader(url, populationId, countingDeviceEmail, successCallback, errorCallback);
}

export function activateCountingDevice(deviceId, populationId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('deviceId', deviceId);
    const url = `${baseUrl}/ActivateCountingDevice?` + params;
    postWithPopulationIdHeader(url, populationId, null, successCallback, errorCallback);
}
