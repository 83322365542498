import { getWithPopulationIdHeader } from './serviceBase';

const baseUrl = '/api/v1.0/inventory/Reports';

export function getActivityHistory(populationId, successCallback, errorCallback, cancelToken) {
    getWithPopulationIdHeader(`${baseUrl}/GetActivityHistory`, populationId, successCallback, errorCallback, cancelToken);
}

export function getPreAnalysisReportHistory(populationId, successCallback, errorCallback, cancelToken) {
    getWithPopulationIdHeader(`${baseUrl}/GetPreAnalysisReportHistory`, populationId, successCallback, errorCallback, cancelToken);
}

export function getPostAnalysisReportHistory(populationId, successCallback, errorCallback, cancelToken) {
    getWithPopulationIdHeader(`${baseUrl}/GetPostAnalysisReportHistory`, populationId, successCallback, errorCallback, cancelToken);
}

