import { post } from './serviceBase';

const baseUrl = '/api/v1.0/Email';

export function sendEmailToSupport(email, files, successCallback, errorCallback) {
    let fd = new FormData();
    files.forEach(element => {
        fd.append('files', element);
    });
    fd.append('Body', email.body);
    if (email.subject) {
        fd.append('Subject', email.subject);
    }
    if (email.phoneNumber) {
        fd.append('PhoneNumber', email.phoneNumber);
    }
    // ask for urgency
    if (email.priority) {
        fd.append('Priority', email.priority);
    }
    const url = `${baseUrl}/SendEmailToSupport`;
    post(url, fd, successCallback, errorCallback);
}


export function sendInventoryEmailToSupport(email, successCallback, errorCallback) {
    const url = `${baseUrl}/SendInventoryEmailToSupport`;
    post(url, email, successCallback, errorCallback);
}


export function sendIndividualLicenseRequest(email, successCallback, errorCallback) {
    let fd = new FormData();
    fd.append('Body', email.body);
    if (email.phoneNumber) {
        fd.append('PhoneNumber', email.phoneNumber);
    }
    const url = `${baseUrl}/SendIndividualLicenseRequest`;
        post(url, fd, successCallback, errorCallback);
}