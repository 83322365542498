import { UserManager, WebStorageStateStore } from 'oidc-client';
import { storeUserError, storeUser } from '../actions/authActions';
import axios from 'axios';

const baseUrl = window.location.origin;

const controllerUrlRegistration = '/api/v1.0/Registration/';

export function createUserManager() {
    return new UserManager({
        authority: window.authenticationConfig.authority,
        client_id: window.authenticationConfig.clientId,
        response_type: window.authenticationConfig.responseType,
        scope: window.authenticationConfig.scope,
        redirect_uri: `${baseUrl}/signin-oidc`,
        post_logout_redirect_uri: `${baseUrl}/signout-oidc`,
        userStore: new WebStorageStateStore({ store: window.localStorage })
    })
}

export async function loadUserFromStorage(store, userManager) {
    try {
        if (!userManager)
            return store.dispatch(storeUserError());

        const user = await userManager.getUser();

        if (!user)
            return store.dispatch(storeUserError());

        store.dispatch(storeUser(user));
    } catch (e) {
        console.error(`User not found: ${e}`);
        return store.dispatch(storeUserError());
    }
}

export function signinRedirect(userManager) {
    return userManager.signinRedirect();
}

export function signinRedirectCallback(userManager) {
    return userManager.signinRedirectCallback();
}

export function signoutRedirect(userManager) {
    userManager.clearStaleState();
    return userManager.signoutRedirect();
}

export function signoutRedirectCallback(userManager) {
    userManager.clearStaleState();
    userManager.removeUser();
    return userManager.signoutRedirectCallback();
}

export async function registerUser(userRegistrationrequest) {
    const response = await axios.post(controllerUrlRegistration + 'RegisterUser', userRegistrationrequest);
    return response.data;
}
