const privacyPolicyHtml_en = `
<p>
TODO: Translate the text below into English?
</p>
<p>
Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
</p>
<p>
Remira GmbH <br/>
Konrad-Zuse-Str. 3 <br/>
44801 Bochum <br/>
</p>
<p>
Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).
</p>
<p>
Widerruf deiner Einwilligung zur Datenverarbeitung
</p>
<p>
Nur mit deiner ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. Ein Widerruf deiner bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
</p>
<p>
Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde
</p>
<p>
Als Betroffener steht dir im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit: Kontaktdaten.
</p>
<p>
Recht auf Datenübertragbarkeit
</p>
<p>
Dir steht das Recht zu, Daten, die wir auf Grundlage deiner Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern du die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangst, erfolgt dies nur, soweit es technisch machbar ist.
</p>
<p>
Recht auf Auskunft, Berichtigung, Sperrung, Löschung
</p>
<p>
Du hast jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über deine gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema personenbezogene Daten kannst du dich jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.
</p>
<p>
SSL- bzw. TLS-Verschlüsselung
</p>
<p>
Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die du an uns als Seitenbetreiber sendest, nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die du über diese Website übermittelst, für Dritte nicht mitlesbar. Du erkennst eine verschlüsselte Verbindung an der „https://“ Adresszeile deines Browsers und am Schloss-Symbol in der Browserzeile.
</p>
<p>
Server-Log-Dateien
</p>
<p>
In Server-Log-Dateien erhebt und speichert der Provider der Website automatisch Informationen, die dein Browser automatisch an uns übermittelt. Dies sind:
</p>
<p>
Besuchte Seite auf unserer Domain <br/>
Datum und Uhrzeit der Serveranfrage <br/>
Browsertyp und Browserversion <br/>
Verwendetes Betriebssystem <br/>
Referrer URL <br/>
Hostname des zugreifenden Rechners <br/>
IP-Adresse <br/>
Es findet keine Zusammenführung dieser Daten mit anderen Datenquellen statt. Grundlage der Datenverarbeitung bildet Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
</p>
<p>
Registrierung auf dieser Website
</p>
<p>
Zur Nutzung bestimmter Funktionen kannst du dich auf unserer Website registrieren. Die übermittelten Daten dienen ausschließlich zum Zwecke der Nutzung des jeweiligen Angebotes oder Dienstes. Bei der Registrierung abgefragte Pflichtangaben sind vollständig anzugeben. Andernfalls werden wir die Registrierung ablehnen.
</p>
<p>
Im Falle wichtiger Änderungen, etwa aus technischen Gründen, informieren wir dich per E-Mail. Die E-Mail wird an die Adresse versendet, die bei der Registrierung angegeben wurde.
</p>
<p>
Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt auf Grundlage deiner Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf deiner bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
</p>
<p>
Wir speichern die bei der Registrierung erfassten Daten während des Zeitraums, den Du auf unserer Webite registriert bist. Deine Daten werden gelöscht, solltest du deine Registrierung aufheben. Gesetzliche Aufbewahrungsfristen bleiben unberührt.
</p>
<p>
Cookies
</p>
<p>
Unsere Website verwendet Cookies. Das sind kleine Textdateien, die dein Webbrowser auf deinem Endgerät speichert. Cookies helfen uns dabei, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen.
</p>
<p>
Einige Cookies sind “Session-Cookies.” Solche Cookies werden nach Ende deiner Browser-Sitzung von selbst gelöscht. Hingegen bleiben andere Cookies auf deinem Endgerät bestehen, bis du diese selbst löschen. Solche Cookies helfen uns, dich bei Rückkehr auf unserer Website wiederzuerkennen.
</p>
<p>
Mit einem modernen Webbrowser kannst du das Setzen von Cookies überwachen, einschränken oder unterbinden. Viele Webbrowser lassen sich so konfigurieren, dass Cookies mit dem Schließen des Programms von selbst gelöscht werden. Die Deaktivierung von Cookies kann eine eingeschränkte Funktionalität unserer Website zur Folge haben.
</p>
<p>
Das Setzen von Cookies, die zur Ausübung elektronischer Kommunikationsvorgänge oder der Bereitstellung bestimmter, von dir erwünschten Funktionen (z.B. Warenkorb) notwendig sind, erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und reibungslosen Bereitstellung unserer Dienste. Sofern die Setzung anderer Cookies (z.B. für Analyse-Funktionen) erfolgt, werden diese in dieser Datenschutzerklärung separat behandelt.
</p >
`;


export default privacyPolicyHtml_en;