import * as React from "react";
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/pro-light-svg-icons'

export const ManageLicenseActionCell = (props) => {


    return (
        <td>
            <Button
                color="link"
                onClick={() => props.enterEdit(props.dataItem)}
                disabled={props.disabled}
            >
                <FontAwesomeIcon icon={faPencilAlt} />
            </Button>
        </td>
    );

}

export default ManageLicenseActionCell
