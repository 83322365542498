import React, { useState, useEffect } from 'react';
import { useLoading } from "../Shared/LoadingContext";
import { useNotification } from '../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../Shared/Notifications/Notification';
import { useExceptionDialog } from '../Shared/ExceptionDialog/ExceptionDialogProvider';
import ConfirmDialog from '../Shared/ConfirmDialog';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row, Card, Button, CardBody, CardHeader, Input } from 'reactstrap';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import ReactTooltip from 'react-tooltip';
import EditActionCell from "../Administration/Warehouse Management/EditActionCell";
import { getBlacklistCompanyWords, addBlacklistCompanyWord, updateBlacklistCompanyWord, deleteBlacklistCompanyWord } from "../../services/blacklistCompanyWordService"
import { process } from "@progress/kendo-data-query";
import GridFooter from '../Shared/GridFooter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'


const editField = "inEdit";

export default function BlacklistCompany() {
    const intl = useIntl();
    const { setLoading } = useLoading();

    const pageId = 'pageBlacklistCompany';
    const [deleteConfVisibile, setDeleteConfVisibile] = React.useState(false);
    const [itemForDelete, setItemForDelete] = React.useState({});
    const [dataState, setDataState] = React.useState({});
    const dispatchNotification = useNotification();
    const [disable, setDisable] = React.useState(false);
    const dispatchExceptionDialog = useExceptionDialog();
    const [blacklistCompanyWords, setBlacklistCompanyWords] = useState([]);
    const [filteredBLWords, setFilteredBLWords] = useState([]);
    const [currentFilterValue, setCurrentFilterValue] = React.useState("");
    const [gridWords, setGridWords] = useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [itemsPerPage, setItemsPerPage] = React.useState(10);
    const [isPagingVisible, setIsPagingVisible] = React.useState(false);

    useEffect(() => {
        refreshBlacklistCompanyWords();
    }, []);

    useEffect(() => {
        applyFilter();
    }, [blacklistCompanyWords, currentFilterValue]);

    useEffect(() => {
        setIsPagingVisible(filteredBLWords.length > 5);
        setPageHandler(1);
    }, [filteredBLWords, itemsPerPage]);

    React.useEffect(() => {
        const filterWord = document.getElementsByName("filterWordValue");
        if (filterWord.length > 0) {
            filterWord[0].disabled = disable;
        }
        if (disable) {
            setTimeout(() => {
                let inputs = document.getElementsByClassName('k-textbox')
                if (inputs.length > 0) {
                    inputs[0].focus();
                }
            });
        }
    }, [disable]);

    const applyFilter = () => {
        const filteredArray = blacklistCompanyWords.filter(
            (w) => (w.blacklistCompanyWordText.toLowerCase().includes(currentFilterValue.toLowerCase()) || w.blacklistCompanyWordId == -1)
        );
        setFilteredBLWords(filteredArray);
    }

    const refreshBlacklistCompanyWords = () => {
        setBlacklistCompanyWords([]);
        getBlacklistCompanyWords(
            bcw => {
                setBlacklistCompanyWords(bcw);
            },
            handleError);
    }

    const handleAddButton = () => {
        const newDataItem = generateNewItem();
        newDataItem.inEdit = true;
        newDataItem.selected = true;
        setBlacklistCompanyWords([newDataItem, ...blacklistCompanyWords]);
        setCurrentPage(1);
        setDisable(true);
    }

    const generateNewItem = () => {
        return {
            blacklistCompanyWordId: -1,
            blacklistCompanyWordText: ''
        };
    }

    const itemChange = (event) => {
        const newData = blacklistCompanyWords.map((item) =>
            item.blacklistCompanyWordId === event.dataItem.blacklistCompanyWordId
                ? {
                    ...item, [event.field || ""]: event.value,
                    changed: (event.value.trim().length > 0)
                }
                : item
        );
        setBlacklistCompanyWords(newData);
    };

    const handleConfirmDialogClose = (result) => {
        if (result) {
            onConfirmDelete()
        }
        else {
            onCancelDelete()
        }
    }

    const onCancelDelete = () => {
        setDeleteConfVisibile(false);
    }

    const onConfirmDelete = () => {
        setDeleteConfVisibile(false);
        setLoading(true);
        removeNotification();
        deleteBlacklistCompanyWord(
            itemForDelete.blacklistCompanyWordId,
            items => {
                setLoading(false);
                refreshBlacklistCompanyWords();
            },
            handleError);
    };

    const handleError = (errorMessage, showNotif = true) => {
        setLoading(false);
        if (showNotif) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            });
        }
    };

    const removeNotification = () => {
        dispatchNotification({
            remove: true,
            pageId: pageId
        });
    };

    const remove = (dataItem) => {
        if (dataItem.importInProgress) {
            return false;
        }
        setDeleteConfVisibile(true)
        setItemForDelete(dataItem);
        setDisable(false);
    };

    const update = (dataItem) => {
        if (!isDataItemValid(dataItem)) {
            return;
        }
        removeNotification();
        setLoading(true);
        updateBlacklistCompanyWord(
            dataItem,
            items => {
                dataItem.inEdit = false;
                setLoading(false);
            },
            handleError);
        setDisable(false);
    };

    const isDataItemValid = (dataItem) => {
        removeNotification();
        if (!dataItem.blacklistCompanyWordText || dataItem.blacklistCompanyWordText.trim().length == 0) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: intl.formatMessage({ id: "BlacklistCompany.BLWordRequired" })
            })
            return false;
        }
        if (dataItem.blacklistCompanyWordText.length > 200) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: intl.formatMessage({ id: "BlacklistCompany.BLWordTooLong" })
            })
            return false;
        }
        if (blacklistCompanyWords.filter(x => x.blacklistCompanyWordId !== dataItem.blacklistCompanyWordId)
            .some(x => x.blacklistCompanyWordText.toLowerCase() === dataItem.blacklistCompanyWordText.toLowerCase())) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: intl.formatMessage({ id: "BlacklistCompany.BLWordExists" })
            })
            return false
        }
        if (dataItem.blacklistCompanyWordText.indexOf(' ') >= 0) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: intl.formatMessage({ id: "BlacklistCompany.BLWordSpacesNotAllowed" })
            })
            return false;
        }
        return true;
    };

    const discard = () => {
        const newData = [...blacklistCompanyWords];
        newData.splice(0, 1);
        setBlacklistCompanyWords(newData);
        setDisable(false);
    };

    const cancel = (dataItem) => {
        const originalItem = blacklistCompanyWords.find(
            (p) => p.blacklistCompanyWordId === dataItem.blacklistCompanyWordId
        );
        const newData = blacklistCompanyWords.map((item) =>
            item.blacklistCompanyWordId === originalItem.blacklistCompanyWordId ? originalItem : item
        );
        dataItem.inEdit = false;
        setBlacklistCompanyWords(newData);
        setDisable(false);
    };

    const enterEdit = (dataItem) => {
        dataItem.changed = false;
        setDisable(true);
        setBlacklistCompanyWords(
            blacklistCompanyWords.map((item) =>
                item.blacklistCompanyWordId === dataItem.blacklistCompanyWordId ? { ...item, inEdit: true } : item
            )
        );
        dataItem.selected = true;
    };

    const add = (newItem) => {
        if (!isDataItemValid(newItem)) {
            return;
        }
        removeNotification();
        setLoading(true);
        newItem.inEdit = false;
        addBlacklistCompanyWord(
            newItem,
            result => {
                newItem.blacklistCompanyWordId = result.blacklistCompanyWordId;
                setDisable(false);
                setLoading(false);
            },
            handleError);
    }

    const filterWords = (value) => {
        setCurrentPage(1);
        setCurrentFilterValue(value);
    }

    const setPageHandler = (page) => {
        if (filteredBLWords && page > 0 && (page <= Math.floor((filteredBLWords.length - 1) / itemsPerPage) + 1 || filteredBLWords.length == 0)) {
            let startIndex = (page - 1) * itemsPerPage;
            let endIndex = (page) * itemsPerPage;
            setGridWords(filteredBLWords.slice(startIndex, endIndex));
            if (blacklistCompanyWords.length > itemsPerPage) {
                setCurrentPage(page);
            }
            else {
                setCurrentPage(1);
            }
        }
    }

    const handleItemsPerPageChange = (dropDownItem) => {
        setItemsPerPage(dropDownItem.value);
    };

    const EditRights = (props) => (
        <EditActionCell
            {...props}
            edit={enterEdit}
            remove={remove}
            discard={discard}
            add={add}
            update={update}
            cancel={cancel}
            editField={editField}
            disabled={disable}
            idPropName="blacklistCompanyWordId"
        />
    );

    return (
        <div className="content">
            <ConfirmDialog
                visible={deleteConfVisibile}
                onClose={handleConfirmDialogClose}
                negative={<FormattedMessage id='Control.Cancel' />}
                positive={<FormattedMessage id='Control.Confirm' />}
                title={<FormattedMessage id='Confirm.DeletionTitle' />}
                detail={intl.formatMessage({ id: "BlacklistCompany.BLWordConfirmDelete" }) + itemForDelete.blacklistCompanyWordText + "?"}
            />
            <Card id="blacklist-card">
                <CardBody>
                    <Row>
                        <Col xs="4">
                            <Input className="grid-filter" name="filterWordValue" placeholder={intl.formatMessage({ id: "BlacklistCompany.BLWordFilter" })} onChange={(event) => {
                                filterWords(event.target.value);
                            }} />
                        </Col>
                        <Col xs="8">
                            <div className="float-right no-margin">
                                <ReactTooltip id="blacklistCompanyAddTip" place="top" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />
                                <Button color="primary" disabled={disable} onClick={handleAddButton} data-tip={intl.formatMessage({ id: 'BlacklistCompany.NoBLWord' })} data-for="blacklistCompanyAddTip">
                                    <FontAwesomeIcon icon={faPlus} />
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row id="blacklist-grid">
                        <Col xs="12">
                            <Grid data={process(gridWords, dataState)}
                                {...dataState}
                                onDataStateChange={(e) => {
                                    setDataState(e.dataState);
                                }}
                                onItemChange={itemChange}
                                editField={editField}
                                key="blacklistCompanyWordId">
                                <GridNoRecords>
                                    {<FormattedMessage id="BlacklistCompany.NoBLWord" />}
                                </GridNoRecords>
                                <Column field="blacklistCompanyWordText" title={intl.formatMessage({ id: "BlacklistCompany.BLWord" })} width="375px" />
                                <Column cell={EditRights} filterable={false} />
                            </Grid>
                        </Col>
                    </Row>
                    {isPagingVisible && (
                        <GridFooter
                            itemsPerPage={itemsPerPage}
                            handleItemsPerPageChange={handleItemsPerPageChange}
                            dataLength={filteredBLWords.length}
                            setPageHandler={setPageHandler}
                            currentPage={currentPage}
                        />
                    )}
                </CardBody>
            </Card>
        </div>
    );
}