import * as React from 'react';
import { useLoading } from "../../Shared/LoadingContext";
import { Grid, GridNoRecords, GridColumn as Column, getSelectedState } from '@progress/kendo-react-grid';
import { Col, Card, CardHeader, CardBody, Button, Row } from 'reactstrap';
import EditActionCell from "./EditActionCell";
import { process } from "@progress/kendo-data-query";
import Population from "./Population";
import {
    WL_ACTIONS
} from "./services";
import { updateWarehouseLocation, deleteWarehouseLocation, addWarehouseLocation, getTimeZones } from '../../../services/warehouseLocationsService';
import ConfirmDialog from '../../Shared/ConfirmDialog';
import { getter } from "@progress/kendo-react-common";
import { useNotification } from '../../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../../Shared/Notifications/Notification';
import { FormattedMessage, useIntl } from 'react-intl';
import { useExceptionDialog } from '../../Shared/ExceptionDialog/ExceptionDialogProvider';

import { getAllBackgroundTaskStatuses, getImportInProgressBackgroundTaskStatuses } from '../../../services/inventoryService';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { GetDisabledAddLocationButtonTooltip, IsAddingPopulationAllowedBySubscription, RefreshUserContext } from '../../UserContext';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import { Context } from '../../Wrapper';
import GridFooter from '../../Shared/GridFooter';
import { DropDownCell } from '../../Shared/DropDownCell';
import { AutoCompleteCell } from '../../Shared/AutoCompleteCell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import countries from "i18n-iso-countries";

const editField = "inEdit";
const DATA_ITEM_KEY = "warehouseLocationId";
const idGetter = getter(DATA_ITEM_KEY);
const SELECTED_FIELD = "selected";
const TASK_STATUS_POLLING_INTERVAL_MILLISECONDS = 2000;

const LanguagesDropdown = props => (
    <DropDownCell
        {...props}
        dataSourceField="dataSourceLanguages"
    />
);

const TimeZonesDropdown = props => (
    <DropDownCell
        {...props}
        dataSourceField="dataSourceTimeZones"
    />
);

const CountriesAutoComplete = props => (
    <AutoCompleteCell
        {...props}
        dataSourceField="dataSourceCountries"
    />
);

const LocationGrid = (props) => {
    const cancelTokenSource = axios.CancelToken.source();

    const intl = useIntl();
    const [data, setData] = React.useState(props.warehouseLocations);
    const [selectedState, setSelectedState] = React.useState({});
    const { setLoading } = useLoading();
    const [deleteConfVisibile, setDeleteConfVisibile] = React.useState(false);
    const [itemForDelete, setItemForDelete] = React.useState({});
    const [selectedWarehouse, setSelectedWarehouse] = React.useState({});
    const [dataState, setDataState] = React.useState({});
    const dispatchNotification = useNotification();
    const pageId = 'pageLocation';
    const [disable, setDisable] = React.useState(true);
    const dispatchExceptionDialog = useExceptionDialog();

    const [countryList, setCountryList] = React.useState([]);
    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/de.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/it.json"));

    const [hubConnection, setHubConnection] = React.useState(null);
    const [pollCount, setPollCount] = React.useState(0);
    const [echoReceived, setEchoReceived] = React.useState(false);

    const isAddingPopulationAllowedBySubscription = IsAddingPopulationAllowedBySubscription();
    const getDisabledAddLocationButtonTooltip = GetDisabledAddLocationButtonTooltip();
    const refreshUserContext = RefreshUserContext();
    const [isLastWH, setIsLastWH] = React.useState(false);
    const [timeZones, setTimeZones] = React.useState(null);
    const context = React.useContext(Context);

    const [editItem, setEditItem] = React.useState(null);
    const [addModeResolved, setAddModeResolved] = React.useState(false);

    const [gridData, setGridData] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [itemsPerPage, setItemsPerPage] = React.useState(10);
    const [isPagingVisible, setIsPagingVisible] = React.useState(false);
    const minGridLength = 5;

    const languages = [
        { value: window.enumLanguage.English, text: 'English' },
        { value: window.enumLanguage.German, text: 'Deutsch' },
        { value: window.enumLanguage.Italian, text: 'Italiano' },
        { value: window.enumLanguage.French, text: 'Français' },
        { value: window.enumLanguage.Spanish, text: 'Español' }
    ];

    const defaultTimeZone = timeZones ? timeZones.find(el => el.text?.indexOf("Berlin") >= 0) : null;
    const defaultLanguage = languages ? languages.find(l => l.value === window.enumLanguage.German) : null;
    const defaultCountryCode = "DE";

    const importStateChanged = (populationId, importStatus) => {
        let importInProgress = getImportInProgressBackgroundTaskStatuses.includes(importStatus);
        props.dispatchWL({ type: WL_ACTIONS.UpdatePopulationImportState, payload: { populationId: populationId, importInProgress: importInProgress } });
    }

    const pollBackgroundTaskStatuses = () => {
        if (!echoReceived) {
            console.log('Polling all background task statuses (current poll count:' + pollCount + ')');
            getAllBackgroundTaskStatuses(
                props.customerId,
                response => {
                    console.log('Background task count:' + response.length);
                    response.forEach(task => importStateChanged(task.populationId, task.status));
                    setPollCount(pollCount + 1);
                },
                () => { console.log('Polling all background task statuses failed!'); },
                cancelTokenSource.token
            );
        }
    }

    React.useEffect(() => {
        const timer = setTimeout(() => {
            pollBackgroundTaskStatuses();
        }, TASK_STATUS_POLLING_INTERVAL_MILLISECONDS);

        return () => clearTimeout(timer);
    }, [pollCount]);

    React.useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(window.origin + '/inventoryImportHub')
            .withAutomaticReconnect()
            .build();
        setHubConnection(newConnection);
    }, []);

    const onEcho = React.useCallback((population, message) => {
        console.log('Echo received: populationId:' + population + ', message:' + message);
        setEchoReceived(true);
    }, [])

    React.useEffect(() => {
        if (hubConnection && !hubConnection._connectionStarted) {
            hubConnection.start()
                .then(_result => {
                    console.log('LocationGrid connected to inventoryImportHub!');

                    hubConnection.on('InventoryImportStatusChanged', (message) => {
                        console.log('LocationGrid - InventoryImportStatusChanged notification received: populationId:' + message.populationId + ', importStatus:' + message.importStatus + ', errorMsg:' + message.errorMessage);
                        importStateChanged(message.populationId, message.importStatus);
                    });

                    hubConnection.on('InventoryImportBackgroundTaskFailed', (message) => {
                        console.log('LocationGrid - InventoryImportBackgroundTaskFailed notification received: populationId:' + message.populationId + ', errorMsg:' + message.errorMessage);
                        importStateChanged(message.populationId, window.enumBackgroundTaskStatus.DataImportFailed);
                    });

                    hubConnection.on('Echo', onEcho);
                    hubConnection.send('echo', 'Placeholder', 'Hello there!');
                })
                .catch(e => console.log('Hub Connection failed: ', e));
        }
        return async () => {
            if (hubConnection) {
                try {
                    hubConnection.off('Echo', onEcho);
                    hubConnection.stop();
                    console.log('LocationGrid - hub connection stopped');
                }
                catch (ex) {
                    console.log('hubConnection.stop() failed ', ex);
                }
            }
        }
    }, [hubConnection]);

    React.useEffect(() => {
        return () => {
            removeNotification();
            setDisable(false);
        };
    }, []);

    const buildWarehouseGridData = () => {
        return props.warehouseLocations.map(item => {
            return {
                ...item,
                dispatch: props.dispatchWL,
                changed: false,
                importPopulationInProgress: item.populations.some(population => population.importInProgress) ? true : false
            };
        });
    }

    const setWarehouseInEdit = (temp) => {
        if (editItem) {
            if (editItem.warehouseLocationId) {
                let found = temp.filter((item) => item.warehouseLocationId == editItem.warehouseLocationId);
                if (found.length) {
                    found[0].inEdit = editItem.inEdit;
                }
            }
            else {
                temp = [editItem, ...temp];
            }
            if (temp.some((loc) => loc.inEdit)) {
                setDisable(true);
            }
        }
    }

    const setWarehouseSelection = (temp) => {
        if (selectedWarehouse.warehouseLocationId) {
            let current = temp.filter((item) => item.warehouseLocationId === selectedWarehouse.warehouseLocationId);
            if (current[0])
                setSelectedWarehouse(current[0]);
        }
        else {
            if (props.warehouseLocations.length > 0)
                setSelectedWarehouse(temp[0])
        }
    }

    React.useEffect(() => {
        let temp = buildWarehouseGridData();
        setWarehouseInEdit(temp);
        setWarehouseSelection(temp);
        setIsLastWH(temp.length < 2);

        setData(temp);

    }, [props.warehouseLocations]);

    React.useEffect(() => {
        if (props.selectedWarehouseLocation)
            setSelectedWarehouse(props.selectedWarehouseLocation);
        else
            setSelectedWarehouse({});
        setSelectedState({});
        setDisable(false);
    }, [props.customerId]);

    React.useEffect(() => {
        if (editItem) {
            setDisable(true);
            setSelectedWarehouse(editItem);
        }
        else {
            setDisable(false);
        }
    }, [editItem]);

    const getSelectedItemsPage = (items, itemsOnPage) => {
        let page = 0;
        for (let i in items) {
            if (i % itemsOnPage == 0) {
                page++;
            }
            if (items[i].selected) {
                return page;
            }
        }
        return 1;
    }

    const setActiveSelectedItemsPage = (items, itemsOnPage) => {
        let selItemPg = getSelectedItemsPage(items, itemsOnPage);
        setPageHandler(selItemPg);
    }

    React.useEffect(() => {
        setIsPagingVisible(data.length > minGridLength);
        setActiveSelectedItemsPage(data, itemsPerPage);
    }, [data, itemsPerPage]);

    const handleConfirmDialogClose = (result) => {
        if (result) {
            onConfirmDelete()
        }
        else {
            onCancelDelete()
        }
    }

    const onCancelDelete = () => {
        setDeleteConfVisibile(false);
    }

    const onConfirmDelete = () => {
        setDeleteConfVisibile(false);
        setLoading(true);
        removeNotification();
        deleteWarehouseLocation(
            itemForDelete.warehouseLocationId,
            () => {
                refreshUserContext(handleError);
                props.dispatchWL({ type: WL_ACTIONS.Remove, payload: { id: itemForDelete.warehouseLocationId } });
                setSelectedWarehouse({});
                setSelectedState({});
                setLoading(false);
            },
            handleError);
    };

    const handleError = (errorMessage, showNotif = true) => {
        setLoading(false);
        if (showNotif) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            });
        }
    };

    const removeNotification = () => {
        dispatchNotification({
            remove: true,
            pageId: pageId
        });
    };

    const remove = (dataItem) => {
        setDeleteConfVisibile(true)
        setItemForDelete(dataItem);
        setDisable(false);
    };

    const update = (dataItem) => {
        if (!isDataItemValid(dataItem)) {
            return;
        }
        setLoading(true);
        removeNotification();
        updateWarehouseLocation(
            dataItem,
            _items => {
                dataItem.inEdit = false;
                dataItem.changed = false;
                dataItem.selected = true;
                props.dispatchWL({ type: WL_ACTIONS.Update, payload: { item: dataItem } });
                setLoading(false);

                setData(
                    data.map((item) =>
                        item.warehouseLocationId === dataItem.warehouseLocationId ? { ...item, ...dataItem } : { ...item, selected: false }
                    )
                );
                setEditItem(null);
            },
            handleError);
    };

    const isDataItemValid = (dataItem) => {
        removeNotification();
        if (!dataItem.warehouseLocationName) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: <FormattedMessage id="LocationGrid.LocationNameReq" />
            })
            return false;
        }
        if (dataItem.warehouseLocationName.length > 200) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: <FormattedMessage id="LocationGrid.LocationNameLong" />
            })
            return false;
        }
        if (data.filter(x => x.warehouseLocationId !== dataItem.warehouseLocationId).some(x => x.warehouseLocationName.toLowerCase() === dataItem.warehouseLocationName.toLowerCase())) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: <FormattedMessage id="LocationGrid.LocationNameExists" />
            })
            return false
        }
        return true;
    };

    const discard = () => {
        const newData = data.filter(x => x.warehouseLocationId);
        setData(newData);
        setEditItem(null);
    };

    const cancel = (dataItem) => {
        setEditItem(null);
        const originalItem = props.warehouseLocations.find(
            (p) => p.warehouseLocationId === dataItem.warehouseLocationId
        );
        const newData = data.map((item) =>
            item.warehouseLocationId === originalItem.warehouseLocationId ? { ...item, ...originalItem, inEdit: false, changed: false } : item
        );
        setData(newData);
    };

    const enterEdit = (dataItem) => {
        dataItem.changed = false;
        dataItem.inEdit = true;
        dataItem.selected = true;
        setEditItem(dataItem);

        let temp = data.map((item) =>
            item.warehouseLocationId === dataItem.warehouseLocationId ? { ...item, ...dataItem } : { ...item, selected: false }
        )
        setData(temp);
    };

    const itemChange = (event) => {
        const newData = gridData.map((item) =>
            item.warehouseLocationId === event.dataItem.warehouseLocationId
                ? { ...item, [event.field || ""]: event.value, changed: true }
                : item
        );
        setGridData(newData);
    };

    const addNewWarehouseLine = (warehouses) => {
        const newDataItem = generateNewItem();
        newDataItem.inEdit = true;
        newDataItem.selected = true;
        setGridData([newDataItem, ...warehouses]);
        props.incrementLoadingCount();
        setEditItem(newDataItem);
        setTimeout(() => {
            let grids = document.getElementsByClassName('k-grid');
            if (grids.length > 0) {
                let grid = grids[0];
                let gridRows = grid.getElementsByClassName('k-grid-edit-row');
                if (gridRows.length > 0) {
                    let insertedRow = gridRows[0];
                    let inputs = insertedRow.getElementsByClassName('k-textbox')
                    if (inputs.length > 0) {
                        inputs[0].focus();
                    }
                }
            }
            props.decrementLoadingCount();
        });
    }

    const handleAddButton = (_event) => {
        addNewWarehouseLine(gridData);
    }

    const generateNewItem = () => {
        return {
            customerId: props.customerId,
            warehouseLocationId: "",
            warehouseLocationName: '',
            timeZoneId: defaultTimeZone?.value,
            countryCode: defaultCountryCode,
            language: defaultLanguage?.value
        };
    }

    React.useEffect(() => {
        const filters = document.getElementsByClassName("k-filtercell-wrapper");
        for (let filter of filters) {
            for (let child of filter.childNodes) {
                if (child.nodeName === "INPUT") {
                    child.disabled = disable;
                }
                if (child.nodeName === "DIV") {
                    child.style.display = disable ? "none" : "block";
                }
            }
        }
    }, [disable]);

    const setPageHandler = (page) => {
        if (data && page > 0 && page <= Math.floor((data.length - 1) / itemsPerPage) + 1) {
            let startIndex = (page - 1) * itemsPerPage;
            let endIndex = (page) * itemsPerPage;

            let temp = data.slice(startIndex, endIndex);
            if (props.addWarehouseDialogOpened && !addModeResolved) {
                setAddModeResolved(true);
                addNewWarehouseLine(temp);
            }
            else {
                setGridData(temp);
            }

            if (data.length > itemsPerPage) {
                setCurrentPage(page);
            }
            else {
                setCurrentPage(1);
            }
        }
    }

    const handleItemsPerPageChange = (dropDownItem) => {
        setItemsPerPage(dropDownItem.value);
    };

    const loadTimeZones = () => {
        setLoading(true);

        getTimeZones(
            result => {
                setLoading(false);
                setTimeZones(result.map(tz => ({ value: tz.id, text: tz.name })));
            },
            handleError);
    }

    const getCountries = () => {
        const cntryLst = countries.getNames(context.locale, { select: 'official' });
        const counts = Object.keys(cntryLst).map((key) => ({ value: key, text: cntryLst[key] }));
        setCountryList(counts);
    }

    React.useEffect(() => {
        loadTimeZones();
        getCountries();
    }, [context.locale]);

    const EditLocation = (props) => (
        <EditActionCell
            {...props}
            remove={remove}
            discard={discard}
            update={update}
            add={add}
            cancel={cancel}
            editField={editField}
            idPropName={"warehouseLocationId"}
            disabled={disable}
            isLastWH={isLastWH}
            edit={enterEdit}
        />
    );

    const add = (event) => {
        let newItem = generateNewItem();
        newItem.warehouseLocationName = event.warehouseLocationName
        newItem.timeZoneId = event.timeZoneId;
        newItem.countryCode = event.countryCode;
        newItem.language = event.language;

        if (!isDataItemValid(newItem)) {
            return;
        }
        removeNotification();
        setLoading(true);
        addWarehouseLocation(
            newItem,
            result => {
                refreshUserContext(handleError);
                setEditItem(null);
                newItem.warehouseLocationId = result.warehouseLocationId;
                newItem.selected = true;
                newItem.populations = [result.population];

                setSelectedWarehouse(newItem);
                selectElement(newItem);
                props.dispatchWL({ type: WL_ACTIONS.Add, payload: { item: newItem } });

                let dispatchMessage = intl.formatMessage({ id: "LocationGrid.DefaultPopulationNotification" });
                dispatchMessage += intl.formatMessage({ id: "LocationGrid.DefaultPopulationNotificationLink" });

                dispatchNotification({
                    type: NotificationTypes.info,
                    pageId: pageId,
                    message: dispatchMessage,
                    isHtml: true
                });
            },
            handleError);
    }

    const selectElement = (item) => {
        setTimeout(() => {
            let grids = document.getElementsByClassName('k-grid');
            if (grids.length > 0) {
                let grid = grids[0];
                let gridRows = grid.getElementsByClassName('k-master-row');
                if (gridRows.length > 0) {
                    for (let row of gridRows) {
                        if (row.firstChild.innerHTML === item.warehouseLocationName) {
                            row.classList.add("k-state-selected");
                        }
                        else {
                            row.classList.remove("k-state-selected");
                        }
                    }
                }
            }
            setDisable(false);
            props.decrementLoadingCount();
        });
    }

    const onSelectionChange = (event) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });

        setSelectedState(newSelectedState);

        if (newSelectedState && Object.getOwnPropertyNames(newSelectedState).length > 0) {
            let id = Object.getOwnPropertyNames(newSelectedState)[0]
            let current = data.filter((item) => item.warehouseLocationId === id);

            if (current?.length > 0) {
                if (!selectedWarehouse || selectedWarehouse.warehouseLocationId !== current[0].warehouseLocationId) {
                    setSelectedWarehouse(current[0]);
                }
            }
        }
    };

    return <div>
        <ConfirmDialog
            visible={deleteConfVisibile}
            onClose={handleConfirmDialogClose}
            negative={<FormattedMessage id='Control.Cancel' />}
            positive={<FormattedMessage id='Control.Delete' />}
            title={<FormattedMessage id='LocationGrid.DeleteConfirmationTitle' />}
            detail={[
                intl.formatMessage({ id: "LocationGrid.DoYouWantToDeleteTheWarehouseLocation" }, { 'WarehouseLocationName': itemForDelete.warehouseLocationName }),
                intl.formatMessage({ id: "LocationGrid.AttentionAllPopulationsWillBeDeleted" })
            ]}
        />

        <Card>
            <CardHeader>
                <h3>
                    <FormattedMessage id='LocationGrid.Locations' />
                    <div className="float-right">
                        <ReactTooltip id="warehouseLocationAddTip" place="top" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />
                        <span data-tip={getDisabledAddLocationButtonTooltip} data-for="warehouseLocationAddTip">
                            <Button color="primary" disabled={disable || !isAddingPopulationAllowedBySubscription}
                                onClick={handleAddButton}
                                data-tip={intl.formatMessage({ id: 'LocationGrid.AddLocation' })}
                                data-for="warehouseLocationAddTip">
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                        </span>
                    </div>
                </h3>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col xs="12">
                        <Grid
                            data={process(gridData.map((item) => ({
                                ...item,
                                [SELECTED_FIELD]: selectedState[idGetter(item)],
                                dataSourceLanguages: languages,
                                dataSourceCountries: countryList,
                                dataSourceTimeZones: timeZones??[]
                            })), dataState)}
                            {...dataState}
                            selectedField={SELECTED_FIELD}
                            onSelectionChange={onSelectionChange}
                            id="location-grid"
                            onDataStateChange={(e) => {
                                setDataState(e.dataState);
                            }}
                            selectable={{
                                enabled: true,
                                cell: false,
                                mode: 'single',
                            }}
                            key="warehouseLocationId"
                            onItemChange={itemChange}
                            editField={editField}
                            sortable={true}
                        >
                            <GridNoRecords>
                                <FormattedMessage id="LocationGrid.AddLocationInfo" />
                            </GridNoRecords>
                            <Column field="warehouseLocationName" title={intl.formatMessage({ id: "LocationGrid.WarehouseLocation" })} />
                            <Column field="countryCode" title={intl.formatMessage({ id: "WarehouseManagement.Country" })} cell={CountriesAutoComplete} />
                            <Column field="language" title={intl.formatMessage({ id: "WarehouseManagement.Lang" })} cell={LanguagesDropdown}/>
                            <Column field="timeZoneId" title={intl.formatMessage({ id: "LocationGrid.TimeZone" })} cell={TimeZonesDropdown} />
                            <Column cell={EditLocation} width="200px" />
                        </Grid>
                    </Col>
                </Row>
                {isPagingVisible && (
                    <GridFooter
                        itemsPerPage={itemsPerPage}
                        handleItemsPerPageChange={handleItemsPerPageChange}
                        dataLength={data.length}
                        setPageHandler={setPageHandler}
                        currentPage={currentPage}
                    />
                )}
            </CardBody>
        </Card>

        <Population
            dataItem={selectedWarehouse}
            disable={disable}
            setDisable={setDisable}
            dispatchWL={props.dispatchWL}
            incrementLoadingCount={props.incrementLoadingCount}
            decrementLoadingCount={props.decrementLoadingCount}
        />

    </div>;
}

export default LocationGrid