export const STORE_USER = 'STORE_USER'
export const STORE_USER_INFO = 'STORE_USER_INFO'
export const USER_SIGNED_OUT = 'USER_SIGNED_OUT'
export const USER_EXPIRED = 'USER_EXPIRED'
export const STORE_USER_ERROR = 'STORE_USER_ERROR'
export const LOADING_USER = 'LOADING_USER'

export const STORE_PROFILE = 'STORE_PROFILE'
export const GET_PROFILE = 'GET_PROFILE'
export const LOADING_PROFILE = 'LOADING_PROFILE'

export const SELECT_POPULATION = 'SELECT_POPULATION'

export const DashboardStoreCustomer = 'dashboard/storeCustomer';
