import * as React from 'react';
import { useDroppable } from '@progress/kendo-react-common';
export const DroppableBox = props => {
    const element = React.useRef(null);
    const [isInside, setIsInside] = React.useState(false);
    const handleDragEnter = React.useCallback(() => {
        setIsInside(true);
    }, []);
    const handleDragLeave = React.useCallback(() => {
        setIsInside(false);
    }, []);
    const handleDrop = React.useCallback(() => {
        props.onDrop(props.id);
    }, [props.onDrop, props.id]);
    useDroppable(element, {
        onDragEnter: handleDragEnter,
        onDragLeave: handleDragLeave,
        onDrop: handleDrop
    });
    return <div ref={element} className={!props.selected && isInside ? "droppable-box" : "droppable-box droppable-box-false"}>
        {props.children || "Drop Here"}
    </div>;
};