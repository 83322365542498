export const WL_ACTIONS = {
    Add: 'Add',
    Remove: 'Remove',
    Set: 'Set',
    Update: 'Update',
    EnterEdit: 'EnterEdit',
    AddPopulation: 'AddPopulation',
    RemovePopulation: 'RemovePopulation',
    UpdatePopulation: 'UpdatePopulation',
    UpdatePopulationImportState: 'UpdatePopulationImportState'
}


export function WL_Reducer(items, action) {
    switch (action.type) {
        case WL_ACTIONS.Set:
            return action.payload.items
        case WL_ACTIONS.Add:
            return [...items, action.payload.item]
        case WL_ACTIONS.Remove:
            return items.filter(item => item.warehouseLocationId !== action.payload.id)
        case WL_ACTIONS.Update:
            return items.map(item => {
                if (item.warehouseLocationId === action.payload.item.warehouseLocationId)
                    return action.payload.item;
                else
                    return item;
            })
        case WL_ACTIONS.EnterEdit:
            return items.map(item => {
                if (item.warehouseLocationId === action.payload.id)
                    return { ...item, inEdit: true };
                else
                    return item;
            })
        case WL_ACTIONS.AddPopulation:
            return items.map(item => {
                if (item.warehouseLocationId === action.payload.id) {
                    return {
                        ...item,
                        populations: [...item.populations, action.payload.item]
                    };
                }
                else
                    return item;
            })
        case WL_ACTIONS.RemovePopulation:
            return items.map(item => {
                if (item.warehouseLocationId === action.payload.id)
                    return {
                        ...item,
                        populations: item.populations.filter(population => population.populationId !== action.payload.populationId)
                    };
                else
                    return item;
            })
        case WL_ACTIONS.UpdatePopulation:
            return items.map(item => {
                if (item.warehouseLocationId === action.payload.id) {
                    return {
                        ...item,
                        populations: item.populations.map(population => {
                            if (population.populationId === action.payload.item.populationId)
                                return action.payload.item;
                            else
                                return population;
                        })
                    };
                }
                else
                    return item;
            })

        case WL_ACTIONS.UpdatePopulationImportState:
            let whOfPop = getWarehouseOfPopulation(items, action.payload.populationId);

            if (!whOfPop) {
                return items;
            }
            return items.map(item => {
                if (item.warehouseLocationId === whOfPop.warehouseLocationId) {
                    return {
                        ...item,
                        importPopulationInProgress: item.populations.some(population => population.populationId === action.payload.populationId) ? action.payload.importInProgress : false,
                        populations: item.populations.map(population => {
                            if (population.populationId === action.payload.populationId)
                                return {
                                    ...population,
                                    importInProgress: action.payload.importInProgress
                                };
                            else
                                return population;
                        })
                    }
                }
                else
                    return item
            })

        default:
            return items
    }
}

function getWarehouseOfPopulation(warehouses, populationId) {
    if (!warehouses || !populationId) {
        return null;
    }

    return warehouses.find(warehouse => {
        return warehouse.populations.find(pop => { return pop.populationId == populationId });
    });
}
