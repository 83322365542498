import React, { useState, useEffect } from 'react';
import { useLoading } from "../Shared/LoadingContext";
import { useNotification } from '../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../Shared/Notifications/Notification';
import { useExceptionDialog } from '../Shared/ExceptionDialog/ExceptionDialogProvider';
import axios from 'axios';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row, Card, CardHeader, CardBody, Input } from 'reactstrap';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { getActivityHistory } from "../../services/reportsService"
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { CSVLink } from "react-csv";
import GridFooter from '../Shared/GridFooter';

export default function ActivityHistory(props) {
    const downloadFileName = 'activityHistory.csv';
    const DEFAULT_ITEMS_PER_PAGE = 10;

    const cancelTokenSource = axios.CancelToken.source();
    const intl = useIntl();
    const { setLoading } = useLoading();

    const pageId = 'pageActivityHistory';
    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const [activityHistory, setActivityHistory] = useState([]);
    const [filteredActivityHistory, setFilteredActivityHistory] = useState([]);
    const [filterDate, setFilterDate] = React.useState("");
    const [filterTime, setFilterTime] = React.useState("");
    const [filterAction, setFilterAction] = React.useState("");
    const [filterUser, setFilterUser] = React.useState("");
    const [gridWords, setGridWords] = useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [itemsPerPage, setItemsPerPage] = React.useState(DEFAULT_ITEMS_PER_PAGE);
    const [isPagingVisible, setIsPagingVisible] = React.useState(false);
    const minGridLength = 5;

    useEffect(() => {
        return () => {
            cancelTokenSource.cancel();
        };
    }, []);

    useEffect(() => {
        refreshActivityHistory();
    }, [props.populationId]);

    useEffect(() => {
        applyFilter();
    }, [activityHistory, filterDate, filterTime, filterAction, filterUser]);

    useEffect(() => {
        setIsPagingVisible(filteredActivityHistory.length > minGridLength);
        setPageHandler(1);
    }, [filteredActivityHistory, itemsPerPage]);

    const applyFilter = () => {
        const filteredArray = activityHistory.filter(
            (w) => (
                w.datum.toLowerCase().includes(filterDate.toLowerCase()) &&
                w.uhrzeit.toLowerCase().includes(filterTime.toLowerCase()) &&
                w.aktion.toLowerCase().includes(filterAction.toLowerCase()) &&
                w.aktUser.toLowerCase().includes(filterUser.toLowerCase())
            )
        );
        setFilteredActivityHistory(filteredArray);
    }

    const refreshActivityHistory = () => {
        setActivityHistory([]);

        if (props.populationId) {
            setLoading(true);
            getActivityHistory(props.populationId,
                actHist => {
                    setLoading(false);
                    setActivityHistory(actHist);
                },
                handleError,
                cancelTokenSource.token
            );
        }
    }

    const handleError = (errorMessage, showNotif = true) => {
        setLoading(false);
        if (showNotif) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            });
        }
    };

    const applyFilterDate = (value) => {
        setCurrentPage(1);
        setFilterDate(value);
    }

    const applyFilterTime = (value) => {
        setCurrentPage(1);
        setFilterTime(value);
    }

    const applyFilterAction = (value) => {
        setCurrentPage(1);
        setFilterAction(value);
    }

    const applyFilterUser = (value) => {
        setCurrentPage(1);
        setFilterUser(value);
    }

    const setPageHandler = (page) => {
        if (filteredActivityHistory && page > 0 && (page <= Math.floor((filteredActivityHistory.length - 1) / itemsPerPage) + 1 || filteredActivityHistory.length == 0)) {
            let startIndex = (page - 1) * itemsPerPage;
            let endIndex = (page) * itemsPerPage;
            setGridWords(filteredActivityHistory.slice(startIndex, endIndex));
            if (activityHistory.length > itemsPerPage) {
                setCurrentPage(page);
            }
            else {
                setCurrentPage(1);
            }
        }
    }

    const handleItemsPerPageChange = (dropDownItem) => {
        setItemsPerPage(dropDownItem.value);
    };

    const exportDisabled = !filteredActivityHistory || filteredActivityHistory.length <= 0;

    return (
        <div className="content">
            <Card id="pop-tile">
                <CardHeader>
                    <h3>
                        <FormattedMessage id='Reports.ActivityHistory.Title' />
                        <div className="float-right no-margin">
                            <CSVLink className="btn btn-primary" data={filteredActivityHistory} filename={downloadFileName} disabled={exportDisabled}>
                                <FormattedMessage id="Control.Export" />
                            </CSVLink>
                        </div>
                    </h3>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col xs="3">
                            <Input className="grid-filter" name="filterDateValue" placeholder={intl.formatMessage({ id: "Reports.ActivityHistory.DateFilter" })} onChange={(event) => {
                                applyFilterDate(event.target.value);
                            }} />
                        </Col>
                        <Col xs="3">
                            <Input className="grid-filter" name="filterTimeValue" placeholder={intl.formatMessage({ id: "Reports.ActivityHistory.TimeFilter" })} onChange={(event) => {
                                applyFilterTime(event.target.value);
                            }} />
                        </Col>
                        <Col xs="3">
                            <Input className="grid-filter" name="filterActionValue" placeholder={intl.formatMessage({ id: "Reports.ActivityHistory.ActionFilter" })} onChange={(event) => {
                                applyFilterAction(event.target.value);
                            }} />
                        </Col>
                        <Col xs="3">
                            <Input className="grid-filter" name="filterUserValue" placeholder={intl.formatMessage({ id: "Reports.ActivityHistory.UserFilter" })} onChange={(event) => {
                                applyFilterUser(event.target.value);
                            }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <Grid data={gridWords}
                                key="id">
                                <GridNoRecords>
                                    {<FormattedMessage id="Reports.ActivityHistory.EmptyActivityHistory" />}
                                </GridNoRecords>
                                <Column field="datum" title={intl.formatMessage({ id: "Reports.ActivityHistory.Date" })} />
                                <Column field="uhrzeit" title={intl.formatMessage({ id: "Reports.ActivityHistory.Time" })} />
                                <Column field="aktion" title={intl.formatMessage({ id: "Reports.ActivityHistory.Action" })} />
                                <Column field="aktUser" title={intl.formatMessage({ id: "Reports.ActivityHistory.User" })} />
                            </Grid>
                        </Col>
                    </Row>
                    {isPagingVisible && (
                        <GridFooter
                            itemsPerPage={itemsPerPage}
                            handleItemsPerPageChange={handleItemsPerPageChange}
                            dataLength={filteredActivityHistory.length}
                            setPageHandler={setPageHandler}
                            currentPage={currentPage}
                        />                      
                    )}
                </CardBody>
            </Card>
        </div>
    );
}