import { get, post, put, deleteResource } from './serviceBase';

const baseUrl = '/api/v1.0/admin/UserManagement';

export function addUser(user, sendMail, successCallback, errorCallback) {
    const params = new URLSearchParams();
    if (sendMail) {
        params.append('sendMail', sendMail);
    }
    const url = `${baseUrl}/AddUser?` + params;
    post(url, user, successCallback, errorCallback);
}

export function getUsers(groupId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('groupId', groupId);
    const url = `${baseUrl}/GetUsers?` + params;
    get(url, successCallback, errorCallback);
}

export function getAvailableUserRoles(successCallback, errorCallback) {
    const url = `${baseUrl}/GetAvailableUserRoles`;
    get(url, successCallback, errorCallback);
}

export function getUserRoles(userId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    if (userId) {
        params.append('userId', userId);
    }
    const url = `${baseUrl}/GetUserRoles?` + params;
    get(url, successCallback, errorCallback);
}

export function addRolesToUser(userRoles, successCallback, errorCallback) {
    const url = `${baseUrl}/AddRolesToUser`;
    post(url, userRoles, successCallback, errorCallback);
}

export function updateUser(userId, sendMail, user, successCallback, errorCallback) {
    const params = new URLSearchParams();
    if (userId) {
        params.append('userId', userId);
    }
    if (sendMail) {
        params.append('sendMail', sendMail);
    }
    const url = `${baseUrl}/UpdateUser?` + params;
    post(url, user, successCallback, errorCallback);
}

export function deleteUser(userId, groupId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    if (userId) {
        params.append('userId', userId);
    }
    if (groupId) {
        params.append('groupId', groupId);
    }
    
    const url = `${baseUrl}/DeleteUser?` + params;
    deleteResource(url, successCallback, errorCallback);
}

export function enableUser(userId, isEnabled, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('userId', userId);
    params.append('isEnabled', isEnabled);
    const url = `${baseUrl}/EnableUser?` + params;
    put(url, null, successCallback, errorCallback);
}

export function updateUserPassword(userId, newPassword, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('userId', userId);
    params.append('newPassword', newPassword);
    const url = `${baseUrl}/UpdateUserPassword?` + params;
    post(url, null, successCallback, errorCallback);
}

export function getPasswordPolicy(successCallback, errorCallback) {
    const url = `${baseUrl}/GetPasswordPolicy`;
    get(url, successCallback, errorCallback);
}
