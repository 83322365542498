/******************************************
 *  Author : Dr. Hanno Georg   
 *  Created On : Fri Sep 03 2021
 *  File : index.js.js
 *******************************************/

import { combineReducers } from "redux";
import authReducer from './authReducer';
import profileReducer from './profileReducer';
import dashboardReducer from './dashboardReducer';

export const reducers = combineReducers({
    auth: authReducer,
    profile: profileReducer,
    dashboard: dashboardReducer
});
