import * as React from "react";
import { Button } from '@progress/kendo-react-buttons';
import { Icon } from '@progress/kendo-react-common';
import { DroppableBox } from "./DroppableBox";
import { useIntl } from "react-intl";
import { Col, Row } from 'reactstrap';

export const DropBoxCell = (props) => {
    const intl = useIntl();

    const handleDrop = React.useCallback(e => {
        let allowDrop = props.dataItem && !props.dataItem.originalColumnName;
        if (allowDrop && props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                value: e,
            });
        }
    }, [props.dataItem, props.onChange, props.field]);

    const onRemove = React.useCallback(event => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: "removeHeader",
                value: event
            });
        }
    }, [props.onChange, props.dataItem]);

    const getFieldType = () => {
        let fieldInfo = '';
        if (props.dataItem) {
            if (props.dataItem.fId <= window.enumFields.Ident15) {
                fieldInfo = intl.formatMessage({ id: 'InterfaceConfiguration.UniqueId' });
                fieldInfo = `${fieldInfo} (${props.dataItem.fId})`;
            }
            else if (props.dataItem.fId >= window.enumFields.AdditionalField1 && props.dataItem.fId <= window.enumFields.AdditionalField15) {
                fieldInfo = intl.formatMessage({ id: 'InterfaceConfiguration.AdditionalFields' });
                fieldInfo = `${fieldInfo} (${props.dataItem.fId - window.enumFields.AdditionalField1 + 1})`;
            }
            else {
                switch (props.dataItem.fId) {
                    case window.enumFields.Quantity:
                        fieldInfo = intl.formatMessage({ id: 'InterfaceConfiguration.Quantity' })
                        break;
                    case window.enumFields.SinglePrice:
                        fieldInfo = intl.formatMessage({ id: 'InterfaceConfiguration.Price' })
                        break;
                    case window.enumFields.QuantityUnit:
                        fieldInfo = intl.formatMessage({ id: 'InterfaceConfiguration.QuantityUnit' })
                        break;
                    case window.enumFields.PriceUnit:
                        fieldInfo = intl.formatMessage({ id: 'InterfaceConfiguration.PriceUnit' })
                        break;
                    case window.enumFields.ItemName:
                        fieldInfo = intl.formatMessage({ id: 'InterfaceConfiguration.ArticleDescription' })
                        break;
                    case window.enumFields.StratumNumber:
                        fieldInfo = intl.formatMessage({ id: 'InterfaceConfiguration.Stratum' })
                        break;
                    case window.enumFields.QuantityDifference:
                        fieldInfo = intl.formatMessage({ id: 'InterfaceConfiguration.DifferenceQuantity' })
                        break;
                    case window.enumFields.DummyT1:
                        fieldInfo = intl.formatMessage({ id: 'InterfaceConfiguration.DifferenceQuantitySign' })
                        break;
                    case window.enumFields.ValueDifference:
                        fieldInfo = intl.formatMessage({ id: 'InterfaceConfiguration.DifferenceValue' })
                        break;
                    case window.enumFields.DummyT2:
                        fieldInfo = intl.formatMessage({ id: 'InterfaceConfiguration.DifferenceValueSign' })
                        break;
                    case window.enumFields.ActualQuantity:
                        fieldInfo = intl.formatMessage({ id: 'InterfaceConfiguration.ActualQuantity' })
                        break;
                    case window.enumFields.QuantitySign:
                        fieldInfo = intl.formatMessage({ id: 'InterfaceConfiguration.QuantitySign' })
                        break;
                    case window.enumFields.CategoryMark:
                        fieldInfo = intl.formatMessage({ id: 'InterfaceConfiguration.CategoryMark' })
                        break;
                    case window.enumFields.PriceSign:
                        fieldInfo = intl.formatMessage({ id: 'InterfaceConfiguration.PriceSign' })
                        break;
                    default:
                        fieldInfo = "";
                }
            }
        }
        return fieldInfo;
    }
    return (
        <td>
            <Row>
                <Col xs="5">
                    <div className="m-2">
                        {getFieldType()}
                    </div>
                </Col>
                <Col xs="7">
                    {props.dataItem && !props.dataItem.originalColumnName &&
                        <DroppableBox
                            onDrop={handleDrop}
                            disabled={true}
                        >

                        </DroppableBox>
                    }
                    {props.dataItem && props.dataItem.originalColumnName &&
                        <Button themeColor={'secondary'}>
                            <Icon name="move" size="medium" className="mr-1" />
                            {props.dataItem.originalColumnName}
                            {props.dataItem.selected == null && props.dataItem.showClose &&
                                <Icon name="close" size="medium" className="ml-1" onClick={onRemove} />}
                        </Button>
                    }
                </Col>
            </Row>
        </td>
    );
};