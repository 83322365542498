import { createContext } from "react";

export const ConfigContext = createContext({
    InterfaceConfiguration: {},
    InitialData: {},
    ImportCountQuantities: {},
    ExportCountItems: {},
    ExportAccountingData: {},
    ExportItemTableId: {}
});

export const CONFIG_CONTEXT_ACTIONS = {
    SetContext: 'SetContext',
    SetInterfaceConfiguration: 'SetInterfaceConfiguration',
    SetInitialData: 'SetInitialData',
    SetInitialDataHeaders: 'SetInitialDataHeaders',
    SetImportCountQuantitiesHeaders: 'SetImportCountQuantitiesHeaders',

    SetColumnSeparators: 'SetColumnSeparators',
    SetDecimalSeparators: 'SetDecimalSeparators',
    SetThousandSeparators: 'SetThousandSeparators',
    SetPriceUnits: 'SetPriceUnits',
    SetFieldTypes: 'SetFieldTypes',
    SetCurrentColumnSeparator: 'SetCurrentColumnSeparator',
    SetCustomColumnSeparator: 'SetCustomColumnSeparator',
    SetHasHeader: "SetHasHeader",
    SetModifiedParamsState: "SetModifiedParamsState",
    SelectedTabType: "SelectedTabType"
}


export function ConfigContextReducer(state, action) {
    switch (action.type) {
        case CONFIG_CONTEXT_ACTIONS.SetContext:
            {
                return action.payload;

            }
        case CONFIG_CONTEXT_ACTIONS.SetInterfaceConfiguration:
            {
                let currentState = { ...state };
                currentState.interfaceConfiguration = action.payload;
                return currentState;
            }
        case CONFIG_CONTEXT_ACTIONS.SetInitialData:
            {
                let currentState = { ...state };
                currentState.initialData = action.payload;
                return currentState;
            }
        case CONFIG_CONTEXT_ACTIONS.SetInitialDataHeaders:
            {
                let currentState = { ...state };
                currentState.interfaceConfiguration.initialDataHeaders = action.payload;
                return currentState;
            }
        case CONFIG_CONTEXT_ACTIONS.SetImportCountQuantitiesHeaders:
            {
                let currentState = { ...state };
                currentState.interfaceConfiguration.importCountQuantitiesHeaders = action.payload;
                return currentState;
            }            
        case CONFIG_CONTEXT_ACTIONS.SetColumnSeparators:
            {
                let currentState = { ...state };
                currentState.columnSeparators = action.payload;
                return currentState;
            }
        case CONFIG_CONTEXT_ACTIONS.SetDecimalSeparators:
            {
                let currentState = { ...state };
                currentState.decimalSeparators = action.payload;
                return currentState;
            }
        case CONFIG_CONTEXT_ACTIONS.SetThousandSeparators:
            {
                let currentState = { ...state };
                currentState.thousandSeparators = action.payload;
                return currentState;
            }
        case CONFIG_CONTEXT_ACTIONS.SetPriceUnits:
            {
                let currentState = { ...state };
                currentState.priceUnits = action.payload;
                return currentState;
            }
        case CONFIG_CONTEXT_ACTIONS.SetFieldTypes:
            {
                let currentState = { ...state };
                currentState.fieldTypes = action.payload;
                return currentState;
            }
        case CONFIG_CONTEXT_ACTIONS.SetCurrentColumnSeparator:
            {
                let currentState = { ...state };
                currentState.currentColumnSeparator = action.payload;
                return currentState;
            }
        case CONFIG_CONTEXT_ACTIONS.SetCustomColumnSeparator:
            {
                let currentState = { ...state };
                currentState.customColumnSeparator = action.payload;
                return currentState;
            }
        case CONFIG_CONTEXT_ACTIONS.SetHasHeader:
            {
                let currentState = { ...state };
                currentState.hasHeader = action.payload;
                return currentState;
            }
        case CONFIG_CONTEXT_ACTIONS.SetModifiedParamsState:
            {
                let currentState = { ...state };
                currentState.modifiedParamsState = action.payload;
                return currentState;
            }

        case CONFIG_CONTEXT_ACTIONS.SelectedTabType:
            {
                let currentState = { ...state };
                currentState.selectedTabType = action.payload;
                return currentState;
            }
        default:
            return state
    }
}
