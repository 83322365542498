import React, { useState, useEffect, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { getCountingDefaults, getStaseqCountingDefaults } from '../../../../services/countService';
import { getInventoryMethod, loadPdfReport, setWaitingForErp } from '../../../../services/inventoryService';
import { useExceptionDialog } from '../../../Shared/ExceptionDialog/ExceptionDialogProvider';
import { useLoading } from '../../../Shared/LoadingContext';
import { NotificationTypes } from '../../../Shared/Notifications/Notification';
import { useNotification } from '../../../Shared/Notifications/NotificationProvider';
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { wizardEvents, wizardStep } from '../Wizard';
import { WizzardContext, WIZZARD_ACTIONS } from '../WizzardContext';
import { off, on, trigger } from '../../../../actions/events';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import PreviewPDF from '../../PreviewPDF';

const CountListParameters = (props) => {
    const pageId = 'pageCountListParameters';
    const { setLoading } = useLoading();
    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const intl = useIntl();

    const [staseqCountingDefault, setStaseqCountingDefault] = useState(null);
    const [countingDefaults, setCountingDefaults] = useState(null);

    const [sequentialTestItemsToCount, setSequentialTestItemsToCount] = useState(0);
    const [completeCountItemsToCount, setCompleteCountItemsToCount] = useState(0);
    const [initalSampleItemsToCount, setInitalSampleItemsToCount] = useState(0);

    const [redrawItemsLeftToCount, setRedrawItemsLeftToCount] = useState(0);
    const [randomSamplingItemsLeftToCount, setRandomSamplingItemsLeftToCount] = useState(0);
    const [completeCountItemsLeftToCount, setCompleteCountItemsLeftToCount] = useState(0);

    const [fileInitialImport, setFileInitialImport] = React.useState(null);
    const [selected, setSelected] = React.useState(0);
    const wizzardContext = React.useContext(WizzardContext);
    const [inventoryMethod, setInventoryMethod] = useState(null);

    const isHighQuality = useCallback(() => {
        return inventoryMethod === window.enumInventoryMethod.Staseq;
    },[inventoryMethod]);

    const notifyError = useCallback((errorMessage) => dispatchNotification({
        type: NotificationTypes.error,
        pageId: 'pageInventoryWizard.Sampling',
        message: errorMessage
    }),[dispatchNotification]);

    const showException = error => dispatchExceptionDialog({
        pageId: 'pageInventoryWizard.StockData',
        message: error.detail || error
    });

    const [loadingCount, setLoadingCount] = useState(0);
    useEffect(() => {
        setLoading(loadingCount > 0);
    }, [loadingCount]);
    const selectedPopulation = useSelector(state => state.dashboard.population);

    const handleError = useCallback((errorMessage, showNotif = true) => {
        decrementLoadingCount();
        if (showNotif) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            })
        }
    },[]);

    React.useEffect(() => {
        incrementLoadingCount();
        getInventoryMethod(
            selectedPopulation.populationId,
            result => {
                decrementLoadingCount();
                if (result.succeeded) {
                    setInventoryMethod(result.data);
                }
            },
            handleError
        );
    }, []);

    React.useEffect(() => {
        if (inventoryMethod != null) {
            incrementLoadingCount();
            if (isHighQuality()) {
                getStaseqCountingDefaults(
                    selectedPopulation.populationId,
                    result => {
                        decrementLoadingCount();
                        setStaseqCountingDefault(result.data);
                        setCompleteCountItemsToCount(result.data.completeCount.itemsLeftToCount);
                        setInitalSampleItemsToCount(result.data.initalSample.itemsLeftToCount);
                        setSequentialTestItemsToCount(result.data.sequentialTest.itemsLeftToCount);
                    },
                    handleError
                );

            }
            else {
                getCountingDefaults(
                    selectedPopulation.populationId,
                    result => {
                        decrementLoadingCount();
                        setCountingDefaults(result.data);
                        setRedrawItemsLeftToCount(result.data.redraw.itemsLeftToCount);
                        setRandomSamplingItemsLeftToCount(result.data.randomSampling.itemsLeftToCount);
                        setCompleteCountItemsLeftToCount(result.data.completeCount.itemsLeftToCount);
                        props.setContinueBtnDisabled(true);

                    },
                    handleError
                );
            }
        }
    }, [inventoryMethod]);

    const totalItemsToCount = completeCountItemsToCount + initalSampleItemsToCount + sequentialTestItemsToCount;
    const staseqAlreadyCountedItems = (staseqCountingDefault?.completeCount?.alreadyCountedItems ?? 0) +
        (staseqCountingDefault?.initalSample?.alreadyCountedItems ?? 0) +
        (staseqCountingDefault?.sequentialTest?.alreadyCountedItems ?? 0);
    const staseqMaxItemsToCount = (staseqCountingDefault?.totalNoOfItems ?? 0) - staseqAlreadyCountedItems - 1;

    const checkHQTotalItemsToCount = useCallback(() => {
        if (totalItemsToCount > staseqMaxItemsToCount) {
            notifyError(intl.formatMessage({ id: 'CountListParameters.SeqMaxNumberOfItemsToCount' }, { MaxItemsToCount: staseqMaxItemsToCount }));
            return false;
        }
        return true;
    }, [intl, notifyError, staseqMaxItemsToCount, totalItemsToCount]);

    const setWaitingForErpAndContinue = useCallback(() => {
        if (wizzardContext.wizzardGlobal.InterfaceConfiguration?.connectedToErp) {
            setLoading(true);
            setWaitingForErp(
                selectedPopulation.populationId,
                true,
                _success => {
                    setLoading(false);
                    trigger(wizardEvents.stepFinalized, {});
                },
                handleError
            );
            return;
        }

        trigger(wizardEvents.stepFinalized, {});
    },[handleError, selectedPopulation.populationId, setLoading, wizzardContext.wizzardGlobal.InterfaceConfiguration?.connectedToErp]);

    const continueButtonClicked = useCallback((event) => {
        if (event.detail === wizardStep.CountListParameters && isHighQuality()) {
            if (!checkHQTotalItemsToCount()) {
                return;
            }
            wizzardContext.dispatchWizzardGlobal({
                type: WIZZARD_ACTIONS.SetStaseqCounting, payload: {
                    completeCountItemsToCount: completeCountItemsToCount,
                    initalSampleItemsToCount: initalSampleItemsToCount,
                    sequentialTestItemsToCount: sequentialTestItemsToCount
                }
            });
        }
        else {
            wizzardContext.dispatchWizzardGlobal({
                type: WIZZARD_ACTIONS.SetStasamCounting, payload: {
                    completeCountItemsToCount: completeCountItemsLeftToCount,
                    randomSamplingItemsToCount: randomSamplingItemsLeftToCount,
                    recountItemsToCount: redrawItemsLeftToCount
                }
            });

        }
        setWaitingForErpAndContinue();
    },[checkHQTotalItemsToCount, completeCountItemsLeftToCount, completeCountItemsToCount, initalSampleItemsToCount, isHighQuality, randomSamplingItemsLeftToCount, redrawItemsLeftToCount, sequentialTestItemsToCount, setWaitingForErpAndContinue, wizzardContext]);

    useEffect(() => {
        on(wizardEvents.continueButtonClicked, continueButtonClicked);

        return () => {
            off(wizardEvents.continueButtonClicked, continueButtonClicked);
        }
    }, [continueButtonClicked]);

    const handleSequentialTestItemsToCountChange = (event) => {
        setSequentialTestItemsToCount(event.target.value);
    };

    const handleCompleteCountItemsToCountChange = (event) => {
        setCompleteCountItemsToCount(event.target.value);
    };

    const handleInitalSampleInputEnabledChange = (event) => {
        setInitalSampleItemsToCount(event.target.value);
    };

    const handleRedrawItemsLeftToCountChange = (event) => {
        setRedrawItemsLeftToCount(event.target.value);
    };

    const handleRandomSamplingItemsLeftToCountChange = (event) => {
        setRandomSamplingItemsLeftToCount(event.target.value);
    };

    const handleCompleteCountItemsLeftToCountChange = (event) => {
        setCompleteCountItemsLeftToCount(event.target.value);
    };

    const incrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount + 1);
    };

    const decrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount - 1);
    };

    const pdfPreviewErrorCallback = error => {
        setLoading(false);
        showException(error);
    }

    const loadPdfSuccess = pdf => {
        setFileInitialImport(pdf);
        setLoading(false);
    }

    const handleSelect = async e => {
        setSelected(e.selected);
        switch (e.selected) {
            case 0:

                break;
            case 1:
                if (!fileInitialImport) {
                    setLoading(true);
                    loadPdfReport(window.enumReportType.UpdateImportResult, loadPdfSuccess, pdfPreviewErrorCallback, selectedPopulation?.populationId);
                }
                break;
            default:
                break;
        }
    };

    return (
        <div>
            <Row>
                <Col xs="9">
                    <TabStrip selected={selected} onSelect={handleSelect}>
                        <TabStripTab title={<FormattedMessage id='CountListParameters.CountingParameters' />}>
                            <Card >

                                <CardBody>
                                    {isHighQuality() ?
                                        (
                                            staseqCountingDefault &&
                                            < div >
                                                <p>  <FormattedMessage id='CountListParameters.InformationAboutProcessOfTheSequenzialTest' /> </p>
                                                <Row className="mt-2">
                                                    <Col xs="9">
                                                        <FormattedMessage id='CountListParameters.MinimumNumberOfItemsNeededToSeachAcceptanceThreshold' />
                                                    </Col>
                                                    <Col xs="3">
                                                        <Input
                                                            disabled={true}
                                                            id="staseqCountingDefault.completeCount.totalItemsToCount"
                                                            value={staseqCountingDefault.noOfItemsToReachTheAcceptanceThreshold}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Col xs="9">
                                                        <FormattedMessage id='CountListParameters.AcceptanceThresholdAfterRecordingOfItems' />
                                                    </Col>
                                                    <Col xs="3">
                                                        <Input
                                                            disabled={true}
                                                            id="staseqCountingDefault.completeCount.totalItemsToCount"
                                                            value={staseqCountingDefault.acceptanceThresholdAfterFeedback}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Col xs="9">
                                                        <FormattedMessage id='CountListParameters.RejectionThresholdAfterRecordingOfItems' />
                                                    </Col>
                                                    <Col xs="3">
                                                        <Input
                                                            disabled={true}
                                                            id="staseqCountingDefault.completeCount.totalItemsToCount"
                                                            value={staseqCountingDefault.rejectionThresholdAfterFeedback}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Col xs="9">
                                                        <FormattedMessage id='CountListParameters.PresentNoOfErrors' />
                                                    </Col>
                                                    <Col xs="3">
                                                        <Input
                                                            disabled={true}
                                                            id="staseqCountingDefault.completeCount.totalItemsToCount"
                                                            value={staseqCountingDefault.currentNoOfErrors}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="mt-5">
                                                    <Col xs="3">
                                                    </Col>
                                                    <Col xs="3">
                                                        <FormattedMessage id='CountListParameters.Total' />
                                                    </Col>
                                                    <Col xs="3">
                                                        <FormattedMessage id='CountListParameters.Counted' />
                                                    </Col>
                                                    <Col xs="3">
                                                        <FormattedMessage id='CountListParameters.CountNow' />
                                                    </Col>
                                                </Row>
                                                <Row className="mt-5">
                                                    <Col xs="3">
                                                        <FormattedMessage id='CountListParameters.ItemsCompleteCount' />
                                                    </Col>
                                                    <Col xs="3">
                                                        <Input
                                                            disabled={true}
                                                            id="staseqCountingDefault.completeCount.totalItemsToCount"
                                                            value={staseqCountingDefault.completeCount.totalItemsToCount}
                                                        />
                                                    </Col>
                                                    <Col xs="3">
                                                        <Input
                                                            disabled={true}
                                                            id="staseqCountingDefault.completeCount.alreadyCountedItems"
                                                            value={staseqCountingDefault.completeCount.alreadyCountedItems}
                                                        />
                                                    </Col>
                                                    <Col xs="3">
                                                        <NumericTextBox
                                                            id="staseqCountingDefault.initalSample.itemsLeftToCount"
                                                            value={completeCountItemsToCount}
                                                            min={0}
                                                            max={staseqMaxItemsToCount}
                                                            disabled={!staseqCountingDefault.completeCountInputEnabled}
                                                            onChange={handleCompleteCountItemsToCountChange}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Col xs="3">
                                                        <FormattedMessage id='CountListParameters.ItemsInitialSample' />
                                                    </Col>
                                                    <Col xs="3">
                                                        <Input
                                                            disabled={true}
                                                            id="staseqCountingDefault.initalSample.totalItemsToCount"
                                                            value={staseqCountingDefault.initalSample.totalItemsToCount}
                                                        />
                                                    </Col>
                                                    <Col xs="3">
                                                        <Input
                                                            disabled={true}
                                                            id="staseqCountingDefault.initalSample.alreadyCountedItems"
                                                            value={staseqCountingDefault.initalSample.alreadyCountedItems}
                                                        />
                                                    </Col>
                                                    <Col xs="3">
                                                        <NumericTextBox
                                                            id="staseqCountingDefault.initalSample.itemsLeftToCount"
                                                            value={initalSampleItemsToCount}
                                                            min={0}
                                                            max={staseqMaxItemsToCount}
                                                            disabled={!staseqCountingDefault.initalSampleInputEnabled}
                                                            onChange={handleInitalSampleInputEnabledChange}
                                                        />
                                                    </Col>
                                                </Row>

                                                <Row className="mt-2">
                                                    <Col xs="3">
                                                        <FormattedMessage id='CountListParameters.ItemsSequentialTest' />
                                                    </Col>
                                                    <Col xs="3">
                                                        <Input
                                                            disabled={true}
                                                            id="staseqCountingDefault.sequentialTest.totalItemsToCount"
                                                            value={staseqCountingDefault.sequentialTest.totalItemsToCount}
                                                        />
                                                    </Col>
                                                    <Col xs="3">
                                                        <Input
                                                            disabled={true}
                                                            id="staseqCountingDefault.sequentialTest.totalItemsToCount"
                                                            value={staseqCountingDefault.sequentialTest.alreadyCountedItems}
                                                        />
                                                    </Col>
                                                    <Col xs="3">
                                                        <NumericTextBox
                                                            id="staseqCountingDefault.sequentialTest.itemsLeftToCount"
                                                            value={sequentialTestItemsToCount}
                                                            min={0}
                                                            max={staseqMaxItemsToCount}
                                                            disabled={!staseqCountingDefault.sequentialTestInputEnabled}
                                                            onChange={handleSequentialTestItemsToCountChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>)
                                        :
                                        (

                                            countingDefaults &&
                                            <div>
                                                <Row>
                                                    <Col xs="3">
                                                    </Col>
                                                    <Col xs="3">
                                                        <FormattedMessage id='CountListParameters.Maximal' />
                                                    </Col>
                                                    <Col xs="3">
                                                        <FormattedMessage id='CountListParameters.Counted' />
                                                    </Col>
                                                    <Col xs="3">
                                                        <FormattedMessage id='CountListParameters.Now' />
                                                    </Col>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Col xs="3">
                                                        <FormattedMessage id='CountListParameters.CompleteCount' />
                                                    </Col>
                                                    <Col xs="3">
                                                        <Input
                                                            disabled={true}
                                                            id="countingDefaults.completeCount.totalItemsToCount"
                                                            value={countingDefaults.completeCount.totalItemsToCount}
                                                        />
                                                    </Col>
                                                    <Col xs="3">
                                                        <Input
                                                            disabled={true}
                                                            id="countingDefaults.completeCount.alreadyCountedItems"
                                                            value={countingDefaults.completeCount.alreadyCountedItems}
                                                        />
                                                    </Col>
                                                    <Col xs="3">
                                                        <NumericTextBox
                                                            id="countingDefaults.completeCount.itemsLeftToCount"
                                                            value={completeCountItemsLeftToCount}
                                                            disabled={!props.isPerpetualInventory() || countingDefaults.completeCount.itemsLeftToCount == 0}
                                                            min={0}
                                                            max={countingDefaults.completeCount.itemsLeftToCount}
                                                            onChange={handleCompleteCountItemsLeftToCountChange}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Col xs="3">
                                                        <FormattedMessage id='CountListParameters.RandomSampling' />
                                                    </Col>
                                                    <Col xs="3">
                                                        <Input
                                                            disabled={true}
                                                            id="countingDefaults.randomSampling.totalItemsToCount"
                                                            value={countingDefaults.randomSampling.totalItemsToCount}
                                                        />
                                                    </Col>
                                                    <Col xs="3">
                                                        <Input
                                                            disabled={true}
                                                            id="countingDefaults.randomSampling.alreadyCountedItems"
                                                            value={countingDefaults.randomSampling.alreadyCountedItems}
                                                        />
                                                    </Col>
                                                    <Col xs="3">
                                                        <NumericTextBox
                                                            id="countingDefaults.randomSampling.itemsLeftToCount"
                                                            value={randomSamplingItemsLeftToCount}
                                                            disabled={!props.isPerpetualInventory() || countingDefaults.randomSampling.itemsLeftToCount == 0}
                                                            min={0}
                                                            max={countingDefaults.randomSampling.itemsLeftToCount}
                                                            onChange={handleRandomSamplingItemsLeftToCountChange}
                                                        />
                                                    </Col>
                                                </Row>

                                                <Row className="mt-2">
                                                    <Col xs="3">
                                                        <FormattedMessage id='CountListParameters.Redraw' />
                                                    </Col>
                                                    <Col xs="3">
                                                        <Input
                                                            disabled={true}
                                                            id="countingDefaults.redraw.totalItemsToCount"
                                                            value={countingDefaults.redraw.totalItemsToCount}
                                                        />
                                                    </Col>
                                                    <Col xs="3">
                                                        <Input
                                                            disabled={true}
                                                            id="countingDefaults.redraw.alreadyCountedItems"
                                                            value={countingDefaults.redraw.alreadyCountedItems}
                                                        />
                                                    </Col>
                                                    <Col xs="3">
                                                        <NumericTextBox
                                                            disabled={!props.isPerpetualInventory() || countingDefaults.redraw.itemsLeftToCount == 0}
                                                            id="countingDefaults.redraw.itemsLeftToCount"
                                                            value={redrawItemsLeftToCount}
                                                            min={0}
                                                            max={countingDefaults.redraw.itemsLeftToCount}
                                                            onChange={handleRedrawItemsLeftToCountChange}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        )
                                    }
                                </CardBody>
                            </Card>
                        </TabStripTab>
                        {props.showImportUpdatePdf &&
                            <TabStripTab title={<FormattedMessage id='General.PdfView' />} >
                                <div>
                                    <PreviewPDF file={fileInitialImport}
                                        prevPageText='<'
                                        nextPageText='>'
                                        downloadFileText='Download document'
                                    />
                                </div>
                            </TabStripTab>}
                    </TabStrip>
                </Col>
                <Col xs="3" >
                    <Card className="card-chart" >
                        <CardBody >
                            <p><FormattedMessage id='CountListParameters.TheCountListWillBeGeneratedUsingTheParametersDisplayed' /></p>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </div>
    );
};

export default CountListParameters