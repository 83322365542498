import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLoading } from "../../Shared/LoadingContext";
import { useNotification } from '../../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../../Shared/Notifications/Notification';
import { useExceptionDialog } from '../../Shared/ExceptionDialog/ExceptionDialogProvider';
import ConfirmDialog from '../../Shared/ConfirmDialog';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row, Button, Input } from 'reactstrap';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { getFullNLS, saveNLSEntry, clearNLSEntry } from '../../../services/supportService';
import EditActionCell from "../../Administration/Warehouse Management/EditActionCell";
import { process } from "@progress/kendo-data-query";
import GridFooter from '../../Shared/GridFooter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import ReactTooltip from 'react-tooltip';

const editField = "inEdit";

const EditRights = (props, actions, disabled) => (
    <EditActionCell
        {...props}
        edit={actions.edit}
        remove={actions.remove}
        discard={actions.discard}
        add={actions.add}
        update={actions.update}
        cancel={actions.cancel}
        editField={editField}
        disabled={disabled}
        idPropName="key"
    />
);

export default function NLSGrid(props) {
    const intl = useIntl();
    const { setLoading } = useLoading();
    const userProfile = useSelector(state => state.profile.profile);
    const pageId = 'pageManageNLS';
    const [deleteConfVisibile, setDeleteConfVisibile] = React.useState(false);
    const [itemForDelete, setItemForDelete] = React.useState({});
    const [dataState, setDataState] = React.useState({});
    const dispatchNotification = useNotification();
    const [disable, setDisable] = React.useState(false);
    const [editingNew, setEditingNew] = React.useState(false);
    const dispatchExceptionDialog = useExceptionDialog();
    const [nlsEntries, setNlsEntries] = useState([]);
    const [filteredNLSEntries, setFilteredNLSEntries] = useState([]);
    const [filterKey, setFilterKey] = React.useState("");
    const [filterValue, setFilterValue] = React.useState("");
    const [gridWords, setGridWords] = useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [itemsPerPage, setItemsPerPage] = React.useState(10);
    const [isPagingVisible, setIsPagingVisible] = React.useState(false);

    useEffect(() => {
        if (props.language >= 0) {
            refreshNLS();
        }
    }, [props.language, userProfile.userLanguage]);

    useEffect(() => {
        applyFilter();
    }, [nlsEntries, filterKey, filterValue]);

    useEffect(() => {
        setIsPagingVisible(filteredNLSEntries.length > 5);
        setPageHandler(1);
    }, [filteredNLSEntries, itemsPerPage]);

    React.useEffect(() => {
        const filterKey = document.getElementsByName("filterNLSKey");
        if (filterKey.length > 0) {
            filterKey[0].disabled = disable;
        }

        const filterWord = document.getElementsByName("filterNLSValue");
        if (filterWord.length > 0) {
            filterWord[0].disabled = disable;
        }

        if (disable) {
            setTimeout(() => {
                let inputs = document.getElementsByClassName('k-textbox')
                if (inputs.length > 0) {
                    inputs[0].focus();
                }
            });
        }
    }, [disable]);

    const applyFilter = () => {
        const filteredArray = nlsEntries.filter(w =>
            w.key.toLowerCase().includes(filterKey.toLowerCase()) &&
            w.value.toLowerCase().includes(filterValue.toLowerCase()) ||
            w.isNew
        );

        setFilteredNLSEntries(filteredArray);
    }

    const refreshNLS = () => {
        getFullNLS(
            nls => {
                const nlsByLang = nls.find(n => n.language === props.language);
                const nlsWithTranslatedNLSKeys = nlsByLang?.entries?.map(n => ({ ...n, keyDisplayName: intl.formatMessage({ id: n.key }) })) ?? [];
                setNlsEntries(nlsWithTranslatedNLSKeys);
            },
            err => {
                setNlsEntries([]);
                handleError(err);
            });
    }

    //const handleAddButton = () => {
    //    const newDataItem = generateNewItem();
    //    setNlsEntries([newDataItem, ...nlsEntries]);
    //    setCurrentPage(1);
    //    setDisable(true);
    //    setEditingNew(true);
    //}

    //const generateNewItem = () => {
    //    return {
    //        key: '',
    //        keyDisplayName:'',
    //        value: '',
    //        inEdit: true,
    //        isNew: true
    //    };
    //}

    //const filterKeys = (key) => {
    //    setCurrentPage(1);
    //    setFilterKey(key);
    //}

    //const filterValues = (value) => {
    //    setCurrentPage(1);
    //    setFilterValue(value);
    //}

    const itemChange = (event) => {
        const newData = gridWords.map((item) =>
            item.key === event.dataItem.key
                ? {
                    ...item, [event.field || ""]: event.value,
                    changed: true
                }
                : item
        );
        setGridWords(newData);
    };

    const handleConfirmDialogClose = (result) => {
        if (result) {
            onConfirmDelete()
        }
        else {
            onCancelDelete()
        }
    }

    const onCancelDelete = () => {
        setDeleteConfVisibile(false);
    }

    const onConfirmDelete = () => {
        setDeleteConfVisibile(false);
        setLoading(true);
        removeNotification();
        clearNLSEntry(
            itemForDelete.key,
            () => {
                setLoading(false);
                refreshNLS();
            },
            handleError);
    };

    const handleError = (errorMessage, showNotif = true) => {
        setLoading(false);
        if (showNotif) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            });
        }
    };

    const removeNotification = () => {
        dispatchNotification({
            remove: true,
            pageId: pageId
        });
    };

    const remove = (dataItem) => {
        if (dataItem.importInProgress) {
            return false;
        }
        setDeleteConfVisibile(true)
        setItemForDelete(dataItem);
        setDisable(false);
    };

    const isDataItemValid = (dataItem) => {
        removeNotification();
        if (dataItem.key?.length > 150) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: intl.formatMessage({ id: "NLS.KeyTooLong" })
            })
            return false;
        }
        if (nlsEntries.filter(x => x.key.toLowerCase() === dataItem.key.toLowerCase()).length > 1) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: intl.formatMessage({ id: "NLS.KeyAlreadyExists" })
            })
            return false
        }
        return true;
    };

    const addOrUpdateItem = (dataItem) => {
        if (!isDataItemValid(dataItem)) {
            return;
        }
        removeNotification();
        setLoading(true);
        saveNLSEntry(
            {
                language: props.language,
                ...dataItem
            },
            () => {
                setDisable(false);
                setLoading(false);

                if (dataItem.isNew) {
                    dataItem.isNew = false;
                    dispatchNotification({
                        type: NotificationTypes.warning,
                        pageId: pageId,
                        message: intl.formatMessage({ id: "NLS.NewKeyAddedWarning" }, { "nlsKey": dataItem.key })
                    })
                }

                setGridWords(gridWords.map(item => ({ ...item, inEdit: false })));
            },
            handleError);
    }

    const add = (newItem) => {
        addOrUpdateItem(newItem);
    }

    const update = (dataItem) => {
        addOrUpdateItem(dataItem);
    };

    const discard = () => {
        const newData = [...nlsEntries];
        newData.splice(0, 1);
        setNlsEntries(newData);
        setDisable(false);
    };

    const cancel = (dataItem) => {
        let newData = [];

        if (dataItem?.isNew) {
            newData = gridWords.filter((item) => !item.isNew);
            setNlsEntries(newData);
        }
        else {
            const originalItem = nlsEntries.find(
                (p) => p.key === dataItem.key
            );
            newData = gridWords.map((item) =>
                item.key === originalItem.key ? originalItem : item
            );
            dataItem.inEdit = false;
            setGridWords(newData);
        }

        setDisable(false);
    };

    const enterEdit = (dataItem) => {
        dataItem.changed = false;
        setDisable(true);
        setEditingNew(false);
        setGridWords(
            gridWords.map((item) =>
                item.key === dataItem.key ? { ...item, inEdit: true } : item
            )
        );
    };

    const setPageHandler = (page) => {
        if (filteredNLSEntries && page > 0 && (page <= Math.floor((filteredNLSEntries.length - 1) / itemsPerPage) + 1 || filteredNLSEntries.length == 0)) {
            let startIndex = (page - 1) * itemsPerPage;
            let endIndex = (page) * itemsPerPage;
            setGridWords(filteredNLSEntries.slice(startIndex, endIndex));
            if (nlsEntries.length > itemsPerPage) {
                setCurrentPage(page);
            }
            else {
                setCurrentPage(1);
            }
        }
    }

    const handleItemsPerPageChange = (dropDownItem) => {
        setItemsPerPage(dropDownItem.value);
    };

    return (
        <div className="content">
            <ConfirmDialog
                visible={deleteConfVisibile}
                onClose={handleConfirmDialogClose}
                negative={<FormattedMessage id='Control.Cancel' />}
                positive={<FormattedMessage id='Control.Confirm' />}
                title={<FormattedMessage id='Confirm.DeletionTitle' />}
                detail={intl.formatMessage({ id: "NLS.ConfirmDelete" }, { 'nlsKey': itemForDelete.keyDisplayName })}
            />

            {/*<Row className="filter-row">*/}
            {/*    <Col xs="12">*/}
            {/*        <table width="100%">*/}
            {/*            <tbody>*/}
            {/*                <tr>*/}
            {/*                    <td width="320px">*/}
            {/*                        <Input className="grid-filter" name="filterNLSKey" placeholder={intl.formatMessage({ id: "NLS.KeyFilter" })} onChange={(event) => {*/}
            {/*                            filterKeys(event.target.value);*/}
            {/*                        }} />*/}
            {/*                    </td>*/}
            {/*                    <td>*/}
            {/*                        <Input className="grid-filter" name="filterNLSValue" placeholder={intl.formatMessage({ id: "NLS.ValueFilter" })} onChange={(event) => {*/}
            {/*                            filterValues(event.target.value);*/}
            {/*                        }} />*/}
            {/*                    </td>*/}
            {/*                    <td width="120px">*/}
            {/*                        <div className="float-right">*/}
            {/*                            <ReactTooltip id="nlsAddTip" place="top" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />*/}
            {/*                            <Button color="primary" disabled={disable} onClick={handleAddButton} data-tip={intl.formatMessage({ id: 'NLS.AddNewEntry' })} data-for="nlsAddTip">*/}
            {/*                                <FontAwesomeIcon icon={faPlus} />*/}
            {/*                            </Button>*/}
            {/*                        </div>*/}
            {/*                    </td>*/}
            {/*                </tr>*/}
            {/*            </tbody>*/}
            {/*        </table>*/}
            {/*    </Col>*/}
            {/*</Row>*/}

            <Row>
                <Col xs="12">
                    <Grid data={process(gridWords, dataState)}
                        {...dataState}
                        onDataStateChange={(e) => {
                            setDataState(e.dataState);
                        }}
                        onItemChange={itemChange}
                        editField={editField}
                        key="key">
                        <GridNoRecords>
                            {<FormattedMessage id="BlacklistCompany.NoBLWord" />}
                        </GridNoRecords>
                        <Column field="keyDisplayName" title={intl.formatMessage({ id: "NLS.Key" })} width={320} editable={editingNew} />
                        <Column field="value" title={intl.formatMessage({ id: "NLS.Value" })} />
                        <Column cell={(props) => EditRights(props, { edit: enterEdit, remove: remove, discard: discard, add: add, update: update, cancel:cancel }, disable)} filterable={false} width={120} />
                    </Grid>
                </Col>
            </Row>
            {isPagingVisible && (
                <GridFooter
                    itemsPerPage={itemsPerPage}
                    handleItemsPerPageChange={handleItemsPerPageChange}
                    dataLength={filteredNLSEntries.length}
                    setPageHandler={setPageHandler}
                    currentPage={currentPage}
                />
            )}

        </div>
    );
}