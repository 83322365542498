import React, { useState, useEffect, useContext } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Container,
    NavItem,
    NavLink
} from 'reactstrap';
import ContactSupport, { SupportType } from './Shared/ContactSupport';
import { useSelector } from 'react-redux';
import { signoutRedirect } from '../services/userService';
import FlagDE from '../assets/images/flag-de.png';
import FlagEN from '../assets/images/flag-uk.png';
import FlagIT from '../assets/images/flag-it.png';
import { FormattedMessage, useIntl } from 'react-intl';
import { Context } from "./Wrapper";
import { getUserProfile, setUserTheme, setUserLanguage } from '../services/profileManagementService';
import store from '../store';
import { storeProfile } from '../actions/profileActions';
import { useLoading } from "./Shared/LoadingContext";
import { useNotification } from './Shared/Notifications/NotificationProvider';
import { NotificationTypes } from './Shared/Notifications/Notification';
import { useExceptionDialog } from './Shared/ExceptionDialog/ExceptionDialogProvider';
import { trigger } from '../actions/events';
import { AuthContext } from '../utils/authProvider';
import { RefreshUserContext, UserContext } from './UserContext';
import License from './License/License';
import ChangePassword from './Administration/User Management/ChangePassword';
import { load, loadMessages } from '@progress/kendo-react-intl';

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import deNumbers from "cldr-numbers-full/main/de/numbers.json";
import deCaGregorian from "cldr-dates-full/main/de/ca-gregorian.json";
import deDateFields from "cldr-dates-full/main/de/dateFields.json";
import deTimeZoneNames from "cldr-dates-full/main/de/timeZoneNames.json";
import itNumbers from "cldr-numbers-full/main/it/numbers.json";
import itCaGregorian from "cldr-dates-full/main/it/ca-gregorian.json";
import itDateFields from "cldr-dates-full/main/it/dateFields.json";
import itTimeZoneNames from "cldr-dates-full/main/it/timeZoneNames.json";
import deMessages from "../language/kendo.de-DE.json";
import itMessages from "../language/kendo.it-IT.json";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSun,
    faMoon,
    faUser,
    faSignOutAlt,
    faPencilAlt,
    faLifeRing
} from '@fortawesome/pro-light-svg-icons';
import { SessionKeyIsAutomaticLogout } from '../services/serviceBase';

load(
    likelySubtags,
    currencyData,
    weekData,
    deNumbers,
    deCaGregorian,
    deDateFields,
    deTimeZoneNames,
    itNumbers,
    itCaGregorian,
    itDateFields,
    itTimeZoneNames
);
loadMessages(deMessages, "de-DE");
loadMessages(itMessages, "it-IT");

export const NavMenuEvents = {
    UserLanguageChanged: "NavMenu:UserLanguageChanged"
}

export const leaveTimeKey = 'leaveTime';
export const leaveEmailKey = 'leaveEmail';
export const showYouForgotNottificationKey = 'showYouForgotNottification';

const NavMenu = () => {
    const { setLoading } = useLoading();
    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const pageId = 'NavMenu';
    const intl = useIntl()
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const user = useSelector(state => state.auth.user);
    const userProfile = useSelector(state => state.profile.profile);
    const userManager = React.useContext(AuthContext);
    const currentCustomer = useSelector(state => state.dashboard.customer);
    const userContext = React.useContext(UserContext);

    const profilename = user?.profile?.name ?? 'empty';

    function signOut() {
        signoutRedirect(userManager);
    }

    const [currentUserName, setCurrentUserName] = useState(null);

    const [userRole, setUserRole] = useState('Role.unknown')

    const getUserRole = () => {
        if (userContext.userGlobal.userInfo.isStatcontrolAdmin)
            setUserRole('Role.statcontroladmin');
        else if (userContext.userGlobal.userInfo.isAdminUser)
            setUserRole('Role.admin');
        else if (userContext.userGlobal.userInfo.isEditor)
            setUserRole("Role.editor");
        else if (userContext.userGlobal.userInfo.isController)
            setUserRole("Role.controller");
        else if (userContext.userGlobal.userInfo.isOperator)
            setUserRole('Role.operator')
    }

    useEffect(() => {
        if (user && userContext.userGlobal.userInfo.userName && userContext.userGlobal.userInfo.userName !== currentUserName) {
            setCurrentUserName(userContext.userGlobal.userInfo.userName);
            getProfile(user.profile.sub);
        }

        if (user && !userContext.userGlobal.userInfo.userName) {
            refreshUserContextForMenu();
        }

        getUserRole();
    }, [userContext.userGlobal.userInfo.userName, user]);

    const displayForgetToLogoutNotification = () => {
        const now = new Date().getTime();
        let leaveTime = parseInt(localStorage.getItem(leaveTimeKey), 10);
        const leaveEmail = localStorage.getItem(leaveEmailKey);
        let showYouForgotNottification = parseInt(localStorage.getItem(showYouForgotNottificationKey), 10);

        if (Number.isNaN(leaveTime))
            leaveTime = 0;

        if (Number.isNaN(showYouForgotNottification))
            showYouForgotNottification = 0;

        const refreshTime = 5000;
        const logoutTime = 300000;

        const showNotification = (now - leaveTime > refreshTime) && (now - leaveTime < logoutTime) || showYouForgotNottification == 1;

        if (showNotification && user?.profile?.email == leaveEmail) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: intl.formatMessage({ id: "General.YouFogotToSignOutOnUYourLastSession" })
            });
        }

        localStorage.removeItem(leaveTimeKey);
        localStorage.removeItem(leaveEmailKey);
        localStorage.removeItem(showYouForgotNottificationKey);

        if (leaveTime != 0 && now - leaveTime > logoutTime) {
            if (user) {
                sessionStorage.setItem(SessionKeyIsAutomaticLogout, 'true');

                signoutRedirect(userManager);
            }

            localStorage.setItem(showYouForgotNottificationKey, 1);
            localStorage.setItem(leaveEmailKey, user?.profile?.email ?? "");
        }
    }

    useEffect(() => {
        dispatchNotification({
            removeAll: true
        });

        window.addEventListener("beforeunload", event => beforeunloadHandler(event));
        displayForgetToLogoutNotification();

        return () => {
            window.removeEventListener('beforeunload', event => beforeunloadHandler(event));
        };
    }, []);

    const beforeunloadHandler = (ev) => {
        //ev.preventDefault();
        localStorage.setItem(leaveTimeKey, new Date().getTime());
        if (user) {
            localStorage.setItem(leaveEmailKey, user.profile ? user.profile.email : "");
        }
        else {
            localStorage.setItem(leaveEmailKey, "");
        }
    }


    const context = useContext(Context);

    const selectLanguageByUserLanguage = userLanguage => {
        switch (userLanguage) {
            case window.enumLanguage['English']:
                context.selectLanguage('en');
                break;
            case window.enumLanguage['German']:
                context.selectLanguage('de');
                break;
            case window.enumLanguage['Italian']:
                context.selectLanguage('it');
                break;
            default:
                context.selectLanguage('en');
        }
    }

    const getProfile = (userSubject) => {
        setLoading(true);

        getUserProfile(
            userSubject,
            result => {
                setLoading(false);
                if (result) {

                    if (isDark && result.userTheme !== window.enumTheme['Dark']) {
                        setDark(false);
                        setTheme(false);
                    }

                    selectLanguageByUserLanguage(result.userLanguage);

                    const profile = {
                        ...userProfile,
                        userLanguage: result.userLanguage
                    };
                    store.dispatch(storeProfile(profile));
                }
                else {
                    dispatchNotification({
                        type: NotificationTypes.error,
                        message: intl.formatMessage({ id: 'General.FailedToGetProfiler' })
                    })
                }
            },
            handleError);
    }

    const handleError = (errorMessage, showNotif = true) => {
        setLoading(false);
        if (showNotif) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            })
        }
    };

    const refreshUserContextForMenu = RefreshUserContext(handleError);

    const [isDark, setDark] = useState(true);

    const ToggleClass = () => {
        let newTheme = !isDark;
        setDark(newTheme);
        setTheme(newTheme);
        setProfileTheme(newTheme);
    };

    const setProfileTheme = (isDarkTheme) => {
        setLoading(true);
        let currentTheme = isDarkTheme ? window.enumTheme['Dark'] : window.enumTheme['Light'];
        setUserTheme(
            user.profile.sub,
            currentTheme,
            result => {
                setLoading(false);
                const profile = {
                    ...userProfile,
                    userTheme: currentTheme
                };
                store.dispatch(storeProfile(profile));
            },
            handleError);
    }

    const setTheme = (dark) => {
        let theme = document.getElementById("theme-wrapper");
        let isLight = theme.classList.contains('light-mode');
        if ((dark && isLight) || (!dark && !isLight)) {
            theme.classList.toggle('light-mode');
        }
    }

    const isLicenseButtonVisible = userContext.userGlobal.userInfo?.isStatcontrolAdmin || userContext.userGlobal.userInfo?.isAdminUser;
    const [isLicenseVisible, setIsLicenseVisible] = React.useState(false);

    const openLicense = () => {
        setIsLicenseVisible(true);
    };

    const handleLicenseClose = () => {
        setIsLicenseVisible(false);
    };

    const [isChangePasswordVisible, setIsChangePasswordVisible] = React.useState(false);

    const openChangePassword = () => {
        setIsChangePasswordVisible(true);
    };

    const handlePasswordChangeClose = () => {
        setIsChangePasswordVisible(false);
    };

    const setLanguage = (e) => {
        const newLocale = e;
        context.selectLanguage(newLocale);
        switch (newLocale) {
            case 'en':
                setUserLanguageHandler(window.enumLanguage['English']);
                break;
            case 'de':
                setUserLanguageHandler(window.enumLanguage['German']);
                break;
            case 'it':
                setUserLanguageHandler(window.enumLanguage['Italian']);
                break;
            default:
                setUserLanguageHandler(window.enumLanguage['English']);
        }
    };

    const setUserLanguageHandler = (language) => {
        setLoading(true);
        setUserLanguage(
            user.profile.sub,
            language,
            _result => {
                setLoading(false);
                const profile = {
                    ...userProfile,
                    userLanguage: language
                };
                store.dispatch(storeProfile(profile));
                trigger(NavMenuEvents.UserLanguageChanged, language);
            },
            handleError);
    }

    const getActiveMenuClass = (lng) => {
        let lngItem = "";
        switch (lng) {
            case 'en':
                lngItem = window.enumLanguage['English'];
                break;
            case 'de':
                lngItem = window.enumLanguage['German'];
                break;
            case 'it':
                lngItem = window.enumLanguage['Italian'];
                break;
            default:
                lngItem = window.enumLanguage['English'];
        }
        return lngItem === userProfile.userLanguage ? "active-menu" : "";
    }

    return (

        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm border-top box-shadow mb-2">
                <Container fluid>
                    <NavbarToggler onClick={toggle} className="mr-2" />

                    <Collapse isOpen={isOpen} className="d-sm-inline-flex flex-sm-row-reverse" navbar>

                        <ul className="navbar-nav flex-grow">

                            {isLicenseButtonVisible && (
                                <NavItem>
                                    <NavLink href="#" onClick={openLicense} className="btn btn-link">
                                        <label className="label label-primary">
                                            {currentCustomer && currentCustomer.activeLicense ? currentCustomer.activeLicense.subscriptionTypeName : ""}
                                        </label>
                                    </NavLink>
                                    {isLicenseVisible && (
                                        <License
                                            onClose={handleLicenseClose}
                                        />
                                    )}
                                </NavItem>
                            )}

                            {isChangePasswordVisible && (
                                <ChangePassword onClose={handlePasswordChangeClose} />
                            )}
                            <NavItem>
                                <ContactSupport supportType={SupportType.Default} />
                            </NavItem>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>
                                    <FontAwesomeIcon icon={faUser} />&nbsp; <FormattedMessage id="Hello" values={{ name: profilename }} /> <b className="caret"></b>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem header>
                                        <FormattedMessage id='EditRights.UserRole' />
                                    </DropdownItem>
                                    <DropdownItem className="dropdown-text">
                                        <FormattedMessage id={userRole} />
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={ToggleClass}>
                                        <FontAwesomeIcon icon={isDark ? faSun : faMoon} />
                                        <span>Theme </span>
                                    </DropdownItem>
                                    <DropdownItem onClick={() => setLanguage('de')} value='de' className={intl.locale + " " + getActiveMenuClass('de')} >
                                        <img src={FlagDE} alt="flag-de" />Deutsch
                                    </DropdownItem>
                                    <DropdownItem onClick={() => setLanguage('en')} value='en' className={intl.locale + " " + getActiveMenuClass('en')} >
                                        <img src={FlagEN} alt="flag-en" />English
                                    </DropdownItem>
                                    <DropdownItem onClick={() => setLanguage('it')} value='it' className={intl.locale + " " + getActiveMenuClass('it')}>
                                        <img src={FlagIT} alt="flag-it" />Italiano
                                    </DropdownItem>
                                    {/*<DropdownItem tag={Link} to="/help">*/}
                                    {/*    <FontAwesomeIcon icon={faLifeRing} /> <FormattedMessage id='NavMenu.Help' />*/}
                                    {/*</DropdownItem>*/}
                                    <DropdownItem onClick={openChangePassword}>
                                        <FontAwesomeIcon icon={faPencilAlt} /><FormattedMessage id='NavMenu.ChangePassword' />
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={() => signOut()}>
                                        <FontAwesomeIcon icon={faSignOutAlt} /><FormattedMessage id='NavMenu.SignOut' />
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </ul>
                    </Collapse>
                </Container>
            </Navbar>

        </header>
    );
}

export default NavMenu;