import React, { useState } from 'react';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import { Label, Error } from "@progress/kendo-react-labels";
import { Col, Row, Button } from 'reactstrap';
import { FormattedMessage, useIntl } from "react-intl";

const EditDeviceDialog = (props) => {
    const intl = useIntl();
    const title = intl.formatMessage({ id: "EditDeviceDialog.Title" }, { "DeviceName": props.device.name });

    const [deviceName, setDeviceName] = useState(props.device.name);
    const handleDeviceNameChange = (event) => {
        setDeviceName(event.value);
    };

    const deviceNameValidator = (value) => {
        if (props.deviceNameValidator) {
            return props.deviceNameValidator(value, props.device);
        }
        return '';
    };

    const isSaveDisabled = deviceNameValidator(deviceName) !== '' || deviceName === props.device.name;

    const handleClose = () => {
        props.onClose();
    };

    const handleSave = () => {
        props.saveDeviceName(props.device.name, deviceName);
        props.onClose();
    };

    return (
        <div>

            <Dialog
                title={title}
                onClose={handleClose}
            >                

                <Row>
                    <Col xs="12">
                        <div className="mb-3">
                            <Label>{intl.formatMessage({ id: 'EditDeviceDialog.DeviceName' }) + " *"}</Label>
                            <Input
                                id={"deviceName"}
                                name={"deviceName"}
                                value={deviceName}
                                maxLength={255}
                                onChange={handleDeviceNameChange}
                            />
                            <Error>{deviceNameValidator(deviceName)}</Error>
                        </div>
                    </Col>
                </Row>

                <DialogActionsBar>
                    <div className="k-form-buttons k-justify-content-stretch">
                        <Button
                            type={"button"}
                            color="secondary"
                            onClick={handleClose}
                        >
                            <FormattedMessage id='Control.Close' />
                        </Button>

                        <Button
                            type={"button"}
                            color="primary"
                            onClick={handleSave}
                            disabled={isSaveDisabled}
                        >
                            <FormattedMessage id='Control.Save' />
                        </Button>
                    </div>
                </DialogActionsBar>
            </Dialog>
        </div>
    );
};

export default EditDeviceDialog;
