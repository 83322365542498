import React from 'react';
import { useIntl } from 'react-intl';
import { Card, CardBody } from 'reactstrap';
import SequentialTestLog from './SequentialTestLog';
import SequentialTestResults from './SequentialTestResults';

const ReportingStatisticsSequential = (props) => {
    const intl = useIntl();

    return (
        <div>
            <SequentialTestResults statistics={props.statistics} pureStaseqReporting={props.pureStaseqReporting}/>
            {props.pureStaseqReporting?.statusOfSequentialTest?.hintKey &&
                <Card className={props.getStatusClass()} >
                    <CardBody>
                        {intl.formatMessage({ id: props.pureStaseqReporting.statusOfSequentialTest.hintKey }) }
                    </CardBody>
                </Card>
            }
            <SequentialTestLog statistics={props.statistics} pureStaseqReporting={props.pureStaseqReporting}/>
        </div>
    );
}

export default ReportingStatisticsSequential
