import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormDropDownList, FormNumericTextBox, FormInput, FormDatePicker } from "../../Inventory/Wizard/form-components";
import { Input } from "@progress/kendo-react-inputs";
import { Col, Row, Button } from 'reactstrap';
import { FormattedMessage, useIntl } from "react-intl";
import ReactTooltip from 'react-tooltip';
import validator from 'validator';
import { useNotification } from "../../Shared/Notifications/NotificationProvider";
import { NotificationTypes } from "../../Shared/Notifications/Notification";
import ConfirmDialog from '../../Shared/ConfirmDialog';
import { getLocale, formatNumber } from '../../../utils/localization';
import { IndividualLicenseEditorState } from './ManageIndividualLicense';
import { getCustomerIndividualLicenseStatus } from '../../../services/licenseManagementService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons'


const EditIndividualForm = (props) => {
    const intl = useIntl();
    const userProfile = useSelector(state => state.profile.profile);
    const locale = getLocale(userProfile.userLanguage);

    const subscriptionTypeNameValidator = (value) => value ? "" : intl.formatMessage({ id: 'ManageLicense.IndividualSubscriptionNameRequired' });
    const pricePerMonthPaymentPerYearValidator = (value) => value != null ? "" : intl.formatMessage({ id: 'ManageLicense.PricePerMonthPaymentPerYearRequired' });
    const numberOfPositionsValidator = (value) => {
        if (value == null) {
            return intl.formatMessage({ id: 'ManageLicense.NumberOfPositionsRequired' });
        }
        if (value < minNumberOfPositions) {
            return intl.formatMessage({ id: 'ManageLicense.ValueMustGreaterThanOrEqualTo' }, { Value: formatNumber(minNumberOfPositions, locale) });
        }
        return '';
    };
    const numberOfPopulationsValidator = (value) => {
        if (value == null) {
            return intl.formatMessage({ id: 'ManageLicense.NumberOfPopulationsRequired' });
        }
        if (value < minNumberOfPopulations) {
            return intl.formatMessage({ id: 'ManageLicense.ValueMustGreaterThanOrEqualTo' }, { Value: formatNumber(minNumberOfPopulations, locale) });
        }
        return '';
    };
    const supportValidator = (value) => value ? "" : intl.formatMessage({ id: 'ManageLicense.SupportRequired' });
    const procedureValidator = (value) => value ? "" : intl.formatMessage({ id: 'ManageLicense.ProcedureRequired' });
    const minimumTermValidator = (value) => value != null ? "" : intl.formatMessage({ id: 'ManageLicense.MinimumTermRequired' });
    const settlementAdditionalPositionPriceValidator = (value) => value != null ? "" : intl.formatMessage({ id: 'ManageLicense.SettlementAdditionalPositionPriceRequired' });
    const licenseStartDateValidator = (value) => value ? "" : intl.formatMessage({ id: 'ManageLicense.LicenseStartDateRequired' });
    const licenseEndDateValidator = (value) => value ? "" : intl.formatMessage({ id: 'ManageLicense.LicenseEndDateRequired' });
    const customerValidator = (value) => value ? "" : intl.formatMessage({ id: 'ManageLicense.CustomerRequired' });
    const gtcAcceptedOnValidator = (value) => {
        if (props.editorState === IndividualLicenseEditorState.Edit) {
            return value ? "" : intl.formatMessage({ id: 'ManageLicense.GTCAcceptedOnRequired' });
        }
        return "";
    };
    const confirmationEmailAddressValidator = (value) => {
        if (!value) {
            return intl.formatMessage({ id: 'ManageLicense.ConfirmationEmailAddressRequired' });
        }
        return validator.isEmail(value) ? "" : intl.formatMessage({ id: 'ManageLicense.ConfirmationEmailAddressInvalid' });
    };

    const dispatchNotification = useNotification();
    const pageId = 'IndividualLicenseEditForm';
    const maximumValue = 2147483647;

    const isSaveDisabled = (formRenderProps) => {
        return !formRenderProps.allowSubmit || !formRenderProps.valid || props.isSaveDisabled;
    };

    const addMonths = (numOfMonths, date = new Date()) => {
        const dateCopy = new Date(date.getTime());

        dateCopy.setMonth(dateCopy.getMonth() + numOfMonths);

        return dateCopy;
    }

    const isServerDataValid = (dataItem) => {
        const areMissingValues =
            dataItem.pricePerMonthPaymentPerYear == null
            || dataItem.settlementAdditionalPositionPrice == null
            || dataItem.numberOfPositions == null
            || dataItem.numberOfPopulations == null
            || dataItem.supportObj == null
            || dataItem.procedureObj == null
            || dataItem.minimumTerm == null;

        if (areMissingValues) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: <FormattedMessage id="ManageLicense.AllValuesRequired" />
            });
            return false;
        }

        const areNegativeValues =
            dataItem.pricePerMonthPaymentPerYear < 0
            || dataItem.pricePerMonthPaymentPerYear > maximumValue
            || dataItem.numberOfPositions < 0
            || dataItem.numberOfPositions > maximumValue
            || dataItem.numberOfPopulations < 0
            || dataItem.numberOfPopulations > maximumValue
            || dataItem.minimumTerm < 0
            || dataItem.minimumTerm > maximumValue;

        if (areNegativeValues) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: <FormattedMessage
                    id="ManageLicense.AllValuesMustBeInRange"
                    values={{
                        Min: 0,
                        Max: maximumValue
                    }}
                />
            });
            return false;
        }

        const isSettlementAdditionalPositionPriceOutOfRange =
            dataItem.settlementAdditionalPositionPrice < 0
            || dataItem.settlementAdditionalPositionPrice > 100;

        if (isSettlementAdditionalPositionPriceOutOfRange) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: <FormattedMessage id="ManageLicense.SettlementAdditionalPositionPriceOutOfRange" />
            });
            return false;
        }

        return true;
    };

    const handleSubmit = (dataItem) => {
        if (isServerDataValid(dataItem)) {
            props.onSubmit(dataItem);
        }
    };

    const handleCancel = () => {
        if (areChanges) {
            setConfirmDialogTitle(<FormattedMessage id='Confirm.UnsavedChangesTitle' />);
            setConfirmDialogDetail(<FormattedMessage id='Confirm.UnsavedChangesText' />);
            setConfirmDialogCallback({
                success: () => {
                    props.cancelEdit();
                }
            });
            setIsConfirmDialogVisible(true);
        }
        else {
            props.cancelEdit();
        }
    };

    const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);
    const [confirmDialogTitle, setConfirmDialogTitle] = useState('');
    const [confirmDialogDetail, setConfirmDialogDetail] = useState('');
    const [confirmDialogCallback, setConfirmDialogCallback] = useState(null);

    const [minimumTerm, setMinimumTerm] = React.useState(null);
    const [licienseStartDate, setLicienseStartDate] = useState(null);

    React.useEffect(() => {
        if (props.item) {
            setMinimumTerm(props.item.minimumTerm);
            setLicienseStartDate(props.item.licienseStartDate);
        }
    }, [props.item]);

    const handleConfirmDialogClose = (isConfirmed) => {
        setIsConfirmDialogVisible(false);
        if (isConfirmed) {
            if (confirmDialogCallback) {
                confirmDialogCallback.success();
            }
        }
        else {
            if (confirmDialogCallback && confirmDialogCallback.cancel) {
                confirmDialogCallback.cancel();
            }
        }

        dispatchNotification({
            removeAll: true
        });
    };

    const [isSubscriptionTypeNameChanged, setIsSubscriptionTypeNameChanged] = useState(false);
    const handleSubscriptionTypeNameChange = (event) => {
        const isChanged = event.value !== props.item.subscriptionTypeName;
        setIsSubscriptionTypeNameChanged(isChanged);
    };

    const [isPricePerMonthPaymentPerYearChanged, setIsPricePerMonthPaymentPerYearChanged] = useState(false);
    const handlePricePerMonthPaymentPerYearChange = (event) => {
        const isChanged = event.value !== props.item.pricePerMonthPaymentPerYear;
        setIsPricePerMonthPaymentPerYearChanged(isChanged);
    };

    const [isNumberOfPositionsChanged, setIsNumberOfPositionsChanged] = useState(false);
    const handleNumberOfPositionsChange = (event) => {
        const isChanged = event.value !== props.item.numberOfPositions;
        setIsNumberOfPositionsChanged(isChanged);
    };

    const [isNumberOfPopulationsChanged, setIsNumberOfPopulationsChanged] = useState(false);
    const handleNumberOfPopulationsChange = (event) => {
        const isChanged = event.value !== props.item.numberOfPopulations;
        setIsNumberOfPopulationsChanged(isChanged);
    };

    const [isMinimumTermChanged, setIsMinimumTermChanged] = useState(false);
    const handleMinimumTermChange = (event, formRenderProps) => {

        if (licienseStartDate && event.value) {
            formRenderProps.onChange("licenseEndDate", {
                value: addMonths(event.value, new Date(licienseStartDate))
            });
        }
        setMinimumTerm(event.value);

        const isChanged = event.value !== props.item.minimumTerm;
        setIsMinimumTermChanged(isChanged);
    };

    const [isSupportChanged, setIsSupportChanged] = useState(false);
    const handleSupportChange = (event) => {
        const isChanged = event.value.value !== props.item.supportId;
        setIsSupportChanged(isChanged);
    };

    const [isProcedureChanged, setIsProcedureChanged] = useState(false);
    const handleProcedureChange = (event) => {
        const isChanged = event.value.value !== props.item.procedureId;
        setIsProcedureChanged(isChanged);
    };

    const [isSettlementAdditionalPositionPriceChanged, setIsSettlementAdditionalPositionPriceChanged] = useState(false);
    const handleSettlementAdditionalPositionPriceChange = (event) => {
        const isChanged = event.value !== props.item.settlementAdditionalPositionPrice;
        setIsSettlementAdditionalPositionPriceChanged(isChanged);
    };

    const [isLicenseStartDateChanged, setIsLicenseStartDateChanged] = useState(false);
    const handleLicenseStartDateChange = (event, formRenderProps) => {

        if (minimumTerm && event.value) {
            formRenderProps.onChange("licenseEndDate", {
                value: addMonths(minimumTerm, new Date(event.value))
            });
        }

        setLicienseStartDate(event.value);

        const isChanged = event.value !== props.item.licenseStartDate;
        setIsLicenseStartDateChanged(isChanged);
    };

    const [isLicenseEndDateChanged, setIsLicenseEndDateChanged] = useState(false);
    const handleLicenseEndDateChange = (event) => {
        const isChanged = event.value !== props.item.licenseEndDate;
        setIsLicenseEndDateChanged(isChanged);
    };

    const [isGTCAcceptedOnChanged, setIsGTCAcceptedOnChanged] = useState(false);
    const handleGTCAcceptedOnChange = (event) => {
        const isChanged = event.value !== props.item.gtcAcceptedOn;
        setIsGTCAcceptedOnChanged(isChanged);
    };

    const [isCustomerChanged, setIsCustomerChanged] = useState(false);
    const handleCustomerChange = (event) => {
        const isChanged = event.value.customerId !== props.item.customerId;
        setIsCustomerChanged(isChanged);

        checkCustomerIndividualLicense(event.value.customerId);
    };

    const [isConfirmationEmailAddressChanged, setIsConfirmationEmailAddressChanged] = useState(false);
    const handleConfirmationEmailAddressChange = (event) => {
        const isChanged = event.value !== props.item.confirmationEmailAddress;
        setIsConfirmationEmailAddressChanged(isChanged);
    };

    const areChanges = isSubscriptionTypeNameChanged
        || isPricePerMonthPaymentPerYearChanged
        || isNumberOfPositionsChanged
        || isNumberOfPopulationsChanged
        || isMinimumTermChanged
        || isSupportChanged
        || isProcedureChanged
        || isSettlementAdditionalPositionPriceChanged
        || isLicenseStartDateChanged
        || isLicenseEndDateChanged
        || isGTCAcceptedOnChanged
        || isCustomerChanged
        || isConfirmationEmailAddressChanged;

    const title = props.editorState === IndividualLicenseEditorState.Add
        ? intl.formatMessage({ id: "ManageLicense.AddIndividualSubscription" })
        : intl.formatMessage({ id: "ManageLicense.EditIndividualSubscription" }, { "Name": props.item.subscriptionTypeName });

    const isSavingEnabled = props.editorState === IndividualLicenseEditorState.Add || props.editorState === IndividualLicenseEditorState.Edit;

    const [hasCustomerPendingLicense, setHasCustomerPendingLicense] = useState(false);
    const [hasCustomerActiveLicense, setHasCustomerActiveLicense] = useState(false);
    const [minNumberOfPopulations, setMinNumberOfPopulations] = useState(1);
    const [minNumberOfPositions, setMinNumberOfPositions] = useState(1);
    const checkCustomerIndividualLicense = (customerId) => {
        props.removeNotification();
        props.incrementLoadingCount();

        setHasCustomerPendingLicense(false);
        setHasCustomerActiveLicense(false);
        setMinNumberOfPopulations(1);
        setMinNumberOfPositions(1);

        getCustomerIndividualLicenseStatus(
            customerId,
            status => {
                props.decrementLoadingCount();
                setHasCustomerPendingLicense(status.hasPendingLicense);
                setHasCustomerActiveLicense(status.hasActiveLicense);
                setMinNumberOfPopulations(status.activeLicenseNumberOfPopulations);
                setMinNumberOfPositions(status.activeLicenseNumberOfPositions);
            },
            props.handleError);
    };

    const showCustomerWarning = hasCustomerPendingLicense || hasCustomerActiveLicense;

    const getCustomerWarningTooltip = () => {
        if (hasCustomerPendingLicense) {
            return intl.formatMessage({ id: 'ManageLicense.CustomerWithPendingIndividualSubscription' });
        }
        if (hasCustomerActiveLicense) {
            return intl.formatMessage({ id: 'ManageLicense.CustomerWithActiveIndividualSubscription' });
        }
        return '';
    };

    return (
        <div>
            <Prompt
                when={areChanges}
                message={JSON.stringify({
                    header: "" + intl.formatMessage({ id: 'Confirm.UnsavedChangesTitle' }),
                    content: "" + intl.formatMessage({ id: 'Confirm.UnsavedChangesText' }),
                    cancel: "" + intl.formatMessage({ id: 'Control.Cancel' }),
                    confirm: "" + intl.formatMessage({ id: 'Control.Confirm' })
                })}
            />

            <Dialog
                title={title}
                onClose={handleCancel}
                className="indi-subscription"
            >
                <Form
                    onSubmit={handleSubmit}
                    initialValues={props.item}
                    render={(formRenderProps) => (
                        <FormElement>
                            <fieldset className={"k-form-fieldset"}>
                                <Row>
                                    <Col xs="12" lg="4">
                                        <div className="mb-3">
                                            <Field
                                                id={"customerObj"}
                                                name={"customerObj"}
                                                label={intl.formatMessage({ id: 'ManageLicense.Customer' }) + " *"}
                                                component={FormDropDownList}
                                                textField={"customerName"}
                                                dataItemKey={"customerId"}
                                                data={props.customers}
                                                validator={customerValidator}
                                                onChange={handleCustomerChange}
                                                disabled={props.editorState !== IndividualLicenseEditorState.Add}
                                                labelAddition={showCustomerWarning && (
                                                    <>
                                                        <ReactTooltip id="warningTooltip" place="right" type="error" backgroundColor='#41b6e6' effect="solid" />
                                                        <FontAwesomeIcon icon={faExclamationTriangle} className="warning-color" data-for="warningTooltip" data-tip={getCustomerWarningTooltip()} />
                                                    </>
                                                )}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Field
                                                name={"subscriptionTypeName"}
                                                component={FormInput}
                                                validator={subscriptionTypeNameValidator}
                                                maxLength={256}
                                                label={intl.formatMessage({ id: 'ManageLicense.IndividualSubscriptionName' }) + " *"}
                                                onChange={handleSubscriptionTypeNameChange}
                                                disabled={props.editorState !== IndividualLicenseEditorState.Add}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Field
                                                id={"supportObj"}
                                                name={"supportObj"}
                                                label={intl.formatMessage({ id: 'ManageLicense.Support' }) + " *"}
                                                component={FormDropDownList}
                                                textField={"text"}
                                                dataItemKey={"value"}
                                                data={props.supports}
                                                validator={supportValidator}
                                                onChange={handleSupportChange}
                                                disabled={props.editorState !== IndividualLicenseEditorState.Add}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Field
                                                id={"licenseStartDate"}
                                                name={"licenseStartDate"}
                                                label={intl.formatMessage({ id: 'ManageLicense.StartDate' }) + " *"}
                                                component={FormDatePicker}
                                                validator={licenseStartDateValidator}
                                                onChange={(e) => {
                                                    handleLicenseStartDateChange(e, formRenderProps);
                                                }}
                                                disabled={props.editorState !== IndividualLicenseEditorState.Add}
                                                locale={locale}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Field
                                                id={"gtcAcceptedOn"}
                                                name={"gtcAcceptedOn"}
                                                label={intl.formatMessage({ id: 'ManageLicense.GtcAcceptedOn' })}
                                                component={FormDatePicker}
                                                validator={gtcAcceptedOnValidator}
                                                onChange={handleGTCAcceptedOnChange}
                                                disabled={props.editorState === IndividualLicenseEditorState.View}
                                                locale={locale}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs="12" lg="4">
                                        <div className="mb-3">
                                            <Field
                                                name={"numberOfPopulations"}
                                                component={FormNumericTextBox}
                                                validator={numberOfPopulationsValidator}
                                                min={minNumberOfPopulations}
                                                max={maximumValue}
                                                format="n0"
                                                label={intl.formatMessage({ id: 'ManageLicense.NumberOfPopulations' }) + " *"}
                                                onChange={handleNumberOfPopulationsChange}
                                                disabled={props.editorState !== IndividualLicenseEditorState.Add}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Field
                                                name={"numberOfPositions"}
                                                component={FormNumericTextBox}
                                                validator={numberOfPositionsValidator}
                                                min={minNumberOfPositions}
                                                max={maximumValue}
                                                format="n0"
                                                label={intl.formatMessage({ id: 'ManageLicense.NumberOfPositions' }) + " *"}
                                                onChange={handleNumberOfPositionsChange}
                                                disabled={props.editorState !== IndividualLicenseEditorState.Add}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Field
                                                id={"procedureObj"}
                                                name={"procedureObj"}
                                                label={intl.formatMessage({ id: 'ManageLicense.Procedure' }) + " *"}
                                                component={FormDropDownList}
                                                textField={"text"}
                                                dataItemKey={"value"}
                                                data={props.procedures}
                                                validator={procedureValidator}
                                                onChange={handleProcedureChange}
                                                disabled={props.editorState !== IndividualLicenseEditorState.Add}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Field
                                                name={"minimumTerm"}
                                                component={FormNumericTextBox}
                                                validator={minimumTermValidator}
                                                min={12}
                                                max={maximumValue}
                                                format="n0"
                                                label={intl.formatMessage({ id: 'ManageLicense.MinimumTerm' }) + " *"}
                                                onChange={(e) => {
                                                    handleMinimumTermChange(e, formRenderProps);
                                                }}
                                                disabled={props.editorState !== IndividualLicenseEditorState.Add}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Field
                                                id={"confirmationEmailAddress"}
                                                name={"confirmationEmailAddress"}
                                                label={intl.formatMessage({ id: 'ManageLicense.ConfirmationEmailAddress' }) + " *"}
                                                component={FormInput}
                                                maxLength={256}
                                                validator={confirmationEmailAddressValidator}
                                                onChange={handleConfirmationEmailAddressChange}
                                                disabled={props.editorState !== IndividualLicenseEditorState.Add}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs="12" lg="4">
                                        <div className="mb-3">
                                            <Field
                                                name={"pricePerMonthPaymentPerYear"}
                                                component={FormNumericTextBox}
                                                validator={pricePerMonthPaymentPerYearValidator}
                                                min={0.01}
                                                max={maximumValue}
                                                format="n2"
                                                label={intl.formatMessage({ id: 'ManageLicense.PricePerMonthPaymentPerYear' }) + " *"}
                                                onChange={handlePricePerMonthPaymentPerYearChange}
                                                disabled={props.editorState !== IndividualLicenseEditorState.Add}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Field
                                                name={"settlementAdditionalPositionPrice"}
                                                component={FormNumericTextBox}
                                                validator={settlementAdditionalPositionPriceValidator}
                                                min={0.01}
                                                max={100}
                                                format="n2"
                                                label={intl.formatMessage({ id: 'ManageLicense.SettlementAdditionalPositionPrice' }) + " *"}
                                                onChange={handleSettlementAdditionalPositionPriceChange}
                                                disabled={props.editorState !== IndividualLicenseEditorState.Add}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Field
                                                id={"status"}
                                                name={"status"}
                                                label={intl.formatMessage({ id: 'ManageLicense.Status' })}
                                                component={Input}
                                                disabled={true}
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <Field
                                                id={"licenseEndDate"}
                                                name={"licenseEndDate"}
                                                label={intl.formatMessage({ id: 'ManageLicense.EndDate' }) + " *"}
                                                component={FormDatePicker}
                                                validator={licenseEndDateValidator}
                                                onChange={handleLicenseEndDateChange}
                                                disabled={true}
                                                locale={locale}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </fieldset>

                            <DialogActionsBar>
                                <div className="k-form-buttons k-justify-content-stretch">
                                    <Button
                                        type={"button"}
                                        onClick={handleCancel}
                                        color="secondary"
                                    >
                                        <FormattedMessage id='Control.Cancel' />
                                    </Button>
                                    {isSavingEnabled &&
                                        <Button
                                            type={"submit"}
                                            color="primary"
                                            disabled={isSaveDisabled(formRenderProps)}
                                        >
                                            <FormattedMessage id='Control.Add' />
                                        </Button>
                                    }
                                </div>
                            </DialogActionsBar>
                        </FormElement>
                    )}
                />
            </Dialog>

            <ConfirmDialog
                visible={isConfirmDialogVisible}
                onClose={handleConfirmDialogClose}
                negative={<FormattedMessage id='Control.Cancel' />}
                positive={<FormattedMessage id='Control.Confirm' />}
                title={confirmDialogTitle}
                detail={confirmDialogDetail}
            />
        </div>
    );
};

export default EditIndividualForm;
