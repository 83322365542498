import { DashboardStoreCustomer, SELECT_POPULATION } from '../actions/types';

const initialState = {
    customer: null,
    population: null
};

export default function dashboardReducer(state = initialState, action) {
    switch (action.type) {
        case DashboardStoreCustomer:
            
            return {
                ...state,
                customer: action.payload
            };
        case SELECT_POPULATION:
            return {
                ...state,
                population: action.payload
            };
        default:
            return state;
    }
}
