import * as React from "react";
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl';
import { useLoading } from '../../Shared/LoadingContext';
import { Col, Row, Button } from 'reactstrap';
import { Input } from "@progress/kendo-react-inputs";
import { useNotification } from '../../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../../Shared/Notifications/Notification';
import { useExceptionDialog } from '../../Shared/ExceptionDialog/ExceptionDialogProvider';
import { getPasswordPolicy, updateUserPassword } from '../../../services/userManagementService';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, FormElement, Field } from '@progress/kendo-react-form';

const ChangePassword = (props) => {
    const pageId = 'UserManagement';
    const { setLoading } = useLoading();
    const dispatchExceptionDialog = useExceptionDialog();
    const user = useSelector(state => state.auth.user);

    const [disableNoUser, setDisableNoUser] = React.useState(false);
    const [disableNoEmail, setDisableNoEmail] = React.useState(false);
    const [disableLetter, setDisableLetter] = React.useState(false);
    const [disableCapital, setDisableCapital] = React.useState(false);
    const [disableNumber, setDisableNumber] = React.useState(false);
    const [disableSpecialChar, setDisableSpecialChar] = React.useState(false);
    const [disableMinLength, setDisableMinLength] = React.useState(false);
    const [disableMaxLength, setDisableMaxLength] = React.useState(false);

    const [minLimit, setMinLimit] = React.useState(10);
    const [maxLimit, setMaxLimit] = React.useState(200);

    const [newPassword, setNewPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');

    const dispatchNotification = useNotification();
    const [disabledSave, setDisabledSave] = React.useState(true);

    React.useEffect(() => {
        getPasswordPolicy(
            passwordPolicy => {
                setDisableNoUser(passwordPolicy.includes("notUsername"));
                setDisableNoEmail(passwordPolicy.includes("notEmail"));
                setDisableLetter(passwordPolicy.includes("lowerCase"));
                setDisableCapital(passwordPolicy.includes("upperCase"));
                setDisableNumber(passwordPolicy.includes("digits"));
                setDisableSpecialChar(passwordPolicy.includes("specialChars"));
                setDisableMinLength(passwordPolicy.includes("length"));
                setDisableMaxLength(passwordPolicy.includes("maxLength"));

                if (passwordPolicy.includes("length")) {
                    const regex = new RegExp("length.(\\d+)", 'gm')
                    let m = regex.exec(passwordPolicy);
                    setMinLimit(Number(m[1]));
                }

                if (passwordPolicy.includes("maxLength")) {
                    const regex = new RegExp("maxLength.(\\d+)", 'gm')
                    let m = regex.exec(passwordPolicy);
                    setMaxLimit(Number(m[1]));
                }
            },
            handleError);
    }, []);

    React.useEffect(() => {
        setDisabledSave(true);
        validatePassword();
    }, [newPassword, confirmPassword]);

    const removeNotification = () => {
        dispatchNotification({
            remove: true,
            pageId: pageId
        });
    };

    const handleNewPasswordChange = (txtPassword) => {
        removeNotification();
        setNewPassword(txtPassword.value);
    }

    const handleConfirmPasswordChange = (txtPassword) => {
        removeNotification();
        setConfirmPassword(txtPassword.value);
    }

    const validatePassword = () => {
        let newPasswordToLower = newPassword.toLowerCase();
        let isValid = true;
        if (user && user.profile) {
            // No user
            if (disableNoUser) {
                let noUser = document.getElementById("noUser");
                let familyNameToLower = user.profile.family_name.toLowerCase();
                let givenNameToLower = user.profile.given_name.toLowerCase();
                if (newPasswordToLower.indexOf(familyNameToLower) >= 0 || newPasswordToLower.indexOf(givenNameToLower) >= 0 || newPasswordToLower.length == 0) {
                    noUser.classList.remove("valid");
                    noUser.classList.add("invalid");
                    isValid = false;
                } else {
                    noUser.classList.remove("invalid");
                    noUser.classList.add("valid");
                }
            }

            // No Email
            if (disableNoEmail) {
                let noEmail = document.getElementById("noEmail");
                let emailToLower = user.profile.email.toLowerCase();
                let account = emailToLower.substr(0, emailToLower.indexOf("@"));
                if (newPasswordToLower.indexOf(account) >= 0 || newPasswordToLower.length == 0) {
                    noEmail.classList.remove("valid");
                    noEmail.classList.add("invalid");
                    isValid = false;
                } else {
                    noEmail.classList.remove("invalid");
                    noEmail.classList.add("valid");
                }
            }
        }

        // Validate lowercase letters
        if (disableLetter) {
            let letter = document.getElementById("letter");
            let lowerCaseLetters = /[a-z]/g;
            if (lowerCaseLetters.test(newPassword)) {
                letter.classList.remove("invalid");
                letter.classList.add("valid");
            } else {
                letter.classList.remove("valid");
                letter.classList.add("invalid");
                isValid = false;
            }
        }

        // Validate capital letters
        if (disableCapital) {
            let capital = document.getElementById("capital");
            let upperCaseLetters = /[A-Z]/g;
            if (upperCaseLetters.test(newPassword)) {
                capital.classList.remove("invalid");
                capital.classList.add("valid");
            } else {
                capital.classList.remove("valid");
                capital.classList.add("invalid");
                isValid = false;
            }
        }

        // Validate numbers
        if (disableNumber) {
            let number = document.getElementById("number");
            let numbers = /[0-9]/g;
            if (numbers.test(newPassword)) {
                number.classList.remove("invalid");
                number.classList.add("valid");
            } else {
                number.classList.remove("valid");
                number.classList.add("invalid");
                isValid = false;
            }
        }

        // Special character
        if (disableSpecialChar) {
            let specialChar = document.getElementById("specialChar");
            let specialChars = /[^A-Za-z0-9]/g;
            if (specialChars.test(newPassword)) {
                specialChar.classList.remove("invalid");
                specialChar.classList.add("valid");
            } else {
                specialChar.classList.remove("valid");
                specialChar.classList.add("invalid");
                isValid = false;
            }
        }

        // Validate min length
        if (disableMinLength) {
            let minLength = document.getElementById("minLength");
            if (newPassword.length >= minLimit) {
                minLength.classList.remove("invalid");
                minLength.classList.add("valid");
            } else {
                minLength.classList.remove("valid");
                minLength.classList.add("invalid");
                isValid = false;
            }
        }

        // Validate max length
        if (disableMaxLength) {
            let maxLength = document.getElementById("maxLength");
            if (newPassword.length <= maxLimit) {
                maxLength.classList.remove("invalid");
                maxLength.classList.add("valid");
            } else {
                maxLength.classList.remove("valid");
                maxLength.classList.add("invalid");
                isValid = false;
            }
        }

        //confirm password
        let confirmation = document.getElementById("confirmation");
        if (newPassword == confirmPassword && newPassword.length > 0 && confirmPassword.length) {
            confirmation.classList.remove("invalid");
            confirmation.classList.add("valid");
        } else {
            confirmation.classList.remove("valid");
            confirmation.classList.add("invalid");
            isValid = false;
        }
        setDisabledSave(isValid);
    }

    const handleSave = () => {
        setLoading(true);
        setDisabledSave(true);

        updateUserPassword(
            user.profile.sub, newPassword,
            () => {
                props.onClose();
                setLoading(false);
                dispatchNotification({ pageId: pageId, type: NotificationTypes.success, message: <FormattedMessage id="UserManagement.ChangePasswordSuccess" /> });
            },
            handleError);
    }

    const handleError = (errorMessage, showNotif = true) => {
        setLoading(false);
        if (showNotif) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            });
        }
    };

    const handleClose = () => {
        props.onClose();
    };

    return (
        <Dialog
            onClose={handleClose}
            className="changePassword"
            title={<FormattedMessage id="UserManagement.ChangeYourPassword" />}
        >           
            <Row>
                <Col xs="12" lg="6">
                    <Form                        
                        render={() => (
                        <FormElement>
                            <Row>
                                <Col xs="12">
                                    <fieldset className={"k-form-fieldset"}>
                                        <div className="mb-3">
                                            <Field
                                                id={"newPassword"}
                                                name={"newPassword"}
                                                label={<FormattedMessage id='UserManagement.PasswordNew' />}
                                                component={Input}
                                                minLength={10}
                                                maxLength={200}
                                                onChange={handleNewPasswordChange}
                                                type={"password"}
                                                required={true}
                                            />
                                        </div>
                                    </fieldset>
                                </Col>
                                </Row>
                                <Row>
                                    <Col xs="12">
                                        <fieldset className={"k-form-fieldset"}>
                                            <div className="mb-3">
                                                <Field
                                                    id={"confirmPassword"}
                                                    name={"confirmPassword"}
                                                    label={<FormattedMessage id='UserManagement.PasswordConfirm' />}
                                                    component={Input}
                                                    minLength={10}
                                                    maxLength={200}
                                                    onChange={handleConfirmPasswordChange}
                                                    type={"password"}
                                                    required={true}
                                                />
                                            </div>
                                        </fieldset>
                                    </Col>
                                </Row>
                            </FormElement>
                        )}
                    />
                </Col>
                <Col className="col-xs-12 col-lg-6" id="message">
                    {user && user.profile && (
                        <div>
                            {disableNoUser && (<p id="noUser" className="invalid"><FormattedMessage id='UserManagement.NoNameInPassword' /></p>)}
                            {disableNoEmail && (<p id="noEmail" className="invalid"><FormattedMessage id='UserManagement.NoEmailInPassword' /></p>)}
                        </div>
                    )}
                    {disableLetter && (<p id="letter" className="invalid"><FormattedMessage id='UserManagement.PasswordMinLowCaseLetter' /></p>)}
                    {disableCapital && (<p id="capital" className="invalid"><FormattedMessage id='UserManagement.PasswordMinCapitalLetter' /></p>)}
                    {disableNumber && (<p id="number" className="invalid"><FormattedMessage id='UserManagement.PasswordMinANumber' /></p>)}
                    {disableSpecialChar && (<p id="specialChar" className="invalid"><FormattedMessage id='UserManagement.PasswordMinASpecialChar' /></p>)}
                    {disableMinLength && (<p id="minLength" className="invalid"><FormattedMessage id='UserManagement.PasswordMinLength' values={{ limit: minLimit }} /></p>)}
                    {disableMaxLength && (<p id="maxLength" className="invalid"><FormattedMessage id='UserManagement.PasswordMaxLength' values={{ limit: maxLimit }} /></p>)}
                    <p id="confirmation" className="invalid"><FormattedMessage id='UserManagement.ConfirmDoesNotMatch' /></p>
                </Col>
            </Row>
            <DialogActionsBar>
                <Button onClick={handleClose} color="secondary">
                    <FormattedMessage id='Control.Cancel' />
                </Button>
                <Button onClick={handleSave} color="primary" disabled={!disabledSave} >
                    <FormattedMessage id='Control.Save' />
                </Button>
            </DialogActionsBar>
        </Dialog>
    );
}

export default ChangePassword