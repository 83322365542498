import * as React from "react";
import { useIntl } from "react-intl";
import { Switch } from "@progress/kendo-react-inputs";

export const SwitchCell = (props) => {
    const intl = useIntl();
    const handleChange = (e) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value,
            });
        }
    };

    const { dataItem } = props;
    const field = props.field || "";
    const dataValue = dataItem[field] === null ? "" : dataItem[field];
    return (
        <td>
            <Switch
                disabled={!dataItem.inEdit}
                checked={dataValue}
                onLabel={intl.formatMessage({ id: 'Control.Yes' })}
                offLabel={intl.formatMessage({ id: 'Control.No' })}
                onChange={handleChange}
            />
        </td>
    );
};