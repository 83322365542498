import * as React from 'react';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import ConfigInventoryImport from "./ConfigInventoryImport";
import { ConfigContext, CONFIG_CONTEXT_ACTIONS } from './ConfigContext';
import { useHistory } from 'react-router-dom';
import { deepClone } from '../../../utils/jsonUtils';
import { FormattedMessage, useIntl } from 'react-intl';
import ConfirmDialog from '../../Shared/ConfirmDialog';

const ConfigTabs = (props) => {
    const intl = useIntl();
    const tabIndex = props.selectedTabIndex ? Number(props.selectedTabIndex) : 0;
    const [selected, setSelected] = React.useState(tabIndex);

    const [currentTabHasChanges, setCurrentTabHasChanges] = React.useState(false);
    const [switchTabConfVisibile, setSwitchTabConfVisibile] = React.useState(false);
    const [initalDataHeaders, setInitalDataHeaders] = React.useState([]);
    const [nextTab, setNextTab] = React.useState(0);
    const [moveToNextTab, setMoveToNextTab] = React.useState(true);
    let history = useHistory();

    const [interfaceConfiguration, setInterfaceConfiguration] = React.useState({
        initialData: {
            startLine: 0,
            headingEnabled: false,
            priceEqualValue: false,
            columnSeparator: 0,
            decimalSeparator: 0,
            thousandSeparator: 0,
            priceUnit: 0,
            exportPositionAndPageNumbers: false,
            leadingZeros: false
        },
        importCountQuantities: {
            startLine: 0,
            headingEnabled: false,
            priceEqualValue: false,
            columnSeparator: 0,
            decimalSeparator: 0,
            thousandSeparator: 0,
            priceUnit: 0,
            exportPositionAndPageNumbers: false,
            leadingZeros: false
        },
        exportCountItems: {
            startLine: 0,
            headingEnabled: false,
            priceEqualValue: false,
            columnSeparator: 0,
            decimalSeparator: 0,
            thousandSeparator: 0,
            priceUnit: 0,
            exportPositionAndPageNumbers: false,
            leadingZeros: false
        },
        exportAccountingData: {
            startLine: 0,
            headingEnabled: false,
            priceEqualValue: false,
            columnSeparator: 0,
            decimalSeparator: 0,
            thousandSeparator: 0,
            priceUnit: 0,
            exportPositionAndPageNumbers: false,
            leadingZeros: false
        },
        exportItemTableId: {
            startLine: 0,
            headingEnabled: false,
            priceEqualValue: false,
            columnSeparator: 0,
            decimalSeparator: 0,
            thousandSeparator: 0,
            priceUnit: 0,
            exportPositionAndPageNumbers: false,
            leadingZeros: false
        }
    });


    const handleSelect = e => {
        setMoveToNextTab(true);
        if (!currentTabHasChanges) {
            setSelected(e.selected);
            setCurrentTabHasChanges(false);

        }
        else {
            setNextTab(e.selected);
            setSwitchTabConfVisibile(true)
        }

    };

    const handleConfirmDialogClose = (result) => {
        if (result) {
            if (moveToNextTab) {
                onConfirmMove()
            }
            else {
                gotoBack();
            }
        }
        else {
            onCancelMove()
        }
    }
    const onCancelMove = () => {
        setSwitchTabConfVisibile(false);
    }

    const onConfirmMove = () => {
        setSwitchTabConfVisibile(false);
        setSelected(nextTab);
    };



    const configContext = React.useContext(ConfigContext);

    React.useEffect(() => {
        if (configContext.configGlobal.interfaceConfiguration) {
            let ic = deepClone(configContext.configGlobal.interfaceConfiguration)
            if (selected === window.enumInterfaceConfigParamsType.ImportOfCountQuantities) {
                setInitalDataHeaders(ic.importCountQuantities.headers);
            }
            else {
                setInitalDataHeaders(ic.initialData.headers);
            }
            
            setInterfaceConfiguration(ic);
        }
    }, [configContext.configGlobal.interfaceConfiguration, selected]);

    React.useEffect(() => {
        if (selected === 0) {
            setInitalDataHeaders(configContext.configGlobal.interfaceConfiguration.initialDataHeaders);
        }
    }, [configContext.configGlobal.interfaceConfiguration.initialDataHeaders, selected]);

    React.useEffect(() => {
        if (selected === 2) {
            setInitalDataHeaders(configContext.configGlobal.interfaceConfiguration.importCountQuantitiesHeaders);
        }
    }, [configContext.configGlobal.interfaceConfiguration.importCountQuantitiesHeaders, selected]);

    React.useEffect(() => {
        let selectedTabType;
        switch (selected) {
            case 0:
                selectedTabType = window.enumInterfaceConfigParamsType.ImportOfInventoryData;
                break;
            case 1:
                selectedTabType = window.enumInterfaceConfigParamsType.ExportOfCountItems;
                break;
            case 2:
                selectedTabType = window.enumInterfaceConfigParamsType.ImportOfCountQuantities;
                break;
            case 3:
                selectedTabType = window.enumInterfaceConfigParamsType.ExportOfAccountingData;
                break;
            case 4:
                selectedTabType = window.enumInterfaceConfigParamsType.ExportOfItemTable;
                break;
            default:
                selectedTabType = window.enumInterfaceConfigParamsType.ImportOfInventoryData;
        }
        configContext.dispatchConfigGlobal({
            type: CONFIG_CONTEXT_ACTIONS.SelectedTabType,
            payload: selectedTabType
        });
    }, [selected]);

    const doBack = () => {
        setMoveToNextTab(false);
        if (currentTabHasChanges) {
            setSwitchTabConfVisibile(true)
        }
        else {
            gotoBack();
        }
    }

    const gotoBack = () => {
        history.push({
            pathname: '/administration/configlist'
        });
    }

    return <div>
        <ConfirmDialog
            visible={switchTabConfVisibile}
            onClose={handleConfirmDialogClose}
            negative={<FormattedMessage id='Control.No' />}
            positive={<FormattedMessage id='Control.Yes' />}
            title={<FormattedMessage id='InterfaceConfiguration.UnsavedChanges' />}
        />
        <TabStrip selected={selected} onSelect={handleSelect}>
            <TabStripTab title={intl.formatMessage({ id: 'InterfaceConfiguration.ImportOfInventoryData' })}>
                <ConfigInventoryImport
                    data={{
                        interfaceConfiguration: interfaceConfiguration,
                        state: interfaceConfiguration.initialData,
                        headers: initalDataHeaders,
                        dispatchHeadetType: CONFIG_CONTEXT_ACTIONS.SetInitialDataHeaders,
                        interfaceConfigParamsType: window.enumInterfaceConfigParamsType.ImportOfInventoryData,
                        handleError: props.handleError,
                        loadData: props.loadData,
                        isInterfaceConfigurationLoaded: props.isInterfaceConfigurationLoaded,
                        setCurrentTabHasChanges: setCurrentTabHasChanges,
                        doBack: doBack,
                        areHeadersEditable: true,
                        expanded: true
                    }}
                />
            </TabStripTab>
            <TabStripTab title={intl.formatMessage({ id: 'InterfaceConfiguration.ExportOfCountItems' })} >
                <ConfigInventoryImport
                    data={{
                        interfaceConfiguration: interfaceConfiguration,
                        state: interfaceConfiguration.exportCountItems,
                        headers: interfaceConfiguration.exportCountItems ? interfaceConfiguration.exportCountItems.headers : [],
                        interfaceConfigParamsType: window.enumInterfaceConfigParamsType.ExportOfCountItems,
                        handleError: props.handleError,
                        loadData: props.loadData,
                        isInterfaceConfigurationLoaded: props.isInterfaceConfigurationLoaded,
                        setCurrentTabHasChanges: setCurrentTabHasChanges,
                        doBack: doBack,
                        areHeadersEditable: false,
                        expanded: props.expanded
                    }}
                />
            </TabStripTab>
            <TabStripTab title={intl.formatMessage({ id: 'InterfaceConfiguration.ImportOfCountQuantities' })} >
                <ConfigInventoryImport
                    data={{
                        interfaceConfiguration: interfaceConfiguration,
                        state: interfaceConfiguration.importCountQuantities,
                        initialData: interfaceConfiguration.initialData,
                        dispatchHeadetType: CONFIG_CONTEXT_ACTIONS.SetImportCountQuantitiesHeaders,
                        headers: initalDataHeaders, //interfaceConfiguration.importCountQuantities ? interfaceConfiguration.importCountQuantities.headers : [],
                        interfaceConfigParamsType: window.enumInterfaceConfigParamsType.ImportOfCountQuantities,
                        handleError: props.handleError,
                        loadData: props.loadData,
                        isInterfaceConfigurationLoaded: props.isInterfaceConfigurationLoaded,
                        setCurrentTabHasChanges: setCurrentTabHasChanges,
                        doBack: doBack,
                        areHeadersEditable: true,
                        expanded: false
                    }}
                />
            </TabStripTab>

            <TabStripTab title={intl.formatMessage({ id: 'InterfaceConfiguration.ExportOfAccountingData' })} >
                <ConfigInventoryImport
                    data={{
                        interfaceConfiguration: interfaceConfiguration,
                        state: interfaceConfiguration.exportAccountingData,
                        headers: interfaceConfiguration.exportAccountingData ? interfaceConfiguration.exportAccountingData.headers : [],
                        interfaceConfigParamsType: window.enumInterfaceConfigParamsType.ExportOfAccountingData,
                        handleError: props.handleError,
                        loadData: props.loadData,
                        isInterfaceConfigurationLoaded: props.isInterfaceConfigurationLoaded,
                        setCurrentTabHasChanges: setCurrentTabHasChanges,
                        doBack: doBack,
                        areHeadersEditable: false,
                        expanded: props.expanded
                    }}
                />
            </TabStripTab>
            <TabStripTab title={intl.formatMessage({ id: 'InterfaceConfiguration.ExportOfItemTable' })}  >
                <ConfigInventoryImport
                    data={{
                        interfaceConfiguration: interfaceConfiguration,
                        state: interfaceConfiguration.exportItemTable,
                        headers: interfaceConfiguration.exportItemTable ? interfaceConfiguration.exportItemTable.headers : [],
                        interfaceConfigParamsType: window.enumInterfaceConfigParamsType.ExportOfItemTable,
                        handleError: props.handleError,
                        loadData: props.loadData,
                        isInterfaceConfigurationLoaded: props.isInterfaceConfigurationLoaded,
                        setCurrentTabHasChanges: setCurrentTabHasChanges,
                        doBack: doBack,
                        areHeadersEditable: false,
                        expanded: false
                    }}
                />
            </TabStripTab>

        </TabStrip>
    </div>
}

export default ConfigTabs
