import * as React from "react";
import { Card, CardBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

export const WaitingForErp = (props) => {

    const waitingForErpText = props.waitingForErpText || <FormattedMessage id="WaitingForErp.Wait" />
    const detailsText = props.detailsText || <FormattedMessage id="WaitingForErp.SyncWithErp" />

    return (
        <Card className="card-chart">
            <CardBody>
                <div className="text-center">
                    <p>
                        {waitingForErpText}
                    </p>
                    <p>
                        {detailsText}
                    </p>
                </div>
            </CardBody>
        </Card>
    )
}
