import * as React from "react";
import { WizzardContext } from "../WizzardContext";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from 'react-intl';
import { useState } from "react";
import { Card, CardBody, CardHeader, FormGroup, Label, Button, Collapse } from 'reactstrap';
import { RadioButton } from "@progress/kendo-react-inputs";
import { formatNumber } from "../../../../utils/localization";
import { Form } from "@progress/kendo-react-form";
import ReactTooltip from "react-tooltip";
import { DefaultInventoryDifference, DefaultSamplingError } from "../../../Administration/Settings/UploadDataQuality";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPencilAlt,
    faChevronUp,
    faChevronDown
} from '@fortawesome/pro-light-svg-icons'

const StockDataAverageCard = props => {
    const wizzardContext = React.useContext(WizzardContext);
    let history = useHistory();
    const intl = useIntl();
    const [isStasamInfOpen, setIsStasamInfOpen] = useState(false);
    const [isStasamParamOpen, setIsStasamParamOpen] = useState(false);

    const goToSettingsHandler = () => {
        history.push('/administration/settings/1');
    };

    return (
        <label htmlFor="averageRadio" className="select-card">
            <Card className={props.selectedCard === props.inventoryMethod.Stasam ? "compare-options card-selected" : "compare-options"}>
                <CardHeader>
                    <h1><FormattedMessage id='StockDataQuality.Average' />
                        <div className="float-right">
                            <RadioButton
                                name="ChooseDataQualityRadio"
                                value={props.inventoryMethod.Stasam}
                                checked={props.selectedCard === props.inventoryMethod.Stasam}
                                onChange={props.handleChange}
                                id="averageRadio"
                            />
                        </div>
                    </h1>
                </CardHeader>
                <CardBody>



                    <h3 className="mt-3">
                        <FormattedMessage id="StockDataQuality.CardBody.Stasam.Sub" />
                        <div className="pull-right">
                            <Button className="btn btn-secondary" onClick={() => setIsStasamInfOpen(!isStasamInfOpen)} >
                                <FontAwesomeIcon icon={isStasamInfOpen ? faChevronUp : faChevronDown} />
                            </Button>

                        </div>
                    </h3>

                    <Collapse isOpen={isStasamInfOpen}>
                        <FormattedMessage id="StockDataQuality.CardBody.Stasam.Row1" />
                        <ul>
                            <li><FormattedMessage id="StockDataQuality.CardBody.Stasam.Row2" /></li>
                            <li><FormattedMessage id="StockDataQuality.CardBody.Stasam.Row3" /></li>
                        </ul>
                        <FormattedMessage id="StockDataQuality.CardBody.Stasam.Row4" />
                    </Collapse>

                    <hr />

                    <h3>
                        <FormattedMessage id="Preparation.StratPara" />
                        <div className="float-right">
                            <ReactTooltip place="top" type="error" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />
                            <Button onClick={goToSettingsHandler} disabled={!props.userWithAdminPriviledges}
                                data-tip={intl.formatMessage({ id: "Preparation.EditInSettings" })} color="secondary">
                                <FontAwesomeIcon icon={faPencilAlt} />
                            </Button>
                            <Button onClick={() => setIsStasamParamOpen(!isStasamParamOpen)} className="btn btn-secondary">
                                <FontAwesomeIcon icon={isStasamParamOpen ? faChevronUp : faChevronDown} />
                            </Button>
                        </div>
                    </h3>

                    <Collapse isOpen={isStasamParamOpen}>
                        <Form render={() => (
                            <>
                                <FormGroup className="mt-3">
                                    <Label><FormattedMessage id="Preparation.Difference" /></Label>
                                    <h5 className="card-title">{formatNumber(wizzardContext.wizzardGlobal.CustomerSettings.inventoryDifference ?? DefaultInventoryDifference, props.language)} %</h5>
                                </FormGroup>
                                <FormGroup className="mt-3">
                                    <Label><FormattedMessage id="Preparation.Safety" /></Label>
                                    <h5 className="card-title">{props.reliabilityObj.text} %</h5>
                                </FormGroup>
                                <FormGroup className="mt-3">
                                    <Label><FormattedMessage id="Preparation.SamplingError" /></Label>
                                    <h5 className="card-title">{formatNumber(wizzardContext.wizzardGlobal.CustomerSettings.samplingError ?? DefaultSamplingError, props.language)} %</h5>
                                </FormGroup>
                            </>
                        )}
                        />
                    </Collapse>
                </CardBody>
            </Card>
        </label>        
    )
}
export default StockDataAverageCard