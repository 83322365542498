import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row, Card, CardBody, Nav, NavItem } from 'reactstrap';
import { HashLink } from 'react-router-hash-link';
import { data as dataEN } from '../language/ReleaseNotes/releaseNotes-EN';

import {
    ExpansionPanel,
    ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { Checkbox } from '@progress/kendo-react-inputs';
import { useHistory } from "react-router-dom";
import { UserContext } from "./UserContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';

function ReleaseNotes() {
    const intl = useIntl();
    const history = useHistory();

    const initialExpanded = dataEN.reduce(
        (acc, cur) => ({ ...acc, [cur.id]: false }),
        {}
    );
    const [expanded, setExpanded] = React.useState(initialExpanded);
    // As now, we support the English language only.
    // Uncomment the following line in order to support other languages too.
    //const userProfile = useSelector(state => state.profile.profile);

    const userContext = React.useContext(UserContext);

    useEffect(() => {
        if (userContext.userGlobal?.userInfo?.isStatcontrolAdmin === undefined) {
            return;
        }

        if (!userContext.userGlobal.userInfo.isStatcontrolAdmin) {
            history.push("/");
        }
    }, [userContext]);

    const [data] = React.useState(dataEN);

    // As now, we support the English language only.
    // Uncomment the following lines in order to support other languages too.
    //useEffect(() => {
    //    switch (userProfile.userLanguage) {
    //        case window.enumLanguage.English:
    //            setData(dataEN);
    //            break;
    //        case window.enumLanguage.German:
    //            setData(dataDE);
    //            break;
    //        case window.enumLanguage.Italian:
    //            setData(dataIT);
    //            break;
    //        default:
    //            setData(dataDE);
    //            break;
    //    }
    //}, [userProfile.userLanguage]);

    const onAction = React.useCallback(
        (id) => {
            setExpanded({ ...expanded, [id]: !expanded[id] });
        },
        [expanded]
    );

    const expandSection = (itemId) => {
        setExpanded({ ...expanded, [itemId]: true });
    };

    const doExpandedAll = () => {
        const currentExp = expandedAll();
        let items = Object.keys(expanded).map((key) => key);
        let exp = {};
        items.forEach(x => {
            exp = {
                ...exp,
                [x]: !currentExp
            };
        });
        setExpanded(exp);
    };

    const expandedAll = () => {
        if (expanded) {
            let items = Object.keys(expanded).map((key) => (expanded[key]));
            return items.map(x => x).every((x) => x);
        }
        else {
            return false;
        }
    };

    const devOpsUrl = 'https://dev.azure.com/remira/STATCONTROL/_workitems/edit';

    const getDevOpsWorkItemUrl = (workItemId) => {
        return `${devOpsUrl}/${workItemId}`;
    };

    return (
        <div className="content">
            <Row>
                <Col xs="12">
                    <h1>
                        <FormattedMessage id='ReleaseNotes.ReleaseNotes' />
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col xs="9">
                    {data.map((item) => (
                        <section id={item.id} key={item.id}>
                            <Card>
                                <ExpansionPanel
                                    title={item.title}
                                    expanded={expanded[item.id]}
                                    tabIndex={0}
                                    key={item.id}
                                    onAction={onAction.bind(undefined, item.id)}
                                    className="release-notes"
                                >
                                    <Reveal transitionEnterDuration={150} transitionExitDuration={150}>
                                        {expanded[item.id] && (
                                            <ExpansionPanelContent>
                                                <CardBody>
                                                    {item.sections.map((section) => (
                                                        <section id={item.id + section.id} key={item.id + section.id}>
                                                            <Row>
                                                                <Col xs="12">
                                                                    <p>{section.title}</p>
                                                                    <ul>
                                                                        {section.changes.map((change) => (
                                                                            <li key={item.id + section.id + change.workitem}>
                                                                                <a
                                                                                    href={getDevOpsWorkItemUrl(change.workitem)}
                                                                                    target="_blank"
                                                                                    className="btn btn-link"
                                                                                >
                                                                                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                                                                                </a>
                                                                                <span dangerouslySetInnerHTML={{ __html: change.text }} />
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </Col>
                                                            </Row>
                                                        </section>
                                                    ))}
                                                </CardBody>
                                            </ExpansionPanelContent>
                                        )}
                                    </Reveal>
                                </ExpansionPanel>
                            </Card>
                        </section>
                    ))}
                </Col>
                <Col xs="3">
                    <section id="help-nav">
                        <Checkbox
                            label={!expandedAll() ? intl.formatMessage({ id: "ReleaseNotes.ExpandAll" }) : intl.formatMessage({ id: "ReleaseNotes.CollapseAll" }) }
                            value={expandedAll()}
                            onChange={doExpandedAll}
                        />
                        
                        <Nav vertical>
                            {data.map((item) => (
                                <NavItem key={item.id}>
                                    <HashLink
                                        onClick={() => {
                                            expandSection(item.id);
                                        }}
                                        smooth
                                        to={'#' + item.id}
                                    >
                                        {item.title}
                                    </HashLink>
                                    <ul>
                                        {item.sections.map((section) => (
                                            <li
                                                key={item.id + section.id}
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    expandSection(item.id);
                                                }}
                                            >
                                                <HashLink smooth to={'#' + item.id + section.id}>
                                                    {section.title}
                                                </HashLink>
                                            </li>
                                        ))}
                                    </ul>
                                </NavItem>
                            ))}
                            <NavItem>
                                <HashLink smooth to={'#top'}>
                                    <FormattedMessage id='ReleaseNotes.TopOfPage' />
                                </HashLink>
                            </NavItem>
                        </Nav>
                    </section>
                </Col>
            </Row>
        </div>
    );
}

export default ReleaseNotes
