import { get, getWithPopulationIdHeader, post, postWithPopulationIdHeader } from './serviceBase';

const baseUrl = '/api/v1.0/Dashboard';

export function getDashboardInfo(successCallback, errorCallback) {
    const url = `${baseUrl}/GetDashboardInfo`;
    get(url, successCallback, errorCallback);
}

export function getUserInfo(successCallback, errorCallback) {
    const url = `${baseUrl}/GetUserInfo`;
    get(url, successCallback, errorCallback);
}

export function getCustomerInfo(customerId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('customerId', customerId);
    const url = `${baseUrl}/GetCustomerInfo?` + params;
    get(url, successCallback, errorCallback);
}

export function getLastStockItemImportDate(populationId, successCallback, errorCallback) {
    const url = `${baseUrl}/GetLastStockItemImportDate`;
    getWithPopulationIdHeader(url, populationId, successCallback, errorCallback);
}

export function checkSession(successCallback, errorCallback) {
    const url = `${baseUrl}/CheckSession`;
    get(url, successCallback, errorCallback);
}

export function setGTCAcceptedForCustomer(successCallback, errorCallback) {
    const url = `${baseUrl}/SetGTCAcceptedForCustomer`;
    post(url, successCallback, errorCallback);
}

export function setLastUsedPopulationId(populationId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('populationId', populationId);
    const url = `${baseUrl}/SetLastUsedPopulationId?` + params;
    post(url, null, successCallback, errorCallback);
}
