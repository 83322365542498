import * as React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useIntl } from 'react-intl';

export const DropDownCell = (props) => {
    const intl = useIntl();
    const handleChange = (e) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value?.value
                //value: e.target.value.value >= 0 ? e.target.value.value : null
            });
        }
    };

    const { dataItem } = props;
    const field = props.field || "";
    const dataValue = dataItem[field] ?? "";
    const dataSourceField = props.dataSourceField || "dataSource";
    const dataStringValue = dataItem[dataSourceField].find((c) => c.value === dataValue) ? dataItem[dataSourceField].find((c) => c.value === dataValue).text : "";
    const dataSource = [{ value: null, text: intl.formatMessage({ id: "InterfaceConfiguration.NoneSelected" }) }, ...dataItem[dataSourceField]];
    return (
        <td>
            {dataItem[dataSourceField]?.length > 0 && dataItem.inEdit ? (
                <DropDownList
                    onChange={handleChange}
                    value={dataSource.find((c) => c.value === dataValue)}
                    data={dataSource}
                    textField="text"
                />
            ) : (
                  dataStringValue
            )}
        </td>
    );
};
