import * as React from "react";
import axios from 'axios';
import { Grid, GridColumn as Column, getSelectedState } from "@progress/kendo-react-grid";
import { Checkbox } from '@progress/kendo-react-inputs';
import { useSelector } from 'react-redux';
import { getCountingList } from '../../../../services/countService';
import { WizzardContext, WIZZARD_ACTIONS } from "../WizzardContext";
import { Card, CardBody, CardHeader } from 'reactstrap';
import { useIntl, FormattedMessage } from "react-intl";
import { getLocale } from '../../../../utils/localization';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { getter } from "@progress/kendo-react-common";
import { filterBy } from "@progress/kendo-data-query";
import { DropdownFilterCell } from './DropdownFilterCell';
import { wizardEvents } from '../Wizard';
import { on, off } from '../../../../actions/events'
import { CountingOnMobileDeviceEvents } from './CountingOnMobileDevice';
import { getColumnValueFromFeedbackItem } from "./countingutils";

const countingStatusWithDifferences = 1;
const countingStatusExactMatch = 2;

const RowRender = (properties) => {
    const { row, props, onDragStart } = { ...properties };
    const additionalProps = {
        onDragStart: (e) => onDragStart(e, props.dataItem),
        onDragOver: (e) => {
            e.preventDefault();
        },
        draggable: true,
    };

    const additionalClassName = props.dataItem.Status === countingStatusWithDifferences ? " countedItemDiff" : "";
    const newClassName = row.props.className + additionalClassName;

    const trProps = {
        className: newClassName,
    };

    return React.cloneElement(
        row,
        { ...row.props, ...additionalProps, ...trProps },
        row.props.children
    );
};

export const DATA_ITEM_KEY = "ItemNo";
export const DATA_ITEM_DEVICE = "Device";
export const DEFAULT_DEVICE_TOKEN = '0';

const CountingOnMobileDeviceTable = (props) => {
    const [columns, setColumns] = React.useState([{}]);
    const cancelTokenSource = axios.CancelToken.source();
    const wizzardContext = React.useContext(WizzardContext);
    const selectedPopulation = useSelector(state => state.dashboard.population);
    const intl = useIntl();
    const userProfile = useSelector(state => state.profile.profile);
    const locale = getLocale(userProfile.userLanguage);
    const [extended, setExtended] = React.useState(false);

    const SELECTED_FIELD = "selected";
    const SELECTED_FIELD_WIDTH = 35;
    const idGetter = getter(DATA_ITEM_KEY);

    const [devices, setDevices] = React.useState(props.devices);
    React.useEffect(() => {
        const device = {
            id: 0,
            name: "",
            token: DEFAULT_DEVICE_TOKEN,
            emailAddress: null,
            isActive: false
        };
        setDevices([device, ...props.devices]);
    }, [props.devices]);

    const handleDeviceChange = (e) => {
        if (e.value.isActive) {
            return;
        }

        const itemId = e.target.props.itemId || '';
        const deviceToken = e.value.token || '';

        const items = [{
            itemNo: itemId,
            deviceToken: deviceToken
        }];

        if (props.onChangeDevice) {
            props.onChangeDevice(items);
        }
    };

    const handleDeviceItemRender = (element, { dataItem }) => {
        if (!dataItem || !dataItem.isActive) {
            return element;
        }

        const children = [
            <span
                key={dataItem.id}
            >
                {dataItem.name}
            </span>
        ];

        return React.cloneElement(
            element,
            {
                ...element.props,
                style: {
                    ...element.props.style,
                    cursor: 'default'
                },
            },
            children
        );
    };

    React.useEffect(() => {
        loadData();
        return () => { cancelTokenSource.cancel(); };
    }, []);

    const [headerLine, setHeaderLine] = React.useState(null);
    const loadData = () => {
        props.incrementLoadingCount();
        getCountingList(
            null,
            null,
            result => {
                let items = transformFeedbackLines(result.headerLine, result.feedbackLines);

                setHeaderLine(result.headerLine);
                setColumns(getColumns(result.headerLine));
                wizzardContext.dispatchWizzardGlobal({ type: WIZZARD_ACTIONS.SetCountData, payload: { CountData: items } });
                wizzardContext.dispatchWizzardGlobal({ type: WIZZARD_ACTIONS.SetHeader, payload: { Header: result.headerLine } });
                wizzardContext.dispatchWizzardGlobal({ type: WIZZARD_ACTIONS.SetTotalPages, payload: { TotalPages: result.totalNumberOfPages } });
                wizzardContext.dispatchWizzardGlobal({ type: WIZZARD_ACTIONS.SetPage, payload: { Page: result.pageNo } });

                props.decrementLoadingCount();
            },
            props.handleError,
            selectedPopulation ? selectedPopulation.populationId : null
        );
    };

    React.useEffect(() => {
        if (headerLine) {
            setColumns(getColumns(headerLine));
        }
    }, [extended]);

    const getColumns = (header) => {
        const minimColumnWidth = 150;
        const multiplier = 10;
        let itemNoWidth = header.itemNo.columnWidth * multiplier;
        itemNoWidth = itemNoWidth < minimColumnWidth ? minimColumnWidth : itemNoWidth;
        const arrowWidth = 100;
        let deviceWidth = header.device.columnWidth * multiplier + arrowWidth;
        deviceWidth = deviceWidth < minimColumnWidth ? minimColumnWidth : deviceWidth;

        let columns = [
            {
                field: "ItemNo",
                title: header.itemNo.columnName,
                editable: false,
                width: itemNoWidth,
                locked: true
            }];

        header.uniqueId.forEach(function (item, index, array) {
            let width = item.columnWidth * multiplier;
            width = width < minimColumnWidth ? minimColumnWidth : width;
            let field = 'Column' + index;
            columns.push({
                field: field,
                title: item.columnName,
                editable: false,
                width: width,
                locked: false
            });
        });

        columns.push({
            field: DATA_ITEM_DEVICE,
            title: intl.formatMessage({ id: header.device.columnName }),
            editable: true,
            width: deviceWidth,
            locked: true
        });

        let marginWidth = 10;
        let gridRemaingWidth = document.getElementById('enterCountingGrid').offsetWidth - itemNoWidth - deviceWidth - SELECTED_FIELD_WIDTH - marginWidth;
        let currentWidth = columns.map((item) => item.width).reduce((a, b) => a + b, 0) - itemNoWidth - deviceWidth;
        let multiplyFactor = gridRemaingWidth > currentWidth ? gridRemaingWidth / currentWidth : 1;
        if (multiplyFactor > 1) {
            columns.filter((item) => !item.locked).forEach(item => {
                item.width = item.width * multiplyFactor;
            });
        }
        return columns;
    }

    const transformFeedbackLines = (header, data) => {
        return data
            .filter((item) => item.status !== countingStatusExactMatch)
            .map((item) => {
                const newItem = {
                    ItemNo: item.itemNo,
                    ActualQuantity: item.actualQuantity,
                    inEdit: false,
                    ActualQuantityHasChanged: false,
                    Device: item.device,
                    Status: item.status
                }
                for (let i = 0; i < header.uniqueId.length; i++) {
                    let key = 'Column' + i;
                    newItem[key] = getColumnValueFromFeedbackItem(item, i, header.uniqueId[i].fId);
                }
                return newItem;
            });
    };

    const executeExtend = (event) => {
        event.preventDefault();
        setExtended(!extended);
    };

    const handleRowRender = (row, props) => {
        return (
            <RowRender
                props={props}
                row={row}
                onDragStart={handleDragStart}
            />
        );
    };


    const handleDragStart = (event, dataItem) => {
        if (!dataItem[SELECTED_FIELD]) {
            event.preventDefault();
        }
        else if (props.onDragStart) {
            const selectedIds = Object.keys(selectedState).filter(key => selectedState[key]).map(id => +id);
            const selectedDataItems = data.filter(item => selectedIds.some(id => id === item[DATA_ITEM_KEY]));
            if (selectedDataItems.length > 0) {
                props.onDragStart(selectedDataItems);
            }
        }
    };

    const CustomCell = (props) => {
        const { dataItem } = props;
        const field = props.field || '';

        const dataValue = dataItem[field] == null ? '' : dataItem[field];
        const dataID = dataItem[DATA_ITEM_KEY] == null ? '' : dataItem[DATA_ITEM_KEY];
        const selectedDevice = devices.find(x => x.token === dataValue);

        let style = { ...props.style };
        if (dataItem[DATA_ITEM_DEVICE] !== DEFAULT_DEVICE_TOKEN) {
            style.color = "darkgray";
        }
        return field === DATA_ITEM_DEVICE ?
            (devices &&
                //Please do not remove this style forwarding below!
                <td className={props.className} style={props.style}>
                    <DropDownList
                        onChange={handleDeviceChange}
                        value={selectedDevice}
                        data={devices}
                        textField={"name"}
                        dataItemKey={"token"}
                        itemId={dataID}
                        disabled={selectedDevice?.isActive ?? false}
                        itemRender={handleDeviceItemRender}
                    />
                </td>
            ) : (
                <td className={props.className} style={style}>{dataValue}</td>
            );
    };

    const ConditionalCellRender = (td, props) => {
        if (props.field === SELECTED_FIELD && props.dataItem[DATA_ITEM_DEVICE] !== DEFAULT_DEVICE_TOKEN) {
            return (
                <td {...td.props}>
                    <Checkbox disabled={true} />
                </td>
            );
        } else {
            return td;
        }
    };

    const [selectedState, setSelectedState] = React.useState({});
    const onSelectionChange = React.useCallback(
        (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });
            setSelectedState(newSelectedState);
        },
        [selectedState]
    );

    const onHeaderSelectionChange = React.useCallback((event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            if (item[DATA_ITEM_DEVICE] === DEFAULT_DEVICE_TOKEN) {
                newSelectedState[idGetter(item)] = checked;
            }
        });
        setSelectedState(newSelectedState);
    }, []);

    React.useEffect(() => {
        on(CountingOnMobileDeviceEvents.ItemsMapped, handleItemsMapped);

        return () => {
            off(CountingOnMobileDeviceEvents.ItemsMapped, handleItemsMapped);
        };
    }, []);

    const handleItemsMapped = React.useCallback((event) => {
        const newSelectedState = selectedState;
        event.detail.forEach(item => {
            newSelectedState[idGetter(item)] = false;
        });
        setSelectedState(newSelectedState);
    }, [selectedState]);

    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        if (filter) {
            filterData(filter);
        }
        else {
            setData(wizzardContext.wizzardGlobal.Counting.CountData.map(dataItem =>
                Object.assign(
                    {
                        selected: false
                    },
                    dataItem
                )));
        }
    }, [wizzardContext.wizzardGlobal.Counting.CountData]);

    const [filter, setFilter] = React.useState();

    const handleFilterChange = (event) => {
        filterData(event.filter);
        setFilter(event.filter);
    };

    const filterData = (fltr) => {
        const filterClone = JSON.parse(JSON.stringify(fltr));
        if (filterClone) {
            let deviceFilter = filterClone.filters.find(x => x.field === DATA_ITEM_DEVICE);
            if (deviceFilter) {
                const token = deviceFilter.value.token;
                deviceFilter.value = token;
            }
        }

        setData(filterBy(wizzardContext.wizzardGlobal.Counting.CountData, filterClone));
    };

    const DeviceFilterCell = (propsy) => (
        <DropdownFilterCell
            {...propsy}
            data={devices}
            textField={"name"}
            dataItemKey={"token"}
        />
    );

    const handlerefreshButtonClicked = (event) => {
        loadData();
    }

    React.useEffect(() => {
        on(wizardEvents.refreshButtonClicked, handlerefreshButtonClicked);

        return () => {
            off(wizardEvents.refreshButtonClicked, handlerefreshButtonClicked);
        }
    }, [handlerefreshButtonClicked]);

    const [statisticsMessage, setStatisticsMessage] = React.useState('');

    React.useEffect(() => {
        const totalCount = wizzardContext.wizzardGlobal.Counting.CountData.length;

        if (totalCount === 0) {
            setStatisticsMessage('');
        }
        else {
            const selectedCount = Object.entries(selectedState).filter(([key, value]) => value === true).length;
            const mappedCount = wizzardContext.wizzardGlobal.Counting.CountData.filter(x => x[DATA_ITEM_DEVICE] !== DEFAULT_DEVICE_TOKEN).length;
            const statisticsMsg = intl.formatMessage({ id: 'CountingList.Statistics' }, {
                TotalCount: totalCount,
                SelectedCount: selectedCount,
                MappedCount: mappedCount
            });
            setStatisticsMessage(statisticsMsg);
        }
    }, [wizzardContext.wizzardGlobal.Counting.CountData, selectedState, locale]);

    return (
        <Card className={extended ? "resize-large" : ""}>
            <CardHeader>
                <h3>
                    <FormattedMessage id='CountingList.TitleMobile' />
                    &nbsp;
                    <small>{statisticsMessage}</small>
                    <div className="float-right">
                        <button className="btn btn-secondary" onClick={executeExtend}>
                            <i className={extended ? "fal fa-compress-alt" : "fal fa-expand-alt"}></i>
                        </button>
                    </div>
                </h3>
            </CardHeader>
            <CardBody>
                <div id="enterCountingGrid">
                    <Grid
                        reorderable={true}
                        editField="inEdit"
                        sortable={true}
                        pageable={false}

                        rowRender={handleRowRender}

                        data={data.map(item => ({
                            ...item,
                            [SELECTED_FIELD]: selectedState[idGetter(item)]
                        }))}
                        dataItemKey={DATA_ITEM_KEY}
                        selectedField={SELECTED_FIELD}
                        selectable={{
                            enabled: true,
                            cell: false,
                            mode: "multiple",
                        }}
                        onSelectionChange={onSelectionChange}
                        onHeaderSelectionChange={onHeaderSelectionChange}
                        filterable={true}
                        filter={filter}
                        onFilterChange={handleFilterChange}
                        className={extended ? "counting-mobile-extended-grid" : "counting-mobile-grid"}
                        cellRender={ConditionalCellRender}
                    >
                        <Column
                            field={SELECTED_FIELD}
                            width={SELECTED_FIELD_WIDTH + "px"}
                            headerSelectionValue={
                                data.findIndex(item => item[DATA_ITEM_DEVICE] === DEFAULT_DEVICE_TOKEN && !selectedState[idGetter(item)]) === -1
                            }
                            locked={true}
                            filterable={false}
                            className="selectableCell"
                        />
                        {columns.map((column, index) => {
                            return (
                                <Column
                                    field={column.field}
                                    title={column.title}
                                    key={index}
                                    width={column.width + "%"}
                                    editable={column.editable}
                                    className={(column.className ?? "" + " selectableCell").trim()}
                                    cell={CustomCell}
                                    locked={column.locked}
                                    filterCell={column.field === DATA_ITEM_DEVICE ? DeviceFilterCell : null}
                                />
                            );
                        })}
                    </Grid>
                </div>
            </CardBody>
        </Card>
    );
}

export default CountingOnMobileDeviceTable
