import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import Wrapper from './components/Wrapper';

require('dotenv').config();
console.log(`Running on environment: ${process.env.REACT_APP_ENV}`);
console.log(process.env);

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter basename={baseUrl}> 
        <Wrapper>
            <App />
        </Wrapper>
    </BrowserRouter>,
    rootElement);
registerServiceWorker();


