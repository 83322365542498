import React from 'react';
import {
    Card, CardTitle, CardBody, Row, Col, Label
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { getLocale, formatCurrency, formatNumber } from '../../utils/localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faTally,
    faSackDollar,
    faRocketLaunch
} from '@fortawesome/pro-light-svg-icons'

export default function CustomerSummary(props) {
    const intl = useIntl();

    const locale = getLocale(props.language);

    const getCurrency = () => {
        return props.customer.currencyName;
    };

    const getTransferredStockValue = () => {
        return formatCurrency(props.customer.transferredStockValue, getCurrency(), locale);
    };

    const getDrawnSamples = () => {
        return props.customer.hasInventoryCompleted ? formatNumber(props.customer.drawnSamples, locale) : intl.formatMessage({ id: 'CustomerSummary.NotYetAvailable' });
    };

    const getSavedEffort = () => {
        return props.customer.hasInventoryCompleted ? formatNumber(props.customer.savedEffort, locale) + '%' : intl.formatMessage({ id: 'CustomerSummary.NotYetAvailable' });
    };

    const getDrawnSamplesTooltip = () => {
        return props.customer.hasInventoryCompleted ? '' : intl.formatMessage({ id: 'CustomerSummary.DrawnSamplesNotCalculated' });
    };

    const getSavedEffortTooltip = () => {
        return props.customer.hasInventoryCompleted ? '' : intl.formatMessage({ id: 'CustomerSummary.SavedEffortNotCalculated' });
    };

    return (
        <Card className="card-chart">
            <CardBody>
                <ReactTooltip id="infoTooltip" place="top" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />

                <Row className="card-chart-title">
                    <Col className="text-left text-success" sm="2">
                        <FontAwesomeIcon icon={faSackDollar} />
                    </Col>
                    <Col className="text-right" sm="10">
                        <Label><FormattedMessage id='CustomerSummary.TransferredStockValue' /></Label>
                        <CardTitle tag="h5" className="text-success">{getTransferredStockValue()}</CardTitle>
                    </Col>
                </Row>
                <Row className="card-chart-title">
                    <Col className="text-left text-success" sm="2">
                        <FontAwesomeIcon icon={faTally} />
                    </Col>
                    <Col className="text-right" sm="10">
                        <Label><FormattedMessage id='CustomerSummary.DrawnSamples' /></Label>
                        <CardTitle tag="h5" className="text-success" data-tip={getDrawnSamplesTooltip()} data-for="infoTooltip">{getDrawnSamples()}</CardTitle>
                    </Col>
                </Row>
                <Row className="card-chart-title">
                    <Col className="text-left text-success" sm="2">
                        <FontAwesomeIcon icon={faRocketLaunch} />
                    </Col>
                    <Col className="text-right" sm="10">
                        <Label><FormattedMessage id='CustomerSummary.SavedEffort' /></Label>
                        <CardTitle tag="h5" className="text-success" data-tip={getSavedEffortTooltip()} data-for="infoTooltip">{getSavedEffort()}</CardTitle>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}
