import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { useIntl } from 'react-intl';
import axios from 'axios';
import { useLoading } from '../../../Shared/LoadingContext';
import { useNotification } from '../../../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../../../Shared/Notifications/Notification';
import { useExceptionDialog } from '../../../Shared/ExceptionDialog/ExceptionDialogProvider';
import SignalRNotificationHandler from "../../../Shared/SignalRNotificationHandler";
import CountingOnMobileDeviceTable, { DATA_ITEM_KEY, DATA_ITEM_DEVICE, DEFAULT_DEVICE_TOKEN } from "./CountingOnMobileDeviceTable";
import DeviceList from "./DeviceList";
import {
    getCountingDevices,
    addCountingDevice,
    editCountingDevice,
    sendCountingDeviceEmail,
    activateCountingDevice
} from '../../../../services/countingDeviceService';
import { WizzardContext, WIZZARD_ACTIONS } from "../WizzardContext";
import { saveCountingListItems } from '../../../../services/countService';
import DeviceCountListDetailsDialog from './DeviceCountListDetailsDialog';
import EditDeviceDialog from './EditDeviceDialog';
import { wizardEvents } from '../Wizard';
import { trigger, on, off } from '../../../../actions/events'

export const CountingOnMobileDeviceEvents = {
    ItemsMapped: "CountingOnMobileDevice:ItemsMapped"
};

const CountingOnMobileDevice = (props) => {
    const dragSource = "CountingOnMobileDeviceTable";
    const intl = useIntl();
    const population = useSelector(state => state.dashboard.population);
    const { setLoading } = useLoading();
    const wizzardContext = useContext(WizzardContext);
    const cancelTokenSource = axios.CancelToken.source();

    const [loadingCount, setLoadingCount] = useState(0);
    useEffect(() => {
        setLoading(loadingCount > 0);
    }, [loadingCount]);

    const incrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount + 1);
    };

    const decrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount - 1);
    };

    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const pageId = 'CountingOnMobileDevice';

    const handleError = (errorMessage, showNotif = true) => {
        decrementLoadingCount();
        if (props.handleError) {
            props.handleError(errorMessage, showNotif, pageId);
        }
        if (showNotif) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            });
        }
    };

    const removeNotification = () => {
        dispatchNotification({
            remove: true,
            pageId: pageId
        });
    };

    const [devices, setDevices] = useState([]);
    useEffect(() => {
        loadCountingDevices();
    }, []);

    const loadCountingDevices = () => {
        incrementLoadingCount();
        getCountingDevices(
            population.populationId,
            countingDevices => {
                decrementLoadingCount();
                const cntDevices = countingDevices.map(countingDevice => {
                    return {
                        ...countingDevice,
                        count: 0
                    };
                });
                setDevices(cntDevices);

                let isAnyDeviceActive = countingDevices.some(x => x.isActive);
                wizzardContext.dispatchWizzardGlobal({ type: WIZZARD_ACTIONS.SetCountDeviceActive, payload: { IsDeviceActive: isAnyDeviceActive } });
            },
            handleError);
    }

    useEffect(() => {
        if (wizzardContext.wizzardGlobal.Counting.CountData.length === 0) {
            return;
        }

        const deviceGroups =
            wizzardContext.wizzardGlobal.Counting.CountData.reduce((previousValue, currentValue) => {
                (previousValue[currentValue[DATA_ITEM_DEVICE]] = previousValue[currentValue[DATA_ITEM_DEVICE]] || []).push(currentValue);
                return previousValue;
            }, {});

        let updatedDevices = devices.map(device => ({
            ...device,
            count: 0
        }));

        for (const [key, value] of Object.entries(deviceGroups)) {
            updatedDevices = updatedDevices.map(device =>
                device.token === key
                    ? {
                        ...device,
                        count: value.length
                    }
                    : device
            );
        }

        setDevices(updatedDevices);
    }, [wizzardContext.wizzardGlobal.Counting.CountData]);

    const handleAddDevice = () => {
        incrementLoadingCount();
        const device = {
            name: generateDeviceName()
        };
        addCountingDevice(
            device,
            population.populationId,
            countingDevice => {
                decrementLoadingCount();
                const cntDevice = {
                    ...countingDevice,
                    count: 0
                };
                setDevices([...devices, cntDevice]);
            },
            handleError);
    };

    const handleSaveDeviceName = (oldName, newName) => {
        if (oldName == newName)
            return;

        incrementLoadingCount();
        editCountingDevice(
            { oldName, newName },
            population.populationId,
            () => {
                const oldDevice = devices.find(d => d.name === oldName);
                if (oldDevice)
                    oldDevice.name = newName;
                setDevices([...devices]);
                decrementLoadingCount();
            },
            handleError);
    }

    const generateDeviceName = () => {
        return `${intl.formatMessage({ id: 'DeviceList.Device' })} ${devices.length + 1}`;
    };

    const [dragState, setDragState] = useState(null);

    const handleDragStart = (dataItems) => {
        setDragState({
            dragFrom: dragSource,
            dragDataItems: dataItems
        });
    };

    const handleDrop = (deviceToken) => {
        if (!dragState || dragState.dragFrom !== dragSource) {
            return;
        }

        let newDataItems = [];

        for (const dragDataItem of dragState.dragDataItems) {
            const countingItem = wizzardContext.wizzardGlobal.Counting.CountData.find(x => x[DATA_ITEM_KEY] === dragDataItem[DATA_ITEM_KEY]);
            if (!countingItem
                || countingItem[DATA_ITEM_DEVICE] !== dragDataItem[DATA_ITEM_DEVICE]
                || dragDataItem[DATA_ITEM_DEVICE] !== DEFAULT_DEVICE_TOKEN) {
                continue;
            }

            newDataItems.push(countingItem);
        }

        if (newDataItems.length === 0) {
            return;
        }

        const items = newDataItems.map(x => ({
            itemNo: x[DATA_ITEM_KEY],
            deviceToken: deviceToken
        }));
        doSaveCountingListItems(items);

        setDragState(null);
    };

    const handleChangeDevice = (items) => {
        doSaveCountingListItems(items);
    };

    const doSaveCountingListItems = (items) => {
        const request = getRequestForSave(items);
        if (request.feedbackLines.length === 0) {
            return;
        }

        incrementLoadingCount();
        saveCountingListItems(
            request,
            () => {
                if (request.feedbackLines.length > 0) {
                    wizzardContext.dispatchWizzardGlobal({ type: WIZZARD_ACTIONS.SetItem, payload: { item: request.feedbackLines[0] } });
                }
                trigger(CountingOnMobileDeviceEvents.ItemsMapped, request.feedbackLines);

                decrementLoadingCount();
            },
            handleError,
            population.populationId,
            cancelTokenSource.token
        );
    };

    const getRequestForSave = (items) => {
        let feedbackLines = [];

        for (const item of items) {
            let countingItem = wizzardContext.wizzardGlobal.Counting.CountData.find(x => x[DATA_ITEM_KEY] === item.itemNo);
            if (!countingItem) {
                continue;
            }

            countingItem[DATA_ITEM_DEVICE] = item.deviceToken;
            countingItem["ActualQuantityHasChanged"] = false;

            feedbackLines.push(countingItem);
        }

        return {
            feedbackLines: feedbackLines
        };
    };

    const [device, setDevice] = useState(null);
    const [isDeviceCountListDetailsVisible, setIsDeviceCountListDetailsVisible] = useState(false);
    const [isEditDeviceVisible, setIsEditDeviceVisible] = useState(false);

    const handleShowDeviceCountListDetails = (deviceId) => {
        const dvc = devices.find(x => x.id === deviceId);
        if (dvc) {
            setDevice({
                ...dvc,
                emailAddress: dvc.emailAddress || ''
            });
            setIsDeviceCountListDetailsVisible(true);
        }
    };

    const handleShowEditDevice = (deviceId) => {
        const dvc = devices.find(x => x.id === deviceId);
        if (dvc) {
            setDevice({
                ...dvc,
                emailAddress: dvc.emailAddress || ''
            });
            setIsEditDeviceVisible(true);
        }
    };

    const handleDeviceCountListDetailsClose = () => {
        setIsDeviceCountListDetailsVisible(false);
    };

    const handleEditDeviceClose = () => {
        setIsEditDeviceVisible(false);
    };

    const handleDeviceCountListDetailsSubmit = (dataItem) => {
        incrementLoadingCount();
        const deviceEmail = {
            deviceId: dataItem.id,
            emailAddress: dataItem.emailAddress
        };
        sendCountingDeviceEmail(
            deviceEmail,
            population.populationId,
            () => {
                decrementLoadingCount();
                setIsDeviceCountListDetailsVisible(false);
                dispatchNotification({
                    pageId: pageId,
                    type: NotificationTypes.success,
                    message: intl.formatMessage({ id: 'DeviceCountListDetailsDialog.EmailSent' })
                });

                const updatedDevices = devices.map(device =>
                    device.id === dataItem.id
                        ? {
                            ...device,
                            emailAddress: dataItem.emailAddress,
                            isActive: true
                        }
                        : device
                );
                setDevices(updatedDevices);

                wizzardContext.dispatchWizzardGlobal({ type: WIZZARD_ACTIONS.SetCountDeviceActive, payload: { IsDeviceActive: true } });
            },
            (_errorMessage, _showNotif = true) => {
                decrementLoadingCount();
                setIsDeviceCountListDetailsVisible(false);
                dispatchNotification({
                    pageId: pageId,
                    type: NotificationTypes.error,
                    message: intl.formatMessage({ id: 'DeviceCountListDetailsDialog.EmailSendingFailed' })
                });
            });
    };

    const handleDeviceCountListDetailsActivate = (deviceId) => {
        removeNotification();
        incrementLoadingCount();
        activateCountingDevice(
            deviceId,
            population.populationId,
            () => {
                decrementLoadingCount();
                dispatchNotification({
                    pageId: pageId,
                    type: NotificationTypes.success,
                    message: intl.formatMessage({ id: 'DeviceCountListDetailsDialog.CountListActivated' })
                });

                const updatedDevices = devices.map(device =>
                    device.id === deviceId
                        ? {
                            ...device,
                            isActive: true
                        }
                        : device
                );
                setDevices(updatedDevices);

                wizzardContext.dispatchWizzardGlobal({ type: WIZZARD_ACTIONS.SetCountDeviceActive, payload: { IsDeviceActive: true } });

                const dvc = updatedDevices.find(x => x.id === deviceId);
                if (dvc) {
                    setDevice({
                        ...dvc,
                        emailAddress: dvc.emailAddress || ''
                    });
                }
            },
            handleError
        );
    };

    const deviceNameValidator = (name, device) => {
        if (name === device.name) {
            return "";
        }

        if (!name) {
            return intl.formatMessage({ id: 'EditDeviceDialog.DeviceNameRequired' });
        }

        const regexInvalidCharactersInFilenames = /[<>:"\/\\|?*]/g;
        const matches = name.match(regexInvalidCharactersInFilenames);
        if (matches) {
            return intl.formatMessage({ id: 'EditDeviceDialog.DeviceNameInvalidCharacters' }, { "InvalidCharacters": matches });
        }

        const existingName = devices.find(d => d.name.toLowerCase() === name.toLowerCase());
        return existingName ? intl.formatMessage({ id: 'EditDeviceDialog.DeviceNameUnique' }) : "";
    };

    const handlerefreshButtonClicked = React.useCallback(() => {
        loadCountingDevices();
    }, [loadCountingDevices]);

    useEffect(() => {
        on(wizardEvents.refreshButtonClicked, handlerefreshButtonClicked);

        return () => {
            off(wizardEvents.refreshButtonClicked, handlerefreshButtonClicked);
        }
    }, [handlerefreshButtonClicked]);

    const onErpSystemFeedback = (message) => {
        if (
            population?.populationId.toLowerCase() === message.populationId.toLowerCase()
        ) {
            trigger(wizardEvents.refreshButtonClicked);
        }
    };

    const countingFinalized = React.useCallback((_event) => {
        if (props.onCountingFinished) {
            props.onCountingFinished();
        }
    },[props]);

    React.useEffect(() => {
        on(wizardEvents.saveInternalCounting, countingFinalized);

        return () => {
            off(wizardEvents.saveInternalCounting, countingFinalized);
        }
    }, [countingFinalized]);

    return (
        <div>
            <Row>
                <Col xs="9">
                    <CountingOnMobileDeviceTable
                        devices={devices}
                        onDragStart={handleDragStart}
                        onChangeDevice={handleChangeDevice}
                        incrementLoadingCount={incrementLoadingCount}
                        decrementLoadingCount={decrementLoadingCount}
                        handleError={handleError}
                    />
                </Col>
                <Col xs="3">
                    <DeviceList
                        devices={devices}
                        totalCount={wizzardContext.wizzardGlobal.Counting.CountData.length}
                        onAddDevice={handleAddDevice}
                        onDrop={handleDrop}
                        onShowDeviceCountListDetails={handleShowDeviceCountListDetails}
                        onShowEditDevice={handleShowEditDevice}
                    />
                </Col>
            </Row>

            <SignalRNotificationHandler notificationType='ErpSystemFeedback' onNotification={onErpSystemFeedback} />

            {isDeviceCountListDetailsVisible && (
                <DeviceCountListDetailsDialog
                    device={device}
                    deviceNameValidator={deviceNameValidator}
                    saveDeviceName={handleSaveDeviceName}
                    onClose={handleDeviceCountListDetailsClose}
                    onSubmit={handleDeviceCountListDetailsSubmit}
                    onActivate={handleDeviceCountListDetailsActivate}
                    incrementLoadingCount={incrementLoadingCount}
                    decrementLoadingCount={decrementLoadingCount}
                    handleError={handleError}
                />
            )}

            {isEditDeviceVisible && (
                <EditDeviceDialog
                    device={device}
                    deviceNameValidator={deviceNameValidator}
                    saveDeviceName={handleSaveDeviceName}
                    onClose={handleEditDeviceClose}
                />
            )}
        </div>
    );
}

export default CountingOnMobileDevice
