import * as React from "react";
import { Col, Row } from 'reactstrap';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { FormattedMessage } from "react-intl";
import debounce from 'lodash.debounce';

const GridFooter = props => {
    const maxPageNumber = props.totalPages != null ? props.totalPages : Math.floor((props.dataLength - 1) / props.itemsPerPage) + 1;
    const firstItem = (props.currentPage * props.itemsPerPage) - (props.itemsPerPage - 1);
    const lastItem = props.currentPage < maxPageNumber ? props.currentPage * props.itemsPerPage : props.dataLength;
    const displayTotalItemCountInfo = props.dataLength != null ? true : false;

    const handlePageChange = (event) => {
        if (event.value <= maxPageNumber) {
            props.setPageHandler(event.value);
        }
        else {
            props.setPageHandler(maxPageNumber);
        }
    };

    const debouncedHandlePageChange = React.useMemo(
        () => debounce(handlePageChange, 1000),
        [props]);

    React.useEffect(() => {
        return () => {
            debouncedHandlePageChange.cancel();
        };
    }, []);

    return (
        <Row className="grid-pagination">
            <Col xs="12">
                {displayTotalItemCountInfo  && (
                <div className="float-left">
                    <span className="grid-pagination-text">
                        <FormattedMessage id="Control.Paging.ItemsPerPage" values={{
                            FirstItem: firstItem,
                            LastItem: lastItem,
                            TotalItems: props.dataLength
                        }}
                        />
                    </span>
                </div>
                )}
                <div className="float-right">
                    {props.handleItemsPerPageChange && displayTotalItemCountInfo  && (
                        <span>
                            <span className="grid-pagination-text"><FormattedMessage id="Control.Paging.NoItemsPerPage" /></span>
                            <span>
                                <DropDownList data={[5, 10, 20, 50]} id="ControlPagingNoItemsPerPage"
                                    value={props.itemsPerPage}
                                    onChange={props.handleItemsPerPageChange}>
                                </DropDownList>
                            </span>
                        </span>
                    )}
                    {maxPageNumber > 1 && (
                        <span>
                            <span className="grid-pagination-text">
                                <FormattedMessage id="Control.Paging.PageXofX" values={{
                                    Current: props.currentPage,
                                    Total: props.totalPages ? props.totalPages : Math.floor((props.dataLength - 1) / props.itemsPerPage) + 1
                                }} />
                            </span>
                            {props.currentPage != 1 && (
                                <span>
                                    <span className="k-icon k-i-arrow-end-left kIconNavigation pagination-arrow" onClick={() => props.setPageHandler(1)} />
                                    <span className="k-icon k-i-arrow-60-left kIconNavigation pagination-arrow" onClick={() => props.setPageHandler(props.currentPage - 1)} />
                                </span>
                            )}
                            <NumericTextBox
                                spinners={false}
                                value={props.currentPage}
                                onChange={debouncedHandlePageChange}
                                min={1}
                                max={maxPageNumber}
                            />
                            {props.currentPage != maxPageNumber && (
                                <span>
                                    <span className="k-icon k-i-arrow-60-right kIconNavigation pagination-arrow" onClick={() => props.setPageHandler(props.currentPage + 1)} />
                                    <span className="k-icon k-i-arrow-end-right kIconNavigation pagination-arrow" onClick={() => props.setPageHandler(maxPageNumber)} />
                                </span>
                            )}
                        </span>
                    )}
                </div>
            </Col>
        </Row >
    );
};

export default GridFooter
