const privacyPolicyHtml_en = `
<p class="lead"lead>As of March 1st, 2022 </p>
<h5>§ 1 General, Definitions </h5>
<ol>
    <li>These GTC apply exclusively to such contracts which REMIRA Group GmbH, Phoenixplatz 2, 44263 Dortmund, Germany ("REMIRA") concludes with companies, merchants, legal entities under public law or public special funds.</li>
    <li>The following definitions of terms shall apply to these GTC: </li>
    <ol type="a">
        <li>"GTC" means these General Terms and Conditions of REMIRA Group GmbH for Software-as-a-Service.</li>
        <li>"Change Request" is a separate agreement between the contracting parties on a change to the content of the contract. </li>
        <li>“Third party Software” is software that is or was created by third parties and/or is or was provided to customer by third parties.</li>
        <li>"Third Party Systems" are software systems created and/or provided to customer by third parties.</li>
        <li>"Employees" means all employees (with and without employee status), officers and agents of REMIRA. </li>
        <li>"Person Day" means the use of an employee to the extent of 8 hours between the hours of 08:00 and 18:00 per business day. </li>
        <li>„Software“ means standard software and/or individual software provided by REMIRA to customer.</li>
        <li>"Standard Software" is software that REMIRA and/or a third party has not created or produced or creates not or produces not for a specific customer.</li>
        <li>"Daily Rate" means the compensation owed by customer per person-day.</li>
        <li>"Contracting Party" means REMIRA or customer. </li>
        <li>"Contracting Parties" means REMIRA and customer. </li>
        <li>"Business Day" is any calendar day that is not Saturday, Sunday or a public holiday valid in the Federal Republic of Germany and/or the federal state of North Rhine-Westphalia. </li>
    </ol>
    <li>General terms and conditions of customer shall not become part of the contractual relationship unless REMIRA expressly consents to their inclusion. This requirement of consent shall apply in any case, i.e. even if REMIRA performs the services without reservation in knowledge of customer's general terms and conditions. </li>
    <li>REMIRA is entitled to adapt these GTC in the course of the contractual relationship. Such an adjustment shall be made by sending new GTC in text form to customer together with an accompanying letter that expressly refers to the GTC that will apply in the future, the time of their validity and customer's right of objection. The GTC adjusted by REMIRA shall become effective upon expiry of a period of 21 calendar days, beginning with the receipt of the adjusted GTC together with the accompanying letter by customer and shall replace the GTC valid until their entry into force in their entirety. Customer shall have the right to object to the inclusion and validity of new GTC adjusted by REMIRA within a period of 14 calendar days, beginning with the receipt of the adjusted GTC and the corresponding accompanying letter by customer. If customer objects to the inclusion of the adjusted GTC in due time, they shall not become part of the contract. If REMIRA does not receive an objection from customer within the objection period, customer shall be deemed to have given its consent to the inclusion of the GTC adjusted by REMIRA. </li>
</ol>
<h5>§ 2 Subject matter of performance, performance of services </h5>
<ol>
    <li>REMIRA shall provide the contractual services in accordance with the current state of proven technology. </li>
    <li>REMIRA shall provide customer with user documentation (user guide) for standard software in digital form (e.g. PDF files) in German language, which is addressed to an expert IT user. No further documentation is owed. </li>
    <li>REMIRA hereby points out that the contractual use of standard software and/or individual software may necessitate the subsequent licensing of third party software. Any additional costs incurred in this regard shall be borne by customer. </li>
    <li>REMIRA is only obligated to provide advice if such has been expressly agreed upon. </li>
    <li>All time specifications refer to the time zone Berlin, Central European Time (CET/MEZ). </li>
    <li>Services of REMIRA shall be deemed guaranteed only to the extent and to the extent that the word "guarantee" or "guaranteed service" is expressly used in connection therewith. In particular, services agreed upon in service level agreements, support contracts, maintenance and service contracts do not constitute guarantees. </li>
    <li>The place of performance and fulfillment is the registered office of REMIRA.  </li>
    <li>The proper usability of standard software is only possible if customer maintains the current system requirements. The current system requirements will be communicated to customer by REMIRA upon request or within the software. </li>
    <li>The backup of customer's data is generally the responsibility of the customer. </li>
    <li>Customer is obligated to comply with all foreign trade regulations and not to establish and/or maintain any connections with persons and organizations against which restrictive measures to combat terrorism or other foreign trade sanctions have been or will be imposed. </li>
    <li>REMIRA is entitled to modify and/or adapt software within the scope of maintenance, support and regular further development, in particular due to general improvements and the adaptation to changed legal framework conditions, by means of patches, updates, upgrades and new releases, to the extent that such modifications and/or adaptations are technically necessary and/or close security gaps in the software and/or improve the software functionally and/or technically and/or the existing functionalities are not restricted. Such changes and adaptations shall in any case only be permissible if they are reasonable for customer, taking into account its interests. </li>
    <li>The services and deliveries of REMIRA are subject to the reservation of self-delivery, subject to the circumstance that REMIRA is not at fault for the temporary or permanent failure of self-delivery. REMIRA will inform customer about such a failure in advance, promptly and in writing. If the failure is permanent or lasts longer than 6 months, customer is entitled to terminate the contractual relationship without observing a notice period (special right of termination). </li>
    <li>If contractual services can be implemented in different technical ways and/or with different technical measures, REMIRA shall decide on the type and measures at its reasonable discretion. Such decision must take into account the interests of customer and be reasonable for customer. </li>    
</ol>
<h5>§ 3 Remuneration claims </h5>
<ol>
    <li>Services of REMIRA are to be remunerated by the customer. </li>
    <li>All daily rates, hourly rates, prices and remunerations stated by REMIRA are exclusive of any applicable statutory value added tax and any applicable customs duties. </li>
    <li>REMIRA shall invoice the contractual remuneration claims in an orderly manner. REMIRA is entitled to electronic invoicing. </li>
    <li>Due remuneration claims of REMIRA shall be settled within 14 (fourteen) calendar days, beginning with the receipt of the invoice. </li>
    <li>If REMIRA renders services within the scope of legal and/or contractual warranty claims of customer, such services shall be free of remuneration, in particular also in the event that a contractual relationship (e.g. software support contract) exists between REMIRA and the customer, according to which the same services would have to be remunerated by customer.  </li>
    <li>One-time claims for remuneration are due for payment upon provision of the rental object. </li>
    <li>The current monthly rent shall be due for payment annually and in advance. </li>
    <li>The monthly rent shall be increased annually on 01.01. by 3 percent of the most recently applicable rent, but not before the expiry of the minimum term of the contract.</li>
</ol>
<h5>§ 4 Browser and system compatibility </h5>
<ol>
    <li>If a correct rendering of the software to be provided is not possible by means of one of the agreed browser versions solely due to the non-compliance with the conventions of the W3C or a programming error on the part of the browser manufacturer and if REMIRA is not responsible for this circumstance, this shall not constitute a material defect and REMIRA shall not be obligated to modify or adapt the software. </li>
    <li>REMIRA hereby expressly points out to customer that the operability or functionality of the software may be impaired or completely eliminated if the agreed operating systems and browser versions are changed, overtaken or replaced by new releases, updates, upgrades or subsequent versions of these programs. </li>
</ol>
<h5>§ 5 General ancillary duties of customer </h5>
<ol>
    <li>Customer is obliged to cooperate to the necessary extent. </li>
    <li>Customer shall ensure that the data and information provided by customer are correct, complete and appropriate; REMIRA shall not have any active inspection obligations in this respect. </li>
    <li>Customer shall immediately notify REMIRA of any defects or gaps in the provision of services, if and as soon as customer becomes aware thereof. </li>
    <li>Customer shall name an employee of its company who shall be available to REMIRA as a contact person authorized to make decisions. Furthermore, customer shall name a deputy for cases in which the primary contact person is unavailable. </li>
    <li>Customer shall support REMIRA in determining the amount of variable, in particular usage-dependent, remuneration claims to the extent necessary and reasonable and free of charge for REMIRA. </li>
    <li>Customer shall support REMIRA in verifying whether software is used exclusively to the extent REMIRA has granted customer rights thereto. Upon REMIRA's request, customer shall, within a reasonable period of time, provide corresponding self-disclosure with comprehensible evidence of the scope of use. </li>
</ol>
<h5>§ 6 Third party works, open source software</h5>
<ol>
    <li>If REMIRA provides customer with third party software, the regulations of the license conditions of the relevant third party shall apply with priority for the use of the relevant third party software and its support and maintenance, if and insofar as REMIRA is obligated vis-à-vis the third party when using the third party software that is the subject of the contract to also include these license conditions in the contractual relationship between the contracting parties. Upon request of customer, REMIRA shall inform customer in text form which license conditions are concerned, to which third party software these refer and shall hand over or send the corresponding license conditions to customer. License terms in this sense are in particular End-User License Agreements, SLAs, support and maintenance contracts and terms of use. </li>
</ol>
<h5>§ 7 Rights of use</h5>
<ol>
    <li>REMIRA grants customer the non-exclusive right to use the rental object in accordance with the contract and for the intended purpose, without any spatial restrictions and limited in time to the term of the rental agreement. </li>
    <li>Customer is not entitled to sublease and/or sublet, in particular not by way of cloud computing, ASP or SaaS. </li>
    <li>Ownership of the rental object is and remains with REMIRA. </li>
    <li>The granting of a factual and/or technical possibility of use does not constitute a granting of the right of use by REMIRA. </li>
    <li>With the consent of REMIRA, customer is entitled to sublicense the rental object. In the event of such consent, the following shall apply: </li>
    <ol type="a">
        <li>Customer is not entitled to grant its sublicensee more rights to the rental object than the rights it has received or receives from REMIRA itself. </li>
        <li>The use of the rental object by sublicensees of customer shall be deemed to be use by customer vis-à-vis REMIRA. Any fault of a sublicensee shall be attributed to customer as its own fault. </li>
        <li>Upon termination of customer's right to use the rental object, regardless of the factual or legal reason, all rights of use of customer's sublicensees to the rental object, which they derive from customer, shall expire simultaneously. </li>
    </ol>
</ol>
<h5>§ 8 Term, Termination</h5>
<ol>
    <li>Customer orders software directly in the software (offer). Upon receipt of the order confirmation by REMIRA (via e-mail) by customer (acceptance), the contract is concluded. </li>
    <li>The rental agreement shall have a term of 36 months (minimum term). The term shall be extended by 12 further months in each case (extension period), unless one of the contracting parties gives ordinary notice of termination of the rental agreement, observing a notice period of 3 months to the end of the minimum term or an extension period. The right of each contracting party to extraordinary termination for good cause shall remain unaffected hereby.  </li>
    <li>Ordinary termination must be in writing; if it does not comply with this, it shall be null and void. </li>
    <li>The price list and general terms and conditions of REMIRA currently valid 4 (four) months before the beginning of the respective extension period shall apply for each extension period. REMIRA shall make these documents available to customer in digital form (pdf) upon request. </li>
    <li>Customer shall discontinue the use of the rental object in its entirety at the latest at the time of termination of the rental agreement and to delete the rental object (in the form of software or databases) in its entirety and without replacement from its systems. Upon REMIRA's request, customer shall confirm the deletion in writing. </li>
    <li>If customer violates his obligation according to § 5 para. (5) and/or § 5 para. (6) of these GTC, REMIRA shall be entitled to terminate the agreement extraordinarily and without observing a notice period (extraordinary special right of termination). </li>
    <li>Customer can only assert the right of termination pursuant to § 543 para. 2 no. 1 BGB (German Civil Code) under the condition that he has previously requested REMIRA in writing to (re-)grant the use of the rental object setting a reasonable deadline and this deadline has expired fruitlessly. This requirement shall not apply in the event that REMIRA seriously and finally refuses to grant the use of the rental object although REMIRA is obliged to do so. </li>
</ol>
<h5>§ 9 Material defects of the rental object</h5>
<ol>
    <li>Claims for defects do not extend to services of REMIRA which customer or a third party modifies without the consent of REMIRA. This shall not apply if customer proves that such modification is not the cause of the reported defect or if customer merely installs new program versions made available by REMIRA. </li>
    <li>Customer shall only be entitled to a reduction of the rent due to defects if customer has previously notified REMIRA in writing of the rental defect(s), REMIRA has been given a reasonable period of time to remedy the defect(s) and this period of time has expired without success. </li>
</ol>
<h5>§ 10 Internet connection, delivery point </h5>
<ol>
    <li>REMIRA owes the provision of the software at the transfer point. </li>
    <li>The connection of customer to the Internet is not subject matter of the contract, but is the responsibility of customer. </li>
    <li>The transfer point is the data interface of REMIRA's data center. </li>
    <li>The responsibility of REMIRA for the availability of the Software ends at the transfer point. </li>
</ol>
<h5>§ 11 Availability, Service Level Agreement (SLA) </h5>
<ol>
    <li>REMIRA shall make the software available to customer on servers of REMIRA via an Internet connection at a rate of 95,0% per calendar year. The thus permissible non-availability of the software in the amount of 5,0% per calendar year shall only be available for unplanned, unforeseeable failures (e.g. impairments of accessibility, functionality or operability) which are not caused intentionally or by gross negligence by REMIRA. If an intentional or negligent violation of contractual and/or legal obligations of REMIRA is at least partly responsible for an availability restriction, the resulting non-availability of the software shall be inadmissible. </li>
    <li>Excluded from the availability according to the above paragraph (1) of this § 11 are planned, announced interruptions due to necessary maintenance and updates, provided that the performance of the respective maintenance, the import of the respective update or the performance of the respective repair is not necessary exclusively due to a circumstance that is based on an intentional or negligent breach of contractual or statutory obligations by REMIRA. </li>
</ol>
<h5>§ 12 Obligations of customer </h5>
<ol>
    <li>Customer shall </li>
    <ol type="a">
        <li>protect the software and/or system access data assigned to it or its users, in particular usage and access authorizations as well as identification and authentication safeguards, from access by unauthorized third parties and shall not disclose such data to unauthorized users and/or unauthorized third parties, </li>
        <li>to prevent unauthorized access by third parties to the software and/or the system and to the documentation by taking appropriate precautions, </li>
        <li>keep the original data carriers as well as the backup copies in a place secured against unauthorized access by third parties, </li>
        <li>comply with the recognized principles of data protection, </li>
        <li>before using the system or the software for the first time, only provide access data to the software or the system to persons who have received sufficient training and have been informed about the consequences of improper or incorrect use of the functions provided in the software or the system, and </li>
        <li>store and retain the information and documents (invoices, credit notes, etc.) contained in the system or the software which are relevant under tax and/or commercial law and which must be retained in accordance with the law </li>
    </ol>
    <li>Customer shall refrain from  </li>
    <ol type="a">
        <li>modifying, editing or decompiling the software in the source and/or object code without the consent of REMIRA, unless and to the extent customer is entitled to do so on the basis of § 69e of the German Copyright Act (UrhG) or the express consent of REMIRA, </li>
        <li>using or allowing the use of functions or processes of the software and the system contrary to the terms of the contract or the terms of use, or to support such use, </li>
        <li>obtaining access for himself/herself or for third parties to data not intended for him/her, </li>
        <li>using the software improperly, in particular to post content that is illegal or immoral or to refer to such content by hyperlink that violates the rights of third parties or is illegal (e.g. violations of personal rights, copyrights, trademark rights or competition laws), </li>
        <li>penetrating or using REMIRA's data networks, servers, programs and program parts or other system components without authorization; and </li>
        <li>removing and/or altering copyright notices, serial numbers or other features serving to identify software and/or hardware and/or </li>
        <li>using the software and systems of REMIRA for the unsolicited sending of electronic messages or information to third parties for advertising purposes (so-called spamming). </li>
    </ol>
    <li>Customer shall make available to REMIRA, upon request and as needed: </li>
    <ol type="a">
        <li>Parameters for system configuration, </li>
        <li>information on interfaces and data formats, </li>
        <li>the provision of test data and </li>
        <li>the definition of test cases. </li>
    </ol>
    <li>In case of connection of the software to third party systems via interface, customer shall provide REMIRA with a test system and/or test access to the respective third party systems in a timely manner and in advance. </li>
    <li>In order to avoid loading and overloading of the software and the system which could not be foreseen by REMIRA and which could be harmful, customer is only entitled to use the software and the system after prior notification to REMIRA and express approval by REMIRA, </li>
    <ol type="a">
        <li>to transfer data to the system to an extent that exceeds the usual use of the software (e.g. additional items for holidays, clearance sales, replacement of the entire assortment, significant expansion of the assortment, etc.), </li>
        <li>to transfer data redundantly to the system, and/or </li>
        <li>to transfer non-interface-compliant data to the system. Whether data to be transferred to the system is interface-compliant shall be verified in advance by customer in each case by means of conscientious tests during the integration of its third party systems into the system as well as prior validation of the data to be transferred. </li>
    </ol>
    <li>Customer shall inform REMIRA without undue delay about an excessive increase in the load for the software and/or the system or an increase that was unforeseeable for REMIRA, e.g. caused by incoming data or use of the front end. An excessive increase in load can be caused, among other things, by advertising measures (also more prominent display of products), special promotions on sales platforms or other sales channels, major product changes, import of bulk and/or legacy data. </li>
    <li>If customer violates an obligation pursuant to this § 12 paragraph (2), (5) and/or (6), REMIRA is entitled to request customer to comply and to set a reasonable deadline for compliance. If this deadline expires fruitlessly, REMIRA shall be entitled to block or restrict, in whole or in part, the contractual performance or the access to the software and the system until customer has fulfilled the obligation to cooperate or the secondary obligation. Such blocking or restriction shall not entitle customer to withhold current rental payments. </li>
    <li>If customer creates data on the system or holds data on the system which cause the violation of a legal and/or contractual obligation or the possession and/or processing of which violates applicable law, REMIRA is entitled to delete the relevant data immediately and without replacement. </li>
</ol>
<h5>§ 13 Data usage and deletion</h5>
<ol>
    <li>Upon termination of the contractual relationship, REMIRA is entitled to delete all data and information transferred by customer to the software and/or the system without replacement.  </li>
    <li>If REMIRA is subject to retention obligations, REMIRA is entitled to store and process the corresponding data and information for the duration of the retention obligation. Upon expiration of the duration of the retention obligation, REMIRA shall delete the relevant data and information in its entirety. Customer shall have no right to export such data prior to deletion. </li>
    <li>Transmission data from real-time communication will be collected and stored by REMIRA for purposes of error detection (so-called debugging) for a maximum of 36 calendar months, if required. </li>
    <li>The asynchronous exchange of data via standard interfaces with third party systems (e.g., eShop) is only collected and stored as needed and for a maximum of 36 calendar months for purposes of error detection (so-called debugging). </li>
</ol>
<h5>§ 14 Support</h5>
<ol>
    <li>Customer is entitled to contact REMIRA's support in the following cases: </li>
     <ol type="a">
        <li>Questions regarding the functioning of the software. </li>
        <li>Questions about changes and configurations of the software. </li>
        <li>Reporting malfunctions, operational limitations, and functional impairments of the software and/or system. </li>
        <li>Advice and support on the operation of the software, on functionalities of the software and on processes. </li>
    </ol>
    <li>Each request or report to REMIRA's support is a support incident. </li>
    <li>Support incidents shall be processed exclusively in German. </li>
    <li>The support is available on workdays from 9:00 a.m. to 5:00 p.m. ("support hours"). Incidents are accepted and processed exclusively within the support hours. </li>
    <li>Customer can send support incidents to REMIRA via the electronic form provided in the software. </li>
    <li>REMIRA shall inform customer about the completion of support incidents. This can also be done in the form of an automatically generated e-mail from the system. </li>
    <li>Exclusively key users are entitled to contact the support of REMIRA. Customer is entitled to name up to three key users at the same time. Key users are the persons authorized to make inquiries from the sphere of customer communicated by customer. After conclusion of the contract, customer shall provide REMIRA with a list of the key users which enables REMIRA to identify key users as such.  </li>
    <li>Customer shall be entitled at any time to update the list of the key users determined by him. An updated list submitted by customer shall become binding for REMIRA at the beginning of the calendar month following the calendar month during which the updated list was submitted by customer. </li>
    <li>REMIRA may provide customer with an emergency telephone number for reporting particularly serious malfunctions, operational restrictions and functional impairments of the software and/or the system, but is not obliged to do so. An emergency telephone number provided may only be called by customer outside the support hours. If customer uses an emergency telephone number provided to him by REMIRA to report malfunctions, operational restrictions and functional impairments of the software and/or the system which REMIRA does not have to remedy within the scope of the warranty, REMIRA shall be entitled to demand a lump-sum processing fee in the amount of EUR 250.00 for each call received.  </li>
    <li>REMIRA shall charge the price for the processing of support incidents on a time-based basis and in a cycle of 20 (twenty) minutes, unless otherwise contractually agreed. </li>
    <li>REMIRA shall be entitled to charge incidents processed outside the support hours at the request of customer with a factor of 2.5. </li>
</ol>
<h5>§ 15</h5>
<ol>
    <li>If REMIRA's services are defective in title and a third party asserts justified and enforceable claims against customer based on this defect in title, then  </li>
    <ol type="a">
        <li>REMIRA may, at its option and at its expense, either modify or replace the relevant services in such a way that the defect of title is eliminated, but still essentially corresponds to the agreed functional and performance characteristics in a manner that is reasonable for customer, or</li>
        <li>indemnify customer from claims against the owner of the property rights or acquire rights from third parties that are necessary in order for customer to be able to use the contractual services free of defects in title and in accordance with the contract and the intended use and grant these rights to customer or </li>
        <li>REMIRA is entitled to take back the affected services against reimbursement of the remuneration paid, whereby REMIRA is obligated to grant customer a reasonable period of time for termination, unless this is only possible under unreasonable legal or other conditions.</li>
    </ol>
    <li>Customer shall inform REMIRA without undue delay of any claim by a third party due to an asserted infringement of intellectual property rights based on services provided by REMIRA. Customer is obligated to refrain from declaring or entering into acknowledgements and/or settlements of such claims asserted by third parties without the consent or approval of REMIRA. Furthermore, customer leaves to REMIRA the legal defense against such claims and the choice and assertion of defense measures, unless the defense measures and legal defense must be reserved to customer for legal reasons. In the event of legal defense by REMIRA, customer shall support REMIRA in this regard to the extent that this is reasonable for customer and the expenses incurred in this regard are not disproportionate. In the event of legal defense by customer, customer shall be entitled to an advance payment in the amount of the estimated, necessary defense costs.  </li>
    <li>If customer violates an obligation according to the regulation in this § 15 paragraph (2), claims for exemption according to the above paragraph (1) of this § 15 expire with immediate effect and retroactively. </li>
</ol>
<h5>§ 16 Liability, Damages </h5>
<ol>
    <li>REMIRA shall be liable without limitation in the event of intent, gross negligence, injury to life, body or health, in accordance with the provisions of the Product Liability Act as well as to the extent of a guarantee assumed by REMIRA. </li>
    <li>If REMIRA negligently breaches an essential obligation, the liability of REMIRA is limited in amount to the compensation of the damage typical for the contract and foreseeable for REMIRA at the time of the conclusion of the contract. Material obligations are obligations the fulfillment of which is essential for the proper performance of the contract, the violation of which endangers the achievement of the purpose of the contract and the compliance with which customer regularly relies on. </li>
    <li>REMIRA's strict liability for defects already existing at the time of the conclusion of the contract (§ 536 a BGB) is excluded.  </li>
    <li>If and to the extent that the creation of data backups is not a service that REMIRA has expressly assumed, REMIRA shall only be liable for the loss of or damage to data and programs and their restoration to the extent that such loss could not have been avoided through reasonable precautionary measures, in particular the daily creation of backup copies of all data and programs. </li>
    <li>In all other respects, any liability for damages on the part of REMIRA, regardless of the legal reason, is excluded. </li>
    <li>The above limitation of liability also applies to the personal liability of REMIRA's employees, bodies and representatives. </li>
</ol>
<h5>§ 17 Minimum wage act </h5>
<ol>
    <li>REMIRA and its subcontractors are obligated to comply with the provisions of the Minimum Wage Act. </li>
    <li>REMIRA shall indemnify customer against any and all claims of third parties to which such third parties are entitled against customer due to a violation of the provisions of the Minimum Wage Act by REMIRA. The provisions in § 15 paragraph (2) of these GTC shall apply accordingly to this indemnification claim. </li>
</ol>
<h5>§ 18 Final provisions </h5>
<ol>
    <li>The contractual relationship shall be governed exclusively by the laws of the Federal Republic of Germany to the exclusion of the UN Convention on Contracts for the International Sale of Goods (CISG) dated April 11, 1980. </li>
    <li>The exclusive place of jurisdiction for all disputes arising out of or in connection with the contractual relationship is the principal place of business of REMIRA (Germany). REMIRA also has the right to sue customer at customer's place of business. </li>
    <li>The contractual language is German. In case of parallel use of other languages and inconsistencies between different language versions, the German wording of the respective provisions shall be decisive. </li>
    <li>Should any provision of these GTC be or become invalid, all other provisions shall remain unaffected. </li>
</ol>
`;

export default privacyPolicyHtml_en;