import React, { createRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { getLocale, formatNumber } from '../../../../utils/localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPaperPlane,
    faQrcode,
    faPencil
} from '@fortawesome/pro-light-svg-icons';

const Device = (props) => {
    const userProfile = useSelector(state => state.profile.profile);
    const locale = getLocale(userProfile.userLanguage);

    const myDiv = createRef();

    useEffect(() => {
        let divElement = myDiv.current;

        if (!divElement) {
            return;
        }

        divElement.addEventListener("drop", handleDrop);
        divElement.addEventListener("dragover", (e) => e.preventDefault());
    });

    const handleDrop = (e) => {
        if (props.onDrop) {
            props.onDrop(props.device.token);
        }
    };

    const handleShowDeviceCountListDetails = () => {
        if (props.onShowDeviceCountListDetails) {
            props.onShowDeviceCountListDetails(props.device.id);
        }
    };

    const handleEditDevice = () => {
        if (props.onEditDevice) {
            props.onEditDevice(props.device.id);
        }
    };

    return (
        <Row>
            <Col xs="12">
                <Label>{props.device.name}</Label>
                {props.device.count > 0 && (
                    <div className="pull-right">
                        <Label>
                            <FormattedMessage
                                id="Control.ProgressCurrentOfTotal"
                                values={{
                                    Current: formatNumber(props.device.count, locale),
                                    Total: formatNumber(props.totalCount, locale)
                                }}
                            />
                        </Label>
                    </div>
                )}
            </Col>
            {!props.device.isActive && (
                <Col xs="9" className="device-info">
                    <div
                        ref={myDiv}
                        className="drop-box"
                    >
                        <Label><FormattedMessage id="DeviceList.DropHere" /></Label>
                    </div>
                </Col>
            )}
            {props.device.count > 0 && (
                <Col xs={!props.device.isActive ? "3" : "12"} className="device-info">
                    <div className="float-right">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleShowDeviceCountListDetails}
                        >
                            <FontAwesomeIcon icon={!props.device.isActive ? faPaperPlane : faQrcode} />
                        </button>
                    </div>
                </Col>
            )}
            {props.device.count == 0 && (
                <Col xs={!props.device.isActive ? "3" : "12"} className="device-info">
                    <div className="float-right">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleEditDevice}
                        >
                            <FontAwesomeIcon icon={faPencil} />
                        </button>
                    </div>
                </Col>
            )}
        </Row>
    );
}

export default Device
