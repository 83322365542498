import React, { useState, useEffect } from 'react';
import {
    Chart,
    ChartLegend,
    ChartArea,
    ChartTitle,
    ChartSeries,
    ChartSeriesItem,
    ChartSeriesLabels,
    ChartTooltip
} from "@progress/kendo-react-charts";
import { Card, CardBody, CardHeader, Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { getLocale, formatNumberWithDecimals } from '../../../../utils/localization';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    IntlProvider,
} from "@progress/kendo-react-intl";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faSackDollar,
    faPlusCircle,
    faMinusCircle,
    faExclamationTriangle,
    faCalculator,
    faBalanceScaleRight,
    faInfoCircle
} from '@fortawesome/pro-light-svg-icons'


const ReportingStatistics = (props) => {
    const intl = useIntl();
    const userProfile = useSelector(state => state.profile.profile);
    const locale = getLocale(userProfile.userLanguage);

    const stratumChartColors = ["#3eaee2", "#319fd2", "#2185b4", "#166f99", "#0e5a7e", "#093950"];
    const stratumChartColorsAlt = ["#FF5869", "#FF5869", "#FF5869", "#FF5869", "#FF5869", "#FF5869"];

    const tooltipRender = (tprops) => {
        if (tprops.point) {
            return tprops.point.dataItem.stat;
        }
    };

    const getStratumChartColor = (stratum) => {
        if (!stratum) {
            return stratumChartColors[0];
        }

        let colors = (stratum.noOfItemsAllowed <= stratum.noOfItemsRecorded) ? stratumChartColors : stratumChartColorsAlt;

        let i = Math.min(colors.length, Math.max(stratum.stratumNo, 1));
        return colors[i - 1];
    };

    const decimals = 2;

    const formattedRequiredTotalDeviationPercent = props.statistics?.differenceOfTotalValuesInPercentRequired != null
        ? props.statistics.differenceOfTotalValuesInPercentRequired.toLocaleString(locale) + '%'
        : '';

    const formattedTotalDeviationPercent = props.statistics?.differenceOfTotalValuesInPercent != null
        ? formatNumberWithDecimals(props.statistics.differenceOfTotalValuesInPercent, locale, decimals)
        : '';

    const formattedRequiredSamplingErrorPercent = props.statistics?.samplingErrorRequired != null
        ? props.statistics.samplingErrorRequired.toLocaleString(locale) + '%'
        : '';

    const formattedSamplingErrorPercent = props.statistics?.samplingError != null
        ? formatNumberWithDecimals(props.statistics.samplingError, locale, decimals)
        : '';

    const samplingErrorClassName = props.statistics?.relativeSamplingErrorThresholdHit
        ? 'sampling-error-hit'
        : ''

    const formattedEstimatedValue = props.statistics?.estimatedTotalStockValue != null
        ? formatNumberWithDecimals(props.statistics.estimatedTotalStockValue, locale, decimals)
        : '';

    const formattedBookValue = props.statistics?.actualBookValue != null
        ? formatNumberWithDecimals(props.statistics.actualBookValue, locale, decimals)
        : '';

    const formattedTotalDeviationValue = props.statistics?.differenceOfTotalValues != null
        ? formatNumberWithDecimals(props.statistics.differenceOfTotalValues, locale, decimals)
        : '';

    const stratumChartData = () => {
        let strats = props.statistics && props.statistics.stratums
            ? props.statistics.stratums.map((item) => ({
                stat: 'Stratum ' + item.stratumNo,
                count: item.noOfItemsInStratum,
                color: getStratumChartColor(item),
                randomSamplingCount: item.noOfItemsDrawn,
                totalValue: item.sumRetrievedRandSampActualValue,
                differencesPos: item.valueOfPosDifferences,
                differencesNeg: item.valueOfNegDifferences
            }))
            : [];

        if (strats.length > 0) {
            strats.push({
                stat: 'Complete Count Stratum',
                count: props.statistics.completeCountStratum.noOfItemsInStratum,
                color: getStratumChartColor(1),
                randomSamplingCount: props.statistics.completeCountStratum.noOfItemsDrawn,
                upperBoundary: props.statistics.completeCountStratum.upperBoundary,
                differencesPos: props.statistics.completeCountStratum.valueOfPosDifferences,
                differencesNeg: props.statistics.completeCountStratum.valueOfNegDifferences
            });
        }

        return strats.reverse();
    };

    const getCurrency = () => {
        if (props.statistics) {
            return props.statistics.currency;
        }
        return decodeURI('%E2%82%AC');
    };

    const getEstimationProcedure = () => {
        let procText = ''

        if (props.statistics && props.statistics.choosenEstimationprocedure) {
            procText = intl.formatMessage({ id: 'ReportingStatistics.EstimationProcedure_' + props.statistics.choosenEstimationprocedure }).toUpperCase();
        }

        return procText;
    };

    const [stratumDetailName, setStratumDetailName] = useState('');
    const [stratumDetailValue1, setStratumDetailValue1] = useState(null);
    const [stratumDetailValue2, setStratumDetailValue2] = useState(null);
    const [stratumDetailValue3, setStratumDetailValue3] = useState(null);

    const formattedStratumDetailValue1 = stratumDetailValue1 != null
        ? formatNumberWithDecimals(stratumDetailValue1, locale, decimals)
        : '';

    const formattedStratumDetailValue2 = stratumDetailValue2 != null
        ? formatNumberWithDecimals(stratumDetailValue2, locale, decimals)
        : '';

    const formattedStratumDetailValue3 = stratumDetailValue3 != null
        ? formatNumberWithDecimals(stratumDetailValue3, locale, decimals)
        : '';

    const updateStratumDetails = (dataItem) => {
        if (dataItem) {
            setStratumDetailName(dataItem.stat);
            setStratumDetailValue1(dataItem.differencesPos);
            setStratumDetailValue2(dataItem.differencesNeg);
            setStratumDetailValue3(dataItem.differencesPos + dataItem.differencesNeg);
        }
    };

    const stratumChartSeriesAreaClicked = (event) => {
        if (event) {
            updateStratumDetails(event.dataItem);
        }
    };

    useEffect(() => {
        let strats = stratumChartData();
        if (strats.length > 0) {
            updateStratumDetails(strats[strats.length - 1]);
        }
    }, [props.statistics]);


    return <Row className="import-result">
        <Col xs="6" className="text-right">
            <Row>
                <Col xs="6">
                    <Card className={(props.getTotalDeviationPercentClass())}>
                        <CardHeader>
                            <h5 className="card-category"><FormattedMessage id='ReportingStatistics.PassesUnder' />{formattedRequiredTotalDeviationPercent}</h5>
                            <h1>{formattedTotalDeviationPercent}
                            </h1>
                        </CardHeader>
                        <CardBody>
                            <FontAwesomeIcon icon={faBalanceScaleRight} />
                            &nbsp; <FormattedMessage id='ReportingStatistics.TotalDeviationPercent' />
                        </CardBody>
                    </Card>
                </Col>
                <Col xs="6">
                    <Card className={props.getRequiredSamplingErrorPercentClass()}>
                        <CardHeader>
                            <h5 className="card-category"><FormattedMessage id='ReportingStatistics.PassesUnder' />{formattedRequiredSamplingErrorPercent}</h5>
                            <h1>{formattedSamplingErrorPercent}
                            </h1>
                        </CardHeader>
                        <CardBody className={samplingErrorClassName}>
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                            &nbsp; <FormattedMessage id='ReportingStatistics.RelativeSamplingErrorPercent' />
                        </CardBody>
                    </Card>
                </Col>

                <Col xs="12">
                    <Card>
                        <CardHeader>
                            <h5 className="card-category">&nbsp;</h5>
                            <h1>{formattedEstimatedValue} <small>vs. {formattedBookValue}</small>
                            </h1>
                        </CardHeader>
                        <CardBody>
                            <FontAwesomeIcon icon={faSackDollar} />
                            &nbsp; <FormattedMessage id='ReportingStatistics.EstimatedVsAccountingValueIn' /> {getCurrency()}
                        </CardBody>
                    </Card>
                </Col>

                <Col xs="6">
                    <Card>
                        <CardHeader>
                            <h5>&nbsp;</h5>
                            <h1>{formattedTotalDeviationValue}</h1>
                        </CardHeader>
                        <CardBody>
                            <FontAwesomeIcon icon={faBalanceScaleRight} />
                            &nbsp; <FormattedMessage id='ReportingStatistics.TotalDeviationIn' /> {getCurrency()}
                        </CardBody>
                    </Card>
                </Col>
                <Col xs="6">
                    <Card>
                        <CardHeader>
                            <h5><FontAwesomeIcon icon={faInfoCircle} /></h5>
                            <h1>{getEstimationProcedure()}</h1>
                        </CardHeader>
                        <CardBody>
                            <FontAwesomeIcon icon={faCalculator} />
                            &nbsp; <FormattedMessage id='ReportingStatistics.EstimationProcedure' />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Col>

        <Col xs="4" >
            <Card className="stratum-chart">
                <IntlProvider locale={locale}>
                    <Chart
                        onSeriesClick={stratumChartSeriesAreaClicked}

                    >
                        <ChartArea background="transparent" />
                        <ChartTitle text={intl.formatMessage({ id: 'SamplingStatistics.StratumsChartTitle' })} />
                        <ChartSeries>
                            <ChartSeriesItem
                                type="funnel"
                                data={stratumChartData()}
                                categoryField="stat"
                                field="count"
                                colorField="color"
                                neckRatio="1.6"
                            >
                                <ChartSeriesLabels color="white" background="none" format="N0" />
                            </ChartSeriesItem>
                        </ChartSeries>
                        <ChartTooltip render={tooltipRender} />
                        <ChartLegend visible={false} />
                    </Chart>
                </IntlProvider>
            </Card>
        </Col>
        <Col xs="2" className="text-right">
            <Card className="compare-options">
                <CardHeader>
                    <h5 className="card-category">{stratumDetailName}</h5>
                    <h1>{stratumDetailValue1 != null ? '+' : ''}{formattedStratumDetailValue1}
                    </h1>
                </CardHeader>
                <CardBody>
                    <FontAwesomeIcon icon={faPlusCircle} />
                    &nbsp; <FormattedMessage id='ReportingStatistics.DeviationIn' /> {getCurrency()}
                </CardBody>
            </Card>
            <Card className="compare-options">
                <CardHeader>
                    <h5 className="card-category">{stratumDetailName}</h5>
                    <h1>{formattedStratumDetailValue2}
                    </h1>
                </CardHeader>
                <CardBody>
                    <FontAwesomeIcon icon={faMinusCircle} />
                    &nbsp; <FormattedMessage id='ReportingStatistics.DeviationIn' /> {getCurrency()}
                </CardBody>
            </Card>
            <Card className="compare-options">
                <CardHeader>
                    <h5 className="card-category">{stratumDetailName}</h5>
                    <h1>{formattedStratumDetailValue3}</h1>
                </CardHeader>
                <CardBody>
                    <FontAwesomeIcon icon={faBalanceScaleRight} />
                    &nbsp; <FormattedMessage id='ReportingStatistics.DeviationIn' /> {getCurrency()}
                </CardBody>
            </Card>
        </Col>
    </Row>
        ;
}

export default ReportingStatistics
