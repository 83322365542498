import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import { Label, Error } from "@progress/kendo-react-labels";
import { Col, Row, Button } from 'reactstrap';
import { FormattedMessage, useIntl } from "react-intl";
import validator from 'validator';
import ConfirmDialog from '../../../Shared/ConfirmDialog';
import { getQrCode } from '../../../../services/commonService';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

const DeviceCountListDetailsDialog = (props) => {
    const intl = useIntl();

    const emailAddressValidator = (value) => {
        if (!value) {
            return intl.formatMessage({ id: 'DeviceCountListDetailsDialog.EmailAddressRequired' });
        }
        return validator.isEmail(value) ? "" : intl.formatMessage({ id: 'DeviceCountListDetailsDialog.EmailAddressInvalid' });
    };

    const [emailAddress, setEmailAddress] = useState(props.device.emailAddress);
    const [isEmailAddressChanged, setIsEmailAddressChanged] = useState(false);
    const handleEmailAddressChange = (event) => {
        setEmailAddress(event.value);

        const isChanged = event.value !== props.device.emailAddress;
        setIsEmailAddressChanged(isChanged);
    };

    const [isEmailAddressTouched, setIsEmailAddressTouched] = useState(false);
    const handleEmailAddressBlur = () => {
        setIsEmailAddressTouched(true);
    };

    const areChanges = isEmailAddressChanged;

    const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);
    const [confirmDialogTitle, setConfirmDialogTitle] = useState('');
    const [confirmDialogDetail, setConfirmDialogDetail] = useState('');
    const [confirmDialogCallback, setConfirmDialogCallback] = useState(null);

    const handleConfirmDialogClose = (isConfirmed) => {
        setIsConfirmDialogVisible(false);
        if (isConfirmed) {
            if (confirmDialogCallback) {
                confirmDialogCallback.success();
            }
        }
        else {
            if (confirmDialogCallback && confirmDialogCallback.cancel) {
                confirmDialogCallback.cancel();
            }
        }
    };

    const handleClose = () => {
        if (areChanges) {
            setConfirmDialogTitle(<FormattedMessage id='Confirm.UnsavedChangesTitle' />);
            setConfirmDialogDetail(<FormattedMessage id='Confirm.UnsavedChangesText' />);
            setConfirmDialogCallback({
                success: () => {
                    props.onClose();
                }
            });
            setIsConfirmDialogVisible(true);
        }
        else {
            props.onClose();
        }
    };

    const handleSubmit = () => {
        const device = {
            ...props.device,
            emailAddress: emailAddress
        };
        props.onSubmit(device);
        saveDeviceName();
    };

    const handleActivate = () => {
        props.onActivate(props.device.id);
        saveDeviceName();
    };

    const [qrCode, setQrCode] = useState(null);
    useEffect(() => {
        props.incrementLoadingCount();
        getQrCode(
            props.device.token,
            qrCode => {
                props.decrementLoadingCount();
                setQrCode(qrCode);
            },
            props.handleError);
    }, []);

    const [selected, setSelected] = React.useState(0);
    const handleSelect = (e) => {
        setSelected(e.selected);
    };

    const [deviceName, setDeviceName] = useState(props.device.name);
    const handleDeviceNameChange = (event) => {
        setDeviceName(event.value);
    };

    const saveDeviceName = (shouldClose) => {
        props.saveDeviceName(props.device.name, deviceName);
        if (shouldClose) {
            props.onClose();
        }
    };

    const deviceNameValidator = (value) => {
        if (props.deviceNameValidator) {
            return props.deviceNameValidator(value, props.device);
        }
        return '';
    };

    const isDeviceNameValid = deviceNameValidator(deviceName) === '';
    const isSaveDisabled = !isDeviceNameValid || deviceName === props.device.name;
    const isSendingDisabled = !isDeviceNameValid || (emailAddressValidator(emailAddress) !== '');
    const isActivateDisabled = !isDeviceNameValid || props.device.isActive;

    const handleSave = () => {
        props.saveDeviceName(props.device.name, deviceName);
        props.onClose();
    };

    return (
        <div>
            <Prompt
                when={areChanges}
                message={JSON.stringify({
                    header: "" + intl.formatMessage({ id: 'Confirm.UnsavedChangesTitle' }),
                    content: "" + intl.formatMessage({ id: 'Confirm.UnsavedChangesText' }),
                    cancel: "" + intl.formatMessage({ id: 'Control.Cancel' }),
                    confirm: "" + intl.formatMessage({ id: 'Control.Confirm' })
                })}
            />

            <Dialog title={<div className="deviceNameEditor"><Input
                name={"deviceName"}
                id={"deviceName"}
                value={deviceName}
                maxLength={255}
                onChange={handleDeviceNameChange}
            />
            <Error>{deviceNameValidator(deviceName)}</Error>
            </div>}>
                <TabStrip selected={selected} onSelect={handleSelect}>
                    <TabStripTab title={intl.formatMessage({ id: 'DeviceCountListDetailsDialog.TitleTab1' })}>
                        <div>
                            {qrCode && (
                                <Row>
                                    <Col xs="12" className={"qr-code-container"}>
                                        <img
                                            alt=''
                                            src={`data:image/png;base64,${qrCode}`}
                                            width={200}
                                            className={props.device.isActive ? '' : 'qr-code-disabled'}
                                        />
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                <Col xs="12" className="text-center">
                                    <label>{props.device.token}</label>
                                </Col>
                            </Row>
                        </div>
                    </TabStripTab>
                    <TabStripTab title={intl.formatMessage({ id: 'DeviceCountListDetailsDialog.TitleTab2' })}>
                        <div>
                            <Row>
                                <Col xs="12">
                                    <div className="mb-3">
                                        <Label>{intl.formatMessage({ id: 'DeviceCountListDetailsDialog.EmailAddress' }) + " *"}</Label>
                                        <Input
                                            id={"emailAddress"}
                                            name={"emailAddress"}
                                            value={emailAddress}
                                            maxLength={256}
                                            onChange={handleEmailAddressChange}
                                            onBlur={handleEmailAddressBlur}
                                        />
                                        {isEmailAddressTouched && (
                                            <Error>{emailAddressValidator(emailAddress)}</Error>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </TabStripTab>
                </TabStrip>
                                                

                <DialogActionsBar>
                    <div className="k-form-buttons k-justify-content-stretch">
                        <Button
                            type={"button"}
                            color="secondary"
                            onClick={handleClose}
                        >
                            <FormattedMessage id='Control.Close' />
                        </Button>
                        <Button
                            type={"button"}
                            color="secondary"
                            onClick={handleSave}
                            disabled={isSaveDisabled}
                        >
                            <FormattedMessage id='Control.Save' />
                        </Button>
                        {selected === 0 && (
                            <Button
                                type={"button"}
                                color="primary"
                                onClick={handleActivate}
                                disabled={isActivateDisabled}
                            >
                                <FormattedMessage id='Control.Activate' />
                            </Button>
                        )}
                        {selected === 1 && (
                            <Button
                                type={"button"}
                                color="primary"
                                onClick={handleSubmit}
                                disabled={isSendingDisabled}
                            >
                                <FormattedMessage id={props.device.isActive ? 'DeviceCountListDetailsDialog.Send' : 'DeviceCountListDetailsDialog.SendAndActivate'} />
                            </Button>
                        )}
                    </div>
                </DialogActionsBar>
            </Dialog>

            <ConfirmDialog
                visible={isConfirmDialogVisible}
                onClose={handleConfirmDialogClose}
                negative={<FormattedMessage id='Control.Cancel' />}
                positive={<FormattedMessage id='Control.Confirm' />}
                title={confirmDialogTitle}
                detail={confirmDialogDetail}
            />
        </div>
    );
};

export default DeviceCountListDetailsDialog;
