const getColumnValueFromFeedbackItem = (item, columnIndex, fId) => {
    let value = null;

    if (columnIndex < item.uniqueId.length) {
        value = item.uniqueId[columnIndex];
    }
    else {
        switch (fId) {
            case window.enumFields.ItemName:
                value = item.description;
                break;
            case window.enumFields.QuantityUnit:
                value = item.quantityUnit;
                break;
            default:
        }
    }
    return value;
}

export {
    getColumnValueFromFeedbackItem
}