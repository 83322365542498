import React, { useState, useEffect } from 'react';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { TextArea, Input } from "@progress/kendo-react-inputs";
import { useNotification } from './Notifications/NotificationProvider';
import { NotificationTypes } from './Notifications/Notification';
import { sendEmailToSupport, sendInventoryEmailToSupport } from '../../services/emailService';
import { FormattedMessage, useIntl } from 'react-intl';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { Col, Row, Button, Label } from 'reactstrap';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Upload } from '@progress/kendo-react-upload';
import { useExceptionDialog } from '../Shared/ExceptionDialog/ExceptionDialogProvider';
import { useLoading } from '../Shared/LoadingContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserHeadset } from '@fortawesome/pro-light-svg-icons'


export const SupportType = {
    Default: 0,
    Inventory: 1
}

const CustomTitleBar = () => {
    return (
        <h3>
            <FormattedMessage id='ContactSupport.ContactSupport' />
            &nbsp;<small>supportstatcontrol@remira.com</small>
        </h3>
    );
};

function ContactSupport(props) {
    const intl = useIntl();
    const { setLoading } = useLoading();

    const allowedExtensions = [".docx", ".svg", ".png", ".xls", ".jpg", ".png", ".jpeg", ".pdf", ".doc", ".xlsx"];
    const [visible, setVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [selectedFiles, setSelectedFiles] = React.useState([]);
    const [allFilesAreValid, setAllFilesAreValid] = useState(true);
    const [filesValidationMessage, setFilesValidationMessage] = useState('');
    const [subject, setSubject] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [priority, setPriority] = useState(null);

    const isButtonVisible = props.isButtonVisible != null ? props.isButtonVisible === true : true;
    const isGeneralEnquiryVisible = props.isGeneralEnquiryVisible != null ? props.isGeneralEnquiryVisible === true : true;

    useEffect(() => {
        if (!isButtonVisible) {
            toggleDialog();
        }
    }, []);

    const handleFileSelected = e => {
        addFilesToSelectetedOnes(e.newState);
    };

    const handleFileRemoved = e => {
        addFilesToSelectetedOnes(e.newState);
    };

    const addFilesToSelectetedOnes = (files) => {
        setAllFilesAreValid(true);
        setFilesValidationMessage("");
        if (files.length > 0) {

            let importFiles = [];
            files.forEach(element => {
                if (!allowedExtensions.some((item) => item === element.extension)) {
                    setAllFilesAreValid(false);
                }
                importFiles.push(element);
            });
            setSelectedFiles(importFiles);
            validateFiles(importFiles);
        }
        else {
            setSelectedFiles([]);
        }
    }

    const validateFiles = (files) => {
        let groups = Object.values(files.reduce((grouping, item) => {
            grouping[item.name] = [...grouping[item.name] || [], item]
            return grouping
        }, {}));
        if (groups.find(element => element.length > 1)) {
            setFilesValidationMessage(<FormattedMessage id='ContactSupport.DuplicatedFiles' />);
            setAllFilesAreValid(false);
        }
    }

    const priorities = [
        intl.formatMessage({ id: "ContactSupport.Urgent" }),
        intl.formatMessage({ id: "ContactSupport.NotUrgent" })
    ];

    const [selected, setSelected] = React.useState(0);

    const handleSelect = e => {
        setSelected(e.selected);
    };

    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const pageId = 'ContactSupport';

    const showSuccess = (success) => {
        dispatchNotification({
            pageId: pageId,
            type: NotificationTypes.success,
            message: success
        });
    };

    const getFileNames = () => {
        if (selectedFiles.length > 0) {
            return selectedFiles;//.map(element => element.name);
        }
        else {
            return [];
        }
    }

    const handleError = (errorMessage, showNotif = true) => {
        setLoading(false);
        if (showNotif) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            });
        }
    };

    const contactButtonClicked = e => {
        toggleDialog();
    };

    const toggleDialog = () => {
        setVisible(!visible);
        setMessage('');
        setSelectedFiles([]);
        setAllFilesAreValid(true);
        setFilesValidationMessage("");
        setSubject('');
        setPhoneNumber('');
        setPriority(null);
    };

    const handleSubmit = () => {
        setLoading(true);
        const files = selectedFiles.map(element => element.getRawFile());
        const successCallback = () => {
            setLoading(false);
            showSuccess(<FormattedMessage id='ContactSupport.EmailSent' />);
            toggleDialog();
            if (props.onClosed) {
                props.onClosed();
            }
        };

        if (!props.supportType || props.supportType === SupportType.Default) {
            sendEmailToSupport(
                {
                    body: message,
                    subject: subject,
                    phoneNumber: phoneNumber,
                    priority: priority
                },
                files,
                successCallback,
                handleError);
        }
        else if (props.supportType === SupportType.Inventory) {
            sendInventoryEmailToSupport(
                {
                    body: message,
                    ...props.payload
                },
                [],
                successCallback,
                handleError);
        }
    };

    const handleClose = () => {
        toggleDialog();
        if (props.onClosed) {
            props.onClosed();
        }
    };

    return (
        <div>
            {isButtonVisible && (
                <Button
                    color="link"
                    onClick={contactButtonClicked}
                    block
                >
                    <FormattedMessage id='ContactSupport.ContactSupport' />
                </Button>
            )}
            {visible && (
                <Dialog
                    title={<CustomTitleBar />}
                    onClose={handleClose}
                    className="customer-support"
                >
                    <TabStrip selected={selected} onSelect={handleSelect}>
                        <TabStripTab title={intl.formatMessage({ id: "ContactSupport.ProblemFixing" })}>
                            <Label><FormattedMessage id='ContactSupport.Text' /></Label>
                            <TextArea
                                value={message}
                                onChange={e => setMessage(e.value)}
                                margin={20}
                                rows={3}
                                fillMode={null}
                                placeholder={intl.formatMessage({ id: "ContactSupport.ProblemExplanation" })}
                            />
                            <div className="k-form-field">
                                <div className="drop-label"><FormattedMessage id='ContactSupport.ResponseTime' /></div>
                                <DropDownList
                                    data={priorities}
                                    value={priority}
                                    onChange={e => setPriority(e.value)}
                                    defaultItem={intl.formatMessage({ id: "ContactSupport.HowUrgentIsTheIssue" })}
                                />
                            </div>
                            <div className="k-form-field">
                                <div className="drop-label"><FormattedMessage id='ContactSupport.Upload' /></div>
                                <Upload
                                    batch={false}
                                    multiple={true}
                                    restrictions={{
                                        allowedExtensions: allowedExtensions,
                                        maxFileSize: 5242880,
                                    }}
                                    defaultFiles={getFileNames()}
                                    withCredentials={false}
                                    autoUpload={false}
                                    showActionButtons={false}
                                    accept={allowedExtensions.join(',')}
                                    saveUrl={""}
                                    removeUrl={""}
                                    customNote={"note"}
                                    onAdd={handleFileSelected}
                                    onRemove={handleFileRemoved}

                                />
                                {filesValidationMessage && <span class="k-file-validation-message k-text-error">{filesValidationMessage}</span>}
                            </div>
                        </TabStripTab>
                        {isGeneralEnquiryVisible && (
                            <TabStripTab title={intl.formatMessage({ id: "ContactSupport.GeneralEnquiry" })}>
                                <div className="drop-label"><FormattedMessage id='ContactSupport.SubjectLine' /></div>
                                <Input
                                    value={subject}
                                    onChange={e => setSubject(e.value)}
                                />
                                <div className="k-form-field">
                                    <Label><FormattedMessage id='ContactSupport.Text' /></Label>
                                    <TextArea
                                        value={message}
                                        onChange={e => setMessage(e.value)}
                                        margin={20}
                                        rows={3}
                                        fillMode={null}
                                        placeholder={intl.formatMessage({ id: "ContactSupport.ProblemExplanation" })}
                                    />
                                </div>
                            </TabStripTab>
                        )}
                        <TabStripTab title={intl.formatMessage({ id: "ContactSupport.CallUs" })}>
                            <Row>
                                <Col xs="12" className="call">
                                    <div className="k-form-field">
                                        <FontAwesomeIcon icon={faUserHeadset} />
                                        <br /><br />
                                        <p><FormattedMessage id='ContactSupport.Phone' /></p>
                                        <p><FormattedMessage id='ContactSupport.OfficeHours' /></p>
                                    </div>
                                </Col>
                                <Col xs="12">
                                    <div className="k-form-field">
                                        <div className="drop-label"><FormattedMessage id='ContactSupport.LeaveYourPhoneNumber' /></div>
                                        <Input
                                            value={phoneNumber}
                                            onChange={e => setPhoneNumber(e.value)}
                                        />
                                    </div>
                                    <div className="k-form-field">
                                        <Label><FormattedMessage id='ContactSupport.Text' /></Label>
                                        <TextArea
                                            value={message}
                                            onChange={e => setMessage(e.value)}
                                            margin={20}
                                            rows={3}
                                            fillMode={null}
                                            placeholder={intl.formatMessage({ id: "ContactSupport.ReplyTime" })}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </TabStripTab>
                    </TabStrip>

                    <DialogActionsBar>
                        <div className="k-form-buttons k-justify-content-stretch">
                            <Button
                                color="secondary"
                                onClick={handleClose}
                            >
                                <FormattedMessage id='Control.Cancel' />
                            </Button>
                            <Button
                                color="primary"
                                disabled={message.length === 0 || !allFilesAreValid}
                                onClick={handleSubmit}
                            >
                                <FormattedMessage id='ContactSupport.Send' />
                            </Button>
                        </div>
                    </DialogActionsBar>
                </Dialog>
            )}
        </div>
    );
}

export default ContactSupport
