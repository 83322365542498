import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { Upload } from '@progress/kendo-react-upload';
import { FormattedMessage, useIntl } from 'react-intl';
import { trigger, on, off } from '../../../../actions/events'
import ConfirmDialog from '../../../Shared/ConfirmDialog';
import { useNotification } from '../../../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../../../Shared/Notifications/Notification';
import {
    uploadInventoryFileChunk,
    finalizeInventoryFileChunkUpload,
    updateImportUploadedFileData,
    getStockDataColumnMapping,
    deleteTempFile,
    getProgramLockState
} from '../../../../services/inventoryService';
import { WizzardContext, WIZZARD_ACTIONS } from "../WizzardContext";
import { wizardEvents, wizardStep } from '../Wizard';
import { v4 as uuidv4 } from "uuid";
import { useLoading } from '../../../Shared/LoadingContext';
import { useSelector } from 'react-redux';
import { useExceptionDialog } from '../../../Shared/ExceptionDialog/ExceptionDialogProvider';
import { importStatusMessage } from './ImportResult';
import LongRunningBackgroundTaskIndicatorDialog from '../../../Shared/LongRunningBackgroundTaskIndicatorDialog';
import { cancelCountingInProgress } from '../../../../services/countService';
import { UserContext } from '../../../UserContext';
import { getColumnSeparators } from '../Stock Data/StockData';

const mapToUniqueId = [];
const mapToAmount = [];
const mapToPrice = [];
const mapToQuantityUnit = [];
const mapToPriceUnit = [];
const mapToArticleDescription = [];
const mapToAdditionalFields = [];

export const importUpdateEvents = {
    selectedFileInfoChanged: "importUpdate:selectedFileInfoChanged"
}

const note = <span><FormattedMessage id='StockData.FilesAllowed' /></span>;
const FILE_SLICE_SIZE_KB = 5;
const MINIMUM_REQUIRED_COLUMN_COUNT = 3;
const FILE_UPLOAD_CHUNK_SIZE_MB = 1;

const ImportUpdate = (props) => {
    const wizzardContext = React.useContext(WizzardContext);

    const userContext = React.useContext(UserContext);
    const isUserAdmin = userContext.userGlobal.userInfo && (userContext.userGlobal.userInfo.isStatcontrolAdmin || userContext.userGlobal.userInfo.isAdminUser);

    const selectedPopulation = useSelector(rxState => rxState.dashboard.population);
    const currentCustomer = useSelector(rxState => rxState.dashboard.customer);

    const importProgress = useRef(0);

    const [selectedFileInfo, setSelectedFileInfo] = useState({ importFile: {}, columnHeaders: [] })
    const [showProgress, setShowProgress] = useState(false)
    const [progress, setProgress] = useState(0)
    const [progressMessage, setProgressMessage] = useState(0)
    const pageId = 'pageInventoryWizard.ImportUpdate';
    const chunkSize = 1048576 * FILE_UPLOAD_CHUNK_SIZE_MB;
    const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0);
    const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize);
    const [chunkCount, setChunkCount] = useState(0);
    const [chunkCounter, setChunkCounter] = useState(1);
    const [fileSize, setFileSize] = useState(0);
    const [fileGuid, setFileGuid] = useState("");
    const [fileToBeUpload, setFileToBeUpload] = useState({});
    const [defaultInterfaceConfiguration, setDefaultInterfaceConfiguration] = React.useState(null);
    const isSequential = wizzardContext.wizzardGlobal.Import.InventoryMethod === window.enumInventoryMethod.Staseq;

    const { setLoading } = useLoading();
    const [notAllowedByLicenseVisibility, setNotAllowedByLicenseVisibility] = useState(false)
    const dispatchExceptionDialog = useExceptionDialog();

    const isCustomerWithoutLicense = currentCustomer.activeLicense.isFreeTrial;
    const [ setIsLicenseVisible] = React.useState(false);

    const intl = useIntl();

    const [loadingCount, setLoadingCount] = React.useState(0);
    useEffect(() => {
        setLoading(loadingCount > 0);
    }, [loadingCount]);
   

    const incrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount + 1);
    };

    const decrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount - 1);
    }

    const handleError = (errorMessage, showNotif = true) => {
        decrementLoadingCount();
        if (showNotif) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            })
        }
    };

    const [state, setState] = React.useState({
        importedColumns: selectedFileInfo.columnHeaders || [],
        importFile: selectedFileInfo.importFile,
        mapToUniqueId,
        mapToAmount,
        mapToPrice,
        mapToQuantityUnit,
        mapToPriceUnit,
        mapToArticleDescription,
        mapToAdditionalFields,
        draggedItem: {},
        test: []
    });

    useEffect(() => {
        if (wizzardContext.wizzardGlobal.CsvMapper.importedColumns.length === 0
            && wizzardContext.wizzardGlobal.CsvMapper.mapToUniqueId.length === 0
            && (wizzardContext.wizzardGlobal.CsvMapper.mapToPrice.length === 0 || isSequential)
            && wizzardContext.wizzardGlobal.CsvMapper.mapToAmount.length === 0) {

            incrementLoadingCount();
            getStockDataColumnMapping(selectedPopulation && selectedPopulation.populationId,
                data => {
                    decrementLoadingCount();

                    state.mapToUniqueId = [];
                    state.mapToAmount = [];
                    state.mapToPrice = [];
                    state.mapToQuantityUnit = [];
                    state.mapToPriceUnit = [];
                    state.mapToArticleDescription = [];
                    state.mapToAdditionalFields = [];

                    data.uniqueId.forEach((item) => {
                        state.mapToUniqueId.push({
                            ColumnName: item.columnName,
                            ColumnNo: item.columnNo,
                            selected: false,
                            dataCollection: 'importedColumns'
                        })
                    });

                    state.mapToAmount.push({
                        ColumnName: data.quantity.columnName,
                        ColumnNo: data.quantity.columnNo,
                        selected: false,
                        dataCollection: 'importedColumns'
                    })

                    state.mapToPrice.push({
                        ColumnName: data.singlePrice.columnName,
                        ColumnNo: data.singlePrice.columnNo,
                        selected: false,
                        dataCollection: 'importedColumns'
                    })

                    state.mapToQuantityUnit.push({
                        ColumnName: data.quantityUnit.columnName,
                        ColumnNo: data.quantityUnit.columnNo,
                        selected: false,
                        dataCollection: 'importedColumns'
                    })

                    state.mapToPriceUnit.push({
                        ColumnName: data.priceUnit.columnName,
                        ColumnNo: data.priceUnit.columnNo,
                        selected: false,
                        dataCollection: 'importedColumns'
                    })

                    state.mapToArticleDescription.push({
                        ColumnName: data.itemName.columnName,
                        ColumnNo: data.itemName.columnNo,
                        selected: false,
                        dataCollection: 'importedColumns'
                    })

                    data.additionalColumns.forEach((item) => {
                        state.mapToAdditionalFields.push({
                            ColumnName: item.columnName,
                            ColumnNo: item.columnNo,
                            selected: false,
                            dataCollection: 'importedColumns'
                        })
                    });

                    let tempState = state;
                    setState(tempState);

                },
                handleError);
        }
        else {
            setState(wizzardContext.wizzardGlobal.CsvMapper);
        }

        setDefaultInterfaceConfiguration(wizzardContext.wizzardGlobal.InterfaceConfiguration);

    }, []);

    const displayImportFailedNotification = () => {
        let failMsg = 'Error uploading inventory file';
        if (importProgress.current > 0)
            failMsg = 'Error processing uploaded data';

        notifyError(failMsg);
    }

    const dispatchNotification = useNotification();
    const notifyError = (errorMessage) => dispatchNotification({
        type: NotificationTypes.error,
        pageId: 'pageInventoryWizard.ImportUpdate',
        message: errorMessage
    });


    const uploadRef = useRef();

    const handleFileSelected = e => {
        let target = e.target;
        if (target.state.files.length > 0) {
            readSelectedFile();
        }
        props.setContinueBtnDisabled(true);
    };

    const clearFileSelectionInfo = () => {
        setSelectedFileInfo({ importFile: {}, columnHeaders: [] });
        uploadRef.current.setState({ ...uploadRef.current.state, files: [] });
    }

    const handleFileRemoved = e => {
        clearFileSelectionInfo();
        props.setContinueBtnDisabled(false);
    };

    const readSelectedFile = () => {
        let fr = new FileReader();
        fr.onloadend = function (evt) {
            let currentSeparator =
                defaultInterfaceConfiguration?.initialData?.columnSeparator !== window.enumColumnSeparator.None ? ";" : "";

            if (defaultInterfaceConfiguration?.initialData) {
                let columnSeparators = getColumnSeparators(defaultInterfaceConfiguration.initialData.customColumnSeparator);

                if (defaultInterfaceConfiguration.initialData.columnSeparator !== window.enumColumnSeparator.None) {
                    currentSeparator = columnSeparators
                        .find(x => x.value === defaultInterfaceConfiguration.initialData.columnSeparator)
                        .textValue;
                }
            }

            if (evt.target.readyState === FileReader.DONE) {
                let columnHeaders = [];
                let needHeaders = currentSeparator && currentSeparator.length > 0;
                if (needHeaders) {
                    columnHeaders = evt.target.result.split('\r\n')[0].split(currentSeparator)
                        .map((headerText, index) => ({ ColumnName: headerText.trim(), ColumnNo: index, selected: false }));
                }

                let selectedFile = { importFile: {}, columnHeaders: [] };
                if (!needHeaders || columnHeaders.length >= MINIMUM_REQUIRED_COLUMN_COUNT)
                    selectedFile = { importFile: uploadRef.current.state.files[0].getRawFile(), columnHeaders: columnHeaders };
                else {
                    uploadRef.current.setState({ ...uploadRef.current.state, files: [] });
                    notifyError(<FormattedMessage id="StockData.WrongFileFormat" />);
                    props.setContinueBtnDisabled(false);
                }
                
                setSelectedFileInfo(selectedFile);
            }
        };

        // need only the first line of file containing column names, so read only the first slice big enough to contain the first line
        let blob = uploadRef.current.state.files[0].getRawFile().slice(0, 1024 * FILE_SLICE_SIZE_KB);
        fr.readAsText(blob);
    }

    const removeNotification = () => {
        dispatchNotification({
            remove: true,
            pageId: pageId
        });
    };

    const uploadInventoryFile = useCallback(() => {
        const resetChunkProperties = () => {
            setShowProgress(true)
            setProgressMessage(<FormattedMessage id='CsvMapper.WaitForUploadInventoryDataFile' />)
            setProgress(0)
            setChunkCounter(1)
            setBeginingOfTheChunk(0)
            setEndOfTheChunk(chunkSize)
        }

        resetChunkProperties();
        const _filesize = selectedFileInfo.importFile.size || 0;
        setFileSize(_filesize);
        const _totalCount = _filesize % chunkSize === 0 ? _filesize / chunkSize : Math.floor(_filesize / chunkSize) + 1;
        setChunkCount(_totalCount)
        setFileToBeUpload(selectedFileInfo.importFile)
        if (_filesize > 0) {
            trigger(wizardEvents.validStateOfCurrentStepChanged, { step: wizardStep.ImportUpdate, isValid: false });
            const _fileID = uuidv4() + "." + selectedFileInfo.importFile.name.split('.').pop();
            setFileGuid(_fileID);
        }
    }, [chunkSize, selectedFileInfo.importFile]);

    const continueButtonClicked = useCallback((event) => {
        removeNotification();

        if (event.detail === wizardStep.ImportUpdate) {
            uploadInventoryFile();
        }
    }, [uploadInventoryFile])

    useEffect(() => {
        on(wizardEvents.continueButtonClicked, continueButtonClicked);

        return () => {
            off(wizardEvents.continueButtonClicked, continueButtonClicked);
        }
    }, [continueButtonClicked]);

    const getColumnMapping = () => {
        return {
            UniqueId: state.mapToUniqueId,
            Quantity: state.mapToAmount[0],
            SinglePrice: state.mapToPrice[0],
            QuantityUnit: state.mapToQuantityUnit[0],
            PriceUnit: state.mapToPriceUnit[0],
            ItemName: state.mapToArticleDescription[0],
            AdditionalColumns: state.mapToAdditionalFields
        }
    }

    const importUploadedData = async () => {
        setLoading(true);
        setProgressMessage(<FormattedMessage id='CsvMapper.WaitForImportingUploadedData' />)
        const response = await updateImportUploadedFileData(fileGuid, selectedFileInfo.importFile.name, getColumnMapping(), selectedPopulation ? selectedPopulation.populationId : null);
        const data = response.data;
        setLoading(false);
        if (!data.succeeded) {
            notifyError(<FormattedMessage id='CsvMapper.InventoryFileImportError' />);
            if (data.errors)
                data.errors.map(item => console.log(item));            
            return { succeeded: false }
        }
        else {
            getCurrentProgramLockState();
            return { succeeded: true }
        }
    }

    const fileUpload = useCallback(async (counter) => {

        const uploadCompleted = async () => {
            setLoading(true);
            const response = await finalizeInventoryFileChunkUpload(fileGuid, currentCustomer.customerId, selectedPopulation.populationId);
            setLoading(false);
            const data = response.data;
            if (data.succeeded) {
                importProgress.current = 2;
                return importUploadedData();
            }
            else if (data.message === "NotAllowedByLicense") {
                setNotAllowedByLicenseVisibility(true);
                return { succeeded: false };
            }
            else {
                if (data.errors)
                    data.errors.map(item => console.log(item));
                return { succeeded: false };
            }
        }

        const uploadChunk = async (chunkParam) => {
            try {
                const response = await uploadInventoryFileChunk(chunkParam, counter, fileGuid, selectedPopulation.populationId);
                const data = response.data;
                if (data.succeeded) {
                    let percentage = (counter / chunkCount) * 100;
                    setProgress(percentage);
                    setBeginingOfTheChunk(endOfTheChunk);
                    setEndOfTheChunk(endOfTheChunk + chunkSize);
                    if (counter === chunkCount) {
                        console.log('Inventory file upload is completed, chunk count:', counter);
                        importProgress.current = 1;
                        const importResult = await uploadCompleted();
                        if (importResult.succeeded) {
                            console.log('Uploaded inventory file processing is successfully completed');
                        }
                        return importResult;
                    }
                    return { succeeded: true };
                } else {
                    console.log('Error Occurred:', data.message);
                    if (data.errors)
                        data.errors.map(item => console.log(item));
                    return { succeeded: false };
                }
            } catch (error) {
                setLoading(false);
                console.log('error', error);
                dispatchExceptionDialog({ pageId: pageId, message: error });
                displayImportFailedNotification();
                setShowProgress(false);
                return { succeeded: false };
            }
        }

        if (counter <= chunkCount) {
            let chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
            const result = await uploadChunk(chunk);
            if (result.succeeded) {
                if (counter < chunkCount)
                    setChunkCounter(counter + 1);
            }
        }
    }, [beginingOfTheChunk, endOfTheChunk, chunkCount, chunkSize, fileGuid, fileToBeUpload, setProgress, setProgressMessage, selectedFileInfo.importFile.name,
        state.importedColumns, state.mapToAmount, state.mapToArticleDescription, state.mapToPrice, state.mapToPriceUnit, state.mapToQuantityUnit,
        state.mapToUniqueId, state.mapToAdditionalFields]);

    useEffect(() => {
        if (fileSize > 0 && chunkCounter <= chunkCount) {
            fileUpload(chunkCounter);
        }
    }, [chunkCounter, fileSize, chunkCount]);

    const onNotAllowedByLicenseDialog = async (isConfirmed) => {
        setNotAllowedByLicenseVisibility(false);

        if (isConfirmed) {
            importProgress.current = 2;
            await importUploadedData();
        }
        else {
            setLoading(true);
            const response = await deleteTempFile(fileGuid, currentCustomer.customerId, selectedPopulation.populationId);
            setLoading(false);
            setShowProgress(false);
            const data = response.data;

            if (data.succeeded) {
                setProgress(0)
                setChunkCounter(1)
                setChunkCount(0)
            }
            else {
                if (data.errors)
                    data.errors.map(item => console.log(item));
                return { succeeded: false };
            }
        }
    };

    const onNotAllowedByLicenseDialogNeutral = async () => {
        setNotAllowedByLicenseVisibility(false);
        setIsLicenseVisible(true)
    };


    const getNotAllowedByLicenseVisibilityDetail = () => {
        const result = isUserAdmin
            ? intl.formatMessage({ id: 'CsvMapper.NumberOfPositionsExceededAdmin' })
            : intl.formatMessage({ id: 'CsvMapper.NumberOfPositionsExceededNonAdmin' });
        return result + (!isCustomerWithoutLicense ? (" " + intl.formatMessage({ id: 'CsvMapper.DoYouWantToContinueTheImportWithYourCurrentSubscription' })) : "");
    };

    const importInProgress = wizzardContext.wizzardGlobal.Import.IsBackgroundTaskRunning;

    const cancelButtonClicked = (populationId) => {
        cancelCountingInProgress(populationId,
            () => { console.log('Counting cancellation initiated') },
            error => { dispatchExceptionDialog({ pageId: pageId, message: error }); }
        );
    }

    const getCurrentProgramLockState = () => {
        setLoading(true);
        getProgramLockState(selectedPopulation && selectedPopulation.populationId,
            data => {
                setLoading(false);
                if (data.succeeded) {
                    console.log('ProgramLockState:', data.data.programLockState);
                    wizzardContext.dispatchWizzardGlobal({
                        type: WIZZARD_ACTIONS.SetProgramLockState,
                        payload: {
                            ProgramLockState: window.enumProgramLockState.ImportCountQuantitiesStarted,
                            BackgroundTaskStatus: window.enumBackgroundTaskStatus.ImportCountQuantitiesStarted,
                            IsBackgroundTaskRunning: data.data.isBackgroundTaskRunning,
                            IsBackgroundTaskFinishedWithError: data.data.isBackgroundTaskFinishedWithError,
                            LastError: data.data.lastError,
                            FirstMissingFeedbackPageNr: data.data.firstMissingFeedbackPageNr,
                            WaitingForErp: data.data.waitingForErp,
                            CountingOption: data.data.countingOption
                        }
                    });
                    if (data.data.isBackgroundTaskFinishedWithError) {
                        notifyError(importStatusMessage(window.enumBackgroundTaskStatus.ImportCountQuantitiesFailed));
                    }
                }
                else {
                    notifyError(<FormattedMessage id="Wizard.Error.ProgramLockState" />);
                }
            },
            error => {
                setLoading(false);
                notifyError(<FormattedMessage id="Wizard.Error.ProgramLockState" />);
                console.log('error', error);
                dispatchExceptionDialog({ pageId: pageId, message: error });
                wizzardContext.dispatchWizzardGlobal({ type: WIZZARD_ACTIONS.SetProgramLockState, payload: { ProgramLockState: window.enumProgramLockState.Unknown, BackgroundTaskStatus: window.enumBackgroundTaskStatus.NoBackgroundTaskRunning } });
            }
        );
    }

    const statusMessageFromProgramLockState = () => {
        let text = '';
        switch (wizzardContext.wizzardGlobal.Import.ProgramLockState) {
            case window.enumProgramLockState.InitialDataLoadingStarted:
                text = intl.formatMessage({ id: 'ImportResult.ImportStatus.DataImportInProgress' });
                break;
            case window.enumProgramLockState.InitialDataLoaded:
                text = intl.formatMessage({ id: 'ImportResult.ImportStatus.DataImportSucceeded' });
                break;
            case window.enumProgramLockState.StratumsCreated:
                text = intl.formatMessage({ id: 'ImportResult.ImportStatus.ReportGenerationInProgress' });
                break;
            case window.enumProgramLockState.CountListCreationStarted:
                text = intl.formatMessage({ id: 'ImportResult.ImportStatus.CreateCountListInProgress' });
                break;
            default:
                text = wizzardContext.wizzardGlobal.Import.ProgramLockState < window.enumProgramLockState.InitialDataLoaded ? intl.formatMessage({ id: 'ImportResult.ImportStatus.DataImportInProgress' }) : '';
        }
        return text;
    }

    const importTaskFinished = inventoryImportStatus => {

        removeNotification();

        let successStates = [window.enumBackgroundTaskStatus.DataImportSucceeded];
        let success = successStates.includes(inventoryImportStatus);

        wizzardContext.dispatchWizzardGlobal({
            type: WIZZARD_ACTIONS.SetProgramLockState,
            payload: {
                ...wizzardContext.wizzardGlobal.Import,
                IsBackgroundTaskRunning: false,
                IsBackgroundTaskFinishedWithError: false,
            }
        });

        if (success) {
            trigger(wizardEvents.stepFinalized, { step: wizardStep.ImportUpdate, isValid: success, goToStep: wizardStep.CountListParameters });
        }
        else {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: importStatusMessage(inventoryImportStatus)
            });
        }
    }

    return (
        <div>
            <ConfirmDialog
                visible={notAllowedByLicenseVisibility}
                onClose={onNotAllowedByLicenseDialog}
                onNeutral={onNotAllowedByLicenseDialogNeutral}
                negative={isCustomerWithoutLicense ? <FormattedMessage id="Control.Ok" /> : <FormattedMessage id="Control.Close" />}
                positive={!isCustomerWithoutLicense ? <FormattedMessage id="Control.Continue" /> : ""}
                neutral={wizzardContext.wizzardGlobal.SupportData && wizzardContext.wizzardGlobal.SupportData.inventoryType === window.enumInventoryType.PerpetualInventorySample ? <FormattedMessage id="Control.Upgrade" /> : null}
                title={<FormattedMessage id="CsvMapper.WarningConfirmationTitle" />}
                detail={getNotAllowedByLicenseVisibilityDetail()}
                positiveDisabled={isCustomerWithoutLicense}
                width={300}
            />
            {importInProgress &&
                <LongRunningBackgroundTaskIndicatorDialog visible={importInProgress}
                    title={<FormattedMessage id='ImportResult.ImportInProgress' />}
                    waitMessage={<FormattedMessage id='ImportResult.ImportStatus.Wait' />}
                    openingStatusText={statusMessageFromProgramLockState()}
                    statusChangeTextProvider={importStatusMessage}
                    finalStates={[
                        window.enumBackgroundTaskStatus.NoBackgroundTaskRunning,
                        window.enumBackgroundTaskStatus.DataImportSucceeded,
                        window.enumBackgroundTaskStatus.DataImportFailed,
                        window.enumBackgroundTaskStatus.ReportGenerationSucceeded,
                        window.enumBackgroundTaskStatus.ReportGenerationSkipped,
                        window.enumBackgroundTaskStatus.ReportGenerationFailed,
                        window.enumBackgroundTaskStatus.ImportCancelled,
                        window.enumBackgroundTaskStatus.DataOrColumnMappingError
                    ]}
                    onTaskFinishedCallback={importTaskFinished}
                    buttons={[
                        {
                            text: <FormattedMessage id='ImportResult.AbortImport' />,
                            onClick: cancelButtonClicked,
                            onClickText: <FormattedMessage id='ImportResult.WaitForCancel' />
                        }
                    ]}
                    links={[
                        {
                            text: <FormattedMessage id='Sidebar.Dashboard' />,
                            to: '/'
                        }
                    ]}
                    hideCloseBtn={true}
                />
            }
            <Row className="wizard-content">
                <Col xs='9'>
                    <Upload
                        ref={uploadRef}
                        batch={false}
                        multiple={false}
                        defaultFiles={[]}
                        withCredentials={false}
                        autoUpload={false}
                        showActionButtons={false}
                        accept={'.csv, .dat, .txt'}
                        saveUrl={""}
                        removeUrl={""}
                        customNote={note}
                        onAdd={handleFileSelected}
                        onRemove={handleFileRemoved}
                        className="wizard-upload"
                    />
                </Col>
                <Col xs='3'>
                    <Card className='card-chart'>
                        <CardBody>
                            <p><FormattedMessage id='ImportUpdate.InventoryDataDescription' /></p>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </div>
    );
};

export default ImportUpdate