import React from 'react';
import { Col, Row, Card, CardBody, Label } from 'reactstrap';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useIntl } from 'react-intl';
import NLSGrid from './NLS/NLSGrid';

export default function ManageNLS() {

    const intl = useIntl();
    const availableLanguages = [
        { text: 'Deutsch', value: window.enumLanguage.German },
        { text: 'English', value: window.enumLanguage.English },
        { text: 'Italiano', value: window.enumLanguage.Italian }
    ];

    const [selectedLanguage, setSelectedLanguage] = React.useState(availableLanguages[0]);

    const handleValueChange = (event) => {
        setSelectedLanguage(event.target.value);
    }

    return (
        <div className="content">
            <Card>
                <CardBody>
                    <Row>
                        <Col xs="12">
                            <table width="100%">
                                <tbody>
                                    <tr>
                                        <td width="320px">
                                            <Label>{intl.formatMessage({ id: "NLS.Language" })}</Label>
                                        </td>
                                        <td />
                                    </tr>
                                    <tr>
                                        <td>
                                            <DropDownList
                                                id={"languageSelector"}
                                                name={"languageSelector"}
                                                textField={"text"}
                                                dataItemKey={"value"}
                                                data={availableLanguages}
                                                value={selectedLanguage}
                                                onChange={handleValueChange}
                                                style={{ width: '100%' }}
                                            />
                                        </td>
                                        <td />
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs="12">
                            <NLSGrid language={selectedLanguage.value} />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
}