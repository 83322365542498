import { DashboardStoreCustomer, SELECT_POPULATION } from './types';

export function storeCustomer(customer) {
    return {
        type: DashboardStoreCustomer,
        payload: customer
    };
}

export function selectPopulation(population) {
    return {
        type: SELECT_POPULATION,
        payload: population
    };
}
