/******************************************
 *  Author : Dr. Hanno Georg   
 *  Created On : Fri Sep 03 2021
 *  File : store.js
 *******************************************/

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { reducers } from './reducers'
import { loadState, ReduxKEY } from './reducers/browser-storage';
import {
    createStateSyncMiddleware,
    initMessageListener,
} from "redux-state-sync";




export const store = configureStore({
    devTools: true,
    reducer: reducers,
    // here we restore the previously persisted state
    preloadedState: loadState(ReduxKEY),
    middleware: () => getDefaultMiddleware({
        serializableCheck: false,
    })
    .concat(createStateSyncMiddleware()),

});


initMessageListener(store);

export default store;