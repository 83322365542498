import React, { useState } from 'react';
import { Button } from "reactstrap";

import { saveAs } from 'file-saver';
import { FormattedMessage, useIntl } from 'react-intl';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { downloadFile } from '../../services/commonService';

const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
};

export default function PreviewPDF(props) {

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPages(nextNumPages);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    return (
        <div className="report_preview">
            <Document
                file={props.file}
                onLoadSuccess={onDocumentLoadSuccess}
                options={options}
            >
                <Page pageNumber={pageNumber} />
            </Document>
            {props.file && numPages > 1 && (
                <span className="text-center">
                    {pageNumber > 1 &&(
                        <span className="k-icon k-i-arrow-60-left  pagination-arrow" onClick={previousPage} />
                    )}

                    <span className="grid-pagination-text">
                        <FormattedMessage  id='Control.Paging.PageXofX' values={{
                        Current: pageNumber,
                        Total: numPages
                        }} />
                    </span>

                    {pageNumber < numPages && (
                        <span className="k-icon k-i-arrow-60-right pagination-arrow" onClick={nextPage} />
                    )}
                </span>)
            }
            {props.file &&
                <Button className="pull-right" color="primary"
                    onClick={async () => {
                        let blob = new Blob([props.file], {
                            type: "application/pdf"
                        });
                        saveAs(blob, props.file.name);
                    }}
                >
                    <FormattedMessage id='Reporting.DownloadDocument'/>
                </Button>
            }
            {props.file && props.exportFileText && props.onExportFileClicked &&
                <Button color="primary" onClick={props.onExportFileClicked}>
                    {props.exportFileText}
                </Button>
            }
        </div>
	)
}