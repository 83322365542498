/******************************************
 *  Author : Dr. Hanno Georg   
 *  Created On : Fri Sep 03 2021
 *  File : apiService.js
 *******************************************/

import { get } from './serviceBase';

const baseUrlVersionController = '/api/v1.0/version';
const baseUrlFeatureController = '/api/v1.0/feature';

async function getVersion(successCallback, errorCallback) {
    const url = `${baseUrlVersionController}`;
    await get(url, successCallback, errorCallback);
}

function getFeatureFlags(successCallback, errorCallback) {
    const url = `${baseUrlFeatureController}/GetFeatureFlags`;
    get(url, successCallback, errorCallback);
}

function isMaintenance(successCallback, errorCallback) {
    const url = `${baseUrlFeatureController}`;
    get(url, successCallback, errorCallback);
}

function getMaintenanceUrl(successCallback, errorCallback) {
    const url = `${baseUrlFeatureController}/GetMaintenanceUrl`;
    get(url, successCallback, errorCallback);
}
 
 export {
    getVersion,
    getFeatureFlags,
    isMaintenance,
    getMaintenanceUrl
 }
 