import { get, post, postWithParams } from './serviceBase';

export const ErrorDefinition = {
    AnyDifferenceIsError: 0,
    AcceptTolerableDeviations: 1
}

const baseUrl = '/api/v1.0/admin/Settings';
const baseUrlRecycleBin = '/api/v1.0/admin/RecycleBin';

export function getCustomerSettings(customerId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('customerId', customerId);
    const url = `${baseUrl}/GetCustomerSettings?` + params;
    get(url, successCallback, errorCallback);
}

export function getPopulations(customerId, successCallback, errorCallback, cancelToken) {
    const params = new URLSearchParams();
    params.append('customerId', customerId);
    const url = `${baseUrl}/GetPopulations?` + params;
    get(url, successCallback, errorCallback, cancelToken);
}

export function getRecycleBinContent(customerId, successCallback, errorCallback, cancelToken) {
    const params = new URLSearchParams();
    params.append('customerId', customerId);
    const url = `${baseUrlRecycleBin}/GetRecycleBinContent?` + params;
    get(url, successCallback, errorCallback, cancelToken);
}

export function restoreWarehouseLocation(wareHouse, successCallback, errorCallback) {
    const url = `${baseUrlRecycleBin}/RestoreWarehouseLocation`;
    post(url, wareHouse, successCallback, errorCallback);
}

export function restorePopulation(population, successCallback, errorCallback) {
    const url = `${baseUrlRecycleBin}/RestorePopulation`;
    post(url, population, successCallback, errorCallback);
}

export function restoreAllDeletedPopulations(customerId, successCallback, errorCallback) {
    const url = `${baseUrlRecycleBin}/RestoreAllDeletedPopulations`;
    postWithParams(url, {}, { customerId }, successCallback, errorCallback);
}

export function restoreAllDeletedWarehouseLocations(customerId, successCallback, errorCallback) {
    const url = `${baseUrlRecycleBin}/RestoreAllDeletedWarehouseLocations`;
    postWithParams(url, {}, { customerId }, successCallback, errorCallback);
}

export function purgeWarehouseLocation(wareHouse, successCallback, errorCallback) {
    const url = `${baseUrlRecycleBin}/PhysicallyDeleteLocation`;
    post(url, wareHouse, successCallback, errorCallback);
}

export function purgePopulation(population, successCallback, errorCallback) {
    const url = `${baseUrlRecycleBin}/PhysicallyDeletePopulation`;
    post(url, population, successCallback, errorCallback);
}

export function purgeAllDeletedPopulations(customerId, successCallback, errorCallback) {
    const url = `${baseUrlRecycleBin}/PurgeAllDeletedPopulations`;
    postWithParams(url, {}, { customerId }, successCallback, errorCallback);
}

export function purgeAllDeletedWarehouseLocations(customerId, successCallback, errorCallback) {
    const url = `${baseUrlRecycleBin}/PurgeAllDeletedWarehouseLocations`;
    postWithParams(url, {}, { customerId }, successCallback, errorCallback);
}

export function getCurrencies(successCallback, errorCallback, cancelToken) {
    const url = `${baseUrl}/GetCurrencies`;
    get(url, successCallback, errorCallback, cancelToken);
}

export function getBalanceSheetDates(successCallback, errorCallback, cancelToken) {
    const url = `${baseUrl}/GetBalanceSheetDates`;
    get(url, successCallback, errorCallback, cancelToken);
}

export function saveCustomerPopulationSettings(payload, successCallback, errorCallback) {
    const url = `${baseUrl}/SaveCustomerPopulationSettings`;
    post(url, payload, successCallback, errorCallback);
}

export function saveCustomerUploadedDataQualitySettings(payload, successCallback, errorCallback) {
    const url = `${baseUrl}/SaveCustomerUploadedDataQualitySettings`;
    post(url, payload, successCallback, errorCallback);
}

export function saveCustomerSequentialTestParameters(payload, successCallback, errorCallback) {
    const url = `${baseUrl}/SaveCustomerSequentialTestParameters`;
    post(url, payload, successCallback, errorCallback);
}
