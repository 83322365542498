import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Button, Card, CardBody, CardHeader, Label } from 'reactstrap';
import { Form, FormElement, Field } from '@progress/kendo-react-form';
import { FormRadioGroup, FormNumericTextBox, FormInput, FormCheckbox, FormDropDownList } from '../../Inventory/Wizard/form-components';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { getLocale, formatNumber } from '../../../utils/localization';
import { ErrorWeight } from './Settings.js';
import { useNotification } from '../../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../../Shared/Notifications/Notification';

const TestParamsCard = (props) => {
    const intl = useIntl();
    const userProfile = useSelector(state => state.profile.profile);
    const locale = getLocale(userProfile.userLanguage);
    const dispatchNotification = useNotification();
    const pageId = 'TestParamsCard';
    const maxAcceptTolerableDeviations = 10;
    const maxAnyDifferenceIsError = 20;

    useEffect(() => {
        resetTestParams();
    }, [props.settings]);

    const removeNotification = () => {
        dispatchNotification({
            remove: true,
            pageId: pageId
        });
    };

    const [testParams, setTestParams] = useState({});
    const resetTestParams = () => {
        let errWeight = ErrorWeight.Percentage;
        if (props.settings.errorWeightPercentage != null) {
            errWeight = ErrorWeight.Percentage;
        }
        else if (props.settings.errorWeightInitialSampleCount != null) {
            errWeight = ErrorWeight.InitialSampleCount;
        }

        const quantityDeviationOperatorObj = props.logicalOperators.find(x => x.value === props.settings.quantityDeviationOperator);
        const deviationOperatorObj = props.logicalOperators.find(x => x.value === props.settings.deviationOperator);

        setTestParams({
            customerId: props.settings.customerId,
            confidenceLevel: props.settings.confidenceLevel,
            errorDefinition: props.settings.errorDefinition,
            errorProportionForRejectionThreshold: props.settings.errorProportionForRejectionThreshold,
            errorWeightPercentage: props.settings.errorWeightPercentage,
            errorWeightInitialSampleCount: props.settings.errorWeightInitialSampleCount,

            errorWeight: errWeight,

            toleranceDescriptionCategory1: props.settings.toleranceDescriptionCategory1 ? props.settings.toleranceDescriptionCategory1 : "",
            toleranceThresholdCategory1: props.settings.toleranceThresholdCategory1,
            toleranceDescriptionCategory2: props.settings.toleranceDescriptionCategory2 ? props.settings.toleranceDescriptionCategory2 : "",
            toleranceThresholdCategory2: props.settings.toleranceThresholdCategory2,
            toleranceDescriptionCategory3: props.settings.toleranceDescriptionCategory3 ? props.settings.toleranceDescriptionCategory3 : "",
            toleranceThresholdCategory3: props.settings.toleranceThresholdCategory3,
            toleranceDescriptionCategory4: props.settings.toleranceDescriptionCategory4 ? props.settings.toleranceDescriptionCategory4 : "",
            toleranceThresholdCategory4: props.settings.toleranceThresholdCategory4,
            toleranceDescriptionCategory5: props.settings.toleranceDescriptionCategory5 ? props.settings.toleranceDescriptionCategory5 : "",
            toleranceThresholdCategory5: props.settings.toleranceThresholdCategory5,
            toleranceDescriptionCategory6: props.settings.toleranceDescriptionCategory6 ? props.settings.toleranceDescriptionCategory6 : "",
            toleranceThresholdCategory6: props.settings.toleranceThresholdCategory6,
            toleranceDescriptionCategory7: props.settings.toleranceDescriptionCategory7 ? props.settings.toleranceDescriptionCategory7 : "",
            toleranceThresholdCategory7: props.settings.toleranceThresholdCategory7,
            toleranceDescriptionCategory8: props.settings.toleranceDescriptionCategory8 ? props.settings.toleranceDescriptionCategory8 : "",
            toleranceThresholdCategory8: props.settings.toleranceThresholdCategory8,
            toleranceDescriptionCategory9: props.settings.toleranceDescriptionCategory9 ? props.settings.toleranceDescriptionCategory9 : "",
            toleranceThresholdCategory9: props.settings.toleranceThresholdCategory9,
            quantityDeviationUnits: props.settings.quantityDeviationUnits,
            quantityDeviationOperator: props.settings.quantityDeviationOperator,
            quantityDeviationPercentage: props.settings.quantityDeviationPercentage,
            valueDeviation: props.settings.valueDeviation,
            deviationOperator: props.settings.deviationOperator,

            quantityDeviationOperatorObj: quantityDeviationOperatorObj,
            deviationOperatorObj: deviationOperatorObj,

            isToleranceCategory1Enabled: props.settings.toleranceDescriptionCategory1 != null,
            isToleranceCategory2Enabled: props.settings.toleranceDescriptionCategory2 != null,
            isToleranceCategory3Enabled: props.settings.toleranceDescriptionCategory3 != null,
            isToleranceCategory4Enabled: props.settings.toleranceDescriptionCategory4 != null,
            isToleranceCategory5Enabled: props.settings.toleranceDescriptionCategory5 != null,
            isToleranceCategory6Enabled: props.settings.toleranceDescriptionCategory6 != null,
            isToleranceCategory7Enabled: props.settings.toleranceDescriptionCategory7 != null,
            isToleranceCategory8Enabled: props.settings.toleranceDescriptionCategory8 != null,
            isToleranceCategory9Enabled: props.settings.toleranceDescriptionCategory9 != null,
            isQuantityDeviationUnitsEnabled: props.settings.quantityDeviationUnits != null,
            isQuantityDeviationPercentageEnabled: props.settings.quantityDeviationPercentage != null,
            isValueDeviationEnabled: props.settings.valueDeviation != null
        });

        setIsConfidenceLevelChanged(false);
        setIsErrorDefinitionChanged(false);
        setIsErrorProportionForRejectionThresholdChanged(false);
        setIsErrorWeightPercentageChanged(false);
        setIsErrorWeightInitialSampleCountChanged(false);

        setErrorDefinition(props.settings.errorDefinition);

        setIsErrorWeightChanged(false);
        setErrorWeight(errWeight);

        setIsToleranceDescriptionCategory1Changed(false);
        setIsToleranceThresholdCategory1Changed(false);
        setIsToleranceDescriptionCategory2Changed(false);
        setIsToleranceThresholdCategory2Changed(false);
        setIsToleranceDescriptionCategory3Changed(false);
        setIsToleranceThresholdCategory3Changed(false);
        setIsToleranceDescriptionCategory4Changed(false);
        setIsToleranceThresholdCategory4Changed(false);
        setIsToleranceDescriptionCategory5Changed(false);
        setIsToleranceThresholdCategory5Changed(false);
        setIsToleranceDescriptionCategory6Changed(false);
        setIsToleranceThresholdCategory6Changed(false);
        setIsToleranceDescriptionCategory7Changed(false);
        setIsToleranceThresholdCategory7Changed(false);
        setIsToleranceDescriptionCategory8Changed(false);
        setIsToleranceThresholdCategory8Changed(false);
        setIsToleranceDescriptionCategory9Changed(false);
        setIsToleranceThresholdCategory9Changed(false);

        setIsIsToleranceCategory1EnabledChanged(false);
        setIsToleranceCategory1Enabled(props.settings.toleranceDescriptionCategory1 != null);
        setIsIsToleranceCategory2EnabledChanged(false);
        setIsToleranceCategory2Enabled(props.settings.toleranceDescriptionCategory2 != null);
        setIsIsToleranceCategory3EnabledChanged(false);
        setIsToleranceCategory3Enabled(props.settings.toleranceDescriptionCategory3 != null);
        setIsIsToleranceCategory4EnabledChanged(false);
        setIsToleranceCategory4Enabled(props.settings.toleranceDescriptionCategory4 != null);
        setIsIsToleranceCategory5EnabledChanged(false);
        setIsToleranceCategory5Enabled(props.settings.toleranceDescriptionCategory5 != null);
        setIsIsToleranceCategory6EnabledChanged(false);
        setIsToleranceCategory6Enabled(props.settings.toleranceDescriptionCategory6 != null);
        setIsIsToleranceCategory7EnabledChanged(false);
        setIsToleranceCategory7Enabled(props.settings.toleranceDescriptionCategory7 != null);
        setIsIsToleranceCategory8EnabledChanged(false);
        setIsToleranceCategory8Enabled(props.settings.toleranceDescriptionCategory8 != null);
        setIsIsToleranceCategory9EnabledChanged(false);
        setIsToleranceCategory9Enabled(props.settings.toleranceDescriptionCategory9 != null);

        setIsQuantityDeviationUnitsChanged(false);
        setIsQuantityDeviationOperatorChanged(false);
        setIsQuantityDeviationPercentageChanged(false);
        setIsValueDeviationChanged(false);
        setIsDeviationOperatorChanged(false);

        setIsIsQuantityDeviationUnitsEnabledChanged(false);
        setIsQuantityDeviationUnitsEnabled(props.settings.quantityDeviationUnits != null);
        setIsIsQuantityDeviationPercentageEnabledChanged(false);
        setIsQuantityDeviationPercentageEnabled(props.settings.quantityDeviationPercentage != null);
        setIsIsValueDeviationEnabledChanged(false);
        setIsValueDeviationEnabled(props.settings.valueDeviation != null);

        updateMaxErrorProportionForRejectionThreshold(props.settings.errorDefinition);
    };

    const minConfidenceLevel = 95.0;
    const maxConfidenceLevel = 99.0;

    const confidenceLevelValidator = (value) => {
        if (value == null) {
            return intl.formatMessage({ id: 'Settings.TestParams.ConfidenceLevelRequired' });
        }
        if (value < minConfidenceLevel || value > maxConfidenceLevel) {
            return intl.formatMessage({ id: 'Settings.TestParams.ValueMustBeInRange' }, {
                Min: formatNumber(minConfidenceLevel, locale),
                Max: formatNumber(maxConfidenceLevel, locale)
            });
        }
        return '';
    };

    const errorDefinitionValidator = (value) => value != null ? "" : intl.formatMessage({ id: "Settings.TestParams.ErrorDefinitionRequired" });

    const minErrorProportionForRejectionThreshold = 0;
    const [maxErrorProportionForRejectionThreshold, setMaxErrorProportionForRejectionThreshold] = useState(maxAnyDifferenceIsError);

    const checkErrorProportionForRejectionThreshold = (errDefinition) => {
        if (errDefinition === window.enumErrorDefinition.AnyDifferenceIsError) {
            if (testParams.errorProportionForRejectionThreshold && testParams.errorProportionForRejectionThreshold < maxAnyDifferenceIsError) {
                dispatchNotification({
                    pageId: pageId,
                    type: NotificationTypes.info,
                    message: <FormattedMessage id='Settings.TestParams.ErrorProportionForRejectionThresholdMax' values={{ Max: maxAnyDifferenceIsError }} />
                });
            }
        }
    }

    const updateMaxErrorProportionForRejectionThreshold = (errDefinition) => {
        removeNotification();
        switch (errDefinition) {
            case window.enumErrorDefinition.AnyDifferenceIsError:
                setMaxErrorProportionForRejectionThreshold(maxAnyDifferenceIsError);
                break;
            case window.enumErrorDefinition.AcceptTolerableDeviations:
                setMaxErrorProportionForRejectionThreshold(maxAcceptTolerableDeviations);
                break;
            default:
                setMaxErrorProportionForRejectionThreshold(maxAnyDifferenceIsError);
                break;
        }
    };

    const updateDefaultErrorProportionForRejectionThreshold = (errDefinition, formRenderProps) => {
        let defaultValue = 0;
        switch (errDefinition) {
            case window.enumErrorDefinition.AnyDifferenceIsError:
                defaultValue = maxAnyDifferenceIsError;
                break;
            case window.enumErrorDefinition.AcceptTolerableDeviations:
                defaultValue = maxAcceptTolerableDeviations;
                break;
            default:
                defaultValue = maxAnyDifferenceIsError;
                break;
        }

        if (testParams.errorProportionForRejectionThreshold == null || testParams.errorProportionForRejectionThreshold > defaultValue) {
            formRenderProps.onChange("errorProportionForRejectionThreshold", {
                value: defaultValue
            });
        }
        else {
            formRenderProps.onChange("errorProportionForRejectionThreshold", {
                value: testParams.errorProportionForRejectionThreshold
            });
        }
    };

    const errorProportionForRejectionThresholdValidator = (value) => {
        if (value == null) {
            return intl.formatMessage({ id: 'Settings.TestParams.ErrorProportionForRejectionThresholdRequired' });
        }
        if (value < minErrorProportionForRejectionThreshold || value > maxErrorProportionForRejectionThreshold) {
            return intl.formatMessage({ id: 'Settings.TestParams.ValueMustBeInRange' }, {
                Min: formatNumber(minErrorProportionForRejectionThreshold, locale),
                Max: formatNumber(maxErrorProportionForRejectionThreshold, locale)
            });
        }
        return '';
    };

    const minErrorWeightPercentage = 0;
    const maxErrorWeightPercentage = 5;

    const errorWeightPercentageValidator = (value) => {
        if (errorWeight !== ErrorWeight.Percentage) {
            return '';
        }
        if (value == null) {
            return intl.formatMessage({ id: 'Settings.TestParams.ErrorWeightRequired' });
        }
        if (value < minErrorWeightPercentage || value > maxErrorWeightPercentage) {
            return intl.formatMessage({ id: 'Settings.TestParams.ValueMustBeInRange' }, {
                Min: formatNumber(minErrorWeightPercentage, locale),
                Max: formatNumber(maxErrorWeightPercentage, locale)
            });
        }
        return '';
    };

    const minErrorWeightInitialSampleCount = 0;
    const maxErrorWeightInitialSampleCount = 1000;

    const errorWeightInitialSampleCountValidator = (value) => {
        if (errorWeight !== ErrorWeight.InitialSampleCount) {
            return '';
        }
        if (value == null) {
            return intl.formatMessage({ id: 'Settings.TestParams.ErrorWeightRequired' });
        }
        if (value < minErrorWeightInitialSampleCount || value > maxErrorWeightInitialSampleCount) {
            return intl.formatMessage({ id: 'Settings.TestParams.ValueMustBeInRange' }, {
                Min: formatNumber(minErrorWeightInitialSampleCount, locale),
                Max: formatNumber(maxErrorWeightInitialSampleCount, locale)
            });
        }
        return '';
    };

    const errorWeightValidator = (value) => value != null ? "" : intl.formatMessage({ id: "Settings.TestParams.ErrorWeightRequired" });

    const toleranceDescription1Validator = (value) => toleranceDescriptionValidator(!isToleranceCategory1Enabled, value);
    const toleranceDescription2Validator = (value) => toleranceDescriptionValidator(!isToleranceCategory2Enabled, value);
    const toleranceDescription3Validator = (value) => toleranceDescriptionValidator(!isToleranceCategory3Enabled, value);
    const toleranceDescription4Validator = (value) => toleranceDescriptionValidator(!isToleranceCategory4Enabled, value);
    const toleranceDescription5Validator = (value) => toleranceDescriptionValidator(!isToleranceCategory5Enabled, value);
    const toleranceDescription6Validator = (value) => toleranceDescriptionValidator(!isToleranceCategory6Enabled, value);
    const toleranceDescription7Validator = (value) => toleranceDescriptionValidator(!isToleranceCategory7Enabled, value);
    const toleranceDescription8Validator = (value) => toleranceDescriptionValidator(!isToleranceCategory8Enabled, value);
    const toleranceDescription9Validator = (value) => toleranceDescriptionValidator(!isToleranceCategory9Enabled, value);
    const toleranceDescriptionValidator = (skipValidation, value) => {
        if (skipValidation) {
            return '';
        }
        if (!value) {
            return intl.formatMessage({ id: "Settings.TestParams.ToleranceDescriptionRequired" });
        }
        return '';
    };

    const minToleranceThreshold = 0;
    const maxToleranceThreshold = 5;

    const toleranceThreshold1Validator = (value) => toleranceThresholdValidator(!isToleranceCategory1Enabled, value);
    const toleranceThreshold2Validator = (value) => toleranceThresholdValidator(!isToleranceCategory2Enabled, value);
    const toleranceThreshold3Validator = (value) => toleranceThresholdValidator(!isToleranceCategory3Enabled, value);
    const toleranceThreshold4Validator = (value) => toleranceThresholdValidator(!isToleranceCategory4Enabled, value);
    const toleranceThreshold5Validator = (value) => toleranceThresholdValidator(!isToleranceCategory5Enabled, value);
    const toleranceThreshold6Validator = (value) => toleranceThresholdValidator(!isToleranceCategory6Enabled, value);
    const toleranceThreshold7Validator = (value) => toleranceThresholdValidator(!isToleranceCategory7Enabled, value);
    const toleranceThreshold8Validator = (value) => toleranceThresholdValidator(!isToleranceCategory8Enabled, value);
    const toleranceThreshold9Validator = (value) => toleranceThresholdValidator(!isToleranceCategory9Enabled, value);
    const toleranceThresholdValidator = (skipValidation, value) => {
        if (skipValidation) {
            return '';
        }
        if (value == null) {
            return intl.formatMessage({ id: 'Settings.TestParams.ToleranceThresholdRequired' });
        }
        if (value < minToleranceThreshold || value > maxToleranceThreshold) {
            return intl.formatMessage({ id: 'Settings.TestParams.ValueMustBeInRange' }, {
                Min: formatNumber(minToleranceThreshold, locale),
                Max: formatNumber(maxToleranceThreshold, locale)
            });
        }
        return '';
    };

    const minQuantityDeviationUnits = 0;
    const maxQuantityDeviationUnits = 2147483647;

    const quantityDeviationUnitsValidator = (value) => {
        if (!isQuantityDeviationUnitsEnabled) {
            return '';
        }
        if (value == null) {
            return intl.formatMessage({ id: 'Settings.TestParams.QuantityDeviationUnitsRequired' });
        }
        if (value < minQuantityDeviationUnits || value > maxQuantityDeviationUnits) {
            return intl.formatMessage({ id: 'Settings.TestParams.ValueMustBeInRange' }, {
                Min: formatNumber(minQuantityDeviationUnits, locale),
                Max: formatNumber(maxQuantityDeviationUnits, locale)
            });
        }
        return '';
    };

    const quantityDeviationOperatorValidator = (value) => {
        if (!isQuantityDeviationUnitsEnabled || !isQuantityDeviationPercentageEnabled) {
            return '';
        }
        return value != null ? "" : intl.formatMessage({ id: "Settings.TestParams.OperatorRequired" });
    };

    const minQuantityDeviationPercentage = 0;
    const maxQuantityDeviationPercentage = 5;

    const quantityDeviationPercentageValidator = (value) => {
        if (!isQuantityDeviationPercentageEnabled) {
            return '';
        }
        if (value == null) {
            return intl.formatMessage({ id: 'Settings.TestParams.QuantityDeviationPercentageRequired' });
        }
        if (value < minQuantityDeviationPercentage || value > maxQuantityDeviationPercentage) {
            return intl.formatMessage({ id: 'Settings.TestParams.ValueMustBeInRange' }, {
                Min: formatNumber(minQuantityDeviationPercentage, locale),
                Max: formatNumber(maxQuantityDeviationPercentage, locale)
            });
        }
        return '';
    };

    const minValueDeviation = 0;
    const maxValueDeviation = 50;

    const valueDeviationValidator = (value) => {
        if (!isValueDeviationEnabled) {
            return '';
        }
        if (value == null) {
            return intl.formatMessage({ id: 'Settings.TestParams.ValueDeviationRequired' });
        }
        if (value < minValueDeviation || value > maxValueDeviation) {
            return intl.formatMessage({ id: 'Settings.TestParams.ValueMustBeInRange' }, {
                Min: formatNumber(minValueDeviation, locale),
                Max: formatNumber(maxValueDeviation, locale)
            });
        }
        return '';
    };

    const deviationOperatorValidator = (value) => {
        if ((!isQuantityDeviationUnitsEnabled && !isQuantityDeviationPercentageEnabled) || !isValueDeviationEnabled) {
            return '';
        }
        return value != null ? "" : intl.formatMessage({ id: "Settings.TestParams.OperatorRequired" });
    };

    const [isConfidenceLevelChanged, setIsConfidenceLevelChanged] = useState(false);
    const handleConfidenceLevelChange = (event) => {
        const isChanged = event.value !== testParams.confidenceLevel;
        setIsConfidenceLevelChanged(isChanged);
    };

    const [isErrorDefinitionChanged, setIsErrorDefinitionChanged] = useState(false);
    const [errorDefinition, setErrorDefinition] = useState(0);
    const handleErrorDefinitionChange = (event) => {
        const isChanged = event.value !== testParams.errorDefinition;
        setIsErrorDefinitionChanged(isChanged);
        setErrorDefinition(event.value);
        updateMaxErrorProportionForRejectionThreshold(event.value);
        checkErrorProportionForRejectionThreshold(event.value);
    };

    const [isErrorProportionForRejectionThresholdChanged, setIsErrorProportionForRejectionThresholdChanged] = useState(false);
    const handleErrorProportionForRejectionThresholdChange = (event) => {
        const isChanged = event.value !== testParams.errorProportionForRejectionThreshold;
        setIsErrorProportionForRejectionThresholdChanged(isChanged);
    };

    const [isErrorWeightPercentageChanged, setIsErrorWeightPercentageChanged] = useState(false);
    const handleErrorWeightPercentageChange = (event) => {
        const isChanged = event.value !== testParams.errorWeightPercentage;
        setIsErrorWeightPercentageChanged(isChanged);
    };

    const [isErrorWeightInitialSampleCountChanged, setIsErrorWeightInitialSampleCountChanged] = useState(false);
    const handleErrorWeightInitialSampleCountChange = (event) => {
        const isChanged = event.value !== testParams.errorWeightInitialSampleCount;
        setIsErrorWeightInitialSampleCountChanged(isChanged);
    };

    const [isErrorWeightChanged, setIsErrorWeightChanged] = useState(false);
    const [errorWeight, setErrorWeight] = useState(0);
    const handleErrorWeightChange = (event) => {
        const isChanged = event.value !== testParams.errorWeight;
        setIsErrorWeightChanged(isChanged);
        setErrorWeight(event.value);
    };

    const [isToleranceDescriptionCategory1Changed, setIsToleranceDescriptionCategory1Changed] = useState(false);
    const handleToleranceDescriptionCategory1Change = (event) => {
        const isChanged = event.value !== testParams.toleranceDescriptionCategory1;
        setIsToleranceDescriptionCategory1Changed(isChanged);
    };

    const [isToleranceThresholdCategory1Changed, setIsToleranceThresholdCategory1Changed] = useState(false);
    const handleToleranceThresholdCategory1Change = (event) => {
        const isChanged = event.value !== testParams.toleranceThresholdCategory1;
        setIsToleranceThresholdCategory1Changed(isChanged);
    };

    const [isToleranceDescriptionCategory2Changed, setIsToleranceDescriptionCategory2Changed] = useState(false);
    const handleToleranceDescriptionCategory2Change = (event) => {
        const isChanged = event.value !== testParams.toleranceDescriptionCategory2;
        setIsToleranceDescriptionCategory2Changed(isChanged);
    };

    const [isToleranceThresholdCategory2Changed, setIsToleranceThresholdCategory2Changed] = useState(false);
    const handleToleranceThresholdCategory2Change = (event) => {
        const isChanged = event.value !== testParams.toleranceThresholdCategory2;
        setIsToleranceThresholdCategory2Changed(isChanged);
    };

    const [isToleranceDescriptionCategory3Changed, setIsToleranceDescriptionCategory3Changed] = useState(false);
    const handleToleranceDescriptionCategory3Change = (event) => {
        const isChanged = event.value !== testParams.toleranceDescriptionCategory3;
        setIsToleranceDescriptionCategory3Changed(isChanged);
    };

    const [isToleranceThresholdCategory3Changed, setIsToleranceThresholdCategory3Changed] = useState(false);
    const handleToleranceThresholdCategory3Change = (event) => {
        const isChanged = event.value !== testParams.toleranceThresholdCategory3;
        setIsToleranceThresholdCategory3Changed(isChanged);
    };

    const [isToleranceDescriptionCategory4Changed, setIsToleranceDescriptionCategory4Changed] = useState(false);
    const handleToleranceDescriptionCategory4Change = (event) => {
        const isChanged = event.value !== testParams.toleranceDescriptionCategory4;
        setIsToleranceDescriptionCategory4Changed(isChanged);
    };

    const [isToleranceThresholdCategory4Changed, setIsToleranceThresholdCategory4Changed] = useState(false);
    const handleToleranceThresholdCategory4Change = (event) => {
        const isChanged = event.value !== testParams.toleranceThresholdCategory4;
        setIsToleranceThresholdCategory4Changed(isChanged);
    };

    const [isToleranceDescriptionCategory5Changed, setIsToleranceDescriptionCategory5Changed] = useState(false);
    const handleToleranceDescriptionCategory5Change = (event) => {
        const isChanged = event.value !== testParams.toleranceDescriptionCategory5;
        setIsToleranceDescriptionCategory5Changed(isChanged);
    };

    const [isToleranceThresholdCategory5Changed, setIsToleranceThresholdCategory5Changed] = useState(false);
    const handleToleranceThresholdCategory5Change = (event) => {
        const isChanged = event.value !== testParams.toleranceThresholdCategory5;
        setIsToleranceThresholdCategory5Changed(isChanged);
    };

    const [isToleranceDescriptionCategory6Changed, setIsToleranceDescriptionCategory6Changed] = useState(false);
    const handleToleranceDescriptionCategory6Change = (event) => {
        const isChanged = event.value !== testParams.toleranceDescriptionCategory6;
        setIsToleranceDescriptionCategory6Changed(isChanged);
    };

    const [isToleranceThresholdCategory6Changed, setIsToleranceThresholdCategory6Changed] = useState(false);
    const handleToleranceThresholdCategory6Change = (event) => {
        const isChanged = event.value !== testParams.toleranceThresholdCategory6;
        setIsToleranceThresholdCategory6Changed(isChanged);
    };

    const [isToleranceDescriptionCategory7Changed, setIsToleranceDescriptionCategory7Changed] = useState(false);
    const handleToleranceDescriptionCategory7Change = (event) => {
        const isChanged = event.value !== testParams.toleranceDescriptionCategory7;
        setIsToleranceDescriptionCategory7Changed(isChanged);
    };

    const [isToleranceThresholdCategory7Changed, setIsToleranceThresholdCategory7Changed] = useState(false);
    const handleToleranceThresholdCategory7Change = (event) => {
        const isChanged = event.value !== testParams.toleranceThresholdCategory7;
        setIsToleranceThresholdCategory7Changed(isChanged);
    };

    const [isToleranceDescriptionCategory8Changed, setIsToleranceDescriptionCategory8Changed] = useState(false);
    const handleToleranceDescriptionCategory8Change = (event) => {
        const isChanged = event.value !== testParams.toleranceDescriptionCategory8;
        setIsToleranceDescriptionCategory8Changed(isChanged);
    };

    const [isToleranceThresholdCategory8Changed, setIsToleranceThresholdCategory8Changed] = useState(false);
    const handleToleranceThresholdCategory8Change = (event) => {
        const isChanged = event.value !== testParams.toleranceThresholdCategory8;
        setIsToleranceThresholdCategory8Changed(isChanged);
    };

    const [isToleranceDescriptionCategory9Changed, setIsToleranceDescriptionCategory9Changed] = useState(false);
    const handleToleranceDescriptionCategory9Change = (event) => {
        const isChanged = event.value !== testParams.toleranceDescriptionCategory9;
        setIsToleranceDescriptionCategory9Changed(isChanged);
    };

    const [isToleranceThresholdCategory9Changed, setIsToleranceThresholdCategory9Changed] = useState(false);
    const handleToleranceThresholdCategory9Change = (event) => {
        const isChanged = event.value !== testParams.toleranceThresholdCategory9;
        setIsToleranceThresholdCategory9Changed(isChanged);
    };

    const [isIsToleranceCategory1EnabledChanged, setIsIsToleranceCategory1EnabledChanged] = useState(false);
    const [isToleranceCategory1Enabled, setIsToleranceCategory1Enabled] = useState(false);
    const handleIsToleranceCategory1EnabledChange = (event) => {
        const isChanged = event.value !== testParams.isToleranceCategory1Enabled;
        setIsIsToleranceCategory1EnabledChanged(isChanged);
        setIsToleranceCategory1Enabled(event.value);
    };

    const [isIsToleranceCategory2EnabledChanged, setIsIsToleranceCategory2EnabledChanged] = useState(false);
    const [isToleranceCategory2Enabled, setIsToleranceCategory2Enabled] = useState(false);
    const handleIsToleranceCategory2EnabledChange = (event) => {
        const isChanged = event.value !== testParams.isToleranceCategory2Enabled;
        setIsIsToleranceCategory2EnabledChanged(isChanged);
        setIsToleranceCategory2Enabled(event.value);
    };

    const [isIsToleranceCategory3EnabledChanged, setIsIsToleranceCategory3EnabledChanged] = useState(false);
    const [isToleranceCategory3Enabled, setIsToleranceCategory3Enabled] = useState(false);
    const handleIsToleranceCategory3EnabledChange = (event) => {
        const isChanged = event.value !== testParams.isToleranceCategory3Enabled;
        setIsIsToleranceCategory3EnabledChanged(isChanged);
        setIsToleranceCategory3Enabled(event.value);
    };

    const [isIsToleranceCategory4EnabledChanged, setIsIsToleranceCategory4EnabledChanged] = useState(false);
    const [isToleranceCategory4Enabled, setIsToleranceCategory4Enabled] = useState(false);
    const handleIsToleranceCategory4EnabledChange = (event) => {
        const isChanged = event.value !== testParams.isToleranceCategory4Enabled;
        setIsIsToleranceCategory4EnabledChanged(isChanged);
        setIsToleranceCategory4Enabled(event.value);
    };

    const [isIsToleranceCategory5EnabledChanged, setIsIsToleranceCategory5EnabledChanged] = useState(false);
    const [isToleranceCategory5Enabled, setIsToleranceCategory5Enabled] = useState(false);
    const handleIsToleranceCategory5EnabledChange = (event) => {
        const isChanged = event.value !== testParams.isToleranceCategory5Enabled;
        setIsIsToleranceCategory5EnabledChanged(isChanged);
        setIsToleranceCategory5Enabled(event.value);
    };

    const [isIsToleranceCategory6EnabledChanged, setIsIsToleranceCategory6EnabledChanged] = useState(false);
    const [isToleranceCategory6Enabled, setIsToleranceCategory6Enabled] = useState(false);
    const handleIsToleranceCategory6EnabledChange = (event) => {
        const isChanged = event.value !== testParams.isToleranceCategory6Enabled;
        setIsIsToleranceCategory6EnabledChanged(isChanged);
        setIsToleranceCategory6Enabled(event.value);
    };

    const [isIsToleranceCategory7EnabledChanged, setIsIsToleranceCategory7EnabledChanged] = useState(false);
    const [isToleranceCategory7Enabled, setIsToleranceCategory7Enabled] = useState(false);
    const handleIsToleranceCategory7EnabledChange = (event) => {
        const isChanged = event.value !== testParams.isToleranceCategory7Enabled;
        setIsIsToleranceCategory7EnabledChanged(isChanged);
        setIsToleranceCategory7Enabled(event.value);
    };

    const [isIsToleranceCategory8EnabledChanged, setIsIsToleranceCategory8EnabledChanged] = useState(false);
    const [isToleranceCategory8Enabled, setIsToleranceCategory8Enabled] = useState(false);
    const handleIsToleranceCategory8EnabledChange = (event) => {
        const isChanged = event.value !== testParams.isToleranceCategory8Enabled;
        setIsIsToleranceCategory8EnabledChanged(isChanged);
        setIsToleranceCategory8Enabled(event.value);
    };

    const [isIsToleranceCategory9EnabledChanged, setIsIsToleranceCategory9EnabledChanged] = useState(false);
    const [isToleranceCategory9Enabled, setIsToleranceCategory9Enabled] = useState(false);
    const handleIsToleranceCategory9EnabledChange = (event) => {
        const isChanged = event.value !== testParams.isToleranceCategory9Enabled;
        setIsIsToleranceCategory9EnabledChanged(isChanged);
        setIsToleranceCategory9Enabled(event.value);
    };

    const [isQuantityDeviationUnitsChanged, setIsQuantityDeviationUnitsChanged] = useState(false);
    const handleQuantityDeviationUnitsChange = (event) => {
        const isChanged = event.value !== testParams.quantityDeviationUnits;
        setIsQuantityDeviationUnitsChanged(isChanged);
    };

    const [isQuantityDeviationOperatorChanged, setIsQuantityDeviationOperatorChanged] = useState(false);
    const handleQuantityDeviationOperatorChange = (event) => {
        const isChanged = event.value.value !== testParams.quantityDeviationOperator;
        setIsQuantityDeviationOperatorChanged(isChanged);
    };

    const [isQuantityDeviationPercentageChanged, setIsQuantityDeviationPercentageChanged] = useState(false);
    const handleQuantityDeviationPercentageChange = (event) => {
        const isChanged = event.value !== testParams.quantityDeviationPercentage;
        setIsQuantityDeviationPercentageChanged(isChanged);
    };

    const [isValueDeviationChanged, setIsValueDeviationChanged] = useState(false);
    const handleValueDeviationChange = (event) => {
        const isChanged = event.value !== testParams.valueDeviation;
        setIsValueDeviationChanged(isChanged);
    };

    const [isDeviationOperatorChanged, setIsDeviationOperatorChanged] = useState(false);
    const handleDeviationOperatorChange = (event) => {
        const isChanged = event.value.value !== testParams.deviationOperator;
        setIsDeviationOperatorChanged(isChanged);
    };

    const [isIsQuantityDeviationUnitsEnabledChanged, setIsIsQuantityDeviationUnitsEnabledChanged] = useState(false);
    const [isQuantityDeviationUnitsEnabled, setIsQuantityDeviationUnitsEnabled] = useState(false);
    const handleIsQuantityDeviationUnitsEnabledChange = (event) => {
        const isChanged = event.value !== testParams.isQuantityDeviationUnitsEnabled;
        setIsIsQuantityDeviationUnitsEnabledChanged(isChanged);
        setIsQuantityDeviationUnitsEnabled(event.value);
    };

    const [isIsQuantityDeviationPercentageEnabledChanged, setIsIsQuantityDeviationPercentageEnabledChanged] = useState(false);
    const [isQuantityDeviationPercentageEnabled, setIsQuantityDeviationPercentageEnabled] = useState(false);
    const handleIsQuantityDeviationPercentageEnabledChange = (event) => {
        const isChanged = event.value !== testParams.isQuantityDeviationPercentageEnabled;
        setIsIsQuantityDeviationPercentageEnabledChanged(isChanged);
        setIsQuantityDeviationPercentageEnabled(event.value);
    };

    const [isIsValueDeviationEnabledChanged, setIsIsValueDeviationEnabledChanged] = useState(false);
    const [isValueDeviationEnabled, setIsValueDeviationEnabled] = useState(false);
    const handleIsValueDeviationEnabledChange = (event) => {
        const isChanged = event.value !== testParams.isValueDeviationEnabled;
        setIsIsValueDeviationEnabledChanged(isChanged);
        setIsValueDeviationEnabled(event.value);
    };

    const areChanges = isConfidenceLevelChanged || isErrorDefinitionChanged || isErrorProportionForRejectionThresholdChanged
        || isErrorWeightPercentageChanged || isErrorWeightInitialSampleCountChanged || isErrorWeightChanged
        || isIsToleranceCategory1EnabledChanged || isToleranceDescriptionCategory1Changed || isToleranceThresholdCategory1Changed
        || isIsToleranceCategory2EnabledChanged || isToleranceDescriptionCategory2Changed || isToleranceThresholdCategory2Changed
        || isIsToleranceCategory3EnabledChanged || isToleranceDescriptionCategory3Changed || isToleranceThresholdCategory3Changed
        || isIsToleranceCategory4EnabledChanged || isToleranceDescriptionCategory4Changed || isToleranceThresholdCategory4Changed
        || isIsToleranceCategory5EnabledChanged || isToleranceDescriptionCategory5Changed || isToleranceThresholdCategory5Changed
        || isIsToleranceCategory6EnabledChanged || isToleranceDescriptionCategory6Changed || isToleranceThresholdCategory6Changed
        || isIsToleranceCategory7EnabledChanged || isToleranceDescriptionCategory7Changed || isToleranceThresholdCategory7Changed
        || isIsToleranceCategory8EnabledChanged || isToleranceDescriptionCategory8Changed || isToleranceThresholdCategory8Changed
        || isIsToleranceCategory9EnabledChanged || isToleranceDescriptionCategory9Changed || isToleranceThresholdCategory9Changed
        || isIsQuantityDeviationUnitsEnabledChanged || isQuantityDeviationUnitsChanged
        || isQuantityDeviationOperatorChanged
        || isIsQuantityDeviationPercentageEnabledChanged || isQuantityDeviationPercentageChanged
        || isIsValueDeviationEnabledChanged || isValueDeviationChanged
        || isDeviationOperatorChanged;

    useEffect(() => {
        if (props.onValueChanged) {
            props.onValueChanged(areChanges);
        }
    }, [isConfidenceLevelChanged, isErrorDefinitionChanged, isErrorProportionForRejectionThresholdChanged,
        isErrorWeightPercentageChanged, isErrorWeightInitialSampleCountChanged, isErrorWeightChanged,
        isIsToleranceCategory1EnabledChanged, isToleranceDescriptionCategory1Changed, isToleranceThresholdCategory1Changed,
        isIsToleranceCategory2EnabledChanged, isToleranceDescriptionCategory2Changed, isToleranceThresholdCategory2Changed,
        isIsToleranceCategory3EnabledChanged, isToleranceDescriptionCategory3Changed, isToleranceThresholdCategory3Changed,
        isIsToleranceCategory4EnabledChanged, isToleranceDescriptionCategory4Changed, isToleranceThresholdCategory4Changed,
        isIsToleranceCategory5EnabledChanged, isToleranceDescriptionCategory5Changed, isToleranceThresholdCategory5Changed,
        isIsToleranceCategory6EnabledChanged, isToleranceDescriptionCategory6Changed, isToleranceThresholdCategory6Changed,
        isIsToleranceCategory7EnabledChanged, isToleranceDescriptionCategory7Changed, isToleranceThresholdCategory7Changed,
        isIsToleranceCategory8EnabledChanged, isToleranceDescriptionCategory8Changed, isToleranceThresholdCategory8Changed,
        isIsToleranceCategory9EnabledChanged, isToleranceDescriptionCategory9Changed, isToleranceThresholdCategory9Changed,
        isIsQuantityDeviationUnitsEnabledChanged, isQuantityDeviationUnitsChanged,
        isQuantityDeviationOperatorChanged,
        isIsQuantityDeviationPercentageEnabledChanged, isQuantityDeviationPercentageChanged,
        isIsValueDeviationEnabledChanged, isValueDeviationChanged,
        isDeviationOperatorChanged
    ]);

    const handleReset = () => {
        setTestParams({});
        setTimeout(() => resetTestParams());
    };

    const handleSubmit = (dataItem) => {
        props.onSubmit(dataItem);
    };

    const getSaveTooltip = (formRenderProps) => {
        if (!formRenderProps.valid) {
            return intl.formatMessage({ id: 'Control.MissingMandatoryFields' });
        }
        return '';
    };

    return (
        <Form
            onSubmit={handleSubmit}
            initialValues={testParams}
            key={JSON.stringify(testParams)}
            render={(formRenderProps) => (
                <FormElement>
                    <Row>
                        <Col xs={errorDefinition === window.enumErrorDefinition.AcceptTolerableDeviations ? "6" : "12"} >
                            <Card>
                                <CardHeader>
                                    <h3><FormattedMessage id="Settings.TestParams.Title" /></h3>
                                </CardHeader>
                                <CardBody>
                                    <Row id="settings">
                                        <Col xs="12">
                                            <fieldset className={"k-form-fieldset"}>
                                                <div className="mb-3">
                                                    <Field
                                                        id={"confidenceLevel"}
                                                        name={"confidenceLevel"}
                                                        label={intl.formatMessage({ id: 'Settings.TestParams.ConfidenceLevelPercent' })}
                                                        component={FormNumericTextBox}
                                                        placeholder={intl.formatMessage({ id: 'Settings.ValuePlaceholder' })}
                                                        min={minConfidenceLevel}
                                                        max={maxConfidenceLevel}
                                                        step={0.1}
                                                        format={'#.0 \\\%'}
                                                        validator={confidenceLevelValidator}
                                                        onChange={handleConfidenceLevelChange}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Field
                                                        id={"errorDefinition"}
                                                        name={"errorDefinition"}
                                                        label={intl.formatMessage({ id: 'Settings.TestParams.ErrorDefinition' })}
                                                        component={FormRadioGroup}
                                                        data={props.errorDefinitions}
                                                        validator={errorDefinitionValidator}
                                                        onChange={(event) => {
                                                            handleErrorDefinitionChange(event);
                                                            updateDefaultErrorProportionForRejectionThreshold(event.value, formRenderProps);
                                                        }}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Field
                                                        id={"errorProportionForRejectionThreshold"}
                                                        name={"errorProportionForRejectionThreshold"}
                                                        label={intl.formatMessage({ id: 'Settings.TestParams.ErrorProportion' })}
                                                        component={FormNumericTextBox}
                                                        placeholder={intl.formatMessage({ id: 'Settings.ValuePlaceholder' })}
                                                        min={minErrorProportionForRejectionThreshold}
                                                        max={maxErrorProportionForRejectionThreshold}
                                                        step={0.1}
                                                        format={'#.0 \\\%'}
                                                        validator={errorProportionForRejectionThresholdValidator}
                                                        onChange={handleErrorProportionForRejectionThresholdChange}
                                                    />
                                                </div>

                                                <Row>
                                                    <Col xs="6" className="error-weight-options">
                                                        <div className="mb-3">
                                                            <Field
                                                                id={"errorWeight"}
                                                                name={"errorWeight"}
                                                                label={intl.formatMessage({ id: 'Settings.TestParams.ErrorWeight' })}
                                                                component={FormRadioGroup}
                                                                data={props.errorWeights}
                                                                validator={errorWeightValidator}
                                                                onChange={handleErrorWeightChange}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col xs="6" className="error-weight-values">
                                                        <div className="mb-3">
                                                            <Field
                                                                id={"errorWeightPercentage"}
                                                                name={"errorWeightPercentage"}
                                                                component={FormNumericTextBox}
                                                                placeholder={intl.formatMessage({ id: 'Settings.ValuePlaceholder' })}
                                                                min={minErrorWeightPercentage}
                                                                max={maxErrorWeightPercentage}
                                                                step={0.1}
                                                                format={'#.0 \\\%'}
                                                                validator={errorWeightPercentageValidator}
                                                                onChange={handleErrorWeightPercentageChange}
                                                                disabled={errorWeight !== ErrorWeight.Percentage}
                                                            />
                                                        </div>

                                                        <div className="mb-3">
                                                            <Field
                                                                id={"errorWeightInitialSampleCount"}
                                                                name={"errorWeightInitialSampleCount"}
                                                                component={FormNumericTextBox}
                                                                placeholder={intl.formatMessage({ id: 'Settings.ValuePlaceholder' })}
                                                                min={minErrorWeightInitialSampleCount}
                                                                max={maxErrorWeightInitialSampleCount}
                                                                format={'# ' + intl.formatMessage({ id: 'Settings.TestParams.Items' })}
                                                                validator={errorWeightInitialSampleCountValidator}
                                                                onChange={handleErrorWeightInitialSampleCountChange}
                                                                disabled={errorWeight !== ErrorWeight.InitialSampleCount}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </fieldset>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        {errorDefinition === window.enumErrorDefinition.AcceptTolerableDeviations && (
                            <Col xs="6">
                                <Card>
                                    <CardHeader>
                                        <h3><FormattedMessage id="Settings.TestParams.ToleranceTitle" /></h3>
                                    </CardHeader>
                                    <CardBody>
                                        <Row id="settings">
                                            <Col xs="12">
                                                <h4><FormattedMessage id="Settings.TestParams.TolerancesForSpecificCategories" /></h4>

                                                <div className="group">
                                                    <Row>
                                                        <Col xs="2" className="tolerance-category">
                                                            <Label><FormattedMessage id="Settings.TestParams.Category" /></Label>

                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"isToleranceCategory1Enabled"}
                                                                    name={"isToleranceCategory1Enabled"}
                                                                    label={1}
                                                                    component={FormCheckbox}
                                                                    onChange={handleIsToleranceCategory1EnabledChange}
                                                                />
                                                            </div>

                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"isToleranceCategory2Enabled"}
                                                                    name={"isToleranceCategory2Enabled"}
                                                                    label={2}
                                                                    component={FormCheckbox}
                                                                    onChange={handleIsToleranceCategory2EnabledChange}
                                                                />
                                                            </div>

                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"isToleranceCategory3Enabled"}
                                                                    name={"isToleranceCategory3Enabled"}
                                                                    label={3}
                                                                    component={FormCheckbox}
                                                                    onChange={handleIsToleranceCategory3EnabledChange}
                                                                />
                                                            </div>

                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"isToleranceCategory4Enabled"}
                                                                    name={"isToleranceCategory4Enabled"}
                                                                    label={4}
                                                                    component={FormCheckbox}
                                                                    onChange={handleIsToleranceCategory4EnabledChange}
                                                                />
                                                            </div>

                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"isToleranceCategory5Enabled"}
                                                                    name={"isToleranceCategory5Enabled"}
                                                                    label={5}
                                                                    component={FormCheckbox}
                                                                    onChange={handleIsToleranceCategory5EnabledChange}
                                                                />
                                                            </div>

                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"isToleranceCategory6Enabled"}
                                                                    name={"isToleranceCategory6Enabled"}
                                                                    label={6}
                                                                    component={FormCheckbox}
                                                                    onChange={handleIsToleranceCategory6EnabledChange}
                                                                />
                                                            </div>

                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"isToleranceCategory7Enabled"}
                                                                    name={"isToleranceCategory7Enabled"}
                                                                    label={7}
                                                                    component={FormCheckbox}
                                                                    onChange={handleIsToleranceCategory7EnabledChange}
                                                                />
                                                            </div>

                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"isToleranceCategory8Enabled"}
                                                                    name={"isToleranceCategory8Enabled"}
                                                                    label={8}
                                                                    component={FormCheckbox}
                                                                    onChange={handleIsToleranceCategory8EnabledChange}
                                                                />
                                                            </div>

                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"isToleranceCategory9Enabled"}
                                                                    name={"isToleranceCategory9Enabled"}
                                                                    label={9}
                                                                    component={FormCheckbox}
                                                                    onChange={handleIsToleranceCategory9EnabledChange}
                                                                />
                                                            </div>
                                                        </Col>

                                                        <Col xs="5">
                                                            <Label><FormattedMessage id="Settings.TestParams.Description" /></Label>

                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"toleranceDescriptionCategory1"}
                                                                    name={"toleranceDescriptionCategory1"}
                                                                    component={FormInput}
                                                                    placeholder={intl.formatMessage({ id: 'Settings.ValuePlaceholder' })}
                                                                    maxLength={255}
                                                                    disabled={!isToleranceCategory1Enabled}
                                                                    validator={toleranceDescription1Validator}
                                                                    onChange={handleToleranceDescriptionCategory1Change}
                                                                />
                                                            </div>

                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"toleranceDescriptionCategory2"}
                                                                    name={"toleranceDescriptionCategory2"}
                                                                    component={FormInput}
                                                                    placeholder={intl.formatMessage({ id: 'Settings.ValuePlaceholder' })}
                                                                    maxLength={255}
                                                                    disabled={!isToleranceCategory2Enabled}
                                                                    validator={toleranceDescription2Validator}
                                                                    onChange={handleToleranceDescriptionCategory2Change}
                                                                />
                                                            </div>

                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"toleranceDescriptionCategory3"}
                                                                    name={"toleranceDescriptionCategory3"}
                                                                    component={FormInput}
                                                                    placeholder={intl.formatMessage({ id: 'Settings.ValuePlaceholder' })}
                                                                    maxLength={255}
                                                                    disabled={!isToleranceCategory3Enabled}
                                                                    validator={toleranceDescription3Validator}
                                                                    onChange={handleToleranceDescriptionCategory3Change}
                                                                />
                                                            </div>

                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"toleranceDescriptionCategory4"}
                                                                    name={"toleranceDescriptionCategory4"}
                                                                    component={FormInput}
                                                                    placeholder={intl.formatMessage({ id: 'Settings.ValuePlaceholder' })}
                                                                    maxLength={255}
                                                                    disabled={!isToleranceCategory4Enabled}
                                                                    validator={toleranceDescription4Validator}
                                                                    onChange={handleToleranceDescriptionCategory4Change}
                                                                />
                                                            </div>

                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"toleranceDescriptionCategory5"}
                                                                    name={"toleranceDescriptionCategory5"}
                                                                    component={FormInput}
                                                                    placeholder={intl.formatMessage({ id: 'Settings.ValuePlaceholder' })}
                                                                    maxLength={255}
                                                                    disabled={!isToleranceCategory5Enabled}
                                                                    validator={toleranceDescription5Validator}
                                                                    onChange={handleToleranceDescriptionCategory5Change}
                                                                />
                                                            </div>

                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"toleranceDescriptionCategory6"}
                                                                    name={"toleranceDescriptionCategory6"}
                                                                    component={FormInput}
                                                                    placeholder={intl.formatMessage({ id: 'Settings.ValuePlaceholder' })}
                                                                    maxLength={255}
                                                                    disabled={!isToleranceCategory6Enabled}
                                                                    validator={toleranceDescription6Validator}
                                                                    onChange={handleToleranceDescriptionCategory6Change}
                                                                />
                                                            </div>

                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"toleranceDescriptionCategory7"}
                                                                    name={"toleranceDescriptionCategory7"}
                                                                    component={FormInput}
                                                                    placeholder={intl.formatMessage({ id: 'Settings.ValuePlaceholder' })}
                                                                    maxLength={255}
                                                                    disabled={!isToleranceCategory7Enabled}
                                                                    validator={toleranceDescription7Validator}
                                                                    onChange={handleToleranceDescriptionCategory7Change}
                                                                />
                                                            </div>

                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"toleranceDescriptionCategory8"}
                                                                    name={"toleranceDescriptionCategory8"}
                                                                    component={FormInput}
                                                                    placeholder={intl.formatMessage({ id: 'Settings.ValuePlaceholder' })}
                                                                    maxLength={255}
                                                                    disabled={!isToleranceCategory8Enabled}
                                                                    validator={toleranceDescription8Validator}
                                                                    onChange={handleToleranceDescriptionCategory8Change}
                                                                />
                                                            </div>

                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"toleranceDescriptionCategory9"}
                                                                    name={"toleranceDescriptionCategory9"}
                                                                    component={FormInput}
                                                                    placeholder={intl.formatMessage({ id: 'Settings.ValuePlaceholder' })}
                                                                    maxLength={255}
                                                                    disabled={!isToleranceCategory9Enabled}
                                                                    validator={toleranceDescription9Validator}
                                                                    onChange={handleToleranceDescriptionCategory9Change}
                                                                />
                                                            </div>
                                                        </Col>

                                                        <Col xs="5">
                                                            <Label><FormattedMessage id="Settings.TestParams.Threshold" /></Label>

                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"toleranceThresholdCategory1"}
                                                                    name={"toleranceThresholdCategory1"}
                                                                    component={FormNumericTextBox}
                                                                    placeholder={intl.formatMessage({ id: 'Settings.ValuePlaceholder' })}
                                                                    min={minToleranceThreshold}
                                                                    max={maxToleranceThreshold}
                                                                    step={0.1}
                                                                    format={'#.0 \\\%'}
                                                                    disabled={!isToleranceCategory1Enabled}
                                                                    validator={toleranceThreshold1Validator}
                                                                    onChange={handleToleranceThresholdCategory1Change}
                                                                />
                                                            </div>

                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"toleranceThresholdCategory2"}
                                                                    name={"toleranceThresholdCategory2"}
                                                                    component={FormNumericTextBox}
                                                                    placeholder={intl.formatMessage({ id: 'Settings.ValuePlaceholder' })}
                                                                    min={minToleranceThreshold}
                                                                    max={maxToleranceThreshold}
                                                                    step={0.1}
                                                                    format={'#.0 \\\%'}
                                                                    disabled={!isToleranceCategory2Enabled}
                                                                    validator={toleranceThreshold2Validator}
                                                                    onChange={handleToleranceThresholdCategory2Change}
                                                                />
                                                            </div>

                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"toleranceThresholdCategory3"}
                                                                    name={"toleranceThresholdCategory3"}
                                                                    component={FormNumericTextBox}
                                                                    placeholder={intl.formatMessage({ id: 'Settings.ValuePlaceholder' })}
                                                                    min={minToleranceThreshold}
                                                                    max={maxToleranceThreshold}
                                                                    step={0.1}
                                                                    format={'#.0 \\\%'}
                                                                    disabled={!isToleranceCategory3Enabled}
                                                                    validator={toleranceThreshold3Validator}
                                                                    onChange={handleToleranceThresholdCategory3Change}
                                                                />
                                                            </div>

                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"toleranceThresholdCategory4"}
                                                                    name={"toleranceThresholdCategory4"}
                                                                    component={FormNumericTextBox}
                                                                    placeholder={intl.formatMessage({ id: 'Settings.ValuePlaceholder' })}
                                                                    min={minToleranceThreshold}
                                                                    max={maxToleranceThreshold}
                                                                    step={0.1}
                                                                    format={'#.0 \\\%'}
                                                                    disabled={!isToleranceCategory4Enabled}
                                                                    validator={toleranceThreshold4Validator}
                                                                    onChange={handleToleranceThresholdCategory4Change}
                                                                />
                                                            </div>

                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"toleranceThresholdCategory5"}
                                                                    name={"toleranceThresholdCategory5"}
                                                                    component={FormNumericTextBox}
                                                                    placeholder={intl.formatMessage({ id: 'Settings.ValuePlaceholder' })}
                                                                    min={minToleranceThreshold}
                                                                    max={maxToleranceThreshold}
                                                                    step={0.1}
                                                                    format={'#.0 \\\%'}
                                                                    disabled={!isToleranceCategory5Enabled}
                                                                    validator={toleranceThreshold5Validator}
                                                                    onChange={handleToleranceThresholdCategory5Change}
                                                                />
                                                            </div>

                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"toleranceThresholdCategory6"}
                                                                    name={"toleranceThresholdCategory6"}
                                                                    component={FormNumericTextBox}
                                                                    placeholder={intl.formatMessage({ id: 'Settings.ValuePlaceholder' })}
                                                                    min={minToleranceThreshold}
                                                                    max={maxToleranceThreshold}
                                                                    step={0.1}
                                                                    format={'#.0 \\\%'}
                                                                    disabled={!isToleranceCategory6Enabled}
                                                                    validator={toleranceThreshold6Validator}
                                                                    onChange={handleToleranceThresholdCategory6Change}
                                                                />
                                                            </div>

                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"toleranceThresholdCategory7"}
                                                                    name={"toleranceThresholdCategory7"}
                                                                    component={FormNumericTextBox}
                                                                    placeholder={intl.formatMessage({ id: 'Settings.ValuePlaceholder' })}
                                                                    min={minToleranceThreshold}
                                                                    max={maxToleranceThreshold}
                                                                    step={0.1}
                                                                    format={'#.0 \\\%'}
                                                                    disabled={!isToleranceCategory7Enabled}
                                                                    validator={toleranceThreshold7Validator}
                                                                    onChange={handleToleranceThresholdCategory7Change}
                                                                />
                                                            </div>

                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"toleranceThresholdCategory8"}
                                                                    name={"toleranceThresholdCategory8"}
                                                                    component={FormNumericTextBox}
                                                                    placeholder={intl.formatMessage({ id: 'Settings.ValuePlaceholder' })}
                                                                    min={minToleranceThreshold}
                                                                    max={maxToleranceThreshold}
                                                                    step={0.1}
                                                                    format={'#.0 \\\%'}
                                                                    disabled={!isToleranceCategory8Enabled}
                                                                    validator={toleranceThreshold8Validator}
                                                                    onChange={handleToleranceThresholdCategory8Change}
                                                                />
                                                            </div>

                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"toleranceThresholdCategory9"}
                                                                    name={"toleranceThresholdCategory9"}
                                                                    component={FormNumericTextBox}
                                                                    placeholder={intl.formatMessage({ id: 'Settings.ValuePlaceholder' })}
                                                                    min={minToleranceThreshold}
                                                                    max={maxToleranceThreshold}
                                                                    step={0.1}
                                                                    format={'#.0 \\\%'}
                                                                    disabled={!isToleranceCategory9Enabled}
                                                                    validator={toleranceThreshold9Validator}
                                                                    onChange={handleToleranceThresholdCategory9Change}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row id="settings">
                                            <Col xs="12">
                                                <h4><FormattedMessage id="Settings.TestParams.AllOther" /></h4>

                                                <div className="group">
                                                    <Row>
                                                        <Col xs="7" className="tolerance-quantity-deviation">
                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"isQuantityDeviationUnitsEnabled"}
                                                                    name={"isQuantityDeviationUnitsEnabled"}
                                                                    label={intl.formatMessage({ id: "Settings.TestParams.AcceptNumericQuantityDeviation" })}
                                                                    component={FormCheckbox}
                                                                    onChange={handleIsQuantityDeviationUnitsEnabledChange}
                                                                />
                                                            </div>
                                                        </Col>

                                                        <Col xs="5">
                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"quantityDeviationUnits"}
                                                                    name={"quantityDeviationUnits"}
                                                                    component={FormNumericTextBox}
                                                                    placeholder={intl.formatMessage({ id: 'Settings.ValuePlaceholder' })}
                                                                    min={minQuantityDeviationUnits}
                                                                    max={maxQuantityDeviationUnits}
                                                                    format={'# ' + intl.formatMessage({ id: 'Settings.TestParams.Units' })}
                                                                    disabled={!isQuantityDeviationUnitsEnabled}
                                                                    validator={quantityDeviationUnitsValidator}
                                                                    onChange={handleQuantityDeviationUnitsChange}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col xs="2">
                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"quantityDeviationOperatorObj"}
                                                                    name={"quantityDeviationOperatorObj"}
                                                                    component={FormDropDownList}
                                                                    textField={"label"}
                                                                    dataItemKey={"value"}
                                                                    data={props.logicalOperators}
                                                                    disabled={!isQuantityDeviationUnitsEnabled || !isQuantityDeviationPercentageEnabled}
                                                                    validator={quantityDeviationOperatorValidator}
                                                                    onChange={handleQuantityDeviationOperatorChange}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col xs="7" className="tolerance-quantity-deviation">
                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"isQuantityDeviationPercentageEnabled"}
                                                                    name={"isQuantityDeviationPercentageEnabled"}
                                                                    label={intl.formatMessage({ id: "Settings.TestParams.AcceptedQuantityDeviation" })}
                                                                    component={FormCheckbox}
                                                                    onChange={handleIsQuantityDeviationPercentageEnabledChange}
                                                                />
                                                            </div>
                                                        </Col>

                                                        <Col xs="5">
                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"quantityDeviationPercentage"}
                                                                    name={"quantityDeviationPercentage"}
                                                                    component={FormNumericTextBox}
                                                                    placeholder={intl.formatMessage({ id: 'Settings.ValuePlaceholder' })}
                                                                    min={minQuantityDeviationPercentage}
                                                                    max={maxQuantityDeviationPercentage}
                                                                    step={0.1}
                                                                    format={'#.0 \\\%'}
                                                                    disabled={!isQuantityDeviationPercentageEnabled}
                                                                    validator={quantityDeviationPercentageValidator}
                                                                    onChange={handleQuantityDeviationPercentageChange}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row id="settings">
                                            <Col xs="12">
                                                <Row>
                                                    <Col xs="2">
                                                        <div className="mb-3">
                                                            <Field
                                                                id={"deviationOperatorObj"}
                                                                name={"deviationOperatorObj"}
                                                                component={FormDropDownList}
                                                                textField={"label"}
                                                                dataItemKey={"value"}
                                                                data={props.logicalOperators}
                                                                disabled={(!isQuantityDeviationUnitsEnabled && !isQuantityDeviationPercentageEnabled) || !isValueDeviationEnabled}
                                                                validator={deviationOperatorValidator}
                                                                onChange={handleDeviationOperatorChange}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <div className="group">
                                                    <Row>
                                                        <Col xs="7" className="tolerance-value-deviation">
                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"isValueDeviationEnabled"}
                                                                    name={"isValueDeviationEnabled"}
                                                                    label={intl.formatMessage({ id: "Settings.TestParams.AcceptValueDeviation" })}
                                                                    component={FormCheckbox}
                                                                    onChange={handleIsValueDeviationEnabledChange}
                                                                />
                                                            </div>
                                                        </Col>

                                                        <Col xs="5">
                                                            <div className="mb-3">
                                                                <Field
                                                                    id={"valueDeviation"}
                                                                    name={"valueDeviation"}
                                                                    component={FormNumericTextBox}
                                                                    placeholder={intl.formatMessage({ id: 'Settings.ValuePlaceholder' })}
                                                                    min={minValueDeviation}
                                                                    max={maxValueDeviation}
                                                                    step={0.1}
                                                                    format={'#.0 EUR'}
                                                                    disabled={!isValueDeviationEnabled}
                                                                    validator={valueDeviationValidator}
                                                                    onChange={handleValueDeviationChange}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                    </Row>

                    <span className="k-form-separator"></span>
                    <div className="float-right">
                        <Button
                            color="secondary"
                            disabled={!formRenderProps.allowSubmit || !formRenderProps.modified || !areChanges}
                            onClick={handleReset}
                        >
                            <FormattedMessage id='Control.Reset' />
                        </Button>

                        <ReactTooltip id="saveTooltip" place="top" type="error" backgroundColor='#41b6e6' effect="solid" />

                        <span
                            data-for="saveTooltip"
                            data-tip={getSaveTooltip(formRenderProps)}
                        >
                            <Button
                                type={"submit"}
                                color="primary"
                                disabled={!formRenderProps.allowSubmit || !formRenderProps.modified || !formRenderProps.valid || !areChanges}
                            >
                                <FormattedMessage id='Control.Save' />
                            </Button>
                        </span>
                    </div>
                </FormElement>
            )}
        />
    );
}

export default TestParamsCard
