import * as React from "react";

export const UsersCell = (props) => {
    return (
        <td>
            {props.dataItem.users && props.dataItem.users.map(user => {
                return (
                    <div key={user.id}>
                            {user.fullName}
                    </div>
                )

            }
            )
            }
        </td>
    );
};