import React, { useState, useEffect } from 'react';
import {
    Row,
    Col,
    Button
} from 'reactstrap';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormTextArea } from "../Inventory/Wizard/form-components";
import { RadioButton, Input } from "@progress/kendo-react-inputs";
import { useLoading } from '../Shared/LoadingContext';
import { useNotification } from '../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../Shared/Notifications/Notification';
import { sendIndividualLicenseRequest } from '../../services/emailService';
import ReactTooltip from 'react-tooltip';

const IndividualLicenseDialog = (props) => {

    const intl = useIntl();

    const { setLoading } = useLoading();
    const dispatchNotification = useNotification();
    const pageId = 'IndividualLicenseDialog';

    const [messageToClient, setMessageToClient] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const dialogHeightEmail = "500px";
    const dialogHeightPhone = "600px";

    const [dialogHeight, setDialogHeight] = useState(dialogHeightEmail);
    const [requestContactVisible, setRequestContactVisible] = useState(false);
    const handleRequestContactChange = () => {
        setRequestContactVisible(!requestContactVisible);
        setDialogHeight(requestContactVisible ? dialogHeightEmail : dialogHeightPhone);
    };

    const [loadingCount, setLoadingCount] = useState(0);
    useEffect(() => {
        setLoading(loadingCount > 0);
    }, [loadingCount]);

    const incrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount + 1);
    };

    const decrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount - 1);
    };

    const removeNotification = () => {
        dispatchNotification({
            remove: true,
            pageId: pageId
        });
    };

    const handleError = (_errorMessage) => {
        decrementLoadingCount();
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: intl.formatMessage({ id: 'IndividualLicenseDialog.ErrorOnSend' })
            });
    };

    const handleBack = () => {
        props.onBack();
    };

    const handleClose = () => {
        props.onClose(false);
    };

    const handleSubmitClick = () => {
        if (!requestContactVisible && !formChanged)
            handleSubmit();
    }

    const handleSubmit = () => {
        removeNotification();
        incrementLoadingCount();
        
        sendIndividualLicenseRequest(
            {
                body: messageToClient,
                phoneNumber: phoneNumber
            },
            () => {
                decrementLoadingCount();

                dispatchNotification({
                    pageId: pageId,
                    type: NotificationTypes.success,
                    message: <FormattedMessage id='IndividualLicenseDialog.SendRequestSuccess' />
                });

                props.onClose(false);
            },
            handleError);
    };

    const [phoneNumberValid, setPhoneNumberValid] = useState(false);
    const phoneNumberRequiredValidator = (value) => {
        if (!value) {
            setPhoneNumberValid(false);
            return intl.formatMessage({ id: 'IndividualLicenseDialog.PhoneRequired' });
        }

        setPhoneNumberValid(true);
        return "";
    };

    const [formChanged, setFormChanged] = useState(false);

    const phoneNumberChanged = (e) => {
        setPhoneNumber(e.value)
        setFormChanged(true);
    };

    const messageToClientChanged = (e) => {
        setMessageToClient(e.value)
        setFormChanged(true);
    };

    const getSendTooltip = (formRenderProps) => {
        if (!formRenderProps.valid) {
            return intl.formatMessage({ id: 'IndividualLicenseDialog.PhoneRequired' });
        }
        return '';
    };

    return (
        <div>
            <Dialog
                title={<FormattedMessage id='IndividualLicenseDialog.Title' />}
                onClose={handleClose}
                className="license-check-out"
                width="1000px"
                height={dialogHeight}
            >
                <Form
                    onSubmit={handleSubmit}
                    render={(formRenderProps) => (
                        <FormElement>
                            <fieldset>
                                <Row>
                                    <Col xs="12">
                                        <Row className="individual-license-indent">
                                            <h3><FormattedMessage id='IndividualLicenseDialog.ClientMessage' /></h3>
                                        </Row>
                                        <Row>
                                            <span className="individual-license-type"><FormattedMessage id='IndividualLicenseDialog.RequestConsultation' /></span>
                                        </Row>
                                        <Row>
                                            <Col xs="1" />
                                            <Col xs="4">
                                                <RadioButton
                                                    className={"individual-license-radio"}
                                                    name="ContactRadioGroup"
                                                    value="Email"
                                                    onClick={handleRequestContactChange}
                                                    checked={!requestContactVisible}
                                                />
                                                <span className="individual-license-type"><FormattedMessage id='IndividualLicenseDialog.RequestConsultationEmail' /></span>
                                            </Col>
                                            <Col xs="4">
                                                <RadioButton
                                                    className={"individual-license-radio"}
                                                    name="ContactRadioGroup"
                                                    value="Phone"
                                                    onClick={handleRequestContactChange}
                                                    checked={requestContactVisible}
                                                />
                                                <span className="individual-license-type"><FormattedMessage id='IndividualLicenseDialog.RequestConsultationPhone' /></span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="1" />
                                            <Col xs="10">
                                                {requestContactVisible &&
                                                    <div className="mb-3 input-top-margin">
                                                        <Field
                                                            id={"phone"}
                                                            name={"phone"}
                                                            component={Input}
                                                            label={intl.formatMessage({ id: 'IndividualLicenseDialog.RequestConsultationPhoneNumber' })}
                                                            validator={phoneNumberRequiredValidator}
                                                            defaultValue={phoneNumber}
                                                            onChange={phoneNumberChanged}
                                                        />
                                                    </div>
                                                }
                                            </Col>
                                        </Row>
                                        <Row><br /></Row>
                                        <Row>
                                            <span className="individual-license-type"><FormattedMessage id='IndividualLicenseDialog.RequestDetails' /></span>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <div className="mb-3">
                                                    <Field
                                                        onChange={messageToClientChanged}
                                                        name={"description"}
                                                        component={FormTextArea}
                                                        rows={5}
                                                        autoSize={true}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </fieldset>
                            <DialogActionsBar>
                                <div className="k-form-buttons k-justify-content-stretch">
                                    <Button color="secondary" onClick={handleBack} >
                                        <FormattedMessage id='Control.Previous' />
                                    </Button>
                                    <ReactTooltip id="sendTooltip" place="top" type="error" backgroundColor='#41b6e6' effect="solid" />
                                    <span
                                        data-for="sendTooltip"
                                        data-tip={getSendTooltip(formRenderProps)}
                                    >
                                        <Button
                                            color="primary"
                                            type={"submit"}
                                            onClick={handleSubmitClick}
                                            disabled={!phoneNumberValid && requestContactVisible}
                                        >
                                            <FormattedMessage id='IndividualLicenseDialog.Request' />
                                        </Button>
                                    </span>
                                </div>
                            </DialogActionsBar>
                        </FormElement>
                    )}
                />
            </Dialog>
        </div>
    );
};

export default IndividualLicenseDialog;
