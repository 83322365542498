import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Stepper } from "@progress/kendo-react-layout";
import { Row, Col, Button } from "reactstrap";
import { Form, FormElement } from "@progress/kendo-react-form";
import axios from 'axios';
import StockDataQuality from "./Preparation/StockDataQuality";
import Preparation from "./Preparation/Preparation";
import StockData, { stockDataEvents } from "./Stock Data/StockData";
import MapRecords from "./Stock Data/MapRecords";
import ImportResult from "./Stock Data/ImportResult";
import Counting from "./Counting/Counting";
import SelectCountingOption from "./Counting/SelectCountingOption";
import Reporting from "./Reporting/Reporting";
import { trigger, on, off } from '../../../actions/events'
import { useNotification } from '../../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../../Shared/Notifications/Notification';
import { getProgramLockState, resetProgramLockState, getReliabilities, setCountingOption } from '../../../services/inventoryService';
import { WizzardContext, WizzardReducer, WIZZARD_ACTIONS } from "./WizzardContext";
import ImportCounting from "./Counting/ImportCounting";
import { useExceptionDialog } from '../../Shared/ExceptionDialog/ExceptionDialogProvider';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLoading } from "../../Shared/LoadingContext";
import { selectPopulation } from '../../../actions/dashboardActions';
import { Context } from '../../Wrapper';
import { UserContext } from '../../UserContext';
import { getCustomerSettings } from "../../../services/settingsService";
import CountListParameters from "./Counting/CountListParameters";
import { getInventoryParameters } from "../../../services/preparationService";
import { loadLastUsedMappingByCustomer } from '../../../services/interfaceConfigurationsService';
import ImportUpdate from "./Stock Data/ImportUpdate";
import ConfirmDialog from "../../Shared/ConfirmDialog";
import { deepClone } from "../../../utils/jsonUtils";

export const wizardEvents = {
    continueButtonClicked: "wizard:continueButtonClicked",
    stepFinalized: "wizard:stepFinalized",
    countingDowloadButtonClicked: "wizard:countingDowloadButtonClicked",
    countingImportButtonClicked: "wizard:countingImportButtonClicked",
    saveInternalCounting: "wizard:saveInternalCounting",
    validStateOfCurrentStepChanged: "wizard:validStateOfCurrentStepChanged",
    inventoryImportCancelled: "wizard:inventoryImportCancelled",
    reloadImportStatistics: "wizard:reloadImportStatistics",
    reDrawButtonClicked: "wizard:reDrawButtonClicked",
    reDrawButtonEnabledStateChanged: "reDrawButtonEnabledStateChanged",
    stepBackEvent: "stepBackEvent",
    samplingContinue: "wizard:samplingContinue",
    loadMappingsButtonClicked: "wizard:loadMappingsButtonClicked",
    saveMappingsButtonClicked: "wizard:saveMappingsButtonClicked",
    resetMappingsButtonClicked: "wizard:resetMappingsButtonClicked",
    refreshButtonClicked: "wizard:refreshButtonClicked",
    previousButtonClicked: "wizard:previousButtonClicked",
    samplingPrevious: "wizard:samplingPrevious",
    refreshButtonStateChanged: "wizard:refreshButtonStateChanged"
}

export const wizardStep = {
    Preparation: 0,
    QualitySelection: 1,
    SelectInventoryFile: 2,
    ColumnMapping: 3,
    Sampling: 4,
    ImportUpdate: 5,
    CountListParameters: 6,
    CountingSelection: 7,
    ImportCounting: 8,
    Counting: 9,
    Reporting: 10
}

const UNKNOWN_WIZARD_STEP = 100;

export const getCurrentProgramLockStateAndDoActions = (populationId, actionSuccess, actionError, actionAdditional, additionalActionParams) => {
    getProgramLockState(populationId,
        data => {
            if (data.succeeded) {
                console.log('getCurrentProgramLockStateAndDoActions - full ProgramLockState info:', data.data);

                if (actionSuccess) {
                    actionSuccess(data.data);
                };

                if (actionAdditional) {
                    actionAdditional(data.data.programLockState, { ...additionalActionParams, isBackgroundTaskRunning: data.data.isBackgroundTaskRunning });
                }
            }
            else {
                if (actionError) {
                    actionError();
                };
            }
        },
        error => {
            if (actionError) {
                actionError(error);
            };
        }
    );
};

const Wizard = (props) => {
    const intl = useIntl();
    const history = useHistory();
    const context = React.useContext(Context);
    const cancelTokenSource = axios.CancelToken.source();
    const userProfile = useSelector(state => state.profile.profile);
    const selectedPopulation = useSelector(state => state.dashboard.population);
    const pageId = 'pageInventoryWizard.StockData';
    const { setLoading } = useLoading();
    const dispatch = useDispatch();
    const userContext = React.useContext(UserContext);
    const currentCustomer = useSelector(state => state.dashboard.customer);
    const isCustomerWithoutLicense = !currentCustomer?.activeLicense || currentCustomer.activeLicense.isFreeTrial;

    const [interfaceConfiguration, setInterfaceConfiguration] = React.useState({});

    const [wizzardGlobal, dispatchWizzardGlobal] = React.useReducer(WizzardReducer, {
        Counting: {
            CurrentImportFile: {},
            CountData: [],
            Skip: 0,
            CurrentPageData: [],
            Total: 0
        },
        Import: { ProgramLockState: window.enumProgramLockState.Unknown },
        CsvMapper: {
            importFile: {},
            columnHeaders: [],
            importedColumns: [],
            mapToUniqueId: [],
            mapToAmount: [],
            mapToPrice: [],
            mapToQuantityUnit: [],
            mapToPriceUnit: [],
            mapToArticleDescription: [],
            mapToAdditionalFields: [],
            draggedItem: {}
        },
        InterfaceConfiguration: interfaceConfiguration
    });
    const wizzardValue = React.useMemo(() => ({ wizzardGlobal, dispatchWizzardGlobal }), [wizzardGlobal, dispatchWizzardGlobal]);

    const [loadingCount, setLoadingCount] = React.useState(0);
    useEffect(() => {
        setLoading(loadingCount > 0);
    }, [loadingCount]);

    useEffect(() => {
        wizzardValue.dispatchWizzardGlobal({
            type: WIZZARD_ACTIONS.SetInvetoryHeaderInfo,
            payload: props.headerInfo
        });
    }, [props.headerInfo]);

    const incrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount + 1);
    };

    const decrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount - 1);
    };

    const dispatchNotification = useNotification();
    const notifyError = (errorMessage) => dispatchNotification({
        type: NotificationTypes.error,
        pageId: pageId,
        message: errorMessage
    });

    const dispatchExceptionDialog = useExceptionDialog();

    const stepsControllerUser = [
        wizardStep.Preparation,
        wizardStep.Sampling,
        wizardStep.Reporting
    ];

    const stepsByIsPerpetual = [
        wizardStep.Preparation,
        wizardStep.QualitySelection,
        wizardStep.SelectInventoryFile,
        wizardStep.ColumnMapping,
        wizardStep.Sampling,
        wizardStep.ImportUpdate,
        wizardStep.CountListParameters,
        wizardStep.CountingSelection,
        wizardStep.ImportCounting,
        wizardStep.Counting,
        wizardStep.Reporting
    ];

    const stepIndexes = userContext.userGlobal.userInfo.isController ? stepsControllerUser : stepsByIsPerpetual;

    const setCountingStepByEditorRole = React.useCallback(() => {
        if (userContext.userGlobal.userInfo.isEditor) {
            if (!wizzardValue.wizzardGlobal?.Import?.WaitingForErp) {
                setStep(stepIndexes.indexOf(wizardStep.Counting));
            }
        }
        else {
            setStep(stepIndexes.indexOf(wizardStep.CountingSelection));
        }
    }, [userContext.userGlobal.userInfo.isEditor, wizzardValue.wizzardGlobal?.Import?.WaitingForErp, stepIndexes]);

    const setReportingStepByEditorRole = React.useCallback(() => {
        userContext.userGlobal.userInfo.isEditor
            ? setStep(stepIndexes.indexOf(wizardStep.Counting))
            : setStep(stepIndexes.indexOf(wizardStep.Reporting));
    }, [userContext.userGlobal.userInfo, stepIndexes]);

    const setCountingStepByCountingOptionAndEditorRole = React.useCallback(() => {
        if (userContext.userGlobal.userInfo.isEditor) {
            setStep(stepIndexes.indexOf(wizardStep.Counting));
        }
        else {
            wizzardValue.wizzardGlobal?.Import?.CountingOption === window.enumCountingOption.ExternalEditing
                ? setStep(stepIndexes.indexOf(wizardStep.ImportCounting))
                : setStep(stepIndexes.indexOf(wizardStep.Counting));
        }
    }, [userContext.userGlobal.userInfo, stepIndexes]);

    const setStepFromProgramLockState = React.useCallback((lockState) => {
        if (userContext.userGlobal.userInfo.isController && lockState < window.enumProgramLockState.FeedbackCompletedTotal) {
            return;
        }

        if (userContext.userGlobal.userInfo.isEditor && lockState < window.enumProgramLockState.CountListCreated) {
            return;
        }

        switch (lockState) {
            case window.enumProgramLockState.ProgramNotInitialized:
                if (wizzardValue.wizzardGlobal?.Import?.WaitingForErp || wizzardValue.wizzardGlobal?.Import?.IsBackgroundTaskRunning) {
                    setStep(stepIndexes.indexOf(wizardStep.Sampling));
                } else {
                    setStep(stepIndexes.indexOf(wizardStep.Preparation));
                }
                break;
            case window.enumProgramLockState.InitialDataLoaded:
            case window.enumProgramLockState.StratumsCreated:
                setStep(stepIndexes.indexOf(wizardStep.Sampling));
                break;
            case window.enumProgramLockState.CountListCreationStarted:
            case window.enumProgramLockState.CountListCreated:
                userContext.userGlobal.userInfo.isController
                    ? setStep(stepIndexes.indexOf(wizardStep.Sampling))
                    : setCountingStepByEditorRole();
                break;
            case window.enumProgramLockState.FeedbackStarted:
                if (userContext.userGlobal.userInfo.isController) {
                    setStep(stepIndexes.indexOf(wizardStep.Sampling))
                }
                else {
                    setCountingStepByCountingOptionAndEditorRole();
                }
                break;
            case window.enumProgramLockState.FeedbackCompletedRun:
            case window.enumProgramLockState.FeedbackCompletedTotal:
                setReportingStepByEditorRole();
                break;
            default:
                setStep(stepIndexes.indexOf(wizardStep.Preparation));
        }

    }, [userContext.userGlobal.userInfo.isController, userContext.userGlobal.userInfo.isEditor, wizzardValue, stepIndexes, setCountingStepByEditorRole, setReportingStepByEditorRole]);

    const [continueBtnDisabled, setContinueBtnDisabled] = React.useState(false);

    const [prevBtnDisabled, setPrevBtnDisabled] = React.useState(false);
    const [resetBtnDisabled, setResetBtnDisabled] = React.useState(true);
    const [showImportUpdatePdf, setShowImportUpdatePdf] = React.useState(false);
    const [showColumnMappingButtons, setShowColumnMappingButtons] = React.useState(false);
    
    const getCurrentProgramLockStateAndDoAction = React.useCallback((action, params) => {
        setLoading(true);
        getCurrentProgramLockStateAndDoActions(selectedPopulation?.populationId,
            data => {
                setLoading(false);
                wizzardValue.dispatchWizzardGlobal({
                    type: WIZZARD_ACTIONS.SetProgramLockState,
                    payload: {
                        ProgramLockState: data.programLockState,
                        BackgroundTaskStatus: data.status,
                        IsBackgroundTaskRunning: data.isBackgroundTaskRunning,
                        IsBackgroundTaskFinishedWithError: data.isBackgroundTaskFinishedWithError,
                        LastError: data.lastError,
                        InventoryMethod: data.inventoryMethod,
                        FirstMissingFeedbackPageNr: data.firstMissingFeedbackPageNr,
                        WaitingForErp: data.waitingForErp,
                        CountingOption: data.countingOption
                    }
                });

                if ((!selectedPopulation?.populationId) && data.population) {
                    dispatch(selectPopulation(data.population));
                }
            },
            error => {
                setLoading(false);
                notifyError(<FormattedMessage id="Wizard.Error.ProgramLockState" />);
                console.log('error', error);
                dispatchExceptionDialog({ pageId: pageId, message: error });
                wizzardValue.dispatchWizzardGlobal({
                    type: WIZZARD_ACTIONS.SetProgramLockState,
                    payload: {
                        ProgramLockState: window.enumProgramLockState.Unknown,
                        BackgroundTaskStatus: window.enumBackgroundTaskStatus.NoBackgroundTaskRunning
                    }
                });
            },
            action,
            params
        );
    }, [dispatch, dispatchExceptionDialog, notifyError, selectedPopulation?.populationId, setLoading, wizzardValue]);

    useEffect(() => {
        incrementLoadingCount();
        getReliabilities(
            result => {
                decrementLoadingCount();
                let rel = [];

                for (let i = 0; i < result.length; i++) {
                    let currElement = {};
                    currElement.value = i;
                    currElement.text = result[i];
                    rel.push(currElement);
                }
                wizzardValue.dispatchWizzardGlobal({ type: WIZZARD_ACTIONS.SetReliabilities, payload: rel });
            },
            handleError);
    }, []);

    const getErrorDetails = error => {
        let errorMessage = '';
        if (typeof error.detail === 'string' || error.detail instanceof String) {
            errorMessage = error.detail;
        }
        else if (typeof error.message === 'string' || error.message instanceof String) {
            errorMessage = error.message;
        }
        return errorMessage;
    }

    const handleExceptions = (error, page) => {
        let errorDetails = getErrorDetails(error);
        if (errorDetails.includes('UnexpectedProgramLockStateException')) {
            setProgressedStep(UNKNOWN_WIZARD_STEP);
        }
        else {
            dispatchExceptionDialog({
                pageId: page,
                message: error
            })
        }
    }

    const handleError = (errorMessage, showNotif = true, pgid = null) => {
        decrementLoadingCount();
        if (showNotif) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pgid ?? pageId,
                message: errorMessage
            });
        }
        else {
            handleExceptions(errorMessage, pgid ?? pageId);
        }
    };

    useEffect(() => {
        incrementLoadingCount();
        getCustomerSettings(
            currentCustomer.customerId,
            custSettings => {
                decrementLoadingCount();
                wizzardValue.dispatchWizzardGlobal({
                    type: WIZZARD_ACTIONS.SetCustomerSettings,
                    payload: custSettings
                });
            },
            handleError);
    }, [currentCustomer.customerId]);

    const loadInventoryParametersIntoWizardContext = () => {
        incrementLoadingCount();
        getInventoryParameters(
            selectedPopulation?.populationId,
            data => {
                decrementLoadingCount();
                wizzardValue.dispatchWizzardGlobal({
                    type: WIZZARD_ACTIONS.SetDataForSupport, payload: {
                        SupportData: data.data
                    }
                });

            },
            handleError);
    }

    const loadInterfaceConfigurationsIntoWizardContext = React.useCallback(() => {
        incrementLoadingCount();
        loadLastUsedMappingByCustomer(selectedPopulation?.populationId,
            data => {
                decrementLoadingCount();
                dispatchWizzardGlobal({
                    type: WIZZARD_ACTIONS.SetInterfaceConfiguration,
                    payload: data.interfaceConfigurationId ? data : null
                });
                setInterfaceConfiguration(data);

                if (data?.connectedToErp) {
                    wizzardValue.dispatchWizzardGlobal({
                        type: WIZZARD_ACTIONS.SetCountNextButton,
                        payload: { CountingOption: window.enumCountingOption.OnMobileDevice }
                    });
                }
            },
            handleError);
    }, [handleError, selectedPopulation?.populationId]);

    useEffect(() => {
        loadInventoryParametersIntoWizardContext();
        loadInterfaceConfigurationsIntoWizardContext();
    }, []);

    const isUpdateImportPageVisible = () => {
        return wizzardValue.wizzardGlobal?.SupportData?.inventoryType === window.enumInventoryType.PerpetualInventorySample
            && wizzardValue.wizzardGlobal?.Import?.ProgramLockState >= window.enumProgramLockState.CountListCreated;
    }

    const getDefaultSteps = () => {
        if (userContext.userGlobal.userInfo.isController) {
            return [
                { label: intl.formatMessage({ id: 'Wizard.Preparation' }), icon: "k-i-cart", isValid: true },
                { label: intl.formatMessage({ id: 'Wizard.PreAnalysis' }), icon: "k-i-clock", isValid: true, className: 'full-step' },
                { label: intl.formatMessage({ id: 'Wizard.PostAnalysis' }), icon: "k-i-preview", isValid: true }
            ]
        }
        else {
            let stepsResult = [
                { label: intl.formatMessage({ id: 'Wizard.Preparation' }), icon: "k-i-cart", isValid: undefined },
                { className: "intermediar-step" },
                { label: intl.formatMessage({ id: 'Wizard.InventoryData' }), icon: "k-i-marker-pin-target", isValid: undefined },
                { className: "intermediar-step" },
                { label: intl.formatMessage({ id: 'Wizard.PreAnalysis' }), icon: "k-i-clock", isValid: undefined },
            ];

            if (step !== wizardStep.Preparation && isUpdateImportPageVisible()) {
                stepsResult.push({ className: "intermediar-step" });
            }
            else {
                stepsResult.push({ className: "intermediar-step", disabled: true });
            }

            stepsResult.push(
                { className: "intermediar-step" },
                { label: intl.formatMessage({ id: 'Wizard.Counting' }), icon: "k-i-dollar", isValid: undefined },
                { className: "intermediar-step" },
                { className: "intermediar-step" },
                { label: intl.formatMessage({ id: 'Wizard.PostAnalysis' }), icon: "k-i-preview", isValid: undefined }
            );

            return stepsResult
        }
    }

    const continueButtonColor = userContext.userGlobal.userInfo.isController ? "secondary" : "primary";
    const previousButtonColor = userContext.userGlobal.userInfo.isController ? "primary" : "secondary";

    const [step, setStep] = React.useState(-1);
    const [formState, setFormState] = React.useState({});
    const [steps, setSteps] = React.useState([]);
    const [loadingSteps, setLoadingSteps] = React.useState(0);
    const [isloaded, setIsloaded] = React.useState(false);
    const [progressedStep, setProgressedStep] = React.useState(0);

    useEffect(() => {
        if (wizzardValue.wizzardGlobal.SupportData && !isloaded) {
            getCurrentProgramLockStateAndDoAction(setStepFromProgramLockState, {});
            setIsloaded(true);
        }
        return () => { cancelTokenSource.cancel(); };
    }, [wizzardValue.wizzardGlobal.SupportData, loadingSteps, userContext.userGlobal.userInfo]);

    useEffect(() => {
        setRedrawButtonDisabled(redrawButtonDisabled || wizzardValue.wizzardGlobal.Import.ProgramLockState === window.enumProgramLockState.FeedbackCompletedTotal);
    }, [wizzardValue.wizzardGlobal?.Import?.ProgramLockState]);

    useEffect(() => {
        let trs = getDefaultSteps();
        setSteps(trs);
    }, [userProfile.userLanguage]);

    useEffect(() => {
        let trs = getDefaultSteps();
        setSteps(trs);
        let next = loadingSteps + 1;
        setLoadingSteps(next);
    }, [userContext.userGlobal.userInfo, wizzardValue.wizzardGlobal?.SupportData, wizzardValue.wizzardGlobal?.Import]);

    const currentStepIndex = stepIndexes[step];
    const lastStepIndex = stepIndexes[stepIndexes.length - 1];
    const isLastStep = lastStepIndex === currentStepIndex;
    const isPreviousStepsValid =
        steps
            .slice(0, currentStepIndex)
            .findIndex((currentStep) => currentStep.isValid === false) === -1;

    const [redrawButtonDisabled, setRedrawButtonDisabled] = useState(false);

    const setValidStateOfCurrentStep = React.useCallback((event) => {
        const newSteps = steps.map((currentStep, index) => ({
            ...currentStep,
            isValid: index === event.detail.step ? event.detail.isValid : currentStep.isValid,
        }));
        setSteps(newSteps);
    }, [steps]);

    const setRedrawButtonEnabledState = React.useCallback((event) => {
        setRedrawButtonDisabled(!event.detail.Enabled);
    }, []);

    React.useEffect(() => {
        on(wizardEvents.validStateOfCurrentStepChanged, setValidStateOfCurrentStep);
        on(wizardEvents.reDrawButtonEnabledStateChanged, setRedrawButtonEnabledState);

        return () => {
            off(wizardEvents.validStateOfCurrentStepChanged, setValidStateOfCurrentStep);
            off(wizardEvents.reDrawButtonEnabledStateChanged, setRedrawButtonEnabledState);
        }
    }, [setValidStateOfCurrentStep, setRedrawButtonEnabledState]);

    const importFileInfoChanged = (event) => {
        wizzardValue.dispatchWizzardGlobal({
            type: WIZZARD_ACTIONS.SetCsvMapperState,
            payload: {
                importFile: event.detail.importFile,
                columnHeaders: event.detail.columnHeaders ? deepClone(event.detail.columnHeaders) : [],
                importedColumns: event.detail.columnHeaders || [],
                mapToUniqueId: [],
                mapToAmount: [],
                mapToPrice: [],
                mapToQuantityUnit: [],
                mapToPriceUnit: [],
                mapToArticleDescription: [],
                mapToAdditionalFields: [],
                draggedItem: {}
            }
        });
    }

    const goToCreateCountlistStep = () => {
        setStep(wizardStep.CountingSelection);
    }

    const goToImportUpdateStep = () => {
        setStep(wizardStep.ImportUpdate);
    }


    const goToCountListParametersStep = () => {
        setStep(wizardStep.CountListParameters);
    }

    const resetProgramLockStateAndGoTostep = (event) => {
        switch (event.detail) {
            case wizardStep.ImportUpdate:
                doResetProgramLockState(goToImportUpdateStep);
                break;
            case wizardStep.CountListParameters:
                doResetProgramLockState(goToCountListParametersStep)
                break;
            case wizardStep.CountingSelection:
                doResetProgramLockState(goToCreateCountlistStep);
                break;
            default:
                break;
        }
    }

    const doResetProgramLockState = (gotoMextStepFunc) => {
        setLoading(true);
        resetProgramLockState(selectedPopulation?.populationId, window.enumProgramLockState.StratumsCreated,
            _data => {
                setLoading(false);
                gotoMextStepFunc();
            },
            error => {
                setLoading(false);
                notifyError(<FormattedMessage id="Wizard.Error.ResetProgramLockState" />);
                console.log('error', error);
                dispatchExceptionDialog({ pageId: pageId, message: error });
            },
            cancelTokenSource.token
        );
    }

    React.useEffect(() => {
        on(stockDataEvents.selectedFileInfoChanged, importFileInfoChanged);

        return () => {
            off(stockDataEvents.selectedFileInfoChanged, importFileInfoChanged);
        }
    }, [importFileInfoChanged]);

    React.useEffect(() => {
        on(wizardEvents.gotoStep, resetProgramLockStateAndGoTostep);


        return () => {
            off(wizardEvents.gotoStep, resetProgramLockStateAndGoTostep);

        }
    }, []);


    const submitInventory = React.useCallback((values) => {
        console.log('Inventory import result submitted:' + JSON.stringify(values));
    }, [])

    const checkStepAndProgramLockStateConsistency = React.useCallback((lockState, step) => {
        let correctedStep;
        switch (lockState) {
            case window.enumProgramLockState.InitialDataLoaded:
            case window.enumProgramLockState.StratumsCreated:
                correctedStep = Math.max(step, stepIndexes.indexOf(wizardStep.Sampling));
                break;
            case window.enumProgramLockState.CountListCreationStarted:
            case window.enumProgramLockState.CountListCreated:
                correctedStep = Math.max(step, stepIndexes.indexOf(wizardStep.CountingSelection));
                break;
            case window.enumProgramLockState.FeedbackStarted:
                correctedStep = Math.max(step, stepIndexes.indexOf(wizardStep.Counting));
                break;
            case window.enumProgramLockState.FeedbackCompletedRun:
                if (step < wizardStep.ImportUpdate) {
                    correctedStep = Math.max(step, stepIndexes.indexOf(wizardStep.Reporting));
                }
                else {
                    correctedStep = step;
                }
                break;
            case window.enumProgramLockState.FeedbackCompletedTotal:
                correctedStep = Math.max(step, stepIndexes.indexOf(wizardStep.Reporting));
                break;
            default:
                correctedStep = step;
        }

        return correctedStep;
    }, [stepIndexes]);

    const stepForwardAction = React.useCallback((lockState, params) => {
        const currentSteps = steps.map((currentStep, index) => ({
            ...currentStep,
            isValid: index === currentStepIndex ? params.isValid : currentStep.isValid,
        }));
        setSteps(currentSteps);

        if (isLastStep && isPreviousStepsValid && params.isValid) {
            console.log('submitting inventory...');
            submitInventory(params.values);
        }
        else {
            let nextstep = Math.min(step + 1, lastStepIndex);
            nextstep = params.goToStep ? params.goToStep : nextstep;
            let modifiedStep = nextstep;

            if (!userContext.userGlobal.userInfo.isController && !userContext.userGlobal.userInfo.isEditor && !params.goToStep) {
                modifiedStep = checkStepAndProgramLockStateConsistency(lockState, nextstep);
            }

            if (modifiedStep === nextstep) {
                console.log('setting next step to:', nextstep);
                setStep(nextstep);
                setFormState(params.values);
            }
            else {
                console.log('setting progressed step to:', modifiedStep);
                setProgressedStep(modifiedStep);
            }
        }
    }, [step, setStep, steps, setSteps, lastStepIndex, currentStepIndex, isLastStep, isPreviousStepsValid, submitInventory, checkStepAndProgramLockStateConsistency])

    const stepForward = React.useCallback((isValid, values, goToStep) => {
        console.log('stepForward isValid:', isValid, 'values:', values, 'goToStep:', goToStep);
        getCurrentProgramLockStateAndDoAction(stepForwardAction, { isValid, values, goToStep });
    }, [stepForwardAction])

    const nextStep = React.useCallback((event) => {
        console.log('nextStep event', event);
        stepForward(event?.detail?.isValid ?? true, {}, event?.detail?.goToStep)
    }, [stepForward]);

    React.useEffect(() => {
        setContinueBtnDisabledState();
        setPrevBtnDisabledState();
    }, [steps, step, wizzardValue.wizzardGlobal]);

    const doSamplingContinue = React.useCallback(() => {
        if (wizzardValue.wizzardGlobal.SupportData && !isUpdateImportPageVisible() /*&& wizzardValue.wizzardGlobal.InitialImport*/) {
            setStep(stepIndexes.indexOf(wizardStep.CountListParameters));
        }
        else {
            stepForward(true, {});
        }
    }, [stepForward, setStep]);

    const continueFromCountingSelectionStep = (event) => {
        if (wizzardValue.wizzardGlobal.Counting.CountingOption === window.enumCountingOption.OnMobileDevice) {
            setStep(stepIndexes.indexOf(wizardStep.Counting));
        }
        else if (wizzardValue.wizzardGlobal.Counting.CountingOption === window.enumCountingOption.ExternalEditing || wizzardValue.wizzardGlobal.Counting.CountingOption === window.enumCountingOption.ContinueHere) {
            trigger(wizardEvents.countingDowloadButtonClicked, currentStepIndex);
        }
        else {
            const { isValid, values } = event;
            stepForward(isValid, values);
        }
    };

    const setCountingOptionAndContinue = () => {
        if (wizzardValue.wizzardGlobal.Counting?.CountingOption !== undefined) {
            setLoading(true);
            setCountingOption(
                selectedPopulation.populationId,
                wizzardValue.wizzardGlobal.Counting.CountingOption,
                _success => {
                    setLoading(false);
                    continueFromCountingSelectionStep();
                },
                handleError
            );
            return;
        }
    };

    const onStepSubmit = (event) => {
        const { isValid, values } = event;
        let stepIndex = currentStepIndex;
        dispatchNotification({
            removeOnPageChanges: true
        });
        switch (stepIndex) {
            case wizardStep.Preparation:
                trigger(wizardEvents.continueButtonClicked, stepIndex);
                break;
            case wizardStep.QualitySelection:
                trigger(wizardEvents.continueButtonClicked, stepIndex);
                break;
            case wizardStep.SelectInventoryFile: {

                let hasMappingsDiscrepancies = true
                if (wizzardValue.wizzardGlobal?.InterfaceConfiguration) {
                    hasMappingsDiscrepancies = checkMappingsDiscrepancies(wizzardValue.wizzardGlobal?.InterfaceConfiguration, wizzardValue.wizzardGlobal.CsvMapper.columnHeaders)
                }
                wizzardValue.dispatchWizzardGlobal({
                    type: WIZZARD_ACTIONS.SetShowColumnMapping,
                    payload: hasMappingsDiscrepancies
                });
                stepForward(isValid, values);
                break;
            }
            case wizardStep.ColumnMapping:
                trigger(wizardEvents.continueButtonClicked, stepIndex);
                break;
            case wizardStep.Sampling:
                if (!userContext.userGlobal.userInfo.isController) {
                    trigger(wizardEvents.continueButtonClicked, stepIndex);
                }
                else {
                    stepForward(isValid, values);
                }
                break;
            case wizardStep.ImportUpdate:
                setShowImportUpdatePdf(true);
                trigger(wizardEvents.continueButtonClicked, stepIndex);
                break;
            case wizardStep.CountListParameters:
                trigger(wizardEvents.continueButtonClicked, stepIndex);
                break;
            case wizardStep.CountingSelection:
                setCountingOptionAndContinue();
                break;
            case wizardStep.ImportCounting:
                trigger(wizardEvents.countingImportButtonClicked, stepIndex);
                break;
            case wizardStep.Counting:
                trigger(wizardEvents.saveInternalCounting, true);
                break;
            case wizardStep.Reporting:
                trigger(wizardEvents.reDrawButtonClicked, stepIndex);
                break;
            default:
                stepForward(isValid, values);
                break;
        }
    }

    const stepBackAction = React.useCallback(
        (_lockState) => {
            if (currentStepIndex === wizardStep.Counting) {
                if (wizzardValue.wizzardGlobal.Counting.CountingOption === window.enumCountingOption.ExternalEditing) {
                    setStep(() => Math.max(step - 1, 0));
                }
                else {
                    setStep(() => Math.max(step - 2, 0));
                }
            }
            else if (currentStepIndex === wizardStep.Sampling) {
                if (wizzardValue.wizzardGlobal.InterfaceConfiguration?.connectedToErp) {
                    setStep(() => Math.max(step - 3, 0));
                }
                else {
                    let skipColumnMapping = getSkipColumnMapping();

                    if (skipColumnMapping || !wizzardGlobal.CsvMapper?.importFile?.name) {
                        setStep(() => Math.max(step - 2, 0));
                    }
                    else {
                        setStep(() => Math.max(step - 1, 0));
                    }
                }
            }
            else {
                setStep(() => Math.max(step - 1, 0));
            }
            dispatchNotification({
                removeOnPageChanges: true
            });
        },
        [step, setStep]
    );

    const getSkipColumnMapping = () => {
        const isSavedInMapper = wizzardValue.wizzardGlobal?.InterfaceConfiguration?.isSavedInMapper;
        const undefinedInterfaceConfig = wizzardValue.wizzardGlobal?.InterfaceConfiguration?.initialData?.headingEnabled == null;
        const isFixedLengthDataFile = wizzardValue.wizzardGlobal?.InterfaceConfiguration?.initialData?.columnSeparator === window.enumColumnSeparator.None;
        let hasMappingsDiscrepancies = true;
        if (wizzardValue.wizzardGlobal?.InterfaceConfiguration) {
            hasMappingsDiscrepancies = checkMappingsDiscrepancies(wizzardValue.wizzardGlobal?.InterfaceConfiguration, wizzardValue.wizzardGlobal.CsvMapper.columnHeaders);
        }
        const isFileWithoutHeader = !undefinedInterfaceConfig && !wizzardValue.wizzardGlobal?.InterfaceConfiguration?.initialData?.headingEnabled;
        let skipColumnMapping = isFileWithoutHeader || isFixedLengthDataFile || !hasMappingsDiscrepancies;
        return isSavedInMapper ? false : skipColumnMapping;
    }

    const stepBack = React.useCallback(
        () => {
            getCurrentProgramLockStateAndDoAction(stepBackAction);
        },
        [stepBackAction]
    );

    const removeAllNotification = () => {
        dispatchNotification({
            remove: true,
            removeAll: true
        });
    };

    const onPrevClick = React.useCallback(
        (event) => {
            event.preventDefault();

            if (currentStepIndex === wizardStep.Sampling) {
                trigger(wizardEvents.previousButtonClicked, currentStepIndex);
            }
            else {
                removeAllNotification();
                stepBack();
            }
        },
        [stepBack]
    );

    const doSamplingPrevious = React.useCallback(() => {
        removeAllNotification();
        stepBack();
    }, [stepBack]);

    React.useEffect(() => {
        on(wizardEvents.samplingContinue, doSamplingContinue);
        on(wizardEvents.samplingPrevious, doSamplingPrevious);

        return () => {
            off(wizardEvents.samplingContinue, doSamplingContinue);
            off(wizardEvents.samplingPrevious, doSamplingPrevious);
        };
    }, [doSamplingContinue, doSamplingPrevious]);

    const [isConfirmVisibile, setIsConfirmVisibile] = useState(false);
    const onSkipClick = (event) => {
        event.preventDefault();
        setIsConfirmVisibile(true);
        setShowImportUpdatePdf(false);
    };

    const inventoryImportCancelled = React.useCallback((_event) => {
        if (step && currentStepIndex === wizardStep.Sampling) {
            stepBack();
        }
    }, [step, stepBack, currentStepIndex]);

    const stepBackEventTriggered = React.useCallback((_event) => {
        stepBack();
    }, [stepBack]);

    React.useEffect(() => {
        on(wizardEvents.stepFinalized, nextStep);
        on(wizardEvents.inventoryImportCancelled, inventoryImportCancelled);
        on(wizardEvents.stepBackEvent, stepBackEventTriggered);
        on(wizardEvents.refreshButtonStateChanged, setRefreshBtnState);

        return () => {
            off(wizardEvents.stepFinalized, nextStep);
            off(wizardEvents.inventoryImportCancelled, inventoryImportCancelled);
            off(wizardEvents.stepBackEvent, stepBackEventTriggered);
            off(wizardEvents.refreshButtonStateChanged, setRefreshBtnState);
        }
    }, [nextStep, inventoryImportCancelled, stepBackEventTriggered]);

    const goToNextStep = () => {
        stepForward(true, {}, step + 1);
    }

    const continueCountingAfterDownload = () => {
        const stepCount = wizzardValue.wizzardGlobal.Counting.CountingOption === window.enumCountingOption.ContinueHere ? 2 : 1;
        stepForward(true, {}, step + stepCount);
    }

    const downloadOrContinueButtonLabel = wizzardValue.wizzardGlobal?.Counting?.CountingOption === window.enumCountingOption.ContinueHere || wizzardValue.wizzardGlobal?.Counting?.CountingOption === window.enumCountingOption.OnMobileDevice
        ? <FormattedMessage id="Wizard.Continue" />
        : <FormattedMessage id="Wizard.Download" />

    const downloadAndContinueButtonLabel = wizzardValue.wizzardGlobal
        ? downloadOrContinueButtonLabel
        : <FormattedMessage id="Wizard.DandC" />;

    const submitButtonLabel = userContext.userGlobal.userInfo.isEditor
        ? <FormattedMessage id="Wizard.Submit" />
        : <FormattedMessage id="Wizard.Continue" />;

    const setContinueBtnDisabledValueFromPreparation = (value) => {
        setContinueBtnDisabled(!value || wizzardValue.wizzardGlobal.Import.WaitingForErp);
    }

    const setResetBtnState = (value) => {
        setResetBtnDisabled(value);
    }

    const isPerpetualInventory = () => {
        return wizzardValue.wizzardGlobal.SupportData && wizzardValue.wizzardGlobal.SupportData.inventoryType === window.enumInventoryType.PerpetualInventorySample;
    }

    const checkMappingsDiscrepancies = (mapping, importedColumns) => {
        let columnNotFound = false;
        if (mapping.additionalColumns?.length) {
            mapping.additionalColumns.forEach(element => {
                let colHeaderIndex = getColumnHeaderIndex(importedColumns, element.columnName);
                if (colHeaderIndex < 0) {
                    columnNotFound = true
                }
            });
        }
        if (mapping.uniqueId?.length) {
            mapping.uniqueId.forEach(element => {
                let colHeaderIndex = getColumnHeaderIndex(importedColumns, element.columnName);
                if (colHeaderIndex < 0) {
                    columnNotFound = true
                }
            });
        }
        if (mapping.priceUnit) {
            let colHeaderIndex = getColumnHeaderIndex(importedColumns, mapping.priceUnit.columnName);
            if (colHeaderIndex < 0) {
                columnNotFound = true
            }
        }
        if (mapping.quantity) {
            let colHeaderIndex = getColumnHeaderIndex(importedColumns, mapping.quantity.columnName);
            if (colHeaderIndex < 0) {
                columnNotFound = true
            }
        }
        if (mapping.quantityUnit) {
            let colHeaderIndex = getColumnHeaderIndex(importedColumns, mapping.quantityUnit.columnName);
            if (colHeaderIndex < 0) {
                columnNotFound = true
            }
        }
        if (mapping.singlePrice) {
            let colHeaderIndex = getColumnHeaderIndex(importedColumns, mapping.singlePrice.columnName);
            if (colHeaderIndex < 0) {
                columnNotFound = true
            }
        }
        if (mapping.itemName) {
            let colHeaderIndex = getColumnHeaderIndex(importedColumns, mapping.itemName.columnName);
            if (colHeaderIndex < 0) {
                columnNotFound = true
            }
        }

        return columnNotFound;
    }

    const getColumnHeaderIndex = (columnHeaders, columnName) => {
        return columnHeaders.findIndex((item) => item.ColumnName === columnName);
    }

    const stepPages =
        [
            <Preparation key="stepPage1" locale={context.locale} language={context.localeName} setContinueBtnDisabled={setContinueBtnDisabledValueFromPreparation} />,
            <StockDataQuality key="stepPage2" />,
            <StockData key="stepPage3" selectedFile={wizzardValue.wizzardGlobal?.CsvMapper?.importFile} />,
            <MapRecords key="stepPage4" importFileInfo={wizzardValue.wizzardGlobal?.CsvMapper} setResetBtnDisabled={setResetBtnState} checkMappingsDiscrepancies={checkMappingsDiscrepancies} getColumnHeaderIndex={getColumnHeaderIndex} />,
            <ImportResult key="stepPage5" setContinueBtnDisabled={setContinueBtnDisabledValueFromPreparation} />,
            <ImportUpdate key="stepPage6" setContinueBtnDisabled={setContinueBtnDisabledValueFromPreparation} />,
            <CountListParameters key="stepPage7" setContinueBtnDisabled={setContinueBtnDisabledValueFromPreparation} showImportUpdatePdf={showImportUpdatePdf} isPerpetualInventory={isPerpetualInventory} />,
            <SelectCountingOption key="stepPage8"
                goToImportCounting={continueCountingAfterDownload}
                handleError={handleError}
                isPerpetualInventory={isPerpetualInventory}
            />,
            <ImportCounting key="stepPage9" goToCounting={goToNextStep} />,
            <Counting key="stepPage10" handleError={handleError} />,
            <Reporting key="stepPage11" handleError={handleError} />
        ];

    const invalidStepExists = !isPreviousStepsValid || (steps[currentStepIndex] && typeof steps[currentStepIndex].isValid !== "undefined" && !steps[currentStepIndex].isValid);

    const importCountingStepContinueBtnDisabled = wizzardValue.wizzardGlobal?.Counting?.CurrentImportFile?.importFile?.size > 0
        ? invalidStepExists
        : true;

    const setContinueBtnDisabledState = React.useCallback(() => {
        let disabled = false;
        let columnMappingStep = steps[wizardStep.ColumnMapping];
        switch (stepIndexes.indexOf(step)) {
            case wizardStep.SelectInventoryFile:
                disabled = !(wizzardValue.wizzardGlobal?.CsvMapper?.importFile && wizzardValue.wizzardGlobal.CsvMapper.importFile.size > 0)
                break;
            case wizardStep.ImportCounting:
                disabled = wizzardValue.wizzardGlobal ? importCountingStepContinueBtnDisabled : invalidStepExists;
                break;
            case wizardStep.Counting:
                disabled = isContinueDisabledOnCounting();
                break;
            case wizardStep.Reporting:
                disabled = isCustomerWithoutLicense;
                break;
            case wizardStep.ColumnMapping:
                disabled = getSkipColumnMapping();
                setShowColumnMappingButtons(!disabled);
                disabled = columnMappingStep.isValid ? disabled : true;
                break;
            default:
                disabled = invalidStepExists;
        }
        setContinueBtnDisabled(disabled || wizzardValue.wizzardGlobal?.Import?.WaitingForErp);
    }, [steps, step, wizzardValue.wizzardGlobal?.CsvMapper, wizzardValue.wizzardGlobal?.Import?.WaitingForErp, importCountingStepContinueBtnDisabled]);

    const isContinueDisabledOnCounting = () => {
        return false;
    };

    const handleResetMappings = (event) => {
        event.preventDefault();
        trigger(wizardEvents.resetMappingsButtonClicked);
    }

    const setPrevBtnDisabledState = React.useCallback(() => {
        let disabled = currentStepIndex === wizardStep.CountingSelection || wizzardValue.wizzardGlobal?.Import?.WaitingForErp;

        if (currentStepIndex === wizardStep.Reporting &&
            wizzardValue.wizzardGlobal?.Import?.ProgramLockState === window.enumProgramLockState.FeedbackCompletedTotal &&
            !userContext.userGlobal.userInfo.isController) {
            disabled = true;
        }

        setPrevBtnDisabled(disabled);
    }, [currentStepIndex, wizzardValue.wizzardGlobal?.Import, setPrevBtnDisabled])

    const handleConfirmDialogClose = (result) => {
        setIsConfirmVisibile(false);
        if (result) {
            removeAllNotification();
            stepForward(true, {}, wizardStep.CountListParameters);
        }
    }

    const handleInventoryProgressedConfirmDialogClose = (result) => {
        if (result) {
            if (progressedStep === UNKNOWN_WIZARD_STEP) {
                getCurrentProgramLockStateAndDoAction(setStepFromProgramLockState, {});
            }
            else {
                let nextstep = progressedStep;
                setStep(nextstep);
            }
            setProgressedStep(0);
        }
        else {
            history.push('/');
        }
    }

    const handleLoadMappings = (event) => {
        event.preventDefault();
        trigger(wizardEvents.loadMappingsButtonClicked);
    }

    const handleSaveMappings = (event) => {
        event.preventDefault();
        trigger(wizardEvents.saveMappingsButtonClicked);
    }

    const onRefreshClick = (event) => {
        event.preventDefault();
        trigger(wizardEvents.refreshButtonClicked);
    }

    const [showRefreshBtn, setShowRefreshBtn] = useState(false);

    const setRefreshBtnState = React.useCallback((event) => {
        setShowRefreshBtn(event.detail);
    }, [])

    React.useEffect(() => {
        const isOnMobileDevice = wizzardValue.wizzardGlobal?.Counting?.CountingOption === window.enumCountingOption.OnMobileDevice;
        const isDeviceActive = wizzardValue.wizzardGlobal?.Counting?.IsDeviceActive;
        const showRefreshBtn = (currentStepIndex === wizardStep.Counting && isOnMobileDevice && isDeviceActive) ||
            currentStepIndex === wizardStep.Reporting;
        setShowRefreshBtn(showRefreshBtn);
    }, [currentStepIndex, setRefreshBtnState, wizzardValue.wizzardGlobal?.Counting?.CountingOption, wizzardValue.wizzardGlobal?.Counting?.IsDeviceActive]);

    return (step >= 0 &&
        <WizzardContext.Provider value={wizzardValue}>
            <div>
                <ConfirmDialog
                    visible={isConfirmVisibile}
                    onClose={handleConfirmDialogClose}
                    negative={<FormattedMessage id='Control.Cancel' />}
                    positive={<FormattedMessage id='Control.Confirm' />}
                    detail={<FormattedMessage id='ImportUpdate.AreYouSureYouWantToSkip' />}
                />
                {
                    !userContext.userGlobal.userInfo.isEditor ?
                        <Row className="StepperRow">
                            <Col xs="12">
                                <Stepper value={step} items={steps} />
                            </Col>
                        </Row>
                        : undefined
                }
                <ConfirmDialog
                    visible={progressedStep > 0}
                    onClose={handleInventoryProgressedConfirmDialogClose}
                    title={<FormattedMessage id='Wizard.InventoryProgressedDialogTitle' />}
                    negative={<FormattedMessage id='Sidebar.Dashboard' />}
                    positive={<FormattedMessage id='Wizard.Continue' />}
                    detail={[
                        intl.formatMessage({ id: 'Wizard.InventoryProgressedConfirmationText1' }),
                        intl.formatMessage({ id: 'Wizard.InventoryProgressedConfirmationText2' })
                    ]}
                />
                <Form
                    initialValues={formState}
                    onSubmitClick={onStepSubmit}
                    render={(formRenderProps) => (
                        <FormElement >

                            {stepPages[currentStepIndex]}
                            <span className="k-form-separator" />
                            <div className="k-form-buttons k-buttons-end form-submit">
                                <span className="form-submit-text">
                                    <FormattedMessage id='Wizard.Step' /> {step + 1} <FormattedMessage id='Wizard.StepOf' /> {stepIndexes.length}
                                </span>
                                <div className="float-right">
                                    {currentStepIndex === wizardStep.ColumnMapping && showColumnMappingButtons
                                        ? (
                                            <>
                                                <Button color="secondary" onClick={handleLoadMappings}  >
                                                    <FormattedMessage id='Control.LoadMapping' />
                                                </Button>

                                                <Button color="secondary" onClick={handleSaveMappings}
                                                    disabled={continueBtnDisabled}
                                                >
                                                    <FormattedMessage id='Control.SaveMapping' />
                                                </Button>

                                                <Button color="secondary" onClick={handleResetMappings} disabled={resetBtnDisabled} >
                                                    <FormattedMessage id='Control.Reset' />
                                                </Button>
                                            </>
                                        )
                                        : undefined
                                    }

                                    {showRefreshBtn &&
                                        <Button color={previousButtonColor}
                                            onClick={onRefreshClick}
                                        >
                                            <FormattedMessage id='Wizard.Refresh' />
                                        </Button>
                                    }

                                    {currentStepIndex !== 0 && !userContext.userGlobal.userInfo.isEditor
                                        ? (
                                            <Button color={previousButtonColor}
                                                disabled={prevBtnDisabled}
                                                onClick={onPrevClick}
                                            >
                                                <FormattedMessage id='Wizard.Previous' />
                                            </Button>
                                        )
                                        : undefined}

                                    {currentStepIndex === wizardStep.CountingSelection
                                        ? (
                                            <Button color={continueButtonColor}
                                                disabled={continueBtnDisabled}
                                                onClick={formRenderProps.onSubmit}
                                            >
                                                {downloadAndContinueButtonLabel}
                                            </Button>
                                        )
                                        : undefined}

                                    {currentStepIndex === wizardStep.ImportUpdate
                                        ? (
                                            <Button color="secondary"
                                                onClick={onSkipClick}
                                            >
                                                <FormattedMessage id='Wizard.Skip' />
                                            </Button>
                                        )
                                        : undefined}

                                    {currentStepIndex !== wizardStep.CountingSelection && currentStepIndex !== wizardStep.Reporting
                                        ? (
                                            <Button color={continueButtonColor}
                                                disabled={continueBtnDisabled}
                                                onClick={formRenderProps.onSubmit}>
                                                {submitButtonLabel}
                                            </Button>
                                        )
                                        : undefined}

                                    {currentStepIndex === wizardStep.Reporting
                                        ? (
                                            <Button color={continueButtonColor}
                                                disabled={redrawButtonDisabled}
                                                onClick={formRenderProps.onSubmit}>
                                                {submitButtonLabel}
                                            </Button>
                                        )
                                        : undefined}
                                </div>
                            </div>

                            <br />
                        </FormElement>
                    )} />

            </div>
        </WizzardContext.Provider>
    );
};

export default Wizard
