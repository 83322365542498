import * as React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button, Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import generalTermsHtml_de from '../language/GeneralTermsAndConditions/GeneralTermsAndConditionsHtml_de';
import generalTermsHtml_en from '../language/GeneralTermsAndConditions/GeneralTermsAndConditionsHtml_en';

const GeneralTermsDialog = props => {
    const [visible, setVisible] = React.useState(props.visible);
    const userProfile = useSelector(state => state.profile.profile);

    const localizedPrivacyPolicyHtml = userProfile && userProfile.userLanguage == window.enumLanguage['German'] ? { __html: generalTermsHtml_de } : { __html: generalTermsHtml_en };

    const handleClose = () => {
        if (props.onClose)
            props.onClose(false);
    };

    const handleCancel = (event) => {
        event.preventDefault();
        if (props.onCancel)
            props.onCancel();
        else if (props.onClose)
            props.onClose(false);
    };

    React.useEffect(() => {
        setVisible(props.visible);
    }, [props.visible]);

    return (
        <div>
            {visible && (
                <Dialog title={<FormattedMessage id='Gtc.Title' />} onClose={handleClose}>
                    <Row>
                        <Col xs="12">
                            <div
                                dangerouslySetInnerHTML={localizedPrivacyPolicyHtml}
                            />
                        </Col>
                    </Row>
                    <DialogActionsBar>
                        <Button type={"submit"} color="secondary" onClick={handleCancel}>
                            <FormattedMessage id='Control.Close' />
                        </Button>
                    </DialogActionsBar>
                </Dialog>
            )}
        </div>
    );
};

export default GeneralTermsDialog