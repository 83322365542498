import React, { useEffect } from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, GridNoRecords, GridColumn as Column } from '@progress/kendo-react-grid';
import { useNotification } from '../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../Shared/Notifications/Notification';
import { useLoading } from '../Shared/LoadingContext';
import { deleteCustomer, getCustomersWithPopulations } from '../../services/supportService';
import { useExceptionDialog } from '../Shared/ExceptionDialog/ExceptionDialogProvider';
import GridFooter from '../Shared/GridFooter';
import { PopulationsCell } from './PopulationsCell';
import CustomerActionCell from './CustomerActionCell';
import { UsersCell } from './UsersCell';
import ConfirmDialog from '../Shared/ConfirmDialog';
import { RefreshUserContext } from '../UserContext';


export const ReorderContext = React.createContext({
    reorder: () => { },
    dragStart: () => { },
});

const CustomerManager = () => {
    const pageId = 'ArticleGrouping';
    const intl = useIntl();
    const dispatchNotification = useNotification();
    const [data, setData] = React.useState([]);
    const { setLoading } = useLoading();
    const dispatchExceptionDialog = useExceptionDialog();
    const [itemsPerPage, setItemsPerPage] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [gridData, setGridData] = React.useState([]);
    const [isPagingVisible, setIsPagingVisible] = React.useState(false);
    const minGridLength = 5;
    const [deleteConfVisibile, setDeleteConfVisibile] = React.useState(false);
    const [itemForDelete, setItemForDelete] = React.useState({});
    const refreshUserContext = RefreshUserContext();

    useEffect(() => {
        setLoading(true);
        getCustomersWithPopulations(
            result => {
                let grdData = result.map((item) => { return { ...item, deleteMethod: remove } });
                setData(grdData);
                setItemsPerPage(10);
                setLoading(false);
            },
            handleError);
    }, []);

    React.useEffect(() => {
        updatePagingControls();
    }, [data, itemsPerPage]);

    const updatePagingControls = () => {
        let isPagingVisible = data?.length > minGridLength;
        setIsPagingVisible(isPagingVisible);
        setPageHandler(isPagingVisible ? currentPage : 1);
    }

    const handleError = (errorMessage, showNotif = true) => {
        setLoading(false);
        if (showNotif) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            });
        }
    };

    const handleItemsPerPageChange = (dropDownItem) => {
        setItemsPerPage(dropDownItem.value);
    };

    const setPageHandler = (page) => {
        if (data && page > 0 && page <= Math.floor((data.length - 1) / itemsPerPage) + 1) {
            let startIndex = (page - 1) * itemsPerPage;
            let endIndex = (page) * itemsPerPage;
            setGridData(data.slice(startIndex, endIndex));
            if (data.length > itemsPerPage) {
                setCurrentPage(page);
            }
            else {
                setCurrentPage(1);
            }
        }
        else {
            setGridData(data);
        }
    }

    const remove = (dataItem) => {
        setDeleteConfVisibile(true)
        setItemForDelete(dataItem);
    }

    const handleConfirmDialogClose = (result) => {
        if (result) {
            onConfirmDelete()
        }
        else {
            onCancelDelete()
        }
    }

    const onCancelDelete = () => {
        setDeleteConfVisibile(false);
    }

    const onConfirmDelete = () => {
        setDeleteConfVisibile(false);
        setLoading(true);
        
        deleteCustomer(
            itemForDelete.customerId,
            _result => {
                setLoading(false);
                refreshUserContext(handleError);
                let temp = data.filter(x => x.customerId !== itemForDelete.customerId)
                setData(temp);
            },
            handleError);
    };

    return (

        <div className="content">
            <ConfirmDialog
                visible={deleteConfVisibile}
                onClose={handleConfirmDialogClose}
                negative={<FormattedMessage id='Control.Cancel' />}
                positive={<FormattedMessage id='Control.Delete' />}
                title={<FormattedMessage id='SupportTools.DeleteConfirmationTitle' />}
                detail={[
                    intl.formatMessage({ id: "SupportTools.DoYouWantToDeleteTheCustomer" }, { 'Name': itemForDelete.customerName }),
                    itemForDelete.warehouseLocations?.length > 0 || itemForDelete.users?.length > 0 ? intl.formatMessage({ id: "SupportTools.AttentionAllRelatedItemsWillBeDeleted" }) : ""
                ]}
            />
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>

                            <Grid
                                data={gridData}
                                key="customerId"
                            >
                                <GridNoRecords>
                                    <FormattedMessage id="SupportTools.NoCustomers" />
                                </GridNoRecords>

                                <Column field="customerName" title={intl.formatMessage({ id: "SupportTools.CustomerName" })} editable={false} />
                                <Column field="customerName" title={intl.formatMessage({ id: "SupportTools.AssignedToPopulation" })} cell={PopulationsCell} />
                                <Column field="customerName" title={intl.formatMessage({ id: "SupportTools.Users" })} cell={UsersCell} />
                                <Column width="80px" filterable={false} cell={CustomerActionCell} />
                            </Grid>
                            {isPagingVisible && (
                                <GridFooter
                                    itemsPerPage={itemsPerPage}
                                    handleItemsPerPageChange={handleItemsPerPageChange}
                                    dataLength={data.length}
                                    setPageHandler={setPageHandler}
                                    currentPage={currentPage}
                                />
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default CustomerManager