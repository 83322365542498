import React, { useState, useEffect } from 'react';
import { registerUser, validateCompanyNameExistance, validateEmailExistance } from '../services/userRegistrationService';
import { Redirect, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import StatLogo from '../assets/images/statcontrol.png';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Form,
    Row,
    Col,
    Button,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from 'reactstrap';
import { Switch } from "@progress/kendo-react-inputs";
import validator from 'validator';
import CookieConsent from './CookieConsent/CookieConsent';
import GeneralTermsDialog from './GeneralTermsDialog';
import Loading from './Shared/Loading';
import { useLoading } from './Shared/LoadingContext';
import { useNotification } from './Shared/Notifications/NotificationProvider';
import { NotificationTypes } from './Shared/Notifications/Notification';
import { useExceptionDialog } from './Shared/ExceptionDialog/ExceptionDialogProvider';
import {
    loadCaptchaEnginge,
    LoadCanvasTemplate,
    validateCaptcha
} from 'react-simple-captcha';
import { validateBlacklistCompanyWords } from "../services/blacklistCompanyWordService";
import { on, off } from '../actions/events';
import ContactSupport, { SupportType } from './Shared/ContactSupport';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faRobot,
    faBuilding,
    faAt,
    faAddressCard
} from '@fortawesome/pro-light-svg-icons';
import GtcText from '../language/GeneralTermsAndConditions/2023-03-01_Unified-Commerce-Holding_AGB_bilingual.pdf';

export const RegisterEvents = {
    ContactSupportRequested: "Register:ContactSupportRequested"
};

function Register() {
    const intl = useIntl();

    const { loading, setLoading } = useLoading();
    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const pageId = 'Register';

    const user = useSelector(state => state.auth.user);

    const [companyName, setCompanyName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [captcha, setCaptcha] = useState('');
    const [gtcChecked, setGtcChecked] = useState(false);
    const [emailValid, setEmailValid] = useState(true);
    const [companyNameValid, setCompanyNameValid] = useState(true);
    const [firstNameValid, setFirstNameValid] = useState(true);
    const [lastNameValid, setLastNameValid] = useState(true);
    const [captchaValid, setCaptchaValid] = useState(true);
    const [isDone, setIsDone] = useState(false);
    const [showGTCDialog, setShowGTCDialog] = useState(false);
    const [registerButtonDisabled, setRegisterButtonDisabled] = useState(true);
    const [isBlackListValid, setIsBlackListValid] = useState(false);
    const [isCompanyAlreadyResisteredValid, setIsCompanyAlreadyResisteredValid] = useState(false);
    const [isEmailAlreadyResisteredValid, setIsEmailAlreadyResisteredValid] = useState(false);

    useEffect(() => {
        loadCaptchaEnginge(6);
    }, []);

    const validateEmail = (email) => {
        let valid = validator.isEmail(email);
        if (valid) {
            setUserEmail(email);
        }
        setEmailValid(valid);
        return valid;
    };

    const validateCompanyName = (cn) => {
        let valid = !validator.isEmpty(cn);
        if (valid) {
            setCompanyName(cn);
        }
        setCompanyNameValid(valid);
        return valid;
    };

    const validateFirstName = (firstname) => {
        let valid = !validator.isEmpty(firstname);
        if (valid) {
            setFirstName(firstname);
        }
        setFirstNameValid(valid);
        return valid;
    };

    const validateLastName = (lastname) => {
        let valid = !validator.isEmpty(lastname);
        setLastNameValid(valid);
        if (valid) {
            setLastName(lastname);
        }
        return valid;
    };



    const updateCaptcha = (value) => {
        const valid = !validator.isEmpty(value);
        if (valid) {
            setCaptcha(value);
        }
        setCaptchaValid(valid);
        return valid;
    };

    const allInputFieldsValid = companyName && firstName && lastName && emailValid && userEmail && captcha;

    const getContactSupportLink = () => {
        return '<a href="#" onclick="document.dispatchEvent(new CustomEvent( \'Register:ContactSupportRequested\')); return false;" > ' + intl.formatMessage({ id: "Register.LinkText" }) + '</a>'
    }

    useEffect(() => {
        setRegisterButtonDisabled(!allInputFieldsValid || !gtcChecked);
    }, [companyName, companyNameValid, firstName, firstNameValid, lastName, lastNameValid, userEmail, emailValid, captcha, captchaValid, gtcChecked]);

    const validateBlacklistCompanyName = (value) => {
        if (value && value !== "") {
            setLoading(true)
            validateBlacklistCompanyWords(value,
                isValid => {
                    setLoading(false)
                    setIsBlackListValid(isValid);
                    dispatchNotification({
                        remove: true,
                        pageId: pageId
                    });
                    if (!isValid) {
                        dispatchNotification({
                            type: NotificationTypes.error,
                            pageId: pageId,
                            message:
                                intl.formatMessage(
                                    { id: "Register.SomethingWentWrong" },
                                    {
                                        ContactLink: getContactSupportLink()
                                    }),
                            isHtml: true
                        });
                    }
                    else {
                        doValidateCompanyNameExistance(value);
                    }
                },
                handleError);
        }
    };

    const doValidateCompanyNameExistance = (value) => {
        if (value && value !== "") {
            setLoading(true)
            validateCompanyNameExistance(value,
                result => {
                    let isValid = result != "Company already registered"
                    setLoading(false)
                    setIsCompanyAlreadyResisteredValid(isValid);
                    dispatchNotification({
                        remove: true,
                        pageId: pageId
                    });
                    if (!isValid) {
                        dispatchNotification({
                            type: NotificationTypes.error,
                            pageId: pageId,
                            message:
                                intl.formatMessage(
                                    { id: "Register.SomethingWentWrong" },
                                    {
                                        ContactLink: getContactSupportLink()
                                    }),
                            isHtml: true
                        });
                    }
                },
                handleError);
        }
    };

    const doValidateEmailExistance = (value) => {
        dispatchNotification({
            remove: true,
            pageId: pageId
        });
        if (value && value !== "") {
            setLoading(true)
            validateEmailExistance(value,
                result => {
                    setLoading(false)
                    let isValid = result != "Email already registered"
                    setIsEmailAlreadyResisteredValid(isValid);
                    dispatchNotification({
                        remove: true,
                        pageId: pageId
                    });
                    if (!isValid) {
                        dispatchNotification({
                            type: NotificationTypes.error,
                            pageId: pageId,
                            message:
                                intl.formatMessage(
                                    { id: "Register.SomethingWentWrong" },
                                    {
                                        ContactLink: getContactSupportLink()
                                    }),
                            isHtml: true
                        });
                    }
                },
                handleError);
        }
    };

    const validateRegistrationInput = () => {
        let cnValid = validateCompanyName(companyName);
        let fnValid = validateFirstName(firstName);
        let lnValid = validateLastName(lastName);
        let emlValid = validateEmail(userEmail);

        return cnValid && fnValid && lnValid && emlValid && captchaValid && gtcChecked;
    };

    const handleError = (errorMessage, showNotif = true) => {
        setLoading(false);
        if (showNotif) {
            if (errorMessage === "InvalidData") {
                errorMessage =
                    intl.formatMessage(
                        { id: "Register.SomethingWentWrong" },
                        {
                            ContactLink: getContactSupportLink()
                        });
            }

            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            });
        }
    };

    const removeNotification = () => {
        dispatchNotification({
            remove: true,
            pageId: pageId
        });
    };

    function register() {
        if (!validateRegistrationInput()) {
            return;
        }

        if (!validateCaptcha(captcha)) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: 'The captcha value does not match, please try again.'
            });
            return;
        }

        setLoading(true);
        removeNotification();
        registerUser({
            CompanyName: companyName,
            Email: userEmail,
            UserName: userEmail,
            FirstName: firstName,
            LastName: lastName
        },
            () => {
                setLoading(false);
                setRegisterButtonDisabled(true);
                setIsDone(true);
            },
            handleError
        );
    }

    const gtcClicked = () => {
        setShowGTCDialog(true);
    };

    const closeGTCDialog = () => {
        setShowGTCDialog(false);
    };

    useEffect(() => {
        on(RegisterEvents.ContactSupportRequested, openContactSupport);

        return () => {
            off(RegisterEvents.ContactSupportRequested, openContactSupport);
        };
    }, []);

    const [isContactSupportVisible, setIsContactSupportVisible] = useState(false);

    const openContactSupport = () => {
        setIsContactSupportVisible(true);
    };

    const handleContactSupportClosed = () => {
        setIsContactSupportVisible(false);
    };

    if (user) {
        return (<Redirect to={'/'} />);
    }

    const onCompanyNameBlurEvent = event => {
        validateBlacklistCompanyName(event.target.value);
    };

    const onEmailBlurEvent = event => {
        doValidateEmailExistance(event.target.value);
    };


    return (
        <div className='container login-container'>
            {loading && <Loading />}
            {isContactSupportVisible && (
                <ContactSupport
                    supportType={SupportType.Default}
                    isButtonVisible={false}
                    isGeneralEnquiryVisible={false}
                    onClosed={handleContactSupportClosed}
                />
            )}
            <Row>
                <Col xs='12'>
                    <div id='logo-container'>
                        <img alt="Stat Logo" src={StatLogo} />
                    </div>
                </Col>
            </Row>
            <hr />
            {!isDone && (
                <>
                    <div className='row'>
                        <div className='col-sm-2 hidden-xs'></div>
                        <div className='col-sm-8 col-xs-12'>
                            <Form className='form-horizontal'>
                                <InputGroup>
                                    <InputGroupAddon addonType='prepend'>
                                        <InputGroupText>
                                            <FontAwesomeIcon icon={faBuilding} className='icon-blue' />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input name="companyNameValue" placeholder='Company name' invalid={!companyNameValid} onChange={(event) => {
                                        validateCompanyName(event.target.value);
                                    }}
                                        onBlur={onCompanyNameBlurEvent}

                                    />
                                </InputGroup>

                                <InputGroup>
                                    <InputGroupAddon addonType='prepend'>
                                        <InputGroupText>
                                            <FontAwesomeIcon icon={faAddressCard} />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder='First name' invalid={!firstNameValid} onChange={(event) => {
                                        validateFirstName(event.target.value);
                                    }} />
                                </InputGroup>

                                <InputGroup>
                                    <InputGroupAddon addonType='prepend'>
                                        <InputGroupText>
                                            <FontAwesomeIcon icon={faAddressCard} />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder='Last name' invalid={!lastNameValid} onChange={(event) => {
                                        validateLastName(event.target.value);
                                    }} />
                                </InputGroup>

                                <InputGroup>
                                    <InputGroupAddon addonType='prepend'>
                                        <InputGroupText>
                                            <FontAwesomeIcon icon={faAt} />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input name="emailValue" placeholder='Email address' invalid={!emailValid} onChange={(event) => {
                                        validateEmail(event.target.value);
                                    }}
                                        onBlur={onEmailBlurEvent}
                                    />
                                </InputGroup>

                                <Row>
                                    <Col xs="7">
                                        <InputGroup>
                                            <InputGroupAddon addonType='prepend'>
                                                <InputGroupText>
                                                    <FontAwesomeIcon icon={faRobot} />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="Enter captcha"
                                                maxLength={6}
                                                invalid={!captchaValid}
                                                onChange={(event) => {
                                                    updateCaptcha(event.target.value);
                                                }}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col xs="5">
                                        <InputGroup>
                                            <LoadCanvasTemplate reloadColor="rgb(65, 182, 230)" />
                                        </InputGroup>
                                    </Col>
                                </Row>

                                <InputGroup>
                                    <Switch
                                        onChange={(event) => { setGtcChecked(event.target.value) }}
                                        checked={gtcChecked}
                                        onLabel={intl.formatMessage({ id: 'Control.Yes' })}
                                        offLabel={intl.formatMessage({ id: 'Control.No' })}
                                    />
                                    <span>
                                        <FormattedMessage id='Control.GtcText' />
                                        <Link className="info-color" to={GtcText} target="_blank">
                                            <FormattedMessage id='Control.Gtc' />
                                        </Link>
                                    </span>
                                </InputGroup>

                            </Form>

                            <Row className="login-form-submit">
                                <Col xs='6'>
                                    <Link className='btn btn-secondary btn-block' to='/'><FormattedMessage id='Control.Cancel' /></Link>
                                </Col>
                                <Col xs='6'>
                                    <Button color='primary' block disabled={registerButtonDisabled || !isBlackListValid || !isCompanyAlreadyResisteredValid || !isEmailAlreadyResisteredValid} onClick={() => register()} >
                                        Register
                                    </Button>
                                </Col>
                            </Row>

                        </div>
                        <div className='col-sm-2 hidden-xs'></div>
                    </div>
                    <CookieConsent />
                    <GeneralTermsDialog visible={showGTCDialog} onClose={closeGTCDialog} />
                </>
            )}
            {isDone && (
                <div>
                    <h2 className="text-center">Thank you for registering to StatControl!</h2>
                    <h4 className="text-center">We have sent you an email to {userEmail}. Please follow the instructions in the email to complete your registration.</h4>
                </div>
            )}
        </div>
    );
}

export default Register
