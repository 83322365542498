import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, FormGroup, Button, Input, Label } from "reactstrap";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ListBox, processListBoxDragAndDrop } from '@progress/kendo-react-listbox';
import { v4 as uuidv4 } from "uuid";
import {
    uploadInventoryFileChunk,
    finalizeInventoryFileChunkUpload,
    importUploadedFileData,
    getStockDataColumnMapping,
    deleteTempFile
} from '../../../../services/inventoryService';
import {
    loadLastUsedMappingByCustomer,
    loadMappingByCustomer,
    saveMappingFromImport
} from '../../../../services/interfaceConfigurationsService';
import { trigger, on, off } from '../../../../actions/events'
import { wizardEvents, wizardStep } from '../Wizard'
import RequiredFieldSign from './RequiredFieldSign'
import ProgressIndicator from '../../../Shared/ProgressIndicator'
import ConfirmDialog from '../../../Shared/ConfirmDialog';
import { useNotification } from '../../../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../../../Shared/Notifications/Notification';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLoading } from "../../../Shared/LoadingContext";
import { useExceptionDialog } from '../../../Shared/ExceptionDialog/ExceptionDialogProvider';
import { useSelector } from 'react-redux';
import { WizzardContext, WIZZARD_ACTIONS } from "../WizzardContext";
import License from '../../../License/License';
import { UserContext } from '../../../UserContext';
import validator from 'validator';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { deepClone } from '../../../../utils/jsonUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons'

const mapToUniqueId = [];
const mapToAmount = [];
const mapToPrice = [];
const mapToQuantityUnit = [];
const mapToPriceUnit = [];
const mapToArticleDescription = [];
const mapToAdditionalFields = [];

const FILE_UPLOAD_CHUNK_SIZE_MB = 1;
const SELECTED_FIELD = 'selected';
const MULTILINE_DROP_TARGETS = ['importedColumns', 'mapToUniqueId', 'mapToAdditionalFields'];
const MAX_UNIQUEID_COL_COUNT = 15;
const MAX_ADDITIONAL_COL_COUNT = 15;

export const MappedColumn = {
    None: 0,
    UniqueId: 1,
    Amount: 2,
    Price: 3,
    QuantityUnit: 4,
    PriceUnit: 5,
    ArticleDescription: 6,
    AdditionalFields: 7
};

const CsvMapper = (props) => {
    const selectedPopulation = useSelector(rxState => rxState.dashboard.population);
    const currentCustomer = useSelector(rxState => rxState.dashboard.customer);
    const intl = useIntl();
    const dispatchNotification = useNotification();
    const [initalHeaderColumns, setInitalHeaderColumns] = useState([])
    const pageId = 'pageInventoryWizard.ColumnMapping';
    const notifyError = (errorMessage, isHtml = false) => dispatchNotification({
        type: NotificationTypes.error,
        pageId: pageId,
        message: errorMessage,
        isHtml: isHtml
    });

    const dispatchExceptionDialog = useExceptionDialog();
    const [mappingsVisible, setMappingsVisible] = React.useState(false)

    const { setLoading } = useLoading();

    const [state, setState] = React.useState({
        columnHeaders: props.importFileInfo.columnHeaders ? deepClone(props.importFileInfo.columnHeaders) : [],
        importedColumns: props.importFileInfo.columnHeaders ? deepClone(props.importFileInfo.columnHeaders) : [],
        importFile: props.importFileInfo.importFile,
        mapToUniqueId,
        mapToAmount,
        mapToPrice,
        mapToQuantityUnit,
        mapToPriceUnit,
        mapToArticleDescription,
        mapToAdditionalFields,
        draggedItem: {},
        test: []
    });

    const wizzardContext = React.useContext(WizzardContext);
    const isSequential = wizzardContext.wizzardGlobal.Import.InventoryMethod === window.enumInventoryMethod.Staseq;

    const userContext = React.useContext(UserContext);
    const isUserAdmin = userContext.userGlobal.userInfo?.isStatcontrolAdmin || userContext.userGlobal.userInfo?.isAdminUser;

    const [loadingCount, setLoadingCount] = React.useState(0);
    useEffect(() => {
        setLoading(loadingCount > 0);
    }, [loadingCount]);

    const incrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount + 1);
    };

    const decrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount - 1);
    }

    const pushMappingToState = (mapping, state, mapType) => {
        switch (mapType) {
            case MappedColumn.UniqueId:
                state.mapToUniqueId.push(mapping);
                break;
            case MappedColumn.Amount:
                state.mapToAmount.push(mapping);
                break;
            case MappedColumn.QuantityUnit:
                state.mapToQuantityUnit.push(mapping);
                break;
            case MappedColumn.Price:
                state.mapToPrice.push(mapping);
                break;
            case MappedColumn.PriceUnit:
                state.mapToPriceUnit.push(mapping);
                break;
            case MappedColumn.ArticleDescription:
                state.mapToArticleDescription.push(mapping);
                break;
            case MappedColumn.AdditionalFields:
                state.mapToAdditionalFields.push(mapping);
                break;
            default:
        }
    }

    const mapColumn = (item, state, columnType) => {
        let isColumnImported = state.importedColumns.some(x => x.ColumnName === item.columnName && x.ColumnNo === item.columnNo);

        if (item.columnName && isColumnImported) {
            let mapping = {
                ColumnName: item.columnName,
                ColumnNo: item.columnNo,
                selected: false,
                dataCollection: 'importedColumns'
            };

            pushMappingToState(mapping, state, columnType);

            state.importedColumns = state.importedColumns.filter(x => x.ColumnName !== item.columnName);
        }
    }

    const loadColumnMappingSuccess = (data) => {
        decrementLoadingCount();
        let st = {
            ...state,
            mapToUniqueId: [],
            mapToAmount: [],
            mapToPrice: [],
            mapToQuantityUnit: [],
            mapToPriceUnit: [],
            mapToArticleDescription: [],
            mapToAdditionalFields: []
        }

        data.uniqueId.forEach((item) => {
            mapColumn(item, st, MappedColumn.UniqueId);
        });

        mapColumn(data.quantity, st, MappedColumn.Amount);
        mapColumn(data.quantityUnit, st, MappedColumn.QuantityUnit);
        mapColumn(data.singlePrice, st, MappedColumn.Price);
        mapColumn(data.priceUnit, st, MappedColumn.PriceUnit);
        mapColumn(data.itemName, st, MappedColumn.ArticleDescription);

        data.additionalColumns.forEach((item) => {
            mapColumn(item, st, MappedColumn.AdditionalFields);
        });

        setState(st);

        wizzardContext.dispatchWizzardGlobal({
            type: WIZZARD_ACTIONS.SetCsvMapperState,
            payload: st
        });
    }

    const loadStockDataColumnMapping = () => {
        incrementLoadingCount();
        getStockDataColumnMapping(selectedPopulation && selectedPopulation.populationId,
            loadColumnMappingSuccess,
            handleError);
    }

    const initializeState = (isFromContinueButtonPessed) => {
        if (isFromContinueButtonPessed) {
            setState(wizzardContext.wizzardGlobal.CsvMapper);
        }
        else {
            let st = {
                ...wizzardContext.wizzardGlobal.CsvMapper,
                importedColumns: deepClone(wizzardContext.wizzardGlobal.CsvMapper.notMappedColumns),
                notMappedColumns: null
            };
            setState(st);
            wizzardContext.dispatchWizzardGlobal({
                type: WIZZARD_ACTIONS.SetCsvMapperState,
                payload: st
            });
        }
    }

    useEffect(() => {
        if (props.skipColumnMapping) {
            registerMappingAndProceedWithUpload();
        }
        else {
            let headerCols = wizzardContext.wizzardGlobal.CsvMapper.columnHeaders ? deepClone(wizzardContext.wizzardGlobal.CsvMapper.columnHeaders) : [];
            setInitalHeaderColumns(headerCols);

            let isFromContinueButtonPessed = !wizzardContext.wizzardGlobal.CsvMapper.notMappedColumns;

            if (isFromContinueButtonPessed) {
                loadMappings(true, true);
            }
            else {
                loadMappings(false, false);
            }

            if (wizzardContext.wizzardGlobal.CsvMapper.mapToUniqueId.length === 0 &&
                (wizzardContext.wizzardGlobal.CsvMapper.mapToPrice.length === 0 || isSequential) &&
                wizzardContext.wizzardGlobal.CsvMapper.mapToAmount.length === 0) {
                loadStockDataColumnMapping();
            }
            else {
                initializeState(isFromContinueButtonPessed);
            }
        }
    }, []);

    const handleError = (errorMessage, showNotif = true) => {
        decrementLoadingCount();
        if (showNotif) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            })
        }
    };

    const [showProgress, setShowProgress] = useState(false)
    const [progress, setProgress] = useState(0)
    const [progressMessage, setProgressMessage] = useState(0)
    const [fileSize, setFileSize] = useState(0)
    const [fileGuid, setFileGuid] = useState("")
    const [fileToBeUpload, setFileToBeUpload] = useState({})
    const [columnCountThresholdText, setColumnCountThresholdText] = useState("")
    const [notAllowedByLicenseVisibility, setNotAllowedByLicenseVisibility] = useState(false)
    const [isLicenseVisible, setIsLicenseVisible] = React.useState(false);

    const chunkSize = 1048576 * FILE_UPLOAD_CHUNK_SIZE_MB;
    const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0)
    const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize)
    const [chunkCount, setChunkCount] = useState(0)
    const [chunkCounter, setChunkCounter] = useState(1)
    const importProgress = useRef(0);
    const isCustomerWithoutLicense = currentCustomer.activeLicense.isFreeTrial;

    const [saveDialogVisible, setSaveDialogVisible] = useState(false)
    const [configuratioNameValid, setConfiguratioNameValid] = useState(false)
    const [configuratioName, setConfiguratioName] = useState("")
    const [mappings, setMappings] = useState([])


    const [selectedMappingValid, setSelectedMappingValid] = useState(false)
    const [selectedMapping, setSelectedMapping] = useState(null)
    const [tempSelectedMapping, setTempSelectedMapping] = useState(false)

    const handleItemClick = (event, data, connectedData) => {
        setState({
            ...state,
            [data]: state[data].map(item => {
                if (item.ColumnName === event.dataItem.ColumnName) {
                    item[SELECTED_FIELD] = !item[SELECTED_FIELD];
                } else if (!event.nativeEvent.ctrlKey) {
                    item[SELECTED_FIELD] = false;
                }

                return item;
            }),
            [connectedData]: state[connectedData].map(item => {
                item[SELECTED_FIELD] = false;
                return item;
            })
        });
    };

    const handleDragStart = e => {
        let target = e.target;
        e.dataItem.dataCollection = target.props.name || '';
        setState({
            ...state,
            draggedItem: e.dataItem
        });
    };

    const handleDrop = e => {
        let target = e.target;
        let dragItemData = state.draggedItem.dataCollection;
        let dropItemData = target.props.name;
        if (dropItemData === "mapToUniqueId" && state.mapToUniqueId.length >= MAX_UNIQUEID_COL_COUNT) {
            setColumnCountThresholdText(<FormattedMessage id="CsvMapper.ColumnMappingCountExceeded" values={{ columnName: intl.formatMessage({ id: 'CsvMapper.UniqueId' }) }} />);
        }
        else if (dropItemData === "mapToAdditionalFields" && state.mapToAdditionalFields.length >= MAX_ADDITIONAL_COL_COUNT) {
            setColumnCountThresholdText(<FormattedMessage id="CsvMapper.ColumnMappingCountExceeded" values={{ columnName: intl.formatMessage({ id: 'CsvMapper.AdditionalFields' }) }} />);
        }
        else {
            if (state[dropItemData].length === 0 || MULTILINE_DROP_TARGETS.indexOf(dropItemData) > -1) {
                let result = processListBoxDragAndDrop(state[dragItemData], state[dropItemData], state.draggedItem, e.dataItem, 'ColumnName');
                let st = {
                    ...state,
                    [dragItemData]: result.listBoxOneData,
                    [dropItemData]: result.listBoxTwoData
                };
                setState(st);

                wizzardContext.dispatchWizzardGlobal({
                    type: WIZZARD_ACTIONS.SetCsvMapperState,
                    payload: st
                });
            }
        }
    };

    const removeNotification = () => {
        dispatchNotification({
            remove: true,
            pageId: pageId
        });
    };

    const uploadInventoryFile = () => {
        const resetChunkProperties = () => {
            setShowProgress(true)
            setProgressMessage(<FormattedMessage id='CsvMapper.WaitForUploadInventoryDataFile' />)
            setProgress(0)
            setChunkCounter(1)
            setBeginingOfTheChunk(0)
            setEndOfTheChunk(chunkSize)
        }

        resetChunkProperties();
        const _filesize = props.importFileInfo.importFile.size || 0;
        setFileSize(_filesize);
        const _totalCount = _filesize % chunkSize === 0 ? _filesize / chunkSize : Math.floor(_filesize / chunkSize) + 1;
        setChunkCount(_totalCount)
        setFileToBeUpload(props.importFileInfo.importFile)
        if (_filesize > 0) {
            trigger(wizardEvents.validStateOfCurrentStepChanged, { step: wizardStep.ColumnMapping, isValid: false });
            const _fileID = uuidv4() + "." + props.importFileInfo.importFile.name.split('.').pop();
            setFileGuid(_fileID);
        }
    }

    const getColumnMapping = () => {
        return {
            UniqueId: state.mapToUniqueId,
            Quantity: state.mapToAmount[0],
            SinglePrice: state.mapToPrice[0],
            QuantityUnit: state.mapToQuantityUnit[0],
            PriceUnit: state.mapToPriceUnit[0],
            ItemName: state.mapToArticleDescription[0],
            AdditionalColumns: state.mapToAdditionalFields
        }
    }

    const importUploadedData = async () => {
        setLoading(true);
        setProgressMessage(<FormattedMessage id='CsvMapper.WaitForImportingUploadedData' />)
        const response = await importUploadedFileData(fileGuid, props.importFileInfo.importFile.name, getColumnMapping(), selectedPopulation ? selectedPopulation.populationId : null);
        const data = response.data;
        setLoading(false);
        if (!data.succeeded) {
            notifyError(<FormattedMessage id='CsvMapper.InventoryFileImportError' />);
            if (data.errors)
                data.errors.map(item => console.log(item));
            return { succeeded: false }
        }
        else {
            trigger(wizardEvents.stepFinalized, {});
            return { succeeded: true }
        }
    }

    const uploadCompleted = async () => {
        setLoading(true);
        const response = await finalizeInventoryFileChunkUpload(fileGuid, currentCustomer.customerId, selectedPopulation.populationId);
        setLoading(false);
        const data = response.data;
        if (data.succeeded) {
            importProgress.current = 2;
            return importUploadedData();
        }
        else if (data.message === "NotAllowedByLicense") {
            setNotAllowedByLicenseVisibility(true);
            return { succeeded: false };
        }
        else {
            if (data.errors)
                data.errors.map(item => console.log(item));
            return { succeeded: false };
        }
    }

    const uploadChunk = async (chunk, counter) => {
        try {
            const response = await uploadInventoryFileChunk(chunk, counter, fileGuid, selectedPopulation.populationId);
            const data = response.data;
            if (data.succeeded) {
                let percentage = (counter / chunkCount) * 100;
                setProgress(percentage);
                setBeginingOfTheChunk(endOfTheChunk);
                setEndOfTheChunk(endOfTheChunk + chunkSize);
                if (counter === chunkCount) {
                    console.log('Inventory file upload is completed, chunk count:', counter);
                    importProgress.current = 1;
                    const importResult = await uploadCompleted();

                    if (importResult.succeeded) {
                        console.log('Uploaded inventory file processing is successfully completed');
                    }
                    else {
                        console.error('Error processing uploaded inventory file!');
                    }

                    return importResult;
                }
                return { succeeded: true };
            } else {
                console.log('Error Occurred:', data.message);
                if (data.errors)
                    data.errors.map(item => console.log(item));
                return { succeeded: false };
            }
        } catch (error) {
            setLoading(false);
            console.log('error', error);
            dispatchExceptionDialog({ pageId: pageId, message: error });
            displayImportFailedNotification();
            setShowProgress(false);
            return { succeeded: false };
        }
    }

    const fileUpload = async (counter) => {
        if (counter <= chunkCount) {
            let chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
            const result = await uploadChunk(chunk, counter);
            if (result.succeeded) {
                if (counter < chunkCount)
                    setChunkCounter(counter + 1);
            }
        }
    }

    const registerMappingAndProceedWithUpload = () => {
        let st = {
            ...state,
            notMappedColumns: state.importedColumns ? deepClone(state.importedColumns) : []
        }
        wizzardContext.dispatchWizzardGlobal({
            type: WIZZARD_ACTIONS.SetCsvMapperState,
            payload: st
        });
        uploadInventoryFile();
    }

    const continueButtonClicked = (event) => {
        removeNotification();
        if (event.detail === wizardStep.ColumnMapping) {
            registerMappingAndProceedWithUpload();
        }
    }

    const loadMappingsButtonClicked = (event) => {
        event.preventDefault();
        if (mappings && mappings.length > 0) {
            setMappingsVisible(true);
            if (selectedMapping) {
                setConfiguratioName(selectedMapping.interfaceConfigurationName);
            }
        }
    }

    const loadMappings = (isInitial, loadLastMapping) => {
        incrementLoadingCount();

        loadMappingByCustomer(
            currentCustomer.customerId,
            items => {
                decrementLoadingCount();
                setMappings(items);

                if (loadLastMapping) {
                    loadLastUsedMappings(isInitial);
                }

            },
            handleError);
    }

    const loadLastUsedMappings = (isInitial) => {
        incrementLoadingCount();

        if (selectedPopulation) {
            loadLastUsedMappingByCustomer(
                selectedPopulation.populationId,
                item => {
                    decrementLoadingCount();
                    if (item) {
                        item.isSavedInMapper = true;
                        wizzardContext.dispatchWizzardGlobal({
                            type: WIZZARD_ACTIONS.SetInterfaceConfiguration,
                            payload: item
                        });

                        populateControlsFromSelectMappings(item, isInitial ? props.importFileInfo.columnHeaders : initalHeaderColumns);
                    }
                },
                handleError);
        }
    }

    const saveMappingsButtonClicked = async () => {
        removeNotification();
        if (selectedMapping) {
            setConfiguratioName(selectedMapping.interfaceConfigurationName);
        }
        else {
            setConfiguratioName("");
        }

        setConfiguratioNameValid(false);
        setSaveDialogVisible(true);
    }

    const handleSaveMappings = async (event) => {
        event.preventDefault();
        setLoading(true);
        removeNotification();
        if (mappings && mappings.some((item) => item.interfaceConfigurationName.trim().toLowerCase() === configuratioName.trim().toLowerCase())) {
            notifyError(<FormattedMessage id='CsvMapper.ThereIsAlreadyAnConfigurationWithTheSameName' />);
            setLoading(false);
        }
        else {
            setLoading(false);
            await doSaveMappings(null);
        }
    }

    const doSaveMappings = async (currentInterfaceConfigurationId) => {
        setLoading(true);
        try {
            let tempMappings = getColumnMapping()

            const response = await saveMappingFromImport(currentCustomer.customerId, selectedPopulation.populationId, currentInterfaceConfigurationId, configuratioName, tempMappings)
            const data = response.data;

            if (!data.succeeded) {
                notifyError(<FormattedMessage id='CsvMapper.ErrorOnSavingMappings' />);
            }
            else {
                setSaveDialogVisible(false);
                loadMappings(false, true);
            }
        }
        catch (err) {
            setSaveDialogVisible(false);
            dispatchExceptionDialog({
                pageId: pageId,
                message: err.response.data
            })
        }
        finally {
            setLoading(false);
        }
    }

    const displayImportFailedNotification = () => {
        let failMsg = 'Error uploading inventory file';
        if (importProgress.current > 0)
            failMsg = 'Error processing uploaded data';

        notifyError(failMsg);
    }

    useEffect(() => {
        if (fileSize > 0 && chunkCounter <= chunkCount) {
            fileUpload(chunkCounter);
        }
    }, [chunkCounter, fileSize, chunkCount]);

    const handleResetCSVMappings = () => {
        let st = {
            ...state,
            importedColumns: initalHeaderColumns ? deepClone(initalHeaderColumns) : [],
            mapToUniqueId: [],
            mapToAmount: [],
            mapToPrice: [],
            mapToQuantityUnit: [],
            mapToPriceUnit: [],
            mapToArticleDescription: [],
            mapToAdditionalFields: [],
            draggedItem: {}
        }
        setState(st);
        wizzardContext.dispatchWizzardGlobal({
            type: WIZZARD_ACTIONS.SetCsvMapperState,
            payload: st
        });
    }

    useEffect(() => {
        on(wizardEvents.continueButtonClicked, continueButtonClicked);
        on(wizardEvents.loadMappingsButtonClicked, loadMappingsButtonClicked);
        on(wizardEvents.saveMappingsButtonClicked, saveMappingsButtonClicked);
        on(wizardEvents.resetMappingsButtonClicked, handleResetCSVMappings);


        return () => {
            off(wizardEvents.continueButtonClicked, continueButtonClicked);
            off(wizardEvents.loadMappingsButtonClicked, loadMappingsButtonClicked);
            off(wizardEvents.saveMappingsButtonClicked, saveMappingsButtonClicked);
            off(wizardEvents.resetMappingsButtonClicked, handleResetCSVMappings);
        }
    }, [continueButtonClicked, loadMappingsButtonClicked, saveMappingsButtonClicked, handleResetCSVMappings]);

    useEffect(() => {
        trigger(wizardEvents.validStateOfCurrentStepChanged, {
            step: wizardStep.ColumnMapping, isValid:
                state.mapToUniqueId.length > 0 && state.mapToUniqueId.length <= MAX_UNIQUEID_COL_COUNT &&
                state.mapToAmount.length > 0 &&
                (state.mapToPrice.length > 0 || isSequential) &&
                state.importFile.name !== undefined
        });
    }, [state, state.mapToUniqueId, state.mapToAmount, state.mapToPrice, notAllowedByLicenseVisibility]);

    useEffect(() => {
        props.setResetBtnDisabled(
            state.mapToUniqueId.length == 0 &&
            state.mapToAmount.length == 0 &&
            state.mapToPrice.length == 0 &&
            state.mapToQuantityUnit.length == 0 &&
            state.mapToPriceUnit.length == 0 &&
            state.mapToArticleDescription.length == 0 &&
            state.mapToAdditionalFields.length == 0
        );
    }, [state]);

    const onCloseColumnThresholdDialog = () => {
        setColumnCountThresholdText("");
    };

    const showColumnInfo = (column) => {
        props.onShowColumnInfo(column);
    };

    const onNotAllowedByLicenseDialog = async (isConfirmed) => {
        setNotAllowedByLicenseVisibility(false);

        if (isConfirmed) {
            importProgress.current = 2;
            await importUploadedData();
        }
        else {
            setLoading(true);
            const response = await deleteTempFile(fileGuid, currentCustomer.customerId, selectedPopulation.populationId);
            setLoading(false);
            setShowProgress(false);
            const data = response.data;

            if (data.succeeded) {
                setProgress(0)
                setChunkCounter(1)
                setChunkCount(0)
            }
            else {
                if (data.errors)
                    data.errors.map(item => console.log(item));
                return { succeeded: false };
            }
        }
    };

    const onNotAllowedByLicenseDialogNeutral = async (_) => {
        setNotAllowedByLicenseVisibility(false);
        setIsLicenseVisible(true)
    };

    const handleLicenseClose = () => {
        setIsLicenseVisible(false);
    };

    const getNotAllowedByLicenseVisibilityDetail = () => {
        const result = isUserAdmin
            ? intl.formatMessage({ id: 'CsvMapper.NumberOfPositionsExceededAdmin' })
            : intl.formatMessage({ id: 'CsvMapper.NumberOfPositionsExceededNonAdmin' });
        return result + (!isCustomerWithoutLicense ? (" " + intl.formatMessage({ id: 'CsvMapper.DoYouWantToContinueTheImportWithYourCurrentSubscription' })) : "");
    };

    const validateConfiguratioName = (value) => {
        setConfiguratioName(value);
    }

    useEffect(() => {
        if (configuratioName) {
            let valid = !validator.isEmpty(configuratioName);
            setConfiguratioNameValid(valid);
        }
        else {
            setConfiguratioNameValid(false);
        }
    }, [configuratioName]);

    const handleOverride = (event) => {
        event.preventDefault();
        doSaveMappings(selectedMapping.interfaceConfigurationId);
    }

    const handleCancel = (event) => {
        event.preventDefault();
        setSaveDialogVisible(false);
    }

    const handleMappingChange = (value) => {
        setTempSelectedMapping(value.value);
        if (value.value.interfaceConfigurationId) {
            setSelectedMappingValid(true);
        }
    }

    const handleSelectMappings = (event) => {
        event.preventDefault();
        setSelectedMapping(tempSelectedMapping)
        setMappingsVisible(false);
        populateControlsFromSelectMappings(tempSelectedMapping, initalHeaderColumns);
    }

    const populateFromMapping = (element, state, mapType) => {
        let colHeaderIndex = props.getColumnHeaderIndex(state.importedColumns, element.columnName);
        if (colHeaderIndex >= 0) {
            pushMappingToState(state.importedColumns[colHeaderIndex], state, mapType);
            state.importedColumns = state.importedColumns.filter(ic => ![state.importedColumns[colHeaderIndex]].includes(ic));
        }
    }

    const populateControlsFromSelectMappings = (mapping, importedColumns) => {
        removeNotification();

        if (!mapping) {
            return
        }

        let st = {
            ...state,
            importedColumns: importedColumns ? deepClone(importedColumns) : [],
            mapToUniqueId: [],
            mapToAmount: [],
            mapToPrice: [],
            mapToQuantityUnit: [],
            mapToPriceUnit: [],
            mapToArticleDescription: [],
            mapToAdditionalFields: [],
            draggedItem: {}
        }

        if (mapping.additionalColumns && mapping.additionalColumns.length) {
            mapping.additionalColumns.forEach(element => {
                populateFromMapping(element, st, MappedColumn.AdditionalFields);
            });
        }
        if (mapping.uniqueId && mapping.uniqueId.length) {
            mapping.uniqueId.forEach(element => {
                populateFromMapping(element, st, MappedColumn.UniqueId);
            });
        }
        if (mapping.priceUnit) {
            populateFromMapping(mapping.priceUnit, st, MappedColumn.PriceUnit);
        }
        if (mapping.quantity) {
            populateFromMapping(mapping.quantity, st, MappedColumn.Amount);
        }
        if (mapping.quantityUnit) {
            populateFromMapping(mapping.quantityUnit, st, MappedColumn.QuantityUnit);
        }
        if (mapping.singlePrice) {
            populateFromMapping(mapping.singlePrice, st, MappedColumn.Price);
        }
        if (mapping.itemName) {
            populateFromMapping(mapping.itemName, st, MappedColumn.ArticleDescription);
        }

        if (props.checkMappingsDiscrepancies(mapping, importedColumns)) {
            notifyError(<FormattedMessage id='CsvMapper.DiscrepanciesWereFoundDuringApplyingColumnMapping' />);
        }

        setTempSelectedMapping(mapping);
        setSelectedMapping(mapping);
        props.setInterfaceConfigurationUsed(mapping);
        setState(st);
        wizzardContext.dispatchWizzardGlobal({
            type: WIZZARD_ACTIONS.SetCsvMapperState,
            payload: st
        });
    }

    const handleSelectCancel = (event) => {
        event.preventDefault();
        setMappingsVisible(false);
    }

    return <div>
        <ConfirmDialog
            visible={columnCountThresholdText}
            onClose={onCloseColumnThresholdDialog}
            negative={<FormattedMessage id="Control.Cancel" />}
            title={<FormattedMessage id="CsvMapper.ColumnThresholdConfirmationTitle" />}
            detail={columnCountThresholdText}
        />
        <ConfirmDialog
            visible={notAllowedByLicenseVisibility}
            onClose={onNotAllowedByLicenseDialog}
            onNeutral={onNotAllowedByLicenseDialogNeutral}
            negative={isCustomerWithoutLicense ? <FormattedMessage id="Control.Ok" /> : <FormattedMessage id="Control.Close" />}
            positive={!isCustomerWithoutLicense ? <FormattedMessage id="Control.Continue" /> : ""}
            neutral={wizzardContext.wizzardGlobal.SupportData && wizzardContext.wizzardGlobal.SupportData.inventoryType == window.enumInventoryType.PerpetualInventorySample ? <FormattedMessage id="Control.Upgrade" /> : null}
            title={<FormattedMessage id="CsvMapper.WarningConfirmationTitle" />}
            detail={getNotAllowedByLicenseVisibilityDetail()}
            positiveDisabled={isCustomerWithoutLicense}
            width={300}
        />
        {isLicenseVisible && (
            <License
                onClose={handleLicenseClose}
            />
        )}
        {!props.skipColumnMapping &&
            <div className='row justify-content-between'>
                <Col xs="6">
                    <p className="mapper-title"><FormattedMessage id='CsvMapper.ImportedColumns' /> </p>
                    <ListBox
                        className="import-columns"
                        data={state.importedColumns ? state.importedColumns : []} textField="ColumnName" selectedField={SELECTED_FIELD}
                        onItemClick={e => handleItemClick(e, 'importedColumns', 'mapToUniqueId')}
                        onDragStart={handleDragStart}
                        onDrop={handleDrop}
                        name='importedColumns'
                    />
                </Col>
                <Col xs="6">
                    <Col xs="12" className='mapped-inv-col-group-multi'>
                        <p className="mapper-title"><FormattedMessage id='CsvMapper.MapTo' /> <b><FormattedMessage id='CsvMapper.UniqueId' /></b></p>
                        <RequiredFieldSign requiredData={state.mapToUniqueId} />
                        <span className="mapper-info" onClick={() => showColumnInfo(MappedColumn.UniqueId)}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </span>
                        <ListBox data={state.mapToUniqueId} textField="ColumnName" selectedField={SELECTED_FIELD}
                            onItemClick={e => handleItemClick(e, 'mapToUniqueId', 'importedColumns')}
                            onDragStart={handleDragStart} onDrop={handleDrop} name='mapToUniqueId'
                        />

                    </Col>
                    <Col xs="12" className='mapped-inv-col-group-single'>
                        <p className="mapper-title"><FormattedMessage id='CsvMapper.MapTo' /> <b><FormattedMessage id='CsvMapper.Amount' /></b></p>
                        <RequiredFieldSign requiredData={state.mapToAmount} />
                        <span className="mapper-info" onClick={() => showColumnInfo(MappedColumn.Amount)}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </span>
                        <ListBox data={state.mapToAmount} textField="ColumnName" selectedField={SELECTED_FIELD}
                            onItemClick={e => handleItemClick(e, 'mapToAmount', 'importedColumns')}
                            onDragStart={handleDragStart} onDrop={handleDrop} name='mapToAmount'
                        />
                    </Col>
                    <Col xs="12" className='mapped-inv-col-group-single'>
                        <p className="mapper-title"><FormattedMessage id='CsvMapper.MapTo' /> <b><FormattedMessage id='CsvMapper.Price' /></b></p>
                        {!isSequential && <RequiredFieldSign requiredData={state.mapToPrice} />}
                        <span className="mapper-info" onClick={() => showColumnInfo(MappedColumn.Price)}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </span>
                        <ListBox data={state.mapToPrice} textField="ColumnName" selectedField={SELECTED_FIELD}
                            onItemClick={e => handleItemClick(e, 'mapToPrice', 'importedColumns')}
                            onDragStart={handleDragStart} onDrop={handleDrop} name='mapToPrice'
                        />
                    </Col>
                    <Col xs="12" className='mapped-inv-col-group-single'>
                        <p className="mapper-title"><FormattedMessage id='CsvMapper.MapTo' /> <b><FormattedMessage id='CsvMapper.QuantityUnit' /></b></p>
                        <span className="mapper-info" onClick={() => showColumnInfo(MappedColumn.QuantityUnit)}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </span>
                        <ListBox data={state.mapToQuantityUnit} textField="ColumnName" selectedField={SELECTED_FIELD}
                            onItemClick={e => handleItemClick(e, 'mapToQuantityUnit', 'importedColumns')}
                            onDragStart={handleDragStart} onDrop={handleDrop} name='mapToQuantityUnit'
                        />
                    </Col>
                    <Col xs="12" className='mapped-inv-col-group-single'>
                        <p className="mapper-title"><FormattedMessage id='CsvMapper.MapTo' /> <b><FormattedMessage id='CsvMapper.PriceUnit' /></b></p>
                        <span className="mapper-info" onClick={() => showColumnInfo(MappedColumn.PriceUnit)}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </span>
                        <ListBox data={state.mapToPriceUnit} textField="ColumnName" selectedField={SELECTED_FIELD}
                            onItemClick={e => handleItemClick(e, 'mapToPriceUnit', 'importedColumns')}
                            onDragStart={handleDragStart} onDrop={handleDrop} name='mapToPriceUnit'
                        />
                    </Col>
                    <Col xs="12" className='mapped-inv-col-group-single'>
                        <p className="mapper-title"><FormattedMessage id='CsvMapper.MapTo' /> <b><FormattedMessage id='CsvMapper.ArticleDescription' /></b></p>
                        <span className="mapper-info" onClick={() => showColumnInfo(MappedColumn.ArticleDescription)}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </span>
                        <ListBox data={state.mapToArticleDescription} textField="ColumnName" selectedField={SELECTED_FIELD}
                            onItemClick={e => handleItemClick(e, 'mapToArticleDescription', 'importedColumns')}
                            onDragStart={handleDragStart} onDrop={handleDrop} name='mapToArticleDescription'
                        />
                    </Col>
                    <Col xs="12" className='mapped-inv-col-group-multi'>
                        <p className="mapper-title"><FormattedMessage id='CsvMapper.MapTo' /> <b><FormattedMessage id='CsvMapper.AdditionalFields' /></b></p>
                        <span className="mapper-info" onClick={() => showColumnInfo(MappedColumn.AdditionalFields)}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </span>
                        <ListBox data={state.mapToAdditionalFields} textField="ColumnName" selectedField={SELECTED_FIELD}
                            onItemClick={e => handleItemClick(e, 'mapToAdditionalFields', 'importedColumns')}
                            onDragStart={handleDragStart} onDrop={handleDrop} name='mapToAdditionalFields'
                        />
                    </Col>
                </Col>
            </div>
        }
        <Row>
            <Col xs='12'>
                <FormGroup style={{ display: showProgress ? "block" : "none" }}>
                    <ProgressIndicator progressPercent={progress} statusMessage={progressMessage} />
                </FormGroup>
            </Col>
        </Row>
        {saveDialogVisible && (
            <Dialog title={intl.formatMessage({ id: 'CsvMapper.SaveMappingDialogTitle' })}
            >
                <Row>
                    <Col xs="12">
                        <Label>
                            <FormattedMessage id='CsvMapper.SaveMappingDialogText' />
                        </Label>
                        <Input name="configurationName" value={configuratioName} onChange={(event) => {
                            validateConfiguratioName(event.target.value);
                        }}
                        />
                    </Col>
                </Row>

                <DialogActionsBar>
                    <Button type={"submit"} color="secondary" onClick={handleCancel}
                    >
                        <FormattedMessage id='Control.Cancel' />
                    </Button>

                    {!selectedMapping
                        ? (
                            <>
                                <Button type={"submit"} color="primary" onClick={handleSaveMappings}
                                    disabled={!configuratioNameValid}
                                >
                                    <FormattedMessage id='Control.Save' />
                                </Button>
                            </>
                        )
                        : undefined
                    }

                    {selectedMapping
                        ? (
                            <>
                                <Button type={"submit"} color="primary" onClick={handleOverride}
                                    disabled={!selectedMapping}
                                >
                                    <FormattedMessage id='Control.Overwrite' />
                                </Button>
                            </>
                        )
                        : undefined
                    }
                </DialogActionsBar>
            </Dialog>
        )}

        {mappingsVisible && (
            <Dialog title={intl.formatMessage({ id: 'CsvMapper.SelectMappingDialogTitle' })}
            >
                <Row>
                    <Col xs="12">
                        <DropDownList
                            id={"interfaceConfigurationId"}
                            name={"interfaceConfigurationName"}
                            textField={"interfaceConfigurationName"}
                            dataItemKey={"interfaceConfigurationId"}
                            value={tempSelectedMapping}
                            data={mappings}
                            label={intl.formatMessage({ id: 'CsvMapper.SelectMappingDialogText' })}
                            onChange={handleMappingChange}
                        />
                    </Col>
                </Row>

                <DialogActionsBar>
                    <Button type={"submit"} color="secondary" onClick={handleSelectCancel}
                    >
                        <FormattedMessage id='Control.Cancel' />
                    </Button>
                    <Button type={"submit"} color="primary" onClick={handleSelectMappings}
                        disabled={!selectedMappingValid}
                    >
                        <FormattedMessage id='Control.Choose' />
                    </Button>
                </DialogActionsBar>
            </Dialog>
        )}
    </div>;
}

export default CsvMapper
