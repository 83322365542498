import { get, post } from './serviceBase';

const baseUrl = '/api/v1.0/admin/MasterData';

export function getCustomerMasterData(customerId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('customerId', customerId);
    const url = `${baseUrl}/GetCustomerMasterData?` + params;
    get(url, successCallback, errorCallback);
}

export function getUsersOfCustomer(customerId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('customerId', customerId);
    const url = `${baseUrl}/GetUsersOfCustomer?` + params;
    get(url, successCallback, errorCallback);
}

export function saveCustomerGeneralInfo(payload, successCallback, errorCallback) {
    const url = `${baseUrl}/SaveCustomerGeneralInfo`;
    post(url, payload, successCallback, errorCallback);
}

export function saveCustomerContactPerson(payload, successCallback, errorCallback) {
    const url = `${baseUrl}/SaveCustomerContactPerson`;
    post(url, payload, successCallback, errorCallback);
}

export function saveCustomerBillingInfo(payload, successCallback, errorCallback) {
    const url = `${baseUrl}/SaveCustomerBillingInfo`;
    post(url, payload, successCallback, errorCallback);
}

export function getCustomers(successCallback, errorCallback) {
    const url = `${baseUrl}/GetCustomers`;
    get(url, successCallback, errorCallback);
}

export function validateCompanyName(customerId, companyName, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('customerId', customerId);
    params.append('companyName', companyName);
    const url = `${baseUrl}/ValidateCompanyName?` + params;
    get(url, successCallback, errorCallback);
}

export function validateVATNumber(vatNumber, billingAddressCompanyName, billingAddressCity, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('vatNumber', vatNumber);
    params.append('billingAddressCompanyName', billingAddressCompanyName);
    params.append('billingAddressCity', billingAddressCity);
    const url = `${baseUrl}/ValidateVATNumber?` + params;
    get(url, successCallback, errorCallback);
}

