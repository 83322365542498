import { get, getFile, post, put, deleteResource } from './serviceBase';

const baseUrl = '/api/v1.0/LicenseManagement';

export function getLicenses(customerId, successCallback, errorCallback) {
    let url = `${baseUrl}/GetLicenses`;
    if (customerId) {
        const params = new URLSearchParams();
        params.append('customerId', customerId);
        url = `${baseUrl}/GetLicenses?` + params;
    }
    get(url, successCallback, errorCallback);
}

export function getSupports(successCallback, errorCallback) {
    const url = `${baseUrl}/GetSupports`;
    get(url, successCallback, errorCallback);
}

export function getCustomerLicenses(successCallback, errorCallback) {
    let url = `${baseUrl}/GetCustomerLicenses`;
    get(url, successCallback, errorCallback);
}

export function getServices(successCallback, errorCallback) {
    const url = `${baseUrl}/GetServices`;
    get(url, successCallback, errorCallback);
}

export function saveLicense(payload, successCallback, errorCallback) {
    const url = `${baseUrl}/SaveLicense`;
    post(url, payload, successCallback, errorCallback);
}

export function assignLicenseToCustomer(payload, successCallback, errorCallback) {
    const url = `${baseUrl}/AssignLicenseToCustomer`;
    post(url, payload, successCallback, errorCallback);
}

export function cancelLicenseOfCustomer(customerId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('customerId', customerId);
    const url = `${baseUrl}/CancelLicenseOfCustomer?` + params;
    post(url, null, successCallback, errorCallback);
}

export function getActiveLicenseOfCustomer(customerId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('customerId', customerId);
    const url = `${baseUrl}/GetActiveLicenseOfCustomer?` + params;
    get(url, successCallback, errorCallback);
}

export function getAllSupports(successCallback, errorCallback) {
    const url = `${baseUrl}/GetAllSupports`;
    get(url, successCallback, errorCallback);
}

export function updateSupport(payload, successCallback, errorCallback) {
    const url = `${baseUrl}/UpdateSupport`;
    post(url, payload, successCallback, errorCallback);
}

export function getLicenseHistoryOfCustomer(customerId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('customerId', customerId);
    const url = `${baseUrl}/GetLicenseHistoryOfCustomer?` + params;
    get(url, successCallback, errorCallback);
}

export function addIndividualLicense(payload, successCallback, errorCallback) {
    const url = `${baseUrl}/AddIndividualLicense`;
    post(url, payload, successCallback, errorCallback);
}

export function updateIndividualLicense(payload, successCallback, errorCallback) {
    const url = `${baseUrl}/UpdateIndividualLicense`;
    post(url, payload, successCallback, errorCallback);
}

export function activateIndividualLicense(customerLicenseId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('customerLicenseId', customerLicenseId);
    const url = `${baseUrl}/ActivateIndividualLicense?` + params;
    put(url, null, successCallback, errorCallback);
}

export function suspendCustomerLicense(customerLicenseId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('customerLicenseId', customerLicenseId);
    const url = `${baseUrl}/SuspendCustomerLicense?` + params;
    put(url, null, successCallback, errorCallback);
}

export function reactivateCustomerLicense(customerLicenseId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('customerLicenseId', customerLicenseId);
    const url = `${baseUrl}/ReactivateCustomerLicense?` + params;
    put(url, null, successCallback, errorCallback);
}

export function cancelCustomerLicense(customerLicenseId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('customerLicenseId', customerLicenseId);
    const url = `${baseUrl}/CancelCustomerLicense?` + params;
    put(url, null, successCallback, errorCallback);
}

export function deleteCustomerLicense(customerLicenseId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('customerLicenseId', customerLicenseId);
    const url = `${baseUrl}/DeleteCustomerLicense?` + params;
    deleteResource(url, successCallback, errorCallback);
}

export function getCustomerIndividualLicenseStatus(customerId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('customerId', customerId);
    let url = `${baseUrl}/GetCustomerIndividualLicenseStatus?` + params;
    get(url, successCallback, errorCallback);
}

export function getCustomerStatus(customerId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('customerId', customerId);
    let url = `${baseUrl}/GetCustomerStatus?` + params;
    get(url, successCallback, errorCallback);
}

export function exportCustomerLicenses(payload, successCallback, errorCallback) {
    const url = `${baseUrl}/ExportCustomerLicenses`;
    getFile(url, payload, successCallback, errorCallback, null);

}
