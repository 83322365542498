import React, { useState, useEffect, useCallback } from 'react';
import WizardTabs from './WizardTabs';
import { Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    getReportingStatistics,
    getStaseqReportingStatistics,
    getPostAnalysisReportStatus,
    getPostAnalysisSeqReportStatus,
    getPureStaseqReportingStatistics,
    endInventory
} from '../../../../services/inventoryService';
import { cancelPostAnalysisReportGenerationInProgress, getCountingDefaults, getStaseqCountingDefaults } from '../../../../services/countService';
import { trigger, on, off } from '../../../../actions/events'
import { wizardEvents, wizardStep } from '../Wizard';
import { WizzardContext, WIZZARD_ACTIONS } from '../WizzardContext';
import { UserContext } from '../../../UserContext';
import LongRunningBackgroundTaskIndicatorDialog from '../../../Shared/LongRunningBackgroundTaskIndicatorDialog';
import { useExceptionDialog } from '../../../Shared/ExceptionDialog/ExceptionDialogProvider';
import { useNotification } from '../../../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../../../Shared/Notifications/Notification';
import { useLoading } from "../../../Shared/LoadingContext";
import OptionSelectorDialog from "../../../Shared/OptionSelectorDialog";
import License from '../../../License/License';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import ConfirmDialog from '../../../Shared/ConfirmDialog';

const Reporting = (props) => {
    const pageId = 'pageReporting';
    const cancelTokenSource = axios.CancelToken.source();
    const dispatchExceptionDialog = useExceptionDialog();
    const dispatchNotification = useNotification();

    const [enableReports, setEnableReports] = useState(true);
    const [statistics, setStatistics] = useState(null);

    const userContext = React.useContext(UserContext);
    const wizzardContext = React.useContext(WizzardContext);
    const selectedPopulation = useSelector(state => state.dashboard.population);

    const currentCustomer = useSelector(state => state.dashboard.customer);
    const isCustomerWithoutLicense = currentCustomer.activeLicense.isFreeTrial;

    const [isLicenseVisible, setIsLicenseVisible] = useState(false);
    const [isReDrawOptionsVisible, setIsReDrawOptionsVisible] = useState(false);

    const importInProgress = wizzardContext.wizzardGlobal.Import.IsBackgroundTaskRunning;
    const importFinishedWithError = wizzardContext.wizzardGlobal.Import.IsBackgroundTaskFinishedWithError;
    const inventoryMethod = wizzardContext.wizzardGlobal.Import.InventoryMethod;
    const isHighQuality = inventoryMethod === window.enumInventoryMethod.Staseq;
    const groupedByIdent = wizzardContext.wizzardGlobal?.InterfaceConfiguration?.groupByIdentFId;

    const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);
    const intl = useIntl();
    const { setLoading } = useLoading();
    let history = useHistory();

    const [pureStaseqReporting, setPureStaseqReporting] = useState(null);

    const [loadingCount, setLoadingCount] = useState(0);
    useEffect(() => {
        setLoading(loadingCount > 0);
    }, [loadingCount]);

    const incrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount + 1);
    };

    const decrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount - 1);
    };

    const statusMessageFromBackgroundTaskStatus = () => {
        return <FormattedMessage id='Counting.ImportStatus.ReportGenerationInProgress' />;
    }

    const importStatusMessage = (importStatus) => {
        let text;
        switch (importStatus) {
            case window.enumBackgroundTaskStatus.PostAnalysisReportGenerationStarted:
                text = (<FormattedMessage id='Counting.ImportStatus.ReportGenerationInProgress' />);
                break;
            case window.enumBackgroundTaskStatus.PostAnalysisReportGenerationFailed:
                text = (<FormattedMessage id='Counting.ImportStatus.ReportGenerationFailed' />);
                break;
            case window.enumBackgroundTaskStatus.WaitingToStart:
                text = (<FormattedMessage id='ImportResult.ImportStatus.WaitingToStart' />);
                break;
            case window.enumBackgroundTaskStatus.NoBackgroundTaskRunning:
            default:
                text = '';
        }
        return text;
    }

    const importTaskFinished = postAnalysisReportGenerationStatus => {
        let success = postAnalysisReportGenerationStatus === window.enumBackgroundTaskStatus.PostAnalysisReportGenerationSucceeded;
        setEnableReports(success);

        if (isCustomerWithoutLicense && !userContext.userGlobal.userInfo.isController) {
            setIsLicenseVisible(true);
        }

        if (success) {
            loadReportingStatistics();
        }
        else {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: importStatusMessage(postAnalysisReportGenerationStatus)
            });
        }

        trigger(wizardEvents.validStateOfCurrentStepChanged, { step: wizardStep.Reporting, isValid: success });
    }

    const loadReportingStatistics = () => {
        if (isHighQuality) {
            loadSequentialReportingStatistics();
        }
        else {
            loadSamplingReportingStatistics();
        }
    }

    const [needToDraw, setNeedToDraw] = useState({ Enabled: false, NoOfItemsToDraw: 0 });

    const handleError = (errorMessage, showNotif = true) => {
        decrementLoadingCount();
        if (showNotif) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            })
        }
    };

    const adjustProgramLockStateInWizardContext = progLockState => {
        let currentContextValue = wizzardContext.wizzardGlobal.Import;
        wizzardContext.dispatchWizzardGlobal({
            type: WIZZARD_ACTIONS.SetProgramLockState,
            payload: {
                ...currentContextValue,
                ProgramLockState: progLockState
            }
        });
    }

    const displayCompletedPerpetualInventoryNotification = (programLockState) => {
        if (wizzardContext.wizzardGlobal.SupportData.inventoryType == window.enumInventoryType.PerpetualInventorySample &&
            programLockState == window.enumProgramLockState.FeedbackCompletedRun) {
            dispatchNotification({
                type: NotificationTypes.info,
                pageId: pageId,
                message: intl.formatMessage({ id: "ReportingStatistics.InventoryCompleted" })
            });
        }
    }

    const loadSamplingReportingStatistics = () => {
        incrementLoadingCount();
        getReportingStatistics(selectedPopulation && selectedPopulation.populationId,
            data => {
                updateUIwithStatistics(data);
                setNeedToDraw({ Enabled: data.needToDraw, NoOfItemsToDraw: data.noOfItemsToDraw });
                adjustProgramLockStateInWizardContext(data.programLockState);

                displayCompletedPerpetualInventoryNotification(data.programLockState);
            },
            error => {
                decrementLoadingCount();
                console.log('error getting reporting statistics for current population', error);
                dispatchExceptionDialog({ pageId: pageId, message: error });
            },
            cancelTokenSource.token
        );
    }

    const loadSequentialReportingStatistics = () => {
        incrementLoadingCount();
        getStaseqReportingStatistics(selectedPopulation && selectedPopulation.populationId,
            data => {
                updateUIwithStatistics(data);
                loadPureStaseqReportingStatistics();
            },
            error => {
                decrementLoadingCount();
                console.log('error getting sequential reporting statistics for current population', error);
                dispatchExceptionDialog({ pageId: pageId, message: error });
            },
            cancelTokenSource.token
        );
    }

    const loadPureStaseqReportingStatistics = () => {
        incrementLoadingCount();
        getPureStaseqReportingStatistics(selectedPopulation && selectedPopulation.populationId,
            data => {
                updateUIwithPureStaseqReporting(data);
            },
            error => {
                decrementLoadingCount();
                console.log('error getting pure sequential reporting statistics for current population', error);
                dispatchExceptionDialog({ pageId: pageId, message: error });
            },
            cancelTokenSource.token
        );
    }

    const updateUIwithPureStaseqReporting = (pssr) => {
        setNeedToDraw({ Enabled: pssr.needToDraw, NoOfItemsToDraw: pssr.noOfItemsToDraw });
        setPureStaseqReporting(pssr);
        adjustProgramLockStateInWizardContext(pssr.programLockState);

        displayCompletedPerpetualInventoryNotification(pssr.programLockState);

        decrementLoadingCount();
    }

    const updateUIwithStatistics = (stats) => {
        if (stats?.hintKey) {
            let hintText = intl.formatMessage({ id: stats.hintKey });
            dispatchNotification({
                type: NotificationTypes.warning,
                pageId: pageId,
                message: hintText,
                isHtml: true
            });
        }

        stats.choosenEstimationprocedureText = intl.formatMessage({ id: 'ReportingStatistics.EstimationProcedure_' + stats.choosenEstimationprocedure }).toUpperCase();
        decrementLoadingCount();
        setStatistics(stats);
    }

    const cancelButtonClicked = (populationId) => {
        cancelPostAnalysisReportGenerationInProgress(populationId,
            () => { console.log('Post analysis report generation cancellation initiated') },
            error => { dispatchExceptionDialog({ pageId: pageId, message: error }); }
        );
    }

    const handleLicenseClose = () => {
        setIsLicenseVisible(false);
    };

    const getReportsStatus = () => {
        let getReportStatusMethod = isHighQuality ? getPostAnalysisSeqReportStatus : getPostAnalysisReportStatus;
        incrementLoadingCount();

        getReportStatusMethod(selectedPopulation && selectedPopulation.populationId,
            data => {
                decrementLoadingCount();
                console.log(isHighQuality ? 'PostAnalysisSeqReportStatus:' : 'PostAnalysisReportStatus:', data);
                if (data.reportCanBeCreated) {
                    setEnableReports(true);
                    loadReportingStatistics();
                }
                else {
                    if (!isHighQuality) {
                        dispatchNotification({
                            type: NotificationTypes.error,
                            pageId: pageId,
                            message: intl.formatMessage({ id: "ReportingStatistics.IDS_HOCH_MSG1" }, { 'blockingStratum': data.blockingStratum })
                        });
                    }
                    else {
                        dispatchNotification({
                            type: NotificationTypes.error,
                            pageId: pageId,
                            message: data.statusMessage
                        });
                    }
                    setEnableReports(false);
                }
            },
            error => {
                decrementLoadingCount();
                if (props.handleError) {
                    props.handleError(error, false, pageId);
                }
                else {
                    dispatchExceptionDialog({ pageId: pageId, message: error });
                }
                setEnableReports(false);
            },
            cancelTokenSource.token
        );
    }

    const loadReportingStatisticsIfReportsAreAllowed = () => {
        if (!importFinishedWithError) {
            getReportsStatus();
        }
        else {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: importStatusMessage(window.enumBackgroundTaskStatus.PostAnalysisReportGenerationFailed)
            });
            setEnableReports(false);
        }
    }

    useEffect(() => {
        if (!importInProgress) {
            if (isCustomerWithoutLicense && !userContext.userGlobal.userInfo.isController) {
                setIsLicenseVisible(true);
            }

            loadReportingStatisticsIfReportsAreAllowed();
        }

        return () => { cancelTokenSource.cancel(); };
    }, []);

    const ReDrawOption = {
        CreateNewCountList: 1,
        CountAdditionalItems: 2,
        EndPartialCounting: 3,
        EndInventory: 4
    }

    const getRedrawButtonText = intl.formatMessage({ id: "Wizard.Redraw" }, { ItemCount: needToDraw.NoOfItemsToDraw });

    const reDrawOptions = [
        {
            value: ReDrawOption.CreateNewCountList,
            text: getRedrawButtonText,
            disabled: ((isHighQuality && pureStaseqReporting && (pureStaseqReporting.statusOfSequentialTest.reachedRejectionThreshold || pureStaseqReporting.statusOfSequentialTest.reachedAcceptanceThreshold)) || needToDraw.NoOfItemsToDraw <= 0)
        },
        {
            value: ReDrawOption.CountAdditionalItems,
            text: intl.formatMessage({ id: 'Reporting.Redraw.Option.CountAdditionalItems' }),
            disabled: wizzardContext.wizzardGlobal.Import.ProgramLockState === window.enumProgramLockState.FeedbackCompletedTotal
        },
        {
            value: ReDrawOption.EndPartialCounting,
            text: intl.formatMessage({ id: 'Reporting.Redraw.Option.EndPartialCounting' }),
            disabled: wizzardContext.wizzardGlobal.Import.ProgramLockState != window.enumProgramLockState.FeedbackCompletedRun ||
                    wizzardContext.wizzardGlobal.SupportData.inventoryType != window.enumInventoryType.PerpetualInventorySample
        },
        {
            value: ReDrawOption.EndInventory,
            text: intl.formatMessage({ id: 'Reporting.Redraw.Option.EndInventory' }),
            disabled: wizzardContext.wizzardGlobal.Import.ProgramLockState < window.enumProgramLockState.FeedbackCompletedRun
        }
    ]

    const reDrawButtonClicked = useCallback((event) => {
        if (event.detail === wizardStep.Reporting) {
            setIsReDrawOptionsVisible(true);
        }
    }, [setIsReDrawOptionsVisible])

    useEffect(() => {
        on(wizardEvents.reDrawButtonClicked, reDrawButtonClicked);

        return () => {
            off(wizardEvents.reDrawButtonClicked, reDrawButtonClicked);
        }
    }, [reDrawButtonClicked]);

    const getCountParamsAndGoBackToRedrawItems = () => {
        if (inventoryMethod != null) {
            incrementLoadingCount();
            if (isHighQuality) {
                getStaseqCountingDefaults(
                    selectedPopulation.populationId,
                    result => {
                        decrementLoadingCount();
                        wizzardContext.dispatchWizzardGlobal({
                            type: WIZZARD_ACTIONS.SetStaseqCounting, payload: {
                                completeCountItemsToCount: result.data.completeCount.itemsLeftToCount,
                                initalSampleItemsToCount: result.data.initalSample.itemsLeftToCount,
                                sequentialTestItemsToCount: result.data.sequentialTest.itemsLeftToCount
                            }
                        });
                        trigger(wizardEvents.gotoStep, wizardStep.CountingSelection);
                    },
                    handleError
                );

            }
            else {
                getCountingDefaults(
                    selectedPopulation.populationId,
                    result => {
                        decrementLoadingCount();
                        wizzardContext.dispatchWizzardGlobal({
                            type: WIZZARD_ACTIONS.SetStasamCounting, payload: {
                                completeCountItemsToCount: result.data.completeCount.itemsLeftToCount,
                                randomSamplingItemsToCount: result.data.randomSampling.itemsLeftToCount,
                                recountItemsToCount: result.data.redraw.itemsLeftToCount
                            }
                        });
                        trigger(wizardEvents.gotoStep, wizardStep.CountingSelection);
                    },
                    handleError
                );
            }

        }
    }

    const handleOptionSelectorDialogClose = (optionValue) => {
        setIsReDrawOptionsVisible(false);
        if (optionValue) {
            if (optionValue == ReDrawOption.CreateNewCountList) {
                if (wizzardContext.wizzardGlobal.SupportData.inventoryType == window.enumInventoryType.PerpetualInventorySample) {
                    trigger(wizardEvents.gotoStep, wizardStep.ImportUpdate);
                }
                else {
                    getCountParamsAndGoBackToRedrawItems();
                }
            }
            else if (optionValue == ReDrawOption.CountAdditionalItems) {
                trigger(wizardEvents.stepBackEvent, wizardStep.Reporting);
            }
            else if (optionValue == ReDrawOption.EndPartialCounting) {
                history.push('/');
            }
            else if (optionValue == ReDrawOption.EndInventory) {
                setIsConfirmDialogVisible(true)
            }
        }
    };

    const handleConfirmDialogClose = (isConfirmed) => {
        setIsConfirmDialogVisible(false);
        if (isConfirmed) {
            incrementLoadingCount();
            endInventory(selectedPopulation && selectedPopulation.populationId,
                _data => {
                    decrementLoadingCount();
                    trigger(wizardEvents.reDrawButtonEnabledStateChanged, { Enabled: false });
                    adjustProgramLockStateInWizardContext(window.enumProgramLockState.FeedbackCompletedTotal);
                },
                error => {
                    decrementLoadingCount();
                    console.log('error ending invetory for current population', error);
                    dispatchExceptionDialog({ pageId: pageId, message: error });
                },
                cancelTokenSource.token
            );
        }
    };

    const handlerefreshButtonClicked = (event) => {
        loadReportingStatisticsIfReportsAreAllowed();
    }

    useEffect(() => {
        on(wizardEvents.refreshButtonClicked, handlerefreshButtonClicked);

        return () => {
            off(wizardEvents.refreshButtonClicked, handlerefreshButtonClicked);
        }
    }, [handlerefreshButtonClicked]);

    return (
        <Row className="wizard-content">
            <Col xs='12'>
                <WizardTabs enableReportPreview={enableReports}
                    statistics={statistics}
                    importInProgress={importInProgress}
                    inventoryMethod={inventoryMethod}
                    isHighQuality={isHighQuality}
                    pureStaseqReporting={pureStaseqReporting}
                    enableGroupedDifferences={groupedByIdent}
                />
            </Col>

            {
                isReDrawOptionsVisible &&
                <OptionSelectorDialog visible={isReDrawOptionsVisible}
                    name='reporting-redraw-option'
                    onClose={handleOptionSelectorDialogClose}
                    negative={<FormattedMessage id="Control.Cancel" />}
                    positive={<FormattedMessage id="Control.Confirm" />}
                    title={<FormattedMessage id="Reporting.Redraw.SelectOption.Title" />}
                    options={reDrawOptions}
                />
            }
            <ConfirmDialog
                visible={isConfirmDialogVisible}
                onClose={handleConfirmDialogClose}
                negative={<FormattedMessage id='Control.No' />}
                positive={<FormattedMessage id='Control.Yes' />}
                detail={<FormattedMessage id='Reporting.AreYouSure' />}
            />
            {importInProgress &&
                <LongRunningBackgroundTaskIndicatorDialog visible={importInProgress}
                    title={<FormattedMessage id='Counting.PostAnalysisReportGenerationInProgress' />}
                    waitMessage={<FormattedMessage id='Counting.WaitForReportGeneration' />}
                    openingStatusText={statusMessageFromBackgroundTaskStatus()}
                    statusChangeTextProvider={importStatusMessage}
                    finalStates={[
                        window.enumBackgroundTaskStatus.NoBackgroundTaskRunning,
                        window.enumBackgroundTaskStatus.PostAnalysisReportGenerationSucceeded,
                        window.enumBackgroundTaskStatus.PostAnalysisReportGenerationFailed,
                        window.enumBackgroundTaskStatus.ImportCancelled
                    ]}
                    onTaskFinishedCallback={importTaskFinished}
                    buttons={[
                        {
                            text: <FormattedMessage id='Control.Cancel' />,
                            onClick: cancelButtonClicked,
                            onClickText: <FormattedMessage id='Counting.WaitForCancelReportGeneration' />
                        }
                    ]}
                    links={[
                        {
                            text: <FormattedMessage id='Sidebar.Dashboard' />,
                            to: '/'
                        }
                    ]}
                />
            }

            {isLicenseVisible && (
                <License
                    onClose={handleLicenseClose}
                />
            )}
        </Row>
    );
};

export default Reporting