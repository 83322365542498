import React, { useEffect } from 'react';
import { Col, Row, Card, CardBody, Button } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, GridNoRecords, GridColumn as Column } from '@progress/kendo-react-grid';
import { deepClone } from '../../utils/jsonUtils';
import { useNotification } from '../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../Shared/Notifications/Notification';
import { useLoading } from '../Shared/LoadingContext';
import { SwitchCell } from '../Administration/Config/SwitchCell';
import { getCustomersWithEnablingArticleGrouping, saveCustomersWithEnablingArticleGrouping } from '../../services/supportService';
import { useExceptionDialog } from '../Shared/ExceptionDialog/ExceptionDialogProvider';
import GridFooter from '../Shared/GridFooter';


const editField = "inEdit";

export const ReorderContext = React.createContext({
    reorder: () => { },
    dragStart: () => { },
});

const ArticleGrouping = (props) => {
    const pageId = 'ArticleGrouping';
    const intl = useIntl();
    const dispatchNotification = useNotification();
    const [customersWithEnablingArticleGrouping, setCustomersWithEnablingArticleGrouping] = React.useState([]);
    const [isChanged, setIsChanged] = React.useState(false);
    const [data, setData] = React.useState([]);
    const { setLoading } = useLoading();
    const dispatchExceptionDialog = useExceptionDialog();
    const [itemsPerPage, setItemsPerPage] = React.useState(10);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [gridData, setGridData] = React.useState([]);
    const [isPagingVisible, setIsPagingVisible] = React.useState(false);
    const minGridLength = 5;


    useEffect(() => {
        setLoading(true);
        getCustomersWithEnablingArticleGrouping(
            result => {
                setLoading(false);
                result = result.map(x => ({ ...x, inEdit: true }))
                setData(deepClone(result));
                setCustomersWithEnablingArticleGrouping(deepClone(result));
                
            },
            handleError);
    }, []);

    useEffect(() => {
        if (!data) {
            return;
        }
        let isChanged = getIsChanged();
        setIsChanged(isChanged)
    }, [data]);

    React.useEffect(() => {
        setIsPagingVisible(customersWithEnablingArticleGrouping.length > minGridLength);
        setPageHandler(1);
    }, [customersWithEnablingArticleGrouping, itemsPerPage]);

    const handleError = (errorMessage, showNotif = true) => {
        setLoading(false);
        if (showNotif) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            });
        }
    };

    const getIsChanged = () => {
        if (data && data.length > 0 && customersWithEnablingArticleGrouping && customersWithEnablingArticleGrouping.length > 0) {
            for (let i = 0; i < data.length; i++) {
                let current = data[i];
                let original = customersWithEnablingArticleGrouping[i];
                if (isDataChanged(current, original)) {
                    return true;
                }
            }
        }
        return false;
    }

    const isDataChanged = (current, original) => {
        return current.enablingArticleGrouping != original.enablingArticleGrouping
    }

    const itemChange = (event) => {
        let newData = gridData.map((item) =>
            item.customerId === event.dataItem.customerId
                ? { ...item, [event.field || ""]: event.value, changed: true }
                : item
        );
        setGridData(newData);

        newData = data.map((item) =>
            item.customerId === event.dataItem.customerId
                ? { ...item, [event.field || ""]: event.value, changed: true }
                : item
        );
        setData(newData);
    }

    const handleReset = () => {
        setData(deepClone(customersWithEnablingArticleGrouping));
    }

    const handleSave = () => {
        let itemsToUpdate = [];

        if (data && data.length > 0 && customersWithEnablingArticleGrouping && customersWithEnablingArticleGrouping.length > 0) {
            for (let i = 0; i < data.length; i++) {
                let current = data[i];
                let original = customersWithEnablingArticleGrouping[i];
                if (isDataChanged(current, original)) {
                    itemsToUpdate.push(current);
                }
            }
        }
        setLoading(true);
        saveCustomersWithEnablingArticleGrouping(itemsToUpdate,
            result => {
                setLoading(false);
            },
            handleError);
    }

    const handleItemsPerPageChange = (dropDownItem) => {
        setItemsPerPage(dropDownItem.value);
    };

    const setPageHandler = (page) => {
        if (data && page > 0 && page <= Math.floor((data.length - 1) / itemsPerPage) + 1) {
            let startIndex = (page - 1) * itemsPerPage;
            let endIndex = (page) * itemsPerPage;
            setGridData(data.slice(startIndex, endIndex));
            if (data.length > itemsPerPage) {
                setCurrentPage(page);
            }
            else {
                setCurrentPage(1);
            }
        }
        else {
            setGridData(data);
        }
    }

    return (

        <div className="content">
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>

                            <Grid
                                data={gridData}
                                key="customerId"
                                onItemChange={itemChange}
                                editField={editField}
                            >
                                <GridNoRecords>
                                    <FormattedMessage id="SupportTools.NoCustomers" />
                                </GridNoRecords>

                                <Column field="customerName" title={intl.formatMessage({ id: "SupportTools.CustomerName" })} editable={false} />
                                <Column field="enablingArticleGrouping" title={intl.formatMessage({ id: "SupportTools.EnablingArticleGrouping" })} cell={SwitchCell} />
                            </Grid>
                            {isPagingVisible && (
                                <GridFooter
                                    itemsPerPage={itemsPerPage}
                                    handleItemsPerPageChange={handleItemsPerPageChange}
                                    dataLength={data.length}
                                    setPageHandler={setPageHandler}
                                    currentPage={currentPage}
                                />
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <div className="float-right">
                <Button
                    disabled={!(isChanged)}
                    color="secondary"
                    onClick={handleReset}
                >
                    <FormattedMessage id='Control.Reset' />
                </Button>
                <Button
                    disabled={!(isChanged)}
                    color="primary"
                    onClick={handleSave}
                >
                    <FormattedMessage id='Control.Save' />
                </Button>
            </div>
        </div>
    )
}

export default ArticleGrouping