import React, { useState, useEffect } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";

export default function CustomerSelection(props) {
    const [customer, setCustomer] = useState(null);

    useEffect(() => {
        setCustomer(props.customer);
    }, [props.customer]);

    const handleChange = (event) => {
        setCustomer(event.target.value);
        props.onCustomerChanged(event.target.value);
    };

    const ddlCustomers = (
        <DropDownList
            data={props.customers}
            textField="customerName"
            dataItemKey="customerId"
            value={customer}
            onChange={props.handleChange ?? handleChange}>
        </DropDownList>
    );

    return (
        <div className="header-customer-selection">
            {props.isStatcontrolAdmin && ddlCustomers}
            <small> {!props.isStatcontrolAdmin && (props.customer ? props.customer.customerName : '')} </small>
        </div>
    );
}
