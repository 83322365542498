import axios from 'axios';
import { get, post } from './serviceBase';

const baseUrl = '/api/v1.0/admin/InterfaceConfigurations';

export async function saveMappingFromImport(customerId, populationId, interfaceConfigurationId, configuratioName, columnMapping) {
    let config = {
        params: {
            customerId: customerId,
            populationId: populationId,
            interfaceConfigurationId: interfaceConfigurationId,
            colMapping: columnMapping,
            configuratioName: configuratioName
        },
        headers: {
            'Content-Type': 'application/json'
        }
    }

    return axios.post(`${baseUrl}/SaveMappingFromImport`, columnMapping, config);
}

export function loadMappingByCustomer(customerId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('customerId', customerId);

    const url = `${baseUrl}/LoadMappingByCustomer?` + params;
    get(url, successCallback, errorCallback);
}

export function loadLastUsedMappingByCustomer(populationId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('populationId', populationId);

    const url = `${baseUrl}/LoadLastUsedMappingByCustomer?` + params;
    get(url, successCallback, errorCallback);
}

export function getCustomerConfigurations(customerId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('customerId', customerId);
    const url = `${baseUrl}/GetCustomerConfigurations?` + params;
    get(url, successCallback, errorCallback);
}

export function getInterfaceConfigurationsByCustomer(customerId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('customerId', customerId);
    const url = `${baseUrl}/GetInterfaceConfigurationsByCustomer?` + params;
    get(url, successCallback, errorCallback);
}

export function getInterfaceConfigurationsByPopulation(populationId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('populationId', populationId);
    const url = `${baseUrl}/GetInterfaceConfigurationsByPopulation?` + params;
    get(url, successCallback, errorCallback);
}

export function getInterfaceConfigurationById(id, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('id', id);
    const url = `${baseUrl}/GetInterfaceConfigurationById?` + params;
    get(url, successCallback, errorCallback);
}


export function addInterfaceConfiguration(dto, successCallback, errorCallback) {
    const url = `${baseUrl}/AddInterfaceConfiguration`;
    post(url, dto, successCallback, errorCallback);
}

export function updateInterfaceConfiguration(dto, successCallback, errorCallback) {
    const url = `${baseUrl}/UpdateInterfaceConfiguration`;
    post(url, dto, successCallback, errorCallback);
}

export function deleteInterfaceConfiguration(interfaceConfigurationId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('interfaceConfigurationId', interfaceConfigurationId);
    const url = `${baseUrl}/DeleteInterfaceConfiguration?` + params;
    post(url, null, successCallback, errorCallback);
}

export function setInterfaceConfigurationDetails(dto, successCallback, errorCallback) {
    const url = `${baseUrl}/SetInterfaceConfigurationDetails`;
    post(url, dto, successCallback, errorCallback);
}

export function cloneInterfaceConfiguration(interfaceConfigurationId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('interfaceConfigurationId', interfaceConfigurationId);
    const url = `${baseUrl}/CloneInterfaceConfiguration?` + params;
    post(url, null, successCallback, errorCallback);
}

