import React, { useState, useEffect } from 'react';

import { isMaintenance, getMaintenanceUrl } from '../services/apiService';

import { IntlProvider } from 'react-intl';
import EnglishLang from '../language/en-US.json';
import GermanLang from '../language/de-DE.json';
import ItalianLang from '../language/it-IT.json';

import {
    loadMessages,
    LocalizationProvider
} from "@progress/kendo-react-intl";
import deMessages from "../language/kendo.de-DE.json";
import itMessages from "../language/kendo.it-IT.json";

loadMessages(deMessages, "de-DE");
loadMessages(itMessages, "it-IT");

export const Context = React.createContext();
const local = navigator.language;

const Wrapper = (props) => {
    const [locale, setLocale] = useState(local);
    const [localeName, setLocaleName] = useState(getLocaleName(local));
    const [messages, setMessages] = useState(null);

    const [maintenanceMode, setMaintenanceMode] = useState(false);

    const [scriptLangEN_Evaluated, setScriptLangEN_Evaluated] = useState(false);
    const [scriptLangDE_Evaluated, setScriptLangDE_Evaluated] = useState(false);
    const [scriptLangIT_Evaluated, setScriptLangIT_Evaluated] = useState(false);

    const languageScriptsEvaluated = scriptLangEN_Evaluated && scriptLangDE_Evaluated && scriptLangIT_Evaluated;

    const buildElementForReportLanguageDefinitions = (src, setEvaluatedFlagMethod) => {
        const script = document.createElement("script");
        script.onload = function () {
            if (setEvaluatedFlagMethod)
                setEvaluatedFlagMethod(true);
        };
        script.src = src;
        script.async = false;
        return script;
    }

    const redirectToMaintenancePage = () => {
        getMaintenanceUrl(
            url => { window.location.href = url; },
            error => { console.log(error); }
        );
    }

    const checkMaintenance = () => {
        isMaintenance((response) => {
            setMaintenanceMode(response);
            if (response) {
                redirectToMaintenancePage();
            }
        }, (error) => {
            console.log(error);
        });
    }

    const buildElementForReportLanguageDefinitions_EN = () => {
        return buildElementForReportLanguageDefinitions("/api/v1.0/ServerSideLocalizationDispatcher/reportLanguageFile.en-US.js", setScriptLangEN_Evaluated);
    }

    const buildElementForReportLanguageDefinitions_DE = () => {
        return buildElementForReportLanguageDefinitions("/api/v1.0/ServerSideLocalizationDispatcher/reportLanguageFile.de-DE.js", setScriptLangDE_Evaluated);
    }

    const buildElementForReportLanguageDefinitions_IT = () => {
        return buildElementForReportLanguageDefinitions("/api/v1.0/ServerSideLocalizationDispatcher/reportLanguageFile.it-IT.js", setScriptLangIT_Evaluated);
    }

    const setMessagesByLocale = loc => {
        let msg;

        switch (loc) {
            case 'de':
                msg = {
                    ...GermanLang,
                    ...window.GermanRep
                };
                break;
            case 'it':
                msg = {
                    ...ItalianLang,
                    ...window.ItalianRep
                };
                break;
            default:
                msg = {
                    ...EnglishLang,
                    ...window.EnglishRep
                };
        }
        setMessages(msg);
    }

    useEffect(() => {
        let repLangScript_EN = buildElementForReportLanguageDefinitions_EN();
        let repLangScript_DE = buildElementForReportLanguageDefinitions_DE();
        let repLangScript_IT = buildElementForReportLanguageDefinitions_IT();

        document.head.prepend(repLangScript_EN);
        document.head.prepend(repLangScript_DE);
        document.head.prepend(repLangScript_IT);

        checkMaintenance();

        return () => {
            document.head.removeChild(repLangScript_IT);
            document.head.removeChild(repLangScript_DE);
            document.head.removeChild(repLangScript_EN);
        }
    }, [])

    useEffect(() => {
        setMessagesByLocale(locale);
    }, [locale])

    function getLocaleName(loc) {
        switch (loc) {
            case 'en':
                return 'en-US';
            case 'de':
                return 'de-DE';
            case 'it':
                return 'it-IT';
        }
        return 'en-US';
    }

    function selectLanguage(newLocale) {
        setLocale(newLocale);
        setLocaleName(getLocaleName(newLocale));
        setMessagesByLocale(newLocale);
    }
    return (
        !maintenanceMode ? (
            languageScriptsEvaluated &&
            <Context.Provider value={{ locale, localeName, selectLanguage }}>
                <LocalizationProvider language={localeName}>
                    <IntlProvider messages={messages} locale={local}>
                        {props.children}
                    </IntlProvider>
                </LocalizationProvider>
            </Context.Provider>
        ) :
            <></>
    );
}
export default Wrapper;