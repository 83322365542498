import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Card, CardHeader, CardBody, Button } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import validator from 'validator';
import { Form, FormElement, Field } from '@progress/kendo-react-form';
import { Input } from "@progress/kendo-react-inputs";
import { FormDropDownList, FormAutoComplete } from '../../Inventory/Wizard/form-components';
import { NotificationTypes } from '../../Shared/Notifications/Notification';
import { saveCustomerGeneralInfo, saveCustomerContactPerson, validateCompanyName } from '../../../services/masterDataService';
import { Prompt } from 'react-router'
import { createUserManager, signoutRedirect } from '../../../services/userService';
import { renewAccessToken, SessionKeyIsAutomaticLogout } from '../../../services/serviceBase';
import { getBlacklistCompanyWords } from '../../../services/blacklistCompanyWordService';

const GeneralTab = (props) => {
    const intl = useIntl();

    const currentCustomer = useSelector(state => state.dashboard.customer);

    const [currentCountries, setCurrentCountries] = React.useState(props.getCountries());

    const [blacklistCompanyWords, setBlacklistCompanyWords] = useState([]);
    useEffect(() => {
        props.incrementLoadingCount();
        getBlacklistCompanyWords(
            bcw => {
                props.decrementLoadingCount();
                setBlacklistCompanyWords(bcw);
            },
            props.handleError);

        return () => {
            props.removeNotification();
        };
    }, []);

    const [customerMasterData, setCustomerMasterData] = useState(props.customerMasterData);
    const [salutations, setSalutations] = useState([]);

    useEffect(() => {
        setSalutations(props.salutations);
    }, [props.salutations]);


    useEffect(() => {
        setCustomerMasterData(props.customerMasterData);

        setIsSalutationChanged(false);
        setIsContactPersonEmailChanged(false);
        setIsContactPersonFirstNameChanged(false);
        setIsContactPersonLastNameChanged(false);
        setIsContactPersonPhoneNumberChanged(false);

        setIsCompanyNameChanged(false);
        setIsCountryChanged(false);
        setIsPostalCodeChanged(false);
        setIsStreetChanged(false);
        setIsCityChanged(false);
        setIsStreetNumber(false);

        props.setAreChangesOnGeneral(false);

    }, [props.customerMasterData]);

    const initialSalutation = () => {
        return props.salutations.find(x => x.value === customerMasterData.salutation);
    };

    const companyNameValidator = (value) => value.trim() ? "" : intl.formatMessage({ id: 'MasterData.CompanyNameRequired' });
    const streetValidator = (value) => value ? "" : intl.formatMessage({ id: 'MasterData.StreetRequired' });
    const streetNumberValidator = (value) => value ? "" : intl.formatMessage({ id: 'MasterData.StreetNumberRequired' });
    const postalCodeValidator = (value) => value ? "" : intl.formatMessage({ id: 'MasterData.PostalCodeRequired' });
    const cityValidator = (value) => value ? "" : intl.formatMessage({ id: 'MasterData.CityRequired' });
    const countryValidator = (value) => {
        if (!value) {
            return intl.formatMessage({ id: 'MasterData.CountryRequired' });
        }
                
        const isValidCountry = props.getCountries().some(x => x === value);
        return isValidCountry ? "" : intl.formatMessage({ id: "MasterData.CountryInvalid" });
    };

    const validateEmail = (email) => {
        if (!email)
            return intl.formatMessage({ id: 'MasterData.ContactPersonEmailRequired' });
        return validator.isEmail(email) ? "" : intl.formatMessage({ id: 'MasterData.ContactPersonEmailRequiredInvalid' });
    }

    const salutationValidator = (value) => value && value.value != null ? "" : intl.formatMessage({ id: 'MasterData.SalutationRequired' });
    const contactPersonFirstNameValidator = (value) => value ? "" : intl.formatMessage({ id: 'MasterData.ContactPersonFirstNameRequired' });
    const contactPersonLastNameValidator = (value) => value ? "" : intl.formatMessage({ id: 'MasterData.ContactPersonLastNameRequired' });
    const contactPersonEmailValidator = (value) => validateEmail(value);


    const handleSubmitContactPerson = (dataItem) => {
        const generalInfo = {
            customerId: dataItem.customerId || currentCustomer.customerId,
            salutation: dataItem.salutationObj.value,
            contactPersonFirstName: dataItem.contactPersonFirstName,
            contactPersonLastName: dataItem.contactPersonLastName,
            contactPersonEmail: dataItem.contactPersonEmail,
            contactPersonPhoneNumber: dataItem.contactPersonPhoneNumber,
        };

        props.removeNotification();
        props.incrementLoadingCount();
        saveCustomerContactPerson(
            generalInfo,
            () => {
                props.decrementLoadingCount();
                props.dispatchNotification({
                    pageId: props.pageId,
                    type: NotificationTypes.success,
                    message: intl.formatMessage({ id: 'MasterData.SaveContactPersonSuccess' })
                });
                props.dispatchCustomerMasterData({
                    ...customerMasterData,
                    salutation: dataItem.salutation,
                    contactPersonFirstName: dataItem.contactPersonFirstName,
                    contactPersonLastName: dataItem.contactPersonLastName,
                    contactPersonEmail: dataItem.contactPersonEmail,
                    contactPersonPhoneNumber: dataItem.contactPersonPhoneNumber
                });

                setIsSalutationChanged(false);
                setIsContactPersonEmailChanged(false);
                setIsContactPersonFirstNameChanged(false);
                setIsContactPersonLastNameChanged(false);
                setIsContactPersonPhoneNumberChanged(false);

                if (props.onGeneralInfoSaved) {
                    props.onGeneralInfoSaved();
                }
            },
            props.handleError);
    };


    const handleSubmitGeneralInfo = (dataItem) => {

        if (isCompanyNameChanged) {

            if (checkCompanyNameForBlacklistCompanyWords(dataItem.companyName.trim())) {
                props.dispatchNotification({
                    type: NotificationTypes.error,
                    pageId: props.pageId,
                    message: intl.formatMessage({ id: 'MasterData.CompanyNameIsNotBlacklistValid' })
                });
                return;
            }

            props.removeNotification();
            props.incrementLoadingCount();
            validateCompanyName(
                dataItem.customerId,
                dataItem.companyName.trim(),
                (result) => {
                    props.decrementLoadingCount();
                    
                    if (result.companyNameAlreadyExists) {
                        props.dispatchNotification({
                            type: NotificationTypes.error,
                            pageId: props.pageId,
                            message: intl.formatMessage({ id: 'MasterData.CompanyNameAlreadyExists' })
                        });
                        return;
                    }

                    props.askForTransferCompanyNameToBilling(dataItem, saveGeneralInfo)

                },
                props.handleError);


        }
        else {
            saveGeneralInfo(dataItem, false);
        }
    };

    const checkCompanyNameForBlacklistCompanyWords = (companyName) => {
        return blacklistCompanyWords.some((item) => item.blacklistCompanyWordText.toUpperCase() === companyName.trim().toUpperCase());
    }

    const saveGeneralInfo = (dataItem, saveCompanyNameToBilling) => {
        
        const countryCode = props.countries.getAlpha2Code(dataItem.country, props.countriesLNG);

        const generalInfo = {
            customerId: dataItem.customerId || currentCustomer.customerId,
            companyName: dataItem.companyName.trim(),
            country: countryCode,
            postalCode: dataItem.postalCode,
            street: dataItem.street,
            city: dataItem.city,
            streetNumber: dataItem.streetNumber,
            saveCompanyNameToBilling: saveCompanyNameToBilling
        };
        
        props.removeNotification();
        props.incrementLoadingCount();
        saveCustomerGeneralInfo(
            generalInfo,
            () => {
                props.decrementLoadingCount();
                props.dispatchNotification({
                    pageId: props.pageId,
                    type: NotificationTypes.success,
                    message: intl.formatMessage({ id: 'MasterData.SaveGeneralInfoSuccess' })
                });
                props.dispatchCustomerMasterData({
                    ...customerMasterData,
                    companyName: dataItem.companyName.trim(),
                    country: dataItem.country,
                    postalCode: dataItem.postalCode,
                    street: dataItem.street,
                    city: dataItem.city,
                    streetNumber: dataItem.streetNumber
                });

                setIsCompanyNameChanged(false);
                setIsCountryChanged(false);
                setIsPostalCodeChanged(false);
                setIsStreetChanged(false);
                setIsCityChanged(false);
                setIsStreetNumber(false);

                if (props.onGeneralInfoSaved) {
                    props.onGeneralInfoSaved();
                }

                let userManager = createUserManager();

                renewAccessToken(userManager)
                    .then(
                        () => {
                            props.refreshUserContext();
                        },
                        () => {
                            console.log('access token renewal failed, redirecting to login page...');

                            sessionStorage.setItem(SessionKeyIsAutomaticLogout, 'true');

                            signoutRedirect(userManager);
                        }
                    );
            },
            props.handleError);
    };

    const [isSalutationChanged, setIsSalutationChanged] = useState(false);
    const handleSalutationChange = (event) => {
        const isChanged = event.value.value !== initialSalutation();
        setIsSalutationChanged(isChanged);
    };

    const [isCompanyNameChanged, setIsCompanyNameChanged] = useState(false);
    const handleCompanyNameChange = (event) => {
        const isChanged = event.value.trim() !== customerMasterData.companyName.trim();
        setIsCompanyNameChanged(isChanged);
    };

    const [isStreetChanged, setIsStreetChanged] = useState(false);
    const handleStreetChange = (event) => {
        const isChanged = event.value !== customerMasterData.street;
        setIsStreetChanged(isChanged);
    };

    const [isStreetNumber, setIsStreetNumber] = useState(false);
    const handleStreetNumber = (event) => {
        const isChanged = event.value !== customerMasterData.streetNumber;
        setIsStreetNumber(isChanged);
    };

    const [isCountryChanged, setIsCountryChanged] = useState(false);
    const handleCountryChange = (event) => {
        const isChanged = event.value !== customerMasterData.country;
        setIsCountryChanged(isChanged);
        setCurrentCountries(props.filterBillingCountries(event.value))
    };

    const [isPostalCodeChanged, setIsPostalCodeChanged] = useState(false);
    const handlePostalCodeChange = (event) => {
        const isChanged = event.value !== customerMasterData.postalCode;
        setIsPostalCodeChanged(isChanged);
    };

    const [isCityChanged, setIsCityChanged] = useState(false);
    const handleCityChange = (event) => {
        const isChanged = event.value !== customerMasterData.city;
        setIsCityChanged(isChanged);
    };


    const [isContactPersonFirstNameChanged, setIsContactPersonFirstNameChanged] = useState(false);
    const handleContactPersonFirstNameChange = (event) => {
        const isChanged = event.value !== customerMasterData.contactPersonFirstName;
        setIsContactPersonFirstNameChanged(isChanged);
    };

    const [isContactPersonLastNameChanged, setIsContactPersonLastNameChanged] = useState(false);
    const handleContactPersonLastNameChange = (event) => {
        const isChanged = event.value !== customerMasterData.contactPersonLastName;
        setIsContactPersonLastNameChanged(isChanged);
    };

    const [isContactPersonEmailChanged, setIsContactPersonEmailChanged] = useState(false);
    const handleContactPersonEmailChange = (event) => {
        const isChanged = event.value !== customerMasterData.contactPersonEmail;
        setIsContactPersonEmailChanged(isChanged);
    };

    const [isContactPersonPhoneNumberChanged, setIsContactPersonPhoneNumberChanged] = useState(false);
    const handleContactPersonPhoneNumberChange = (event) => {
        const isChanged = event.value !== customerMasterData.contactPersonPhoneNumber;
        setIsContactPersonPhoneNumberChanged(isChanged);
    };

    useEffect(() => {
        const areChanges = getAreChanges();
        props.setAreChangesOnGeneral(areChanges);

    }, [isSalutationChanged,
        isCompanyNameChanged, isStreetChanged,
        isCountryChanged, isPostalCodeChanged, isCityChanged,
        isContactPersonFirstNameChanged, isContactPersonLastNameChanged,
        isContactPersonEmailChanged, isContactPersonPhoneNumberChanged
    ]);

    const getAreChanges = () => {
        return isSalutationChanged || isStreetNumber
            || isCompanyNameChanged || isStreetChanged
            || isCountryChanged || isPostalCodeChanged || isCityChanged
            || isContactPersonFirstNameChanged || isContactPersonLastNameChanged
            || isContactPersonEmailChanged || isContactPersonPhoneNumberChanged;
    }

    const getSalutation = () => {
        let sal = props.salutations.find(x => x.value === customerMasterData.salutation);
        return { value: sal };
    }

    const isDisabledGeneralContactPerson = (formRenderProps) => {
        return !formRenderProps.allowSubmit || !formRenderProps.valid || !(isSalutationChanged || isContactPersonPhoneNumberChanged
            || isContactPersonFirstNameChanged || isContactPersonLastNameChanged || isContactPersonEmailChanged)
    }

    return (
        <>
            <Prompt
                when={getAreChanges()}
                message={JSON.stringify({
                    header: "" + intl.formatMessage({ id: 'Confirm.UnsavedChangesTitle' }),
                    content: "" + intl.formatMessage({ id: 'Confirm.UnsavedChangesText' }),
                    cancel: "" + intl.formatMessage({ id: 'Control.Cancel' }),
                    confirm: "" + intl.formatMessage({ id: 'Control.Confirm' }),
                    confirmFunc: true
                })}
            />
            <div className="content">
                <Row id="masterdata">
                    <Col xs="12" lg="6">
                        <Form
                            onSubmit={handleSubmitGeneralInfo}
                            initialValues={customerMasterData}
                            key={JSON.stringify(customerMasterData) + "generalAddress"}
                            render={(formRenderProps) => (

                                <FormElement>
                                    <Card>
                                        <CardHeader>
                                            <h3>
                                                <FormattedMessage id='MasterData.CustomerInfo' />
                                                <div className="float-right">
                                                    <Button
                                                        type={"submit"}
                                                        color="primary"
                                                        disabled={!formRenderProps.allowSubmit || !formRenderProps.valid}
                                                    >
                                                        <FormattedMessage id='Control.Save' />
                                                    </Button>

                                                </div>
                                            </h3>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col xs="12">
                                                    <fieldset className={"k-form-fieldset"}>
                                                        <div className="mb-3">
                                                            <div className="k-form-field">
                                                                <Field
                                                                    id={"companyName"}
                                                                    name={"companyName"}
                                                                    label={intl.formatMessage({ id: 'MasterData.CompanyName' })}
                                                                    component={Input}
                                                                    validator={companyNameValidator}
                                                                    maxLength={200}
                                                                    onChange={handleCompanyNameChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="8">
                                                    <fieldset className={"k-form-fieldset"}>
                                                        <div className="mb-3">
                                                            <Field
                                                                id={"street"}
                                                                name={"street"}
                                                                label={intl.formatMessage({ id: 'MasterData.Street' })}
                                                                component={Input}
                                                                validator={streetValidator}
                                                                maxLength={200}
                                                                onChange={handleStreetChange}
                                                            />
                                                        </div>
                                                    </fieldset>
                                                </Col>
                                                <Col xs="4">
                                                    <fieldset className={"k-form-fieldset"}>
                                                        <div className="mb-3">
                                                            <Field
                                                                id={"streetNumber"}
                                                                name={"streetNumber"}
                                                                label={intl.formatMessage({ id: 'MasterData.No' })}
                                                                component={Input}
                                                                validator={streetNumberValidator}
                                                                maxLength={10}
                                                                onChange={handleStreetNumber}
                                                            />
                                                        </div>
                                                    </fieldset>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="4">
                                                    <fieldset className={"k-form-fieldset"}>
                                                        <div className="mb-3">
                                                            <Field
                                                                id={"postalCode"}
                                                                name={"postalCode"}
                                                                label={intl.formatMessage({ id: 'MasterData.PostalCode' })}
                                                                component={Input}
                                                                validator={postalCodeValidator}
                                                                onChange={handlePostalCodeChange}
                                                                maxLength={10}
                                                            />
                                                        </div>
                                                    </fieldset>
                                                </Col>
                                                <Col xs="8">
                                                    <fieldset className={"k-form-fieldset"}>
                                                        <div className="mb-3">
                                                            <Field
                                                                id={"city"}
                                                                name={"city"}
                                                                label={intl.formatMessage({ id: 'MasterData.City' })}
                                                                component={Input}
                                                                onChange={handleCityChange}
                                                                validator={cityValidator}
                                                                maxLength={200}
                                                            />
                                                        </div>
                                                    </fieldset>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12">
                                                    <fieldset className={"k-form-fieldset"}>
                                                        <div className="mb-3 country">
                                                            <Field
                                                                id={"country"}
                                                                name={"country"}
                                                                label={intl.formatMessage({ id: 'MasterData.Country' })}
                                                                component={FormAutoComplete}
                                                                data={currentCountries}
                                                                validator={countryValidator}
                                                                maxLength={200}
                                                                onChange={handleCountryChange}
                                                            />
                                                        </div>
                                                    </fieldset>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </FormElement>
                            )}
                        />
                    </Col>
                    <Col xs="12" lg="6">
                        <Form
                            onSubmit={handleSubmitContactPerson}
                            initialValues={customerMasterData}
                            key={JSON.stringify(customerMasterData) + "generalContactPerson"}
                            render={(formRenderProps) => (
                                <FormElement>
                                    <Card>
                                        <CardHeader>
                                            <h3>
                                                <FormattedMessage id='MasterData.ContactPersonTitle' />
                                                <div className="float-right">
                                                    <Button
                                                        type={"submit"}
                                                        color="primary"
                                                        disabled={isDisabledGeneralContactPerson(formRenderProps)}
                                                    >
                                                        <FormattedMessage id='Control.Save' />
                                                    </Button>
                                                </div>
                                            </h3>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col xs="12">
                                                    <fieldset className={"k-form-fieldset"}>
                                                        <div className="mb-3">
                                                            <Field
                                                                id={"salutationObj"}
                                                                name={"salutationObj"}
                                                                label={intl.formatMessage({ id: 'MasterData.Salutation' })}
                                                                component={FormDropDownList}
                                                                textField={"text"}
                                                                dataItemKey={"value"}
                                                                data={salutations}
                                                                validator={salutationValidator}
                                                                onChange={handleSalutationChange}
                                                            />
                                                        </div>
                                                    </fieldset>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="6">
                                                    <fieldset className={"k-form-fieldset"}>
                                                        <div className="mb-3">
                                                            <Field
                                                                name={"contactPersonFirstName"}
                                                                label={intl.formatMessage({ id: 'MasterData.Firstname' })}
                                                                component={Input}
                                                                onChange={handleContactPersonFirstNameChange}
                                                                validator={contactPersonFirstNameValidator}
                                                                maxLength={200}
                                                            />
                                                        </div>
                                                    </fieldset>
                                                </Col>
                                                <Col xs="6">
                                                    <fieldset className={"k-form-fieldset"}>
                                                        <div className="mb-3">
                                                            <Field
                                                                name={"contactPersonLastName"}
                                                                label={intl.formatMessage({ id: 'MasterData.Lastname' })}
                                                                component={Input}
                                                                onChange={handleContactPersonLastNameChange}
                                                                validator={contactPersonLastNameValidator}
                                                                maxLength={200}
                                                            />
                                                        </div>
                                                    </fieldset>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12">
                                                    <fieldset className={"k-form-fieldset"}>
                                                        <div className="mb-3">
                                                            <Field
                                                                name={"contactPersonEmail"}
                                                                label={intl.formatMessage({ id: 'MasterData.Mail' })}
                                                                component={Input}
                                                                onChange={handleContactPersonEmailChange}
                                                                validator={contactPersonEmailValidator}
                                                                maxLength={256}
                                                            />
                                                        </div>
                                                    </fieldset>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12">
                                                    <fieldset className={"k-form-fieldset"}>
                                                        <div className="mb-3">
                                                            <Field
                                                                name={"contactPersonPhoneNumber"}
                                                                label={intl.formatMessage({ id: 'MasterData.PhoneNo' })}
                                                                component={Input}
                                                                onChange={handleContactPersonPhoneNumberChange}
                                                                maxLength={20}
                                                                validator={""}
                                                            />
                                                        </div>
                                                    </fieldset>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </FormElement>
                            )}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default GeneralTab
