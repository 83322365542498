import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Loader } from "@progress/kendo-react-indicators";
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { useExceptionDialog } from './ExceptionDialog/ExceptionDialogProvider';
import { getBackgroundTaskStatus } from '../../services/inventoryService';
import axios from 'axios';

const TASK_STATUS_POLLING_INTERVAL_MILLISECONDS = 2000;

const LongRunningBackgroundTaskIndicatorDialog = props => {

    const cancelTokenSource = axios.CancelToken.source();
    const selectedPopulation = useSelector(state => state.dashboard.population);
    const [exit, setExit] = useState(false);
    const currentImportState = useRef(null);
    const [statusText, setStatusText] = useState(props.openingStatusText);
    const [hubConnection, setHubConnection] = useState(null);
    const [pollCount, setPollCount] = useState(0);
    const [echoReceived, setEchoReceived] = useState(false);
    const pageId = 'LongRunningBackgroundTaskIndicator';
    const dispatchExceptionDialog = useExceptionDialog();
    const showException = error => {
        if (error) {
            let errorMessage = '';
            if (typeof error === 'string' || error instanceof String) {
                errorMessage = error;
            }
            else if (error.response && error.response.data) {
                errorMessage = error.response.data.detail;
            }
            if (errorMessage && errorMessage.length > 0) {
                dispatchExceptionDialog({
                    pageId: pageId,
                    message: errorMessage
                })
            }
        }
    };

    const closeDialog = () => {
        //setExit(true);
        if (props.onTaskFinishedCallback) {
            props.onTaskFinishedCallback(currentImportState.current);
        }
    };

    const setStatusTextByImportStatus = React.useCallback((importStatus) => {
        let msg = props.statusChangeTextProvider ? props.statusChangeTextProvider(importStatus) : importStatus;
        setStatusText(msg);
    }, [setStatusText]);

    const importStateChanged = React.useCallback((importStatus) => {
        currentImportState.current = importStatus;

        if (props.finalStates && props.finalStates.includes(importStatus)) {
            closeDialog();
        }
        else {
            setStatusTextByImportStatus(importStatus);
            setPollCount(pollCount + 1);
        }
    }, [closeDialog, setStatusTextByImportStatus])

    const pollBackgroundTaskStatus = () => {
        if (!echoReceived) {
            console.log('Polling background task status (current poll count:' + pollCount + ')');
            getBackgroundTaskStatus(
                selectedPopulation.populationId,
                response => {
                    console.log('Background task status:' + response.status);
                    importStateChanged(response.status);
                },
                () => { console.log('Polling background task status failed!'); },
                cancelTokenSource.token
            );
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            pollBackgroundTaskStatus();
        }, TASK_STATUS_POLLING_INTERVAL_MILLISECONDS);

        return () => clearTimeout(timer);
    }, [pollCount]);

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(window.origin + '/inventoryImportHub')
            .withAutomaticReconnect()
            .build();

        setHubConnection(newConnection);

        return () => { cancelTokenSource.cancel(); };
    }, [props.visible]);

    const onInventoryImportStatusChanged = React.useCallback((message) => {
        if (
            selectedPopulation?.populationId.toLowerCase() === message.populationId.toLowerCase()
        ) {
            console.log('InventoryImportStatusChanged notification received: populationId:' + message.populationId + ', importStatus:' + message.importStatus + ', errorMsg:' + message.errorMessage);
            importStateChanged(message.importStatus);
            showException(message.errorMessage);
        }
    }, [selectedPopulation, importStateChanged])

    const onInventoryImportBackgroundTaskFailed = React.useCallback((message) => {
        if (selectedPopulation && selectedPopulation.populationId === message.populationId) {
            console.log('InventoryImportBackgroundTaskFailed notification received: populationId:' + message.populationId + ', errorMsg:' + message.errorMessage);
            importStateChanged(window.enumBackgroundTaskStatus.DataImportFailed);
            showException(message.errorMessage);
            closeDialog();
        }
    }, [selectedPopulation, importStateChanged])

    const onEcho = React.useCallback((population, message) => {
        console.log('Echo received: populationId:' + population + ', message:' + message);
        setEchoReceived(true);
    }, [])

    useEffect(() => {
        if (hubConnection && !hubConnection._connectionStarted) {
            hubConnection.start()
                .then(_result => {
                    console.log('Connected to inventoryImportHub!');
                    hubConnection.on('InventoryImportStatusChanged', onInventoryImportStatusChanged);
                    hubConnection.on('InventoryImportBackgroundTaskFailed', onInventoryImportBackgroundTaskFailed);
                    hubConnection.on('Echo', onEcho);

                    hubConnection.send('echo', selectedPopulation.populationId, 'Hello there!');
                })
                .catch(e => {
                    console.log('Hub Connection failed: ', e);
                });
        }

        return async () => {
            if (hubConnection) {
                try {
                    hubConnection.off('InventoryImportStatusChanged', onInventoryImportStatusChanged);
                    hubConnection.off('InventoryImportBackgroundTaskFailed', onInventoryImportBackgroundTaskFailed);
                    hubConnection.off('Echo', onEcho);
                    hubConnection.stop();
                }
                catch (ex) {
                    console.log('hubConnection.stop() failed ', ex);
                }
            }
        }

    }, [hubConnection]);

    const titleText = (currentImportState.current && currentImportState.current !== window.enumBackgroundTaskStatus.NoBackgroundTaskRunning) ? props.title : '';
    const waitMessage = props.waitingForErp === true && (currentImportState.current === null || currentImportState.current === window.enumBackgroundTaskStatus.NoBackgroundTaskRunning)
        ? <FormattedMessage id='WaitingForErp.SyncWithErp' />
        : props.waitMessage;
    
    return !exit && props.visible ? (
        <div>
            <Dialog title={titleText} className='background-task-dialog'>
                <div className="text-center">
                    <p>
                        {waitMessage}
                    </p>
                    <Loader themeColor={window.enumTheme['Dark'] ? 'light' : 'dark'} type="infinite-spinner" size="large" />
                    <p>
                        {statusText}
                    </p>
                </div>
                {
                    ((props.buttons || props.links) &&
                        <DialogActionsBar>
                            {
                                (currentImportState.current && currentImportState.current !== window.enumBackgroundTaskStatus.NoBackgroundTaskRunning && props.buttons)
                                ? props.buttons.map((btn, index) => {
                                    let btnkey = "lrbtidab_" + index;
                                    return (
                                        <Button key={btnkey} color="secondary" disabled={btn.disabled}
                                            onClick={
                                            (event) => {
                                                btn.onClick(selectedPopulation ? selectedPopulation.populationId : null);
                                                event.currentTarget.disabled = true;
                                                setStatusText(btn.onClickText);
                                                return false;
                                            }}>
                                            {btn.text}
                                        </Button>
                                    )
                                })
                                : null
                            }
                            {
                                props.links && props.links.map((link, index) => {
                                    return (<Link key={index} className='btn btn-primary btn-block' to={link.to}>{link.text}</Link>)
                                })
                            }
                        </DialogActionsBar>)
                }
            </Dialog>
        </div>
    ) : null
};


export default LongRunningBackgroundTaskIndicatorDialog;
