import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import LicenseDialog from './LicenseDialog';
import LicenseMasterDataDialog from './LicenseMasterDataDialog';
import IndividualLicenseDialog from './IndividualLicenseDialog';

const License = (props) => {
    const currentCustomer = useSelector(state => state.dashboard.customer);

    const [activeLicenseId, setActiveLicenseId] = useState(null);
    const [selectedLicenseId, setSelectedLicenseId] = useState(null);
    const [billingPeriod, setBillingPeriod] = useState(null);

    useEffect(() => {
        if (!currentCustomer) {
            return;
        }

        setActiveLicenseId(currentCustomer.activeLicense.licenseId);
        setSelectedLicenseId(currentCustomer.activeLicense.licenseId);
        setBillingPeriod(currentCustomer.activeLicense.licenseBillingPeriod ?? window.enumBillingPeriod.Year);

        setIsLicenseDialogVisible(true);
    }, [currentCustomer]);

    const [isLicenseDialogVisible, setIsLicenseDialogVisible] = useState(false);

    const handleLicenseDialogContinue = (params) => {
        if (params.licenseId > 0) {
            setSelectedLicenseId(params.licenseId);
            setBillingPeriod(params.billingPeriod);
            setIsLicenseDialogVisible(false);
            setIsLicenseMasterDataDialogVisible(true);
        }
        else {
            setIsLicenseDialogVisible(false);
            setIsIndividualLicenseDialogVisible(true);
        }
    };

    const handleLicenseDialogClose = () => {
        setIsLicenseDialogVisible(false);
        props.onClose();
    };

    const [isLicenseMasterDataDialogVisible, setIsLicenseMasterDataDialogVisible] = useState(false);
    const [isIndividualLicenseDialogVisible, setIsIndividualLicenseDialogVisible] = useState(false);

    const handleLicenseMasterDataDialogBack = () => {
        setIsLicenseMasterDataDialogVisible(false);
        setIsLicenseDialogVisible(true);
    };

    const handleLicenseMasterDataDialogClose = (isLicenseAssigned) => {
        setIsLicenseMasterDataDialogVisible(false);
        props.onClose(isLicenseAssigned);
    };

    const handleIndividualLicenseDialogBack = () => {
        setIsIndividualLicenseDialogVisible(false);
        setIsLicenseDialogVisible(true);
    };

    const handleIndividualLicenseDialogClose = (isLicenseAssigned) => {
        setIsIndividualLicenseDialogVisible(false);
        props.onClose(isLicenseAssigned);
    };

    return (
        <div>
            {isLicenseDialogVisible && (
                <LicenseDialog
                    activeLicenseId={activeLicenseId}
                    selectedLicenseId={selectedLicenseId}
                    billingPeriod={billingPeriod}
                    onContinue={handleLicenseDialogContinue}
                    onClose={handleLicenseDialogClose}
                />
            )}
            {isLicenseMasterDataDialogVisible && (
                <LicenseMasterDataDialog
                    licenseId={selectedLicenseId}
                    billingPeriod={billingPeriod}
                    onBack={handleLicenseMasterDataDialogBack}
                    onClose={handleLicenseMasterDataDialogClose}
                />
            )}
            {isIndividualLicenseDialogVisible && (
                <IndividualLicenseDialog
                    onBack={handleIndividualLicenseDialogBack}
                    onClose={handleIndividualLicenseDialogClose}
                />
            )}
        </div>
    );
};

export default License;
