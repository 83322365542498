import * as React from "react";
import { WizzardContext } from "../WizzardContext";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from 'react-intl';
import { useState } from "react";
import { Card, CardBody, CardHeader, FormGroup, Label, Button, Collapse } from 'reactstrap';
import { RadioButton } from "@progress/kendo-react-inputs";
import { formatNumber, formatNumberWithDecimals } from "../../../../utils/localization";
import { Form } from "@progress/kendo-react-form";
import ReactTooltip from "react-tooltip";
import { defaultConfidenceLevel, defaultErrorDefinition, defaultErrorProportionForRejectionThreshold, defaultErrorWeightPercentage } from "../../../Administration/Settings/Settings";
import { ErrorDefinition } from "../../../../services/settingsService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPencilAlt,
    faChevronUp,
    faChevronDown
} from '@fortawesome/pro-light-svg-icons'

const StockDataHighCard = props => {
    const wizzardContext = React.useContext(WizzardContext);
    let history = useHistory();
    const intl = useIntl();
    const [isStaseqInfoOpen, setIsStaseqInfoOpen] = useState(false);
    const [isStaseqParamOpen, setIsStaseqParamOpen] = useState(false);

    const goToTestParameter = () => {
        history.push('/administration/settings/2');
    };

    return (
        <label htmlFor="highRadio" className="select-card">
            <Card className={props.selectedCard === props.inventoryMethod.Staseq ? "compare-options card-selected" : "compare-options"}>
                <CardHeader>
                    <h1><FormattedMessage id='StockDataQuality.High' />
                        <div className="float-right">
                            <RadioButton
                                name="ChooseDataQualityRadio"
                                value={props.inventoryMethod.Staseq}
                                checked={props.selectedCard === props.inventoryMethod.Staseq}
                                onChange={props.handleChange}
                                id="highRadio"
                            />
                        </div>
                    </h1>
                </CardHeader>
                <CardBody>
                    <h3 className="mt-3">
                        <FormattedMessage id="StockDataQuality.CardBody.Staseq.Sub" />
                        <div className="pull-right">
                            <Button className="btn btn-secondary" onClick={() => setIsStaseqInfoOpen(!isStaseqInfoOpen)} >
                                <FontAwesomeIcon icon={isStaseqInfoOpen ? faChevronUp : faChevronDown} />
                            </Button>

                        </div>
                    </h3>

                    <Collapse isOpen={isStaseqInfoOpen}>
                        <FormattedMessage id="StockDataQuality.CardBody.Stasaq.Row1" />
                        <ul>
                            <li><FormattedMessage id="StockDataQuality.CardBody.Stasaq.Row2" /></li>
                            <li><FormattedMessage id="StockDataQuality.CardBody.Stasaq.Row3" /></li>
                            <li><FormattedMessage id="StockDataQuality.CardBody.Stasaq.Row4" /></li>
                        </ul>
                        <FormattedMessage id="StockDataQuality.CardBody.Stasaq.Row5" />
                    </Collapse>

                    <hr />

                    <h3>
                        <FormattedMessage id="Settings.TestParams.TabText" />
                        <div className="float-right">
                            <ReactTooltip place="top" type="error" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />
                            <Button onClick={goToTestParameter} disabled={!props.userWithAdminPriviledges}
                                data-tip={intl.formatMessage({ id: "Preparation.EditInSettings" })} color="secondary">
                                <FontAwesomeIcon icon={faPencilAlt} />
                            </Button>
                            <Button onClick={() => setIsStaseqParamOpen(!isStaseqParamOpen)} className="btn btn-secondary">
                                <FontAwesomeIcon icon={isStaseqParamOpen ? faChevronUp : faChevronDown} />
                            </Button>
                        </div>
                    </h3>


                    <Collapse isOpen={isStaseqParamOpen}>



                        <Form render={() => (
                            <>
                                <FormGroup className="mt-3">
                                    <Label><FormattedMessage id="Settings.TestParams.ConfidenceLevelPercent" /></Label>
                                    <h5 className="card-title">{formatNumberWithDecimals(wizzardContext.wizzardGlobal.CustomerSettings.confidenceLevel ?? defaultConfidenceLevel, props.language, 1)} %</h5>
                                </FormGroup>
                                <FormGroup className="mt-3">
                                    <Label><FormattedMessage id="Settings.TestParams.ErrorDefinition" /></Label>
                                    <h5 className="card-title">
                                        {(wizzardContext.wizzardGlobal.CustomerSettings.errorDefinition ?? defaultErrorDefinition) == ErrorDefinition.AcceptTolerableDeviations ?
                                            <FormattedMessage id="Settings.TestParams.ErrorDefinition.AcceptDeviations" /> :
                                            <FormattedMessage id="Settings.TestParams.ErrorDefinition.AnyDiffError" />
                                        }
                                    </h5>
                                </FormGroup>
                                <FormGroup className="mt-3">
                                    <Label><FormattedMessage id="Settings.TestParams.ErrorProportion" /></Label>
                                    <h5 className="card-title">{formatNumberWithDecimals(wizzardContext.wizzardGlobal.CustomerSettings.errorProportionForRejectionThreshold ?? defaultErrorProportionForRejectionThreshold, props.language, 1)} %</h5>
                                </FormGroup>
                                <FormGroup className="mt-3">
                                    <Label><FormattedMessage id="Settings.TestParams.ErrorWeight" /></Label>
                                    {wizzardContext.wizzardGlobal.CustomerSettings.errorWeightInitialSampleCount != null ?
                                        <h5 className="card-title">
                                            {formatNumber(wizzardContext.wizzardGlobal.CustomerSettings.errorWeightInitialSampleCount, props.language)}
                                            {' ' + intl.formatMessage({ id: 'Settings.TestParams.Items' })}
                                        </h5> :
                                        <h5 className="card-title">{formatNumberWithDecimals(wizzardContext.wizzardGlobal.CustomerSettings.errorWeightPercentage ?? defaultErrorWeightPercentage, props.language, 1)} %</h5>
                                    }
                                </FormGroup>
                            </>
                        )}
                        />
                    </Collapse>
                </CardBody>
            </Card>
        </label>
    )
}
export default StockDataHighCard
