const privacyPolicyHtml_de = `
<p class="lead"lead>Stand 01.03.2022 </p>
<h5>§ 1 Allgemeines, Definitionen</h5>
<ol>
    <li>Diese AGB der REMIRA Group GmbH, Phoenixplatz 2, 44263 Dortmund, Germany (im Folgenden kurz „REMIRA“) finden ausschließlich bei solchen Verträgen Anwendung, die REMIRA mit Unternehmen, Kaufleuten, juristischen Personen des öffentlichen Rechts oder öffentlichen Sondervermögen schließt. </li>
    <li>Für diese AGB gelten folgende Begriffsdefinitionen: </li>
    <ol type="a">
        <li>„AGB“ sind vorliegende Allgemeine Geschäftsbedingungen der REMIRA Group GmbH für Software-as-a-Service. </li>
        <li>„Change Request“ ist eine gesonderte Vereinbarung zwischen den Vertragsparteien über eine Änderung des Vertragsinhalts. </li>
        <li>„Drittsoftware“ ist Software, die von Dritten erstellt und/oder dem Kunden von Dritten überlassen wird bzw. wurde. </li>
        <li>„Drittsystem“ ist ein Softwaresystem, das von Dritten erstellt und/oder dem Kunden von Dritten überlassen wird bzw. wurde. </li>
        <li>„Mitarbeiter“ sind sämtliche Mitarbeiter (mit und ohne Arbeitnehmerstatus), Organe und Vertreter von REMIRA. </li>
        <li>„Personentag“ ist der Einsatz eines Mitarbeiters im Umfang von 8 Zeitstunden zwischen 08:00 und 18:00 Uhr pro Werktag. </li>
        <li>„Software“ ist von REMIRA an den Kunden überlassene Standardsoftware und/oder Individualsoftware. </li>
        <li>„Standardsoftware“ ist Software, die REMIRA und/oder ein Dritter nicht für einen bestimmten Kunden erstellt oder erstellt hat. </li>
        <li>„Tagessatz“ ist die vom Kunden pro Personentag geschuldete Vergütung. </li>
        <li>„Vertragspartei“ ist REMIRA oder der Kunde. </li>
        <li>„Vertragsparteien“ sind REMIRA und der Kunde. </li>
        <li>„Werktag“ ist jeder Kalendertag, der nicht Samstag, Sonntag oder ein in der Bundesrepublik Deutschland oder dem Bundesland Nordrhein-Westfalen gesetzlicher Feiertag ist.</li>
    </ol>
    <li>Allgemeine Geschäftsbedingungen des Kunden werden nicht Bestandteil des Vertragsverhältnisses, es sei denn, REMIRA stimmt deren Einbeziehung ausdrücklich zu. Dieses Zustimmungsbedürfnis gilt in jedem Fall, also auch dann, wenn REMIRA in Kenntnis der Allgemeinen Geschäftsbedingungen des Kunden die Leistungen vorbehaltlos ausführt. </li>
    <li>REMIRA ist berechtigt, vorliegende AGB im Laufe des Vertragsverhältnisses anzupassen. Eine solche Anpassung erfolgt durch die Übersendung neuer AGB in Textform an den Kunden nebst einem Begleitschreiben, dass auf die zukünftig geltenden AGB, den Zeitpunkt deren Geltung und das Widerspruchsrecht des Kunden ausdrücklich hinweist. Die durch REMIRA angepassten AGB werden mit Ablauf einer Frist von 21 Kalendertagen, beginnend mit Zugang der angepassten AGB nebst Begleitschreiben beim Kunden wirksam und ersetzen die bis zu deren Inkrafttreten geltenden AGB insgesamt. Der Kunde hat das Recht, der Einbeziehung und Geltung neuer, durch REMIRA angepasster AGB innerhalb einer Frist von 14 Kalendertagen, beginnend mit Zugang der anpassten AGB und dem entsprechenden Begleitschreiben beim Kunden, zu widersprechen. Widerspricht der Kunde der Einbeziehung der angepassten AGB fristgemäß, so werden diese nicht Vertragsinhalt. Geht REMIRA innerhalb der Widerspruchsfrist kein Widerspruch durch den Kunden zu, gilt die Zustimmung des Kunden zur Einbeziehung der durch REMIRA angepassten AGB als erteilt. </li>
</ol>
<h5>§ 2 Leistungsgegenstand, Leistungserbringung </h5>
<ol>
    <li>REMIRA erbringt die vertragsgemäßen Leistungen gemäß dem jeweils aktuellen Stand bewährter Technik. </li>
    <li>REMIRA überlässt dem Kunden zu Standardsoftware eine Benutzerdokumentation (Anwenderleitfaden) in digitaler Form (z.B. PDF-Dateien) in deutscher Sprache, die sich an einen fachkundigen IT-Nutzer adressiert. Weitere Dokumentation ist nicht geschuldet. </li>
    <li>REMIRA weist hiermit darauf hin, dass durch den vertragsgemäßen Einsatz von Standardsoftware und/oder Individualsoftware eine Nachlizenzierung von Drittsoftware notwendig werden kann. Hierfür anfallende Mehrkosten trägt der Kunde. </li>
    <li>Beratungspflichten von REMIRA bestehen nur, soweit solche ausdrücklich vereinbart sind. </li>
    <li>Alle Zeitangaben beziehen sich auf die Zeitzone Berlin, Central European Time/Mitteleuropäische Zeit (CET/MEZ). </li>
    <li>Leistungen von REMIRA gelten nur insofern und insoweit als garantiert, wie das Wort „Garantie“ oder „garantierte Leistung“ ausdrücklich und in deren Zusammenhang fällt. Insbesondere in Service Level Agreements, Supportverträgen, Wartungs- und Pflegeverträgen vereinbarte Leistungen stellen keine Garantien dar. </li>
    <li>Leistungs- und Erfüllungsort ist der Hauptgeschäftssitz von REMIRA.  </li>
    <li>Die ordnungsgemäße Nutzbarkeit von Standardsoftware ist nur möglich, sofern der Kunde die aktuellen Systemvoraussetzungen vorhält. Die aktuellen Systemvoraussetzungen werden dem Kunden auf Nachfrage hin von REMIRA mitgeteilt oder innerhalb der Software zur Kenntnisnahme bereitgestellt. </li>
    <li>Die Sicherung von Daten des Kunden liegt grundsätzlich im Verantwortungsbereich des Kunden. </li>
    <li>Der Kunde ist verpflichtet, sämtliche außenwirtschaftsrechtlichen Bestimmungen zu beachten und keine Verbindungen zu Personen und Organisationen aufzunehmen und/oder zu unterhalten, gegen die restriktive Maßnahmen zur Bekämpfung des Terrorismus oder andere außenwirtschaftliche Sanktionen verhängt wurden bzw. werden. </li>
    <li>REMIRA ist berechtigt, Software im Rahmen von Wartung, Support und regulärer Weiterentwicklung, insbesondere aufgrund allgemeiner Verbesserungen und der Anpassung an geänderte rechtliche Rahmenbedingungen, mittels Patches, Updates, Upgrades und neuen Releases zu ändern und/oder anzupassen, soweit diese Änderungen bzw. Anpassungen technisch notwendig sind und/oder Sicherheitslücken in der Software schließen und/oder die Software funktional und/oder technisch verbessern und/oder die bestehenden Funktionalitäten nicht eingeschränkt werden. Solche Änderungen und Anpassungen sind in jedem Falle nur dann zulässig, wenn sie dem Kunden unter Berücksichtigung seiner Interessen zumutbar sind. </li>
    <li>Die Leistungen und Lieferungen von REMIRA stehen unter dem Vorbehalt der Selbstbelieferung, bedingt auf den Umstand, dass REMIRA an dem temporären oder dauerhaften Ausfall der Selbstbelieferung kein Verschulden trifft. REMIRA informiert den Kunden über einen solchen Ausfall möglichst vorab, zeitnah und schriftlich. Ist der Ausfall dauerhaft oder dauert dieser länger als 6 Monate, so ist der Kunde berechtigt, das Vertragsverhältnis ohne Einhaltung einer Kündigungsfrist zu kündigen (außerordentliches Sonderkündigungsrecht) </li>
    <li>Sind vertragsgemäße Leistungen auf verschiedene technische Arten und/oder mit verschiedenen technischen Mitteln umsetzbar, so entscheidet REMIRA über Art und Mittel nach billigem Ermessen. Eine solche Entscheidung muss die Interessen des Kunden berücksichtigen und diesem zumutbar sein. </li>
</ol>
<h5>§ 3 Vergütungsansprüche</h5>
<ol>
    <li>Leistungen von REMIRA sind vom Kunden zu vergüten. </li>
    <li>Sämtliche von REMIRA ausgewiesenen Tagessätze, Stundensätze, Preise und Vergütungen verstehen sich jeweils zuzüglich anfallender, gesetzlicher Umsatzsteuer und gegebenenfalls anfallender Zölle. </li>
    <li>REMIRA stellt die vertragsgemäßen Vergütungsansprüche ordentlich in Rechnung. REMIRA ist zur elektronischen Rechnungslegung berechtigt. </li>
    <li>Fällige Vergütungsansprüche von REMIRA sind innerhalb von 14 (vierzehn) Kalendertagen, beginnend mit Rechnungserhalt, auszugleichen. </li>
    <li>Erbringt REMIRA Leistungen im Rahmen gesetzlicher und/oder vertraglicher Gewährleistungsansprüche des Kunden, so sind diese vergütungsfrei, insbesondere auch in dem Fall, dass zwischen REMIRA und dem Kunden ein Vertragsverhältnis (z.B. Software-Supportvertrag) besteht, gemäß dem dieselben Leistungen vom Kunden zu vergüten wären.  </li>
    <li>Einmalig anfallende Vergütungsansprüche sind spätestens mit Überlassung des Mietgegenstands zur Zahlung fällig. </li>
    <li>Die laufende, monatliche Miete ist jährlich und im Voraus zur Zahlung fällig.  </li>
    <li>Die monatliche Miete erhöht sich jährlich zum 01.01. um jeweils 3% der jeweils zuletzt gültigen Miete, jedoch frühestens zum Ablauf der Mindestvertragslaufzeit. </li>
</ol>
<h5>§ 4 Browser- und Systemkompatibilität </h5>
<ol>
    <li>Ist eine korrekte Wiedergabe der zu überlassenden Software mittels einer der vereinbarten Browserversionen allein aufgrund der Nichteinhaltung der Konventionen der W3C oder eines Programmierfehlers seitens des Browserherstellers nicht möglich und hat REMIRA diesen Umstand nicht zu vertreten, so stellt dies keinen Sachmangel dar und REMIRA ist nicht verpflichtet, die Software zu ändern oder anzupassen. </li>
    <li>REMIRA weist den Kunden hiermit ausdrücklich darauf hin, dass die Lauffähigkeit bzw. Funktionalität der Software beeinträchtigt oder vollständig beseitigt werden kann, sofern die vereinbarten Betriebssysteme und Browserversionen durch neue Releases, Updates, Upgrades oder Folgeversionen dieser Programme verändert, überholt oder abgelöst werden.</li>
</ol>
<h5>§ 5 Allgemeine Nebenpflichten des Kunden </h5>
<ol>
    <li>Der Kunde ist zur Mitwirkung in notwendigem Umfang verpflichtet.  </li>
    <li>Der Kunde stellt sicher, dass von ihm beigebrachte Daten und Informationen richtig, vollständig und zweckmäßig sind; REMIRA obliegen insofern keine aktiven Prüfungspflichten. </li>
    <li>Der Kunde ist verpflichtet, REMIRA unverzüglich auf etwaige Mängel oder Lücken bei der Leistungserbringung hinzuweisen, sofern und sobald er hiervon Kenntnis nimmt. </li>
    <li>Der Kunde benennt einen Mitarbeiter seines Haues, der REMIRA als entscheidungsberechtigter Ansprechpartner zur Verfügung steht. Ferner benennt der Kunde einen Stellvertreter für Fälle der Nichterreichbarkeit des primären Ansprechpartners. </li>
    <li>Der Kunde unterstützt REMIRA bei der Ermittlung der Höhe variabler, insbesondere nutzungsabhängiger Vergütungsansprüche in notwendigem und zumutbarem Umfang und für REMIRA kostenfrei. </li>
    <li>Der Kunde unterstützt REMIRA bei Prüfungen, ob Software ausschließlich in dem Umfang genutzt wird, wie REMIRA dem Kunden an dieser Rechte eingeräumt hat. Auf entsprechende Nachfrage von REMIRA hin erteilt der Kunde innerhalb angemessener Frist entsprechende Selbstauskünfte mit nachvollziehbaren Nachweisen über den Nutzungsumfang. </li>
</ol>
<h5>§ 6 Werke und Leistungen Dritter</h5>
<ol>
    <li>Überlässt REMIRA dem Kunden Drittsoftware, so gelten für die Nutzung der betreffenden Drittsoftware und deren Support und Wartung vorrangig die Regelungen der Lizenzbedingungen der betreffenden Dritten, sofern und soweit REMIRA beim Einsatz der vertragsgegenständlichen Drittsoftware gegenüber dem Dritten verpflichtet ist, diese Lizenzbedingungen auch in das Vertragsverhältnis zwischen den Vertragsparteien einzubeziehen. Auf Nachfrage des Kunden teilt REMIRA dem Kunden in Textform mit, um welche Lizenzbedingungen es sich handelt, auf welche Drittsoftware sich diese beziehen und überlässt bzw. übersendet dem Kunden die entsprechenden Lizenzbedingungen. Lizenzbedingungen in diesem Sinne sind insbesondere End-User Licence Agreements, SLAs, Support- und Wartungsverträge und Nutzungsbedingungen. </li>
</ol>
<h5>§ 7 Nutzungsrechte</h5>
<ol>
    <li>REMIRA räumt dem Kunden das einfache Recht ein, den Mietgegenstand räumlich unbeschränkt und zeitlich auf die Laufzeit des Mietvertrags beschränkt vertrags- und bestimmungsgemäß zu nutzen. </li>
    <li>Der Kunde ist nicht zur Unter- und/oder Weitervermietung berechtigt, insbesondere nicht im Wege von Cloud-Computing, ASP oder SaaS. </li>
    <li>Das Eigentum am Mietgegenstand liegt und verbleibt bei REMIRA. </li>
    <li>Die Einräumung einer faktischen und/oder technischen Nutzungsmöglichkeit stellt keine Nutzungsrechtseinräumung durch REMIRA dar. </li>
    <li>Mit Zustimmung von REMIRA ist der Kunde zur Unterlizenzierung des Mietgegenstands berechtigt. Im Falle einer solchen Zustimmung gilt Folgendes: </li>
    <ol type="a">
        <li>Der Kunde ist nicht berechtigt, seinem Unterlizenznehmer mehr Rechte am Mietgegenstand einzuräumen, als er selbst von REMIRA an Rechten erhalten hat bzw. erhält. </li>
        <li>Die Nutzung des Mietgegenstands durch Unterlizenznehmer des Kunden gilt gegenüber REMIRA als Nutzung durch den Kunden. Ein Verschulden eines Unterlizenznehmers wird dem Kunden als eigenes Verschulden zugerechnet. </li>
        <li>Mit Beendigung des Nutzungsrechts des Kunden am Mietgegenstand, gleich aus welchem Sach- und Rechtsgrund, erlöschen zeitgleich sämtliche Nutzungsrechte der Unterlizenznehmer des Kunden am Mietgegenstand, die diese vom Kunden ableiten. </li>
    </ol>
</ol>
<h5>§ 8 Vertragsschluss, Laufzeit, Kündigung </h5>
<ol>
    <li>Der Kunde bestellt Software direkt in der Software (Angebot). Mit Zugang der Auftragsbestätigung durch REMIRA (via E-Mail) beim Kunden (Annahme) ist der Vertrag geschlossen. </li>
    <li>Der Mietvertrag hat eine Laufzeit von 36 Monaten (Mindestvertragslaufzeit). Die Laufzeit verlängert sich um jeweils 12 weitere Monate (Verlängerungszeitraum), sofern nicht eine Vertragspartei den Mietvertrag unter Einhaltung einer Kündigungsfrist von 3 Monaten zum Ende der Mindestvertragslaufzeit oder eines Verlängerungszeitraums ordentlich kündigt. Das Recht jeder Vertragspartei zur außerordentlichen Kündigung aus wichtigem Grund bleibt hiervon unberührt. </li>
    <li>Eine ordentliche Kündigung bedarf der Schriftform; genügt sie dieser nicht, so ist sie nichtig. </li>
    <li>Für jeden Verlängerungszeitraum gelten die jeweils 4 (vier) Monate vor Beginn des jeweiligen Verlängerungszeitraums aktuell geltende Preisliste und Allgemeine Geschäftsbedingungen von REMIRA. REMIRA stellt diese Unterlagen dem Kunden auf Nachfrage in digitaler Form (pdf) zur Verfügung. </li>
    <li>Der Kunde ist verpflichtet, die Nutzung des Mietgegenstands spätestens zum Beendigungszeitpunkt des Mietverhältnisses insgesamt einzustellen und den Mietgegenstand (in Form von Software oder Datenbanken) insgesamt und ersatzlos von seinen Systemen und aus seinen Systemen zu löschen. Auf Nachfrage von REMIRA hin hat der Kunde die Löschung schriftlich zu bestätigen. </li>
    <li>Verstößt der Kunde gegen seine Pflicht gemäß § 5 Abs. (5) und/oder § 5 Abs. (6) dieser AGB, so ist REMIRA berechtigt, den Vertrag außerordentlich und ohne Einhaltung einer Kündigungsfrist zu kündigen (außerordentliches Sonderkündigungsrecht). </li>
    <li>Das Kündigungsrecht gemäß § 543 Abs. 2 Nr. 1 BGB kann der Kunde nur unter der Voraussetzung geltend machen, dass er REMIRA zuvor schriftlich unter angemessener Fristsetzung zur (Wieder-)Gewährung des Gebrauchs der Mietsache aufgefordert hat und diese Frist fruchtlos verstrichen ist. Diese Voraussetzung entfällt für den Fall, dass REMIRA die Gewährung des Gebrauchs der Mietsache ernsthaft und endgültig verweigert, obwohl REMIRA hierzu verpflichtet ist. </li>
</ol>
<h5>§ 9 Mängel der Mietsache</h5>
<ol>
    <li>Mängelansprüche erstrecken sich nicht auf Leistungen von REMIRA, die der Kunde oder ein Dritter im Auftrag des Kunden ohne Zustimmung von REMIRA ändert. Dies gilt nicht, wenn der Kunde nachweist, dass diese Änderung für den gemeldeten Mangel nicht ursächlich ist oder der Kunde lediglich von REMIRA verfügbar gemachte neue Programmstände installiert. </li>
    <li>Der Kunde ist erst dann zur Mietminderung aufgrund von Mängeln berechtigt, wenn er REMIRA zuvor den Mietmangel bzw. die Mietmängel schriftlich anzeigt, REMIRA eine angemessene Frist zur Abhilfe setzt und diese Frist fruchtlos verstreicht. </li>
</ol>
<h5>§ 10 Internetanbindung, Übergabepunkt </h5>
<ol>
    <li>REMIRA schuldet die Zurverfügungstellung der Software am Übergabepunkt. </li>
    <li>Die Anbindung des Kunden an das Internet ist nicht Gegenstand des Vertrags, sondern obliegt dem Verantwortungsbereich des Kunden. </li>
    <li>Übergabepunkt ist die Datenschnittstelle des Rechenzentrums von REMIRA. </li>
    <li>Die Verantwortlichkeit von REMIRA für die Verfügbarkeit der Software endet am Übergabepunkt.</li>
</ol>
<h5>§ 11 Verfügbarkeit, SLA</h5>
<ol>
    <li>REMIRA stellt dem Kunden die Software auf Servern von REMIRA über eine Internetverbindung zu 99,5 % pro Kalenderjahr zur Verfügung. Die somit zulässige Nicht-Verfügbarkeit der Software in Höhe von 5,0 % pro Kalenderjahr steht nur für ungeplante, nicht vorhersehbare und nicht vorsätzlich oder grob fahrlässig durch REMIRA angesetzte Ausfälle (z.B. Beeinträchtigungen der Erreichbarkeit, Funktionsfähigkeit oder Bedienbarkeit) zur Verfügung. Ist eine vorsätzliche oder fahrlässige Verletzung vertraglicher und/oder gesetzlicher Pflichten von REMIRA zumindest mitursächlich für eine Verfügbarkeitseinschränkung, ist die hieraus resultierende Nicht-Verfügbarkeit der Software unzulässig. </li>
    <li>Ausgenommen von der Verfügbarkeit gemäß vorstehendem Abs. (1) dieses § 11 sind geplante, angekündigte Unterbrechungen aufgrund notwendiger Wartung und Updates, sofern sie aufgrund notwendiger technischer oder dringender sicherheits-relevanter Gründe erfolgen und sofern die Durchführung der jeweiligen Wartung, des Einspielens des jeweiligen Updates oder die Durchführung der jeweiligen Reparatur nicht ausschließlich auf Grund eines Umstandes notwendig ist, der auf einer vorsätzlichen oder fahrlässigen Verletzung vertraglicher oder gesetzlicher Pflichten durch REMIRA beruht.</li>
</ol>
<h5>§ 12 Pflichten des Kunden</h5>
<ol>
    <li>Der Kunde ist verpflichtet,</li>
    <ol type="a">
        <li>die ihm bzw. seinen Nutzern zugeordneten Software- und/oder Systemzugangsdaten, insbesondere Nutzungs- und Zugangsberechtigungen sowie Identifikations- und Authentifizierungs-Sicherungen vor dem Zugriff durch unberechtigte Dritte zu schützen und gibt diese nicht an unberechtigte Nutzer und/oder unberechtigte Dritte weiter, </li>
        <li>den unbefugten Zugriff Dritter auf die Software und/oder das System und auf die Dokumentation durch geeignete Vorkehrungen zu verhindern, </li>
        <li>Original-Datenträger sowie die Sicherungskopien an einem gegen den unberechtigten Zugriff Dritter gesicherten Ort aufzubewahren, </li>
        <li>den anerkannten Grundsätzen der Datensicherung Rechnung zu tragen, </li>
        <li>vor der ersten Benutzung des Systems bzw. der Software nur ausreichend geschulten und über die Folgen einer missbräuchlichen oder fehlerhaften Nutzung der in der Software bzw. dem System bereitgestellten Funktionen aufgeklärte Personen Zugangsdaten zur Software bzw. zum System zur Nutzung zu überlassen und </li>
        <li>die im System bzw. der Software befindlichen, steuer- und/oder handelsrechtlich relevanten, aufbewahrungspflichtigen Informationen und Dokumente (Rechnungen, Gutschriften u.ä.) gesetzeskonform zu speichern und aufzubewahren. </li>
    </ol>
    <li>Der Kunde hat es zu unterlassen, </li>
    <ol type="a">
        <li>die Software ohne Zustimmung von REMIRA im Quell- und/oder Objektcode zu ändern, zu bearbeiten, zu dekompilieren, sofern und soweit er hierzu nicht aufgrund § 69e UrhG oder der ausdrücklichen Zustimmung durch REMIRA berechtigt ist, </li>
        <li>Funktionen oder Prozesse der Software und des Systems vertrags- bzw. bestimmungswidrig zu nutzen, nutzen zu lassen oder eine solche Nutzung zu unterstützen oder zu ermöglichen, </li>
        <li>sich selbst oder Dritten Zugang zu nicht für ihn bestimmte Daten und Informationen zu verschaffen, </li>
        <li>die Software missbräuchlich zu nutzen, insbesondere rechts- oder sittenwidrigen oder solche Inhalte einzustellen oder auf solche Inhalte durch Hyperlink zu verweisen, die gegen Rechte Dritter verstoßen oder rechtswidrig sind (z.B. Verstöße gegen Persönlichkeits-, Urheber-, Marken- oder Wettbewerbsrecht),</li>
        <li>in die Datennetze, Server, Programme und Programmteile oder sonstige Systemkomponenten von REMIRA unbefugt einzudringen oder zu nutzen und </li>
        <li>Urhebervermerke, Seriennummern sowie sonstige der Identifikation von Soft- und/oder Hardware dienende Merkmale zu entfernen und/oder zu verändern und/oder </li>
        <li>die Software und Systeme von REMIRA für den unaufgeforderten Versand von elektronischen Nachrichten oder Informationen an Dritte zu Werbezwecken (sog. Spamming) zu nutzen.</li>
    </ol>
    <li>Der Kunde stellt REMIRA im Bedarfsfalle und auf Nachfrage von REMIRA hin Folgendes zur Verfügung: </li>
    <ol type="a">
        <li>Parameter zur Systemkonfiguration, </li>
        <li>Informationen zu Schnittstellen und Datenformaten, </li>
        <li>die Bereitstellung von Testdaten und </li>
        <li>die Definition von Testfällen.</li>
    </ol>
    <li>Im Falle der Anbindung der Software an Drittsysteme per Schnittstelle stellt der Kunde REMIRA zeitnah und rechtzeitig vorab ein Testsystem und/oder einen Testzugang zu den betreffenden Drittsystemen bereit. </li>
    <li>Um für REMIRA nicht vorhersehbare und gegebenenfalls schädliche Be- und Überlastungen der Software und des Systems zu vermeiden, ist der Kunde nur nach vorheriger Mitteilung an REMIRA und ausdrücklicher Genehmigung durch REMIRA berechtigt, </li>
    <ol type="a">
        <li>Daten in einem Umfang an das System zu übertragen, der über die übliche Nutzung der Software hinausgeht (z.B. zusätzliche Artikel zu Festtagen, Räumungsverkäufe, Austausch des Gesamtsortiments, erhebliche Erweiterung des Sortiments etc.), </li>
        <li>Daten redundant an das System zu übertragen und/oder </li>
        <li>nicht-schnittstellenkonforme Daten an das System zu übertragen. Ob an das System zu übertragende Daten schnittstellenkonform sind, prüft der Kunde in jedem Fall vorab durch gewissenhafte Tests bei der Integration seiner Drittsysteme in das System sowie vorheriger Validierung der zu übertragenden Daten.</li>
    </ol>
    <li>Der Kunde informiert REMIRA über einen übermäßigen oder für REMIRA unvorhersehbaren Anstieg der Last für die Software und/oder das System, z.B. hervorgerufen durch Dateneingang oder Nutzung des Frontends, unverzüglich. Ein übermäßiger Lastanstieg kann u.a. durch Werbemaßnahmen (auch prominentere Darstellung von Produkten), Sonderaktionen auf Vertriebsplattformen oder sonstigen Vertriebskanälen, größere Produktwechsel, Import von Massen- und/oder Altdaten hervorgerufen werden. </li>
    <li>Verstößt der Kunde gegen eine Pflicht gemäß dieses § 12 Abs. (2), (5) und/oder (6), so ist REMIRA berechtigt, den Kunden zur Einhaltung aufzufordern und ihm hierzu eine angemessene Frist zu setzen. Verstreicht diese Frist fruchtlos, so ist REMIRA berechtigt, die vertragsgemäße Leistungserbringung bzw. den Zugang zur Software und dem System bis zur Erfüllung der Mitwirkungs- oder Nebenpflicht durch den Kunden ganz oder teilweise zu sperren oder einzuschränken. Eine solche Sperrung oder Einschränkung berechtigt den Kunden nicht zur Zurückbehaltung der monatlichen Grundgebühren und Mindesttransaktionsgebühren. </li>
    <li>Erzeugt der Kunde auf dem System Daten oder hält er auf dem System Daten, die die Verletzung einer Pflicht gemäß dieses § 12 bedingen oder deren Besitz und/oder deren Verarbeitung gegen geltendes Recht verstößt, so ist REMIRA berechtigt, die betreffenden Daten sofort und ersatzlos zu löschen. </li>
</ol>
<h5>§ 13 Datenverwendung und -löschung </h5>
<ol>
    <li>Mit Beendigung des Vertragsverhältnisses ist REMIRA berechtigt, sämtliche vom Kunden in die Software und/oder in das System übertragene Daten und Informationen ersatzlos zu löschen.  </li>
    <li>Treffen REMIRA Aufbewahrungspflichten, so ist REMIRA berechtigt, die entsprechenden Daten und Informationen für die Dauer der Aufbewahrungspflicht zu speichern und zu verarbeiten. Mit Ablauf der Dauer der Aufbewahrungspflicht löscht REMIRA die betreffenden Daten und Informationen insgesamt. Ein Anspruch des Kunden auf Export dieser Daten vor Löschung besteht nicht, sofern und soweit die Vertragsparteien einen solchen Export nicht gesondert vereinbaren. </li>
    <li>Übertragungsdaten aus Echtzeitkommunikation werden von REMIRA bei Bedarf zu Zwecken der Fehlererkennung (sog. Debugging) für maximal 36 Kalendermonate erhoben und gespeichert.  </li>
    <li>Der asynchrone Datenaustausch über Standardschnittstellen mit Drittsystemen (z.B. eShop) wird nur bei Bedarf und maximal für 36 Kalendermonate zu Zwecken der Fehlererkennung (sog. Debugging) erhoben und gespeichert. </li>
</ol>
<h5>§ 14 Support</h5>
<ol>
    <li>Der Kunde ist berechtigt, in folgenden Fällen den Support von REMIRA zu kontaktieren: </li>
    <ol type="a">
        <li>Fragen zur Funktionsweise der Software. </li>
        <li>Fragen zu Änderungen und Konfigurationen der Software. </li>
        <li>Meldung von Störungen, Betriebseinschränkungen und Funktionsbeeinträchtigungen der Software und/oder des Systems. </li>
        <li>Beratung und Unterstützung bei der Bedienung der Software, zu Funktionalitäten der Software und zu Prozessen.</li>
    </ol>
    <li>Jede Anfrage oder Meldung an den Support von REMIRA ist ein Support-Incident. </li>
    <li>Die Bearbeitung von Support-Incidents erfolgt ausschließlich in deutscher Sprache. </li>
    <li>Der Support steht werktäglich von 9:00 Uhr bis 17:00 Uhr zur Verfügung („Supportzeiten“). Die Annahme und Bearbeitung von Incidents erfolgt ausschließlich innerhalb der Supportzeiten. </li>
    <li>Der Kunde kann Support-Anfragen über das in der Software bereitgestellte, elektronische Formular an REMIRA versenden. </li>
    <li>REMIRA informiert den Kunden über die Erledigung der Support-Incidents. Dies kann auch in Form einer automatisch generierten E-Mail aus dem System erfolgen. </li>
    <li>Ausschließlich Key-User sind berechtigt, den Support von REMIRA zu kontaktieren. Der Kunde ist berechtigt, bis zu drei Key-User zeitgleich zu benennen. Key-User sind die vom Kunden mitgeteilten Anfrageberechtigten aus der Sphäre des Kunden. Der Kunde übergibt REMIRA nach Vertragsschluss eine Liste der Key-User, die REMIRA in die Lage versetzt, Key-User als solche zu identifizieren.  </li>
    <li>Der Kunde ist jederzeit berechtigt, die Liste der von ihm bestimmten Key-User zu aktualisieren. Eine vom Kunden übermittelte, aktualisierte Liste wird mit Beginn des Kalendermonats für REMIRA verbindlich, der auf den Kalendermonat folgt, in dessen Verlauf die aktualisierte Liste vom Kunden vorgelegt wurde. </li>
    <li>REMIRA kann dem Kunden zur Meldung besonders schwerwiegender Störungen, Betriebseinschränkungen und Funktionsbeeinträchtigungen der Software und/oder des Systems eine Notfalltelefonnummer mitteilen, ist hierzu jedoch nicht verpflichtet. Eine zur Verfügung gestellte Notfalltelefonnummer darf ausschließlich außerhalb der Supportzeiten vom Kunden angerufen werden. Nutzt der Kunde eine ihm von REMIRA zur Verfügung gestellte Notfallnummer zur Meldung von Störungen, Betriebseinschränkungen und Funktionsbeeinträchtigungen der Software und/oder des Systems, die REMIRA nicht im Rahmen der Gewährleistung zu beseitigen hat, so ist REMIRA berechtigt, je eingegangenem Anruf eine pauschale Bearbeitungsgebühr in Höhe von EUR 250,- zu verlangen.  </li>
    <li>REMIRA rechnet den Preis für die Bearbeitung von Support-Incidents aufwandsbezogen und in einer Taktung von 20 (zwanzig) Minuten ab, sofern vertraglich nicht anders vereinbart. </li>
    <li>REMIRA ist berechtigt, auf Kundenwunsch außerhalb der Supportzeiten bearbeitete Incidents mit dem Faktor 2,5 zu berechnen. </li>
</ol>
<h5>§ 15 Ansprüche des Kunden bei Rechtsmängeln</h5>
<ol>
    <li>Sind Leistungen von REMIRA rechtsmangelhaft und macht ein Dritter vor diesem Hintergrund gegenüber dem Kunden begründete und durchsetzbare Ansprüche wegen dieser Rechtsmangelhaftigkeit geltend, so  </li>
    <ol type="a">
        <li>kann REMIRA nach seiner Wahl und auf seine Kosten entweder die betreffenden Leistungen so ändern oder ersetzen, dass die Rechtsmangelhaftigkeit beseitigt wird, aber im Wesentlichen doch den vereinbarten Funktions- und Leistungsmerkmalen in für den Kunden zumutbarer Weise entsprechen oder  </li>
        <li>den Kunden von Ansprüchen gegenüber dem Schutzrechtsinhaber freistellen oder Rechte von Dritten erwerben, die notwendig sind, damit der Kunde die vertragsgemäßen Leistungen rechtsmangelfrei sowie vertrags- und bestimmungsgemäß nutzen kann und diese Rechte dem Kunden einräumen oder </li>
        <li>ist REMIRA berechtigt, die betroffenen Leistungen gegen Erstattung der entrichteten Vergütung zurückzunehmen, wobei REMIRA verpflichtet ist, dem Kunden dabei eine angemessene Auslauffrist zu gewähren, es sei denn, dies ist nur zu unzumutbaren rechtlichen oder sonstigen Bedingungen möglich.</li>
    </ol>
    <li>Der Kunde informiert REMIRA unverzüglich über eine Inanspruchnahme durch einen Dritten aufgrund einer geltend gemachten Schutzrechtsverletzung aufgrund Leistungen von REMIRA. Der Kunde ist verpflichtet es zu unterlassen, Anerkenntnisse und/oder Vergleiche über solche von Dritten geltend gemachten Ansprüche ohne Zustimmung oder Genehmigung von REMIRA zu erklären oder zu schließen. Ferner überlässt der Kunde REMIRA die Rechtsverteidigung gegen solche Ansprüche und die Wahl und Geltendmachung von Abwehrmaßnahmen, soweit nicht dem Kunden aus Rechtsgründen die Abwehrmaßnahmen und/oder die Rechtsverteidigung vorbehalten bleiben müssen. Im Falle der Rechtsverteidigung durch REMIRA unterstützt der Kunde REMIRA hierbei, soweit ihm dies zumutbar und die dahingehenden Aufwände nicht unverhältnismäßig sind. Im Falle der Rechtsverteidigung durch den Kunden hat dieser Anspruch auf einen Vorschuss in Höhe der geschätzten, notwendigen Verteidigungskosten.  </li>
    <li>Verletzt der Kunde eine Pflicht gemäß der Regelung in diesem § 15 Abs. (2), so erlöschen Freistellungsansprüche gemäß vorstehendem Abs. (1) dieses § 15 mit sofortiger Wirkung und rückwirkend. </li>
</ol>
<h5>§ 16 Haftung, Schadensersatz</h5>
<ol>
    <li>REMIRA haftet unbeschränkt bei Vorsatz, grober Fahrlässigkeit, für die Verletzung von Leben, Leib oder Gesundheit, nach den Vorschriften des Produkthaftungsgesetzes sowie im Umfang einer von REMIRA übernommenen Garantie. </li>
    <li>Verletzt REMIRA eine wesentliche Pflicht fahrlässig, so ist die Haftung von REMIRA der Höhe nach begrenzt auf den Ersatz des bei Vertragsschluss vertragstypischen und für REMIRA vorhersehbaren Schadens. Wesentliche Pflichten sind solche, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht, deren Verletzung die Erreichung des Vertragszwecks gefährdet und auf deren Einhaltung der Kunde regelmäßig vertraut. </li>
    <li>Die verschuldensunabhängige Haftung von REMIRA für Mängel, die bei Vertragsschluss bereits vorliegen (§ 536 a BGB), ist ausgeschlossen.  </li>
    <li>Sofern und soweit die Anfertigung von Datensicherungen keine Leistung ist, die REMIRA ausdrücklich übernommen hat, haftet REMIRA für den Verlust oder die Beschädigung von Daten und Programmen und deren Wiederherstellung nur insoweit, als dieser Verlust nicht durch angemessene Vorsorgemaßnahmen, insbesondere die tägliche Anfertigung von Sicherungskopien aller Daten und Programme, vermeidbar gewesen wäre. </li>
    <li>Im Übrigen ist jegliche Schadensersatzhaftung von REMIRA, gleich aus welchem Rechtsgrund, ausgeschlossen. </li>
    <li>Die vorstehende Haftungsbeschränkung gilt auch für die persönliche Haftung der Mitarbeiter, Organe und Vertreter von REMIRA. </li>
</ol>
<h5>§ 17 Mindestlohngesetz</h5>
<ol>
    <li>REMIRA verpflichtet sich und seine Subunternehmer zur Einhaltung der Vorschriften des Mindestlohngesetzes. </li>
    <li>REMIRA stellt den Kunden von sämtlichen Ansprüchen Dritter frei, die diesen gegenüber dem Kunden aufgrund eines Verstoßes von REMIRA gegen die Vorschriften des Mindestlohngesetzes zustehen. Die Regelungen in § 15 Abs. (2) und § 15 Abs. (3) dieser AGB gelten für diesen Freistellungsanspruch entsprechend. </li>
</ol>
<h5>§ 18 Schlussbestimmungen</h5>
<ol>
    <li>Das Vertragsverhältnis unterliegt ausschließlich dem Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts (CISG United Nations Convention on Contracts for International Sale of Goods vom 11.04.1980). </li>
    <li>Ausschließlicher Gerichtsstand für alle Streitigkeiten aus oder im Zusammenhang mit dem Vertragsverhältnis ist der Hauptgeschäftssitz von REMIRA (Germany). REMIRA hat auch das Recht, den Kunden an dessen Sitz zu verklagen. </li>
    <li>Vertragssprache ist Deutsch. Bei paralleler Verwendung anderer Sprachen und Widersprüchlichkeiten zwischen verschiedenen Sprachfassungen ist der deutsche Wortlaut der betreffenden Regelungen entscheidend. </li>
    <li>Sollte eine Bestimmung dieser AGB unwirksam sein oder werden, so bleiben alle übrigen Bestimmungen hiervon unberührt. </li>
</ol>
`;

export default privacyPolicyHtml_de;