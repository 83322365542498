import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row, Card, CardHeader, CardBody } from 'reactstrap';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';

const SequentialTestLogValueCell = (props) => {
    return <td className="text-right">{props.dataItem[props.field]}</td>
}

export default function SequentialTestLog(props) {
    const intl = useIntl();
    const [testLogGridData, setTestLogGridData] = useState([]);
    const [testLogGridColumns, setTestLogGridColumns] = useState([]);
    const [testLogValueNameKeys, setTestLogValueNameKeys] = useState(null);
    const userProfile = useSelector(state => state.profile.profile);

    useEffect(() => {
        buildTestLogValueNameKeysArray();
        buildTestLogGridData();
    }, [props.pureStaseqReporting, userProfile.userLanguage]);

    useEffect(() => {
        buildTestLogGridData();
    }, [testLogValueNameKeys]);

    useEffect(() => {
        buildTestLogGridColumns();
    }, [testLogGridData]);

    const buildTestLogGridData = () => {
        if (props.pureStaseqReporting?.testLogOfSequenzialTest && testLogValueNameKeys) {
            let ind = 0;
            let gridData = [];

            let data = { id: 'test-log-' + ind, description: intl.formatMessage({ id: testLogValueNameKeys.recordingRun }) };
            let index = 1;
            props.pureStaseqReporting.testLogOfSequenzialTest.forEach(log => { data = { ...data, ['value' + index]: log.recordingRun }; index++; });
            gridData.push(data);
            ind++;

            data = { id: 'test-log-' + ind, description: intl.formatMessage({ id: testLogValueNameKeys.samplesDrawn }) };
            index = 1;
            props.pureStaseqReporting.testLogOfSequenzialTest.forEach(log => { data = { ...data, ['value' + index]: log.samplesDrawn }; index++; });
            gridData.push(data);
            ind++;

            data = { id: 'test-log-' + ind, description: intl.formatMessage({ id: testLogValueNameKeys.samplesDrawnAccumulated }) };
            index = 1;
            props.pureStaseqReporting.testLogOfSequenzialTest.forEach(log => { data = { ...data, ['value' + index]: log.samplesDrawnAccumulated }; index++; });
            gridData.push(data);
            ind++;

            data = { id: 'test-log-' + ind, description: intl.formatMessage({ id: testLogValueNameKeys.acceptanceThreshold }) };
            index = 1;
            props.pureStaseqReporting.testLogOfSequenzialTest.forEach(log => { data = { ...data, ['value' + index]: log.acceptanceThreshold }; index++; });
            gridData.push(data);
            ind++;

            data = { id: 'test-log-' + ind, description: intl.formatMessage({ id: testLogValueNameKeys.rejectionThreshold }) };
            index = 1;
            props.pureStaseqReporting.testLogOfSequenzialTest.forEach(log => { data = { ...data, ['value' + index]: log.rejectionThreshold }; index++; });
            gridData.push(data);
            ind++;

            data = { id: 'test-log-' + ind, description: intl.formatMessage({ id: testLogValueNameKeys.errors }) };
            index = 1;
            props.pureStaseqReporting.testLogOfSequenzialTest.forEach(log => { data = { ...data, ['value' + index]: log.errors }; index++; });
            gridData.push(data);
            ind++;

            data = { id: 'test-log-' + ind, description: intl.formatMessage({ id: testLogValueNameKeys.errorsAccumulated }) };
            index = 1;
            props.pureStaseqReporting.testLogOfSequenzialTest.forEach(log => { data = { ...data, ['value' + index]: log.errorsAccumulated }; index++; });
            gridData.push(data);
            ind++;

            setTestLogGridData(gridData);
        }
    }

    const buildTestLogGridColumns = () => {
        if (props.pureStaseqReporting?.testLogOfSequenzialTest?.length > 0) {
            let cols = [
                <Column key={'test-col-0'} field={'description'}
                    title={intl.formatMessage({ id: "Reporting.SequentialTest.Description" })}
                    width="300px"
                />
            ];

            let index = 1;
            props.pureStaseqReporting.testLogOfSequenzialTest.forEach((_item) => {
                cols.push(<Column key={'test-col-' + index} field={'value' + index} title={'Test ' + index}
                    width="80px"
                    cell={SequentialTestLogValueCell}
                />);
                index++;
            });
            setTestLogGridColumns(cols);
        }
    }

    const buildTestLogValueNameKeysArray = () => {
        if (props.pureStaseqReporting?.protocolOfResult) {
            let nameKeys = {
                recordingRun: props.pureStaseqReporting?.protocolOfResult?.textKeyRecordingRun,
                samplesDrawn: props.pureStaseqReporting?.protocolOfResult?.textKeySamplesDrawn,
                samplesDrawnAccumulated: props.pureStaseqReporting?.protocolOfResult?.textKeySamplesDrawnAccumulated,
                rejectionThreshold: props.pureStaseqReporting?.protocolOfResult?.textKeyRejectionThreshold,
                acceptanceThreshold: props.pureStaseqReporting?.protocolOfResult?.textKeyAcceptanceThreshold,
                errors: props.pureStaseqReporting?.protocolOfResult?.textKeyErrors,
                errorsAccumulated: props.pureStaseqReporting?.protocolOfResult?.textKeyErrorsAccumulated
            };
            setTestLogValueNameKeys(nameKeys);
        }
    }

    return (
        <Card id="pop-tile">
            <CardHeader>
                <h3>
                    <FormattedMessage id='Reporting.SequentialTest.Log.Title' />
                </h3>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col xs="12">
                        <Grid data={testLogGridData}
                            key="id">
                            <GridNoRecords>
                                {<FormattedMessage id="Reporting.SequentialTest.EmptySequentialTestLogs" />}
                            </GridNoRecords>
                            {testLogGridColumns}
                        </Grid>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}