import * as React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useIntl } from 'react-intl';
import { UserContext } from "../../UserContext";

export const DropDownCell = (props) => {
    const intl = useIntl();
    const userContext = React.useContext(UserContext);
    const handleChange = (e) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value,
            });
        }
    };

    const { dataItem } = props;
    const field = props.field || "";
    
    const getCanChangeRole = () => {
        if (userContext.userGlobal && userContext.userGlobal.userInfo && dataItem.email === userContext.userGlobal.userInfo.userName)
            return false;

        let canChangeRoles = dataItem.users.some((item) => item.id !== dataItem.id && item.isAdmin) ? true : false;
        return canChangeRoles;
    };

    const ddlValue = dataItem[field] != null ? dataItem.dataSource.find((c) => c.value === dataItem[field][0]?.id) : null;

    const userRolesString = dataItem?.userRoles?.length > 0
        ? dataItem.userRoles.map(role => intl.formatMessage({ id: 'Role.' + role.name.toLowerCase() })).join(', ')
        : '';

    return (
        <td>
            {dataItem.inEdit && getCanChangeRole() ? (
                <DropDownList
                    onChange={handleChange}
                    value={ddlValue}
                    data={dataItem.dataSource}
                    textField="text"
                />
            ) : <>{ userRolesString }</>
            }
        </td>
    );
};
