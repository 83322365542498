import React, { useEffect, useCallback } from 'react';
import InterfaceConfigurationFieldMappings from "./InterfaceConfigurationFieldMappings";
import InterfaceConfigurationParameters from './InterfaceConfigurationParameters';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { useLoading } from '../../Shared/LoadingContext';
import { setInterfaceConfigurationDetails } from '../../../services/interfaceConfigurationsService';
import { ConfigContext } from './ConfigContext';
import { deepClone } from '../../../utils/jsonUtils';

const ConfigInventoryImport = (props) => {
    const { setLoading } = useLoading();
    const [isParamChanged, setIsParamChanged] = React.useState(true);
    const [isParamValid, setIsParamValid] = React.useState(true);
    const [isMappingsChanged, setIsMappingsChanged] = React.useState(true);
    const [isMappingValid, setIsMappingValid] = React.useState(true);

    const [interfaceConfigurationParameters, setInterfaceConfigurationParameters] = React.useState({});
    const [interfaceConfigurationFieldMappings, setInterfaceConfigurationFieldMappings] = React.useState({});

    const [doReset, setDoReset] = React.useState(0);
    const configContext = React.useContext(ConfigContext);

    useEffect(() => {
        props.data.setCurrentTabHasChanges(isParamChanged || isMappingsChanged);
    }, [isParamChanged, isMappingsChanged]);

    const handleSave = useCallback(() => {
        doHandleSave();
    }, [interfaceConfigurationFieldMappings, interfaceConfigurationParameters, props.data.loadData, props.data.handleError]);

    const doHandleSave = () => {
        let mappings = deepClone(interfaceConfigurationFieldMappings);
        
        let icp = {
            ...interfaceConfigurationParameters,
            interfaceConfigurationId: configContext.configGlobal.interfaceConfiguration.interfaceConfigurationId,
            interfaceConfigurationFieldMappings: mappings
        }
        setLoading(true);
        setInterfaceConfigurationDetails(
            icp,            
            _ => {
                setLoading(false);
                props.data.loadData();
            },
            props.data.handleError);
    }

    const handleReset = useCallback(() => {
        setDoReset(doReset + 1);
    }, [doReset]);

    const goToConfigList = useCallback((_dataItem) => {
        if (props.data?.doBack) {
            props.data.doBack();
        }
    }, [props.data]);

    return <div>
        <InterfaceConfigurationParameters params={{
            ...props.data,
            setIsParamChanged: setIsParamChanged,
            setIsParamValid: setIsParamValid,
            setInterfaceConfigurationParameters: setInterfaceConfigurationParameters,
            doReset: doReset
        }} />
        <InterfaceConfigurationFieldMappings params={{
            ...props.data,
            setIsMappingsChanged: setIsMappingsChanged,
            setIsMappingValid: setIsMappingValid,
            setInterfaceConfigurationFieldMappings: setInterfaceConfigurationFieldMappings,
            doReset: doReset
        }} />
        <span className="k-form-separator"></span>
        <div className="float-right">
            <Button
                color="secondary"
                onClick={goToConfigList}
            >
                <FormattedMessage id='InterfaceConfiguration.BackToOverview' />
            </Button>
            <Button
                disabled={!(isParamChanged || isMappingsChanged)}
                color="secondary"
                onClick={handleReset}
            >
                <FormattedMessage id='Control.Reset' />
            </Button>
            <Button
                disabled={!((isParamValid && isMappingValid) && (isParamChanged || isMappingsChanged))}
                color="primary"
                onClick={handleSave}
            >
                <FormattedMessage id='Control.Save' />
            </Button>
        </div>
    </div>
}

export default ConfigInventoryImport

