import { get, post } from './serviceBase';

const baseUrl = '/api/v1.0/ProfileManagement';

export function getUserProfile(userSubject, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('userSubject', userSubject);

    const url = `${baseUrl}/getuserprofile?` + params;
    get(url, successCallback, errorCallback);
}

export function setUserTheme(userSubject, theme, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('userSubject', userSubject);
    params.append('theme', theme);
    const url = `${baseUrl}/setusertheme?` + params;
    post(url, theme, successCallback, errorCallback);
}

export function setUserLanguage(userSubject, language, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('userSubject', userSubject);
    params.append('language', language);
    const url = `${baseUrl}/setuserlanguage?` + params;
    post(url, language, successCallback, errorCallback);
}
