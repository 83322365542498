import * as React from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
    Grid,
    GridNoRecords,
    GridColumn as Column,
} from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { Col, Row, Button, Card, CardBody, Input } from 'reactstrap';
import validator from 'validator';
import UserManagementActionCell from "./UserManagementActionCell";
import { addUser, updateUser, enableUser, getUsers, getAvailableUserRoles, deleteUser } from '../../../services/userManagementService';
import ConfirmDialog from '../../Shared/ConfirmDialog';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLoading } from '../../Shared/LoadingContext';
import { useNotification } from '../../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../../Shared/Notifications/Notification';
import { storeCustomer } from '../../../actions/dashboardActions';
import CustomerSelection from '../../Dashboard/CustomerSelection';
import { useExceptionDialog } from '../../Shared/ExceptionDialog/ExceptionDialogProvider';
import { UserContext } from "../../UserContext";
import ReactTooltip from 'react-tooltip';
import { getLocale } from "../../../utils/localization";
import GridFooter from "../../Shared/GridFooter";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'
import { DropDownCell } from "./DropDownCell";


const editField = "inEdit";

const UserManagement = () => {
    const intl = useIntl();
    const { setLoading } = useLoading();
    const dispatchNotification = useNotification();
    const userProfile = useSelector(state => state.profile.profile);
    const locale = getLocale(userProfile.userLanguage);
    const currentCustomer = useSelector(state => state.dashboard.customer);
    const dispatch = useDispatch();

    const [isConfirmDialogVisible, setIsConfirmDialogVisible] = React.useState(false);
    const [confirmDialogTitle, setConfirmDialogTitle] = React.useState('');
    const [confirmDialogDetail, setConfirmDialogDetail] = React.useState('');
    const [confirmDialogOkText, setConfirmDialogOkText] = React.useState('');
    const [confirmDialogCallback, setConfirmDialogCallback] = React.useState(null);
    const [initialItem, setInitialItem] = React.useState({});
    const [disable, setDisable] = React.useState(false);
    const [dataState, setDataState] = React.useState({});
    const pageId = 'UserManagement';
    const [filteredUsers, setFilteredUsers] = React.useState([]);
    const [gridData, setGridData] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [itemsPerPage, setItemsPerPage] = React.useState(10);
    const [deleteConfVisibile, setDeleteConfVisibile] = React.useState(false);
    const [itemForDelete, setItemForDelete] = React.useState({});

    const [currentFilterLastName, setCurrentFilterLastName] = React.useState("");
    const [currentFilterFirstName, setCurrentFilterFirstName] = React.useState("");
    const [currentFilterEmail, setCurrentFilterEmail] = React.useState("");
    const [currentFilterUserRole, setCurrentFilterUserRole] = React.useState("");
    const [isPagingVisible, setIsPagingVisible] = React.useState(false);
    const [userRoles, setUserRoles] = React.useState([]);

    const dispatchExceptionDialog = useExceptionDialog();

    const [editItem, setEditItem] = React.useState({
        id: '',
    });

    const userContext = React.useContext(UserContext);

    React.useEffect(() => {
        setTabIndexToHeaderFilters();
        return () => {
            removeNotification();
        };
    }, []);

    const setTabIndexToHeaderFilters = () => {
        let filterHeaders = document.getElementsByClassName("k-filter-row");
        for (const filterHeader of filterHeaders) {
            let k = 0;
            for (const child of filterHeader.children) {
                if (child.hasAttribute("aria-label")) {
                    k++;
                    child.firstChild.firstChild.firstChild.setAttribute("tabindex", k);
                }
            }
        }
    }

    React.useEffect(() => {
        if (!currentCustomer && userContext.userGlobal.userInfo.accessibleCustomers && userContext.userGlobal.userInfo.accessibleCustomers.length > 0) {
            const defaultCustomer = userContext.userGlobal.userInfo.accessibleCustomers.find(x => x.customerId === userContext.userGlobal.userInfo.customerId);
            if (defaultCustomer) {
                dispatch(storeCustomer(defaultCustomer));
            }
        }
    }, [userContext.userGlobal.userInfo]);

    const [data, setData] = React.useState([]);
    React.useEffect(() => {
        if (!currentCustomer) {
            return;
        }

        setLoading(true);
        getUsers(
            currentCustomer.customerId,
            users => {
                setLoading(false);
                users = users.map(user => ({
                    ...user,
                    changed: false,
                    dataSource: userRoles
                }));
                setData(users);
                setDisable(false);
            },
            handleError);
    }, [currentCustomer, locale]);

    React.useEffect(() => {
        let users = data.map(user => ({
            ...user,
            dataSource: userRoles
        }));
        setData(users);
    }, [userRoles]);

    const minGridLength = 5;

    React.useEffect(() => {
        setIsPagingVisible(filteredUsers.length > minGridLength);
        setPageHandler(1);
    }, [filteredUsers, itemsPerPage]);

    React.useEffect(() => {
        applyFilter();
    }, [data, currentFilterLastName, currentFilterFirstName, currentFilterEmail, currentFilterUserRole]);

    const applyFilter = () => {
        let users = data.map(user => ({
            ...user,
            dataSource: userRoles,
            users: data
        }));
        const filteredArray = users.filter(
            (u) => (u.customerId == -1 ||
                (u.firstName.toLowerCase().includes(currentFilterFirstName.toLowerCase()) &&
                    u.lastName.toLowerCase().includes(currentFilterLastName.toLowerCase()) &&
                    u.email.toLowerCase().includes(currentFilterEmail.toLowerCase()) &&
                    getUserRolesString(u.userRoles).toLowerCase().includes(currentFilterUserRole.toLowerCase()))
            )
        );
        setFilteredUsers(filteredArray);
    }

    const getUserRolesString = (roles) => {
        return roles.map(role => intl.formatMessage({ id: 'Role.' + role.name.toLowerCase() })).join(', ')
    }

    React.useEffect(() => {
        setLoading(true);
        getAvailableUserRoles(
            roles => {
                setLoading(false);
                roles = roles.map(role => {
                    return {
                        ...role,
                        text: intl.formatMessage({ id: 'Role.' + role.name.toLowerCase() }),
                        value: role.id
                    }
                });
                setUserRoles(roles);
            },
            handleError);
    }, [locale]);


    React.useEffect(() => {
        const filterWord = document.getElementsByName("filterWordValue");
        if (filterWord.length > 0) {
            filterWord[0].disabled = disable;
        }
        if (disable) {
            setTimeout(() => {
                let inputs = document.getElementsByClassName('k-textbox')
                if (inputs.length > 0) {
                    inputs[0].focus();
                }
            });
        }
    }, [disable]);

    const handleEnableUser = (dataItem, isEnabled) => {
        const title = isEnabled
            ? intl.formatMessage({ id: 'UserManagement.ActivationConf' })
            : intl.formatMessage({ id: 'UserManagement.DeactivationConf' });
        setConfirmDialogTitle(title);
        const detail = isEnabled
            ? intl.formatMessage({ id: 'UserManagement.ActivationDetail' }, { 'UserName': `${dataItem.firstName} ${dataItem.lastName}` })
            : intl.formatMessage({ id: 'UserManagement.DeactivationDetail' }, { 'UserName': `${dataItem.firstName} ${dataItem.lastName}` });
        setConfirmDialogDetail(detail);
        const okText = isEnabled
            ? intl.formatMessage({ id: 'Control.Unlock' })
            : intl.formatMessage({ id: 'Control.Lock' });
        setConfirmDialogOkText(okText);
        setConfirmDialogCallback({
            success: () => onConfirmEnable(dataItem, isEnabled)
        });
        setIsConfirmDialogVisible(true)
    };

    const onConfirmEnable = (dataItem, isEnabled) => {
        setLoading(true);
        enableUser(
            dataItem.id,
            isEnabled,
            () => {
                setLoading(false);
                dataItem.enabled = !dataItem.enabled;
                const newData = data.map((item) =>
                    item.id === dataItem.id ? dataItem : item
                );
                setData(newData);
            },
            handleError);
    };

    const add = (dataItem) => {
        if (!isDataItemValid(dataItem)) {
            return;
        }
        setDisable(false);
        setLoading(true);

        const userRoleIds = dataItem.userRoles ? dataItem.userRoles.map(x => x.id) : [];

        const user = {
            userName: dataItem.email,
            email: dataItem.email,
            firstName: dataItem.firstName,
            lastName: dataItem.lastName,
            customerId: currentCustomer.customerId,
            roleIds: userRoleIds
        };
        addUser(
            user,
            true,
            value => {
                setLoading(false);
                let id = dataItem.id;
                dataItem.id = value.id;
                dataItem.roles = value.role;
                dataItem.enabled = true;
                dataItem.inEdit = false;
                dataItem.customerId = currentCustomer.customerId;
                const newData = data.map((item) =>
                    item.id === id ? dataItem : item
                );
                setData(newData);
            },
            handleError);
    };

    const isDataItemValid = (dataItem) => {
        dispatchNotification({ remove: true, pageId: pageId });
        if (!dataItem.lastName) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: <FormattedMessage id="UserManagement.LastnameReq" />
            });
            return false;
        }
        if (dataItem.lastName.length > 200) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: <FormattedMessage id="UserManagement.LastnameTooLong" />
            });
            return false;
        }

        if (!dataItem.firstName) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: <FormattedMessage id="UserManagement.FirstnameReq" />
            });
            return false;
        }
        if (dataItem.firstName.length > 200) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: <FormattedMessage id="UserManagement.FirstnameTooLong" />
            });
            return false;
        }

        if (!dataItem.email) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: <FormattedMessage id="UserManagement.EmailReq" />
            });
            return false;
        }
        if (dataItem.email.length > 200) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: <FormattedMessage id="UserManagement.EmailTooLong" />
            });
            return false;
        }
        if (!validator.isEmail(dataItem.email)) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: <FormattedMessage id="UserManagement.EmailNotValid" values={{ email: dataItem.email }} />
            });
            return false;
        }
        return true;
    };

    const update = (dataItem) => {
        if (!isDataItemValid(dataItem)) {
            return;
        }
        setDisable(false);
        setLoading(true);
        let sendEmail = initialItem.email !== dataItem.email;
        const user = {
            userName: dataItem.email,
            email: dataItem.email,
            firstName: dataItem.firstName,
            lastName: dataItem.lastName,
            customerId: currentCustomer.customerId,
            roleIds: dataItem.userRoles.map(x => x.id)
        };
        updateUser(
            dataItem.id,
            sendEmail,
            user,
            result => {
                setLoading(false);
                if (result === "UserExists") {
                    dispatchNotification({
                        pageId: pageId,
                        type: NotificationTypes.error,
                        message: <FormattedMessage id="UserManagement.UserEmailAlreadyExists" />
                    });
                }
                else {
                    dataItem.inEdit = false;
                    const newData = data.map((item) =>
                        item.id === dataItem.id ? dataItem : item
                    );
                    setData(newData);
                }
            },
            handleError);
    };

    const discard = () => {
        const newData = [...data];
        newData.splice(0, 1);
        setData(newData);
        setDisable(false);
    };

    const cancel = (dataItem) => {
        const originalItem = editItem;
        const newData = data.map((item) =>
            item.id === originalItem.id ? originalItem : item
        );
        setData(newData);
        setDisable(false);
    };

    const enterEdit = (dataItem) => {
        setEditItem({
            ...dataItem,
            changed: false
        });
        setInitialItem(dataItem);
        setData(
            data.map((item) =>
                item.id === dataItem.id ? { ...item, inEdit: true, changed: false } : item
            )
        );
        setDisable(true);
    };

    const itemChange = (event) => {
        const newData = gridData.map((item) =>
            item.id === event.dataItem.id
                ? { ...item, [event.field || ""]: (event.field == "userRoles" ? [event.value] : event.value), changed: true }
                : item
        );

        setGridData(newData);
    };

    const addNew = () => {
        const newDataItem = generateNewUser();
        newDataItem.inEdit = true;
        newDataItem.selected = true;

        setData([newDataItem, ...data]);
        setCurrentPage(1);
        setDisable(true);
    };

    const generateNewUser = () => {
        return {
            customerId: -1,
            firstName: '',
            lastName: '',
            email: '',
            userRoleSelected: userRoles[0],
            Discontinued: false,
            dataSource: userRoles
        };
    }

    const handleConfirmDialogClose = (isConfirmed) => {
        setIsConfirmDialogVisible(false);
        if (isConfirmed) {
            if (confirmDialogCallback) {
                confirmDialogCallback.success();
            }
        }
    };

    const handleError = (errorMessage, showNotif = true) => {
        setLoading(false);
        if (showNotif) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            });
        }
    };

    const removeNotification = () => {
        dispatchNotification({
            remove: true,
            pageId: pageId
        });
    };

    const handleCustomerChanged = (cust) => {
        dispatch(storeCustomer(cust));
    };

    const filterLastname = (value) => {
        setCurrentPage(1);
        setCurrentFilterLastName(value);
    }

    const filterFirstname = (value) => {
        setCurrentPage(1);
        setCurrentFilterFirstName(value);
    }

    const filterEmail = (value) => {
        setCurrentPage(1);
        setCurrentFilterEmail(value);
    }

    const filterUserRole = (value) => {
        setCurrentPage(1);
        setCurrentFilterUserRole(value);
    }

    const setPageHandler = (page) => {
        if (filteredUsers && page > 0 && (page <= Math.floor((filteredUsers.length - 1) / itemsPerPage) + 1 || filteredUsers.length == 0)) {
            let startIndex = (page - 1) * itemsPerPage;
            let endIndex = (page) * itemsPerPage;
            setGridData(filteredUsers.slice(startIndex, endIndex));
            if (data.length > itemsPerPage) {
                setCurrentPage(page);
            }
            else {
                setCurrentPage(1);
            }
        }
    }

    const handleItemsPerPageChange = (dropDownItem) => {
        setItemsPerPage(dropDownItem.value);
    };

    const remove = (dataItem) => {
        setDeleteConfVisibile(true)
        setItemForDelete(dataItem);
        setDisable(false);
    };

    const handleDeleteConfirmDialogClose = (result) => {
        if (result) {
            onConfirmDelete()
        }
        else {
            onCancelDelete()
        }
    }
    const onCancelDelete = () => {
        setDeleteConfVisibile(false);
    }

    const onConfirmDelete = () => {
        setDeleteConfVisibile(false);
        removeNotification();
        setLoading(true);

        deleteUser(
            itemForDelete.id,
            currentCustomer.customerId,
            items => {
                setLoading(false);
                let newData = data.filter(x => x.id !== itemForDelete.id)
                setData(newData)
            },
            handleError);
    };

    const EditRights = (props) => (
        <UserManagementActionCell
            {...props}
            edit={enterEdit}
            enable={handleEnableUser}
            add={add}
            discard={discard}
            update={update}
            cancel={cancel}
            editField={editField}
            disabled={disable}
            users={data}
            remove={remove}
        />
    );



    return (
        <div className="content">
            <ConfirmDialog
                visible={isConfirmDialogVisible}
                onClose={handleConfirmDialogClose}
                negative={<FormattedMessage id="Control.Cancel" />}
                positive={confirmDialogOkText}
                title={confirmDialogTitle}
                detail={confirmDialogDetail}
            />
            <ConfirmDialog
                visible={deleteConfVisibile}
                onClose={handleDeleteConfirmDialogClose}
                negative={<FormattedMessage id='Control.Cancel' />}
                positive={<FormattedMessage id='Control.Delete' />}
                title={<FormattedMessage id='UserManagement.DeleteUserConfirmationTitle' />}
                detail={intl.formatMessage({ id: "UserManagement.ConfirmDeleteText" }, { 'UserName': itemForDelete.email })}
            />
            <Row>
                <Col xs="12">
                    <h1>
                        <FormattedMessage id='UserManagement.UserManagement' />&nbsp;
                        {userContext.userGlobal.userInfo && (
                            <CustomerSelection
                                customer={currentCustomer}
                                customers={userContext.userGlobal.userInfo.accessibleCustomers}
                                isStatcontrolAdmin={userContext.userGlobal.userInfo.isStatcontrolAdmin}
                                onCustomerChanged={handleCustomerChanged}
                            />
                        )}
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <Row className="filter-row">
                                <table width="100%">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <Input className="grid-filter" name="filterLastName" placeholder={intl.formatMessage({ id: "UserManagement.Lastname" })} onChange={(event) => {
                                                    filterLastname(event.target.value);
                                                }} />
                                            </td>
                                            <td>
                                                <Input className="grid-filter" name="filterFirstName" placeholder={intl.formatMessage({ id: "UserManagement.Firstname" })} onChange={(event) => {
                                                    filterFirstname(event.target.value);
                                                }} />
                                            </td>
                                            <td>
                                                <Input className="grid-filter" name="filterEmail" placeholder={intl.formatMessage({ id: "UserManagement.Mail" })} onChange={(event) => {
                                                    filterEmail(event.target.value);
                                                }} />
                                            </td>
                                            <td>
                                                <Input className="grid-filter" name="filterUserRole" placeholder={intl.formatMessage({ id: "UserManagement.UserRole" })} onChange={(event) => {
                                                    filterUserRole(event.target.value);
                                                }} />
                                            </td>
                                            <td width="160px">
                                                <div className="float-right">
                                                    <ReactTooltip id="userAddTip" place="top" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />
                                                    <Button color="primary" disabled={disable} onClick={addNew} data-tip={intl.formatMessage({ id: 'UserManagement.AddUser' })} data-for="userAddTip">
                                                        <FontAwesomeIcon icon={faPlus} />
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Row>
                            <Row id="user-grid">
                                <Col xs="12">
                                    <Grid
                                        data={process(gridData, dataState)}
                                        {...dataState}
                                        onDataStateChange={(e) => {
                                            setDataState(e.dataState);
                                        }}
                                        onItemChange={itemChange}
                                        editField={editField}
                                        sortable={true}
                                    >
                                        <GridNoRecords>
                                            <FormattedMessage id="UserManagement.NoUserInfo" />
                                        </GridNoRecords>
                                        <Column field="lastName" title={intl.formatMessage({ id: "UserManagement.Lastname" })} />
                                        <Column field="firstName" title={intl.formatMessage({ id: "UserManagement.Firstname" })} />
                                        <Column field="email" title={intl.formatMessage({ id: "UserManagement.Mail" })} />
                                        <Column field="userRoles" title={intl.formatMessage({ id: "UserManagement.UserRole" })} cell={DropDownCell} />
                                        <Column cell={EditRights} filterable={false} width="160px" />
                                    </Grid>
                                </Col>
                            </Row>
                            {isPagingVisible && (
                                <GridFooter
                                    itemsPerPage={itemsPerPage}
                                    handleItemsPerPageChange={handleItemsPerPageChange}
                                    dataLength={data.length}
                                    setPageHandler={setPageHandler}
                                    currentPage={currentPage}
                                />
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default UserManagement
