import React, { useEffect } from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { UserContext } from '../../UserContext';
import CustomerSelection from '../../Dashboard/CustomerSelection';
import { storeCustomer } from '../../../actions/dashboardActions';
import { addInterfaceConfiguration, deleteInterfaceConfiguration, cloneInterfaceConfiguration, getInterfaceConfigurationsByCustomer, updateInterfaceConfiguration } from '../../../services/interfaceConfigurationsService';
import { useLoading } from '../../Shared/LoadingContext';
import { useNotification } from '../../Shared/Notifications/NotificationProvider';
import { useExceptionDialog } from '../../Shared/ExceptionDialog/ExceptionDialogProvider';
import { NotificationTypes } from '../../Shared/Notifications/Notification';
import EditActionCell from './EditActionCell';
import { process } from '@progress/kendo-data-query';
import { Grid, GridNoRecords, GridColumn as Column } from '@progress/kendo-react-grid';
import GridFooter from '../../Shared/GridFooter';
import ConfirmDialog from '../../Shared/ConfirmDialog';
import ReactTooltip from 'react-tooltip';
import { PopulationsCell } from './PopulationsCell';
import { SwitchCell } from './SwitchCell';
import { DropDownCell } from '../../Shared/DropDownCell';
import { useHistory } from 'react-router-dom';
import { getCustomerSettings } from '../../../services/settingsService';
import { SplitButton } from "@progress/kendo-react-buttons";
import { deepClone } from '../../../utils/jsonUtils';

const editField = "inEdit";

const ConfigList = () => {
    const intl = useIntl();
    const { setLoading } = useLoading();
    const pageId = 'ConfigList';
    const userContext = React.useContext(UserContext);
    const currentCustomer = useSelector(state => state.dashboard.customer);
    const dispatch = useDispatch();
    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const [dataState, setDataState] = React.useState({});
    const [disable, setDisable] = React.useState(false);
    const [customerSettings, setCustomerSettings] = React.useState(null);

    const [data, setData] = React.useState([]);
    const [gridData, setGridData] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [itemsPerPage, setItemsPerPage] = React.useState(10);
    const [isPagingVisible, setIsPagingVisible] = React.useState(false);
    const [deleteConfVisibile, setDeleteConfVisibile] = React.useState(false);
    const [itemForDelete, setItemForDelete] = React.useState({});
    const [addICItems, setAddICItems] = React.useState([]);
    const userProfile = useSelector(state => state.profile.profile);
    
    const minGridLength = 5;
    let history = useHistory();


    React.useEffect(() => {
        if (!currentCustomer && userContext.userGlobal.userInfo.accessibleCustomers && userContext.userGlobal.userInfo.accessibleCustomers.length > 0) {
            const defaultCustomer = userContext.userGlobal.userInfo.accessibleCustomers.find(x => x.customerId === userContext.userGlobal.userInfo.customerId);
            if (defaultCustomer) {
                dispatch(storeCustomer(defaultCustomer));
            }
        }
    }, [userContext.userGlobal.userInfo]);

    const handleCustomerChanged = (cust) => {
        dispatch(storeCustomer(cust));
    };

    const handleError = (errorMessage, showNotif = true) => {
        setLoading(false);
        if (showNotif) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            });
        }
    };

    useEffect(() => {
        if (currentCustomer == null)
            return;

        setLoading(true);

        getCustomerSettings(
            currentCustomer.customerId,
            data => {
                setLoading(false);
                setCustomerSettings(data);
                getInterfaceConfigurations()
            },
            handleError);
    }, [currentCustomer.customerId]);

    const getInterfaceConfigurations = () => {
        setLoading(true);
        getInterfaceConfigurationsByCustomer(
            currentCustomer.customerId,
            data => {
                setLoading(false);
                data = data.map((x) =>
                    alignConfigData(x)
                );
                setData(data);
            },
            handleError);
    }

    const alignConfigData = (item) => {
        return item.initialData
            ? {
                ...item,
                dataSource: item.initialData.interfaceConfigurationFieldMappings
                    .filter(f => f.fId <= window.enumFields.Ident15)
                    .map((m) => {
                        return {
                            value: m.fId,
                            text: m.originalColumnName
                        }
                    })
            }
            : {
                ...item,
                dataSource: []
            }
    }

    const removeNotification = () => {
        dispatchNotification({
            remove: true,
            pageId: pageId
        });
    };

    const itemChange = (event) => {
        const newData = gridData.map((item) =>
            item.interfaceConfigurationId === event.dataItem.interfaceConfigurationId
                ? { ...item, [event.field || ""]: event.value, changed: true }
                : item
        );
        setGridData(newData);
    };

    const enterEdit = (dataItem) => {
        dataItem.changed = false;
        setDisable(true);
        dataItem.inEdit = true;
        setData(
            data.map((item) =>
                item.interfaceConfigurationId === dataItem.interfaceConfigurationId ? { ...item, inEdit: true } : item
            )
        );
    };

    const remove = (dataItem) => {
        if (dataItem.populationInterfaceConfigurations && dataItem.populationInterfaceConfigurations.length > 0) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: intl.formatMessage({ id: "InterfaceConfiguration.CurrentlyTheFollowingPopulations" }) + " " + dataItem.populationInterfaceConfigurations.map(x => x.populationName).join(', ')
            })
        }
        else {
            setDeleteConfVisibile(true)
            setItemForDelete(dataItem);
            setDisable(false);
        }
    }

    const update = (dataItem) => {
        setDisable(false);
        if (!isDataItemValid(dataItem)) {
            return;
        }
        setLoading(true);
        removeNotification();
        updateInterfaceConfiguration(
            dataItem,
            result => {
                if (result === "AlreadyExists") {
                    dispatchNotification({
                        type: NotificationTypes.error,
                        pageId: pageId,
                        message: <FormattedMessage id="InterfaceConfiguration.ConfigurationNameReq" />
                    })
                    return;
                }
                setLoading(false);

                dataItem.inEdit = false;
                let temp = data.map(item => {
                    if (item.interfaceConfigurationId === dataItem.interfaceConfigurationId)
                        return dataItem;
                    else
                        return item;
                })
                setData(temp);
            },
            handleError);
        setDisable(false);
    }

    const isDataItemValid = (dataItem) => {
        removeNotification();
        if (!dataItem.configurationName) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: <FormattedMessage id="InterfaceConfiguration.ConfigurationNameReq" />
            })
            return false;
        }

        if (data.filter(x => x.interfaceConfigurationId !== dataItem.interfaceConfigurationId).some(x => x.configurationName.trim().toLowerCase() === dataItem.configurationName.trim().toLowerCase())) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: <FormattedMessage id="InterfaceConfiguration.ConfigurationNameExists" />
            })
            return false
        }
        return true;
    };

    const add = (newItem) => {
        if (!isDataItemValid(newItem)) {
            return;
        }
        removeNotification();
        setLoading(true);
        newItem.inEdit = false;
        addInterfaceConfiguration(
            newItem,
            result => {
                if (result === "AlreadyExists") {
                    dispatchNotification({
                        type: NotificationTypes.error,
                        pageId: pageId,
                        message: <FormattedMessage id="InterfaceConfiguration.ConfigurationNameReq" />
                    })
                    return;
                }
                let id = newItem.interfaceConfigurationId
                newItem.interfaceConfigurationId = result;

                setDisable(false);
                const newData = data.map((item) =>
                    item.interfaceConfigurationId === id ? newItem : item
                );
                setData(newData);
                setLoading(false);
            },
            handleError);
    }

    const cancel = (dataItem) => {
        const originalItem = data.find(
            (p) => p.interfaceConfigurationId === dataItem.interfaceConfigurationId
        );
        const newData = data.map((item) =>
            item.interfaceConfigurationId === originalItem.interfaceConfigurationId ? { ...originalItem, inEdit: false } : item
        );
        setData(newData);
        setDisable(false);
    }

    React.useEffect(() => {
        setIsPagingVisible(data.length > minGridLength);
        setPageHandler(1);
    }, [data, itemsPerPage]);

    React.useEffect(() => {
        let temp = deepClone(data);
        let items = temp.sort((a, b) => {
            const agrteb = a.configurationName.toLowerCase() > b.configurationName.toLowerCase() ? 1 : 0;
            return a.configurationName.toLowerCase() < b.configurationName.toLowerCase() ? -1 : agrteb;
        });
        let dataSource = items.map(x => {
            return {
                element: x,
                text: intl.formatMessage({ id: 'InterfaceConfiguration.CreateCopyOf' }, { 'ConfigurationName': x.configurationName }),
                iconClass: "fa fa-clone",
            }
        })

        setAddICItems(dataSource);
    }, [data, userProfile.userLanguage]);

    const goToDetails = (dataItem) => {
        history.push({
            pathname: '/administration/config/' + dataItem.interfaceConfigurationId
        });
    }

    const goToLocations = (dataItem) => {
        history.push({
            pathname: '/administration/warehousemanagement'
        });
    }


    const discard = () => {
        const newData = [...data];
        newData.splice(0, 1);
        setData(newData);
        setDisable(false);
    };

    const EditItem = (props) => (
        <EditActionCell
            {...props}
            edit={enterEdit}
            remove={remove}
            update={update}
            add={add}
            cancel={cancel}
            editField={editField}
            disabled={disable}
            discard={discard}
            goToDetails={goToDetails}
            goToLocations={goToLocations}
            idPropName={"interfaceConfigurationId"}
            showDelete={true}
        />
    );

    const setPageHandler = (page) => {
        if (data && page > 0 && page <= Math.floor((data.length - 1) / itemsPerPage) + 1) {
            let startIndex = (page - 1) * itemsPerPage;
            let endIndex = (page) * itemsPerPage;
            setGridData(data.slice(startIndex, endIndex));
            if (data.length > itemsPerPage) {
                setCurrentPage(page);
            }
            else {
                setCurrentPage(1);
            }
        }
        else {
            setGridData(data);
        }
    }

    const handleItemsPerPageChange = (dropDownItem) => {
        setItemsPerPage(dropDownItem.value);
    };

    const handleConfirmDialogClose = (result) => {
        if (result) {
            onConfirmDelete()
        }
        else {
            onCancelDelete()
        }
    }
    const onCancelDelete = () => {
        setDeleteConfVisibile(false);
    }

    const onConfirmDelete = () => {
        setDeleteConfVisibile(false);
        removeNotification();
        setLoading(true);

        deleteInterfaceConfiguration(
            itemForDelete.interfaceConfigurationId,
            () => {
                let temp = data.filter(x => x.interfaceConfigurationId !== itemForDelete.interfaceConfigurationId)
                setData(temp);
                setLoading(false);
            },
            handleError);
    };

    const generateNewItem = () => {
        return {
            interfaceConfigurationId: -1,
            customerId: currentCustomer.customerId,
            configurationName: '',
            configurationType: 1,
            sap: false,
            connectedToErp: false,
            groupByIdentFId: null,
            dataSource: []
        };
    }

    const handleAddICButton = (event) => {
        const newDataItem = generateNewItem();
        newDataItem.inEdit = true;
        newDataItem.selected = true;

        setData([newDataItem, ...data]);
        setCurrentPage(1);
        setDisable(true);
        setTimeout(() => {
            let grids = document.getElementsByClassName('k-grid');
            if (grids.length > 0) {
                let grid = grids[0];
                let gridRows = grid.getElementsByClassName('k-grid-edit-row');
                if (gridRows.length > 0) {
                    let insertedRow = gridRows[0];
                    let inputs = insertedRow.getElementsByClassName('k-textbox')
                    if (inputs.length > 0) {
                        inputs[0].focus();
                    }
                }
            }
        });
    }

    const handleCopy = (event) => {
        setLoading(true);
        cloneInterfaceConfiguration(
            event.item.element.interfaceConfigurationId,
            result => {
                result = alignConfigData(result)
                let temp = [{ ...result, inEdit: true }, ...data]                
                setDisable(true);
                setData(temp);
                setLoading(false);
            },
            handleError);
    };

    return (
        <div className="content">
            <Row>
                <Col xs="12">
                    <h1>
                        <FormattedMessage id='InterfaceConfiguration.Configuration' /> {" "} <small><FormattedMessage id='InterfaceConfiguration.OfImportExportStructures' />&nbsp;</small>
                        {userContext.userGlobal.userInfo && (
                            <CustomerSelection
                                customer={currentCustomer}
                                customers={userContext.userGlobal.userInfo.accessibleCustomers}
                                isStatcontrolAdmin={userContext.userGlobal.userInfo.isStatcontrolAdmin}
                                onCustomerChanged={handleCustomerChanged}
                            />
                        )}
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <Card>
                        <ConfirmDialog
                            visible={deleteConfVisibile}
                            onClose={handleConfirmDialogClose}
                            negative={<FormattedMessage id='Control.Cancel' />}
                            positive={<FormattedMessage id='Control.Delete' />}
                            title={<FormattedMessage id='InterfaceConfiguration.DeleteConfirmationTitle' />}
                            detail={intl.formatMessage({ id: "InterfaceConfiguration.ConfirmDeleteText" }, { 'ConfigurationName': itemForDelete.configurationName })}
                        />
                        <CardHeader>
                            <h3>
                                <FormattedMessage id='InterfaceConfiguration.ConfigList' />
                                <ReactTooltip id="populationAddTip" place="top" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />
                                <div className="float-right mb-3">
                                    <SplitButton color="primary" text={intl.formatMessage({ id: 'InterfaceConfiguration.AddInterfaceConfiguration' })}
                                        disabled={disable}
                                        onButtonClick={() => handleAddICButton()}
                                        onItemClick={(e) => handleCopy(e)}
                                        items={addICItems}
                                        iconClass="fa fa-plus"
                                    >
                                    </SplitButton>
                                </div>
                            </h3>
                        </CardHeader>
                        <CardBody>
                            <Grid data={process(gridData, dataState)}
                                {...dataState}
                                onDataStateChange={(e) => {
                                    setDataState(e.dataState);
                                }}
                                onItemChange={itemChange}
                                editField={editField}
                                sortable={true}
                                key="interfaceConfigurationId"
                                filterable={false}>

                                <GridNoRecords>
                                    <FormattedMessage id="InterfaceConfiguration.NoInterfaceConfiguration" />
                                </GridNoRecords>
                                <Column field="configurationName" title={intl.formatMessage({ id: "InterfaceConfiguration.ConfigurationName" })} />
                                <Column field="configurationName" title={intl.formatMessage({ id: "InterfaceConfiguration.AssignedToPopulation" })} cell={PopulationsCell} />
                                <Column field="sap" title={intl.formatMessage({ id: "InterfaceConfiguration.UseSap" })} cell={SwitchCell} />
                                <Column field="connectedToErp" title={intl.formatMessage({ id: "InterfaceConfiguration.ConnectedToErp" })} cell={SwitchCell} />
                                {customerSettings && customerSettings.enablingArticleGrouping &&
                                    <Column field="groupByIdentFId" title={intl.formatMessage({ id: "InterfaceConfiguration.GroupByMappesIndent" })} cell={DropDownCell} dataSource={[]} />
                                }
                                <Column cell={EditItem} width="220px" filterable={false} />
                            </Grid>
                            {isPagingVisible && (
                                <GridFooter
                                    itemsPerPage={itemsPerPage}
                                    handleItemsPerPageChange={handleItemsPerPageChange}
                                    dataLength={data.length}
                                    setPageHandler={setPageHandler}
                                    currentPage={currentPage}
                                />
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ConfigList