import * as React from 'react';
import { AutoComplete } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';

export const AutoCompleteCell = (props) => {

    const { dataItem } = props;
    const field = props.field || "";
    const dataSourceField = props.dataSourceField || "dataSource";

    const handleChange = (e) => {
        updateAutocomplete(e.target.value);
        if (props.onChange) {
            const newVal = getValueByText(e.target.value);
            if (newVal) {
                props.onChange({
                    dataIndex: 0,
                    dataItem: props.dataItem,
                    field: props.field,
                    syntheticEvent: e.syntheticEvent,
                    value: newVal
                });
            }
        }
    };

    const filterDataSourceByText = (txt) => {
        const data = dataItem[dataSourceField];
        const filter = {
            field: "text",
            operator: "contains",
            value: txt,
            ignoreCase: true
        };
        return txt && data ? filterBy(data, filter) : data;
    };
    
    const getTextByValue = (val) => {
        return dataItem[dataSourceField].find((c) => c.value === val)?.text ?? "";
    };

    const getValueByText = (text) => {
        return dataItem[dataSourceField].find((c) => c.text === text)?.value ?? "";
    };

    const [autocompleteData, setAutocompleteData] = React.useState(filterDataSourceByText(getTextByValue(props.dataItem[field])));
    const [value, setValue] = React.useState(getTextByValue(props.dataItem[field]));

    const updateAutocomplete = (val) => {
        setAutocompleteData(filterDataSourceByText(val));
        setValue(val);
    }

    React.useEffect(() => {
        let val = getTextByValue(dataItem[field]);
        updateAutocomplete(val);
    }, [props.dataItem.inEdit, props.dataItem[field], props.dataItem[dataSourceField]]);

    return (
        <td>
            {dataItem[dataSourceField]?.length > 0 && dataItem.inEdit ? (
                <AutoComplete
                    onChange={handleChange}
                    data={autocompleteData}
                    textField="text"
                    value={value}
                />
            ) : (
                  value
            )}
        </td>
    );
};
