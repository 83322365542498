import ReactDOM from "react-dom";
import React from "react";
import ConfirmDialog from "./ConfirmDialog";
import { trigger } from "../../actions/events";


export const UserLeaveConfirmationEvents = {
    RouteChanged: "UserLeaveConfirmation:RouteChanged"
}

const UserLeaveConfirmation = (message, callback, confirmOpen, setConfirmOpen) => {
    const container = document.createElement("div");
    container.setAttribute("custom-confirmation-navigation", "");
    document.body.appendChild(container);

    const handleConfirm = (callbackState) => {
        ReactDOM.unmountComponentAtNode(container);
        document.body.removeChild(container);
        callback(callbackState);
        setConfirmOpen(false);
        if (confirmFunc) {
            trigger(UserLeaveConfirmationEvents.RouteChanged, {});
        }
    };
        
    document.body.appendChild(container);

    const { header, content, cancel, confirm, confirmFunc } = JSON.parse(message);

    ReactDOM.render(
        <ConfirmDialog
            visible={confirmOpen}
            onClose={handleConfirm}
            negative={cancel}
            positive={confirm}
            title={header}
            detail={content}
        />,
        container
    );
};

export default UserLeaveConfirmation;