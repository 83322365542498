import {
    STORE_PROFILE,    
    LOADING_PROFILE
  } from '../actions/types'
  
  const initialState = {
    profile: { userLanguage: 1,
                userTheme : 1},
    isLoadingProfie: false
  };
  
export default function profileReducer(state, action) {

    if (!state)
        state = initialState;

    switch (action.type) {
        case STORE_PROFILE:
        return {
          ...state,
            isLoadingProfie: false,
            profile: action.payload
        }
        case LOADING_PROFILE:
        return {
          ...state,
            isLoadingProfie: true
        }      
      default:
        return state
    }
  }