/******************************************
 *  Author : Dr. Hanno Georg   
 *  Created On : Fri Sep 03 2021
 *  File : protectedRoute.js
 *******************************************/

 import React from 'react'
 import { Route, Redirect } from 'react-router-dom'
 import { useSelector } from 'react-redux'
 
function ProtectedRoute({ children, component: Component, ...rest }) {
    const user = useSelector(state => state.auth.user);
    if (!user) {
        sessionStorage.setItem('redirectUrl', rest.path);
    }
    return user
        ? (<Route {...rest} component={Component} />)
        : (<Redirect to={'/login'} />);
}
 
export default ProtectedRoute
