import React, { useState} from "react";
import { Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {
    ExpansionPanel,
    ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Button } from 'reactstrap';
import { Reveal } from "@progress/kendo-react-animation";
import { ExceptionDialogActions } from "./ExceptionDialogProvider"
import { useIntl } from "react-intl";


const ExceptionDialog = props => {
    const [exit, setExit] = useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const intl = useIntl();

    const handleCloseExceptionDialog = () => {
        setExit(true);
        setTimeout(() => {
            props.dispatch({
                type: ExceptionDialogActions.REMOVE_EXCEPTION,
                id: props.id
            })
        }, 10)
        if (props.dismissCallback)
            props.dismissCallback();
    };

    const getMessage = () => {
        let errorMessage = '';
        if (typeof props.message === 'string' || props.message instanceof String) {
            errorMessage = props.message;
        }
        else if (typeof props.message.title === 'string' || props.message.title instanceof String) {
            errorMessage = props.message.title;
            const translatedTitle = intl.formatMessage({ id: 'ExceptionDetails.Title' });
            errorMessage = errorMessage.replace('An error occurred while processing your request.', translatedTitle);
        }
        else if (props.message && props.message.response && props.message.response.data) {
            errorMessage = props.message.response.data.detail;
        }
        return errorMessage;
    };

    const getDetail = () => {
        let errorMessage = '';
        if (typeof props.message.detail === 'string' || props.message.detail instanceof String) {
            errorMessage = props.message.detail;
        }
        else if (typeof props.message.message === 'string' || props.message.message instanceof String) {
            errorMessage = props.message.message;
        }
        return errorMessage;
    };

    return !exit && (
        <div>
            <Dialog title={intl.formatMessage({ id: 'ExceptionDetails.ExceptionDetails' })} className='exception-dialog'>
                <p>
                    {getMessage()}
                </p>
                {getDetail() !== "" &&
                    <ExpansionPanel
                        title={intl.formatMessage({ id: 'ExceptionDetails.Details' })}
                        expandIcon="fa fa-angle-down"
                        collapseIcon="fa fa-angle-up"
                        expanded={expanded}
                        onAction={(e) => setExpanded(!e.expanded)}
                    >
                        <Reveal>
                            {expanded && (
                                <ExpansionPanelContent>
                                    {getDetail()}
                                </ExpansionPanelContent>
                            )}
                        </Reveal>
                    </ExpansionPanel>
                }
                <span className="k-form-separator"></span>
                <DialogActionsBar>
                    <div >
                        <Button type={"submit"} onClick={handleCloseExceptionDialog} color="danger" className="btn btn-danger float-right">
                            {intl.formatMessage({ id: 'Control.Close' })}
                        </Button>
                    </div>
                </DialogActionsBar>
                
            </Dialog>
        </div>
    )
};

export default ExceptionDialog;
