import { NotificationGroup } from '@progress/kendo-react-notification';
import React, { useState } from 'react';
import { Button, Alert,  Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneLaptop, faTimes } from '@fortawesome/pro-light-svg-icons'

function DeviceAlert() {
    const [visible, setVisible] = useState(true);
    const onDismiss = () => setVisible(false);
    return (
        <div>
        { visible &&
        <NotificationGroup className="device-alert">
            <Alert color="info" isOpen={visible} className="device-alert">
                <Row>
                    <Col xs="3" className="alert-icon align-items-center"><FontAwesomeIcon icon={faPhoneLaptop} /></Col>
                    <Col xs="7">
                        <p>
                            This application isn't optimized for mobile devices. For a better user experience switch to a larger device (e.g. Tablet in landscape mode).
                        </p>
                    </Col>
                    <Col xs="1" className="alert-icon">
                        <Button
                            color="link"
                            onClick={onDismiss}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                    </Col>
                </Row>
            </Alert>
                </NotificationGroup>}
        </div>
    )
}

export default DeviceAlert