import axios from 'axios';
import { get, getFile, getWithPopulationIdHeader, postWithPopulationIdHeader, getFileFromReportItemListResponse } from './serviceBase';

const baseUrl = '/api/v1.0/inventory/Inventory';
const baseUrlStatus = '/api/v1.0/Status';

export const getImportInProgressBackgroundTaskStatuses = () => [
    window.enumBackgroundTaskStatus.DataImportInProgress,
    window.enumBackgroundTaskStatus.DataImportSucceeded,
    window.enumBackgroundTaskStatus.LorenzCurveInProgress,
    window.enumBackgroundTaskStatus.CreateCountListInProgress,
    window.enumBackgroundTaskStatus.ReportGenerationInProgress,
    window.enumBackgroundTaskStatus.StratificationInProgress,
    window.enumBackgroundTaskStatus.StructureAnalysisInProgress
]

export const getImportCountQuantitiesInProgressBackgroundTaskStatuses = () => [
    window.enumBackgroundTaskStatus.ImportCountQuantitiesStarted
]

export const getPostAnalysisReportGenerationInProgressProgramLockStates = () => [
    window.enumBackgroundTaskStatus.ImportCountQuantitiesSucceeded,
    window.enumBackgroundTaskStatus.PostAnalysisReportGenerationStarted
]

export async function uploadInventoryFileChunk(chunk, counter, fileName, populationId) {
    return axios.post(`${baseUrl}/UploadInventoryFileChunk`, chunk, {
        params: {
            id: counter,
            fileName: fileName,
        },
        headers: { 'Content-Type': 'application/json', 'X-Remira-PopulationId': populationId }
    });
}

export async function finalizeInventoryFileChunkUpload(fileName, customerId, populationId) {
    let formData = new FormData();
    formData.append('fileName', fileName);
    return axios.post(`${baseUrl}/UploadComplete`, {}, {
        params: {
            fileName: fileName,
            customerId: customerId,
            populationId: populationId
        },
        data: formData,
        headers: { 'X-Remira-PopulationId': populationId }
    });
}

export async function importUploadedFileData(fileName, originalFileName, columnMapping, populationId) {
    let config = {
        params: {
            fileName: fileName,
            originalFileName: originalFileName
        },
        headers: {
            'Content-Type': 'application/json'
        }
    }

    if (populationId && populationId.length > 0) {
        config.headers = { ...config.headers, 'X-Remira-PopulationId': populationId };
    }
    return axios.post(`${baseUrl}/ImportUploadedData`, columnMapping, config);
}

export async function updateImportUploadedFileData(fileName, originalFileName, columnMapping, populationId) {
    let config = {
        params: {
            fileName: fileName,
            originalFileName: originalFileName
        },
        headers: {
            'Content-Type': 'application/json'
        }
    }

    if (populationId && populationId.length > 0) {
        config.headers = { ...config.headers, 'X-Remira-PopulationId': populationId };
    }
    return axios.post(`${baseUrl}/UpdateImportUploadedData`, columnMapping, config);
}

export function getProgramLockState(populationId, successCallback, errorCallback, cancelToken) {
    getWithPopulationIdHeader(`${baseUrlStatus}/GetProgramLockState`, populationId, successCallback, errorCallback, cancelToken);
}

export function getBackgroundTaskStatus(populationId, successCallback, errorCallback, cancelToken) {
    getWithPopulationIdHeader(`${baseUrlStatus}/GetBackgroundTaskStatus`, populationId, successCallback, errorCallback, cancelToken);
}

export function getAllBackgroundTaskStatuses(customerId, successCallback, errorCallback, cancelToken) {
    const params = new URLSearchParams();
    params.append('customerId', customerId);

    const url = `${baseUrlStatus}/GetAllBackgroundTaskStatuses?` + params;

    get(url, successCallback, errorCallback, cancelToken);
}

export function getInventoryHeaderInfo(populationId, successCallback, errorCallback, cancelToken) {
    getWithPopulationIdHeader(`${baseUrl}/GetInventoryHeaderInfo`, populationId, successCallback, errorCallback, cancelToken);
}

export async function setLastUsedPopulation(populationId, successCallback, errorCallback, cancelToken) {
    return postWithPopulationIdHeader(`${baseUrl}/SetLastUsedPopulation`, populationId, null, successCallback, errorCallback, cancelToken);
}

export function getSamplingStatistics(populationId, successCallback, errorCallback, cancelToken) {
    getWithPopulationIdHeader(`${baseUrl}/GetSamplingStatistics`, populationId, successCallback, errorCallback, cancelToken);
}

export function getReportingStatistics(populationId, successCallback, errorCallback, cancelToken) {
    getWithPopulationIdHeader(`${baseUrl}/GetReportingStatistics`, populationId, successCallback, errorCallback, cancelToken);
}

export function getStaseqReportingStatistics(populationId, successCallback, errorCallback, cancelToken) {
    getWithPopulationIdHeader(`${baseUrl}/GetStaseqReportingStatistics`, populationId, successCallback, errorCallback, cancelToken);
}

export function getPureStaseqReportingStatistics(populationId, successCallback, errorCallback, cancelToken) {
    getWithPopulationIdHeader(`${baseUrl}/GetPureStaseqReportingStatistics`, populationId, successCallback, errorCallback, cancelToken);
}

export async function cancelImportInProgress(populationId, successCallback, errorCallback) {
    return postWithPopulationIdHeader(`${baseUrl}/CancelRunningImport`, populationId, null, successCallback, errorCallback);
}

function loadPdfReportAction(getPdfFunction, resultSetter, errorCallback, populationId) {
    getPdfFunction('',
        (result) => {
            if (resultSetter)
                resultSetter(result)
        },
        errorCallback,
        populationId
    );
}

export function loadPdfReportInfo(populationId, reportType, successCallback, errorCallback, cancelToken) {
    switch (reportType) {
        case window.enumReportType.Countlist:
            getCountlistPdfInfo(populationId,
                (result) => {
                    let file = getFileFromReportItemListResponse(result);
                    if (successCallback) {
                        successCallback(file, result);
                    }
                },
                errorCallback, cancelToken);
            break;
        case window.enumReportType.DifferencesList:
            getDifferencesListPdfInfo(populationId,
                (result) => {
                    let file = getFileFromReportItemListResponse(result);
                    if (successCallback) {
                        successCallback(file, result);
                    }
                },
                errorCallback, cancelToken);
            break;
        default:
    }
}

export function loadPdfReport(reportType, resultSetter, errorCallback, populationId) {
    switch (reportType) {
        case window.enumReportType.InitialImportResult:
            loadPdfReportAction(getInitialImportPdf, resultSetter, errorCallback, populationId);
            break;
        case window.enumReportType.StructureAnalysis:
            loadPdfReportAction(getStructureAnalysisPdf, resultSetter, errorCallback, populationId);
            break;
        case window.enumReportType.LorenzCurve:
            loadPdfReportAction(getLorenzCurvePdf, resultSetter, errorCallback, populationId);
            break;
        case window.enumReportType.Stratification:
            loadPdfReportAction(getStratificationPdf, resultSetter, errorCallback, populationId);
            break;
        case window.enumReportType.Countlist:
            loadPdfReportAction(getCountlistPdf, resultSetter, errorCallback, populationId);
            break;
        case window.enumReportType.Extrapolation:
            loadPdfReportAction(getExtrapolationPdf, resultSetter, errorCallback, populationId);
            break;
        case window.enumReportType.DifferencesList:
            loadPdfReportAction(getDifferencesListPdf, resultSetter, errorCallback, populationId);
            break;
        case window.enumReportType.DifferencesListGrouped:
            loadPdfReportAction(getDifferencesListGroupedPdf, resultSetter, errorCallback, populationId);
            break;
        case window.enumReportType.CountedItems:
            loadPdfReportAction(getCountedItemListPdf, resultSetter, errorCallback, populationId);
            break;
        case window.enumReportType.UncountedItems:
            loadPdfReportAction(getUncountedItemListPdf, resultSetter, errorCallback, populationId);
            break;
        case window.enumReportType.MultipleCountedItems:
            loadPdfReportAction(getMultipleCountedItemListPdf, resultSetter, errorCallback, populationId);
            break;
        case window.enumReportType.Evaluation:
            loadPdfReportAction(getEvaluationPdf, resultSetter, errorCallback, populationId);
            break;
        case window.enumReportType.DecisionGraph:
            loadPdfReportAction(getDecisionGraphPdf, resultSetter, errorCallback, populationId);
            break;
        case window.enumReportType.UpdateImportResult:
            loadPdfReportAction(getUpdateImportPdf, resultSetter, errorCallback, populationId);
            break;            
        default:
    }
}

export function getInitialImportPdf(param, successCallback, errorCallback, populationId) {
    const url = `${baseUrl}/GetInitialImportPdf`;
    return getFile(url, param, successCallback, errorCallback, populationId);
}

export function getUpdateImportPdf(param, successCallback, errorCallback, populationId) {
    const url = `${baseUrl}/GetUpdateImportPdf`;
    return getFile(url, param, successCallback, errorCallback, populationId);
}

export async function getExtrapolationPdf(param, successCallback, errorCallback, populationId) {
    const url = `${baseUrl}/GetExtrapolationPdf`;
    return getFile(url, param, successCallback, errorCallback, populationId);
}

export async function getDifferencesListPdf(param, successCallback, errorCallback, populationId) {
    const url = `${baseUrl}/GetDifferencesListPdf`;
    return getFile(url, param, successCallback, errorCallback, populationId);
}

export function getDifferencesListPdfInfo(populationId, successCallback, errorCallback, cancelToken) {
    const url = `${baseUrl}/GetDifferencesListPdfInfo`;
    getWithPopulationIdHeader(url, populationId, successCallback, errorCallback, cancelToken);
}

export async function getDifferencesListExported(successCallback, errorCallback, populationId) {
    const url = `${baseUrl}/GetDifferencesListExported`;
    return getFile(url, null, successCallback, errorCallback, populationId);
}

export async function getDifferencesListGroupedPdf(param, successCallback, errorCallback, populationId) {
    const url = `${baseUrl}/GetDifferencesListGroupedPdf`;
    return getFile(url, param, successCallback, errorCallback, populationId);
}

export async function getDifferencesListGroupedExported(successCallback, errorCallback, populationId) {
    const url = `${baseUrl}/GetDifferencesListGroupedExported`;
    return getFile(url, null, successCallback, errorCallback, populationId);
}

export async function getStructureAnalysisPdf(param, successCallback, errorCallback, populationId) {
    const url = `${baseUrl}/GetStructureAnalysisPdf`;
    return getFile(url, param, successCallback, errorCallback, populationId);
}

export async function getLorenzCurvePdf(param, successCallback, errorCallback, populationId) {
    const url = `${baseUrl}/GetLorenzCurvePdf`;
    return getFile(url, param, successCallback, errorCallback, populationId);
}

export async function getStratificationPdf(param, successCallback, errorCallback, populationId) {
    const url = `${baseUrl}/GetStratificationPdf`;
    return getFile(url, param, successCallback, errorCallback, populationId);
}

export async function getCountlistPdf(param, successCallback, errorCallback, populationId) {
    const url = `${baseUrl}/GetCountlistPdf`;
    return getFile(url, param, successCallback, errorCallback, populationId);
}

export async function getCountlistPdfInfo(populationId, successCallback, errorCallback, cancelToken) {
    const url = `${baseUrl}/GetCountlistPdfInfo`;
    getWithPopulationIdHeader(url, populationId, successCallback, errorCallback, cancelToken);
}

export async function getCountedItemListPdf(param, successCallback, errorCallback, populationId) {
    const url = `${baseUrl}/GetCountedItemListPdf`;
    return getFile(url, param, successCallback, errorCallback, populationId);
}

export async function getUncountedItemListPdf(param, successCallback, errorCallback, populationId) {
    const url = `${baseUrl}/GetUncountedItemListPdf`;
    return getFile(url, param, successCallback, errorCallback, populationId);
}

export async function getMultipleCountedItemListPdf(param, successCallback, errorCallback, populationId) {
    const url = `${baseUrl}/GetMultipleCountedItemListPdf`;
    return getFile(url, param, successCallback, errorCallback, populationId);
}

export async function getEvaluationPdf(param, successCallback, errorCallback, populationId) {
    const url = `${baseUrl}/GetEvaluationPdf`;
    return getFile(url, param, successCallback, errorCallback, populationId);
}

export async function getDecisionGraphPdf(param, successCallback, errorCallback, populationId) {
    const url = `${baseUrl}/GetDecisionGraphPdf`;
    return getFile(url, param, successCallback, errorCallback, populationId);
}

export async function getPdfFileByName(pdfName, successCallback, errorCallback, populationId) {
    const params = new URLSearchParams();
    params.append('pdfName', pdfName);
    const url = `${baseUrl}/GetPdfFileByName?` + params;
    return getFile(url, {}, successCallback, errorCallback, populationId);
}

export async function getPdfFromBlobStorage(url, successCallback, errorCallback, populationId) {
    return getFile(url, {}, successCallback, errorCallback, populationId);
}

export function getCompleteCountParameter(populationId, successCallback, errorCallback, cancelToken) {
    getWithPopulationIdHeader(`${baseUrl}/GetCompleteCountParameter`, populationId, successCallback, errorCallback, cancelToken);
}

export async function setCompleteCountThreshold(populationId, thresholdParams, successCallback, errorCallback) {
    return postWithPopulationIdHeader(`${baseUrl}/SetCompleteCountThreshold`, populationId, thresholdParams, successCallback, errorCallback);
}

export async function createStratification(populationId, successCallback, errorCallback) {
    return postWithPopulationIdHeader(`${baseUrl}/CreateStratification`, populationId, null, successCallback, errorCallback);
}

export function getPostAnalysisReportStatus(populationId, successCallback, errorCallback, cancelToken) {
    getWithPopulationIdHeader(`${baseUrlStatus}/GetPostAnalysisReportStatus`, populationId, successCallback, errorCallback, cancelToken);
}

export function getPostAnalysisSeqReportStatus(populationId, successCallback, errorCallback, cancelToken) {
    getWithPopulationIdHeader(`${baseUrlStatus}/GetPostAnalysisSeqReportStatus`, populationId, successCallback, errorCallback, cancelToken);
}

export function getStockDataColumnMapping(populationId, successCallback, errorCallback, cancelToken) {
    getWithPopulationIdHeader(`${baseUrlStatus}/GetStockDataColumnMapping`, populationId, successCallback, errorCallback, cancelToken);
}

export async function setStratificationParameters(populationId, stratificationParameters, successCallback, errorCallback) {
    return postWithPopulationIdHeader(`${baseUrl}/SetStratificationParameters`, populationId, stratificationParameters, successCallback, errorCallback);
}

export async function setSequentialTestParameters(populationId, sequentialTestParameters, successCallback, errorCallback) {
    return postWithPopulationIdHeader(`${baseUrl}/SetSequentialTestParameters`, populationId, sequentialTestParameters, successCallback, errorCallback);
}

export function getReliabilities( successCallback, errorCallback) {
    get(`${baseUrl}/GetReliabilities`, successCallback, errorCallback);
}
export async function resetProgramLockState(populationId, programLockState, successCallback, errorCallback) {
    return postWithPopulationIdHeader(`${baseUrlStatus}/ResetProgramLockState`, populationId, programLockState, successCallback, errorCallback);
}

export async function endInventory(populationId, successCallback, errorCallback) {
    return postWithPopulationIdHeader(`${baseUrl}/EndInventory`, populationId, null, successCallback, errorCallback);
}


export async function deleteTempFile(fileName, customerId, populationId) {
    let formData = new FormData();
    formData.append('fileName', fileName);
    return axios.post(`${baseUrl}/DeleteTempFile`, {}, {
        params: {
            fileName: fileName,
            customerId: customerId,
            populationId: populationId
        },
        data: formData,
    });
}

export function getInventoryMethod(populationId, successCallback, errorCallback) {
    getWithPopulationIdHeader(`${baseUrl}/GetInventoryMethod`, populationId, successCallback, errorCallback);
}

export function resetInventory(populationId, successCallback, errorCallback) {
    const url = `${baseUrl}/ResetInventory`;
    postWithPopulationIdHeader(url, populationId, null, successCallback, errorCallback);
}

export function setWaitingForErp(populationId, isWaitForErp, successCallback, errorCallback, cancelToken) {
    postWithPopulationIdHeader(`${baseUrl}/SetWaitingForErp`, populationId, isWaitForErp, successCallback, errorCallback, cancelToken);
}

export function setCountingOption(populationId, countingoption, successCallback, errorCallback, cancelToken) {
    postWithPopulationIdHeader(`${baseUrl}/SetCountingOption`, populationId, countingoption, successCallback, errorCallback, cancelToken);
}

export async function getDefaultDifferencesListFileName(populationId, grouped, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('grouped', grouped);
    const url = `${baseUrl}/GetDefaultDifferencesListFileName?` + params;
    getWithPopulationIdHeader(url, populationId, successCallback, errorCallback);
}
