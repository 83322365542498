import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Col, Row} from 'reactstrap';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { useSelector } from 'react-redux';
import { getLicenseHistoryOfCustomer } from '../../../services/licenseManagementService';
import { formatDate, formatDateTime, formatNumber, getLocale } from '../../../utils/localization';
import { process } from "@progress/kendo-data-query";
import {
    setGroupIds,
    getGroupIds,
    setExpandedState,
} from "@progress/kendo-react-data-tools";
import GridFooter from '../../Shared/GridFooter';

const initialItemsPerPage = 50;
const initialDataState = {
    take: initialItemsPerPage,
    skip: 0,
    group: [
        {
            field: "historyLastModifiedGroupId",
            
        },
        {
            field: "historyLastModifiedGroup"
        }
    ],
};
const processWithGroups = (data, dataState) => {
    const newDataState = process(data, dataState);
    setGroupIds({
        data: newDataState.data,
        group: dataState.group,
    });
    return newDataState;
};

export default function HistoryLicenseTab(props) {
    const [data, setData] = React.useState([]);
    const [dataState, setDataState] = React.useState(initialDataState);
    const [resultState, setResultState] = React.useState(
        processWithGroups([], initialDataState)
    );
    const [collapsedState, setCollapsedState] = React.useState([]);
    const onDataStateChange = (event) => {
        const newDataState = processWithGroups(data, event.dataState);
        setDataState(event.dataState);
        setResultState(newDataState);
    };
    const userProfile = useSelector(state => state.profile.profile);
    const locale = getLocale(userProfile.userLanguage);
    const [itemsPerPage, setItemsPerPage] = React.useState(initialItemsPerPage);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [isPagingVisible, setIsPagingVisible] = React.useState(false);
    const minGridLength = 5;
    
    const onExpandChange = React.useCallback(
        (event) => {
            const item = event.dataItem;

            if (item.groupId) {
                const collapsedIds = !event.value
                    ? [...collapsedState, item.groupId]
                    : collapsedState.filter((groupId) => groupId !== item.groupId);
                setCollapsedState(collapsedIds);
            }
        },
        [collapsedState]
    );

    const intl = useIntl();

    const currentCustomer = useSelector(state => state.dashboard.customer);

    useEffect(() => {
        if (currentCustomer) {
            getData();
        }
    }, [currentCustomer.customerId, userProfile.userLanguage]);

    const getData = () => {
        props.incrementLoadingCount();
        getLicenseHistoryOfCustomer(
            currentCustomer.customerId,
            items => {
                props.decrementLoadingCount();
                items = translateItems(items);
                                
                setData(items);
                const newDataState = processWithGroups(items, dataState);
                setDataState(dataState);
                setResultState(newDataState);
            },
            props.handleError);
    }

    const translateItems = (items) => {
        return items.map(item => (
            {
                ...item,
                historyLastModifiedGroupId: item.customerLicenseId ,
                historyLastModifiedGroup: formatDateTime(new Date(item.lastModifiedOn), locale) + " - " + item.lastModifiedBy
                    + " - " + (item.isModified ? intl.formatMessage({ id: "HistoryLicense.Modified" }) : intl.formatMessage({ id: "HistoryLicense.Created" })) ,
                columnNameTrans: intl.formatMessage({ id: "HistoryLicense." + item.columnName }),
                oldValueTrans: formatValue(item.oldValue, item.oldValueDate, item.oldValueDecimal, item.isDate, item.isDecimal),
                newValueTrans: formatValue(item.newValue, item.newValueDate, item.newValueDecimal, item.isDate, item.isDecimal),
            }));

    }

    const formatValue = (value, valueDate, valueDecimal, isDate, isDecimal) => {

        if (isDate) {
            return formatDate(valueDate, locale);
        }
        if (isDecimal) {
            return formatNumber(valueDecimal, locale);
        }
        return value;
    }

    const onGroupsToggle = () => {
        const dataStateWithoutPaging = processWithGroups(data, {
            group: dataState.group,
        });
        setCollapsedState(
            collapsedState.length
                ? []
                : getGroupIds({
                    data: dataStateWithoutPaging.data,
                })
        );
    }

    const setPageHandler = (page) => {
        if (data && page > 0 && (page <= Math.floor((data.length - 1) / itemsPerPage) + 1 || data.length === 0)) {
            let startIndex = (page - 1) * itemsPerPage;
            let items = data;

            dataState.take = itemsPerPage;
            dataState.skip = startIndex;
            
            const newDataState = processWithGroups(items, dataState);
            setDataState(dataState);
            setResultState(newDataState);

            if (data.length > itemsPerPage) {
                setCurrentPage(page);
            }
            else {
                setCurrentPage(1);
            }
        }
    }
    useEffect(() => {
        setIsPagingVisible(data.length > minGridLength);
        setPageHandler(1);
    }, [data, itemsPerPage]);

    const newData = setExpandedState({
        data: resultState.data,
        collapsedIds: collapsedState,
    });

    const handleItemsPerPageChange = (dropDownItem) => {
        setItemsPerPage(dropDownItem.value);
    };

    return (
        <div >
            <Row >
                <Col xs="12">
                    <button
                        color="primary"
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-animation-container "
                        onClick={onGroupsToggle}
                    >
                        {collapsedState.length ? intl.formatMessage({ id: "HistoryLicense.ExpandAll" }) : intl.formatMessage({ id: "HistoryLicense.CollapseAll" })}
                    </button>
                    <Grid data={newData}
                        {...dataState}
                        total={resultState.total}
                        onDataStateChange={onDataStateChange}                        
                        onExpandChange={onExpandChange}
                        expandField="expanded"
                    >
                        <Column field="columnNameTrans" title={intl.formatMessage({ id: "HistoryLicense.Action" })} />
                        <Column field="oldValueTrans" title={intl.formatMessage({ id: "HistoryLicense.OldValue" })} />
                        <Column field="newValueTrans" title={intl.formatMessage({ id: "HistoryLicense.NewValue" })} />
                    </Grid>
                </Col>
            </Row>
            {isPagingVisible &&(
            <GridFooter
                itemsPerPage={itemsPerPage}
                handleItemsPerPageChange={handleItemsPerPageChange}
                dataLength={data.length}
                setPageHandler={setPageHandler}
                currentPage={currentPage}
                />
            )}
        </div>
    );
}
