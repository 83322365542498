export const getLocale = (language, simplified) => {
    switch (language) {
        case window.enumLanguage['German']:
            return simplified ? 'de' : 'de-DE';
        case window.enumLanguage['Italian']:
            return simplified ? 'it' : 'it-IT';
        case window.enumLanguage['English']:
            return simplified ? 'en' : 'en-US';
        default:
            return simplified ? 'en' : 'en-US';
    }
};

export const formatDate = (value, locale, defaultValue) => {    
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return value ? new Date(value).toLocaleDateString(locale, options) : (defaultValue ?? '');
};

export const formatNumber = (value, locale, defaultValue) => {
    return value != null ? value.toLocaleString(locale) : (defaultValue ?? '');
};

export const formatNumberWithDecimals = (value, locale, decimals, defaultValue) => {
    return value != null ? (+(value.toFixed(decimals))).toLocaleString(locale, { minimumFractionDigits: decimals, maximumFractionDigits: decimals }) : (defaultValue ?? '');
};

export const formatCurrency = (value, currency, locale, defaultValue) => {
    const options = { style: 'currency', currency: currency };
    return value != null ? value.toLocaleString(locale, options) : (defaultValue ?? '');
};

export const formatDateTime = (value, locale, defaultValue) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return value ? new Date(value).toLocaleTimeString(locale, options) : (defaultValue ?? '');
};

export const formatWithParameters = (formatString, args) => {
    return formatString.replace(/{(\d+)}/g, function (match, index) {
        return typeof args[index] == 'undefined' ? match : args[index];
    });
};

export const parseCustomFloat = (input, decimalSeparator, thousandSeparator) => {
    let sanitizedInput = input;
    if (thousandSeparator.length > 0) {
        const thSepPattern = thousandSeparator + '*'
        sanitizedInput = input.replace(new RegExp(`${thSepPattern}`, 'g'), '');
    }

    let normalizedInput = sanitizedInput;
    if (decimalSeparator.length > 0) {
        normalizedInput = sanitizedInput.replace(new RegExp(`${decimalSeparator}`, 'g'), '.');
    }

    const floatValue = parseFloat(normalizedInput);
    return isNaN(floatValue) ? null : floatValue;
}

const replaceLastOccurrence = (inputString, targetChar, replacement) => {
    const lastIndex = inputString.lastIndexOf(targetChar);

    if (lastIndex === -1) {
        return inputString;
    }

    return inputString.slice(0, lastIndex) + replacement + inputString.slice(lastIndex + 1);
}

export const formatWithCustomSeparators = (value, decimalSeparator, groupSeparator) => {
    if (value === null) {
        return '';
    }

    const formattedString = value.toLocaleString('en-US', {
        style: 'decimal',
        useGrouping: true,
        //minimumFractionDigits: 2,
        //maximumFractionDigits: 2,
        minimumIntegerDigits: 1,
        minimumSignificantDigits: 1
    });

    let formattedValue = formattedString.replace(/,/g, 'x');

    if (decimalSeparator) {
        formattedValue = replaceLastOccurrence(formattedValue, '.', decimalSeparator);
    }

    formattedValue = formattedValue.replace(/x/g, groupSeparator ?? '');

    return formattedValue;
}

export const systemDecimalSeparator = (1.1).toLocaleString().substring(1, 2);
export const systemGroupSeparator = (1111.1).toLocaleString().substring(1, 2);

