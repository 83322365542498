import * as React from "react";
import { Button } from 'reactstrap';
import { useIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faTrashAlt,
    faTimes,
    faSave,
    faPencilAlt,
    faGlobe
} from '@fortawesome/pro-light-svg-icons'

export const EditActionCell = (props) => {
    const intl = useIntl();
    const { dataItem } = props;
    const inEdit = dataItem[props.editField];
    const isNewItem = dataItem[props.idPropName] === "" || dataItem[props.idPropName] === -1;

    const [importInProgress, setImportInProgress] = React.useState(false);
    const [importPopulationInProgress, setImportPopulationInProgress] = React.useState(false);

    const hideDelete = props.hideDelete ?? false;

    const setLocationDataTip = intl.formatMessage({ id: 'WarehouseLocation.SetLocation' });
    const editDataTip = intl.formatMessage({ id: 'Control.Edit' });
    const deleteDataTip = intl.formatMessage({ id: 'Control.Delete' });
    const saveDataTip = intl.formatMessage({ id: 'Control.Save' });
    const cancelDataTip = intl.formatMessage({ id: 'Control.Cancel' });

    const disabledDeleteButtonTooltip = () => {
        let tooltipString = '';
        if (importInProgress) {
            tooltipString = intl.formatMessage({ id: 'Population.ImportInProgress' })
        }
        else if (props.isLastWH) {
            tooltipString = intl.formatMessage({ id: 'WarehouseLocation.ThereMustBeAtLeast1Location' });
        }
        else if (props.isLastPop) {
            tooltipString = intl.formatMessage({ id: 'Population.MinPopRequired' });
        }

        return tooltipString;
    };

    React.useEffect(() => {
        setImportInProgress(dataItem.importInProgress);
        setImportPopulationInProgress(dataItem.importPopulationInProgress);

    }, []);

    const saveClick = () => {
        return isNewItem ? props.add(dataItem) : props.update(dataItem);
    }

    const cancelClick = () => {
        return isNewItem ? props.discard(dataItem) : props.cancel(dataItem);
    }

    const editWarehouseLocationClick = () => {
        return props.enterEditWarehouseLocation(props.dataItem);
    }

    const editClick = () => {
        return props.edit(dataItem);
    }

    const removeClick = () => {
        return props.remove(dataItem);
    }

    const disableEdit = importInProgress || props.disabled;
    const disableEditWarehouseLocation = importInProgress || props.disabled || importPopulationInProgress;
    const disableRemove = importInProgress || props.disabled || props.isLastPop || importPopulationInProgress || props.isLastWH;

    const inEditRenderer = () => {
        return (
            <td>
                <ReactTooltip id="buttonTip" place="left" type="error" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />
                <Button
                    color="link"
                    onClick={saveClick}
                    data-tip={saveDataTip} data-for="buttonTip"
                    disabled={!dataItem.changed}
                >
                    <FontAwesomeIcon icon={faSave} />
                </Button>
                <Button
                    color="link"
                    onClick={cancelClick}
                    data-tip={cancelDataTip} data-for="buttonTip"
                >
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
            </td>
        );
    }

    const inNoEditRenderer = () => {
        return (
            <td>
                {props.enterEditWarehouseLocation &&
                    <Button
                        data-tip={setLocationDataTip} data-for="buttonTip"
                        color="link"
                        onClick={editWarehouseLocationClick}
                        disabled={disableEditWarehouseLocation}
                    >
                        <FontAwesomeIcon icon={faGlobe} />
                    </Button>
                }

                <ReactTooltip id="buttonTip" place="left" type="error" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />
                <Button
                    color="link"
                    onClick={editClick}
                    data-tip={editDataTip} data-for="buttonTip"
                    disabled={disableEdit}
                >
                    <FontAwesomeIcon icon={faPencilAlt} />
                </Button>
                {!hideDelete &&
                    <span>
                        <span data-tip={disabledDeleteButtonTooltip()} data-for="buttonTip">
                            <Button
                                color="link"
                                data-tip={deleteDataTip} data-for="buttonTip"
                                onClick={removeClick}
                                disabled={disableRemove}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                        </span>
                    </span>
                }
            </td>
        );
    }

    const editActionCellRenderer = () => {
        return inEdit ? inEditRenderer() : inNoEditRenderer();
    }

    return editActionCellRenderer();
}

export default EditActionCell