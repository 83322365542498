import React, { useState, useEffect } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    Button
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { FormattedMessage, useIntl } from 'react-intl';
import { useLoading } from '../Shared/LoadingContext';
import { useNotification } from '../Shared/Notifications/NotificationProvider';
import { useExceptionDialog } from '../Shared/ExceptionDialog/ExceptionDialogProvider';
import { NotificationTypes } from '../Shared/Notifications/Notification';
import { getLicenses, getSupports } from '../../services/licenseManagementService';
import { getLocale, formatNumber, formatCurrency } from '../../utils/localization';

const LicenseDialog = (props) => {
    const intl = useIntl();

    const { setLoading } = useLoading();
    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const pageId = 'LicenseDialog';

    const userProfile = useSelector(state => state.profile.profile);
    const locale = getLocale(userProfile.userLanguage);
    const currentCustomer = useSelector(state => state.dashboard.customer);
    const [selectedLicenseId, setSelectedLicenseId] = useState(props.selectedLicenseId);

    const isContinueDisabled = selectedLicenseId == null || props.activeLicenseId === selectedLicenseId;

    const [supports, setSupports] = useState([]);
    useEffect(() => {
        incrementLoadingCount();
        getSupports(
            values => {
                decrementLoadingCount();
                setSupports(values);
            },
            handleError);
    }, []);

    const translateData = (data) => {
        data.forEach((item, index, arr) => {
            const support = supports.find(x => item.supportId === x.value);
            arr[index].supportObj = support ? support.text : '';

            const procedure = procedures.find(x => item.procedure === x.value);
            arr[index].procedureObj = procedure ? procedure.text : '';
        });
        return data;
    };

    const getTranslatedProcedureText = procVal => {
        let translated = '';
        if (procVal != null) {
            translated = intl.formatMessage({ id: 'ManageLicense.Procedure_' + procVal });
        }
        return translated;
    };

    const procedures = Object.keys(window.enumProcedure).map(procedure => { return { text: getTranslatedProcedureText(window.enumProcedure[procedure]), value: window.enumProcedure[procedure] } });

    useEffect(() => {
        if (!supports || supports.length === 0
            || !procedures || procedures.length === 0) {
            return;
        }

        loadLicenses();
    }, [supports]);

    const [licenses, setLicenses] = useState([]);
    const loadLicenses = () => {
        removeNotification();
        incrementLoadingCount();
        getLicenses(
            currentCustomer.customerId,
            licenseInfos => {
                decrementLoadingCount();
                let data = translateData(licenseInfos);
                setLicenses(data);
            },
            handleError);
    };

    useEffect(() => {
        loadLicenses();
    }, [userProfile.userLanguage]);

    const [loadingCount, setLoadingCount] = useState(0);
    useEffect(() => {
        setLoading(loadingCount > 0);
    }, [loadingCount]);

    const incrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount + 1);
    };

    const decrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount - 1);
    };

    const handleError = (errorMessage, showNotif = true) => {
        decrementLoadingCount();
        if (showNotif) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            });
        }
    };

    const removeNotification = () => {
        dispatchNotification({
            remove: true,
            pageId: pageId
        });
    };

    const handleClose = () => {
        props.onClose();
    };

    const handleContinue = () => {
        const params = {
            licenseId: selectedLicenseId,
            billingPeriod: props.billingPeriod
        };
        props.onContinue(params);
    };

    const getLicenseButtonText = (licenseId) => {
        if (props.activeLicenseId === licenseId) {
            return <FormattedMessage id='Control.Active' />;
        }

        if (selectedLicenseId === licenseId) {
            return <FormattedMessage id='Control.Chosen' />;
        }

        return <FormattedMessage id='Control.Choose' />;
    };

    const isLicenseButtonDisabled = (licenseId) => {
        return licenseId <= props.activeLicenseId;
    };

    return (
        <Dialog
            title={<FormattedMessage id='LicenseDialog.Heading' />}
            onClose={handleClose}
            className="license"
        >
            <p className="lead">
                <FormattedMessage id='LicenseDialog.Details' />
            </p>
            <Row className="mt-5">
                <Col xs="12">
                    <div className="k-card-deck">
                        {licenses.map(license => license.isLicenseVisible && (
                            <Card
                                className={selectedLicenseId === license.licenseId ? 'selected-plan' : undefined}
                                key={"standard-lic-card-" + license.licenseId}
                            >
                                <CardBody>
                                    <p className="lead">{license.subscriptionTypeName}</p>
                                    <ul>
                                        {license.isPricePerMonthPaymentPerYearVisible && (
                                            <li>
                                                {formatCurrency(license.pricePerMonthPaymentPerYear, 'EUR', locale)}
                                                <small>
                                                    &nbsp;
                                                    <FormattedMessage id='ManageLicense.PricePerMonthPaymentPerYear' />
                                                </small>
                                            </li>
                                        )}
                                        {license.isPricePerMonthPaymentPerMonthVisible && (
                                            <li>
                                                {formatCurrency(license.pricePerMonthPaymentPerMonth, 'EUR', locale)}
                                                <small>
                                                    &nbsp;
                                                    <FormattedMessage id='ManageLicense.PricePerMonthPaymentPerMonth' />
                                                </small>
                                            </li>
                                        )}
                                        {license.isNumberOfPositionsVisible && (
                                            <li>
                                                <FormattedMessage
                                                    id="LicenseDialog.UpToNumberOfPositions"
                                                    values={{
                                                        NumberOfPositions: formatNumber(license.numberOfPositions, locale)
                                                    }}
                                                />
                                            </li>
                                        )}
                                        {license.isNumberOfPopulationsVisible && (
                                            <li>
                                                <FormattedMessage
                                                    id="LicenseDialog.UpToNumberOfPopulations"
                                                    values={{
                                                        NumberOfPopulations: formatNumber(license.numberOfPopulations, locale)
                                                    }}
                                                />
                                            </li>
                                        )}
                                        {license.isMinimumTermVisible && (
                                            <li>
                                                <FormattedMessage
                                                    id="LicenseDialog.MinimumTerm"
                                                    values={{
                                                        MinimumTerm: formatNumber(license.minimumTerm, locale)
                                                    }}
                                                />
                                            </li>
                                        )}
                                        {license.isSupportVisible && (
                                            <li>
                                                <FormattedMessage
                                                    id="LicenseDialog.Support"
                                                    values={{
                                                        Support: license.supportObj
                                                    }}
                                                />
                                            </li>
                                        )}
                                        {license.isProcedureVisible && (
                                            <li>
                                                <FormattedMessage
                                                    id="LicenseDialog.Procedure"
                                                    values={{
                                                        Procedure: license.procedureObj
                                                    }}
                                                />
                                            </li>
                                        )}
                                        {license.isSettlementAdditionalPositionPriceVisible && (
                                            <li>
                                                {formatCurrency(license.settlementAdditionalPositionPrice, 'EUR', locale)}
                                                <small>
                                                    &nbsp;
                                                    <FormattedMessage id='ManageLicense.SettlementAdditionalPositionPrice' />
                                                </small>
                                            </li>
                                        )}
                                        {license.isDescriptionVisible && (
                                            <li>
                                                {license.description}
                                            </li>
                                        )}
                                    </ul>
                                </CardBody>
                                <CardFooter>
                                    <Button
                                        color={selectedLicenseId === license.licenseId ? 'primary' : undefined}
                                        className={selectedLicenseId === license.licenseId ? 'btn btn-block' : 'btn btn-secondary btn-block'}
                                        disabled={isLicenseButtonDisabled(license.licenseId)}
                                        onClick={() => setSelectedLicenseId(license.licenseId)}
                                    >
                                        {getLicenseButtonText(license.licenseId)}
                                    </Button>
                                </CardFooter>
                            </Card>
                        ))}
                        <Card
                            className={selectedLicenseId === 0 ? 'selected-plan' : undefined}
                            key={"standard-lic-card-" + 0}
                        >
                            <CardBody>
                                <p className="lead"><FormattedMessage id='LicenseDialog.Individual' /></p>
                                <ul>
                                    <li>
                                        <FormattedMessage id='LicenseDialog.PriceOnDemand' />
                                    </li>
                                </ul>
                            </CardBody>
                            <CardFooter>
                                <Button
                                    color={selectedLicenseId === 0 ? 'primary' : undefined}
                                    className={selectedLicenseId === 0 ? 'btn btn-block' : 'btn btn-secondary btn-block'}
                                    onClick={() => setSelectedLicenseId(0)}
                                >
                                    {getLicenseButtonText(0)}
                                </Button>
                            </CardFooter>
                        </Card>
                    </div>
                </Col>


            </Row>

            <DialogActionsBar>
                <Button
                    color="secondary"
                    onClick={handleClose}
                >
                    <FormattedMessage id='Control.Cancel' />
                </Button>
                <Button
                    color="primary"
                    disabled={isContinueDisabled}
                    onClick={handleContinue}
                >
                    <FormattedMessage id='Control.Continue' />
                </Button>
            </DialogActionsBar>
        </Dialog>
    );
}

export default LicenseDialog;
