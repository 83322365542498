import React, { useEffect } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { getVersion } from '../services/apiService'
import { FormattedMessage, useIntl } from 'react-intl';
import { UserContext } from "./UserContext";
import { useNotification } from "./Shared/Notifications/NotificationProvider";
import { useExceptionDialog } from "./Shared/ExceptionDialog/ExceptionDialogProvider";
import { NotificationTypes } from "./Shared/Notifications/Notification";
import { SessionKeyIsAutomaticLogout } from '../services/serviceBase';

const Footer = () => {

    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const pageId = 'Footer';
    const userContext = React.useContext(UserContext);
    const intl = useIntl();
    const [state, setState] = React.useState({
        version: null
    });

    useEffect(() => {
        const isAutomaticLogout = sessionStorage.getItem(SessionKeyIsAutomaticLogout);
        if (isAutomaticLogout) {
            return;
        }

        getVersion(
            result => {
                setState({ version: result })
            },
            handleError);

    }, []);

    const handleError = (errorMessage, showNotif = true) => {
        if (showNotif) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            });
        }
    };

    const versionControl = intl.formatMessage({ id: "Footer.Version" }) + ": " + (state.version ? state.version.version : '--') + " " + intl.formatMessage({ id: "Footer.Build" }) + ": " + (state.version ? state.version.patch : '--') + "." + (state.version ? state.version.build : '--');

    return (
        <footer className="footer d-flex justify-content-center">
            <Nav>
                <NavItem>
                    <NavLink href="https://www.remira.com/de/" target="_blank">
                        <FormattedMessage id='Footer.Copyright' />
                        &copy; {(new Date().getFullYear())} &mdash; REMIRA Group GmbH
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href={userContext.userGlobal && userContext.userGlobal.userInfo.isStatcontrolAdmin ? "/release-notes" : "#"} target="_blank" disabled={!userContext.userGlobal?.userInfo?.isStatcontrolAdmin}>
                        {versionControl}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="https://www.remira.com/en/imprint" target="_blank">
                        <FormattedMessage id='Footer.Imprint' />
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="https://www.remira.com/en/privacy-policy" target="_blank">
                        <FormattedMessage id='CookieConsent.PrivacyPolicy' />
                    </NavLink>
                </NavItem>
            </Nav>
        </footer>
    );
}

export default Footer
