import { get, post } from './serviceBase';

const baseUrl = '/api/v1.0/admin/BlacklistCompany';

export function getBlacklistCompanyWords(successCallback, errorCallback) {
    const url = `${baseUrl}/GetBlacklistCompanyWords`;
    get(url, successCallback, errorCallback);
}

export function addBlacklistCompanyWord(blWord, successCallback, errorCallback) {
    const url = `${baseUrl}/AddBlacklistCompanyWord`;
    post(url, blWord, successCallback, errorCallback);
}

export function updateBlacklistCompanyWord(blWord, successCallback, errorCallback) {
    const url = `${baseUrl}/UpdateBlacklistCompanyWord`;
    post(url, blWord, successCallback, errorCallback);
}

export function deleteBlacklistCompanyWord(blacklistCompanyWordId, successCallback, errorCallback) {
    const params = new URLSearchParams();
    if (blacklistCompanyWordId) {
        params.append('blacklistCompanyWordId', blacklistCompanyWordId);
    }
    const url = `${baseUrl}/DeleteBlacklistCompanyWord?` + params;
    get(url, successCallback, errorCallback);
}

export function validateBlacklistCompanyWords(companyName, successCallback, errorCallback) {
    const params = new URLSearchParams();
    if (companyName) {
        params.append('companyName', companyName);
    }
    const url = `${baseUrl}/ValidateBlacklistCompanyWords?` + params;
    get(url, successCallback, errorCallback);
}