import React, { useState, useEffect } from 'react';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Prompt, useParams } from 'react-router-dom';
import { storeCustomer } from '../../../actions/dashboardActions';
import { useNotification } from '../../Shared/Notifications/NotificationProvider';
import RecycleBinCard from "./RecycleBinCard";
import TestParamsCard from "./TestParamsCard";
import ConfirmDialog from '../../Shared/ConfirmDialog';
import UploadDataQuality from "./UploadDataQuality";
import PopulationSettings from './PopulationSettings';
import { Context } from '../../Wrapper';

const SettingsTabs = (props) => {

    const intl = useIntl();
    const context = React.useContext(Context);
    const dispatch = useDispatch();
    const tabIndexPopulation = 0;
    const tabIndexUploadedDataQuality = 1;
    const tabIndexTestParams = 2;

    const [selected, setSelected] = React.useState(tabIndexPopulation);
    const dispatchNotification = useNotification();
    const { tab } = useParams();

    const handleSelect = (e) => {
        if (areChangesOnCurrentTab()) {
            setConfirmDialogTitle(<FormattedMessage id='Confirm.UnsavedChangesTitle' />);
            setConfirmDialogDetail(<FormattedMessage id='Confirm.UnsavedChangesText' />);
            setConfirmDialogCallback({
                success: () => {
                    setSelected(e.selected);
                }
            });
            setIsConfirmDialogVisible(true);
        }
        else {
            dispatchNotification({
                removeOnPageChanges: true
            });
            setSelected(e.selected);
        }
    };

    const [areChangesOnUploadDataQuality, setAreChangesOnUploadDataQuality] = useState();
    const [areChangesOnPopulationSettings, setAreChangesOnPopulationSettings] = useState();
    const areChangesOnCurrentTab = () => {
        switch (selected) {
            case tabIndexPopulation:
                return areChangesOnPopulationSettings;
            case tabIndexUploadedDataQuality:
                return areChangesOnUploadDataQuality;
            case tabIndexTestParams:
                return areChangesOnTestParams;
            default:
                return false;
        }
    };

    const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);
    const [confirmDialogTitle, setConfirmDialogTitle] = useState('');
    const [confirmDialogDetail, setConfirmDialogDetail] = useState('');
    const [confirmDialogCallback, setConfirmDialogCallback] = useState(null);

    const handleConfirmDialogClose = (isConfirmed) => {
        setIsConfirmDialogVisible(false);
        if (isConfirmed) {
            if (confirmDialogCallback) {
                confirmDialogCallback.success();
            }
        }
        else if (confirmDialogCallback?.cancel) {
            confirmDialogCallback.cancel();
        }
    };

    const [triggerResetDataQuality, setTriggerResetDataQuality] = useState(false);
    const [triggerResetPopulationSettings, setTriggerResetPopulationSettings] = useState(false);
    useEffect(() => {
        setTriggerResetPopulationSettings(!triggerResetPopulationSettings)
        setTriggerResetDataQuality(!triggerResetDataQuality);
        if (tab) {
            setSelected(Number(tab));
        }
    }, [props.settings, props.populations, props.reliabilities]);

    useEffect(() => {
        if (props.nextCustomerSelection) {
            if (areChangesOnCurrentTab()) {
                setConfirmDialogTitle(<FormattedMessage id='Confirm.UnsavedChangesTitle' />);
                setConfirmDialogDetail(<FormattedMessage id='Confirm.UnsavedChangesText' />);
                setConfirmDialogCallback({
                    success: () => {
                        dispatch(storeCustomer(props.nextCustomerSelection));
                    },
                    cancel: () => {
                        props.setNextCustomerSelection(null)
                    }
                });
                setIsConfirmDialogVisible(true);
            }
            else {
                dispatch(storeCustomer(props.nextCustomerSelection));
            }
        }
    }, [props.nextCustomerSelection]);

    const [areChangesOnTestParams, setAreChangesOnTestParams] = useState(false);

    const handleTestParamsValueChanged = (areChanges) => {
        setAreChangesOnTestParams(areChanges);
    };

    const handleTestParamsSubmit = (dataItem) => {
        props.onSubmitTestParams(dataItem);
    };

    const handleSubmitPopulationSettings = (dataItem) => {
        props.onSubmitPopulationSettings(dataItem);
    };

    const handleSubmitUploadDataQuality = (dataItem) => {
        props.onSubmitUploadedDataQualitySettings(dataItem);
    };

    return (
        <div>
            <Prompt
                when={areChangesOnCurrentTab()}
                message={JSON.stringify({
                    header: "" + intl.formatMessage({ id: 'Confirm.UnsavedChangesTitle' }),
                    content: "" + intl.formatMessage({ id: 'Confirm.UnsavedChangesText' }),
                    cancel: "" + intl.formatMessage({ id: 'Control.Cancel' }),
                    confirm: "" + intl.formatMessage({ id: 'Control.Confirm' })
                })}
            />
            <TabStrip
                selected={selected}
                onSelect={handleSelect}
            >
                <TabStripTab title={<FormattedMessage id='Settings.Population' />}>
                    <PopulationSettings
                        resetPopulationSettings={triggerResetPopulationSettings}
                        setAreChangesOnPopulationSettings={setAreChangesOnPopulationSettings}
                        currencies={props.currencies}
                        settings={props.settings}
                        inventoryTypes={props.inventoryTypes}
                        decimalSeparators={props.decimalSeparators}
                        onSubmitPopulationSettings={handleSubmitPopulationSettings}
                        populations={props.populations}
                        locale={context.locale}
                        language={context.localeName}
                    />
                </TabStripTab>
                <TabStripTab title={<FormattedMessage id='Settings.UploadedDataQuality' />}>
                    <UploadDataQuality
                        dataQualities={props.dataQualities}
                        reliabilities={props.reliabilities}
                        settings={props.settings}
                        resetDataQuality={triggerResetDataQuality}
                        setAreChangesOnUploadDataQuality={setAreChangesOnUploadDataQuality}
                        onSubmitUploadedDataQualitySettings={handleSubmitUploadDataQuality}
                    />
                </TabStripTab>
                <TabStripTab title={<FormattedMessage id='Settings.TestParams.TabText' />}>
                    <TestParamsCard
                        settings={props.settings}
                        errorDefinitions={props.errorDefinitions}
                        errorWeights={props.errorWeights}
                        logicalOperators={props.logicalOperators}
                        onValueChanged={handleTestParamsValueChanged}
                        onSubmit={handleTestParamsSubmit}
                    />
                </TabStripTab>
                <TabStripTab title={<FormattedMessage id='Settings.RecycleBin.TabText' />}>
                    <RecycleBinCard customer={props.customer} />
                </TabStripTab>
            </TabStrip>

            <ConfirmDialog
                visible={isConfirmDialogVisible}
                onClose={handleConfirmDialogClose}
                negative={<FormattedMessage id='Control.Cancel' />}
                positive={<FormattedMessage id='Control.Confirm' />}
                title={confirmDialogTitle}
                detail={confirmDialogDetail}
            />
        </div>
    );
}

export default SettingsTabs
