import * as React from "react";
import { NumericTextBox } from "@progress/kendo-react-inputs";

export const PageBreakCell = (props) => {
    const handleChange = (e) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value ?? 0,
            });
        }
    };

    const { dataItem } = props;
    const field = props.field || "";
    const dataValue = dataItem[field] === null ? 0 : dataItem[field];

    const isEditable = (dataItem.fId >= window.enumFields.Ident1 && dataItem.fId <= window.enumFields.Ident15)
        || (dataItem.fId >= window.enumFields.AdditionalField1 && dataItem.fId <= window.enumFields.AdditionalField15)
        || (dataItem.fId === window.enumFields.StratumNumber);

    const content = dataItem.inEdit ? (
        <NumericTextBox
            value={dataValue}
            onChange={handleChange}
            min={0}
            max={2147483647}
        />
    ) : (
        dataValue.toString()
    );

    return (
        <td>
            {isEditable ? content : ""}
        </td>
    );
};
