import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox } from "@progress/kendo-react-inputs";
import { Card, CardBody, Col, Row } from 'reactstrap';
import { getCurrencies, setInventoryParameters } from '../../../../services/preparationService';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLoading } from "../../../Shared/LoadingContext";
import { useNotification } from '../../../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../../../Shared/Notifications/Notification';
import { wizardEvents } from '../Wizard';
import { trigger, on, off } from '../../../../actions/events';
import ConfirmDialog from '../../../Shared/ConfirmDialog';
import { selectPopulation } from '../../../../actions/dashboardActions';
import { WizzardContext, WIZZARD_ACTIONS } from "../WizzardContext";
import { UserContext } from '../../../UserContext';
import axios from 'axios';
import { useExceptionDialog } from '../../../Shared/ExceptionDialog/ExceptionDialogProvider';
import { DropDownList, AutoComplete } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { filterBy } from '@progress/kendo-data-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import {
    IntlProvider,
    LocalizationProvider
} from "@progress/kendo-react-intl";

export const PreparationInfo = {
    None: 0,
    WarehouseLocation: 1,
    Population: 2,
    Currency: 3,
    InventoryTypePerpetual: 4,
    BalanceSheetDate: 5
};

const Preparation = (props) => {

    const cookieNameSkipCheckForChanges = "CookieSkipCheckForChanges";
    const cookieValueSkipCheckForChanges = "Accepted";
    const [cookies, setCookie, removeCookie] = useCookies([cookieNameSkipCheckForChanges]);
    const intl = useIntl();
    const [skipCheckForChanges, setSkipCheckForChanges] = useState(false);
    const [isDontShowThisMessageAgainVisible, setIsDontShowThisMessageAgainVisible] = useState(false);

    const cancelTokenSource = axios.CancelToken.source();
    const wizzardContext = React.useContext(WizzardContext);
    const userContext = React.useContext(UserContext);

    const warehouseLocation = wizzardContext.wizzardGlobal?.HeaderInfo?.warehouse;

    const selectedPopulation = useSelector(state => state.dashboard.population);
    const currentCustomer = useSelector(state => state.dashboard.customer);
    const dispatch = useDispatch();

    const { setLoading } = useLoading();
    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const pageId = 'Preparation';

    const [loadingCount, setLoadingCount] = useState(0);
    useEffect(() => {
        setLoading(loadingCount > 0);
    }, [loadingCount]);

    const incrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount + 1);
    };

    const decrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount - 1);
    };

    const handleError = (errorMessage, showNotif = true) => {
        decrementLoadingCount();
        if (showNotif) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            })
        }
    };

    const [currency, setCurrency] = useState(null);
    const [currencyName, setCurrencyName] = useState(null);
    const [currencies, setCurrencies] = useState([]);
    const [currenciesItems, setCurrenciesItems] = useState([]);

    useEffect(() => {
        incrementLoadingCount();
        getCurrencies(
            selectedPopulation.populationId,
            currs => {
                decrementLoadingCount();
                setCurrenciesItems(currs);
                let data = currs.map(element => element.text);
                setCurrencies(data);
            },
            handleError,
            cancelTokenSource.token
        );
    }, [selectedPopulation]);

    useEffect(() => {
        if (currenciesItems && !currency) {
            const curr = currenciesItems.find(x => x.isDefault);
            if (curr) {
                setCurrencyName(curr.text);
                setCurrency(curr);
            }
        }
    }, [currenciesItems]);

    const [inventoryType, setInventoryType] = useState(null);
    const [inventoryTypes, setInventoryTypes] = useState([]);

    useEffect(() => {
        let invTypes = [
            { value: window.enumInventoryType.PeriodicInventorySample, text: intl.formatMessage({ id: 'InventoryType.PeriodicInventorySample' }) },
            { value: window.enumInventoryType.CompleteCount, text: intl.formatMessage({ id: 'InventoryType.CompleteCount' }) }
        ];
        if (!wizzardContext.wizzardGlobal.InterfaceConfiguration?.connectedToErp) {
            invTypes.unshift({ value: window.enumInventoryType.PerpetualInventorySample, text: intl.formatMessage({ id: 'InventoryType.PerpetualInventorySample' }) });
        }
        setInventoryTypes(invTypes);
    }, [props.locale]);


    const minLegalBalanceSheetDate = new Date(new Date().setMonth(new Date().getMonth() - 1));
    minLegalBalanceSheetDate.setHours(0, 0, 0, 0);
    const maxLegalBalanceSheetDate = new Date(new Date().setMonth(new Date().getMonth() + 3));
    maxLegalBalanceSheetDate.setHours(0, 0, 0, 0);
    const defaultBalanceSheetDate = new Date(new Date().getFullYear(), 11, 31);
    const minBalanceSheetDate = new Date(new Date().setMonth(new Date().getMonth() - 60));
    minBalanceSheetDate.setHours(0, 0, 0, 0);
    const maxBalanceSheetDate = new Date(new Date().setMonth(new Date().getMonth() + 60));
    maxBalanceSheetDate.setHours(0, 0, 0, 0);
    const [balanceSheetDate, setBalanceSheetDate] = useState(defaultBalanceSheetDate);

    const [areChanges, setAreChanges] = useState(false);

    useEffect(() => {
        SetContinueBtnDisabled(currency, inventoryType, balanceSheetDate);
    }, [inventoryType, currency, balanceSheetDate]);

    const handleCurrencyChange = (event) => {
        setCurrencyName(event.value);
        let curr = currenciesItems.filter((item) => item.text === event.value)[0];
        setCurrency(curr);
        setAreChanges(true);
        setCurrencies(filterCurrencies(event.value));
    };

    const filterCurrencies = (value) => {
        const data = currenciesItems.map(element => element.text);
        const filter = {
            value: value,
            operator: "contains",
            ignoreCase: true,
        };
        return value ? filterBy(data, filter) : data;
    };

    const handleInventoryTypeChange = (event) => {
        setInventoryType(event.target.value);
        setAreChanges(true);
    };

    const handleBalanceSheetDateChange = (event) => {
        setBalanceSheetDate(event.value);
        const isBalanceSheetDateValid = BalanceSheetDateValidator(event.value) === "";
        if (isBalanceSheetDateValid) {
            showLegalBalanceSheetDateNotification(event.value);
        }
    };

    useEffect(() => {
        if (!wizzardContext.wizzardGlobal.CustomerSettings || currenciesItems.length === 0 || inventoryTypes.length === 0)
            return;

        if (wizzardContext.wizzardGlobal.SupportData?.inventoryType != null) {
            let popInvType = inventoryTypes.find(x => x.value === wizzardContext.wizzardGlobal.SupportData.inventoryType);
            setInventoryType(popInvType);
        }
        else if (wizzardContext.wizzardGlobal.CustomerSettings.populationInventoryType != null) {
            setInventoryType(inventoryTypes.find(x => x.value === wizzardContext.wizzardGlobal.CustomerSettings.populationInventoryType))
        }

        if (wizzardContext.wizzardGlobal.SupportData?.balanceSheetDate) {
            setBalanceSheetDate(new Date(wizzardContext.wizzardGlobal.SupportData.balanceSheetDate));
        }
        else if (wizzardContext.wizzardGlobal.CustomerSettings.populationBalanceSheetDate) {
            setBalanceSheetDate(new Date(wizzardContext.wizzardGlobal.CustomerSettings.populationBalanceSheetDate))
        }

        if (wizzardContext.wizzardGlobal.SupportData?.reportCurrency) {
            setCurrency(currenciesItems.find(x => x.text === wizzardContext.wizzardGlobal.SupportData.reportCurrency));
            setCurrencyName(wizzardContext.wizzardGlobal.SupportData.reportCurrency);
        }
        else if (wizzardContext.wizzardGlobal.CustomerSettings.populationCurrency) {
            let cur = currenciesItems.find(x => x.value === wizzardContext.wizzardGlobal.CustomerSettings.populationCurrency);
            if (cur) {
                setCurrency(cur);
                setCurrencyName(cur.text);
            }
        }
    }, [currentCustomer.customerId, currenciesItems, inventoryTypes, selectedPopulation, wizzardContext.wizzardGlobal.SupportData]);

    const currencyValidator = (value) => value ? "" : intl.formatMessage({ id: "Preparation.SelectCurrency" });
    const inventoryTypeValidator = (value) => value ? "" : intl.formatMessage({ id: "Preparation.SelectInventoryType" });
    const BalanceSheetDateValidator = (value) => value >= minBalanceSheetDate && value <= maxBalanceSheetDate ? "" :
        intl.formatMessage(
            { id: "Preparation.BalanceSheetValid" },
            {
                FromDate: minBalanceSheetDate.toLocaleDateString(props.locale, dateOptions),
                ToDate: maxBalanceSheetDate.toLocaleDateString(props.locale, dateOptions)
            }
        );

    const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };

    const [isConfirmDialogVisible, setIsConfirmDialogVisible] = React.useState(false);
    const [confirmDialogTitle, setConfirmDialogTitle] = React.useState('');
    const [confirmDialogDetail, setConfirmDialogDetail] = React.useState('');
    const [confirmDialogCallback, setConfirmDialogCallback] = React.useState(null);

    const [isBalanceSheetDateInPastVisible, setIsBalanceSheetDateInPastVisible] = React.useState(false);

    const handleConfirmDialogClose = (isConfirmed) => {
        setIsConfirmDialogVisible(false);
        setIsBalanceSheetDateInPastVisible(false);
        if (confirmDialogCallback) {
            if (isConfirmed) {
                confirmDialogCallback.success();
            }
            else {
                confirmDialogCallback.cancel();
            }
        }
    };

    const checkBalanceSheetDateInPastAndContinue = () => {
        if (balanceSheetDate < new Date()) {
            setConfirmDialogTitle(<FormattedMessage id="Preparation.BalanceSheetDateConfirm" />);
            setConfirmDialogDetail(<FormattedMessage id="Preparation.BalanceSheetDateInPastQ" />);
            setConfirmDialogCallback({
                success: () => saveInventoryParameters(),
                cancel: () => { }
            });
            setIsBalanceSheetDateInPastVisible(true);
        }
        else {
            saveInventoryParameters();
        }
    };

    const handleContinueButtonClicked = () => {
        if (userContext.userGlobal.userInfo.isController) {
            trigger(wizardEvents.stepFinalized, {});
        }
        else {
            if (!isFormValid()) {
                return;
            }
            if (!skipCheckForChanges && !areChanges
                && balanceSheetDate.toLocaleDateString(props.locale, dateOptions) == defaultBalanceSheetDate.toLocaleDateString(props.locale, dateOptions)) {
                setConfirmDialogTitle(<FormattedMessage id="Preparation.NoChanges" />);
                setConfirmDialogDetail(<FormattedMessage id="Preparation.NoChangesQ" />);
                setConfirmDialogCallback({
                    success: () => checkBalanceSheetDateInPastAndContinue(),
                    cancel: () => undoSkipCheckForChanges()
                });
                setIsDontShowThisMessageAgainVisible(true);
                setIsConfirmDialogVisible(true);
            }
            else {
                checkBalanceSheetDateInPastAndContinue();
            }

            dispatchNotification({ remove: true, pageId: pageId });
        }
    };

    const isFormValid = () => {
        const isCurrencyValid = currencyValidator(currency ? currency.value : currency) === "";
        const isInventoryTypeValid = inventoryTypeValidator(inventoryType) === "";
        const isBalanceSheetDateValid = BalanceSheetDateValidator(balanceSheetDate) === "";
        return isCurrencyValid && isInventoryTypeValid && isBalanceSheetDateValid;
    };

    const SetContinueBtnDisabled = (pCurrency, pInventoryType, pBalanceSheetDate) => {
        const backgroundDataValid = warehouseLocation && selectedPopulation;
        
        const isCurrencyValid = currencyValidator(pCurrency ? pCurrency.value : pCurrency) === "";
        const isInventoryTypeValid = inventoryTypeValidator(pInventoryType) === "";
        const isBalanceSheetDateValid = BalanceSheetDateValidator(pBalanceSheetDate) === "";
        const inputDataValid = isInventoryTypeValid && isCurrencyValid && isBalanceSheetDateValid;
        
        props.setContinueBtnDisabled((backgroundDataValid && inputDataValid) || userContext.userGlobal.userInfo.isController);
    };

    const saveInventoryParameters = () => {
        const inventoryParameters = {
            inventoryType: inventoryType.value,
            populationName: selectedPopulation.populationName,
            balanceSheetDate: new Date(Date.UTC(balanceSheetDate.getFullYear(), balanceSheetDate.getMonth(), balanceSheetDate.getDate())),
            reportCurrency: currency.text,
            reportLanguage: warehouseLocation?.language,
            countryCode: warehouseLocation?.countryCode
        };

        incrementLoadingCount();
        setInventoryParameters(
            selectedPopulation.populationId,
            inventoryParameters,
            response => {
                decrementLoadingCount();
                if (response.succeeded) {
                    wizzardContext.dispatchWizzardGlobal({
                        type: WIZZARD_ACTIONS.SetDataForSupport, payload: {
                            SupportData: {
                                ...inventoryParameters,
                                customerName: currentCustomer ? currentCustomer.customerName : '',
                                warehouseLocationName: warehouseLocation ? warehouseLocation.warehouseLocationName : '',
                                populationName: selectedPopulation?.populationName ?? '',
                                inventoryType: inventoryType.value
                            }
                        }
                    });
                    dispatch(selectPopulation(selectedPopulation));
                    trigger(wizardEvents.stepFinalized, {});
                }
                else {
                    dispatchNotification({
                        type: NotificationTypes.error,
                        message: response.message
                    });
                }
            },
            handleError);

    };

    useEffect(() => {
        on(wizardEvents.continueButtonClicked, handleContinueButtonClicked);

        return () => {
            off(wizardEvents.continueButtonClicked, handleContinueButtonClicked);
        }
    }, [handleContinueButtonClicked]);

    useEffect(() => {
        return () => {
            setLoading(false);
            cancelTokenSource.cancel();
        };
    }, []);

    useEffect(() => {
        const shouldSkipCheckForChanges = cookies[cookieNameSkipCheckForChanges] === cookieValueSkipCheckForChanges;
        setSkipCheckForChanges(shouldSkipCheckForChanges);
    }, []);

    useEffect(() => {
        const isBalanceSheetDateValid = BalanceSheetDateValidator(defaultBalanceSheetDate) === "";
        if (isBalanceSheetDateValid) {
            showLegalBalanceSheetDateNotification(defaultBalanceSheetDate);
        }
    }, []);

    const handleSkipCheckForChangesChange = (event) => {
        if (event.value) {
            setCookie(cookieNameSkipCheckForChanges, cookieValueSkipCheckForChanges, { path: '/' });
        }
        else {
            removeCookie(cookieNameSkipCheckForChanges, { path: '/' });
        }
        setSkipCheckForChanges(event.value);
    };

    const undoSkipCheckForChanges = () => {
        removeCookie(cookieNameSkipCheckForChanges, { path: '/' });
        setSkipCheckForChanges(false);
    };

    const showLegalBalanceSheetDateNotification = (value) => {
        dispatchNotification({ remove: true, pageId: pageId });

        if (value < minLegalBalanceSheetDate || value > maxLegalBalanceSheetDate) {
            dispatchNotification({
                type: NotificationTypes.info,
                pageId: pageId,
                message: intl.formatMessage({ id: "Preparation.LegalBalanceSheetDate" })
            });
        }
    };

    const [preparationInfo, setPreparationInfo] = React.useState(PreparationInfo.None);

    const showInfo = (column) => {
        setPreparationInfo(column);
    };

    return (
        <div>
            <Row className="wizard-content">
                <Col xs="9">
                    <Row>
                        <Col xs="6">
                            <div className="mb-3">
                                <div className="k-form-field">
                                    <p className="mapper-title"><FormattedMessage id='Preparation.InventoryType' /></p>
                                    <span className="mapper-info"
                                        onClick={() => showInfo(PreparationInfo.InventoryType)}
                                        onKeyPress={() => showInfo(PreparationInfo.InventoryType)}
                                    >
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                    </span>
                                    <DropDownList
                                        id={"inventoryTypeText"}
                                        name={"inventoryTypeText"}
                                        value={inventoryType}
                                        textField={"text"}
                                        dataItemKey={"value"}
                                        data={inventoryTypes}
                                        onChange={handleInventoryTypeChange}
                                        disabled={userContext.userGlobal.userInfo.isController}
                                    />
                                    <span className="k-file-validation-message k-text-error">{inventoryTypeValidator(inventoryType)}</span>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="k-form-field">
                                    <p className="mapper-title"><FormattedMessage id='Preparation.Currency' /></p>
                                    <span className="mapper-info"
                                        onClick={() => showInfo(PreparationInfo.Currency)}
                                        onKeyPress={() => showInfo(PreparationInfo.Currency)}
                                    >
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                    </span>
                                    <AutoComplete
                                        id={"currency"}
                                        name={"currency"}
                                        value={currencyName}
                                        data={currencies}
                                        onChange={handleCurrencyChange}
                                        disabled={userContext.userGlobal.userInfo.isController}
                                        required
                                    />
                                    <span className="k-file-validation-message k-text-error">{currencyValidator(currencyName)}</span>
                                </div>
                            </div>
                            <LocalizationProvider language={props.language}>
                                <IntlProvider locale={props.locale}>
                                    <div className="mb-3" id="BalanceSheetDateContainer">
                                        <div className={"k-form-field-wrap"}>
                                            <p className="mapper-title"><FormattedMessage id='Preparation.BalanceSheetDate' /></p>
                                            <span className="mapper-info"
                                                onClick={() => showInfo(PreparationInfo.BalanceSheetDate)}
                                                onKeyPress={() => showInfo(PreparationInfo.BalanceSheetDate)}
                                            >
                                                <FontAwesomeIcon icon={faInfoCircle} />
                                            </span>
                                            <DatePicker
                                                id={"BalanceSheetDate"}
                                                name={"BalanceSheetDate"}
                                                min={minBalanceSheetDate}
                                                max={maxBalanceSheetDate}
                                                onChange={handleBalanceSheetDateChange}
                                                value={balanceSheetDate}
                                                clearButton={false}
                                                required
                                                popupSettings={{ appendTo: document.getElementById("BalanceSheetDateContainer") }}
                                                disabled={userContext.userGlobal.userInfo.isController}
                                            />
                                            <span className="k-file-validation-message k-text-error">{BalanceSheetDateValidator(balanceSheetDate)}</span>
                                        </div>
                                    </div>
                                </IntlProvider>
                            </LocalizationProvider>
                        </Col>
                    </Row>
                </Col>
                <Col xs="3">
                    {preparationInfo !== PreparationInfo.None &&
                        (<Card className="card-chart" >
                            <CardBody >
                                {preparationInfo === PreparationInfo.None && (
                                    <p><FormattedMessage id='PreparationInfo.None' /></p>
                                )}
                                {preparationInfo === PreparationInfo.WarehouseLocation && (
                                    <p><FormattedMessage id='PreparationInfo.WarehouseLocation' /></p>
                                )}
                                {preparationInfo === PreparationInfo.Population && (
                                    <p><FormattedMessage id='PreparationInfo.Population' /></p>
                                )}
                                {preparationInfo === PreparationInfo.Currency && (
                                    <p><FormattedMessage id='PreparationInfo.Currency' /></p>
                                )}
                                {preparationInfo === PreparationInfo.InventoryType && (
                                    <span>
                                        <p><FormattedMessage id='PreparationInfo.InventoryType' /></p>
                                        {inventoryType.value === window.enumInventoryType.PerpetualInventorySample && (
                                            <p><FormattedMessage id='PreparationInfo.InventoryTypePerpetual' /></p>
                                        )}
                                        {inventoryType.value === window.enumInventoryType.PeriodicInventorySample && (
                                            <p><FormattedMessage id='PreparationInfo.InventoryTypePeriodic' /></p>
                                        )}
                                        {inventoryType.value === window.enumInventoryType.CompleteCount && (
                                            <p><FormattedMessage id='PreparationInfo.InventoryTypeCompleteCount' /></p>
                                        )}
                                    </span>
                                )}
                                {preparationInfo === PreparationInfo.BalanceSheetDate && (
                                    <p><FormattedMessage id='PreparationInfo.BalanceSheetDate' /></p>
                                )}
                            </CardBody>
                        </Card>)}
                </Col>
            </Row>

            <ConfirmDialog
                visible={isConfirmDialogVisible || isBalanceSheetDateInPastVisible}
                onClose={handleConfirmDialogClose}
                negative={<FormattedMessage id="Control.Cancel" />}
                positive={<FormattedMessage id="Control.Confirm" />}
                title={confirmDialogTitle}
                detail={confirmDialogDetail}
            >
                {(isDontShowThisMessageAgainVisible && !isBalanceSheetDateInPastVisible) && <Checkbox
                    label={<FormattedMessage id="Preparation.DontShowThisMessageAgain" />}
                    value={skipCheckForChanges}
                    onChange={handleSkipCheckForChangesChange}
                />}
            </ConfirmDialog>

        </div>
    )
}

export default Preparation
