/******************************************
 *  Author : Dr. Hanno Georg   
 *  Created On : Fri Sep 03 2021
 *  File : jsonUtils.js
 *******************************************/

 export const prettifyJson = (value) => {
    return JSON.stringify(value, null, 2);
}

export const deepClone = (value) => {
    if (value) {
        return JSON.parse(JSON.stringify(value));
    }
    return null;
}
