import { NotificationGroup } from '@progress/kendo-react-notification';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Alert, Row, Col } from 'reactstrap';
import { useCookies } from 'react-cookie';
import PrivacyPolicyDialog from './PrivacyPolicyDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCookieBite } from '@fortawesome/pro-light-svg-icons'

function CookieConsent(props) {
    const defaultCookieConsentName = "CookieConsent";
    const defaultConsentExpirationSeconds = 3600 * 24 * 365; // 1 year

    const [cookies, setCookie] = useCookies([defaultCookieConsentName]);
    const [showConsent, setShowConsent] = useState(false);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

    React.useEffect(() => {
        setShowConsent(!cookies[defaultCookieConsentName]);
    }, []);
    const handleSubmit = () => {
        setCookie(defaultCookieConsentName, "Accepted", { path: '/', maxAge: defaultConsentExpirationSeconds });
        setShowConsent(false);
    };

    const privacyPolicyClicked = () => {
        setShowPrivacyPolicy(true);
    }

    const privacyPolicyAccepted = (accepted) => {
        setShowPrivacyPolicy(false);
        if (accepted) {
            handleSubmit();
        }
    }

    return (
        <div>
            {showConsent
                &&
                <NotificationGroup>
                    <Alert color="info" className="cookie-consent">
                        <Row>
                            <Col xs="3" className="alert-icon align-items-center"><FontAwesomeIcon icon={faCookieBite} /></Col>
                            <Col xs="9">
                                <p>
                                    <FormattedMessage id='CookieConsent.ThisApplicationUsesCookies' />.
                                </p>
                                <div className="k-form-buttons k-justify-content-stretch">
                                    <Button
                                        color="secondary"
                                        href="https://www.remira.com/en/privacy-policy"
                                        target="_blank">
                                        <span>
                                            <FormattedMessage id='CookieConsent.PrivacyPolicy' />
                                        </span>
                                    </Button>
                                    <Button
                                        color="primary"
                                        onClick={handleSubmit}
                                    >
                                        <FormattedMessage id='CookieConsent.Accept' />
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Alert>
                    <PrivacyPolicyDialog visible={showPrivacyPolicy} onClose={privacyPolicyAccepted} />
                </NotificationGroup>}
        </div>
    );
}

export default CookieConsent
