import * as React from "react";
import { Col, Row, Card, CardBody, Nav, NavItem, NavLink } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { HashLink, NavHashLink } from 'react-router-hash-link';


const Help = () => {

    return (
        <div className="content">
            <Row>
                <Col xs="12">
                    <h1>
                        Online Help
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col xs="9">                    
                    <section id="section-one">
                        <Card>
                            <CardBody>
                                <h2>Title H2</h2>
                                <h3>Title H3</h3>
                                <p> Section 1 Content</p>
                            </CardBody>
                        </Card>                        
                    </section>
                    <section id="section-two">
                        <Card>
                            <CardBody>
                                <h2>Title H2</h2>
                                <h3 id="2-1">Title H3</h3>
                                <p id="2-2"> Section 2 Content</p>
                            </CardBody>
                        </Card>                        
                    </section>
                    <section id="section-three">
                        <Card>
                            <CardBody>
                                <h2>Title H2</h2>
                                <h3>Title H3</h3>
                                <p> Section 3 Content</p>
                            </CardBody>
                        </Card>
                    </section>
                    <section id="section-four">
                        <Card>
                            <CardBody>
                                <h2>Title H2</h2>
                                <h3>Title H3</h3>
                                <p> Section 4 Content</p>
                            </CardBody>
                        </Card>
                    </section>
                </Col>
                <Col xs="3">
                    <section id="help-nav">
                        <Nav vertical>
                            <NavItem>
                                <HashLink smooth to={'#section-one'}>
                                    Section One
                                </HashLink>
                            </NavItem>
                            <NavItem>
                                <HashLink smooth to={'#section-two'}>
                                    Section Two
                                </HashLink>
                                <ul>
                                    <li>
                                        <HashLink smooth to={'#2-1'}>
                                            2-1
                                        </HashLink>
                                    </li>
                                    <li>
                                        <HashLink smooth to={'#2-2'}>
                                            2-2
                                        </HashLink>
                                    </li>
                                </ul>
                            </NavItem>
                            <NavItem>
                                <HashLink smooth to={'#top'}>
                                    Top of Page
                                </HashLink>
                            </NavItem>
                        </Nav>
                    </section>
                </Col>
            </Row>
        </div>
    )
}

export default Help