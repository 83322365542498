import React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import  Footer  from './Footer';
import Sidebar from "./Sidebar";
import { useLoading } from "./Shared/LoadingContext";
import Loading from './Shared/Loading';
import CookieConsent from './CookieConsent/CookieConsent';
import { UserContext, UserReducer } from './UserContext';
import { checkSession } from '../services/dashboardService';

export const Layout = (props) => {
    const { loading } = useLoading();
    const MINUTE_MS = 180000;


    const [userGlobal, dispatchUserGlobal] = React.useReducer(UserReducer, {
        userInfo: {}
    });
    
  
    React.useEffect(() => {
        const interval = setInterval(() => {
            checkSession();
        }, MINUTE_MS);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])

    const userValue = React.useMemo(() => ({ userGlobal, dispatchUserGlobal }), [userGlobal, dispatchUserGlobal]);

 

    return (
        <UserContext.Provider value={userValue}>
            <div>
                <div className="wrapper">
                    <Sidebar toggleSidebar={props.toggleSidebar} />
                    <div className="main-panel">
                        {loading && <Loading />}
                        <NavMenu />
                        <Container>
                            {props.children}
                        </Container>
                        <br />
                        <br />
                        <CookieConsent>
                        </CookieConsent>
                        <Footer fluid />
                    </div>
                </div>
            </div>
        </UserContext.Provider>
    );
}
