import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Card, CardBody, CardHeader } from 'reactstrap';
import { Form, FormElement, Field } from '@progress/kendo-react-form';
import { FormRadioGroup, FormNumericTextBox, FormDropDownList, } from '../../Inventory/Wizard/form-components';
import { FormattedMessage } from 'react-intl';


export const DefaultInventoryDifference = 2
export const DefaultReliabilityLevel = 19;
export const DefaultSamplingError = 1;

const UploadDataQuality = (props) => {

    const RELIABILITY_LEVEL_SUFFIX = ' %';
    const [uploadedDataQualitySettings, setUploadedDataQualitySettings] = useState({});
    const uploadedDataQualityValidator = (value) => value != null ? "" : <FormattedMessage id="Settings.DataQualityRequired" />;

    const handleResetUploadedDataQualitySettings = () => {
        setUploadedDataQualitySettings({});
        setTimeout(() => resetUploadedDataQualitySettings());
    };

    const [isReliabilityLevelChanged, setReliabilityLevelChanged] = useState(false);
    const handleReliabilityLevelChange = (event) => {
        const isChanged = event.value.value !== uploadedDataQualitySettings.reliabilityLevel;
        setReliabilityLevelChanged(isChanged);
    };

    const [isInventoryDifferenceChanged, setInventoryDifferenceChanged] = useState(false);
    const handleInventoryDifferenceChange = (event) => {
        const isChanged = event.value !== uploadedDataQualitySettings.inventoryDifference;
        setInventoryDifferenceChanged(isChanged);
    };


    const [isUploadedDataQualityChanged, setIsUploadedDataQualityChanged] = useState(false);
    const handleUploadedDataQualityChange = (event) => {
        const isChanged = event.value !== uploadedDataQualitySettings.uploadedDataQuality;
        setIsUploadedDataQualityChanged(isChanged);
    };

    const [isSamplingErrorChanged, setSamplingErrorChange] = useState(false);
    const handleSamplingErrorChange = (event) => {
        const isChanged = event.value !== uploadedDataQualitySettings.samplingError;
        setSamplingErrorChange(isChanged);
    };

    const areChangesOnUploadedDataQuality = isInventoryDifferenceChanged || isReliabilityLevelChanged || isSamplingErrorChanged || isUploadedDataQualityChanged;

    useEffect(() => {
        props.setAreChangesOnUploadDataQuality(areChangesOnUploadedDataQuality);
    }, [areChangesOnUploadedDataQuality]);

    useEffect(() => {
        resetUploadedDataQualitySettings();
    }, [props.resetDataQuality])

    const handleSubmitUploadedDataQualitySettings = (dataItem) => {
        props.onSubmitUploadedDataQualitySettings(dataItem);
    };

    const resetUploadedDataQualitySettings = () => {
        let reliabilityObj = props.reliabilities.find(x => x.value === (props.settings.reliabilityLevel ?? DefaultReliabilityLevel));
        if (reliabilityObj) {
            reliabilityObj = {
                ...reliabilityObj,
                text: reliabilityObj.text + RELIABILITY_LEVEL_SUFFIX
            }
        }
        setUploadedDataQualitySettings({
            customerId: props.settings.customerId,
            uploadedDataQuality: props.settings.uploadedDataQuality,
            inventoryDifference: props.settings.inventoryDifference ?? DefaultInventoryDifference,
            reliabilityLevel: props.settings.reliabilityLevel ?? DefaultReliabilityLevel,
            reliabilityLevelObj: reliabilityObj,
            samplingError: props.settings.samplingError ?? DefaultSamplingError
        });

        setIsUploadedDataQualityChanged(false);
        setInventoryDifferenceChanged(false);
        setSamplingErrorChange(false);
        setReliabilityLevelChanged(false);
    };

    return (
        <Form
            onSubmit={handleSubmitUploadedDataQualitySettings}
            initialValues={uploadedDataQualitySettings}
            key={JSON.stringify(uploadedDataQualitySettings)}
            render={(formRenderProps) => (
                <FormElement>
                    <Card>
                        <CardHeader>
                            <h3><FormattedMessage id='Settings.DataQuality' /></h3>
                        </CardHeader>
                        <CardBody>
                            <Row id="settings">
                                <Col xs="4">
                                    <fieldset className={"k-form-fieldset"}>
                                        <div className="mb-3">
                                            <Field
                                                id={"uploadedDataQuality"}
                                                name={"uploadedDataQuality"}
                                                component={FormRadioGroup}
                                                data={props.dataQualities}
                                                validator={uploadedDataQualityValidator}
                                                onChange={handleUploadedDataQualityChange}
                                            />
                                        </div>
                                    </fieldset>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader>
                            <h3><FormattedMessage id='Settings.AverageDataQuality' /></h3>
                        </CardHeader>
                        <CardBody>
                            <Row id="settings">
                                <Col xs="4" >
                                    <Field
                                        id={"inventoryDifference"}
                                        name={"inventoryDifference"}
                                        format={'# \\\%'}
                                        label={<FormattedMessage id='Preparation.Difference' />}
                                        component={FormNumericTextBox}
                                        min={0}
                                        max={100}
                                        onChange={handleInventoryDifferenceChange}
                                    />
                                </Col>

                                <Col xs="4">
                                    <Field
                                        id={"reliabilityLevelObj"}
                                        name={"reliabilityLevelObj"}
                                        label={<FormattedMessage id='Preparation.Safety' />}
                                        component={FormDropDownList}
                                        textField={"text"}
                                        dataItemKey={"value"}
                                        suffix={RELIABILITY_LEVEL_SUFFIX}
                                        data={props.reliabilities}
                                        onChange={handleReliabilityLevelChange}
                                    />
                                </Col>
                                <Col xs="4">
                                    <Field
                                        id={"samplingError"}
                                        name={"samplingError"}
                                        format={'# \\\%'}
                                        label={<FormattedMessage id='Preparation.SamplingError' />}
                                        component={FormNumericTextBox}
                                        min={0}
                                        max={100}
                                        onChange={handleSamplingErrorChange} />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <span className="k-form-separator"></span>
                    <div className="float-right">
                        <Button
                            color="secondary"
                            disabled={!formRenderProps.allowSubmit || !formRenderProps.modified || !areChangesOnUploadedDataQuality}
                            onClick={handleResetUploadedDataQualitySettings}
                        >
                            <FormattedMessage id='Control.Reset' />
                        </Button>
                        <Button
                            type={"submit"}
                            color="primary"
                            disabled={!formRenderProps.allowSubmit || !formRenderProps.modified || !formRenderProps.valid || !areChangesOnUploadedDataQuality}
                        >
                            <FormattedMessage id='Control.Save' />
                        </Button>
                    </div>
                </FormElement>
            )}
        />
    );
}

export default UploadDataQuality