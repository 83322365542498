import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import SettingsTabs from './SettingsTabs';
import CustomerSelection from '../../Dashboard/CustomerSelection';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLoading } from '../../Shared/LoadingContext';
import { useNotification } from '../../Shared/Notifications/NotificationProvider';
import { useExceptionDialog } from '../../Shared/ExceptionDialog/ExceptionDialogProvider';
import { NotificationTypes } from '../../Shared/Notifications/Notification';
import {
    getCustomerSettings,
    getPopulations, getCurrencies, getBalanceSheetDates,
    saveCustomerPopulationSettings, saveCustomerUploadedDataQualitySettings, saveCustomerSequentialTestParameters
} from '../../../services/settingsService';
import { storeCustomer } from '../../../actions/dashboardActions';
import { UserContext } from '../../UserContext';
import { getReliabilities } from '../../../services/inventoryService';
import deMessages from "../../../language/kendo.de-DE.json";
import itMessages from "../../../language/kendo.it-IT.json";
import { loadMessages } from '@progress/kendo-react-intl';

loadMessages(deMessages, "de-DE");
loadMessages(itMessages, "it-IT");

export const ErrorWeight = {
    Percentage: 0,
    InitialSampleCount: 1
};

const defaultInitailPopulation = 'Central Warehouse';
const defaultPopulationDecimalSeparator = 1;
const defaultUploadedDataQuality = 0;
export const defaultConfidenceLevel = 95;
export const defaultErrorDefinition = 0;
export const defaultErrorProportionForRejectionThreshold = 20;
export const defaultErrorWeightInitialSampleCount = 200;
export const defaultErrorWeightPercentage = 0.0;

const defaultCustomerSettings = {
    customerId: '',
    populationInitialPopulationName: defaultInitailPopulation,
    populationCurrency: null,
    populationInventoryType: null,
    populationDecimalSeparator: defaultPopulationDecimalSeparator,
    populationBalanceSheetDate: null,
    uploadedDataQuality: defaultUploadedDataQuality,
    inventoryDifference: null,
    reliabilityLevel: null,
    samplingError: null,
    confidenceLevel: defaultConfidenceLevel,
    errorDefinition: defaultErrorDefinition,
    errorProportionForRejectionThreshold: defaultErrorProportionForRejectionThreshold,
    errorWeightPercentage: defaultErrorWeightPercentage,
    errorWeightInitialSampleCount: null,
    toleranceDescriptionCategory1: null,
    toleranceThresholdCategory1: null,
    toleranceDescriptionCategory2: null,
    toleranceThresholdCategory2: null,
    toleranceDescriptionCategory3: null,
    toleranceThresholdCategory3: null,
    toleranceDescriptionCategory4: null,
    toleranceThresholdCategory4: null,
    toleranceDescriptionCategory5: null,
    toleranceThresholdCategory5: null,
    toleranceDescriptionCategory6: null,
    toleranceThresholdCategory6: null,
    toleranceDescriptionCategory7: null,
    toleranceThresholdCategory7: null,
    toleranceDescriptionCategory8: null,
    toleranceThresholdCategory8: null,
    toleranceDescriptionCategory9: null,
    toleranceThresholdCategory9: null,
    quantityDeviationUnits: null,
    quantityDeviationOperator: null,
    quantityDeviationPercentage: null,
    valueDeviation: null,
    deviationOperator: null
};

const Settings = () => {
    const intl = useIntl();
    const currentCustomer = useSelector(state => state.dashboard.customer);
    const dispatch = useDispatch();

    const { setLoading } = useLoading();
    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const pageId = 'Settings';
    const [nextCustomerSelection, setNextCustomerSelection] = React.useState(null);

    const userContext = React.useContext(UserContext);
    const [currenciesItems, setCurrenciesItems] = useState({});
    const [customerSettings, setCustomerSettings] = useState(defaultCustomerSettings);

    useEffect(() => {
        return () => {
            removeNotification();
        };
    }, []);

    useEffect(() => {
        if (!currentCustomer && userContext.userGlobal.userInfo.accessibleCustomers && userContext.userGlobal.userInfo.accessibleCustomers.length > 0) {
            const defaultCustomer = userContext.userGlobal.userInfo.accessibleCustomers.find(x => x.customerId === userContext.userGlobal.userInfo.customerId);
            if (defaultCustomer) {
                dispatch(storeCustomer(defaultCustomer));
            }
        }
    }, [userContext.userGlobal.userInfo]);

    const setCustomerSettingsOrDefaultValues = custSettings => {
        decrementLoadingCount();

        const settings = {
            ...custSettings,
            populationInitialPopulationName: custSettings.populationInitialPopulationName ? custSettings.populationInitialPopulationName : defaultInitailPopulation,
            populationDecimalSeparator: custSettings.populationDecimalSeparator != null ? custSettings.populationDecimalSeparator : defaultPopulationDecimalSeparator,
            uploadedDataQuality: custSettings.uploadedDataQuality != null ? custSettings.uploadedDataQuality : defaultUploadedDataQuality,
            populationCurrency: custSettings.populationCurrencyName,
            confidenceLevel: custSettings.confidenceLevel != null ? custSettings.confidenceLevel : defaultConfidenceLevel,
            errorDefinition: custSettings.errorDefinition != null ? custSettings.errorDefinition : defaultErrorDefinition,
            errorProportionForRejectionThreshold: custSettings.errorProportionForRejectionThreshold != null ? custSettings.errorProportionForRejectionThreshold : defaultErrorProportionForRejectionThreshold,
            errorWeightPercentage: custSettings.errorWeightInitialSampleCount == null ? (custSettings.errorWeightPercentage ?? defaultErrorWeightPercentage) : null
        };
        setCustomerSettings(settings);
    }

    useEffect(() => {
        if (!currentCustomer) {
            return;
        }

        removeNotification();
        incrementLoadingCount();
        getCustomerSettings(
            currentCustomer.customerId,
            setCustomerSettingsOrDefaultValues,
            handleError);
    }, [currentCustomer]);

    const [populations, setPopulations] = useState([]);
    useEffect(() => {
        if (!currentCustomer) {
            return;
        }

        incrementLoadingCount();
        getPopulations(
            currentCustomer.customerId,
            pops => {
                decrementLoadingCount();
                setPopulations(pops);
            },
            handleError);
    }, [currentCustomer]);

    const [reliabilities, setReliabilities] = useState([]);
    useEffect(() => {
        incrementLoadingCount();
        getReliabilities(
            result => {
                decrementLoadingCount();
                let rel = [];

                for (let i = 0; i < result.length; i++) {
                    let currElement = {};
                    currElement.value = i;
                    currElement.text = result[i];
                    rel.push(currElement);
                }

                setReliabilities(rel);
            },
            handleError);
    }, []);

    const [currencies, setCurrencies] = useState([]);
    useEffect(() => {
        incrementLoadingCount();
        getCurrencies(
            currs => {
                decrementLoadingCount();
                setCurrenciesItems(currs);
                let data = currs.map(element => element.text);
                setCurrencies(data);
            },
            handleError);
    }, []);

    const [balanceSheetDates, setBalanceSheetDates] = useState([]);
    useEffect(() => {
        incrementLoadingCount();
        getBalanceSheetDates(
            balSheetDates => {
                decrementLoadingCount();
                setBalanceSheetDates(balSheetDates);
            },
            handleError);
    }, []);

    const inventoryTypes = [
        { value: window.enumInventoryType.PeriodicInventorySample, text: intl.formatMessage({ id: 'InventoryType.PeriodicInventorySample' }), isDefault: true },
        { value: window.enumInventoryType.PerpetualInventorySample, text: intl.formatMessage({ id: 'InventoryType.PerpetualInventorySample' }) },
        { value: window.enumInventoryType.CompleteCount, text: intl.formatMessage({ id: 'InventoryType.CompleteCount' }) }
    ];

    const decimalSeparators = [
        { value: 0, text: intl.formatMessage({ id: 'DecimalSeparator.Point' }) },
        { value: 1, text: intl.formatMessage({ id: 'DecimalSeparator.Comma' }) }
    ];

    const dataQualities = [
        { value: window.enumInventoryMethod.Stasam, label: intl.formatMessage({ id: 'DataQualities.Average' }), disabled: false },
        { value: window.enumInventoryMethod.Staseq, label: intl.formatMessage({ id: 'DataQualities.High' }), disabled: false }
    ];

    const errorDefinitions = [
        { value: window.enumErrorDefinition.AnyDifferenceIsError, label: intl.formatMessage({ id: "Settings.TestParams.ErrorDefinition.AnyDiffError" }) },
        { value: window.enumErrorDefinition.AcceptTolerableDeviations, label: intl.formatMessage({ id: "Settings.TestParams.ErrorDefinition.AcceptDeviations" }) }
    ];

    const errorWeights = [
        { value: ErrorWeight.Percentage, label: intl.formatMessage({ id: "Settings.TestParams.ErrorWeight.Percentage" }) },
        { value: ErrorWeight.InitialSampleCount, label: intl.formatMessage({ id: "Settings.TestParams.ErrorWeight.InitialSampleCount" }) }
    ];

    const logicalOperators = [
        { value: window.enumLogicalOperator.And, label: intl.formatMessage({ id: 'Settings.TestParams.LogicalOperator.And' }) },
        { value: window.enumLogicalOperator.Or, label: intl.formatMessage({ id: 'Settings.TestParams.LogicalOperator.Or' }) }
    ];

    const [loadingCount, setLoadingCount] = useState(0);
    useEffect(() => {
        setLoading(loadingCount > 0);
    }, [loadingCount]);

    const incrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount + 1);
    };

    const decrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount - 1);
    };

    const handleError = (errorMessage, showNotif = true) => {
        decrementLoadingCount();
        if (showNotif) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            });
        }
    };

    const removeNotification = () => {
        dispatchNotification({
            remove: true,
            pageId: pageId
        });
    };

    const handleSubmitPopulationSettings = (dataItem) => {
        let currency = currenciesItems.filter((item) => item.text === dataItem.populationCurrency)[0];
        const populationSettings = {
            customerId: customerSettings.customerId,
            initialPopulationName: dataItem.populationInitialPopulationName,
            currency: currency.value,
            inventoryType: dataItem.populationInventoryType.value,
            decimalSeparator: dataItem.populationDecimalSeparator.value,
            balanceSheetDate: dataItem.populationBalanceSheetDate,
        };

        removeNotification();
        incrementLoadingCount();
        saveCustomerPopulationSettings(
            populationSettings,
            () => {
                decrementLoadingCount();
                dispatchNotification({
                    pageId: pageId,
                    type: NotificationTypes.success,
                    message: <FormattedMessage id='Settings.SavePopulationSettingsSuccess' />
                });
                setCustomerSettings({
                    ...customerSettings,
                    populationInitialPopulationName: dataItem.populationInitialPopulationName,
                    populationCurrency: dataItem.populationCurrency,
                    populationInventoryType: dataItem.populationInventoryType.value,
                    populationDecimalSeparator: dataItem.populationDecimalSeparator.value,
                    populationBalanceSheetDate: dataItem.populationBalanceSheetDate,
                });
            },
            handleError);
    };

    const handleSubmitUploadedDataQualitySettings = (dataItem) => {

        let uploadedDataQualitySettings = {
            customerId: customerSettings.customerId,
            uploadedDataQuality: dataItem.uploadedDataQuality,
            inventoryDifference: dataItem.inventoryDifference,
            reliabilityLevel: dataItem.reliabilityLevelObj.value,
            samplingError: dataItem.samplingError
        };

        removeNotification();
        incrementLoadingCount();
        saveCustomerUploadedDataQualitySettings(
            uploadedDataQualitySettings,
            () => {
                decrementLoadingCount();
                dispatchNotification({
                    pageId: pageId,
                    type: NotificationTypes.success,
                    message: <FormattedMessage id='Settings.SaveUploadedDataQualitySettingsSuccess' />
                });
                setCustomerSettings({
                    ...customerSettings,
                    uploadedDataQuality: dataItem.uploadedDataQuality,
                    inventoryDifference: dataItem.inventoryDifference,
                    reliabilityLevel: dataItem.reliabilityLevelObj.value,
                    samplingError: dataItem.samplingError
                });
            },
            handleError);
    };

    const updateToleranceDescriptions = (testParams, dataItem) => {
        const tolDescCat1 = dataItem.isToleranceCategory1Enabled ? dataItem.toleranceDescriptionCategory1 : testParams.toleranceDescriptionCategory1;
        const tolDescCat2 = dataItem.isToleranceCategory2Enabled ? dataItem.toleranceDescriptionCategory2 : testParams.toleranceDescriptionCategory2;
        const tolDescCat3 = dataItem.isToleranceCategory3Enabled ? dataItem.toleranceDescriptionCategory3 : testParams.toleranceDescriptionCategory3;
        const tolDescCat4 = dataItem.isToleranceCategory4Enabled ? dataItem.toleranceDescriptionCategory4 : testParams.toleranceDescriptionCategory4;
        const tolDescCat5 = dataItem.isToleranceCategory5Enabled ? dataItem.toleranceDescriptionCategory5 : testParams.toleranceDescriptionCategory5;
        const tolDescCat6 = dataItem.isToleranceCategory6Enabled ? dataItem.toleranceDescriptionCategory6 : testParams.toleranceDescriptionCategory6;
        const tolDescCat7 = dataItem.isToleranceCategory7Enabled ? dataItem.toleranceDescriptionCategory7 : testParams.toleranceDescriptionCategory7;
        const tolDescCat8 = dataItem.isToleranceCategory8Enabled ? dataItem.toleranceDescriptionCategory8 : testParams.toleranceDescriptionCategory8;
        const tolDescCat9 = dataItem.isToleranceCategory9Enabled ? dataItem.toleranceDescriptionCategory9 : testParams.toleranceDescriptionCategory9;

        let testParamsWithToleranceDesc = {
            ...testParams,
            toleranceDescriptionCategory1: tolDescCat1,
            toleranceDescriptionCategory2: tolDescCat2,
            toleranceDescriptionCategory3: tolDescCat3,
            toleranceDescriptionCategory4: tolDescCat4,
            toleranceDescriptionCategory5: tolDescCat5,
            toleranceDescriptionCategory6: tolDescCat6,
            toleranceDescriptionCategory7: tolDescCat7,
            toleranceDescriptionCategory8: tolDescCat8,
            toleranceDescriptionCategory9: tolDescCat9,
        }
        return testParamsWithToleranceDesc;
    };

    const updateToleranceThresholds = (testParams, dataItem) => {
        const tolThrshldCat1 = dataItem.isToleranceCategory1Enabled ? dataItem.toleranceThresholdCategory1 : testParams.toleranceThresholdCategory1;
        const tolThrshldCat2 = dataItem.isToleranceCategory2Enabled ? dataItem.toleranceThresholdCategory2 : testParams.toleranceThresholdCategory2;
        const tolThrshldCat3 = dataItem.isToleranceCategory3Enabled ? dataItem.toleranceThresholdCategory3 : testParams.toleranceThresholdCategory3;
        const tolThrshldCat4 = dataItem.isToleranceCategory4Enabled ? dataItem.toleranceThresholdCategory4 : testParams.toleranceThresholdCategory4;
        const tolThrshldCat5 = dataItem.isToleranceCategory5Enabled ? dataItem.toleranceThresholdCategory5 : testParams.toleranceThresholdCategory5;
        const tolThrshldCat6 = dataItem.isToleranceCategory6Enabled ? dataItem.toleranceThresholdCategory6 : testParams.toleranceThresholdCategory6;
        const tolThrshldCat7 = dataItem.isToleranceCategory7Enabled ? dataItem.toleranceThresholdCategory7 : testParams.toleranceThresholdCategory7;
        const tolThrshldCat8 = dataItem.isToleranceCategory8Enabled ? dataItem.toleranceThresholdCategory8 : testParams.toleranceThresholdCategory8;
        const tolThrshldCat9 = dataItem.isToleranceCategory9Enabled ? dataItem.toleranceThresholdCategory9 : testParams.toleranceThresholdCategory9;

        let testParamsWithToleranceThresholds = {
            ...testParams,
            toleranceThresholdCategory1: tolThrshldCat1,
            toleranceThresholdCategory2: tolThrshldCat2,
            toleranceThresholdCategory3: tolThrshldCat3,
            toleranceThresholdCategory4: tolThrshldCat4,
            toleranceThresholdCategory5: tolThrshldCat5,
            toleranceThresholdCategory6: tolThrshldCat6,
            toleranceThresholdCategory7: tolThrshldCat7,
            toleranceThresholdCategory8: tolThrshldCat8,
            toleranceThresholdCategory9: tolThrshldCat9,
        }
        return testParamsWithToleranceThresholds;
    };

    const updateDeviations = (testParams, dataItem) => {
        const qntDevUnits = dataItem.isQuantityDeviationUnitsEnabled ? dataItem.quantityDeviationUnits : testParams.quantityDeviationUnits;
        const qntDevOp = dataItem.isQuantityDeviationUnitsEnabled && dataItem.isQuantityDeviationPercentageEnabled ? dataItem.quantityDeviationOperatorObj?.value : testParams.quantityDeviationOperator;
        const qntPerc = dataItem.isQuantityDeviationPercentageEnabled ? dataItem.quantityDeviationPercentage : testParams.quantityDeviationPercentage;

        const devOp = (dataItem.isQuantityDeviationUnitsEnabled || dataItem.isQuantityDeviationPercentageEnabled) && dataItem.isValueDeviationEnabled ? dataItem.deviationOperatorObj?.value : testParams.deviationOperator;
        const valDev = dataItem.isValueDeviationEnabled ? dataItem.valueDeviation : testParams.valueDeviation;

        let testParamsWithTolerance = {
            ...testParams,
            quantityDeviationUnits: qntDevUnits,
            quantityDeviationOperator: qntDevOp,
            quantityDeviationPercentage: qntPerc,
            deviationOperator: devOp,
            valueDeviation: valDev
        }
        return testParamsWithTolerance;
    };

    const buildTestParams = (dataItem) => {
        const errWeightPercentage = dataItem.errorWeight === ErrorWeight.Percentage ? dataItem.errorWeightPercentage : null;
        const errWeightInitSampleCnt = dataItem.errorWeight === ErrorWeight.InitialSampleCount ? dataItem.errorWeightInitialSampleCount : null;

        let testParams = {
            customerId: customerSettings.customerId,
            confidenceLevel: dataItem.confidenceLevel,
            errorDefinition: dataItem.errorDefinition,
            errorProportionForRejectionThreshold: dataItem.errorProportionForRejectionThreshold,
            errorWeightPercentage: errWeightPercentage,
            errorWeightInitialSampleCount: errWeightInitSampleCnt
        };

        const areTolerableDeviationsEnabled = dataItem.errorDefinition === window.enumErrorDefinition.AcceptTolerableDeviations;

        if (areTolerableDeviationsEnabled) {
            testParams = updateToleranceDescriptions(testParams, dataItem);
            testParams = updateToleranceThresholds(testParams, dataItem);
            testParams = updateDeviations(testParams, dataItem);
        }

        return testParams;
    };

    const handleSubmitTestParams = (dataItem) => {
        const testParams = buildTestParams(dataItem);

        removeNotification();
        incrementLoadingCount();
        saveCustomerSequentialTestParameters(
            testParams,
            () => {
                decrementLoadingCount();
                dispatchNotification({
                    pageId: pageId,
                    type: NotificationTypes.success,
                    message: <FormattedMessage id='Settings.SaveTestParamsSuccess' />
                });
                setCustomerSettings({
                    ...customerSettings,
                    confidenceLevel: dataItem.confidenceLevel,
                    errorDefinition: dataItem.errorDefinition,
                    errorProportionForRejectionThreshold: dataItem.errorProportionForRejectionThreshold,
                    errorWeightPercentage: dataItem.errorWeight === ErrorWeight.Percentage ? dataItem.errorWeightPercentage : null,
                    errorWeightInitialSampleCount: dataItem.errorWeight === ErrorWeight.InitialSampleCount ? dataItem.errorWeightInitialSampleCount : null,
                    toleranceDescriptionCategory1: testParams.toleranceDescriptionCategory1,
                    toleranceThresholdCategory1: testParams.toleranceThresholdCategory1,
                    toleranceDescriptionCategory2: testParams.toleranceDescriptionCategory2,
                    toleranceThresholdCategory2: testParams.toleranceThresholdCategory2,
                    toleranceDescriptionCategory3: testParams.toleranceDescriptionCategory3,
                    toleranceThresholdCategory3: testParams.toleranceThresholdCategory3,
                    toleranceDescriptionCategory4: testParams.toleranceDescriptionCategory4,
                    toleranceThresholdCategory4: testParams.toleranceThresholdCategory4,
                    toleranceDescriptionCategory5: testParams.toleranceDescriptionCategory5,
                    toleranceThresholdCategory5: testParams.toleranceThresholdCategory5,
                    toleranceDescriptionCategory6: testParams.toleranceDescriptionCategory6,
                    toleranceThresholdCategory6: testParams.toleranceThresholdCategory6,
                    toleranceDescriptionCategory7: testParams.toleranceDescriptionCategory7,
                    toleranceThresholdCategory7: testParams.toleranceThresholdCategory7,
                    toleranceDescriptionCategory8: testParams.toleranceDescriptionCategory8,
                    toleranceThresholdCategory8: testParams.toleranceThresholdCategory8,
                    toleranceDescriptionCategory9: testParams.toleranceDescriptionCategory9,
                    toleranceThresholdCategory9: testParams.toleranceThresholdCategory9,
                    quantityDeviationUnits: testParams.quantityDeviationUnits,
                    quantityDeviationOperator: testParams.quantityDeviationOperator,
                    quantityDeviationPercentage: testParams.quantityDeviationPercentage,
                    valueDeviation: testParams.valueDeviation,
                    deviationOperator: testParams.deviationOperator
                });
            },
            handleError
        );
    };

    const onCustomerHandleChange = (event) => {
        setNextCustomerSelection(event.value)
    };

    return (
        <div className="content">
            <Row>
                <Col xs="12">
                    <h1>
                        <FormattedMessage id="Sidebar.Settings" />&nbsp;
                        <small><FormattedMessage id="Settings.Parameter" /></small>&nbsp;
                        {userContext.userGlobal.userInfo && (
                            <CustomerSelection
                                customer={currentCustomer}
                                customers={userContext.userGlobal.userInfo.accessibleCustomers}
                                isStatcontrolAdmin={userContext.userGlobal.userInfo.isStatcontrolAdmin}
                                handleChange={onCustomerHandleChange}
                            />
                        )}
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <SettingsTabs
                        settings={customerSettings}
                        populations={populations}
                        currencies={currencies}
                        inventoryTypes={inventoryTypes}
                        decimalSeparators={decimalSeparators}
                        balanceSheetDates={balanceSheetDates}
                        dataQualities={dataQualities}
                        reliabilities={reliabilities}
                        errorDefinitions={errorDefinitions}
                        errorWeights={errorWeights}
                        logicalOperators={logicalOperators}
                        defaultInitailPopulation={defaultInitailPopulation}
                        onSubmitPopulationSettings={handleSubmitPopulationSettings}
                        onSubmitUploadedDataQualitySettings={handleSubmitUploadedDataQualitySettings}
                        onSubmitTestParams={handleSubmitTestParams}
                        setNextCustomerSelection={setNextCustomerSelection}
                        nextCustomerSelection={nextCustomerSelection}
                        customer={currentCustomer}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default Settings
