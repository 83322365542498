import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row, Button, Card, CardBody } from 'reactstrap';
import { DropDownList, AutoComplete } from "@progress/kendo-react-dropdowns";
import { FormattedMessage, useIntl } from 'react-intl';
import { Input } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { filterBy } from "@progress/kendo-data-query";
import {
    IntlProvider,
    LocalizationProvider
} from "@progress/kendo-react-intl";

const PopulationSettings = (props) => {

    const intl = useIntl();
    const [populationSettings, setPopulationSettings] = useState({});

    const populationInitialPopulationNameValidator = (value) => {
        if (!value || value.trim().length == 0) {
            return intl.formatMessage({ id: "Settings.InitialPopulationRequired" });
        }

        if (value.length > 200) {
            return intl.formatMessage({ id: "Settings.InitialPopulationLong" });
        }

        return "";
    }

    const populationCurrencyValidator = (value) => {
        const curr = props.currencies.find(x => x === value)
        return value && curr ? "" : intl.formatMessage({ id: "Settings.CurrencyRequired" });
    }
    const populationInventoryTypeValidator = (value) => {
        return value ? "" : intl.formatMessage({ id: 'Settings.InventoryTypeRequired' });
    }

    const populationDecimalSeparatorValidator = (value) => value ? "" : intl.formatMessage({ id: 'Settings.DecimalSeparatorRequired' });
    const populationBalanceSheetDateValidator = (value) => value ? "" : intl.formatMessage({ id: 'Settings.BalanceSheetDateRequired' });

    const isPopulationSettingsValid =
        populationInitialPopulationNameValidator(populationSettings.populationInitialPopulationName) === "" &&
        populationCurrencyValidator(populationSettings.populationCurrency) === "" &&
        populationInventoryTypeValidator(populationSettings.populationInventoryType) === "" &&
        populationDecimalSeparatorValidator(populationSettings.populationDecimalSeparator) === "" &&
        populationBalanceSheetDateValidator(populationSettings.populationBalanceSheetDate) === "";

    const [isPopulationInitialPopulationNameChanged, setPopulationInitialPopulationNameChanged] = useState(false);
    const handlePopulationInitialPopulationNameChange = (event) => {
        const isChanged = event.value !== populationSettings.populationInitialPopulationName;
        setPopulationSettings({
            ...populationSettings,
            populationInitialPopulationName: event.value.trim() == "" ? props.defaultInitailPopulation : event.value
        });
        setPopulationInitialPopulationNameChanged(isChanged);
    };

    const [populationCurrencies, setPopulationCurrencies] = React.useState(props.currencies);
    const [isPopulationCurrencyChanged, setIsPopulationCurrencyChanged] = useState(false);
    const handlePopulationCurrencyChange = (event) => {
        const isChanged = event.value !== populationSettings.populationCurrency;
        setPopulationSettings({
            ...populationSettings,
            populationCurrency: event.value
        });
        setIsPopulationCurrencyChanged(isChanged);
        setPopulationCurrencies(filterPopulationCurrency(event.value));
    };

    const filterPopulationCurrency = (value) => {
        const data = props.currencies;
        const filter = {
            value: value,
            operator: "contains",
            ignoreCase: true,
        };
        return value ? filterBy(data, filter) : data;
    };

    const [isPopulationInventoryTypeChanged, setIsPopulationInventoryTypeChanged] = useState(false);
    const handlePopulationInventoryTypeChange = (event) => {
        const isChanged = event.value !== populationSettings.populationInventoryType;
        setPopulationSettings({
            ...populationSettings,
            populationInventoryType: event.value
        });
        setIsPopulationInventoryTypeChanged(isChanged);
    };

    const [isPopulationDecimalSeparatorChanged, setIsPopulationDecimalSeparatorChanged] = useState(false);
    const handlePopulationDecimalSeparatorChange = (event) => {
        const isChanged = event.value !== populationSettings.populationDecimalSeparator;
        setPopulationSettings({
            ...populationSettings,
            populationDecimalSeparator: event.value
        });
        setIsPopulationDecimalSeparatorChanged(isChanged);
    };

    const [isPopulationBalanceSheetDateChanged, setIsPopulationBalanceSheetDateChanged] = useState(false);
    const handlePopulationBalanceSheetDateChange = (event) => {
        const isChanged = event.value !== populationSettings.populationBalanceSheetDate;
        setPopulationSettings({
            ...populationSettings,
            populationBalanceSheetDate: event.value
        });
        setIsPopulationBalanceSheetDateChanged(isChanged);
    };

    const areChangesOnPopulationSettings =
        isPopulationInitialPopulationNameChanged || isPopulationCurrencyChanged || isPopulationInventoryTypeChanged
        || isPopulationDecimalSeparatorChanged || isPopulationBalanceSheetDateChanged;

    useEffect(() => {
        props.setAreChangesOnPopulationSettings(areChangesOnPopulationSettings);
    }, [areChangesOnPopulationSettings]);

    useEffect(() => {
        setPopulationSettings({
            ...populationSettings,
            populationInventoryType: props.inventoryTypes.find(x => x.value === populationSettings?.populationInventoryType?.value) ?? null,
            populationDecimalSeparator: props.decimalSeparators.find(x => x.value === populationSettings?.populationDecimalSeparator?.value)
        });
    }, [props.inventoryTypes, props.decimalSeparators])

    const handleSubmitPopulationSettings = () => {
        props.onSubmitPopulationSettings(populationSettings);
    };

    const handleResetPopulationSettings = () => {
        resetPopulationSettings();
    };

    const resetPopulationSettings = useCallback(() => {
        setPopulationSettings({
            customerId: props.settings.customerId,
            populationInitialPopulationName: props.settings.populationInitialPopulationName,
            populationCurrency: props.settings.populationCurrency,
            populationInventoryType: props.inventoryTypes.find(x => x.value === props.settings.populationInventoryType) ?? null,
            populationDecimalSeparator: props.decimalSeparators.find(x => x.value === props.settings.populationDecimalSeparator),
            populationBalanceSheetDate: props.settings.populationBalanceSheetDate ? new Date(props.settings.populationBalanceSheetDate) : new Date(new Date(Date.now()).getFullYear(), 11, 31),
        });
        ValuesAreChanged();
    }, [props.settings]);

    const ValuesAreChanged = () => {
        setPopulationInitialPopulationNameChanged(false);
        setIsPopulationCurrencyChanged(false);
        setIsPopulationInventoryTypeChanged(false);
        setIsPopulationDecimalSeparatorChanged(false);
        setIsPopulationBalanceSheetDateChanged(false);
    }

    useEffect(() => {
        resetPopulationSettings();
    }, [resetPopulationSettings])

    return (
        <div>
            <Card>
                <CardBody>
                    <Row id="settings">
                        <Col xs="6">
                            <div className="mb-3">
                                <div className="drop-label"><FormattedMessage id="Settings.InitialPopulation" /></div>
                                <Input
                                    id={"populationInitialPopulationName"}
                                    name={"populationInitialPopulationName"}
                                    maxLength={200}
                                    value={populationSettings.populationInitialPopulationName}
                                    onChange={handlePopulationInitialPopulationNameChange}
                                />
                                <span className="k-file-validation-message k-text-error">{populationInitialPopulationNameValidator(populationSettings.populationInitialPopulationName)}</span>
                            </div>

                            <div className="mb-3">
                                <div className="drop-label"><FormattedMessage id="Settings.Currency" /></div>
                                <AutoComplete
                                    id={"populationCurrency"}
                                    name={"populationCurrency"}
                                    value={populationSettings.populationCurrency}
                                    data={populationCurrencies}
                                    validator={populationCurrencyValidator}
                                    onChange={handlePopulationCurrencyChange}
                                    placeholder={intl.formatMessage({ id: 'Settings.CurrencyPlaceholder' })}
                                    maxLength={200}
                                />
                                <span className="k-file-validation-message k-text-error">{populationCurrencyValidator(populationSettings.populationCurrency)}</span>
                            </div>

                            <div className="mb-3">
                                <div className="drop-label"><FormattedMessage id="Settings.InventoryType" /></div>
                                <DropDownList
                                    id={"populationInventoryType"}
                                    name={"populationInventoryType"}
                                    value={populationSettings.populationInventoryType}
                                    textField={"text"}
                                    dataItemKey={"value"}
                                    data={props.inventoryTypes}
                                    onChange={handlePopulationInventoryTypeChange}
                                />
                                <span className="k-file-validation-message k-text-error">{populationInventoryTypeValidator(populationSettings.populationInventoryType)}</span>
                            </div>
                        </Col>
                        <Col xs="6">
                            <div className="mb-3">
                                <div className="drop-label"><FormattedMessage id="Settings.DecimalSeparator" /></div>
                                <DropDownList
                                    id={"populationDecimalSeparator"}
                                    name={"populationDecimalSeparator"}
                                    value={populationSettings.populationDecimalSeparator}
                                    textField={"text"}
                                    dataItemKey={"value"}
                                    data={props.decimalSeparators}
                                    onChange={handlePopulationDecimalSeparatorChange}
                                />
                                <span className="k-file-validation-message k-text-error">{populationDecimalSeparatorValidator(populationSettings.populationDecimalSeparator)}</span>
                            </div>
                            <LocalizationProvider language={props.language}>
                                <IntlProvider locale={props.locale}>
                                    <div className="mb-3">
                                        <div className="drop-label"><FormattedMessage id="Settings.BalanceSheetDate" /></div>
                                        <div className={"k-form-field-wrap"}>
                                            <DatePicker
                                                value={populationSettings.populationBalanceSheetDate}
                                                id={"populationBalanceSheetDate"}
                                                name={"populationBalanceSheetDate"}
                                                format={"dd MMMM"}
                                                onChange={handlePopulationBalanceSheetDateChange}
                                                clearButton={false}
                                            />
                                            <span className="k-file-validation-message k-text-error">{populationBalanceSheetDateValidator(populationSettings.populationBalanceSheetDate)}</span>
                                        </div>
                                    </div>
                                </IntlProvider>
                            </LocalizationProvider>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <span className="k-form-separator"></span>
            <div className="float-right">
                <Button
                    color="secondary"
                    disabled={!areChangesOnPopulationSettings}
                    onClick={handleResetPopulationSettings}
                >
                    <FormattedMessage id='Control.Reset' />
                </Button>
                <Button
                    type={"submit"}
                    color="primary"
                    onClick={handleSubmitPopulationSettings}
                    disabled={!areChangesOnPopulationSettings || !isPopulationSettingsValid}
                >
                    <FormattedMessage id='Control.Save' />
                </Button>
            </div>
        </div>
    );
}

export default PopulationSettings