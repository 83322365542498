import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormDatePicker } from "../../Inventory/Wizard/form-components";
import { Col, Row, Button } from 'reactstrap';
import { FormattedMessage, useIntl } from "react-intl";
import ConfirmDialog from '../../Shared/ConfirmDialog';
import { getLocale } from '../../../utils/localization';
import { IndividualLicenseEditorState } from './ManageIndividualLicense';

const GTCAcceptedOnIndividualForm = (props) => {
    const intl = useIntl();
    const userProfile = useSelector(state => state.profile.profile);
    const locale = getLocale(userProfile.userLanguage);

    const isSaveDisabled = (formRenderProps) => {
        return !formRenderProps.allowSubmit || !formRenderProps.valid
    };

    const handleSubmit = (dataItem) => {
        props.onSubmit(dataItem);
    };

    const handleCancel = () => {
        if (areChanges) {
            setConfirmDialogTitle(<FormattedMessage id='Confirm.UnsavedChangesTitle' />);
            setConfirmDialogDetail(<FormattedMessage id='Confirm.UnsavedChangesText' />);
            setConfirmDialogCallback({
                success: () => {
                    props.cancelEdit();
                }
            });
            setIsConfirmDialogVisible(true);
        }
        else {
            props.cancelEdit();
        }
    };

    const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);
    const [confirmDialogTitle, setConfirmDialogTitle] = useState('');
    const [confirmDialogDetail, setConfirmDialogDetail] = useState('');
    const [confirmDialogCallback, setConfirmDialogCallback] = useState(null);

    const handleConfirmDialogClose = (isConfirmed) => {
        setIsConfirmDialogVisible(false);
        if (isConfirmed) {
            if (confirmDialogCallback) {
                confirmDialogCallback.success();
            }
        }
        else {
            if (confirmDialogCallback && confirmDialogCallback.cancel) {
                confirmDialogCallback.cancel();
            }
        }
    };

    const [isGTCAcceptedOnChanged, setIsGTCAcceptedOnChanged] = useState(false);
    const handleGTCAcceptedOnChange = (event) => {
        const isChanged = event.value !== props.item.gtcAcceptedOn;
        setIsGTCAcceptedOnChanged(isChanged);
    };

    const areChanges = isGTCAcceptedOnChanged;

    const title = intl.formatMessage({ id: "ManageLicense.SetGTCAcceptanceDate" }, { "Name": props.item.subscriptionTypeName });

    const isSavingEnabled = props.editorState === IndividualLicenseEditorState.Add || props.editorState === IndividualLicenseEditorState.Edit;

    return (
        <div>
            <Prompt
                when={areChanges}
                message={JSON.stringify({
                    header: "" + intl.formatMessage({ id: 'Confirm.UnsavedChangesTitle' }),
                    content: "" + intl.formatMessage({ id: 'Confirm.UnsavedChangesText' }),
                    cancel: "" + intl.formatMessage({ id: 'Control.Cancel' }),
                    confirm: "" + intl.formatMessage({ id: 'Control.Confirm' })
                })}
            />

            <Dialog
                title={title}
                onClose={handleCancel}
            >
                <Form
                    onSubmit={handleSubmit}
                    initialValues={props.item}
                    render={(formRenderProps) => (
                        <FormElement>
                            <fieldset className={"k-form-fieldset"}>
                                <Row>
                                    <Col xs="12">
                                        <div className="mb-3">
                                            <Field
                                                id={"gtcAcceptedOn"}
                                                name={"gtcAcceptedOn"}
                                                label={intl.formatMessage({ id: 'ManageLicense.GtcAcceptedOn' })}
                                                component={FormDatePicker}
                                                onChange={handleGTCAcceptedOnChange}
                                                disabled={props.editorState === IndividualLicenseEditorState.View}
                                                locale={locale}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </fieldset>

                            <DialogActionsBar>
                                <div className="k-form-buttons k-justify-content-stretch">
                                    <Button
                                        type={"button"}
                                        onClick={handleCancel}
                                        color="secondary"
                                    >
                                        <FormattedMessage id='Control.Close' />
                                    </Button>
                                    {isSavingEnabled &&
                                        <Button
                                            type={"submit"}
                                            color="primary"
                                            disabled={isSaveDisabled(formRenderProps)}
                                        >
                                            <FormattedMessage id='Control.Save' />
                                        </Button>
                                    }
                                </div>
                            </DialogActionsBar>
                        </FormElement>
                    )}
                />
            </Dialog>

            <ConfirmDialog
                visible={isConfirmDialogVisible}
                onClose={handleConfirmDialogClose}
                negative={<FormattedMessage id='Control.Cancel' />}
                positive={<FormattedMessage id='Control.Confirm' />}
                title={confirmDialogTitle}
                detail={confirmDialogDetail}
            />
        </div>
    );
};

export default GTCAcceptedOnIndividualForm;
