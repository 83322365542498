import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { useLoading } from '../Shared/LoadingContext';
import { useNotification } from '../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../Shared/Notifications/Notification';
import ConfirmDialog from '../Shared/ConfirmDialog';
import ManageLicense from './Subscription/ManageLicense';
import ManageIndividualLicense from './Subscription/ManageIndividualLicense';
import BlacklistCompany from './BlacklistCompany';
import CustomerOverview from './CustomerOverview';
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { executeGarbageCollector } from '../../services/supportService';
import { useExceptionDialog } from '../Shared/ExceptionDialog/ExceptionDialogProvider';
import ManageSupportDetails from './ManageSupportDetails';

import ExecuteScripts from './ExecuteScripts';
import ArticleGrouping from './ArticleGrouping';
import CustomerManager from './CustomerManager';
import ManageStatcontrolOptions from './ManageNLS';

export default function SupportTools() {
    const { setLoading } = useLoading();
    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const pageId = 'SupportTools';

    const [loadingCount, setLoadingCount] = useState(0);
    useEffect(() => {
        setLoading(loadingCount > 0);
    }, [loadingCount]);

    const incrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount + 1);
    };

    const decrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount - 1);
    };

    const handleError = (errorMessage, showNotif = true) => {
        decrementLoadingCount();
        if (showNotif) {
            dispatchNotification({
                type: NotificationTypes.error,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            })
        }
    };

    const handleExecuteGarbageCollector = () => {
        setConfirmDialogTitle(<FormattedMessage id='SupportTools.ConfirmationTitleGarbageCollector' />);
        setConfirmDialogDetail(<FormattedMessage id='SupportTools.ConfirmationTextGarbageCollector' />);
        setConfirmDialogCallback({
            success: () => doGarbageCollector()
        });
        setIsConfirmDialogVisible(true);
    };

    const doGarbageCollector = () => {
        incrementLoadingCount();
        executeGarbageCollector(
            () => {
                decrementLoadingCount();
                dispatchNotification({
                    type: NotificationTypes.success,
                    message: <FormattedMessage id='SupportTools.ExecuteGarbageCollectorSuccess' />
                });
            },
            handleError);
    };

    const [isConfirmDialogVisible, setIsConfirmDialogVisible] = React.useState(false);
    const [confirmDialogTitle, setConfirmDialogTitle] = React.useState('');
    const [confirmDialogDetail, setConfirmDialogDetail] = React.useState('');
    const [confirmDialogCallback, setConfirmDialogCallback] = React.useState(null);

    const handleConfirmDialogClose = (isConfirmed) => {
        setIsConfirmDialogVisible(false);
        if (isConfirmed) {
            if (confirmDialogCallback) {
                confirmDialogCallback.success();
            }
        }
    };

    const [selected, setSelected] = React.useState(0);

    const handleSelect = (e) => {
        dispatchNotification({
            removeOnPageChanges: true
        });
        setSelected(e.selected);
    };

    return (
        <div className="content">
            <Row>
                <Col xs="12">
                    <h1>
                        <FormattedMessage id='SupportTools.Title' />
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <TabStrip selected={selected} onSelect={handleSelect}>
                        <TabStripTab title={<FormattedMessage id='SupportTools.TitleExecuteSupportScript' />} >
                            <ExecuteScripts/>
                        </TabStripTab>
                        {/*<TabStripTab title={<FormattedMessage id='SupportTools.TitleGarbageCollector' />} >*/}
                        {/*    <span className="k-form-separator"></span>*/}
                        {/*    <div className="float-right">*/}
                        {/*        <Button*/}
                        {/*            color="primary"*/}
                        {/*            onClick={handleExecuteGarbageCollector}*/}
                        {/*        >*/}
                        {/*            <FormattedMessage id='SupportTools.ExecuteGarbageCollector' />*/}
                        {/*        </Button>*/}
                        {/*    </div>*/}

                        {/*    <ConfirmDialog*/}
                        {/*        visible={isConfirmDialogVisible}*/}
                        {/*        onClose={handleConfirmDialogClose}*/}
                        {/*        negative={<FormattedMessage id='Control.Cancel' />}*/}
                        {/*        positive={<FormattedMessage id='Control.Confirm' />}*/}
                        {/*        title={confirmDialogTitle}*/}
                        {/*        detail={confirmDialogDetail}*/}
                        {/*    />*/}
                        {/*</TabStripTab>*/}
                        <TabStripTab title={<FormattedMessage id='ManageLicense.Title' />} >
                            <ManageLicense />
                            <ManageIndividualLicense />
                        </TabStripTab>
                        <TabStripTab title={<FormattedMessage id='ManageLicense.ManageSupportDetails' />} >
                            <ManageSupportDetails />
                        </TabStripTab>
                        <TabStripTab title={<FormattedMessage id='SupportTools.TitleBlacklistCompany' />} >
                            <BlacklistCompany />
                        </TabStripTab>
                        <TabStripTab title={<FormattedMessage id='SupportTools.TitleCustomerOverview' />} >
                            <CustomerOverview />
                        </TabStripTab>
                        <TabStripTab title={<FormattedMessage id='SupportTools.ArticleGrouping' />} >
                            <ArticleGrouping />
                        </TabStripTab>
                        <TabStripTab title={<FormattedMessage id='SupportTools.CustomerManager' />} >
                            <CustomerManager />
                        </TabStripTab>
                        <TabStripTab title={<FormattedMessage id='NLS.Title' />} >
                            <ManageStatcontrolOptions />
                        </TabStripTab>
                    </TabStrip>
                </Col>
            </Row>
        </div>
    );
}
