import { post } from './serviceBase';

const baseUrl = '/api/v1.0/admin/UserRegistration';

export function registerUser(user, successCallback, errorCallback) {
    const url = `${baseUrl}/registeruser`;
    post(url, user, successCallback, errorCallback);
}

export function validateCompanyNameExistance(companyName, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('companyName', companyName);
    const url = `${baseUrl}/ValidateCompanyNameExistance?` + params;
    post(url, companyName, successCallback, errorCallback);
}


export function validateEmailExistance(email, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('email', email);
    const url = `${baseUrl}/ValidateEmailExistance?` + params;
    post(url, email, successCallback, email);
}
