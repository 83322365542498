import React from "react";
import { HubConnectionBuilder } from '@microsoft/signalr';

const DEFAULT_POLLING_INTERVAL_MILLISECONDS = 2000;

const SignalRNotificationHandler = (props) => {
    const [hubConnection, setHubConnection] = React.useState(null);
    const [pollCount, setPollCount] = React.useState(0);
    const [echoReceived, setEchoReceived] = React.useState(false);

    const pollingInterval = props.pollingIntervalMilliseconds || DEFAULT_POLLING_INTERVAL_MILLISECONDS;

    const onSignalRNotification = (message) => {
        console.log(props.notificationType + ' received, notification: ', message);
        if (props.onNotification) {
            props.onNotification(message);
        }
    }

    const pollNotification = React.useCallback(() => {
        if (!echoReceived && props.notificationFallbackMethod) {
            console.log('Polling ' + props.notificationType + ' status (current poll count:' + pollCount + ')');
            props.notificationFallbackMethod();
        }
    },[echoReceived, pollCount, props]);

    React.useEffect(() => {
        if (!echoReceived && props.notificationFallbackMethod) {
            const timer = setTimeout(() => {
                pollNotification();
                setPollCount(pollCount + 1);
            }, pollingInterval);

            return () => clearTimeout(timer);
        }
    }, [pollCount, echoReceived, props.notificationFallbackMethod, pollingInterval, pollNotification]);

    React.useEffect(() => {
        if (props.notificationType) {
            const newConnection = new HubConnectionBuilder()
                .withUrl(window.origin + '/inventoryImportHub')
                .withAutomaticReconnect()
                .build();
            setHubConnection(newConnection);
        }
    }, []);

    const onEcho = React.useCallback((population, message) => {
        console.log('Echo received: populationId:' + population + ', message:' + message);
        setEchoReceived(true);
    }, [])

    React.useEffect(() => {
        if (hubConnection && !hubConnection._connectionStarted) {
            hubConnection.start()
                .then(_result => {
                    console.log('SignalRNotificationHandler connected to inventoryImportHub!');

                    if (props.notificationType) {
                        hubConnection.on(props.notificationType, onSignalRNotification);
                    }

                    hubConnection.on('Echo', onEcho);
                    hubConnection.send('echo', 'Placeholder', 'Hello there!');
                })
                .catch(e => console.log('Hub Connection failed: ', e));
        }
        return async () => {
            if (hubConnection) {
                try {
                    hubConnection.off('Echo', onEcho);

                    if (props.notificationType) {
                        hubConnection.off(props.notificationType, onSignalRNotification);
                    }

                    hubConnection.stop();
                    console.log('SignalRNotificationHandler - hub connection stopped');
                }
                catch (ex) {
                    console.log('hubConnection.stop() failed ', ex);
                }
            }
        }
    }, [hubConnection]);

    return (<div/>);
}

export default SignalRNotificationHandler
