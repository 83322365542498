import * as React from "react";
import { Button } from '@progress/kendo-react-buttons';
import { FormattedMessage } from "react-intl";
import ReactTooltip from "react-tooltip";

export const PopulationsCell = (props) => {
    const getTolltipText = () => {
        if (props.dataItem.populationInterfaceConfigurations.length <= 1)
            return "";
        let strArray = props.dataItem.populationInterfaceConfigurations.map(x => x.populationName + " ( " + x.locationName + " )")
        return strArray.join('<br />');
    }


    return (
        <td>
            {props.dataItem.populationInterfaceConfigurations && props.dataItem.populationInterfaceConfigurations.length > 0 &&
                <div>
                    {props.dataItem.populationInterfaceConfigurations.length == 1
                        ? <>{props.dataItem.populationInterfaceConfigurations[0].populationName} <small> {props.dataItem.populationInterfaceConfigurations[0].locationName}</small> </>
                        :
                        <span>
                            <ReactTooltip id="buttonTip" place="left" type="error" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" data-multiline={true} />
                        <Button data-for="buttonTip" data-html={true} data-tip={getTolltipText()}
                                className="m-1" themeColor={'primary'}>
                                {props.dataItem.populationInterfaceConfigurations.length + " "}  <FormattedMessage id="InterfaceConfiguration.Total" />
                            </Button>
                        </span>
                    }
                </div>}
        </td>
    );
};