import React, { createContext, useContext, useReducer} from "react";
import {
    NotificationGroup,
} from "@progress/kendo-react-notification";

import { v4 } from "uuid";
import NotificationCtrl from "./Notification";

const NotificationContext = createContext();

export const NotificationActions = {
    ADD_NOTIFICATION: "ADD_NOTIFICATION",
    REMOVE_NOTIFICATION: "REMOVE_NOTIFICATION",
    REMOVE_PAGE_NOTIFICATION: "REMOVE_PAGE_NOTIFICATION",
    REMOVE_ALL_NOTIFICATION: "REMOVE_ALL_NOTIFICATION",
    REMOVE_ALL_NOTIFICATION_ON_PAGE_CHANGE: "REMOVE_ALL_NOTIFICATION_ON_PAGE_CHANGE",
};

const NotificationProvider = (props) => {

    const isNotificationDisplayed = (notes, message) => {
        if (!message || !notes)
            return true;
        return notes.find(note => note.message == message)
    }

    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case NotificationActions.ADD_NOTIFICATION:
                if (isNotificationDisplayed(state, action.payload.message))
                    return state;
                return [...state, { ...action.payload }];
            case NotificationActions.REMOVE_NOTIFICATION:
                return state.filter(el => el.id !== action.id);
            case NotificationActions.REMOVE_PAGE_NOTIFICATION:
                return state.filter(el => el.pageId !== action.payload.pageId);
            case NotificationActions.REMOVE_ALL_NOTIFICATION:
                return [];
            case NotificationActions.REMOVE_ALL_NOTIFICATION_ON_PAGE_CHANGE:
                return [];
            default:
                return state
        }
    }, []);

    return (
        <NotificationContext.Provider value={dispatch}>
            <div className={"notification-wrapper"}>
                <NotificationGroup>
                    {state.map((note) => {
                        return <NotificationCtrl dispatch={dispatch} key={note.id} {...note} />
                    })}
                </NotificationGroup>

            </div>
            {props.children}
        </NotificationContext.Provider>
    )
};

export const useNotification = () => {
    const dispatch = useContext(NotificationContext);

    const getNotificationAction = (val) => {
        if (val.removeAll)
            return NotificationActions.REMOVE_ALL_NOTIFICATION;
        if (val.remove)
            return NotificationActions.REMOVE_PAGE_NOTIFICATION;
        if (val.removeOnPageChanges)
            return NotificationActions.REMOVE_ALL_NOTIFICATION_ON_PAGE_CHANGE;

        return NotificationActions.ADD_NOTIFICATION
    }

    return (props) => {
        dispatch({
            type: getNotificationAction(props),
            payload: {
                id: v4(),
                ...props
            }
        })          
    }
};

export default NotificationProvider;