import { getAllWithPopulationIdHeaders, postWithPopulationIdHeader } from './serviceBase';

const baseUrl = '/api/v1.0/StatisticsCollector';

export function getAllStatistics(populationIds, successCallback, errorCallback, cancelToken) {
    const url = `${baseUrl}/GetStatistics`;
    const requests = populationIds.map(populationId => {
        return {
            url: url,
            populationId: populationId
        };
    });
    getAllWithPopulationIdHeaders(requests, successCallback, errorCallback, cancelToken);
}

export function getAllCachedStatistics(populationIds, successCallback, errorCallback, cancelToken) {
    const url = `${baseUrl}/GetCachedPopulationStatistics`;
    const request = {
        populationIds: populationIds
    };
    postWithPopulationIdHeader(url, null, request, successCallback, errorCallback, cancelToken);
}
