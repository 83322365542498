export const FSort = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    10: 10,
    11: 11,
    12: 12,
    13: 13,
    14: 14,
    15: 15,
    16: 16,
    17: 17,
    18: 20,
    19: 25,
    20: 26,
    21: 27,
    22: 28,
    23: 29,
    24: 30,
    25: 31,
    26: 32,
    27: 33,
    28: 34,
    29: 35,
    30: 36,
    31: 37,
    32: 38,
    33: 39,
    34: 23,
    35: 21,
    36: 22,
    37: 18,
    38: 24,
    39: 19,
    118: 40,
    359: 41,
    360: 42,
    361: 43,
    362: 44
}

export function getFSort(fid) {
    return FSort[fid];
}