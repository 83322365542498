import * as React from "react";
import { Card, CardBody, Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { WizzardContext } from "../WizzardContext";
import { wizardEvents, wizardStep } from '../Wizard';
import { trigger, on, off } from '../../../../actions/events';
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { setInventoryMethod } from '../../../../services/stockDataQualityService';
import { useNotification } from '../../../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../../../Shared/Notifications/Notification';
import { useExceptionDialog } from '../../../Shared/ExceptionDialog/ExceptionDialogProvider';
import { getLocale } from "../../../../utils/localization";
import { UserContext } from "../../../UserContext";
import { DefaultInventoryDifference, DefaultReliabilityLevel, DefaultSamplingError } from "../../../Administration/Settings/UploadDataQuality";
import { defaultConfidenceLevel, defaultErrorDefinition, defaultErrorProportionForRejectionThreshold, defaultErrorWeightPercentage } from "../../../Administration/Settings/Settings";
import { setStratificationParameters, setSequentialTestParameters, setWaitingForErp } from "../../../../services/inventoryService";
import { useLoading } from "../../../Shared/LoadingContext";
import StockDataHighCard from "./StockDataHighCard";
import StockDataAverageCard from "./StockDataAverageCard";

const StockDataQuality = () => {
    const wizzardContext = React.useContext(WizzardContext);
    const [selectedValue, setSelectedValue] = React.useState(null);
    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const pageId = 'StockDataQuality';
    const currentCustomer = useSelector(state => state.dashboard.customer);
    const selectedPopulation = useSelector(state => state.dashboard.population);
    const userProfile = useSelector(state => state.profile.profile);
    const locale = getLocale(userProfile.userLanguage);
    const userContext = React.useContext(UserContext);
    const userWithAdminPriviledges = userContext.userGlobal.userInfo && (userContext.userGlobal.userInfo.isStatcontrolAdmin || userContext.userGlobal.userInfo.isAdminUser);


    const { setLoading } = useLoading();

    const handleChange = React.useCallback(

        (e) => {
            setSelectedValue(e.value);
        },
        [setSelectedValue]
    );

    useEffect(() => {
        if (!wizzardContext.wizzardGlobal.CustomerSettings)
            return;

        let inventoryMethod = wizzardContext.wizzardGlobal.CustomerSettings.uploadedDataQuality === null || wizzardContext.wizzardGlobal.CustomerSettings.uploadedDataQuality === 0 ? window.enumInventoryMethod.Stasam : window.enumInventoryMethod.Staseq;
        if (wizzardContext.wizzardGlobal.Import && wizzardContext.wizzardGlobal.Import.InventoryMethod) {
            inventoryMethod = wizzardContext.wizzardGlobal.Import.InventoryMethod;
        }

        setSelectedValue(inventoryMethod);
    }, []);

    const handleContinueButtonClicked = () => {
        setInventoryMethod(
            selectedPopulation.populationId,
            selectedValue,
            response => {
                if (response.succeeded) {
                    switch (selectedValue) {
                        case window.enumInventoryMethod.Stasam:
                            updateStratificationParameters();
                            break;
                        case window.enumInventoryMethod.Staseq:
                            updateSequentialTestParameters();
                            break;
                    }
                }
                else {
                    dispatchNotification({
                        type: NotificationTypes.error,
                        message: response.message
                    });
                }
            },
            handleError);
    }

    useEffect(() => {
        on(wizardEvents.continueButtonClicked, handleContinueButtonClicked);
        return () => {
            off(wizardEvents.continueButtonClicked, handleContinueButtonClicked);
        }
    }, [handleContinueButtonClicked]);

    const handleError = (errorMessage, showNotif = true) => {
        setLoading(false);
        if (showNotif) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            })
        }
    };

    const [reliabilityObj, setReliabilityObj] = React.useState({});

    useEffect(() => {
        if (!wizzardContext.wizzardGlobal.CustomerSettings)
            return;
        const reliability = wizzardContext.wizzardGlobal.Reliabilities.find(x => x.value === (wizzardContext.wizzardGlobal.CustomerSettings.reliabilityLevel ?? DefaultReliabilityLevel));
        setReliabilityObj(reliability);
        setSelectedValue(wizzardContext.wizzardGlobal.CustomerSettings.uploadedDataQuality == null || wizzardContext.wizzardGlobal.CustomerSettings.uploadedDataQuality === 0 ? window.enumInventoryMethod.Stasam : window.enumInventoryMethod.Staseq)
    }, [currentCustomer.customerId]);

    const setWaitingForErpAndContinue = () => {
        if (wizzardContext.wizzardGlobal.InterfaceConfiguration?.connectedToErp) {
            setLoading(true);
            setWaitingForErp(
                selectedPopulation.populationId,
                true,
                _success => {
                    setLoading(false);
                    trigger(wizardEvents.stepFinalized, {
                        isValid: true,
                        values: {},
                        goToStep: wizardStep.Sampling
                    });
                },
                handleError
            );
            return;
        }

        trigger(wizardEvents.stepFinalized, {});
    };

    const updateStratificationParameters = () => {
        let stratificationParameters = {
            inventoryDifference: wizzardContext.wizzardGlobal.CustomerSettings.inventoryDifference ?? DefaultInventoryDifference,
            reliabilityLevel: wizzardContext.wizzardGlobal.CustomerSettings.reliabilityLevel ?? DefaultReliabilityLevel,
            samplingError: wizzardContext.wizzardGlobal.CustomerSettings.samplingError ?? DefaultSamplingError,
        };

        setLoading(true);
        setStratificationParameters(
            selectedPopulation.populationId,
            stratificationParameters,
            _success => {
                setLoading(false);
                setWaitingForErpAndContinue();
            },
            handleError
        );
    };


    const updateSequentialTestParameters = () => {
        const sequentialTestParameters = {
            confidenceLevel: wizzardContext.wizzardGlobal.CustomerSettings.confidenceLevel ?? defaultConfidenceLevel,
            errorDefinition: wizzardContext.wizzardGlobal.CustomerSettings.errorDefinition ?? defaultErrorDefinition,
            errorProportionForRejectionThreshold: wizzardContext.wizzardGlobal.CustomerSettings.errorProportionForRejectionThreshold ?? defaultErrorProportionForRejectionThreshold,
            errorWeightPercentage: wizzardContext.wizzardGlobal.CustomerSettings.errorWeightInitialSampleCount == null
                ? (wizzardContext.wizzardGlobal.CustomerSettings.errorWeightPercentage ?? defaultErrorWeightPercentage)
                : null,
            errorWeightInitialSampleCount: wizzardContext.wizzardGlobal.CustomerSettings.errorWeightInitialSampleCount,
            toleranceDescriptionCategory1: wizzardContext.wizzardGlobal.CustomerSettings.toleranceDescriptionCategory1,
            toleranceThresholdCategory1: wizzardContext.wizzardGlobal.CustomerSettings.toleranceThresholdCategory1,
            toleranceDescriptionCategory2: wizzardContext.wizzardGlobal.CustomerSettings.toleranceDescriptionCategory2,
            toleranceThresholdCategory2: wizzardContext.wizzardGlobal.CustomerSettings.toleranceThresholdCategory2,
            toleranceDescriptionCategory3: wizzardContext.wizzardGlobal.CustomerSettings.toleranceDescriptionCategory3,
            toleranceThresholdCategory3: wizzardContext.wizzardGlobal.CustomerSettings.toleranceThresholdCategory3,
            toleranceDescriptionCategory4: wizzardContext.wizzardGlobal.CustomerSettings.toleranceDescriptionCategory4,
            toleranceThresholdCategory4: wizzardContext.wizzardGlobal.CustomerSettings.toleranceThresholdCategory4,
            toleranceDescriptionCategory5: wizzardContext.wizzardGlobal.CustomerSettings.toleranceDescriptionCategory5,
            toleranceThresholdCategory5: wizzardContext.wizzardGlobal.CustomerSettings.toleranceThresholdCategory5,
            toleranceDescriptionCategory6: wizzardContext.wizzardGlobal.CustomerSettings.toleranceDescriptionCategory6,
            toleranceThresholdCategory6: wizzardContext.wizzardGlobal.CustomerSettings.toleranceThresholdCategory6,
            toleranceDescriptionCategory7: wizzardContext.wizzardGlobal.CustomerSettings.toleranceDescriptionCategory7,
            toleranceThresholdCategory7: wizzardContext.wizzardGlobal.CustomerSettings.toleranceThresholdCategory7,
            toleranceDescriptionCategory8: wizzardContext.wizzardGlobal.CustomerSettings.toleranceDescriptionCategory8,
            toleranceThresholdCategory8: wizzardContext.wizzardGlobal.CustomerSettings.toleranceThresholdCategory8,
            toleranceDescriptionCategory9: wizzardContext.wizzardGlobal.CustomerSettings.toleranceDescriptionCategory9,
            toleranceThresholdCategory9: wizzardContext.wizzardGlobal.CustomerSettings.toleranceThresholdCategory9,
            quantityDeviationUnits: wizzardContext.wizzardGlobal.CustomerSettings.quantityDeviationUnits,
            quantityDeviationOperator: wizzardContext.wizzardGlobal.CustomerSettings.quantityDeviationOperator,
            quantityDeviationPercentage: wizzardContext.wizzardGlobal.CustomerSettings.quantityDeviationPercentage,
            valueDeviation: wizzardContext.wizzardGlobal.CustomerSettings.valueDeviation,
            deviationOperator: wizzardContext.wizzardGlobal.CustomerSettings.deviationOperator
        };

        setLoading(true);
        setSequentialTestParameters(
            selectedPopulation.populationId,
            sequentialTestParameters,
            () => {
                setLoading(false);
                setWaitingForErpAndContinue();
            },
            handleError
        );
    };

    useEffect(() => {
        on(wizardEvents.continueButtonClicked, handleContinueButtonClicked);

        return () => {
            off(wizardEvents.continueButtonClicked, handleContinueButtonClicked);
        }
    }, [handleContinueButtonClicked]);

    return (

        <Row className="wizard-content">
            <Col xs="9" >
                <p className="lead"><FormattedMessage id='StockDataQuality.ChooseQuality' /></p>
                <Row>
                    <Col xs="6" >
                        <StockDataAverageCard
                            inventoryMethod={window.enumInventoryMethod}
                            selectedCard={selectedValue}
                            language={locale}
                            handleChange={handleChange}
                            userWithAdminPriviledges={userWithAdminPriviledges}
                            reliabilityObj={reliabilityObj}
                        />
                    </Col>
                    <Col xs="6" >
                        <StockDataHighCard
                            inventoryMethod={window.enumInventoryMethod}
                            selectedCard={selectedValue}
                            language={locale}
                            handleChange={handleChange}
                            userWithAdminPriviledges={userWithAdminPriviledges}
                        />
                    </Col>
                </Row>
            </Col>

            <Col xs="3">
                <Card className="card-chart">
                    <CardBody>
                        <FormattedMessage id="StockDataQuality.Info" />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default StockDataQuality
