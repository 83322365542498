import {
    STORE_PROFILE,
    LOADING_PROFILE
  } from './types'
  
  
  export function storeProfile(profile) {
    return {
        type: STORE_PROFILE,
        payload: profile
    }
  }
  
  export function loadingProfile() {
    return {
        type: LOADING_PROFILE
    }
  }
  
  export function getProfile() {
    return {}
  }
  
  