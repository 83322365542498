import { getWithPopulationIdHeader, postWithPopulationIdHeader } from './serviceBase';

const baseUrl = '/api/v1.0/Preparation';

export function getCurrencies(populationId, successCallback, errorCallback, cancelToken) {
    const url = `${baseUrl}/GetCurrencies`;
    getWithPopulationIdHeader(url, populationId, successCallback, errorCallback, cancelToken);
}

export function setInventoryParameters(populationId, payload, successCallback, errorCallback) {
    const params = new URLSearchParams();
    params.append('populationId', populationId);
    const url = `${baseUrl}/SetInventoryParameters?` + params;
    postWithPopulationIdHeader(url, populationId, payload, successCallback, errorCallback);
}

export function getInventoryParameters(populationId, successCallback, errorCallback, cancelToken) {
    const url = `${baseUrl}/GetInventoryParameters`;
    getWithPopulationIdHeader(url, populationId, successCallback, errorCallback, cancelToken);
}
