import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, Card, CardHeader, CardBody, Button, Label } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { NotificationTypes } from '../../Shared/Notifications/Notification';
import { storeCustomer } from '../../../actions/dashboardActions';
import { UserContext } from '../../UserContext';
import { ProgressBar } from "@progress/kendo-react-progressbars";
import ConfirmDialog from '../../Shared/ConfirmDialog';
import { getActiveLicenseOfCustomer, cancelLicenseOfCustomer } from '../../../services/licenseManagementService';
import { getLocale, formatDate, formatNumber } from '../../../utils/localization';
import ReactTooltip from 'react-tooltip';
import HistoryLicenseTab from './HistoryLicenseTab';

const LicenseTab = (props) => {
    const intl = useIntl();
    const userProfile = useSelector(state => state.profile.profile);
    const currentCustomer = useSelector(state => state.dashboard.customer);
    const dispatch = useDispatch();

    const userContext = React.useContext(UserContext);

    const locale = getLocale(userProfile.userLanguage);

    useEffect(() => {
        return () => {
            props.removeNotification();
        };
    }, []);

    useEffect(() => {
        if (!currentCustomer && userContext.userGlobal.userInfo.accessibleCustomers && userContext.userGlobal.userInfo.accessibleCustomers.length > 0) {
            const defaultCustomer = userContext.userGlobal.userInfo.accessibleCustomers.find(x => x.customerId === userContext.userGlobal.userInfo.customerId);
            if (defaultCustomer) {
                dispatch(storeCustomer(defaultCustomer));
            }
        }
    }, [userContext.userGlobal.userInfo]);

    useEffect(() => {
        if (!currentCustomer) {
            return;
        }

        getActiveLicense(currentCustomer.customerId);
    }, [currentCustomer]);

    const [license, setLicense] = useState(null);
    const getActiveLicense = (customerId) => {
        props.incrementLoadingCount();
        setLicense(null);
        getActiveLicenseOfCustomer(
            customerId,
            activeLicense => {
                props.decrementLoadingCount();
                setLicense(activeLicense);
            },
            props.handleError);
    };

    const isTerminateLicenseDisabled = license && license.activeLicense && (license.activeLicense.isFreeTrial || license.activeLicense.isCancelled);

    const handleTerminateLicense = () => {
        setConfirmDialogTitle(<FormattedMessage id='MasterData.ConfirmTerminationTitle' />);
        setConfirmDialogDetail(<FormattedMessage id='MasterData.ConfirmTerminationText' />);
        setConfirmDialogCallback({
            success: () => terminateLicense()
        });
        setIsConfirmDialogVisible(true);
    };

    const terminateLicense = () => {
        props.incrementLoadingCount();
        props.removeNotification();
        cancelLicenseOfCustomer(
            currentCustomer.customerId,
            () => {
                props.decrementLoadingCount();
                props.dispatchNotification({
                    pageId: props.pageId,
                    type: NotificationTypes.success,
                    message: <FormattedMessage id='MasterData.CancelCustomerLicenseSuccess' />
                });
                setLicense({
                    ...license,
                    activeLicense: {
                        ...license.activeLicense,
                        isCancelled: true
                    }
                });
            },
            props.handleError);
    };

    const handleUpgradeLicense = () => {
        props.onShowLicense();
    };

    const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);
    const [confirmDialogTitle, setConfirmDialogTitle] = useState('');
    const [confirmDialogDetail, setConfirmDialogDetail] = useState('');
    const [confirmDialogCallback, setConfirmDialogCallback] = useState(null);

    const handleConfirmDialogClose = (isConfirmed) => {
        setIsConfirmDialogVisible(false);
        if (isConfirmed) {
            if (confirmDialogCallback) {
                confirmDialogCallback.success();
            }
        }
    };

    const isUpgradeLicenseDisabled = license && license.activeLicense && license.activeLicense.subscriptionType === window.enumSubscriptionType.Premium;

    const getUpgradeLicenseTooltip = () => {
        if (!license || !license.activeLicense) {
            return '';
        }

        const isPremiumSubscriptionType = license.activeLicense.subscriptionType === window.enumSubscriptionType.Premium;
        return isPremiumSubscriptionType ? intl.formatMessage({ id: 'LicenseDialog.NoHigherLicenseAvailable' }) : '';
    };

    return (
        <div className="content">
            <Row id="masterdata">
                <Col xs="12">
                    <Card>
                        <CardHeader>
                            <h3>
                                <FormattedMessage id='MasterData.LicenseTitle' />
                                <div className="float-right">
                                    <Button
                                        color="secondary"
                                        disabled={isTerminateLicenseDisabled}
                                        onClick={handleTerminateLicense}
                                    >
                                        <FormattedMessage id='Control.Terminate' />
                                    </Button>

                                    <ReactTooltip id="tooltipUpgradeLicense" place="bottom" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip"/>
                                    <span data-for="tooltipUpgradeLicense" data-tip={getUpgradeLicenseTooltip()}>
                                        <Button
                                            color="primary"
                                            disabled={isUpgradeLicenseDisabled}
                                            onClick={handleUpgradeLicense}
                                        >
                                            <FormattedMessage id='MasterData.Upgrade' />
                                        </Button>
                                    </span>
                                </div>
                            </h3>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col xs="3">
                                    <fieldset className={"k-form-fieldset"}>
                                        <div className="mb-3">
                                            <Label><FormattedMessage id='MasterData.Licensed' /></Label>
                                            <p>{license?.activeLicense?.subscriptionTypeName}</p>
                                        </div>
                                    </fieldset>
                                </Col>
                                <Col xs="3">
                                    <fieldset className={"k-form-fieldset"}>
                                        <div className="mb-3">
                                            <Label><FormattedMessage id='MasterData.ExpirationDate' /></Label>
                                            <p>{formatDate(license?.activeLicense?.licenseEndDate, locale)}</p>
                                        </div>
                                    </fieldset>
                                </Col>
                                <Col xs="3">
                                    <fieldset className={"k-form-fieldset"}>
                                        <div className="mb-3">
                                            <Label><FormattedMessage id='MasterData.Pop' /></Label>
                                            <ProgressBar
                                                min={0}
                                                max={license?.activeLicense?.numberOfPopulations}
                                                value={license?.numberOfPopulations}
                                                label={() => {
                                                    return <strong>{formatNumber(license?.numberOfPopulations, locale)} <FormattedMessage id='Wizard.StepOf' /> {formatNumber(license?.activeLicense?.numberOfPopulations, locale)}</strong>;
                                                }}
                                            />
                                        </div>
                                    </fieldset>
                                </Col>
                                <Col xs="3">
                                    <fieldset className={"k-form-fieldset"}>
                                        <div className="mb-3">
                                            <Label><FormattedMessage id='MasterData.Pos' /></Label>
                                            <ProgressBar
                                                min={0}
                                                max={license?.activeLicense?.numberOfPositions}
                                                value={license?.numberOfPositions}
                                                label={() => {
                                                    return <strong>{formatNumber(license?.numberOfPositions, locale)} <FormattedMessage id='Wizard.StepOf' /> {formatNumber(license?.activeLicense?.numberOfPositions, locale)}</strong>;
                                                }}
                                            />
                                        </div>
                                    </fieldset>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader>
                            <h3>
                                <FormattedMessage id='HistoryLicense.History' />
                            </h3>
                        </CardHeader>
                        <CardBody>
                            <Row id="masterdata">
                                <Col xs="12">
                                    <HistoryLicenseTab
                                        incrementLoadingCount={props.incrementLoadingCount}
                                        decrementLoadingCount={props.decrementLoadingCount}
                                        handleError={props.handleError}
                                        removeNotification={props.removeNotification}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <ConfirmDialog
                visible={isConfirmDialogVisible}
                onClose={handleConfirmDialogClose}
                negative={<FormattedMessage id='Control.Cancel' />}
                positive={<FormattedMessage id='Control.Confirm' />}
                title={confirmDialogTitle}
                detail={confirmDialogDetail}
            />
        </div>
    );
}

export default LicenseTab
