import React from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import CsvMapper, { MappedColumn } from './CsvMapper';
import { FormattedMessage } from 'react-intl';
import { WizzardContext} from "../WizzardContext";

const MapRecords = (props) => {
    const [interfaceConfigurationUsed, setInterfaceConfigurationUsed] = React.useState(null);
    const [mappedColumn, setMappedColumn] = React.useState(MappedColumn.None);

    const wizzardContext = React.useContext(WizzardContext);

    const undefinedInterfaceConfig = wizzardContext.wizzardGlobal?.InterfaceConfiguration?.initialData == null;
    const isFixedLengthDataFile = wizzardContext.wizzardGlobal?.InterfaceConfiguration?.initialData?.columnSeparator === window.enumColumnSeparator.None;
    const isFileWithoutHeader = !undefinedInterfaceConfig && !wizzardContext.wizzardGlobal?.InterfaceConfiguration?.initialData?.headingEnabled;
    let skipColumnMapping = isFileWithoutHeader || isFixedLengthDataFile || !wizzardContext.wizzardGlobal?.ShowColumnMapping;

    const handleShowColumnInfo = (column) => {
        setMappedColumn(column);
    };

    return (
        <Row className="wizard-content">
            <Col xs="9" >
                <CsvMapper
                    importFileInfo={props.importFileInfo}
                    setResetBtnDisabled={props.setResetBtnDisabled}
                    setInterfaceConfigurationUsed={setInterfaceConfigurationUsed}
                    onShowColumnInfo={handleShowColumnInfo}
                    checkMappingsDiscrepancies={props.checkMappingsDiscrepancies}
                    getColumnHeaderIndex={props.getColumnHeaderIndex}
                    skipColumnMapping={skipColumnMapping}
                />
            </Col>

            <Col xs="3">
                <Row>
                    <Card className="card-chart">
                        <CardBody>
                            <p><FormattedMessage id='CsvMapper.TheAdministrationAndAdvancedCreationOfAllInterfacesCanBeFound' /></p>
                        </CardBody>
                    </Card>
                </Row>
                {!skipColumnMapping && (
                    <Row>
                        <Card className="card-chart">
                            <CardBody>
                                {mappedColumn === MappedColumn.None && (
                                    <p><FormattedMessage id='CsvMapper.DragDrop' /></p>
                                )}
                                {mappedColumn === MappedColumn.UniqueId && (
                                    <p><FormattedMessage id='CsvMapper.InfoUniqueId' /></p>
                                )}
                                {mappedColumn === MappedColumn.Amount && (
                                    <p><FormattedMessage id='CsvMapper.InfoAmount' /></p>
                                )}
                                {mappedColumn === MappedColumn.Price && (
                                    <p><FormattedMessage id='CsvMapper.InfoPrice' /></p>
                                )}
                                {mappedColumn === MappedColumn.QuantityUnit && (
                                    <p><FormattedMessage id='CsvMapper.InfoQuantityUnit' /></p>
                                )}
                                {mappedColumn === MappedColumn.PriceUnit && (
                                    <p><FormattedMessage id='CsvMapper.InfoPriceUnit' /></p>
                                )}
                                {mappedColumn === MappedColumn.ArticleDescription && (
                                    <p><FormattedMessage id='CsvMapper.InfoArticleDescription' /></p>
                                )}
                                {mappedColumn === MappedColumn.AdditionalFields && (
                                    <p><FormattedMessage id='CsvMapper.InfoAdditionalFields' /></p>
                                )}
                            </CardBody>
                        </Card>
                    </Row>
                )}
                {interfaceConfigurationUsed &&
                    <Row>
                        <Card className="card-chart">
                            <CardBody>
                                <p><FormattedMessage id='CsvMapper.InterfaceConfigurationUsed' /> {interfaceConfigurationUsed.interfaceConfigurationName}</p>
                            </CardBody>
                        </Card>
                    </Row>
                }
            </Col>
        </Row>
    )
}

export default MapRecords