import React, {useEffect } from 'react';
import { useLoading } from "../Shared/LoadingContext";
import { useNotification } from '../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../Shared/Notifications/Notification';
import { useExceptionDialog } from '../Shared/ExceptionDialog/ExceptionDialogProvider';
import { useIntl } from 'react-intl';
import { Col, Row, Card, CardBody} from 'reactstrap';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import EditActionCell from "../Administration/Warehouse Management/EditActionCell";

import { getAllSupports, updateSupport } from '../../services/licenseManagementService';

const editField = "inEdit";

export default function ManageSupportDetails() {
    const intl = useIntl();
    const { setLoading } = useLoading();

    const pageId = 'pageManageSupportDetails';
    const [dataState, setDataState] = React.useState([]);
    const [gridData, setGridData] = React.useState([]);
    const dispatchNotification = useNotification();
    const [disable, setDisable] = React.useState(false);
    const dispatchExceptionDialog = useExceptionDialog();
    
    

    useEffect(() => {
        getData();
    }, []);
        
    
    React.useEffect(() => {
        if (disable) {
            setTimeout(() => {
                let inputs = document.getElementsByClassName('k-textbox')
                if (inputs.length > 0) {
                    inputs[0].focus();
                }
            });
        }
    }, [disable]);
        

    const getData = () => {
        setLoading(true);
        getAllSupports(
            items => {
                items = items.map(item => (
                    {
                        ...item,
                        changed: false
                    }));
                setLoading(false);
                setDataState(items);
                setGridData(items);

            },
            handleError);
    }

    const itemChange = (event) => {
        const newData = gridData.map((item) =>
            item.supportId === event.dataItem.supportId
                ? {
                    ...item, [event.field || ""]: event.value,
                    changed: (event.value.trim().length > 0)
                }
                : item
        );
        setGridData(newData);
    };

    const handleError = (errorMessage, showNotif = true) => {
        setLoading(false);
        if (showNotif) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            });
        }
    };

    const removeNotification = () => {
        dispatchNotification({
            remove: true,
            pageId: pageId
        });
    };

    const update = (dataItem) => {
        if (!isDataItemValid(dataItem)) {
            return;
        }
        removeNotification();
        setLoading(true);
        updateSupport(
            dataItem,
            items => {
                dataItem.inEdit = false;
                setDataState(
                    dataState.map((item) =>
                        item.supportId === dataItem.supportId ? { ...dataItem, inEdit: false } : item
                    )
                );
                setLoading(false);
            },
            handleError);
        setDisable(false);
    };

    const isDataItemValid = (dataItem) => {
        removeNotification();
        if (!dataItem.supportName || dataItem.supportName.trim().length == 0) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: intl.formatMessage({ id: "ManageLicense.SupportNameRequired" })
            })
            return false;
        }
        if (dataItem.supportName.length > 256) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: intl.formatMessage({ id: "ManageLicense.SupportNameTooLong" })
            })
            return false;
        }
        if (gridData.filter(x => x.supportId !== dataItem.supportId)
            .some(x => x.supportName.toLowerCase() === dataItem.supportName.toLowerCase())) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: intl.formatMessage({ id: "ManageLicense.SupportNameExists" })
            })
            return false
        }
        return true;
    };

    const discard = () => {
        const newData = [...gridData];
        newData.splice(0, 1);
        setGridData(newData);
        setDisable(false);
    };

    const cancel = (dataItem) => {
        const originalItem = dataState.find(
            (p) => p.supportId === dataItem.supportId
        );
        const newData = gridData.map((item) =>
            item.supportId === originalItem.supportId ? originalItem : item
        );
        dataItem.inEdit = false;
        setGridData(newData);
        setDisable(false);
    };

    const enterEdit = (dataItem) => {
        dataItem.changed = false;
        setDisable(true);
        setGridData(
            gridData.map((item) =>
                item.supportId === dataItem.supportId ? { ...item, inEdit: true } : item
            )
        );
        dataItem.selected = true;
    };


    const EditRights = (props) => (
        <EditActionCell
            {...props}
            edit={enterEdit}
            discard={discard}
            update={update}
            cancel={cancel}
            editField={editField}
            idPropName={"supportId"}
            disabled={disable}
            hideDelete={true}
        />
    );

    return (
        <div className="content">
            <Card>
                
                <CardBody>
                    <Row>
                        <Col xs="12">
                            <Grid
                                data={gridData}
                                data={gridData}
                                {...gridData}
                                onDataStateChange={(e) => {
                                    setGridData(e.dataState);
                                }}
                                onItemChange={itemChange}
                                editField={editField}
                            key="supportId"
                            >
                                <Column field="supportName" title={intl.formatMessage({ id: "ManageLicense.SupportName" })} />
                                <Column cell={EditRights} filterable={false} width="200px"/>
                            </Grid>
                        </Col>
                    </Row>                   
                </CardBody>
            </Card>
        </div>
    );
}