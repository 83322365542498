import React, { useState, useEffect } from 'react';
import { useLoading } from "../Shared/LoadingContext";
import { useNotification } from '../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../Shared/Notifications/Notification';
import { useExceptionDialog } from '../Shared/ExceptionDialog/ExceptionDialogProvider';
import axios from 'axios';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row, Card, CardHeader, CardBody, Input } from 'reactstrap';
import { Grid, GridColumn as Column, GridNoRecords } from '@progress/kendo-react-grid';
import { getPreAnalysisReportHistory, getPostAnalysisReportHistory } from "../../services/reportsService"
import { getPdfFileByName } from '../../services/inventoryService';
import { saveAs } from 'file-saver';
import ReportTypeCell from "./ReportTypeCell";
import ActionCell from "./ActionCell";
import GridFooter from '../Shared/GridFooter';

export default function ReportsHistory(props) {

    const DEFAULT_ITEMS_PER_PAGE = 10;

    const cancelTokenSource = axios.CancelToken.source();
    const intl = useIntl();
    const { setLoading } = useLoading();

    const pageId = 'pageReportsHistory';
    const minGridLength = 5;
    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const [rawActivityHistory, setRawActivityHistory] = useState([]);
    const [activityHistory, setActivityHistory] = useState([]);
    const [filteredActivityHistory, setFilteredActivityHistory] = useState([]);
    const [filterDate, setFilterDate] = React.useState("");
    const [filterType, setFilterType] = React.useState("");
    const [filterName, setFilterName] = React.useState("");
    const [gridWords, setGridWords] = useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [itemsPerPage, setItemsPerPage] = React.useState(DEFAULT_ITEMS_PER_PAGE);
    const [isPagingVisible, setIsPagingVisible] = React.useState(false);

    useEffect(() => {
        return () => {
            cancelTokenSource.cancel();
        };
    }, []);

    useEffect(() => {
        refreshActivityHistory();
    }, [props.populationId]);

    useEffect(() => {
        translateActivityHistory();
    }, [rawActivityHistory, props.locale]);

    useEffect(() => {
        applyFilter();
    }, [activityHistory, filterDate, filterType, filterName]);

    useEffect(() => {
        setIsPagingVisible(filteredActivityHistory.length > minGridLength);
        setPageHandler(1);
    }, [filteredActivityHistory, itemsPerPage]);

    const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDateString = (date) => {
        return date.toLocaleDateString(props.locale, dateOptions);
    }

    const formattedReportType = (repType) => {
        let formatId = 'Reports.ReportType.Unknown';
        switch (repType) {
            case window.enumReportType.InitialImportResult:
                formatId = 'Reports.ReportType.InitialImportResult';
                break;
            case window.enumReportType.StructureAnalysis:
                formatId = 'Reports.ReportType.StructureAnalysis';
                break;
            case window.enumReportType.LorenzCurve:
                formatId = 'Reports.ReportType.LorenzCurve';
                break;
            case window.enumReportType.Stratification:
                formatId = 'Reports.ReportType.Stratification';
                break;
            case window.enumReportType.Countlist:
                formatId = 'Reports.ReportType.Countlist';
                break;
            case window.enumReportType.CountlistFeedback:
                formatId = 'Reports.ReportType.CountlistFeedback';
                break;
            case window.enumReportType.Extrapolation:
                formatId = 'Reports.ReportType.Extrapolation';
                break;
            case window.enumReportType.Evaluation:
                formatId = 'Reports.ReportType.Evaluation';
                break;
            case window.enumReportType.DifferencesList:
                formatId = 'Reports.ReportType.DifferencesList';
                break;
            case window.enumReportType.DifferencesListGrouped:
                formatId = 'Reports.ReportType.DifferencesListGrouped';
                break;
            case window.enumReportType.CountedItems:
                formatId = 'Reports.ReportType.CountedItems';
                break;
            case window.enumReportType.UncountedItems:
                formatId = 'Reports.ReportType.UncountedItems';
                break;
            case window.enumReportType.MultipleCountedItems:
                formatId = 'Reports.ReportType.MultipleCountedItems';
                break;
            case window.enumReportType.DecisionGraph:
                formatId = 'Reports.ReportType.DecisionGraph';
                break;
        }
        return intl.formatMessage({ id: formatId });
    }

    const applyFilter = () => {
        const filteredArray = activityHistory.filter(
            (w) => (
                w.reportDate.toLowerCase().includes(filterDate.toLowerCase()) &&
                w.reportTypeString.toLowerCase().includes(filterType.toLowerCase()) &&
                w.reportName.toLowerCase().includes(filterName.toLowerCase()) 
            )
        );
        setFilteredActivityHistory(filteredArray);
    }

    const refreshActivityHistory = () => {
        setRawActivityHistory([]);
        if (props.populationId) {
            setLoading(true);
            let getReportHistory = props.postAnalysis ? getPostAnalysisReportHistory : getPreAnalysisReportHistory;
            getReportHistory(props.populationId,
                repHist => {
                    setLoading(false);
                    setRawActivityHistory(repHist);
                },
                handleError,
                cancelTokenSource.token
            );
        }
    }

    const translateActivityHistory = () => {
        if (rawActivityHistory?.length > 0) {
            let repHistory = rawActivityHistory.map(rep => ({
                ...rep,
                reportDate: formattedDateString(new Date(rep.reportDate)),
                reportTypeString: formattedReportType(rep.reportType)
            }));
            setActivityHistory(repHistory);
        }
        else {
            setActivityHistory([]);
        }
    }

    const handleError = (errorMessage, showNotif = true) => {
        setLoading(false);

        if (showNotif) {
            dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            });
        }
    };

    const applyFilterDate = (value) => {
        setCurrentPage(1);
        setFilterDate(value);
    }

    const applyFilterType = (value) => {
        setCurrentPage(1);
        setFilterType(value);
    }

    const applyFilterName = (value) => {
        setCurrentPage(1);
        setFilterName(value);
    }

    const setPageHandler = (page) => {
        if (filteredActivityHistory && page > 0 && (page <= Math.floor((filteredActivityHistory.length - 1) / itemsPerPage) + 1 || filteredActivityHistory.length == 0)) {
            let startIndex = (page - 1) * itemsPerPage;
            let endIndex = (page) * itemsPerPage;
            setGridWords(filteredActivityHistory.slice(startIndex, endIndex));
            if (activityHistory.length > itemsPerPage) {
                setCurrentPage(page);
            }
            else {
                setCurrentPage(1);
            }
        }
    }

    const handleItemsPerPageChange = (dropDownItem) => {
        setItemsPerPage(dropDownItem.value);
    };

    const pdfPreviewErrorCallback = error => {
        handleError(error, false);
    }

    const downloadReport = (dataItem) => {
        downloadPdfFromBlob(dataItem, downloadReportToDisk);
    };

    const openReport = (dataItem) => {
        downloadPdfFromBlob(dataItem, openReportOnNewTab);
    };

    const downloadPdfFromBlob = (dataItem, action) => {
        setLoading(true);
        getPdfFileByName(dataItem.reportName,
            (report) => {
                action({ report: report, fileName: dataItem.reportName});
                setLoading(false);
            },
            pdfPreviewErrorCallback,
            props.populationId
        );
    };

    const downloadReportToDisk = (params) => {
        let blob = new Blob([params.report], {
            type: "application/pdf"
        });
        saveAs(blob, params.fileName);
    }

    const openReportOnNewTab = (params) => {
        if (params.report) {
            const url = window.URL.createObjectURL(
                params.report
            );
            window.open(url);
        }
    }

    const MyReportTypeCell = (props) => (
        <ReportTypeCell {...props} />
    );

    const DownloadActionCell = (props) => (
        <ActionCell
            {...props}
            open={openReport}
            download={downloadReport}
        />
    );

    return (
        <div className="content">
            <Card id="pop-tile">
                <CardHeader>
                    <h3>{props.title}</h3>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col xs="3">
                            <Input className="grid-filter" name="filterNameValue" placeholder={intl.formatMessage({ id: "Reports.ReportHistory.NameFilter" })} onChange={(event) => {
                                applyFilterName(event.target.value);
                            }} />
                        </Col>
                        <Col xs="3">
                            <Input className="grid-filter" name="filterTypeValue" placeholder={intl.formatMessage({ id: "Reports.ReportHistory.TypeFilter" })} onChange={(event) => {
                                applyFilterType(event.target.value);
                            }} />
                        </Col>
                        <Col xs="3">
                            <Input className="grid-filter" name="filterDateValue" placeholder={intl.formatMessage({ id: "Reports.ActivityHistory.DateFilter" })} onChange={(event) => {
                                applyFilterDate(event.target.value);
                            }} />
                        </Col>
                    </Row>
                    <Row id="history-grid">
                        <Col xs="12">
                            <Grid data={gridWords}
                                key="id">
                                <GridNoRecords>
                                    {<FormattedMessage id="Reports.ReportHistory.EmptyReportsHistory" />}
                                </GridNoRecords>
                                <Column field="reportName" title={intl.formatMessage({ id: "Reports.ReportHistory.Name" })} />
                                <Column field="reportTypeString" title={intl.formatMessage({ id: "Reports.ReportHistory.Type" })} cell={MyReportTypeCell} />
                                <Column field="reportDate" title={intl.formatMessage({ id: "Reports.ActivityHistory.Date" })} format="{0:y.MM.dd}" />
                                <Column cell={DownloadActionCell} filterable={false} />
                            </Grid>
                        </Col>
                    </Row>
                    {isPagingVisible && (
                        <GridFooter
                            itemsPerPage={itemsPerPage}
                            handleItemsPerPageChange={handleItemsPerPageChange}
                            dataLength={filteredActivityHistory.length}
                            setPageHandler={setPageHandler}
                            currentPage={currentPage}
                        />                       
                    )}
                </CardBody>
            </Card>
        </div>
    );
}