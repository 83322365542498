import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row, Card, CardBody, Button } from 'reactstrap';
import { Form, Field } from '@progress/kendo-react-form';
import { FormComboBox, FormCheckbox } from '../Inventory/Wizard/form-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNotification } from '../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../Shared/Notifications/Notification';
import { useLoading } from '../Shared/LoadingContext';
import { UserContext } from '../UserContext';
import { useSelector } from 'react-redux';
import { getCustomers } from '../../services/masterDataService';
import { getWarehouseLocations, getPopulations } from '../../services/warehouseLocationsService';
import { getSupportScripts, executeSupportScript, getWarehouseLocationByPopulation } from '../../services/supportService';
import ConfirmDialog from '../Shared/ConfirmDialog';
import { useExceptionDialog } from '../Shared/ExceptionDialog/ExceptionDialogProvider';

const ExecuteScripts = () => {
    const pageId = 'ExecuteScripts';
    const intl = useIntl();
    const userProfile = useSelector(state => state.profile.profile);
    const userContext = React.useContext(UserContext);
    const selectedPopulation = userContext.userGlobal.userInfo.lastUsedPopulation ? userContext.userGlobal.userInfo.lastUsedPopulation.populationId : null;
    const dispatchNotification = useNotification();
    const { setLoading } = useLoading();
    const dispatchExceptionDialog = useExceptionDialog();

    const [customer, setCustomer] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [lastUsedPopulationData, setLastUsedPopulationData] = useState(null);

    const [warehouseLocation, setWarehouseLocation] = useState(null);
    const [warehouseLocations, setWarehouseLocations] = useState([]);

    const [population, setPopulation] = useState(null);
    const [populations, setPopulations] = useState([]);

    const [runOnAdminDb, setRunOnAdminDb] = useState(false);

    useEffect(() => {
        incrementLoadingCount();
        getCustomers(
            custs => {
                decrementLoadingCount();
                setCustomers(custs);
                getWarehouseLocation();
            },
            handleError);
    }, []);

    const getWarehouseLocation = () => {
        if (!selectedPopulation)
            return;

        incrementLoadingCount();

        getWarehouseLocationByPopulation(
            selectedPopulation,
            result => {
                decrementLoadingCount();
                if (result)
                    setLastUsedPopulationData(result)

            },
            handleError);
    }

    useEffect(() => {
        if (lastUsedPopulationData) {
            let foundCustomers = customers.filter((item) => item.customerId === lastUsedPopulationData.customerId);

            if (foundCustomers.length === 0)
                return;

            setCustomer(foundCustomers[0]);
        }
    }, [customers, lastUsedPopulationData]);

    useEffect(() => {
        if (lastUsedPopulationData) {
            const whlid = warehouseLocation?.warehouseLocationId ?? lastUsedPopulationData.warehouseLocationId;

            let foundWH = warehouseLocations.filter((item) => item.warehouseLocationId === whlid);
            if (foundWH.length === 0)
                return;

            setWarehouseLocation(foundWH[0]);
        }
    }, [warehouseLocations]);

    useEffect(() => {
        if (userProfile) {
            const popid = population?.populationId ?? selectedPopulation;

            let foundPop = populations.filter((item) => item.populationId === popid);
            if (foundPop.length === 0)
                return;

            setPopulation(foundPop[0]);
        }
    }, [populations]);


    useEffect(() => {
        setWarehouseLocation(null);
        setWarehouseLocations([]);

        if (!customer) {
            return;
        }

        incrementLoadingCount();
        getWarehouseLocations(
            customer.customerId,
            warehouses => {
                decrementLoadingCount();
                setWarehouseLocations([{ warehouseLocationId: null, warehouseLocationName: intl.formatMessage({ id:'InterfaceConfiguration.None'}) }, ...warehouses]);
            },
            handleError);
    }, [customer]);


    useEffect(() => {
        setPopulation(null);
        setPopulations([]);

        if (!warehouseLocation) {
            return;
        }

        incrementLoadingCount();
        getPopulations(
            warehouseLocation.warehouseLocationId,
            pops => {
                decrementLoadingCount();
                setPopulations([{ populationId: null, populationName: intl.formatMessage({ id: 'InterfaceConfiguration.None' }) }, ...pops]);
            },
            handleError);
    }, [warehouseLocation]);

    useEffect(() => {
        setSupportScript(null);
    }, [runOnAdminDb]);

    useEffect(() => {
        if (warehouseLocations?.length > 0 && warehouseLocations[0].warehouseLocationId === null) {
            setWarehouseLocations([{ warehouseLocationId: null, warehouseLocationName: intl.formatMessage({ id: 'InterfaceConfiguration.None' }) }, ...warehouseLocations.slice(1)]);
            setPopulations([{ populationId: null, populationName: intl.formatMessage({ id: 'InterfaceConfiguration.None' }) }, ...populations.slice(1)]);
        }
    }, [userProfile.userLanguage]);

    const [supportScript, setSupportScript] = useState(null);
    const [supportScripts, setSupportScripts] = useState([]);
    useEffect(() => {
        incrementLoadingCount();
        getSupportScripts(
            scripts => {
                decrementLoadingCount();
                setSupportScripts(scripts);
            },
            handleError);
    }, []);

    const filteredSupportScripts = supportScripts.filter(x => x.runInAdminDb === runOnAdminDb);

    const [loadingCount, setLoadingCount] = useState(0);
    useEffect(() => {
        setLoading(loadingCount > 0);
    }, [loadingCount]);

    const incrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount + 1);
    };

    const decrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount - 1);
    };

    const handleError = (errorMessage, showNotif = true) => {
        decrementLoadingCount();
        if (showNotif) {
            dispatchNotification({
                type: NotificationTypes.error,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            })
        }
    };

    const handleCustomerChange = (event) => {
        setCustomer(event.value);
    };

    const handleWarehouseLocationChange = (event) => {
        setWarehouseLocation(event.value);
    };

    const handlePopulationChange = (event) => {
        setPopulation(event.value);
    };

    const handleSupportScriptChange = (event) => {
        setSupportScript(event.value);
    };

    const [isConfirmDialogVisible, setIsConfirmDialogVisible] = React.useState(false);
    const [confirmDialogTitle, setConfirmDialogTitle] = React.useState('');
    const [confirmDialogDetail, setConfirmDialogDetail] = React.useState('');
    const [confirmDialogCallback, setConfirmDialogCallback] = React.useState(null);

    const handleConfirmDialogClose = (isConfirmed) => {
        setIsConfirmDialogVisible(false);
        if (isConfirmed) {
            if (confirmDialogCallback) {
                confirmDialogCallback.success();
            }
        }
    };

    const handleSubmit = () => {
        if (!isFormValid()) {
            return;
        }

        setConfirmDialogTitle(<FormattedMessage id='SupportTools.ConfirmationTitle' />);
        setConfirmDialogDetail(<FormattedMessage id='SupportTools.ConfirmationText' />);
        setConfirmDialogCallback({
            success: () => executeScript()
        });
        setIsConfirmDialogVisible(true);
    };

    const executeScript = () => {
        const data = {
            customerId: customer.customerId,
            warehouseLocationId: warehouseLocation.warehouseLocationId,
            populationId: population.populationId,
            supportScriptId: supportScript.supportScriptId,
            runOnAdminDb: runOnAdminDb
        };

        incrementLoadingCount();
        executeSupportScript(
            data,
            () => {
                decrementLoadingCount();
                dispatchNotification({
                    type: NotificationTypes.success,
                    message: <FormattedMessage id='SupportTools.ExecuteSupportScriptSuccess' />
                });
            },
            handleError);
    };

    const isFormValid = () => {
        const isCustomerValid = customerValidator(customer) === "";
        const isWarehouseLocationValid = warehouseLocationValidator(warehouseLocation) === "";
        const isPopulationValid = populationValidator(population) === "";
        const isSupportScriptValid = supportScriptValidator(supportScript) === "";
        return isCustomerValid && isWarehouseLocationValid && isPopulationValid && isSupportScriptValid;
    };

    const customerValidator = (value) => value ? "" : <FormattedMessage id='SupportTools.SelectCustomer' />;
    const warehouseLocationValidator = (value) => value?.warehouseLocationId || runOnAdminDb ? "" : <FormattedMessage id='SupportTools.SelectWarehouseLocation' />;
    const populationValidator = (value) => value?.populationId || runOnAdminDb ? "" : <FormattedMessage id='SupportTools.SelectPopulation' />;
    const supportScriptValidator = (value) => value ? "" : <FormattedMessage id='SupportTools.SelectSupportScript' />;

    const isWarehouseLocationDisabled = customer == null;
    const isPopulationDisabled = warehouseLocation == null;

    const handleRunOnAdminDbChange = useCallback((event) => {
        setRunOnAdminDb(event.value);
    }, [runOnAdminDb]);

    return (

        <div className="content">
            <Form
                initialValues={{ customer: customer, warehouseLocation: warehouseLocation, population: population, supportScript: supportScript }}
                key={JSON.stringify({ customer: customer, warehouseLocation: warehouseLocation, population: population, supportScript: supportScript })}
                render={() => (
                    <fieldset className={"k-form-fieldset"}>
                        <Card>
                            <CardBody>
                                <Row className="k-form">
                                    <Col xs="6">
                                        <div className="mb-3">
                                            <Field
                                                id={"customer"}
                                                name={"customer"}
                                                label={<FormattedMessage id='SupportTools.Customer' />}
                                                component={FormComboBox}
                                                textField={"customerName"}
                                                dataItemKey={"customerId"}
                                                data={customers}
                                                onChange={handleCustomerChange}
                                                validator={customerValidator}
                                                clearButton={false}
                                                placeholder={intl.formatMessage({ id: 'SupportTools.CustomerPlaceholder' })}
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <Field
                                                id={"warehouseLocation"}
                                                name={"warehouseLocation"}
                                                label={<FormattedMessage id='SupportTools.WarehouseLocation' />}
                                                component={FormComboBox}
                                                textField={"warehouseLocationName"}
                                                dataItemKey={"warehouseLocationId"}
                                                data={warehouseLocations}
                                                onChange={handleWarehouseLocationChange}
                                                validator={warehouseLocationValidator}
                                                clearButton={false}
                                                placeholder={intl.formatMessage({ id: 'SupportTools.WarehouseLocationPlaceholder' })}
                                                disabled={isWarehouseLocationDisabled}
                                            />
                                        </div>
                                    </Col>

                                    <Col xs="6">
                                        <div className="mb-3">
                                            <Field
                                                id={"population"}
                                                name={"population"}
                                                label={<FormattedMessage id='SupportTools.Population' />}
                                                component={FormComboBox}
                                                textField={"populationName"}
                                                dataItemKey={"populationId"}
                                                data={populations}
                                                onChange={handlePopulationChange}
                                                validator={populationValidator}
                                                clearButton={false}
                                                placeholder={intl.formatMessage({ id: 'SupportTools.PopulationPlaceholder' })}
                                                disabled={isPopulationDisabled}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Field
                                                id={"supportScript"}
                                                name={"supportScript"}
                                                label={<FormattedMessage id='SupportTools.SupportScript' />}
                                                component={FormComboBox}
                                                textField={"supportScriptName"}
                                                dataItemKey={"supportScriptId"}
                                                data={filteredSupportScripts}
                                                onChange={handleSupportScriptChange}
                                                validator={supportScriptValidator}
                                                clearButton={false}
                                                placeholder={intl.formatMessage({ id: 'SupportTools.SupportScriptPlaceholder' })}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Field
                                                id={"runOnAdminDb"}
                                                name={"runOnAdminDb"}
                                                label={intl.formatMessage({ id: 'SupportTools.RunOnAdminDb' })}
                                                checked={runOnAdminDb}
                                                component={FormCheckbox}
                                                onChange={handleRunOnAdminDbChange}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <span className="k-form-separator"></span>
                        <div className="float-right">
                            <Button
                                color="primary"
                                onClick={handleSubmit}
                                disabled={!isFormValid()}
                            >
                                <FormattedMessage id='SupportTools.ExecuteSupportScript' />
                            </Button>
                        </div>

                    </fieldset>
                )}
            />

            <ConfirmDialog
                visible={isConfirmDialogVisible}
                onClose={handleConfirmDialogClose}
                negative={<FormattedMessage id='Control.Cancel' />}
                positive={<FormattedMessage id='Control.Confirm' />}
                title={confirmDialogTitle}
                detail={confirmDialogDetail}
            />
        </div>
    )
}

export default ExecuteScripts