import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLoading } from '../../Shared/LoadingContext';
import { useNotification } from '../../Shared/Notifications/NotificationProvider';
import { useExceptionDialog } from '../../Shared/ExceptionDialog/ExceptionDialogProvider';
import { NotificationTypes } from '../../Shared/Notifications/Notification';
import CustomerSelection from '../../Dashboard/CustomerSelection';
import { storeCustomer } from '../../../actions/dashboardActions';
import { RefreshUserContext, UserContext } from '../../UserContext';
import { getCustomerMasterData } from '../../../services/masterDataService';
import ConfirmDialog from '../../Shared/ConfirmDialog';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import LicenseTab from './LicenseTab';
import GeneralTab from './GeneralTab';
import BillingTab from './BillingTab';
import License from '../../License/License';
import { UserLeaveConfirmationEvents } from '../../Shared/UserLeaveConfirmation';
import { on, off } from '../../../actions/events';
import { filterBy } from '@progress/kendo-data-query';
import { getLocale } from '../../../utils/localization';

const defaultCustomerMasterData = {
    customerId: '',
    companyName: '',
    street: '',
    addressNumber: '',
    postalCode: '',
    city: '',
    country: '',

    salutation: null,
    contactPersonFirstName: '',
    contactPersonLastName: '',
    contactPersonEmail: '',
    contactPersonPhoneNumber: '',

    billingAddressCompanyName: '',
    billingAddressStreet: '',
    billingAddressNumber: '',
    bBillingAddressPostalCode: '',
    billingAddressCity: '',
    billingAddressCountry: '',

    vatNumber: '',
    billingEmailAddress: '',
    billingInfoPurchaseOrderNumber: '',
    billingInfoPurchaseOrderDate: null
};

const MasterData = (props) => {
    const intl = useIntl();
    let countries = require("i18n-iso-countries");

    const [countriesLNG, setCountriesLNG] = React.useState("en");
    const userProfile = useSelector(state => state.profile.profile);
    const locale = getLocale(userProfile.userLanguage);

    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/de.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/it.json"));
    const currentCustomer = useSelector(state => state.dashboard.customer);
    const dispatch = useDispatch();
    const refreshUserContext = RefreshUserContext();
    const { setLoading } = useLoading();
    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const pageId = 'MasterData';

    const userContext = React.useContext(UserContext);

    const [customerMasterData, dispatchCustomerMasterData] = useState(defaultCustomerMasterData);
    const [salutations, setSalutations] = useState([]);

    const [areChangesOnGeneral, setAreChangesOnGeneral] = React.useState(false);
    const [areChangesOnBilling, setAreChangesOnBilling] = React.useState(false);

    const [nextCustomerSelection, setNextCustomerSelection] = React.useState(null);

    const tabIndexGeneral = 0;
    const tabIndexBilling = 2;
    const [selected, setSelected] = React.useState(tabIndexGeneral);

    const [userLeaveConfirmation, setUserLeaveConfirmation] = React.useState(0);
    const userLeaveConfirmationConfirmed = () => {
        setSelected(tabIndexGeneral);
        let val = Math.random();
        setUserLeaveConfirmation(val);
    }

    useEffect(() => {
        on(UserLeaveConfirmationEvents.RouteChanged, userLeaveConfirmationConfirmed);
        return () => {
            off(UserLeaveConfirmationEvents.RouteChanged, userLeaveConfirmationConfirmed);
        };
    }, []);

    useEffect(() => {        
        let lang;
        switch (userProfile.userLanguage) {
            case window.enumLanguage.German:
                lang = "de";
                break;
            case window.enumLanguage.Italian:
                lang = "it";
                break;
            default:
                lang = "en";
                break;
        }
        setCountriesLNG(lang);
        loadSalutations();
    }, [userProfile.userLanguage]);
        
    useEffect(() => {
        loadSalutations();
    }, []);

    const loadSalutations = () => {
        let salutationsList = [{ value: 0, text: intl.formatMessage({ id: 'Salutations.Mr' }) },
            { value: 1, text: intl.formatMessage({ id: 'Salutations.Mrs' }) },
            { value: 2, text: intl.formatMessage({ id: 'Salutations.Ms' })},
            { value: 3, text: intl.formatMessage({ id: 'Salutations.Diverse' }) }];
        setSalutations(salutationsList);
    };

    const [loadingCount, setLoadingCount] = useState(0);
    useEffect(() => {
        setLoading(loadingCount > 0);
    }, [loadingCount]);

    const incrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount + 1);
    };

    const decrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount - 1);
    };

    const handleError = (errorMessage, showNotif = true) => {
        decrementLoadingCount();
        if (showNotif) {
            dispatchNotification({
                pageId: pageId,
                type: NotificationTypes.error,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            });
        }
    };

    const removeNotification = () => {
        dispatchNotification({
            remove: true,
            pageId: pageId
        });
    };
  
    const getMasterData = (customerId) => {
        if (!customerId)
            return;
        incrementLoadingCount();
        getCustomerMasterData(
            customerId,
            custMasterData => {
                decrementLoadingCount();
                const masterData = setFiledsMasterData(custMasterData);
                
                dispatchCustomerMasterData(masterData);

                if (props.onMasterDataLoaded) {
                    props.onMasterDataLoaded(masterData);
                }
            },
            handleError);
    };

    const setFiledsMasterData = (custMasterData) => {
        let salutation = null;

        if (salutations)
            salutation = salutations.find(x => x.value === custMasterData.salutation);

        let countryName = "";
        if (custMasterData.country)
            countryName = countries.getName(custMasterData.country, countriesLNG, { select: 'official' });

        let billingAddressCountryName = "";
        if (custMasterData.billingAddressCountry)
            billingAddressCountryName = countries.getName(custMasterData.billingAddressCountry, countriesLNG, { select: 'official' });

        let val = Math.random();
        return {
            ...custMasterData,
            tempId: val,
            companyName: custMasterData.companyName || '',
            street: custMasterData.street || '',
            streetNumber: custMasterData.streetNumber || '',
            postalCode: custMasterData.postalCode || '',
            city: custMasterData.city || '',
            country: countryName || '',
            salutationObj: salutation,
            contactPersonFirstName: custMasterData.contactPersonFirstName || '',
            contactPersonLastName: custMasterData.contactPersonLastName || '',
            contactPersonEmail: custMasterData.contactPersonEmail || '',
            contactPersonPhoneNumber: custMasterData.contactPersonPhoneNumber || '',

            billingAddressCompanyName: custMasterData.billingAddressCompanyName || '',
            billingAddressStreet: custMasterData.billingAddressStreet || '',
            billingAddressStreetNumber: custMasterData.billingAddressStreetNumber || '',
            billingAddressPostalCode: custMasterData.billingAddressPostalCode || '',
            billingAddressCity: custMasterData.billingAddressCity || '',
            billingAddressCountry: billingAddressCountryName || '',

            vatNumber: custMasterData.vatNumber || '',
            billingEmailAddress: custMasterData.billingEmailAddress || '',
            billingInfoPurchaseOrderNumber: custMasterData.billingInfoPurchaseOrderNumber || '',
            billingInfoPurchaseOrderDate: custMasterData.billingInfoPurchaseOrderDate ? new Date(custMasterData.billingInfoPurchaseOrderDate) : null,
        };
    }

    useEffect(() => {
        if (customerMasterData) {
            const masterData = setFiledsMasterData(customerMasterData);

            dispatchCustomerMasterData(masterData);
        }
    }, [ salutations, userLeaveConfirmation, countriesLNG]);

    useEffect(() => {
        if (currentCustomer) {
            getMasterData(currentCustomer.customerId);
        }
    }, [currentCustomer, salutations, userLeaveConfirmation, countriesLNG]);


    const handleSelect = (e) => {
        if (areChangesOnCurrentTab()) {
            setConfirmDialogTitle(<FormattedMessage id='Confirm.UnsavedChangesTitle' />);
            setConfirmDialogDetail(<FormattedMessage id='Confirm.UnsavedChangesText' />);
            setConfirmDialogPositiveButtonText(<FormattedMessage id='Control.Confirm' />);
            setConfirmDialogNegativeButtonText(<FormattedMessage id='Control.Cancel' />);

            setConfirmDialogCallback({
                success: () => {
                    setSelected(e.selected);
                }
            });
            setIsConfirmDialogVisible(true);
        }
        else {
            dispatchNotification({
                removeOnPageChanges: true
            });
            setSelected(e.selected);
        }
    };

    const areChangesOnCurrentTab = () => {
        switch (selected) {
            case tabIndexGeneral:
                return areChangesOnGeneral;
            case tabIndexBilling:
                return areChangesOnBilling;
            default:
                return false;
        }
    };

    const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);
    const [confirmDialogTitle, setConfirmDialogTitle] = useState('');
    const [confirmDialogDetail, setConfirmDialogDetail] = useState('');
    const [confirmDialogNegativeButtonText, setConfirmDialogNegativeButtonText] = useState('');
    const [confirmDialogPositiveButtonText, setConfirmDialogPositiveButtonText] = useState('');

    const [confirmDialogCallback, setConfirmDialogCallback] = useState(null);

    const handleConfirmDialogClose = (isConfirmed) => {
        setIsConfirmDialogVisible(false);
        if (isConfirmed) {
            if (confirmDialogCallback) {
                confirmDialogCallback.success();
            }            
        }
        else {
            if (confirmDialogCallback && confirmDialogCallback.cancel) {
                confirmDialogCallback.cancel();
            }
        }
    };

    useEffect(() => {
        if (nextCustomerSelection) {
            if (areChangesOnCurrentTab()) {
                setConfirmDialogTitle(<FormattedMessage id='Confirm.UnsavedChangesTitle' />);
                setConfirmDialogDetail(<FormattedMessage id='Confirm.UnsavedChangesText' />);
                setConfirmDialogPositiveButtonText(<FormattedMessage id='Control.Confirm' />);
                setConfirmDialogNegativeButtonText(<FormattedMessage id='Control.Cancel' />);
                setConfirmDialogCallback({
                    success: () => {
                        dispatch(storeCustomer(nextCustomerSelection));
                    },
                    cancel: () => {
                        setNextCustomerSelection(null)
                    }
                });
                setIsConfirmDialogVisible(true);
            }
            else {
                dispatch(storeCustomer(nextCustomerSelection));
            }
        }
    }, [nextCustomerSelection]);

    const askForTransferCompanyNameToBilling = (dataItem, callbackFunction) => {
        if (dataItem.companyName !== customerMasterData.billingAddressCompanyName) {
            setConfirmDialogTitle(<FormattedMessage id='MasterData.DoYouWantToSetBillingCompanyNameTitle' />);
            setConfirmDialogDetail(<FormattedMessage id='MasterData.DoYouWantToSetBillingCompanyNameText' values={{ CompanyName: dataItem.companyName }} />);
            setConfirmDialogPositiveButtonText(<FormattedMessage id='Control.Confirm' />);
            setConfirmDialogNegativeButtonText(<FormattedMessage id='Control.Cancel' />);
            setConfirmDialogCallback({
                success: () => {
                    if (callbackFunction)
                        callbackFunction(dataItem, true)
                },
                cancel: () => {
                    if (callbackFunction)
                        callbackFunction(dataItem, false)
                }
            });
            setIsConfirmDialogVisible(true);
        }
        else {
            callbackFunction(dataItem, false);
        }
    };

    const onCustomerHandleChange = (event) => {
        setNextCustomerSelection(event.value)
    };

    const [isLicenseVisible, setIsLicenseVisible] = useState(false);

    const handleShowLicense = () => {
        setIsLicenseVisible(true);
    };

    const handleLicenseClose = () => {
        setIsLicenseVisible(false);
    };

    const refreshUserContextMain = () => { 
        refreshUserContext(handleError);
    }

    const getCountries = () => {
        const countryList = countries.getNames(countriesLNG, { select: 'official' });
        return Object.keys(countryList).map((key) => (countryList[key]));
    }

    const filterBillingCountries = (value) => {
        const data = getCountries();
        const filter = {
            value: value,
            operator: "contains",
            ignoreCase: true,
        };
        return value ? filterBy(data, filter) : data;
    };

    const handleBillingInfoSaved = (billingInfo) => {
        if (props.onBillingInfoSaved) {
            props.onBillingInfoSaved(billingInfo);
        }
    };

    const handleBillingInfoChanged = (areChanges) => {
        if (props.onBillingInfoChanged) {
            props.onBillingInfoChanged(areChanges);
        }
    };

    return (
        <>
            {(props.isStandalone === false || typeof props.isStandalone == "undefined") && (
                <div className="content">
                    <Row>
                        <Col xs="12">
                            <h1>
                                <FormattedMessage id='MasterData.Title' />&nbsp;
                                {userContext.userGlobal.userInfo && (
                                    <CustomerSelection
                                        customer={currentCustomer}
                                        customers={userContext.userGlobal.userInfo.accessibleCustomers}
                                        isStatcontrolAdmin={userContext.userGlobal.userInfo.isStatcontrolAdmin}
                                        handleChange={onCustomerHandleChange}
                                    />
                                )}
                            </h1>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12">
                            <TabStrip selected={selected} onSelect={handleSelect}>
                                {(props.isGeneralInfoVisible || typeof props.isGeneralInfoVisible == "undefined") && <TabStripTab title={intl.formatMessage({ id: 'MasterData.GeneralTab' })}>
                                    <GeneralTab
                                        incrementLoadingCount={incrementLoadingCount}
                                        decrementLoadingCount={decrementLoadingCount}
                                        handleError={handleError}
                                        removeNotification={removeNotification}
                                        customerMasterData={customerMasterData}
                                        dispatchCustomerMasterData={dispatchCustomerMasterData}
                                        dispatchNotification={dispatchNotification}
                                        pageId={pageId}
                                        setAreChangesOnGeneral={setAreChangesOnGeneral}
                                        loadSalutations={loadSalutations}
                                        salutations={salutations}
                                        refreshUserContext={refreshUserContextMain}
                                        askForTransferCompanyNameToBilling={askForTransferCompanyNameToBilling}
                                        countries={countries}
                                        getCountries={getCountries}
                                        countriesLNG={countriesLNG}
                                        filterBillingCountries={filterBillingCountries}
                                    />
                                </TabStripTab>}
                                {(props.isLicenseInfoVisible || typeof props.isLicenseInfoVisible == "undefined") && <TabStripTab title={intl.formatMessage({ id: 'MasterData.LicenseTab' })} disabled={props.isLicenseInfoDisabled}>
                                    <LicenseTab onShowLicense={handleShowLicense}
                                        incrementLoadingCount={incrementLoadingCount}
                                        decrementLoadingCount={decrementLoadingCount}
                                        handleError={handleError}
                                        removeNotification={removeNotification}
                                        dispatchNotification={dispatchNotification}
                                    />
                                </TabStripTab>}
                                {(props.isBillingInfoVisible || typeof props.isBillingInfoVisible == "undefined") && <TabStripTab title={intl.formatMessage({ id: 'MasterData.BillingTab' })}  disabled={props.isBillingInfoDisabled}>
                                    <BillingTab
                                        incrementLoadingCount={incrementLoadingCount}
                                        decrementLoadingCount={decrementLoadingCount}
                                        handleError={handleError}
                                        removeNotification={removeNotification}
                                        customerMasterData={customerMasterData}
                                        dispatchCustomerMasterData={dispatchCustomerMasterData}
                                        dispatchNotification={dispatchNotification}
                                        pageId={pageId}
                                        setAreChangesOnBilling={setAreChangesOnBilling}
                                        countries={countries}
                                        getCountries={getCountries}
                                        countriesLNG={countriesLNG}
                                        filterBillingCountries={filterBillingCountries}
                                        locale={countriesLNG}
                                        language={locale}
                                        onBillingInfoSaved={handleBillingInfoSaved}
                                        onBillingInfoChanged={handleBillingInfoChanged}
                                    />
                                </TabStripTab>}
                            </TabStrip>
                            {isLicenseVisible && (
                                <License
                                    onClose={handleLicenseClose}
                                />
                            )}
                        </Col>
                    </Row>
                </div>
            )}

            {props.isStandalone && props.isBillingInfoVisible && (
                <BillingTab
                    incrementLoadingCount={incrementLoadingCount}
                    decrementLoadingCount={decrementLoadingCount}
                    handleError={handleError}
                    removeNotification={removeNotification}
                    customerMasterData={customerMasterData}
                    dispatchCustomerMasterData={dispatchCustomerMasterData}
                    dispatchNotification={dispatchNotification}
                    pageId={pageId}
                    setAreChangesOnBilling={setAreChangesOnBilling}
                    countries={countries}
                    getCountries={getCountries}
                    countriesLNG={countriesLNG}
                    filterBillingCountries={filterBillingCountries}
                    locale={countriesLNG}
                    language={locale}
                    onBillingInfoSaved={handleBillingInfoSaved}
                    onBillingInfoChanged={handleBillingInfoChanged}
                />
            )}

            <ConfirmDialog
                visible={isConfirmDialogVisible}
                onClose={handleConfirmDialogClose}
                negative={confirmDialogNegativeButtonText}
                positive={confirmDialogPositiveButtonText}
                title={confirmDialogTitle}
                detail={confirmDialogDetail}
            />
        </>
    );
}

export default MasterData
