import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import CustomerSelection from '../Dashboard/CustomerSelection';
import { useLoading } from '../Shared/LoadingContext';
import { UserContext } from '../UserContext';
import { useNotification } from '../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../Shared/Notifications/Notification';
import { getWarehouseLocations, getPopulations } from '../../services/warehouseLocationsService';
import { useExceptionDialog } from '../Shared/ExceptionDialog/ExceptionDialogProvider';
import { useSelector, useDispatch } from 'react-redux';
import { selectPopulation, storeCustomer } from '../../actions/dashboardActions';
import { DropDownList } from '@progress/kendo-react-dropdowns';

const ReportFilters = () => {
    const intl = useIntl();
    const userContext = React.useContext(UserContext);
    const { setLoading } = useLoading();
    const dispatchNotification = useNotification();
    const dispatchExceptionDialog = useExceptionDialog();
    const dispatch = useDispatch();
    const pageId = 'reportFilters';

    const currentCustomer = useSelector(state => state.dashboard.customer);
    
    const [warehouseLocation, setWarehouseLocation] = useState(null);
    const [warehouseLocations, setWarehouseLocations] = useState([]);

    const [population, setPopulation] = useState(null);
    const [populations, setPopulations] = useState([]);

    const isWarehouseLocationDisabled = currentCustomer == null;
    const isPopulationDisabled = warehouseLocation == null;

    const isUserStatcontrolAdmin = userContext.userGlobal.userInfo && userContext.userGlobal.userInfo.isStatcontrolAdmin;

    const currentPopulationId = userContext.userGlobal.userInfo.lastUsedPopulation?.populationId;

    const [loadingCount, setLoadingCount] = useState(0);
    useEffect(() => {
        setLoading(loadingCount > 0);
    }, [loadingCount]);

    useEffect(() => {
        if (!currentCustomer) {
            setWarehouseLocations([]);
            return;
        }

        incrementLoadingCount();
        getWarehouseLocations(
            currentCustomer.customerId,
            warehouses => {
                decrementLoadingCount();
                setWarehouseLocations(warehouses);
                
            },
            handleError);
    }, [currentCustomer]);

    useEffect(() => {
        if (warehouseLocations) {
            let warehouseLocationId = userContext.userGlobal.userInfo.lastUsedPopulation?.warehouseLocationId;

            let found = warehouseLocations.filter((item) => item.warehouseLocationId === warehouseLocationId);
            if (found.length === 1) {
                setWarehouseLocation(found[0]);
            }
            else {
                setWarehouseLocation(warehouseLocations[0]);
            }
        }
        else {
            setWarehouseLocation(null);
        }
    }, [warehouseLocations]);

    useEffect(() => {
        if (!warehouseLocation) {
            setPopulations([]);
            return;
        }

        incrementLoadingCount();
        getPopulations(
            warehouseLocation.warehouseLocationId,
            pops => {
                decrementLoadingCount();
                setPopulations(pops);
            },
            handleError);
    }, [warehouseLocation]);

    useEffect(() => {
        if (!populations) {
            setPopulation(null);
            return;
        }

        if (currentPopulationId) {
            let foundPop = populations.filter((item) => item.populationId === currentPopulationId);
            if (foundPop.length === 0) {
                setPopulation(populations[0]);
            }
            else {
                setPopulation(foundPop[0]);
            }
        }
        else {
            setPopulation(populations[0]);
        }
    }, [populations]);

    useEffect(() => {
        if (population) {
            dispatch(selectPopulation(population));
        }
    }, [population]);

    const incrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount + 1);
    };

    const decrementLoadingCount = () => {
        setLoadingCount(prevLoadingCount => prevLoadingCount - 1);
    };

    const handleError = (errorMessage, showNotif = true) => {
        decrementLoadingCount();
        setPopulations([]);
        if (showNotif) {
            dispatchNotification({
                type: NotificationTypes.error,
                message: errorMessage
            });
        }
        else {
            dispatchExceptionDialog({
                pageId: pageId,
                message: errorMessage
            })
        }
    };

    const onCustomerHandleChange = (event) => {
        dispatch(storeCustomer(event.value));
    };

    const handleWarehouseLocationChange = (event) => {
        setWarehouseLocation(event.value);
    };

    const handlePopulationChange = (event) => {
        setPopulation(event.value);
    };

    return (
        <div className="content">
            <Row>
                <Col xs="12">
                    <h1>
                        <FormattedMessage id='Reports.Title' /> {" "}
                        {
                            <CustomerSelection
                                customer={currentCustomer}
                                customers={userContext.userGlobal.userInfo.accessibleCustomers}
                                isStatcontrolAdmin={isUserStatcontrolAdmin}
                                handleChange={onCustomerHandleChange}
                            />
                        }
                    </h1>
                </Col>
            </Row>

            <Row>
                <Col xs="12">

                    <Card>
                        <CardBody>
                            <Row className="k-form">
                                <Col xs="6">
                                    <div className="mb-3">
                                        <div className="mapped-inv-col-group-single">
                                            <p className="mapper-title"><FormattedMessage id='Reports.ReportFilter.WarehouseLocation' /></p>
                                            <DropDownList
                                                id={"warehouseLocation"}
                                                name={"warehouseLocation"}
                                                value={warehouseLocation}
                                                textField={"warehouseLocationName"}
                                                dataItemKey={"warehouseLocationId"}
                                                data={warehouseLocations}
                                                onChange={handleWarehouseLocationChange}
                                                placeholder={intl.formatMessage({ id: 'SupportTools.WarehouseLocationPlaceholder' })}
                                                disabled={isWarehouseLocationDisabled}
                                            />
                                            
                                        </div>                                 
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className="mb-3">
                                        <div className="mapped-inv-col-group-single">
                                            <p className="mapper-title"><FormattedMessage id='Reports.ReportFilter.Population' /></p>
                                            <DropDownList
                                                id={"population"}
                                                name={"population"}
                                                value={population}
                                                textField={"populationName"}
                                                dataItemKey={"populationId"}
                                                data={populations}
                                                onChange={handlePopulationChange}
                                                placeholder={intl.formatMessage({ id: 'SupportTools.PopulationPlaceholder' })}
                                                disabled={isPopulationDisabled}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ReportFilters
