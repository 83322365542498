import React, { useState} from 'react';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import PreviewPDF from '../../PreviewPDF';
import { loadPdfReport, resetInventory } from '../../../../services/inventoryService';
import { useNotification } from '../../../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../../../Shared/Notifications/Notification';
import { useExceptionDialog } from '../../../Shared/ExceptionDialog/ExceptionDialogProvider';
import { useLoading } from "../../../Shared/LoadingContext";
import SamplingStatistics from './SamplingStatistics';
import { FormattedMessage } from 'react-intl';
import { on, off, trigger } from '../../../../actions/events'
import { wizardEvents } from '../Wizard';
import ConfirmDialog from '../../../Shared/ConfirmDialog';

const SamplingTabs = (props) => {

    const dispatchNotification = useNotification();
    const notifyError = (errorMessage) => dispatchNotification({
        type: NotificationTypes.error,
        pageId: 'pageInventoryWizard.Sampling',
        message: errorMessage
    });

    const dispatchExceptionDialog = useExceptionDialog();
    const showException = error => dispatchExceptionDialog({
        pageId: 'pageInventoryWizard.StockData',
        message: error.detail || error
    });

    const { loading, setLoading } = useLoading();

    const [selected, setSelected] = React.useState(0);
    const [fileInitialImport, setFileInitialImport] = React.useState(null);
    const [fileStructureAnalysis, setFileStructureAnalysis] = React.useState(null);
    const [fileLorenzCurve, setFileLorenzCurve] = React.useState(null);
    const [fileStratification, setFileStratification] = React.useState(null);

    const pricesImported = props.statistics && props.statistics.pricesImported;
    const reportTabsVisible = !props.dataOrColumnMappingError && pricesImported;

    const pdfPreviewErrorCallback = error => {
        notifyError('Unexpected error loading pdf preview');
        showException(error);
    }

    const handleSelect = async e => {
        setSelected(e.selected);
        switch (e.selected) {
            case 1:
                if (!fileInitialImport) {
                    setLoading(true);
                    await loadPdfReport(window.enumReportType.InitialImportResult, setFileInitialImport, pdfPreviewErrorCallback, props.population && props.population.populationId);
                    setLoading(false);
                }
                break;
            case 2:
                if (!fileStructureAnalysis) {
                    setLoading(true);
                    await loadPdfReport(window.enumReportType.StructureAnalysis, setFileStructureAnalysis, pdfPreviewErrorCallback, props.population && props.population.populationId);
                    setLoading(false);
                }
                break;
            case 3:
                if (!fileLorenzCurve) {
                    setLoading(true);
                    await loadPdfReport(window.enumReportType.LorenzCurve, setFileLorenzCurve, pdfPreviewErrorCallback, props.population && props.population.populationId);
                    setLoading(false);
                }
                break;
            case 4:
                if (!fileStratification) {
                    setLoading(true);
                    await loadPdfReport(window.enumReportType.Stratification, setFileStratification, pdfPreviewErrorCallback, props.population && props.population.populationId);
                    setLoading(false);
                }
                break;
            default:
        }
    };

    const [isThresholdChanged, setThresholdChanged] = useState(false)

    const wizzardContinueClick = React.useCallback(() => {
        if (isThresholdChanged) {
            setConfirmDialogTitle(<FormattedMessage id='SamplingStatistics.ConfirmDialogTitle' />);
            setConfirmDialogDetail(<FormattedMessage id='SamplingStatistics.ConfirmDialogBody' />);
            setConfirmDialogPositive(<FormattedMessage id='Control.Continue' />);
            setConfirmDialogCallback({
                success: () => continueWizzardSteps()
            });
            setIsConfirmDialogVisible(true);
        }
        else {
            continueWizzardSteps()
        }
    }, [isThresholdChanged]);

    const handleWizardPrevious = React.useCallback(() => {
        setConfirmDialogTitle(<FormattedMessage id="ResetInventory.ResetInventory" />);
        setConfirmDialogDetail(<FormattedMessage id="ResetInventory.AreYouSureYouWantToResetThisUpload" />);
        setConfirmDialogPositive(<FormattedMessage id="Control.Reset" />);
        setConfirmDialogCallback({
            success: () => resetInventoryAndGoBack()
        });
        setIsConfirmDialogVisible(true);
    }, []);

    const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);
    const [confirmDialogTitle, setConfirmDialogTitle] = useState('');
    const [confirmDialogDetail, setConfirmDialogDetail] = useState('');
    const [confirmDialogPositive, setConfirmDialogPositive] = useState('');
    const [confirmDialogCallback, setConfirmDialogCallback] = useState(null);

    const handleConfirmDialogClose = (isConfirmed) => {
        setIsConfirmDialogVisible(false);
        if (confirmDialogCallback) {
            if (isConfirmed) {
                confirmDialogCallback.success();
            }
        }
    };

    React.useEffect(() => {
        on(wizardEvents.continueButtonClicked, wizzardContinueClick);
        on(wizardEvents.previousButtonClicked, handleWizardPrevious);

        return () => {
            off(wizardEvents.continueButtonClicked, wizzardContinueClick);
            off(wizardEvents.previousButtonClicked, handleWizardPrevious);
        };
    }, [wizzardContinueClick, handleWizardPrevious]);

    const continueWizzardSteps = () => {
        trigger(wizardEvents.samplingContinue, {});
    };

    const resetInventoryAndGoBack = () => {
        setLoading(true);
        resetInventory(
            props.population.populationId,
            () => {
                setLoading(false);
                trigger(wizardEvents.samplingPrevious, {});
            },
            handleError);
    };

    const handleError = (errorMessage, showNotif = true) => {
        setLoading(false);
        if (showNotif) {
            notifyError(errorMessage);
        }
        else {
            showException(errorMessage);
        }
    };

    return <div>
        <TabStrip selected={selected} onSelect={handleSelect}>
            <TabStripTab title={<FormattedMessage id='ImportResult.Title' />}>
                <SamplingStatistics statistics={props.statistics} importInProgress={props.importInProgress}
                    setThresholdChanged={setThresholdChanged}
                    importFinishedWithError={props.importFinishedWithError} readOnly={props.readOnly} />
            </TabStripTab>
            <TabStripTab title={<FormattedMessage id='General.PdfView' />} disabled={!props.EnablePdfSummary && !props.dataOrColumnMappingError}>
                <div>
                    <PreviewPDF file={fileInitialImport}
                        prevPageText='<'
                        nextPageText='>'
                        downloadFileText={<FormattedMessage id='Reporting.DownloadDocument' />}
                    />
                </div>
            </TabStripTab>
            {reportTabsVisible &&
                <TabStripTab title={<FormattedMessage id='SamplingStatistics.Struc' />} disabled={!props.EnableReportPreview}>
                    <div>
                        <PreviewPDF file={fileStructureAnalysis}
                            prevPageText='<'
                            nextPageText='>'
                            downloadFileText={<FormattedMessage id='Reporting.DownloadDocument' />}
                        />
                    </div>
                </TabStripTab>
            }
            {reportTabsVisible &&
                <TabStripTab title={<FormattedMessage id='SamplingStatistics.Lorenz' />} disabled={!props.EnableReportPreview}>
                    <div>
                        <PreviewPDF file={fileLorenzCurve}
                            prevPageText='<'
                            nextPageText='>'
                            downloadFileText={<FormattedMessage id='Reporting.DownloadDocument' />}
                        />
                    </div>
                </TabStripTab>
            }
            {reportTabsVisible &&
                <TabStripTab title={<FormattedMessage id='SamplingStatistics.Strat' />} disabled={!props.EnableReportPreview}>
                    <div>
                        <PreviewPDF file={fileStratification}
                            prevPageText='<'
                            nextPageText='>'
                            downloadFileText={<FormattedMessage id='Reporting.DownloadDocument' />}
                        />
                    </div>
                </TabStripTab>
            }
        </TabStrip>
        <ConfirmDialog
            visible={isConfirmDialogVisible}
            onClose={handleConfirmDialogClose}
            negative={<FormattedMessage id='Control.Cancel' />}
            positive={confirmDialogPositive}
            title={confirmDialogTitle}
            detail={confirmDialogDetail}
        />
    </div>;
}

export default SamplingTabs
