import * as React from "react";
import { Button } from "reactstrap";
import ReactTooltip from 'react-tooltip';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faShare,
    faDownload
} from '@fortawesome/pro-light-svg-icons'


export const ActionCell = (props) => {
    const { dataItem } = props;
    const intl = useIntl();
    return (
        <td>
            <ReactTooltip id="buttonTipOpen" place="top" type="error" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />
            <Button
                data-tip={intl.formatMessage({ id: 'Reports.ReportHistory.Open' })} data-for="buttonTipOpen"
                color="link"
                onClick={() => props.open(dataItem)}
            >
                <FontAwesomeIcon icon={faShare} />
            </Button>
            <ReactTooltip id="buttonTipDownload" place="top" type="error" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />
            <Button
                data-tip={intl.formatMessage({ id: 'Reports.ReportHistory.Download' })} data-for="buttonTipDownload"
                color="link"
                onClick={() => props.download(dataItem)}
            >
                <FontAwesomeIcon icon={faDownload} />
            </Button>
        </td>
    );
}

export default ActionCell