import * as React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button, Row, Col, Label } from 'reactstrap';
import { Input } from "@progress/kendo-react-inputs";
import { deepClone } from '../../../utils/jsonUtils';
import { useIntl, FormattedMessage } from "react-intl";
import { NotificationTypes } from "../../Shared/Notifications/Notification";

const HeaderEditForm = props => {
    const intl = useIntl();

    const pageId = "HeaderEditForm"

    const [visible, setVisible] = React.useState(props.visible);

    React.useEffect(() => {
        if (document.getElementById("AddHeader.ColumnName")) {
            document.getElementById("AddHeader.ColumnName").focus();
        }
    }, [visible]);

    const removeNotification = () => {
        props.dispatchNotification({
            remove: true,
            pageId: pageId
        });
    };

    const handleClose = () => {
        if (props.onClose)
            props.onClose();
    };

    const handleCancel = (event) => {
        event.preventDefault();
        if (props.onCancel)
            props.onCancel();
        else if (props.onClose)
            props.onClose();
    };

    const handleConfirm = (event) => {
        event.preventDefault();
        removeNotification();
        if (isItemValid()) {
            if (props.onClose)
                props.onClose(item);
        }
    };

    React.useEffect(() => {
        setItem(deepClone(props.item))
        setVisible(props.visible);
    }, [props.visible]);

    const [item, setItem] = React.useState({ name: "" });

    const handleChange = (e) => {
        setItem(
            {
                ...item,
                ColumnName: e.value
            });
    }

    const getTitle = () => {
        return props.Item ? intl.formatMessage({ id: 'InterfaceConfiguration.EditHeader' }) : intl.formatMessage({ id: 'InterfaceConfiguration.AddHeader' })
    }

    const isItemValid = () => {
        if (!item.ColumnName || item.ColumnName.length == 0) {
                props.dispatchNotification({
                    type: NotificationTypes.error,
                    pageId: pageId,
                    message: <FormattedMessage id="InterfaceConfiguration.NameReq" />
                })
            return false;
        }
        if (props.otherItems.filter((x) => x.ColumnNo != item.ColumnNo && x.ColumnName.toLowerCase() === item.ColumnName.toLowerCase()).length > 0) {
            props.dispatchNotification({
                type: NotificationTypes.error,
                pageId: pageId,
                message: <FormattedMessage id="InterfaceConfiguration.NameMustBeUnique" />
            })
            return false;
        }
        return true;
    };

    return (
        <div>
            {visible && (
                <Dialog title={getTitle()} onClose={handleClose}  >
                    <Row className="m-3">
                        <Col>
                            <Row>
                                <Col>
                                    <Label><FormattedMessage id='InterfaceConfiguration.Name' /></Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Input
                                        id={"AddHeader.ColumnName"}
                                        name={"AddHeader.ColumnName"}
                                        value={item.ColumnName}
                                        onChange={handleChange}
                                        maxLength={50}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <DialogActionsBar>
                        {
                            props.negative &&
                            <Button type={"submit"} color="secondary" onClick={handleCancel}>
                                {props.negative}
                            </Button>
                        }
                        {
                            props.positive &&
                            <Button type={"submit"} color="primary" onClick={handleConfirm} disabled={!item.ColumnName || item.ColumnName.length == 0} >
                                {props.positive}
                            </Button>
                        }
                    </DialogActionsBar>
                </Dialog>
            )}
        </div>
    );
};

export default HeaderEditForm
