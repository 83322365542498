import * as React from "react";
import { Button } from 'reactstrap';
import { useIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faWrench,
    faTimes,
    faSave,
    faLink,
    faPencilAlt,
    faTrashAlt
} from '@fortawesome/pro-light-svg-icons'

export const EditActionCell = (props) => {
    const intl = useIntl();
    const { dataItem } = props;
    const inEdit = dataItem[props.editField];
    const isNewItem = dataItem[props.idPropName] === "" || dataItem[props.idPropName] === -1;


    return inEdit ? (
        <td>
            <Button
                color="link"
                onClick={() =>
                    isNewItem ? props.add(dataItem) : props.update(dataItem)
                }
                disabled={!dataItem.changed}
            >
                <FontAwesomeIcon icon={faSave} />
            </Button>
            <Button
                color="link"
                onClick={() =>
                    isNewItem ? props.discard(dataItem) : props.cancel(dataItem)
                }
            >
                <FontAwesomeIcon icon={faTimes} />
            </Button>
        </td>
    ) : (
        <td>
            <div>
                {props.goToDetails &&
                    <Button
                        color="link"
                        onClick={() => props.goToDetails(dataItem)}
                        data-tip={intl.formatMessage({ id: 'InterfaceConfiguration.Details' })} data-for="buttonTip"
                        disabled={props.disabled}
                    >
                        <FontAwesomeIcon icon={faWrench} />
                    </Button>
                }
                {props.goToLocations &&
                    <Button
                        color="link"
                        onClick={() => props.goToLocations(dataItem)}
                        data-tip={intl.formatMessage({ id: 'InterfaceConfiguration.GoToLocations' })} data-for="buttonTip"
                        disabled={props.disabled}
                    >
                        <FontAwesomeIcon icon={faLink} />
                    </Button>
                }
                <ReactTooltip id="buttonTip" place="left" type="error" backgroundColor='#41b6e6' effect="solid" className="generic-tooltip" />
                <Button
                    color="link"
                    onClick={() => props.edit(dataItem)}
                    data-tip={intl.formatMessage({ id: 'Control.EditInfo' })} data-for="buttonTip"
                    disabled={props.disabled}
                >
                    <FontAwesomeIcon icon={faPencilAlt} />
                </Button>
                {props.showDelete &&
                    <Button
                        color="link"
                        data-tip={intl.formatMessage({ id: 'Control.Delete' })} data-for="buttonTip"
                        onClick={() =>
                            props.remove(dataItem)
                        }
                        disabled={props.disabled}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                }
            </div>
        </td>
    );
}

export default EditActionCell