import React, { useState, useRef } from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { Upload } from '@progress/kendo-react-upload';
import { FormattedMessage } from 'react-intl';
import { trigger } from '../../../../actions/events'
import ConfirmDialog from '../../../Shared/ConfirmDialog';
import { useNotification } from '../../../Shared/Notifications/NotificationProvider';
import { NotificationTypes } from '../../../Shared/Notifications/Notification';
import { WizzardContext } from "../WizzardContext";

export const stockDataEvents = {
    selectedFileInfoChanged: "stockData:selectedFileInfoChanged"
}

const note = <span><FormattedMessage id='StockData.FilesAllowed' /></span>;
const FILE_SLICE_SIZE_KB = 5;
export const MINIMUM_REQUIRED_COLUMN_COUNT = 3;

const StockData = (props) => {
    const wizzardContext = React.useContext(WizzardContext);
    const dispatchNotification = useNotification();
    const [defaultInterfaceConfiguration, setDefaultInterfaceConfiguration] = React.useState(null);

    const notifyError = (errorMessage) => dispatchNotification({
        type: NotificationTypes.error,
        pageId: 'pageInventoryWizard.StockData',
        message: errorMessage
    });

    React.useEffect(() => {
        setDefaultInterfaceConfiguration(wizzardContext.wizzardGlobal.InterfaceConfiguration);
    }, []);

    const [confirmOverwriteVisible, setConfirmOverwriteVisible] = useState(false);
    const uploadRef = useRef();

    const handleFileSelected = e => {
        let target = e.target;
        if (target.state.files.length > 0) {
            if (wizzardContext.wizzardGlobal.Import.ProgramLockState >= window.enumProgramLockState.InitialDataLoaded) {
                setConfirmOverwriteVisible(true);
            }
            else {
                readSelectedFile();
            }
        }
    };

    const clearFileSelectionInfo = () => {
        trigger(stockDataEvents.selectedFileInfoChanged, { importFile: {}, columnHeaders: [] });
        uploadRef.current.setState({ ...uploadRef.current.state, files: [] });
    }

    const handleFileRemoved = e => {
        clearFileSelectionInfo();
    };


    const fileLoadEnded = (evt) => {
        let currentSeparator =
            defaultInterfaceConfiguration?.initialData?.columnSeparator !== window.enumColumnSeparator.None ? ";" : "";

        if (defaultInterfaceConfiguration?.initialData) {
            let columnSeparators = getColumnSeparators(defaultInterfaceConfiguration.initialData.customColumnSeparator);

            if (defaultInterfaceConfiguration.initialData.columnSeparator !== window.enumColumnSeparator.None) {
                currentSeparator = columnSeparators
                    .find(x => x.value === defaultInterfaceConfiguration.initialData.columnSeparator)
                    .textValue;
            }
        }

        if (evt.target.readyState === FileReader.DONE) {
            let columnHeaders = [];
            let needHeaders = currentSeparator && currentSeparator.length > 0;
            if (needHeaders) {
                columnHeaders = evt.target.result.split('\r\n')[0].split(currentSeparator)
                    .map((headerText, index) => ({ ColumnName: headerText.trim(), ColumnNo: index, selected: false }));
            }
            let selectedFileInfo = { importFile: {}, columnHeaders: [] };
            if (!needHeaders || columnHeaders.length >= MINIMUM_REQUIRED_COLUMN_COUNT)
                selectedFileInfo = { importFile: uploadRef.current.state.files[0].getRawFile(), columnHeaders: columnHeaders };
            else {
                uploadRef.current.setState({ ...uploadRef.current.state, files: [] });
                notifyError(<FormattedMessage id="StockData.WrongFileFormat" />);
            }
            trigger(stockDataEvents.selectedFileInfoChanged, selectedFileInfo);
        }
    }

    const readSelectedFile = () => {
        let fr = new FileReader();
        fr.onloadend = fileLoadEnded;

        // need only the first line of file containing column names, so read only the first slice big enough to contain the first line
        let blob = uploadRef.current.state.files[0].getRawFile().slice(0, 1024 * FILE_SLICE_SIZE_KB);
        fr.readAsText(blob);
    }

    const onCloseOverwriteDialog = (isConfirmed) => {
        setConfirmOverwriteVisible(false);
        if (isConfirmed) {
            readSelectedFile();
        }
        else {
            clearFileSelectionInfo();
        }
    }

    return (
        <div>
            <ConfirmDialog
                visible={confirmOverwriteVisible}
                onClose={onCloseOverwriteDialog}
                negative={<FormattedMessage id="Control.Close" />}
                positive={<FormattedMessage id="Control.Overwrite" />}
                title={<FormattedMessage id="StockData.Overwrite" />}
                detail={<FormattedMessage id="StockData.OverwriteQ" />}
            />
            <Row className="wizard-content">
                <Col xs='9'>
                    <Upload
                        ref={uploadRef}
                        batch={false}
                        multiple={false}
                        defaultFiles={props.selectedFile.name ? [props.selectedFile] : []}
                        withCredentials={false}
                        autoUpload={false}
                        showActionButtons={false}
                        accept={'.csv, .dat, .txt'}
                        saveUrl={""}
                        removeUrl={""}
                        customNote={note}
                        onAdd={handleFileSelected}
                        onRemove={handleFileRemoved}
                        className="wizard-upload"
                    />
                </Col>
                <Col xs='3'>
                    <Card className='card-chart'>
                        <CardBody>
                            <p><FormattedMessage id='Inventory data description' /></p>
                            <p><FormattedMessage id="StockData.Info" /></p>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export const getColumnSeparators = (customColumnSeparator) => {
    return [
        { value: window.enumColumnSeparator.Semicolon, textValue: ';' },
        { value: window.enumColumnSeparator.Comma, textValue: ',' },
        { value: window.enumColumnSeparator.Tabulator, textValue: '\t' },
        { value: window.enumColumnSeparator.Custom, textValue: customColumnSeparator },
        { value: window.enumColumnSeparator.None, textValue: 'none' },
    ];
};

export default StockData